import { styled } from '@material-ui/core'
import { AccountBalance, CreditCard } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import React from 'react'
import { useSelector } from 'react-redux'
import BlueInfoCard from '../components/BlueInfoCard'
import GreyInfoCard from '../components/GreyInfoCard'
import PaymentChannels from '../components/PaymentChannels'
import { FormRouteInfoPropTypes } from '../types'

const StyledImg = styled('img')({
  width: '95%',
  display: 'block',
  margin: 'auto',
})

const PaymentContainer = styled('div')({
  padding: '.5rem',
})

const BankInfoRight: React.FC<FormRouteInfoPropTypes> = (props) => {
  const orgCountry: string = useSelector(orgSelectors.getOrgIso2)?.toString() || ''

  const INFO_CONTENT = [
    {
      id: 1,
      icon: <AccountBalance />,
      header: 'Why do you need my business bank account?',
      description:
        'We require your business bank account to start receiving payments from customers - personal bank accounts cannot be used.',
    },
    {
      id: 2,
      icon: <CreditCard />,
      header: 'Where can I find my bank account info?',
      description: `To find your bank account info online, log into your bank's website and look for account details. You can also find bank account info on your paper ${
        orgCountry === 'AU' ? 'statements' : 'checks'
      }.`,
    },
  ]
  return (
    <div>
      <BlueInfoCard header={'Offer your customers the most trusted and convenient payment methods'}>
        <PaymentContainer>
          <PaymentChannels />
        </PaymentContainer>
      </BlueInfoCard>

      <GreyInfoCard content={INFO_CONTENT}>
        {orgCountry === 'US' && <StyledImg src={`${window.PUBLIC_URL}/images/cashflow/BankInfo.svg`} />}
      </GreyInfoCard>
    </div>
  )
}
export default BankInfoRight
