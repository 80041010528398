import { Popover } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { useTranslate } from 'ra-core'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-final-form'
import { FinanceCtaType } from 'resources/financeCTAs/constants'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useGetPaymentOptionDataFromSystem } from '../utils'

type PropTypes = {
  deletePaymentOption: () => void
  systemUuid: string | null
  paymentOptionId: number
  isPendingSave: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  icon: {
    color: theme.greyMid1,
  },
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    flex: 1,
    padding: '8px 15px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.greyLight1,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    color: '#999',
  },
}))

const PaymentOptionCardDropDown: React.FC<PropTypes> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const popoverRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()

  const paymentOption = useGetPaymentOptionDataFromSystem(props.systemUuid, props.paymentOptionId)

  const onDelete = () => {
    props.deletePaymentOption()
  }

  const toggleProjectCheckoutFinance = (id) => {
    const oldVal = paymentOption?.disabled_finance_product_category_ids || []
    const newVal = oldVal.includes(id) ? oldVal.filter((value) => value !== id) : [...oldVal, id]
    window.editor.execute(new window.SetValueCommand(paymentOption, 'disabled_finance_product_category_ids', newVal))
    form.mutators.markFieldAsDirty('design')
    form.change('design', 'has unsaved change')
    setIsOpen(false)
  }

  return (
    <div>
      <div ref={popoverRef} onClick={() => setIsOpen(true)}>
        <MoreVertOutlined className={classes.icon} />
      </div>
      <Popover
        id={'paymentoptioncard-actions-popover'}
        open={isOpen}
        anchorEl={popoverRef?.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.menuWrapper}>
          <div className={classes.row} onClick={onDelete}>
            {translate('Remove Payment Option')}
          </div>

          {/* If there is at least one checkout_finance CTA show this button */}
          {paymentOption?.finance_ctas.find((cta) => cta.type === FinanceCtaType.CHECKOUT_FINANCE) &&
            paymentOption?.finance_product_category_id && (
              <div
                className={classes.row}
                onClick={() => toggleProjectCheckoutFinance(paymentOption?.finance_product_category_id)}
              >
                {paymentOption?.disabled_finance_product_category_ids?.includes(
                  paymentOption?.finance_product_category_id
                )
                  ? translate('Enable Checkout Financing')
                  : translate('Disable Checkout Financing')}
              </div>
            )}
        </div>
      </Popover>
    </div>
  )
}
export default PaymentOptionCardDropDown
