import { Card, CardContent, DialogActions, Typography, useMediaQuery } from '@material-ui/core'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import CloseOutlined from '@material-ui/icons/CloseOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField } from 'opensolar-ui'
import { getExpectedMilestones } from 'pages/cashFlow/utils'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { Theme } from 'types/themes'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import LoanMilestoneTimeline from './LoanMilestoneTimeline'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeOpenSolarStyles<{ isMobile: boolean }>((theme) => ({
  lenderDescriptionText: {
    whiteSpace: 'pre-line', // respects linebreaks
    marginBottom: '16px',
  },
  lenderLogo: {
    marginBottom: '16px',
  },
  cardContent: {
    display: 'flex',
  },
  card: {
    marginBottom: '19px',
  },
  applyNowButton: {
    marginLeft: 'auto',
  },
  applyNowButtonContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
  },
  dialogTitleContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: ({ isMobile }) => (isMobile ? '17px' : undefined),
    fontWeight: ({ isMobile }) => (isMobile ? 400 : undefined),
  },
  loadingDots: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.5,
    backgroundColor: theme.greyLight1,
    zIndex: 10,
  },
  timelineWrapper: {
    margin: '16px 0px',
  },
  timelineWrapperTitle: {
    marginBottom: '25px',
  },
  totalAmountCardContent: {
    textAlign: 'center',
    padding: '13px 14px !important',
  },
  totalAmountCardContentTitle: {
    marginBottom: '10px',
    whiteSpace: 'nowrap',
    color: theme.greyDark2,
  },
  editIcon: {
    margin: '0px !important',
  },
  editTextField: {
    height: '28px',
    padding: '6px 0px',
  },
  dialogPaper: {
    width: ({ isMobile }) => (isMobile ? '100%' : undefined),
    margin: ({ isMobile }) => (isMobile ? '10px' : undefined),
  },
  dialogTitle: {
    paddingBottom: '0px',
  },
  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.greyMid2,
  },
  closeButton: {
    color: theme.greyMid1,
  },
}))

type PropTypes = {
  open: boolean
  onClose: () => void
  orgId: number
  projectId: number
  countryIso2: string
  systemUuid?: string | undefined
  paymentOptionId?: number | undefined
  expectedMilestonePayments?: ExpectedMilestonePaymentType[]
  amountDue?: number
}

type LenderType = {
  id: number
  logo_url: string
  logo_height: number | null
  title: string
  main_text: string
  sub_text: string | null
  show_apply_button: boolean
}

const CheckoutFinancingDialog: React.FunctionComponent<PropTypes> = (props) => {
  const {
    open,
    onClose,
    projectId,
    systemUuid,
    paymentOptionId,
    orgId,
    expectedMilestonePayments: expectedMilestonePaymentsProp,
    amountDue,
    countryIso2,
  } = props
  const notify = useNotify()
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const currencySymbol = currencySymbolForCountry(countryIso2)

  const [loanAmount, setLoanAmount] = useState(amountDue)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEditingAmount, setIsEditingAmount] = useState(false)
  const [lenders, setLenders] = useState<LenderType[]>([])
  const [milestonesChecked, setMilestonesChecked] = useState<{ [key: number]: boolean }>({})
  const [expectedMilestonePayments, setExpectedMilestonePayments] = useState<ExpectedMilestonePaymentType[]>([])

  const fetchExpectedMilestonePayments = useCallback(() => {
    if (orgId && systemUuid && paymentOptionId) {
      if (expectedMilestonePaymentsProp) {
        setExpectedMilestonePayments(expectedMilestonePaymentsProp)
      } else {
        // Fetch expected milestones if no expected milestones are provided
        getExpectedMilestones(orgId, projectId, systemUuid, paymentOptionId).then((res) => {
          setExpectedMilestonePayments(res.data.expected_milestone_payments)
          setLoanAmount(res.data.total_amount_payable - res.data.total_amount_paid)
        })
      }
    }
  }, [orgId, projectId, systemUuid, paymentOptionId, expectedMilestonePaymentsProp])

  const fetchAvailableLenders = useCallback(() => {
    setIsLoading(true)

    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/projects/${projectId}/checkout_financing/lenders/?payment_option_id=${paymentOptionId}`,
    })
      .then((response) => {
        setLenders(response.data?.lenders)
      })
      .catch((err) => {
        // TODO: Add error handling
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [paymentOptionId, orgId, projectId])

  useEffect(() => {
    if (orgId && projectId && systemUuid && paymentOptionId) {
      fetchAvailableLenders()
      fetchExpectedMilestonePayments()
    }
  }, [orgId, projectId, systemUuid, paymentOptionId])

  const handleApplyNowButton = (lender) => {
    if (loanAmount === 0) {
      notify('Please edit the loan amount or select milestones to proceed.')
      return
    }

    setIsSubmitting(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/projects/${projectId}/checkout_financing/affiliate_link/?finance_cta_id=${lender.id}`,
      data: {
        loanAmount: loanAmount,
        paymentOptionId: paymentOptionId,
      },
    })
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.affiliateLink;
        link.target = "_blank";
        link.rel = "noopener noreferrer sponsored";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up after clicking
      })
      .catch((err) => {
        if (err?.body?.message) {
          notify(err?.body?.message, 'warning')
        } else {
          notify('Failed to launch loan application url', 'warning')
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handleOnClose = () => {
    if (!isSubmitting) {
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={handleOnClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.titleWrapper}>
          <div className={classes.dialogTitleContent}>
            <AccountBalanceOutlinedIcon />
            <span>{translate('Explore available financing')}</span>
          </div>
          <div>
            <IconButton onClick={handleOnClose}>
              <CloseOutlined className={classes.closeButton} />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {!!lenders.length && (
          <div className={classes.timelineWrapper}>
            {!!expectedMilestonePayments.length && (
              <Typography variant="subtitle2" className={classes.timelineWrapperTitle}>
                {translate('Select financed amount')}
              </Typography>
            )}
            <Grid container alignItems="center">
              {!!expectedMilestonePayments.length && (
                <Grid item xs={12} md={9}>
                  <LoanMilestoneTimeline
                    onChangeTotalAmount={(amount) => {
                      setLoanAmount(amount)
                    }}
                    milestonesChecked={milestonesChecked}
                    setMilestonesChecked={(value) => {
                      setMilestonesChecked(value)
                    }}
                    milestones={expectedMilestonePayments}
                    countryIso2={countryIso2}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <Card>
                  <CardContent className={classes.totalAmountCardContent}>
                    <div className={classes.totalAmountCardContentTitle}>{translate('Total loan amount')}</div>
                    {!isEditingAmount ? (
                      <Button onClick={() => setIsEditingAmount(true)} variant="text">
                        <Box display="flex" alignItems="center">
                          <Typography variant="h6">
                            {`${formatCurrencyWithSymbol(loanAmount, currencySymbol, undefined, 0)}`}
                          </Typography>
                          <EditOutlinedIcon className={classes.editIcon} />
                        </Box>
                      </Button>
                    ) : (
                      <TextField
                        type="number"
                        variant="standard"
                        className={classes.editTextField}
                        value={loanAmount}
                        onChange={(e) => {
                          setMilestonesChecked({})
                          setLoanAmount(Number(e.target.value))
                        }}
                        onBlur={() => {
                          setIsEditingAmount(false)
                        }}
                        autoFocus
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        )}
        {lenders.map((lender) => (
          <Card key={lender.title} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <img
                    src={lender.logo_url}
                    height={lender.logo_height || '56px'}
                    alt={`${lender.title} logo`}
                    className={classes.lenderLogo}
                  />
                  <Typography variant="subtitle2" className={classes.lenderDescriptionText}>
                    {lender.main_text}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.lenderDescriptionText}
                    dangerouslySetInnerHTML={{ __html: lender.sub_text || '' }}
                  />
                </Grid>
                <Grid container item xs={12} md={4} className={classes.applyNowButtonContainer}>
                  {lender.show_apply_button && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleApplyNowButton(lender)
                      }}
                      endIcon={<OpenInNewOutlinedIcon />}
                      className={classes.applyNowButton}
                    >
                      {translate('Apply Now')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}

        {(isLoading || isSubmitting) && (
          <div className={classes.loadingDots}>
            <LoadingDots />
          </div>
        )}

        {!lenders.length && !isLoading && (
          <Typography>{translate('There are no available lenders for your region at the moment.')}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('Close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckoutFinancingDialog
