import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import Title, { TitlePropType } from './Title';
import { useTranslate } from 'ra-core';
var Subtitle = function (_a) {
    var subtitle = _a.subtitle;
    var translate = useTranslate();
    return subtitle ? (_jsx("p", { style: {
            color: 'rgba(0, 0, 0, 0.54)',
            margin: 0,
            marginBottom: 10,
        }, children: typeof subtitle === 'string' ? translate(subtitle) : subtitle })) : null;
};
export default Subtitle;
