import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { addAccordionFormsAndSort } from 'customForm/card/addAccordionFormsAndSort'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import DeleteButton from 'elements/proUXButtons/DeleteButton'
import { Button } from 'opensolar-ui'
import React, { useCallback, useMemo } from 'react'
import { NotFound, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { pure } from 'recompose'
import { FormPlacementEnum } from 'resources/customForms/types'
import { ProjectType } from 'types/projects'
import { RenderablePanel } from 'types/renderablePanel'
import { doNotTranslate } from 'util/misc'
import { useSolarAppAvailable } from 'util/solarApp'
import useMemoizedSelector from 'util/useMemoizedSelector'
import getProjectSectionStyles from '../../styles'
import { projectDataLoad } from '../design/util/projectDataLoad'
import { projectDataSave } from '../design/util/projectDataSave'
import SectionContainer from '../SectionContainer'
import AddressAccordion from './address'
import ContactsAccordion from './contacts'
import CustomFields from './customFields'
import InstallationAccordion from './installation'
import OrgSharing from './orgSharing'
import PermitsAndServices from './permitsAndServices'
import ProjectCTAs from './ProjectCTAs'
import { ProjectDuplicateButton } from './ProjectDuplicateButton'
import ProjectFiles from './projectFiles'
import SaleAccordion from './sale'
import SalesAccordion from './sales'
import ShareableLinkAccordion from './shareableLink'
import SiteDetailsAccordion from './siteDetails'
import SolarAPP from './solarApp'
import SystemSummaryAccordion from './systemSummary'
import Transactions from './transactions'

const useStyles = makeStyles(getProjectSectionStyles)

const LEFT_SIDE_STANDARD_COMPONENTS = [
  {
    component: ContactsAccordion,
    shouldRender: true,
    priority: 20,
    props: {},
  },
  {
    component: AddressAccordion,
    shouldRender: true,
    priority: 30,
    props: {},
  },
  {
    component: SalesAccordion,
    shouldRender: true,
    priority: 40,
    props: {},
  },
  {
    component: SiteDetailsAccordion,
    shouldRender: true,
    priority: 50,
    props: {},
  },
  {
    component: SystemSummaryAccordion,
    shouldRender: true,
    priority: 60,
    props: {},
  },
  {
    component: SaleAccordion,
    shouldRender: true,
    priority: 70,
    props: {},
  },
  {
    component: InstallationAccordion,
    shouldRender: true,
    priority: 80,
    props: {},
  },
  {
    component: PermitsAndServices,
    shouldRender: true,
    priority: 90,
    props: {},
  },
]

type PropTypes = {
  width: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
}

const InfoPageRenderer: React.FunctionComponent<PropTypes> = (props) => {
  const { allowView, allowDelete } = useMemoizedSelector(permissionsSelectors.getProjectPermissionByKey('project'))
  const proposalPermission = useMemoizedSelector(permissionsSelectors.getProjectPermissionByKey('proposal'))
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const project = useForm().getState()?.values as ProjectType
  const orgId = useSelector(authSelectors.getOrgId)
  const customForms = useGetCustomForms({ location: FormPlacementEnum.info_page })
  const connectedOrgs = useSelector(orgSelectors.getOrg)?.connected_orgs || []
  const enableSolarAPP = useSolarAppAvailable(project.country_iso2)

  const onDeleteSuccess = useCallback(() => {
    logAmplitudeEvent('project_deleted', { project_id: project?.id })
    history.push('/projects')
  }, [project?.id])

  const sortAndRenderLeftComponents = useMemo(() => {
    let toRender: RenderablePanel[] = [
      ...LEFT_SIDE_STANDARD_COMPONENTS,
      {
        component: ProjectCTAs,
        shouldRender: props.width === 'xs',
        priority: 10,
        props: {},
      },
    ]
    return addAccordionFormsAndSort(toRender, customForms)
  }, [props.width, customForms])

  if (!allowView) {
    return <NotFound />
  }

  return (
    <SectionContainer section="info" mode="background">
      <div className={classes.formPage}>
        <div className={classes.sectionWrapper}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={8} item>
              {sortAndRenderLeftComponents}
            </Grid>
            <Grid xs={12} sm={4} item>
              {props.width !== 'xs' && <ProjectCTAs />}
              <CustomFields />
              {enableSolarAPP && <SolarAPP project={project} />}
              <ProjectFiles />
              <Transactions org_id={orgId} project_id={project.id} />
              {proposalPermission.allowView && proposalPermission.allowEdit && (
                <ShareableLinkAccordion orgId={orgId} projectId={project.id} />
              )}
              {connectedOrgs.length > 0 && <OrgSharing connectedOrgs={connectedOrgs} />}
            </Grid>
            {project && (
              <Grid xs={12}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '15px 0px',
                  }}
                >
                  {orgId && project.id && (
                    <ProjectDuplicateButton orgId={orgId} projectId={project.id} style={{ margin: 5 }} />
                  )}

                  {project.id && allowDelete && (
                    <DeleteButton
                      // color="default"
                      label="delete project"
                      confirmMode="dialog"
                      resource="projects"
                      record={project}
                      startIcon={<DeleteIcon />}
                      type="gray"
                      confirmTitle={translate('Delete %{title}?', { title: project?.title || 'project' })}
                      confirmBody={translate('Are you sure you want to delete this project?')}
                      onSuccess={onDeleteSuccess}
                      style={{ margin: 5 }}
                    />
                  )}

                  {isStaff && (
                    <>
                      <Button variant="contained" style={{ margin: 5 }} onClick={projectDataSave}>
                        {doNotTranslate('Save Project Data')}
                      </Button>
                      <Button variant="contained" style={{ margin: 5 }} onClick={projectDataLoad}>
                        {doNotTranslate('Load Project Data')}
                      </Button>
                    </>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </SectionContainer>
  )
}

export default pure(withWidth()(InfoPageRenderer))
