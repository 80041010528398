import { markPaymentComplete } from 'ducks/paymentsPage'
import usePageVisibility from 'hooks/usePageVisibility'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useLoadMonitePaymentsComponent = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [loadError, setLoadError] = useState<string | undefined>(undefined)

  useEffect(() => {
    const sdkURL = ['production', 'staging4'].includes(window.ENV)
      ? 'https://pay.monite.com/drop-in/index.js'
      : 'https://pay.sandbox.monite.com/drop-in/index.js'
    const script = document.createElement('script')
    script.src = sdkURL
    script.crossOrigin = 'anonymous'
    script.onload = (e) => {
      setIsLoaded(true)
      setLoadError(undefined)
    }
    script.onerror = (err) => {
      console.error('Error loading Monite Payments Component', err)
    }
    document.head.appendChild(script)
  }, [])

  return { isLoaded, loadError }
}

const MAX_POLL_ATTEMPTS = 10

export const useGetMonitePaymentLink = (sdkIsLoaded: boolean, orgId: number, paymentRequestId: number) => {
  const [linkID, setLinkID] = useState<string | undefined>(undefined)
  const [status, setStatus] = useState<'created' | 'succeeded' | undefined>(undefined)
  const [isLoadingPaymentRequest, setIsLoadingPaymentRequest] = useState<boolean>(false)
  const [pollAttempts, setPollAttempts] = useState<number>(0)
  const [triggerRefresh, setTriggerRefresh] = useState<undefined | string>(undefined)

  const pageIsVisible = usePageVisibility()
  const dispatch = useDispatch()

  const getPaymentLink = () => {
    console.log('getting link', pollAttempts)
    if (pollAttempts > MAX_POLL_ATTEMPTS) {
      return
    }
    setIsLoadingPaymentRequest(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/payments/requests/${paymentRequestId}/payment_link/monite/`,
    })
      .then((res) => {
        setLinkID(res.data.payment_link_id)
        setStatus(res.data.status)
        if (res.data.status !== 'succeeded') {
          setPollAttempts(pollAttempts + 1)
        } else {
          // if the payment is complete stop polling
          setPollAttempts(MAX_POLL_ATTEMPTS + 1)
        }
      })
      .catch((err) => {
        console.log('err', err)
        setPollAttempts(MAX_POLL_ATTEMPTS + 1)
      })
      .finally(() => {
        setIsLoadingPaymentRequest(false)
      })
  }

  // when the sdk is initially loaded an the page is visible, get the payment link
  // and refresh the payment link immediately whenever the user returns to the page
  useEffect(() => {
    if (sdkIsLoaded && pageIsVisible) {
      getPaymentLink()
    }
  }, [sdkIsLoaded, pageIsVisible])

  // when the user leaves the page, restart the poll count. This will often mean
  // they have been brought to the bank's website to complete the payment
  useEffect(() => {
    if (!pageIsVisible) {
      setPollAttempts(0)
    }
  }, [pageIsVisible])

  // poll our API while the payment is in the 'created' state which allow the client
  // to be udpated if the payment has been completed

  useEffect(() => {
    if (pollAttempts > 0 && pageIsVisible) {
      setTimeout(() => {
        setTriggerRefresh(`${new Date()}`)
      }, Math.max(pollAttempts, 3) * 1000)
    }
  }, [pageIsVisible, pollAttempts])

  useEffect(() => {
    if (triggerRefresh) {
      getPaymentLink()
    }
  }, [triggerRefresh])

  useEffect(() => {
    if (status === 'succeeded') {
      dispatch(markPaymentComplete())
    }
  }, [status])

  return { linkID, status, isLoadingPaymentRequest }
}
