var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import sanitizeRestProps from './sanitizeRestProps';
import { fieldPropTypes } from './types';
var useStyles = makeStyles({
    root: { display: 'inline-block' },
}, { name: 'RaFileField' });
var FileField = function (props) {
    var className = props.className, classesOverride = props.classes, emptyText = props.emptyText, record = props.record, source = props.source, title = props.title, src = props.src, target = props.target, rest = __rest(props, ["className", "classes", "emptyText", "record", "source", "title", "src", "target"]);
    var sourceValue = get(record, source);
    var classes = useStyles(props);
    if (!sourceValue) {
        return emptyText ? (_jsx(Typography, __assign({ component: "span", variant: "body2", className: className }, sanitizeRestProps(rest), { children: emptyText }))) : (_jsx("div", __assign({ className: classnames(classes.root, className) }, sanitizeRestProps(rest))));
    }
    if (Array.isArray(sourceValue)) {
        return (_jsx("ul", __assign({ className: classnames(classes.root, className) }, sanitizeRestProps(rest), { children: sourceValue.map(function (file, index) {
                var fileTitleValue = get(file, title) || title;
                var srcValue = get(file, src) || title;
                return (_jsx("li", { children: _jsx("a", { href: srcValue, title: fileTitleValue, target: target, children: fileTitleValue }) }, index));
            }) })));
    }
    var titleValue = get(record, title) || title;
    return (_jsx("div", __assign({ className: classnames(classes.root, className) }, sanitizeRestProps(rest), { children: _jsx("a", { href: sourceValue, title: titleValue, target: target, children: titleValue }) })));
};
FileField.defaultProps = {
    addLabel: true,
};
FileField.propTypes = __assign(__assign({}, fieldPropTypes), { src: PropTypes.string, title: PropTypes.string, target: PropTypes.string });
export default FileField;
