import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { useDispatch, useSelector } from 'react-redux'
import { doNotTranslate } from 'util/misc'
import IntegrationRow from '../IntegrationRow'

const SolarAppCTA = () => {
  const dispatch = useDispatch()
  const orgId = useSelector(authSelectors.getOrgId)
  const { allowView: canViewAPIKeysPage } = useSelector(
    permissionsSelectors.getPermissionByKey('integrations_and_api_keys')
  )

  return (
    <IntegrationRow
      title={doNotTranslate('SolarAPP+')}
      btnLabel={doNotTranslate('Set up SolarAPP+')}
      description={doNotTranslate(
        'Tired of waiting for permit approvals? Set up SolarAPP+ and experience faster permit approvals from local AHJs.'
      )}
      logo={<img src={`${window.PUBLIC_URL}/images/solarapp_logo.png`} width="36px" height="23px" />}
      tooltip={
        canViewAPIKeysPage
          ? doNotTranslate(
              'You will need to set up SolarAPP+ in Control > Other > Integrations & API Keys before creating a permit pack.'
            )
          : doNotTranslate('You will need to set up SolarAPP+ before creating a permit pack.')
      }
      onClick={
        canViewAPIKeysPage
          ? () => {
              const redirect = `/external_api_keys/${orgId}`
              dispatch(
                setStudioSavePrompt({
                  show_prompt: true,
                  prompt_heading: doNotTranslate('Leave Project to Integrations & API Keys page?'),
                  redirect,
                })
              )
            }
          : undefined
      }
      disabled={!canViewAPIKeysPage}
    />
  )
}

export default SolarAppCTA
