var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import AlertError from '@material-ui/icons/ErrorOutline';
import classnames from 'classnames';
import { Button } from 'opensolar-ui';
import PropTypes from 'prop-types';
import React, { Children, Fragment, isValidElement } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeleteButton, SaveButton } from '../button';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        toolbar: {
            // backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            backgroundColor: 'rgba(224, 224, 224, 0.5)',
            justifyContent: 'flex-end',
        },
        desktopToolbar: {
            marginTop: theme.spacing(2),
        },
        mobileToolbar: {
            position: 'fixed',
            bottom: 50,
            left: 0,
            right: 0,
            padding: '8px',
            width: '100%',
            boxSizing: 'border-box',
            flexShrink: 0,
            zIndex: 2,
        },
        ux2DesktopToolbar: (_a = {
                position: 'fixed',
                bottom: 0,
                width: 'calc(100% - 240px)',
                left: 240,
                zIndex: 2,
                boxSizing: 'border-box'
            },
            _a[theme.breakpoints.down('xs')] = {
                left: 0,
                bottom: 50,
            },
            _a),
        defaultToolbar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        spacer: (_b = {},
            _b[theme.breakpoints.down('xs')] = {
                height: '5em',
            },
            _b),
    });
}, { name: 'RaToolbar' });
var valueOrDefault = function (value, defaultValue) { return (typeof value === 'undefined' ? defaultValue : value); };
var Toolbar = function (props) {
    var _a;
    var basePath = props.basePath, children = props.children, className = props.className, disabled = props.disabled, classesOverride = props.classes, handleSubmit = props.handleSubmit, handleSubmitWithRedirect = props.handleSubmitWithRedirect, invalid = props.invalid, pristine = props.pristine, record = props.record, redirect = props.redirect, resource = props.resource, saving = props.saving, hideCancelButton = props.hideCancelButton, submitOnEnter = props.submitOnEnter, undoable = props.undoable, width = props.width, rest = __rest(props, ["basePath", "children", "className", "disabled", "classes", "handleSubmit", "handleSubmitWithRedirect", "invalid", "pristine", "record", "redirect", "resource", "saving", "hideCancelButton", "submitOnEnter", "undoable", "width"]);
    var staticClasses = useStyles(props);
    var classes = props.classes && ((_a = Object.keys(props.classes)) === null || _a === void 0 ? void 0 : _a.length) > 0 ? props.classes : staticClasses;
    var zone = useSelector(function (state) { return state.zone; });
    var translate = useTranslate();
    // force redirect /{resource}/list to /{resource}
    var redirectLinkOnCancel = redirect && redirect !== 'list' && redirect !== 'edit' ? redirect : "/".concat(resource);
    return (_jsxs(Fragment, { children: [_jsx(MuiToolbar, __assign({ className: classnames(classes.toolbar, (zone === 'control' || zone === 'inventory') && width !== 'xs'
                    ? classes.ux2DesktopToolbar
                    : classes.desktopToolbar, // TODO fix me please
                width === 'xs' && classes.mobileToolbar, className), role: "toolbar" }, rest, { children: Children.count(children) === 0 ? (_jsxs("div", { className: classes.defaultToolbar, children: [!hideCancelButton && (_jsx(Link, { style: { textDecoration: 'none' }, to: redirectLinkOnCancel, children: _jsx(Button, { variant: "contained", color: "default", style: { margin: '10px 0 10px 10px' }, startIcon: _jsx(AlertError, {}), children: _jsx("span", { children: translate('Cancel') }) }) })), _jsx(SaveButton, { handleSubmitWithRedirect: handleSubmitWithRedirect || handleSubmit, invalid: invalid, redirect: redirect, saving: saving, disabled: disabled, submitOnEnter: submitOnEnter }), false && record && typeof record.id !== 'undefined' && (_jsx(DeleteButton, { variant: "contained", color: "default", basePath: basePath, record: record, resource: resource, undoable: undoable }))] })) : (Children.map(children, function (button) {
                    return button && isValidElement(button)
                        ? React.cloneElement(button, {
                            basePath: basePath,
                            handleSubmit: valueOrDefault(button.props.handleSubmit, handleSubmit),
                            handleSubmitWithRedirect: valueOrDefault(button.props.handleSubmitWithRedirect, handleSubmitWithRedirect),
                            onSave: button.props.onSave,
                            invalid: invalid,
                            pristine: pristine,
                            record: record,
                            resource: resource,
                            saving: saving,
                            submitOnEnter: valueOrDefault(button.props.submitOnEnter, submitOnEnter),
                            undoable: valueOrDefault(button.props.undoable, undoable),
                        })
                        : null;
                })) })), _jsx("div", { className: classes.spacer })] }));
};
Toolbar.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    handleSubmit: PropTypes.func,
    handleSubmitWithRedirect: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
    resource: PropTypes.string,
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    submitOnEnter: PropTypes.bool,
    undoable: PropTypes.bool,
    width: PropTypes.string,
};
Toolbar.defaultProps = {
    submitOnEnter: true,
};
export default withWidth({ initialWidth: 'xs' })(Toolbar);
