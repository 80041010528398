import { InputAdornment, makeStyles } from '@material-ui/core'
import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import { permissionsSelectors } from 'ducks/permissions'
import DependentInput from 'elements/input/DependentInput'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { useEffect } from 'react'
import { NumberInput, SelectInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { UNIT_OPTIONS, UTILITY_TARIFF_BILL_FREQUENCY_CHOICES } from 'resources/utilityTariffs/common'
import SolarCompensationInputs from 'resources/utilityTariffs/tariffInputs/solarCompensation/SolarCompensationInputs'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import { ActionType, EventType } from 'types/tracking'
import { currencySymbolForCountry } from 'util/misc'
import { useCountrySpecificTariffFields, useDefaultTariffCountry } from './hooks'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  inputContainer: {
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  labelWidth: {
    margin: 0,
    marginRight: 'auto',
  },
  inputWidth: {
    width: '60%',
    margin: '10px 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  halfWidth: {
    width: '50%',
    margin: 0,
  },
  rightInput: {
    marginLeft: 5,
  },
}))

const ExportCreditContent = ({ currencySymbol, countryIso2, disabled }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPublicFeatureConfig('default_tariff_fields'))
  }, [])

  const countrySpecificDefaults = useSelector((state: RootState) =>
    featureConfigSelectors.getFeatureConfigData(state, 'default_tariff_fields')
  )

  const showCountrySpecificFields = useCountrySpecificTariffFields(countryIso2)

  useEffect(() => {
    if (showCountrySpecificFields && countrySpecificDefaults) {
      countrySpecificDefaults[countryIso2].export_credits.forEach((credit, index) => {
        form.mutators.updateField(
          `utility_tariff_current_custom.export_credits.${index}.criteria_name`,
          credit.criteria_name
        )
        form.mutators.updateField(
          `utility_tariff_current_custom.export_credits.${index}.criteria_lower_limit`,
          credit.criteria_lower_limit
        )
        form.mutators.updateField(
          `utility_tariff_current_custom.export_credits.${index}.criteria_upper_limit`,
          credit.criteria_upper_limit
        )
        form.mutators.updateField(
          `utility_tariff_current_custom.export_credits.${index}.tou_periods.0.blocks.0.units`,
          credit.tou_periods[0].blocks[0].units
        )
      })
    }
  }, [showCountrySpecificFields, countrySpecificDefaults])

  return (
    <>
      {showCountrySpecificFields && countrySpecificDefaults ? (
        <>
          {countrySpecificDefaults[countryIso2].export_credits.map((credit, index) => {
            return (
              <div className={classes.row}>
                <p className={classes.labelWidth}>{index === 0 ? translate('Export Credit') : ''}</p>
                <NumberInput
                  key={`${credit.criteria_lower_limit}-${credit.criteria_upper_limit}`}
                  className={classes.inputWidth}
                  variant="outlined"
                  defaultValue={credit.tou_periods[0].blocks[0].value}
                  label={`PV System Size (${credit.criteria_lower_limit} - ${credit.criteria_upper_limit} kW)`}
                  resource={'projects'}
                  source={`utility_tariff_current_custom.export_credits.${index}.tou_periods.0.blocks.0.value`}
                  fullWidth
                  endAdornment={<InputAdornment position="end">{translate('per kWh')}</InputAdornment>}
                  startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                  disabled={disabled}
                />
              </div>
            )
          })}
        </>
      ) : (
        <div className={classes.row}>
          <p className={classes.labelWidth}>{translate('Export Credit')}</p>
          <NumberInput
            className={classes.inputWidth}
            variant="outlined"
            label={''}
            resource={'projects'}
            options={{
              InputLabelProps: {
                shrink: false,
              },
            }}
            source={'utility_tariff_current_custom.export_credits.0.tou_periods.0.blocks.0.value'}
            fullWidth
            endAdornment={<InputAdornment position="end">{translate('per kWh')}</InputAdornment>}
            startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
            disabled={disabled}
          />
        </div>
      )}
    </>
  )
}

const SimpleFlatRateContent = () => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const countryIso2 = useDefaultTariffCountry()?.iso2
  const currencySymbol = currencySymbolForCountry(countryIso2)
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('energy_tariff'))
  const { trackEvent } = useTrackComponent({
    componentKey: 'simple_flat_rate_content',
    eventName: `User Entered Manunal Energy Rates Under The Tab Simple Flat Rate`,
  })

  const disabled = !allowEdit

  useEffect(() => {
    if (dirtyFields.some((x) => x.includes('utility_tariff_current_custom'))) {
      form.mutators.markFieldAsDirty('utility_tariff_current_custom')
    }
  }, [dirtyFields])

  return (
    <div className={classes.inputContainer}>
      <div className={classes.row}>
        <p className={classes.labelWidth}>{translate('Price of Electricity')}</p>
        <NumberInput
          variant="outlined"
          label={''}
          resource={'projects'}
          options={{
            InputLabelProps: {
              shrink: false,
            },
          }}
          source={'utility_tariff_current_custom.energy_charges.0.tou_periods.0.blocks.0.value'}
          className={classes.inputWidth}
          endAdornment={<InputAdornment position="end">{translate('per kWh')}</InputAdornment>}
          startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
          disabled={disabled}
          onChange={() =>
            trackEvent(EventType.USER_INTERACTION, { type: ActionType.EDIT }, { field: 'price_of_electricity' })
          }
        />
      </div>
      <div className={classes.row}>
        <p className={classes.labelWidth}>{translate('Fixed Charge')}</p>
        <div className={`${classes.row} ${classes.inputWidth} ${classes.noWrap}`}>
          <NumberInput
            className={classes.halfWidth}
            variant="outlined"
            label={''}
            resource={'projects'}
            options={{
              InputLabelProps: {
                shrink: false,
              },
            }}
            source={'utility_tariff_current_custom.fixed_charges.0.value'}
            startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
            disabled={disabled}
            onChange={() =>
              trackEvent(EventType.USER_INTERACTION, { type: ActionType.EDIT }, { field: 'fixed_charge' })
            }
          />
          <SelectInput
            className={`${classes.halfWidth} ${classes.rightInput}`}
            variant="outlined"
            label="Units"
            resource={'projects'}
            optionText={(record: any) => {
              return `${currencySymbol}/${translate(record?.name)}`
            }}
            source={'utility_tariff_current_custom.fixed_charges.0.units'}
            choices={UNIT_OPTIONS.fixed_charges}
            disabled={disabled}
            onChange={() => trackEvent(EventType.USER_INTERACTION, { type: ActionType.EDIT }, { field: 'units' })}
          />
        </div>
      </div>
      <div className={classes.row}>
        <p className={classes.labelWidth}>{translate('Bill Frequency')}</p>
        <SelectInput
          className={classes.inputWidth}
          variant="outlined"
          label={''}
          resource={'projects'}
          options={{
            InputLabelProps: {
              shrink: false,
            },
          }}
          fullWidth
          source={'utility_tariff_current_custom.bill_frequency'}
          choices={UTILITY_TARIFF_BILL_FREQUENCY_CHOICES}
          disabled={disabled}
          onChange={() =>
            trackEvent(EventType.USER_INTERACTION, { type: ActionType.EDIT }, { field: 'bill_frequency' })
          }
        />
      </div>
      <div className={classes.row}>
        <p className={classes.labelWidth}>{translate('Solar Compensation Mechanism')}</p>
        <div className={classes.inputWidth}>
          <SolarCompensationInputs
            hideLabel={true}
            overrideSource={'utility_tariff_current_custom.solar_compensation_settings'}
            countryIso2={countryIso2}
            currencySymbol={currencySymbol}
            simpleFlatInput={true}
            disabled={disabled}
          />
        </div>
      </div>
      <DependentInput
        dependsOn="utility_tariff_current_custom"
        resolve={(v) => v?.solar_compensation_settings?.distributed_generation_rules !== 'Net Metering'}
      >
        <ExportCreditContent
          currencySymbol={currencySymbol}
          countryIso2={countryIso2}
          disabled={disabled}
        ></ExportCreditContent>
      </DependentInput>
    </div>
  )
}

export default SimpleFlatRateContent
