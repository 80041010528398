import { doNotTranslate } from 'util/misc'
import { FieldErrors, SolarAppErrorResponsePayload } from './types'

/**
 * SolarAppErrorCodes used to aid FE error handling and messaging
 * Ref: https://gosolarapp.org/api/docs#section/Errors/Error-code-reference
 */
export const SOLAR_APP_ERROR_CODES = {
  AHJ_CONFIG_ERROR: {
    code: '0102',
    details:
      'Verify that the product type is supported by the AHJ. If not, contact SolarAPP+ to see if it can be enabled for your account.',
  },
}

export const getErrorMessage = (errorResponse: SolarAppErrorResponsePayload | null, defaultErrorMessage?: string) => {
  let errorMsg = defaultErrorMessage || doNotTranslate('An unexpected issue occurred, Please try again later.')

  if (errorResponse?.message) {
    errorMsg = errorResponse.message
  }
  return errorMsg
}

export const getValidationErrors = (errorResponse: SolarAppErrorResponsePayload): FieldErrors => {
  if (errorResponse?.errors) {
    return Object.keys(errorResponse.errors).reduce((acc, key) => {
      if (errorResponse.errors) {
        acc[key] = errorResponse.errors[key].join(' ')
      }
      return acc
    }, {})
  }
  return undefined
}
