var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import { Button } from 'opensolar-ui';
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import { useCallback } from 'react';
var useStyles = makeStyles(function (theme) { return ({
    contentText: {
        minWidth: 400,
    },
    confirmPrimary: {
        color: theme.palette.primary.main,
    },
    confirmWarning: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
    dialogActionRoot: {
        padding: 10,
    },
    button: {
        marginRight: 2,
    },
}); }, { name: 'RaConfirm' });
/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     ConfirmIcon=ActionCheck
 *     CancelIcon=AlertError
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
var Confirm = function (props) {
    var isOpen = props.isOpen, loading = props.loading, title = props.title, content = props.content, confirm = props.confirm, cancel = props.cancel, confirmColor = props.confirmColor, ConfirmIcon = props.ConfirmIcon, CancelIcon = props.CancelIcon, onClose = props.onClose, onConfirm = props.onConfirm, _a = props.translateOptions, translateOptions = _a === void 0 ? {} : _a;
    var classes = useStyles(props);
    var translate = useTranslate();
    var handleConfirm = useCallback(function (e) {
        e.stopPropagation();
        onConfirm(e);
    }, [onConfirm]);
    var handleClick = useCallback(function (e) {
        e.stopPropagation();
    }, []);
    return (_jsxs(Dialog, { open: isOpen, onClose: onClose, onClick: handleClick, "aria-labelledby": "alert-dialog-title", children: [_jsx(DialogTitle, { id: "alert-dialog-title", children: translate(title, __assign({ _: title }, translateOptions)) }), _jsx(DialogContent, { children: _jsx(DialogContentText, { className: classes.contentText, children: translate(content, __assign({ _: content }, translateOptions)) }) }), _jsxs(DialogActions, { className: classes.dialogActionRoot, children: [_jsx(Button, { variant: "contained", color: "default", startIcon: _jsx(CancelIcon, {}), disabled: loading, classes: { root: classes.button }, onClick: onClose, children: _jsx("span", { children: translate(cancel, { _: cancel }) }) }), _jsx(Button, { disabled: loading, variant: "contained", color: "primary", onClick: handleConfirm, startIcon: _jsx(ConfirmIcon, {}), autoFocus: true, children: _jsx("span", { children: translate(confirm, { _: confirm }) }) })] })] }));
};
Confirm.propTypes = {
    cancel: PropTypes.string,
    classes: PropTypes.object,
    confirm: PropTypes.string,
    confirmColor: PropTypes.string,
    ConfirmIcon: PropTypes.elementType,
    CancelIcon: PropTypes.elementType,
    content: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
Confirm.defaultProps = {
    cancel: 'ra.action.cancel',
    classes: {},
    confirm: 'ra.action.confirm',
    confirmColor: 'primary',
    ConfirmIcon: ActionCheck,
    CancelIcon: AlertError,
    isOpen: false,
};
export default Confirm;
