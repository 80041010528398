// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import { HelpLink } from 'elements/link/HelpLink'
import { DEFAULT_YEAR } from 'elements/scheduleDialog/utils'
import TrackedToolbar from 'elements/TrackedToolbar'
import { SimpleForm, TextInput, useTranslate } from 'react-admin'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { required } from 'validations'
import OverviewInputs from '../../elements/input/OverviewInputs'
import useTranslateParse from '../../util/useTranslateParse'
import StrategyTable, { defaultStrategy } from './StrategyTable'
import { validateBatteryScheme } from './validation'

const useStyles = makeStyles((theme) => ({
  text: { fontSize: 14, lineHeight: '16px', color: 'rgba(0, 0, 0, 0.75)', letterSpacing: '0.75px' },
  textHighlight: { marginTop: 20, fontWeight: 'bold' },
}))

const formatSubmitValues = (values: any) => {
  if (values.strategies) {
    values['data'] = JSON.stringify(values.strategies)
  }
  delete values.strategies
  return values
}

const defaultValue = {
  strategies: [{ ...defaultStrategy }],
}

const parseDefaultValues = (record: any) => {
  if (record) {
    try {
      record.strategies = JSON.parse(record.data)
    } catch (error) {
      return defaultValue
    }
    // Parse date ranges
    record.strategies?.forEach((strategy) => {
      strategy.schedule.date_ranges = strategy.schedule.date_ranges?.map((range) => {
        if (range?.start_date && range?.end_date) {
          const startDate = new Date(range?.start_date)
          const endDate = new Date(range?.end_date)
          return {
            start_date: new Date(DEFAULT_YEAR, startDate?.getMonth(), startDate?.getDate()),
            end_date: new Date(DEFAULT_YEAR, endDate?.getMonth(), endDate?.getDate()),
          }
        } else {
          return range
        }
      })
    })

    return record
  } else {
    return defaultValue
  }
}

const BatterySchemesForm = (props: any) => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const classes = useStyles()
  return (
    <SimpleForm
      validate={(values: any) => validateBatteryScheme(values, translate)}
      formatSubmitValues={formatSubmitValues}
      defaultValue={parseDefaultValues}
      toolbar={<TrackedToolbar {...props} />}
      {...props}
    >
      <OverviewInputs>
        <CustomField
          component={TextInput}
          validate={required}
          inputStyle={{ width: 256 }}
          name="name"
          source="name"
          label="Name"
        />
        <CustomField
          component={TextInput}
          fullWidth={true}
          multiline={true}
          name={'description'}
          label="Description"
          source="description"
        />
      </OverviewInputs>
      <div>
        <div className={classes.text}>
          <div>
            {translate(
              'Create your own battery dispatch rules which allow you to control when to charge/discharge your battery.'
            )}
          </div>
          <div>{translate('There are 4 different battery operating rules that can be set:')}</div>
          <div> 1. {translate('Allow Charge From System (i.e. PV)')},</div>
          <div> 2. {translate('Allow Charge from Grid')},</div>
          <div> 3. {translate('Allow Discharge to Load')},</div>
          <div> 4. {translate('Allow discharge to Grid')},</div>
          <div>
            {translateParse(
              'Some combinations of the operating rules are also permitted. A more detailed guide is provided on our <a>Help Center<a>.',
              {
                a: (label: string) => (
                  <HelpLink href="https://support.opensolar.com/hc/en-us/articles/7875451066511" title={label} />
                ),
              }
            )}
          </div>
          <div>
            {translateParse(
              "The <b>Minimum State of Charge (%)<b> prevents the battery from discharging if it's current battery state of charge (SOC) is equal to or less than its set value.",
              {
                b: (label: string) => <b>{label}</b>,
              }
            )}
          </div>
          <div>
            {' '}
            {translateParse(
              'The <b>Maximum Charge/Discharge Rate (kW)<b> prevents the battery from charging/discharge faster than the set value, or the battery “Max Continuous Power”, whichever is smaller.',
              {
                b: (label: string) => <b>{label}</b>,
              }
            )}
          </div>
          <div className={classes.textHighlight}>
            {translate(
              'If MORE THAN 1 strategy applies to the same time-period, the strategy created at the bottom of the list will apply.'
            )}
            <br />
            {translate(
              'If a time-period is not assigned a strategy then the battery will do nothing (i.e. does not charge or discharge).'
            )}
          </div>
        </div>

        <StrategyTable />
      </div>
      <TextInput source="data" style={{ display: 'none' }} />
      <ShareabilitySelector />
    </SimpleForm>
  )
}

export default BatterySchemesForm
