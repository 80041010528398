var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fab, makeStyles, useMediaQuery } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import { Link } from 'react-router-dom';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import Button, { sanitizeButtonRestProps } from './Button';
var CreateButton = function (props) {
    var _a = props.basePath, basePath = _a === void 0 ? '' : _a, className = props.className, classesOverride = props.classes, _b = props.label, label = _b === void 0 ? 'ra.action.create' : _b, _c = props.icon, icon = _c === void 0 ? defaultIcon : _c, id = props.id, onClick = props.onClick, rest = __rest(props, ["basePath", "className", "classes", "label", "icon", "id", "onClick"]);
    var classes = useStyles(props);
    var translate = useTranslate();
    var isSmall = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    return isSmall ? (_jsx(Link, { id: id, style: { textDecoration: 'none', color: '#fff' }, to: "".concat(basePath, "/create"), onClick: onClick, children: _jsx(Fab, __assign({ id: id ? id + '-button' : undefined, color: "primary", className: classnames(classes.floating, className), style: {
                width: 70,
                height: 70,
                borderRadius: '50%',
            }, name: 'raCreateButton', "aria-label": label && translate(label) }, sanitizeButtonRestProps(rest), { children: icon })) })) : (_jsx(Link, { id: id, style: { textDecoration: 'none' }, to: "".concat(basePath, "/create"), onClick: onClick, children: _jsx(Button, __assign({ id: id ? id + '-button' : undefined, variant: "outlined", color: "default", size: "default", name: 'raCreateButton', style: { margin: 5 }, startIcon: icon }, rest, { children: _jsx("span", { children: translate(label) }) })) }));
};
var defaultIcon = _jsx(ContentAdd, {});
var useStyles = makeStyles(function (theme) { return ({
    floating: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 110,
        left: 'auto',
        position: 'fixed',
        zIndex: 1000,
    },
    floatingLink: {
        color: 'inherit',
    },
}); }, { name: 'RaCreateButton' });
CreateButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    onClick: PropTypes.func,
};
var enhance = onlyUpdateForKeys(['basePath', 'label', 'translate']);
export default enhance(CreateButton);
