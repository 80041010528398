import { osSupportLink, WebinarLink } from 'constants/links'
import _, { isEqual } from 'lodash'
import { CheckTermType } from 'myenergy/dialogs/checkout/types'
import { DesignData } from 'opensolar-sdk'
import { ProposalTemplateTypeEnum } from 'resources/proposalTemplates/types'
import appStorage from 'storage/appStorage'
import { MapDataTypes } from 'types/map'
import { ContactData, ContactDataTypeEnum } from 'types/projects'
import type { ProposalTemplateSettingsType } from 'types/proposalTemplate'
import type { ComponentTypes, ComponentTypesV2 } from 'types/selectComponent'
import { getOrgFromState } from './org'
export { getQueryVariable, parseQueryStringToDictionary } from './query'

export const ar12 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
export const ar6 = [0, 1, 2, 3, 4, 5]
export const ar4 = [0, 1, 2, 3]
export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const fullMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const bimonthlyNames = ['Jan-Feb', 'Mar-Apr', 'May-Jun', 'Jul-Aug', 'Sep-Oct', 'Nov-Dec']
export const quarterNamesInMonth = ['Jan-Mar', 'Mar-Jun', 'Jul-Sep', 'Oct-Dec']
export const quarterNames = ['1st Quarter', '2nd Quarter', '3rd Quarter', '4th Quarter']
export const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
export const getNumberWithOrdinal = (n) => {
  var s = ['th', 'st', 'nd', 'rd']
  var v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
export const DEFAULT_USAGE_DATA = { usage_data_source: 'Default' }

export const periodsPerYear = {
  weekly: 52,
  fortnightly: 26,
  bimonthly: 24,
  monthly: 12,
  every_second_month: 6,
  quarterly: 4,
  yearly: 1,
}

export const defaultDailyCurve = 'Evening Peak'

export const getFontColorBaseOnBackgroundColor = (backgroundColor) => {
  const asRgb = hexToRgbObj(backgroundColor)
  const rbgObject =
    backgroundColor[0] === '#'
      ? Object.keys(asRgb!).map((key) => asRgb![key])
      : backgroundColor.replace(/[^\d,]/g, '').split(',')
  return getFontColorBaseOnRgb(rbgObject[0], rbgObject[1], rbgObject[2])
}

export const getFontColorBaseOnRgb = (r, g, b) => {
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)'
}

export const hexToRgbObj = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export const sumArray = (values) => {
  return values.reduce((a, b) => a + b, 0)
}

export const meanArray = (values) => sumArray(values) / values.length

export const reloadEntireApp = (reloadPath?) => {
  console.warn('Reloading entire app')
  if (reloadPath) {
    window.location.href = window.location.origin + reloadPath
  } else if (window.RUNNING_AS_APP === true) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage('RELOAD_APP')
  } else {
    // Attempt to fix redirection bug, break most browsers.
    // setTimeout is required for Safari otherwise the reload() call seems to get ignored when we have
    // set location.href immediately before
    // setTimeout(() => {
    //   window.location.reload()
    // }, 100)

    // Reverted to previous version because otherwisde the redirection to homepage does not succeed because UX1 does
    // not have a route for /home so it redirects to /projects and breaks the redirection.
    window.location.reload()
  }
}

export const getUserId = () => {
  try {
    var auth = appStorage.getAuth()

    if (auth && auth.user) {
      return auth.user.id
    }
  } catch (err) {
    console.log('Error: getUserId()', err)
  }
  return null
}

export const getRoleId = () => {
  try {
    const auth = appStorage.getAuth()

    if (auth && auth.org_id && auth.roles) {
      return auth.roles.filter((r) => r.org_id === auth?.org_id)[0].id
    }
  } catch (err) {
    console.log('Error: getRoleId()', err)
  }
  return null
}

export const parseUsageJsonString = (usage_json_string: string) => {
  try {
    // Beware: JSON.parse(null) and JSON.parse(false) will not throw exceptions!
    // Ensure null/false fallback to default value
    if (usage_json_string) {
      return JSON.parse(usage_json_string) || DEFAULT_USAGE_DATA
    }
  } catch (err) {}
  return DEFAULT_USAGE_DATA
}

const countNotEmpty = (values) => values.filter((value) => Boolean(value) && value > 0).length

// THIS DOESN'T SEEM TO BE IN USE ANYWHERE
// export const descriptionForProjectUsageJson = (usage_json_string, country_iso2, usage_annual_or_guess) => {
//   try {
//     var data = parseUsageJsonString(usage_json_string)

//     // @TODO: Translation. Format rounded number with localized thousands separator
//     // (i.e. Use period in NL, use toLocaleString()?)
//     var estimateString = numberWithCommas(usage_annual_or_guess, true) + ' kWh/yr'

//     // @TODO: Translation required
//     switch (data.usage_data_source) {
//       case 'Estimate':
//         return data.values + ' for location: ' + estimateString
//       case 'kwh_monthly':
//         return 'Monthly kWh (' + countNotEmpty(data.values) + '/12) ' + estimateString
//       case 'kwh_daily_per_month':
//         return 'Daily kWh (' + countNotEmpty(data.values) + '/12) ' + estimateString
//       case 'bill_monthly':
//         return 'Monthly Bills (' + countNotEmpty(data.values) + '/12) ' + estimateString
//       case 'kwh_every_second_month':
//         return 'Bi-Monthly kWh (' + countNotEmpty(data.values) + '/6) ' + estimateString
//       case 'bill_every_second_month':
//         return 'Bi-Monthly Bills (' + countNotEmpty(data.values) + '/6) ' + estimateString
//       case 'kwh_quarterly':
//         return 'Quarterly kWh (' + countNotEmpty(data.values) + '/4) ' + estimateString
//       case 'bill_quarterly':
//         return 'Quarterly Bills (' + countNotEmpty(data.values) + '/4) ' + estimateString
//       case 'kwh_annual':
//         return 'Annual kWh ' + estimateString
//       case 'bill_annual':
//         return 'Annual Bill (' + currencySymbolForCountry(country_iso2) + data.values + ') ' + estimateString
//       case 'interval_60min':
//         return 'Hourly Interval: ' + Math.round(sumArray(data.values.split(','))) + ' kWh/yr'
//       case 'Default':
//       default:
//         return 'Default for location: ' + estimateString
//     }
//   } catch (err) {
//     return 'Unknown'
//   }
// }

// THIS DOESN'T SEEM TO BE IN USE ANYWHERE
// export const curvesForProjectUsageJson = (usage_json_string, msg = 'Default') => {
//   try {
//     var data = parseUsageJsonString(usage_json_string)

//     return {
//       weekday: data.curve_weekday ? data.curve_weekday : msg + ' (' + defaultDailyCurve + ')',
//       weekend: data.curve_weekend ? data.curve_weekend : msg + ' (' + defaultDailyCurve + ')',
//       scale_weekend:
//         data.scale_weekend && data.scale_weekend !== 1
//           ? (data.scale_weekend > 1 ? '+' : '') + Math.round((data.scale_weekend - 1.0) * 100) + ' % vs weekdays'
//           : msg + ' (Identical to weekdays)',
//     }
//   } catch (err) {
//     return 'Unknown'
//   }
// }

export const parsePostcoderPlace = (place) => {
  const parts: { [key: string]: string | number } = {}
  let address = place.summaryline.split(',')
  parts.address = address[0]
  parts.street_number = place.premise
  parts.route = place.uniquedeliverypointreferencenumber
  parts.zip = place.zip
  parts.locality = place.locality
  parts.country_iso2 = place.country_iso2
  parts.lat = parseFloat(place.lat)
  parts.lon = parseFloat(place.lon)
  return parts
}

export const parseGooglePlace = (place) => {
  const extractTextByClassName = (dom, query) => {
    const node = dom.getElementsByClassName(query)[0]
    return node?.innerText
  }

  const parts: { [key: string]: string | number } = {}

  //Removed: This seems to be included in Address lookups but no in reverse geocodes
  //parts['address'] = place['name']

  place.address_components.forEach((address_component) => {
    if (address_component.types.indexOf('street_number') !== -1) {
      parts['street_number'] = address_component['short_name']
    } else if (address_component.types.indexOf('route') !== -1) {
      parts['route'] = address_component['short_name']
    } else if (address_component.types.indexOf('postal_code') !== -1) {
      parts['zip'] = address_component['short_name']
    } else if (address_component.types.indexOf('locality') !== -1) {
      parts['locality'] = address_component['long_name']
    } else if (address_component.types.indexOf('administrative_area_level_1') !== -1) {
      parts['state'] = address_component['short_name']
    } else if (address_component.types.indexOf('country') !== -1) {
      parts['country_iso2'] = address_component['short_name']
    }
  })

  // adr_address has the full address with the proper format in HTML
  // for some addresses in Europe, the address conatins "." and ","
  // e.g. C. de Salvador de Madariaga, 11, 28027 Madrid, Spain
  // if no adr_address is available, we use the address_components to build the address
  // please see http://microformats.org/wiki/adr for more info about adr_address
  if (place.adr_address) {
    const parser = new DOMParser()
    const parsedDom = parser.parseFromString(place.adr_address, 'text/html')
    parts['address'] = extractTextByClassName(parsedDom, 'street-address')
  }

  if (!parts['address']) {
    if (parts['street_number'] && parts['route']) {
      parts['address'] = parts['street_number'] + ' ' + parts['route']
    } else if (parts['route']) {
      parts['address'] = parts['route']
    } else {
      parts['address'] = ''
    }
  }

  // Extra pass to populate locality from sublocality or sublocality_level_1 if not already set
  // e.g. 176 Harrisonville Road, Mullica Hill, NJ 08062, USA only includes sublocality/sublocality_level_1 but not locality
  if (!parts['locality']) {
    place.address_components.forEach((address_component) => {
      if (address_component.types.indexOf('sublocality') !== -1) {
        parts['locality'] = address_component['long_name']
      } else if (address_component.types.indexOf('sublocality_level_1') !== -1) {
        parts['locality'] = address_component['long_name']
      } else if (address_component.types.indexOf('postal_town') !== -1) {
        parts['locality'] = address_component['long_name']
      }
    })
  }

  if (place.geometry.location) {
    if (typeof place.geometry.location.lat === 'function') {
      parts.lat = place.geometry.location.lat()
    } else {
      parts.lat = place.geometry.location.lat
    }

    if (typeof place.geometry.location.lng === 'function') {
      parts.lon = place.geometry.location.lng()
    } else {
      parts.lon = place.geometry.location.lng
    }
  }

  return parts
}

export const ContactName = (contact) => {
  if (!contact) {
    return ''
  } else if (
    contact.first_name &&
    contact.first_name.length > 0 &&
    contact.family_name &&
    contact.family_name.length > 0
  ) {
    return contact.first_name + ' ' + contact.family_name
  } else if (contact.first_name && contact.first_name.length > 0) {
    return contact.first_name
  } else if (contact.family_name && contact.family_name.length > 0) {
    return contact.family_name
  } else if (contact.email && contact.email.length > 0) {
    return contact.email
  } else {
    return ''
  }
}

export const urlsToIds = (urls) => {
  return urls.map(function (url) {
    return urlToId(url)
  })
}

export const urlToId = (url) => {
  try {
    var id
    if (Number.isInteger(parseInt(url, 10))) {
      id = url
    } else {
      id = url.split('/').slice(-2, -1)[0]
    }
    return parseInt(id, 10)
  } catch (error) {
    return undefined
  }
}

/*
Sample usage:
        <SimpleForm
          defaultValue={function(data) {
            return pick(data, [
              'address',
              'zip',
              'locality',
            ])
          }}
        >
*/
export const pick = (object, keys) => {
  var result = {}
  keys.forEach(function (key) {
    result[key] = object[key]
  })
  return result
}

export const parseFloatOrNull = (value) => {
  if (value === '') {
    return null
  } else {
    try {
      return parseFloat(value)
    } catch (e) {
      return null
    }
  }
}

export const inputValueToInt = (value) => {
  if (value === '') {
    return 0
  } else {
    return parseInt(value, 10)
  }
}

export const parse_percentage_value = (v) => {
  if (v === '') return null
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }
  var x = v / 100
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const format_percentage_value = (v, places: number = 2) => {
  if (v === null) return ''
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  const multiplier = 10 ** places

  //work around for floating point number precision issue
  var x = Math.round(v * 100 * multiplier) / multiplier
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const round_number_to_specific_decimal_places = (v, places) => {
  if (isNaN(v)) return v
  const multiplier = 10 ** places
  return Math.round(v * multiplier) / multiplier
}

export const watt_to_kw = (v) => {
  //do not format it still typing...
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v / 1000
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const kw_to_watt = (v) => {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * 1000
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const mm_to_m = (v) => {
  //do not format it still typing...
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v / 1000
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const m_to_mm = (v) => {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * 1000
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const currencySymbolForCurrencyCode = (currencyCode) => {
  switch (currencyCode.toUpperCase()) {
    case 'GBP':
      return '£'
    case 'EUR':
      return '€'
    default:
      return '$'
  }
}

export const roundKwStc = (value) => {
  if (value < 10) {
    return value.toFixed(3)
  } else if (value >= 10 && value < 100) {
    return value.toFixed(2)
  } else if (value >= 100 && value < 1000) {
    return value.toFixed(1)
  } else {
    return Math.round(value)
  }
}

export const REGION_TO_COUNTRY_CODES = {
  EU: [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'GB',
  ],
  AMERICAS: ['US', 'CA', 'AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GY', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE'],
  AFRICA: [
    'DZ',
    'AO',
    'SH',
    'BJ',
    'BW',
    'BF',
    'BI',
    'CM',
    'CV',
    'CF',
    'TD',
    'KM',
    'CG',
    'CD',
    'DJ',
    'EG',
    'GQ',
    'ER',
    'SZ',
    'ET',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'CI',
    'KE',
    'LS',
    'LR',
    'LY',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'YT',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'ST',
    'RE',
    'RW',
    'ST',
    'SN',
    'SC',
    'SL',
    'SO',
    'ZA',
    'SS',
    'SH',
    'SD',
    'SZ',
    'TZ',
    'TG',
    'TN',
    'UG',
    'CD',
    'ZM',
    'TZ',
    'ZW',
  ],
  OCEANIA: ['AU', 'NZ'],
}

// To complete list use this ref and replace convert to iso2:
// https://gist.github.com/voskobovich/43f851859c23a8261514
export const currencySymbolForCountry = (countryCode) => {
  switch (countryCode) {
    case 'VN': // Vietnam
      return '₫'
    case 'DZ': // Algeria
      return 'DA'
    case 'TH': // Thailand
      return '฿'
    case 'NG': // Nigeria
      return '₦'
    case 'AW': // Aruba
    case 'AN': // Netherlands Antilles
      return 'ƒ'
    case 'BR': // Brazil
      return 'R$'
    case 'BW': // Botswana
      return 'P'
    case 'BG': // Bulgaria
      return 'Лв.'
    case 'MM': // Myanmar/Burma
      return 'K'
    case 'CN': // China
      return 'E£'
    case 'JP': // Japan
      return '¥'
    case 'CR': // Costa Rica
      return '₡'
    case 'HR': // Croatia
      return '€'
    case 'CZ': // Czech Republic
      return 'Kč'
    case 'CD': // DR Congo
      return 'FC'
    case 'ID': // Indonesia
      return 'Rp'
    case 'IN': // India
      return '₹'
    case 'PK': // Pakistan
    case 'NP': // Nepal
    case 'LK': // Sri Lanka
    case 'SC': // Seychelles
    case 'MU': // Mauritius
      return '₨'
    case 'PH': // Philippines
      return '₱'
    case 'GH': // Ghana
      return '₵'
    case 'GT': // Guatemala
      return 'Q'
    case 'HU': // Hungary
      return 'Ft '
    case 'IR': // Iran
      return '﷼'
    case 'IQ': // Iraq
      return 'د.ع'
    case 'IL': // Israel
      return '₪'
    case 'JO': // Jordan
      return 'JD'
    case 'KZ': // Kazakhstan
      return '₸'
    case 'KE': // Kenya
      return 'KSh'
    case 'KW': // Kuwait
      return 'KD'
    case 'LV': // Latvia
      return 'Ls'
    case 'MY': // Malaysia
      return 'RM'
    case 'MA': // Morocco
      return 'DH'
    case 'PA': // Panama
      return 'B/.'
    case 'PE': // Peru
      return 'PEN'
    case 'PL': // Poland
      return 'zł'
    case 'QA': // Qatar
      return 'QR'
    case 'RO': // Romania
      return 'lei'
    case 'RU': // Russia
      return '₽'
    case 'SA': // Saudi Arabia
      return 'SR'
    case 'RS': // Serbia
      return 'дин'
    // case 'CI': // Ivory Coast
    //   return 'CFA'
    case 'VU': // Vanuatu
      return 'VT'
    case 'CM': // Cameroon
      return 'FCFA'
    case 'ZA': // South Africa
      return 'R'
    case 'KR': // South Korea
      return '₩'
    case 'CH': // Switzerland
      return '₣'
    case 'TZ': // Tanzania
      return 'TSh'
    case 'TN': // Tunisia
      return 'DT'
    case 'TR': // Turkey
      return '₺'
    case 'AE': // UAE
      return 'DH'
    case 'UG': // Uganda
      return 'USh'
    case 'UA': // Ukraine
      return '₴'
    case 'ZM': // Zambia
      return 'K'
    case 'EG': // Egypt
      return 'E£'
    case 'UZ': // Uzbekistan
      return 'UZS'
    case 'NA': // Namibia
      return 'N$'
    case 'AO': // Angola
      return 'AOA'
    case 'ML': // Mali
    case 'NE': // Niger
    case 'SN': // Senegal
    case 'TG': // Togo
    case 'GW': // Guinea-Bissau
    case 'CI': // Cote d'Ivorie
    case 'BJ': // Benin
    case 'BF': // Burkina Faso
      return 'XOF'
    case 'GB': // United Kingdom
    case 'FK': // Falkland Island
    case 'GI': // Gibraltar
    case 'LB': // Lebanon
    case 'SH': // Saint Helena
    case 'SY': // Syria
    case 'IM': // Isle of Man
    case 'GG': // Guernsey
    case 'JE': // Jersey
      return '£'
    case 'SE': // Sweden
      return 'SEK '
    case 'DK': // Denmark
    case 'NO': // Norway
    case 'IS': // Iceland
      return 'kr'
    case 'AZ': // Azerbaijan
      return '₼'
    case 'BH': // Bahrain
      return 'BD'
    case 'BD': // Bangladesh
      return 'Tk'
    case 'BB': // Barbados
      return '$'
    case 'BY': // Belarus
      return 'p'
    case 'BO': // BOLIVIA
      return '$b'
    case 'MG': // Madagascar
      return 'Ar'
    case 'AT': // Austria
    case 'BE': // Belgium
    case 'CY': // Cyprus
    case 'EE': // Estonia
    case 'FI': // Finland
    case 'FR': // France
    case 'DE': // Germany
    case 'GR': // Greece
    case 'IE': // Ireland
    case 'IT': // Italy
    case 'LT': // Lithuania
    case 'LU': // Luxembourg
    case 'MT': // Malta
    case 'NL': // Netherlands
    case 'PT': // Portugal
    case 'SK': // Slovakia
    case 'SI': // Slovenia
    case 'ES': // Spain
      return '€'
    default:
      return '$'
  }
}

export const colorIsLightOrDark = (hexColor) => {
  //if only first half of color is defined, repeat it
  if (hexColor.length < 5) {
    hexColor += hexColor.slice(1)
  }
  return hexColor.replace('#', '0x') > 0xffffff * 0.8 ? 'light' : 'dark'
}

export const addUpdateCssRule = (selectorText, ruleBody) => {
  // e.g. selectorText = '.BillSavingsChart .NewBill .ct-bar'
  // ruleBody =  '{ stroke: #0F0; }'
  //
  // Notice: This works around CORS access/update issues when updating a CSS file stored
  // on filesystem, e.g. when app is embedded on iOS filesystem.
  // This method is not subject to this constraint and can be used to both
  // add a new rule and update it too.

  var style = document.getElementById(selectorText)

  if (style) {
    style.innerHTML = selectorText + ' ' + ruleBody
  } else {
    style = document.createElement('style')
    // @ts-ignore TODO: .type is not an attribute on DOMElement
    style.type = 'text/css'

    //tag with id in case we want to update it again later
    style.id = selectorText
    style.innerHTML = selectorText + ruleBody
    document.getElementsByTagName('head')[0].appendChild(style)
  }
}

export const SystemsDataFromDesignDataJsonString = (designDataJsonString) => {
  try {
    // @ts-ignore TODO: types for window.CompressionHelper
    const designData = window.CompressionHelper.decompress(designDataJsonString, true)
    if (!designData) {
      return []
    } else {
      return designData.object.children
        .filter(function (c) {
          return c['type'] === 'OsSystem'
        })
        .map(function (s) {
          return { ...s.userData, children: s.children }
        })
        .sort((a, b) => a.order - b.order)
    }
  } catch (err) {
    //console.warn(err)
    return []
  }
}

export const SystemsDataFromDesignData = (designData: DesignData) => {
  try {
    // @ts-ignore TODO: types for window.CompressionHelper
    if (!designData) {
      return []
    } else {
      return designData.object.children
        .filter(function (c) {
          return c['type'] === 'OsSystem'
        })
        .map(function (s) {
          return { ...s.userData, children: s.children }
        })
        .sort((a, b) => a.order - b.order)
    }
  } catch (err) {
    //console.warn(err)
    return []
  }
}

export const isTariffMissing = (selectedProject) =>
  !selectedProject.utility_tariff_current && !selectedProject.utility_tariff_current_custom

export const tariffNotSuppliedText = (utility_tariff_or_guess, translate) => {
  return utility_tariff_or_guess && utility_tariff_or_guess.name && utility_tariff_or_guess.name.length > 0
    ? translate('Tariff not specified') +
        ', ' +
        translate('using') +
        '&nbsp;' +
        utility_tariff_or_guess.name +
        '&nbsp;' +
        translate('based on location.')
    : translate('Tariff not specified, unable to find a default tariff for this location.')
}

export const tariffNotSuppliedTextShort = (translate) => translate('Tariff not specified, using estimate.')

export const incentivesAsString = (incentive_to_installer, translate, currencySymbol) =>
  Object.keys(incentive_to_installer)
    .filter((key) => key !== 'total' && incentive_to_installer[key] > 0)
    .map(
      (key) =>
        'content.incentives.' +
        key +
        '.title' +
        ' ' +
        currencySymbol +
        Math.round(incentive_to_installer[key]).toLocaleString()
    )
    .join(', ')

export const hasIncentivesToInstaller = (selectedSystem) =>
  Boolean(selectedSystem.pricing.incentive_to_installer && selectedSystem.pricing.incentive_to_installer['total'] > 0)

export const getLabel = (value, choices, translate) => {
  for (var i = 0; i < choices.length; i++) {
    if (choices[i].id === value) {
      return translate ? translate(choices[i].name) : choices[i].name
    }
  }
  return null
}

export const getIdForLabel = (label, choices) => {
  for (var i = 0; i < choices.length; i++) {
    if (choices[i].name === label) {
      return choices[i].id
    }
  }
  return null
}

export const titleCase = (str) => str && str.replace(/\b\S/g, (t) => t.toUpperCase())

export const capitalize = (input) => input.charAt(0).toUpperCase() + input.slice(1)

export const renderRawHtmlEditor = (value) => value && value.indexOf('__USE_RAW_HTML__') !== -1

export const renderDraftEditor = (value) => !value || value.indexOf('__USE_RAW_HTML__') === -1

export const dateParser = (date: Date) => {
  if (!date) {
    return null
  }
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  return [year, month, day].join('-')
}

export const dateParserYYYYMMDD = (utc: Date | null, useUSFormat: boolean = false) => {
  const zeroPad = (input) => {
    if (String(input).length === 1) {
      return '0' + String(input)
    } else {
      return String(input)
    }
  }

  // Adapted from dateParser but with leading zeroes
  if (!utc) {
    return null
  }
  const localTime = new Date(utc)
  if (isNaN(localTime.getTime())) return null
  let year = localTime.getFullYear()
  let month = localTime.getMonth() + 1
  let day = localTime.getDate()

  return [year, zeroPad(month), zeroPad(day)].join('-')
}

export const convertLocaleName = (locale) => {
  if (!locale || !locale.includes) {
    return locale
  } else if (!locale.includes('_')) {
    return locale
  } else {
    const parts = locale.split('_')
    return `${parts[0]}-${parts[1]}`
  }
}

export const formatDateString = (dateString) => {
  if (!dateString) return ''
  const parts = dateString.split('-')
  const year = parts[0]
  const month = parts[1] - 1
  // To accept ISO format datetimes we may need to strip off the time component which is delimited by "T"
  const day = parts[2].split('T')[0]
  const date = new Date(year, month, day)
  let locale = appStorage.getLocale() || 'en-AU'

  // by default use en-AU for date time formatting
  if (locale === 'en') {
    locale = 'en-AU'
  }
  const formatLocale = convertLocaleName(locale)
  return new Intl.DateTimeFormat(formatLocale).format(date)
}

export const parseDateFromYYYYMMDD = (input) => {
  // parse a date in yyyy-mm-dd format
  var parts = input.split('-')
  // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]) // Note: months are 0-based
}

export const formatTimeStringBaseOnLocale = (utc, convertDate = false) => {
  if (convertDate) utc += ' UTC' // should ideally be applied for all function uses
  const createdTime = new Date(utc)
  const locale = appStorage.getLocale()
  const formatLocale = locale === 'en' ? 'en-AU' : locale?.replace('_', '-').toUpperCase()
  return createdTime.toLocaleString(formatLocale)
}

export const formatTimeString = (utc) => {
  const createdTime = new Date(utc)
  return (
    createdTime.getFullYear() +
    '-' +
    (createdTime.getMonth() + 1) +
    '-' +
    createdTime.getDate() +
    ' ' +
    createdTime.getHours() +
    ':' +
    createdTime.getMinutes() +
    ':' +
    createdTime.getSeconds()
  )
}

export const formatDateStringBaseOnLocale = (utc) => {
  const createdDate = new Date(utc)
  const locale = appStorage.getLocale()
  const formatLocale = locale === 'en' ? 'en-AU' : locale?.replace('_', '-').toUpperCase()
  const activityDate = createdDate.toDateString()
  // @ts-ignore TODO: activityDate is a string, not a Date.
  return activityDate.toLocaleString(formatLocale).replace(/^\S+\s/, '')
}

export const formatCurrency = (value) =>
  (value ? value : 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const formatInt = (value, locale?: string) =>
  (value ? value : 0).toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

export const clone = (obj) => JSON.parse(JSON.stringify(obj))

export const getRange = (size) => Array.from({ length: size }, (v, k) => k + 1)

//inclsive of from and to
export const getRangeFromTo = (from, to) => Array.from({ length: to - from + 1 }, (v, k) => k + from)
export const getRangeFromToWrapped = (from, to, itemsToWrap) => [
  ...getRangeFromTo(from + itemsToWrap, to),
  ...getRangeFromTo(from, from + itemsToWrap - 1),
]

export const getSystemSold = (record) => {
  var systems =
    record.systems && record.systems.length ? record.systems.filter((s) => s.url === record.system_sold) : []
  if (systems.length > 0) {
    return systems[0]
  } else {
    return null
  }
}

export const getPaymentOptionSold = (record) => {
  try {
    var payment_options =
      record.systems && record.systems.length
        ? record.systems
            .filter((s) => s.url === record.system_sold)[0]
            .payment_options.filter((po) => po.url === record.payment_option_sold)
        : []
    if (payment_options.length > 0) {
      return payment_options[0]
    }
  } catch (err) {}
  return null
}

export const getSystemInstalled = (record) => {
  var systems =
    record.systems && record.systems.length ? record.systems.filter((s) => s.url === record.system_installed) : []
  if (systems.length > 0) {
    return systems[0]
  } else {
    return null
  }
}

export const getTemplateSettings = (proposalTemplateSettings, key) =>
  proposalTemplateSettings && proposalTemplateSettings[key] && proposalTemplateSettings[key].length > 0
    ? proposalTemplateSettings[key].split(',')
    : []

export const systemNameOrDefault = (system) => {
  if (system.name && system.name.length > 0) {
    return system.name
  } else if (system.battery_total_kwh > 0) {
    if (system.module_quantity > 0) {
      return system.module_quantity + ' ' + window.translate('Panels + Battery')
    } else {
      return system.battery_total_kwh + ' ' + window.translate('kWh Battery')
    }
  } else {
    return system.module_quantity + ' ' + window.translate('Panels')
  }
}

export const systemNameOrDefaultWithSize = (systemData, opts) => {
  if (!systemData) {
    return 'Unknown System'
  } else {
    return (
      (systemData.name
        ? systemData.name
        : systemData.module_quantity + ' Panels System (' + systemData.kw_stc + ' kW)') + (opts?.choiceSuffix || '')
    )
  }
}

export const paymentOptionNameOrDefault = (paymentOptionData) =>
  paymentOptionData && paymentOptionData.title ? paymentOptionData.title : 'Untitled'

export const numberWithCommas = (x, round) =>
  (round === true ? Math.round(x) : x.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const generateDefaultValueFunc = (fields) => {
  // Return a function with fields embedded as closure
  return function (data) {
    return pick(data, fields)
  }
}

// help prevent scrolling of body on mobile device
export const DialogHelper = (function () {
  let top
  return {
    afterOpen: function (trackEventName?) {
      // record the position of body when dialog open
      window.isDialogHelperActived = true
      top = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      document.body.style.cssText = `position: fixed;width: 100%;top: ${-top}px;`

      if (trackEventName) {
        window.GA4?.recordEvent(trackEventName)
      }
    },
    beforeClose: function (specifiedTop?) {
      // reposition body when dialog close
      window.isDialogHelperActived = false
      const documentElement = document.scrollingElement || document.documentElement || document.body
      document.body.style.cssText = ''
      documentElement.scrollTop = specifiedTop || top
    },
  }
})()

export const toYearsAndMonths = (years_decimal) => {
  return {
    years: Math.floor(years_decimal),
    months: Math.round((years_decimal - Math.floor(years_decimal)) * 12),
  }
}

export const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const monthlyToDailyPerMonth = (monthlyValues) => monthlyValues.map((v, i) => v / daysInMonth[i])

export const toCapitalise = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str
}

export const measurementsToUnitLabel = (measurements) => (measurements === 'imperial' ? 'ft' : 'm')

export const trimDecimalPlaces = (v, places) => {
  if (isNaN(v)) {
    return v
  } else {
    return parseFloat(v.toFixed(places).toString())
  }
}

export const formatDecimalPlaces = (v, places) => {
  if (isNaN(v)) {
    return (0).toFixed(places).toString()
  } else if (places === null) {
    // Use places=null to skip rounding
    return v
  } else {
    return v.toFixed(places).toString()
  }
}

export const formatCurrencyNumber = (value, places, locale?: string) => {
  if (!places && places !== 0) {
    places = 2
  }
  locale = convertLocaleName(locale)

  return new Intl.NumberFormat(locale, { minimumFractionDigits: places, maximumFractionDigits: places }).format(
    value ? value : 0
  )
}

export const formatCurrencyWithSymbol = (value, currencySymbol, locale?, places?) => {
  if (currencySymbol === '€') {
    if (['en', 'en-us'].includes(locale)) {
      // Beware language us lowercase like en-us not en-US
      return '€' + formatCurrencyNumber(value, places, locale)
    } else {
      return formatCurrencyNumber(value, places, locale) + ' €'
    }
  } else {
    return currencySymbol + formatCurrencyNumber(value, places, locale)
  }
}

export const feetToMeters = (v) => {
  //do not format it still typing...
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v / FEET_PER_METER
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const celsiusToFahrenheit = (v) => {
  return v * 1.8 + 32
}

export const fahrenheitToCelsius = (v) => {
  return (v - 32) / 1.8
}

export const METERS_PER_INCH = 0.0254
export const INCHES_PER_METER = 39.3701
export const FEET_PER_METER = 3.28084
export const METERS_PER_FOOT = 0.3048

export const metersToFeet = (v) => {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * FEET_PER_METER
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const metersToFeetSquared = (v) => {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * 10.7639
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const kmToMiles = (v) => {
  //do not format it still typing...
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v / 1.60934
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

export const milesToKm = (v) => {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * 1.60934
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

// @DEPRECATED: please use `useSelector(orgSelectors.getMeasurementUnits)` wherever possible
export const getMeasurementsFromState = (state) => {
  var org = getOrgFromState(state)
  if (org && org.measurement_units) {
    return org.measurement_units
  } else if (org && org.country && org.country.iso2 && org.country.iso2 === 'US') {
    return 'imperial'
  } else {
    return 'metric'
  }
}

export const getRoleFromState = (state) => {
  if (state.auth && state.auth.org_id) {
    try {
      return state.auth.roles.filter((role) => role.org_id === state.auth.org_id)[0]
    } catch (e) {
      //pass
    }
  }
  return null
}

export const getRoleIdFromState = (state) => {
  const role = getRoleFromState(state)
  return role?.id
}

export const formatVideoUrl = (rawVideoUrl) => {
  if (rawVideoUrl.indexOf('https://www.youtube.com') !== -1) {
    return rawVideoUrl.split('https://www.youtube.com/watch?v=')[1]
  } else if (rawVideoUrl.indexOf('https://youtu.be/') !== -1) {
    return rawVideoUrl.split('https://youtu.be/')[1]
  } else {
    return null
  }
}

export const isIE = () => {
  // @ts-ignore We do not have types for window.ActiveXObject
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  } else {
    return false
  }
}

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k))
    else acc[pre + k] = obj[k]
    return acc
  }, {})

export const taxNameForCountryIso2 = (country_iso2: string | undefined) => {
  if (country_iso2 === 'AU' || country_iso2 === 'NZ') {
    return 'GST'
  } else if (country_iso2 === 'ZA' || country_iso2 === 'GB') {
    return 'VAT'
  } else {
    return 'tax'
  }
}

const taxRateDefault = {
  AU: 10,
  NZ: 15,
  DEFAULT: 0.0,
}

export const taxRateDefaultForCountryIso2 = (country_iso2) =>
  taxRateDefault.hasOwnProperty(country_iso2) ? taxRateDefault[country_iso2] : taxRateDefault.DEFAULT

export const countryIso2FromProposalData = (proposalData) =>
  proposalData ? countryIso2FromSelectedProject(proposalData.selectedProject) : null

export const countryIso2FromSelectedProject = (selectedProject) =>
  selectedProject && selectedProject.proposal_data && selectedProject.proposal_data.project
    ? selectedProject.proposal_data.project.country_iso2
    : null

export const hasSelectedSystem = function () {
  return window.editor && window.editor.selectedSystem
}

export const hasScene = function () {
  return window.editor && window.editor.scene
}

export const apiVersionCompatibility = (actualVersion, requiredVersion) => {
  // supported: 4
  // patch: 3
  // minor: 2
  // major: 1

  if (!actualVersion || !requiredVersion) {
    console.debug('API version check failed: ', actualVersion, requiredVersion)
    return 4
  }

  if (requiredVersion === actualVersion) {
    return 4
  }

  var requiredApiVersionParts = requiredVersion.split('.').map(function (value) {
    return parseInt(value)
  })
  var actualApiVersionParts = actualVersion.split('.').map(function (value) {
    return parseInt(value)
  })
  if (actualApiVersionParts[0] < requiredApiVersionParts[0]) {
    return 1 // major
  } else if (actualApiVersionParts[1] < requiredApiVersionParts[1]) {
    return 2 // minor
  } else {
    //supported
    //patch updates do not require notification of an optional update
    return 4
  }
}

const COMPATIBILITY_TO_MISMATCH_TYPE = {
  1: 'mandatory',
  2: 'optional',
  3: null, //patch updates do not require notification of an optional update
  4: null,
}

export const apiVersionMismatchType = (actualVersion, requiredVersion) => {
  const apiCompatibility = apiVersionCompatibility(actualVersion, requiredVersion)
  return COMPATIBILITY_TO_MISMATCH_TYPE[apiCompatibility]
}

export const getPmtOptionHasEditableQuoteTable = (pmtOption) => {
  if (!pmtOption || !pmtOption.configuration_json) return true
  let configuration_json = JSON.parse(pmtOption.configuration_json)
  //disable ability to customize quotation table if the payment option is integrated
  return (
    !configuration_json ||
    !['sunlight', 'dividend', 'mosaic', 'sungage', 'loanpal', 'phoenix'].includes(configuration_json.integration)
  )
}

export const parseCommaSeperatedStates = (rawVal, translateFn) => {
  if (!rawVal || !translateFn) return ''
  let localizedAnd = translateFn('and')
  return rawVal
    ?.split(',')
    ?.map((state, i, arr) => {
      let addLeadingAnd = i > 0 && i === arr.length - 1
      let addLeadingComma = !addLeadingAnd && i > 0
      let prefix = `${addLeadingAnd ? ` ${localizedAnd} ` : ''}${addLeadingComma ? ', ' : ''}`
      return `${prefix}${state}`
    })
    .join('')
}

//creating a sunlight prequal requires a contact with valid email, and a 10 digit phone num
export const getIsValidSunlightPrequalContact = (con) => {
  if (!con || !con.email || !con.phone || !con.first_name || !con.family_name) return false
  let isValid = false
  if (typeof con.phone === 'string' && con.phone.length >= 10) {
    isValid = con.phone.replace(/\D/g, '').length === 10
  }
  return isValid
}

export const getIsValidSunlightCreditAppContact = (con) => {
  if (!con || !con.first_name || !con.family_name || !con.email) return false
  else return true
}

export const getIsValidEmail = (raw) => {
  if (!raw) return false
  else return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(raw)
}

export const JSONstringifySortedKeys = (obj, space = undefined) => {
  const allKeys: string[] = []
  var seen = {}
  JSON.stringify(obj, function (key, value) {
    if (!(key in seen)) {
      allKeys.push(key)
      seen[key] = null
    }
    return value
  })
  allKeys.sort()
  return JSON.stringify(obj, allKeys, space)
}

export const getMapTypeKey = (mapType: MapDataTypes | null | undefined) => {
  if (!mapType) return ''
  const clone: any = {
    ...mapType,
  }
  delete clone['variation_data']
  delete clone['score']
  return JSONstringifySortedKeys(clone)
}

export const labelForImagery = (mapType: string, variation_name?: string) => {
  switch (mapType) {
    case 'Google':
      return 'Google Oblique'
    case 'GoogleTop':
      return 'Google'
    case 'GoogleRoadMap':
      return 'Google Road'
    case 'IgnOrtofotos':
      return 'IGN Ortofotos'
    case 'IgnPotential':
      return 'IGN Solar Potential'
    case 'IgnMedian':
      return 'IGN Solar Media de la Propiedad'
    case 'IgnCatastral':
      return 'IGN Catastral'
    case 'Nearmap':
      return variation_name ? 'Nearmap ' + variation_name : 'Nearmap Vertical'
    case 'Nearmap3D':
      return variation_name ? 'Nearmap3D ' + variation_name : 'Nearmap3D'
    case 'None':
      return variation_name ? variation_name : mapType
    case 'EagleViewInform':
      return variation_name ? variation_name : mapType
    case 'Google3D':
      return variation_name ? variation_name : mapType
    case 'MetroMap':
      return variation_name ? 'MetroMap ' + variation_name : mapType
    default:
      return mapType
  }
}

export const loadStripeIfNotFound = () => {
  if (!window.Stripe) {
    var script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/'
    document.head.appendChild(script)
  }
}

export const getIsDecisionApproval = (integrationDecision: string) => {
  const APPROVAL_STATUS_OPTIONS = [
    'Credit Expired',
    'Project Withdrawn',
    'Pending Loan Docs',
    'Project Completed',
    'M2',
    'M0',
    'M1',
    'Permit',
    'LoanOfferSubmitted',
    'ConditionallyApproved',
    'ApplicationApproved',
    'ConditionallyApprovedApplicationReceived',
    'Approved',
    'Approved for reduced loan amount',
    'Conditionally approved',
    'Installer paid',
    'PreContractualDocumentsGenerated',
    'FinalLoanContractCompleted',
    'BankDetailsCompleted',
    'ConfirmationOfInstallation',
    'Application withdrawn',
    'LoanDrawdownProcessed',
    'Conditionally approved',
    'Conditionally approved for reduced loan amount',
  ]
  return integrationDecision && APPROVAL_STATUS_OPTIONS.includes(integrationDecision)
}
// used for amplitude. Different companies use different codes but we should map them to common ones for reporting purposes
export const getCommonCreditDecision = (integrationDecision) => {
  switch (integrationDecision) {
    case 'APPROVED':
      return 'approved'
    case 'Approved':
      return 'approved'
    case 'ApprovedPendingDocs':
      return 'approved'
    case 'ApprovedDocsComplete':
      return 'approved'
    case 'PrequalApproved':
      return 'approved'
    case 'Auto Approved':
      return 'approved'
    case 'Pass':
      return 'approved'
    case 'pass':
      return 'approved'
    case 'Pending Loan Docs':
      return 'approved'
    case 'DECLINED':
      return 'declined'
    case 'Declined':
      return 'declined'
    case 'Declined - Credit Report Not Found':
      return 'Frozen File'
    case 'Declined - Credit Report Frozen':
      return 'No Hit'
    case 'Credit Declined':
      return 'declined'
    case 'PrequalDeclined':
      return 'declined'
    case 'Auto Declined':
      return 'declined'
    case 'PENDING':
      return 'pending'
    case 'Pending':
      return 'pending'
    case 'Pending decision':
      return 'pending'
    case 'Under review':
      return 'pending'
    case 'Pending Review':
      return 'pending'
    case 'PrequalPending':
      return 'pending'
    case 'CONDITIONAL':
      return 'approved with stips'
    case 'Conditionally approved':
      return 'approved with stips'
    case 'ApprovedWithStipulations':
      return 'approved with stips'
    case 'APPROVED_COUNTER_OFFER':
      return 'approved with counter offer'
    case 'Approved for reduced loan amount':
      return 'approved with counter offer'
    case 'Conditionally approved for reduced loan amount':
      return 'approved with stips for reduced loan amount'
    default:
      return integrationDecision
  }
}

/*
Used to indicate phrases this should not be translated to ensure they are not changed to use translate() and
added to translation files. Mostly for features which are in a single language only to avoid translating them
for no reason.
*/
let showTranslationIcons: boolean | null | undefined = null
export const doNotTranslate = (value) => {
  if (showTranslationIcons === null) showTranslationIcons = appStorage.getBool('showTranslationIcons') // Delay first load of this until switched to LS
  return showTranslationIcons ? '☑️ ' + value : value
}

export const parseJsonSafe = (jsonString, defaultValue?: unknown) => {
  if (defaultValue === undefined) {
    defaultValue = {}
  }

  /*
  If empty or invalid json just return empty object
  */
  if (jsonString) {
    try {
      return JSON.parse(jsonString)
    } catch (e) {
      console.warn(e)
    }
  }
  return defaultValue
}

export const getIntegrationsStringFromActions = (available_actions, system_uuid = undefined) => {
  try {
    const integrations: string[] = []
    available_actions?.forEach((sys) => {
      if (!system_uuid || sys.system_uuid === system_uuid) {
        sys?.actions_available?.forEach((act) => {
          if (act.payment_method === 'mosaic_application' && !integrations?.includes('mosaic'))
            integrations.push('mosaic')
          else if (act.payment_method === 'sungage_application' && !integrations?.includes('sungage'))
            integrations.push('sungage')
          else if (act.payment_method === 'phoenix_application' && !integrations?.includes('phoenix'))
            integrations.push('phoenix')
          else if (act.payment_method === 'commonbond_application' && !integrations?.includes('commonbond'))
            integrations.push('commonbond')
          else if (act.payment_method === 'loanpal_application' && !integrations?.includes('loanpal'))
            integrations.push('loanpal')
          else if (act.payment_method === 'brighte_application' && !integrations?.includes('brighte'))
            integrations.push('brighte')
          else if (act.payment_method === 'energy_ease_application' && !integrations?.includes('energy_ease'))
            integrations.push('energy_ease')
          else if (act.payment_method === 'plenti_loan_application' && !integrations?.includes('plenti_loan'))
            integrations.push('plenti_loan')
          else if (act.payment_method === 'plenti_bnpl_application' && !integrations?.includes('plenti_bnpl'))
            integrations.push('plenti_bnpl')
          else if (act.payment_method === 'sunlight_loan_application' && !integrations?.includes('sunlight_loan'))
            integrations.push('sunlight_loan')
          else if (act.payment_method === 'sunlight_loan_prequal' && !integrations?.includes('sunlight_prequal'))
            integrations.push('sunlight_prequal')
          else if (act.payment_method === 'dividend_application' && !integrations?.includes('dividend'))
            integrations.push('dividend')
        })
      }
    })
    return integrations?.length > 0 ? integrations.join(';') : 'none'
  } catch (ex) {
    return 'none'
  }
}

export const parseIntegrationJson = (integration_json_or_string) => {
  /*
  Only JSON.parse() if not already parsed
  */
  if (integration_json_or_string && integration_json_or_string.constructor.name === 'String') {
    return JSON.parse(integration_json_or_string)
  } else {
    return integration_json_or_string
  }
}

export const detectProposalV2ProposalTemplate = (proposalTemplateSettings?: ProposalTemplateSettingsType) => {
  return {
    isDetected: !!(proposalTemplateSettings && proposalTemplateSettings.type === ProposalTemplateTypeEnum.proposalV2),
    showHeader: false,
    showSidebar: false,
    showButtonsForSinglePaymentOption: false,
    hideBottomToolbar: true,
  }
}

export const detectBespokeProposalTemplate = (proposalTemplateSettings) => {
  var isBespoke = !!proposalTemplateSettings?.bespoke_proposal_url
  var showHeader = !isBespoke
  var showSidebar = isBespoke ? proposalTemplateSettings?.bespoke_proposal_sidebar : true
  var showButtonsForSinglePaymentOption = !isBespoke

  return {
    isDetected: isBespoke,
    showHeader,
    showSidebar,
    showButtonsForSinglePaymentOption,
    hideBottomToolbar: false,
  }
}

export const detectIframeProposalTemplate = (proposalTemplateSettings) => {
  const bespokeConfig = detectBespokeProposalTemplate(proposalTemplateSettings)
  const proposalV2Config = detectProposalV2ProposalTemplate(proposalTemplateSettings)
  return {
    isProposalV2: proposalV2Config.isDetected,
    isBespoke: bespokeConfig.isDetected,
    isIframe: proposalV2Config.isDetected || bespokeConfig.isDetected,
    showHeader: proposalV2Config.isDetected ? proposalV2Config.showHeader : bespokeConfig.showHeader,
    showSidebar: proposalV2Config.isDetected ? proposalV2Config.showSidebar : bespokeConfig.showSidebar,
    showButtonsForSinglePaymentOption: proposalV2Config.isDetected
      ? proposalV2Config.showButtonsForSinglePaymentOption
      : bespokeConfig.showButtonsForSinglePaymentOption,
    hideBottomToolbar: proposalV2Config.isDetected
      ? proposalV2Config.hideBottomToolbar
      : bespokeConfig.hideBottomToolbar,
  }
}

export const mapComponentTypesV2ToV1 = (componentTypeV2: ComponentTypesV2): ComponentTypes => {
  switch (componentTypeV2) {
    case 'module':
      return 'module'
    case 'inverter':
      return 'inverter'
    case 'battery':
      return 'battery'
    default:
      return 'other'
  }
}

export const mapComponentTypesV1ToV2 = (componentTypeV1?: ComponentTypes): ComponentTypesV2 => {
  switch (componentTypeV1) {
    case 'module':
      return 'module'
    case 'inverter':
      return 'inverter'
    case 'battery':
      return 'battery'
    default:
      return 'general'
  }
}

export const semiRandomFloatFromSeed = (seed) => {
  /*
  Simplistic method to generate a predictable random-ish float based on a seed value.
  e.g. Useful for fuzzing lat/lon always by the same specific amount based on a projectId
  */
  var modulo = 1000
  // @ts-ignore TODO: this is making unsafe assumptions about JS handling of numbers in strings.
  var str = `${((2 ** 31 - 1) & Math.imul(48271, seed)) / 2 ** 31}`.split('').slice(-10).join('') % modulo

  return str / modulo
}

export const randomLonLatOffsetForPrivacy = (seed) => {
  // Seed must be a float or int
  const maxOffsetInDegrees = 0.001
  return [
    (semiRandomFloatFromSeed(seed + 1) - 0.5) * 2 * maxOffsetInDegrees,
    (semiRandomFloatFromSeed(seed + 2) - 0.5) * 2 * maxOffsetInDegrees,
  ]
}

export const removeArchivedFromPmtName = (rawName) => {
  try {
    let withoutArchive = rawName
    if (withoutArchive?.includes('(archived')) {
      withoutArchive = withoutArchive.substring(0, withoutArchive.indexOf('(archived'))
    }
    if (withoutArchive?.includes('(Archived')) {
      withoutArchive = withoutArchive.substring(0, withoutArchive.indexOf('(Archived'))
    }
    return withoutArchive
  } catch {}
  return rawName
}

const components = ['inverters', 'batteries', 'others']
export const getExhibitTerms = (data) => {
  const exhibitTerms: any[] = []
  //find in module
  if (!!data?.module?.component_content?.quote_acceptance_content) {
    exhibitTerms.push(data.module)
  }
  //find in other components
  for (var i = 0; i < 3; i++) {
    var exhibitChecks = data[components[i]].filter((x) => !!x.component_content.quote_acceptance_content)
    if (exhibitChecks.length > 0) {
      for (var j = 0; j < exhibitChecks.length; j++) {
        exhibitTerms.push(exhibitChecks[j])
      }
    }
  }
  return exhibitTerms
}

const domParser = new DOMParser()

export const compileTerms = (quoteContent, exhibitQuoteContent, paymentOptionQuoteContent) => {
  const compiledTerms: CheckTermType[] = []
  if (quoteContent.length > 0) {
    quoteContent.forEach((x) => {
      compiledTerms.push({
        source: 'standard',
        content: x,
        checked: false,
        isHtml: true,
      })
    })
  }

  if (exhibitQuoteContent.length > 0) {
    exhibitQuoteContent.forEach((x) => {
      const content = x.component_content.quote_acceptance_content
      let contentList: string[]
      let isHtml = false
      try {
        // Attempt to parse the custom content as html
        // If this works, return one term per root level element
        const parsed = domParser.parseFromString(content, 'text/html')
        isHtml = true
        if (parsed.body.children?.length > 1) {
          contentList = []
          for (var i = 0; i < parsed.body.children.length; i++) {
            const el = parsed.body.children[i]
            contentList.push(el.outerHTML)
          }
        } else {
          contentList = [content]
        }
      } catch (e) {
        contentList = [content]
      }

      contentList.forEach((content) => {
        compiledTerms.push({
          source: x.code,
          content: content,
          checked: false,
          isHtml,
        })
      })
    })
  }

  if (paymentOptionQuoteContent && paymentOptionQuoteContent.length > 0) {
    paymentOptionQuoteContent.forEach((message) => {
      compiledTerms.push({ source: 'standard', content: message.message, checked: false, isHtml: true })
    })
  }
  return compiledTerms
}

export const getExhibitsByAutomationKey = (data, automationKey) => {
  const exhibits = {
    module: [] as any[],
    batteries: [] as any[],
    inverters: [] as any[],
    others: [] as any[],
  }
  if (!data || !automationKey) return exhibits

  if (data.module.component_content?.automation && data.module.component_content.automation === automationKey) {
    exhibits['module'].push(data.module.code)
  }

  for (var i = 0; i < 3; i++) {
    var exhibitChecks = data[components[i]].filter(
      (x) => x.component_content.automation && x.component_content.automation === automationKey
    )
    if (exhibitChecks.length > 0) {
      for (var j = 0; j < exhibitChecks.length; j++) {
        exhibits[components[i]].push(exhibitChecks[j].code)
      }
    }
  }

  return exhibits
}

export const getHelpCenterByLocale = (locale: string) => {
  if (osSupportLink.locale.includes(locale)) {
    return osSupportLink.url + `/hc/${locale}/`
  }
  return osSupportLink.url
}

export const getContactUsLinkByLocale = (locale: string) => {
  if (!osSupportLink.locale.includes(locale)) {
    locale = 'en-us'
  }
  return osSupportLink.url + `/hc/${locale}/requests/new`
}

export const getCommunityLinkByLocale = (locale: string) => {
  if (!osSupportLink.locale.includes(locale)) {
    locale = 'en-us'
  }
  return osSupportLink.url + `/hc/${locale}/community/topics`
}

export const getWebinarLinkByCountry = (country_iso2: string) => {
  if (country_iso2 === 'US') {
    return WebinarLink + '-usa'
  } else if (country_iso2 === 'GB') {
    return WebinarLink + '-uk'
  } else {
    return WebinarLink
  }
}

export const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now() //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const getNMReportsLink = () => {
  return `https://apps.nearmap.com/account/profile/solar-jobs` //no URL for their QA environment.
}

export const showShareField = (form, componentType) => {
  if (form['lookup_in_component_database']) {
    const value = form[componentType]
    return value === '' ? undefined : value
  } else {
    return form['custom_data_field_code']
  }
}

export const saveFileAs = (dataUri, filename) => {
  var link = document.createElement('a')
  if (typeof link.download === 'string') {
    link.setAttribute('href', dataUri)
    link.setAttribute('download', filename)
    link.setAttribute('target', '_blank')
    document.body.appendChild(link) // Firefox requires the link to be in the body
    link.click()
    document.body.removeChild(link) // remove the link when done
  } else {
    window.location?.replace(dataUri)
  }
}

export const parseStringFieldToFloat = (stringValue: string | undefined, locale: string = 'en-US') => {
  if (!stringValue) return undefined
  else {
    locale = convertLocaleName(locale)
    const decimalSeparator = new Intl.NumberFormat(locale).format(1.1).charAt(1)
    let justNumbers = stringValue.replace(new RegExp(`[^0-9${decimalSeparator}]`, 'g'), '')

    if (decimalSeparator === ',') {
      justNumbers = justNumbers.replace(',', '.')
    }
    if (justNumbers.length > 0) {
      justNumbers = justNumbers.substring(0, 9)
    }

    return parseFloat(justNumbers)
  }
}

export const filterForRealContacts = (contacts: ContactData[]) => {
  return contacts?.filter((con) => con.type === ContactDataTypeEnum.REAL_CONTACT)
}

export const getFirstRealContact = (contacts: ContactData[]) => {
  let realContacts = filterForRealContacts(contacts)
  return realContacts?.length ? realContacts[0] : null
}

export const getLocalisedAndFormattedDate = () => {
  const userTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: userTimezone,
  }

  const currentDate: Date = new Date()
  const locale = convertLocaleName(appStorage.getLocale())
  const formattedDate: string = new Intl.DateTimeFormat(locale, options).format(currentDate)

  const capitalizedFormattedDate: string = formattedDate.replace(/^\w/g, (match) => match.toUpperCase())

  return capitalizedFormattedDate
}

type InferPromiseArgs<T> = T extends (...args: infer R) => Promise<any> ? R : never
type InferReturnType<T> = T extends (...args: any) => Promise<infer R> ? R : never

// Wrap a promise call to cache the promise if other places calling it again.
// So when multiple places calling the same promise function at the same time
// the actual promise function will only run once and return the same result
// Be careful when using this method, avoid causing memory leak
export const getCachedPromiseCall = function <T>(promiseCall: T) {
  const caches = new Map()

  return async function (...args: InferPromiseArgs<T>): Promise<InferReturnType<T>> {
    const argumentsInString = JSON.stringify(args)
    if (caches.has(argumentsInString)) {
      return await caches.get(argumentsInString)
    } else {
      // @ts-ignore
      caches.set(argumentsInString, promiseCall.apply(this, args))
      const response = await caches.get(argumentsInString)
      // Clear cache
      caches.delete(argumentsInString)
      return response
    }
  }
}

export const getAcceptProposalText = (actionTitle, quoteAcceptanceContent, paymentAmount) => {
  if (actionTitle) return actionTitle
  if ((!quoteAcceptanceContent || quoteAcceptanceContent.length === 0) && paymentAmount > 0) return 'Make Payment'
  return 'Accept Proposal'
}

export const isPricingLockedForStage = (project, workflows) => {
  const selectedWorkflow = project?.workflow
  const workflow = workflows?.find((x) => x.id === selectedWorkflow?.workflow_id)
  if (workflow) {
    const activeStage = workflow?.workflow_stages?.find((x) => x.id === selectedWorkflow?.active_stage_id)
    if (activeStage) return activeStage?.milestone > 0
  }
  return false
}

export const getCurrentDate = () => {
  const dateNow = new Date()
  return `${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`
}

export const handleImplicitToggles = (workflow, project, form) => {
  const dirtyFields = form.mutators.getFormDirtyFields()
  const initialValues = form.getState().initialValues
  const workflow_stages = workflow.workflow_stages
  const findStage = workflow_stages?.find((x) => x.id === workflow.active_stage_id)
  if (findStage?.milestone !== undefined && (project.org === workflow.org || workflow.org_id === project.org_id)) {
    const milestone = findStage?.milestone
    if (milestone <= 1) {
      if (project.project_sold === 1) {
        form.change('project_sold', null)
        if (dirtyFields.includes('contract_date')) form.change('contract_date', initialValues.contract_date)
      }
      if (project.project_installed === 1) {
        form.change('project_installed', null)
        if (dirtyFields.includes('installation_date')) form.change('installation_date', initialValues.installation_date)
      }
    } else if (milestone > 1 && milestone < 3) {
      //if milestone is past SOLD
      if (project.project_sold === null) {
        form.change('project_sold', 1)
        if (!project.contract_date) form.change('contract_date', getCurrentDate())
      }
      if (project.project_installed === 1) {
        form.change('project_installed', null)
        if (dirtyFields.includes('installation_date')) form.change('installation_date', initialValues.installation_date)
      }
    } else if (milestone > 2 && milestone < 4) {
      //if milestone is past INSTALLED
      if (project.project_sold === null) {
        form.change('project_sold', 1)
        if (!project.contract_date) form.change('contract_date', getCurrentDate())
      }
      if (project.project_installed === null) {
        form.change('project_installed', 1)
        if (!project.installation_date) form.change('installation_date', getCurrentDate())
      }
    }
  }
}

export const updateProjectWorkflows = (selectedWorkflow, newWorkflow, project, form, source) => {
  const prevWorkflow = project.workflows?.find((x) => x.workflow_id === selectedWorkflow?.workflow_id)
  form.change(source, {
    org_id: selectedWorkflow?.org_id,
    workflow_id: newWorkflow.workflow_id,
    active_stage_id: newWorkflow.active_stage_id,
  })
  const projWorkflow = project.workflows?.find((x) => x.workflow_id === newWorkflow.workflow_id)
  let updatedWorkflows = [...project.workflows]
  if (prevWorkflow && prevWorkflow !== projWorkflow) {
    updatedWorkflows[project.workflows?.indexOf(prevWorkflow)] = {
      ...prevWorkflow,
      is_selected: false,
    }
  }
  if (projWorkflow) {
    updatedWorkflows[project.workflows?.indexOf(projWorkflow)] = {
      ...projWorkflow,
      is_selected: true,
      active_stage_id: newWorkflow.active_stage_id,
    }
    form.change('workflows', updatedWorkflows)
  } else {
    form.change('workflows', [
      ...updatedWorkflows,
      { ...newWorkflow, id: 'new-workflow-' + project.workflows?.length, is_selected: true },
    ])
  }
}

export const isMountingComponent = (otherComponentType) => otherComponentType?.includes('mounting_')

// This should only be used for populated selector value not for the redux store state
// Understand the difference between the two before using it https://opensolar.slab.com/posts/redux-selector-referencing-object-issue-1iizklrk
const reduxSelectorCachedValue = {} // potential memory leak if not cleared
export const getReduxSelectorCachedValueWhenEqual = ({ name, newValue }) => {
  if (reduxSelectorCachedValue[name] && isEqual(reduxSelectorCachedValue[name], newValue)) {
    return reduxSelectorCachedValue[name]
  } else {
    reduxSelectorCachedValue[name] = newValue
    return newValue
  }
}

type InferArguments<T> = T extends (...args: infer R) => any ? R : never
type InferReturnValue<T> = T extends (...args: any) => infer R ? R : never

// Shallow comparison
export function getCachedFunctionReturnValue<T extends Function>(
  func: T
): (...args: InferArguments<T>) => InferReturnValue<T> {
  // Ignoring detect context change for now
  let cachedDependenciesAndValue: Map<any, any> = new Map()

  return function (this: any, ...args: InferArguments<T>): InferReturnValue<T> {
    const hasChange = args.some((arg, index) => {
      return cachedDependenciesAndValue.get(index) !== arg
    })

    if (!hasChange && cachedDependenciesAndValue.has('value')) {
      return cachedDependenciesAndValue.get('value')
    }

    // Free up memory
    cachedDependenciesAndValue = new Map()

    const value = func.apply(this, args)
    args.forEach((arg, index) => {
      cachedDependenciesAndValue.set(index, arg)
    })
    cachedDependenciesAndValue.set('value', value)
    return value
  }
}

export const getReadableLabel = (label: string): string => {
  return label.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (char) => char.toUpperCase())
}

export const isValueNumeric = (value: any): boolean => {
  if (
    value === '' ||
    typeof value === 'boolean' ||
    value === null ||
    _.isSymbol(value) ||
    _.isNaN(value) ||
    Array.isArray(value)
  ) {
    return false
  }
  const num = Number(value)
  return _.isNumber(num) && Number.isFinite(num)
}
