import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, styled } from 'opensolar-ui';
export var StyledAlert = styled(Alert)(function (_a) {
    var theme = _a.theme;
    return ({
        margin: '5px 0px',
        whiteSpace: 'pre-line',
    });
});
var AlertMessage = function (_a) {
    var elementData = _a.elementData, translate = _a.translate, proposalData = _a.proposalData, config = _a.config, onAction = _a.onAction, options = _a.options;
    var messages = elementData.actionData.messages;
    var defaultSeverity = 'warning';
    return (_jsx(_Fragment, { children: messages
            ? messages.map(function (message, index) { return (_jsx(StyledAlert, { severity: message.type || defaultSeverity, className: "small", children: message.text }, message.type + message.text)); })
            : null }));
};
export default AlertMessage;
