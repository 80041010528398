import { Theme, useMediaQuery } from '@material-ui/core'
import { InfoOutlined, VisibilityOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import BlueInfoCard from '../components/BlueInfoCard'
import GreyInfoCard from '../components/GreyInfoCard'
import { FormRouteInfoPropTypes } from '../types'
import { CONVERSION_RATE_MAP, FEE_SAVINGS_MAP, MISSED_REVENUE_MAP } from './constants'

interface StyledProps {
  isMobile?: boolean
  addtlValueLabel?: string
}

const AddtlValueRow = styled('div')<StyledProps>(({ isMobile }) => ({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  margin: '0.5rem',
  gap: 20,
}))

const AddtlValueDescWrapper = styled('div')({
  textAlign: 'left',
  fontSize: 13,
})

const AddtlValueContainer = styled('div')<StyledProps>(({ addtlValueLabel }) => ({
  background: '#FFD800',
  borderRadius: '8px',
  fontWeight: 500,
  fontSize: '13px',
  position: 'relative',
  padding:
    addtlValueLabel === 'softCost'
      ? '10px 16px'
      : addtlValueLabel === 'increasedConversion'
      ? '6px 16px'
      : addtlValueLabel === 'feeSavings'
      ? '16px'
      : addtlValueLabel === 'missedRevenue'
      ? '2px 16px'
      : '2px 20px',
}))

const AddtlValueDesc = styled('span')({
  fontStyle: 'italic',
})

const ControlDescriptionRight: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const orgCountry: string = useSelector(orgSelectors.getOrgIso2)?.toString() || ''
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const INFO_CONTENT = [
    {
      id: 1,
      icon: <InfoOutlined />,
      header: 'Why do we need this information?',
      description:
        'Regulations applicable to OpenSolar require financial institutions to obtain identifying information of certain owners or controllers of a business. This requirement ensures compliance with regulations by OpenSolar.',
    },
    {
      id: 2,
      icon: <VisibilityOutlined />,
      header: 'How do we secure your information?',
      description:
        'Information captured as part of this process is securely transferred to our Payment Service Provider. Access to this information is strictly limited to individuals who need it for internal business processes, such as reviewing your registration information, onboarding purposes, and technical troubleshooting.',
    },
  ]

  const ADDTL_VALUE_CONTENT = [
    {
      id: 1,
      price: '28',
      header: 'Soft Cost Savings',
      description: 'Automated payment collection & follow-up',
      label: 'softCost',
    },
    {
      id: 2,
      price: CONVERSION_RATE_MAP[orgCountry],
      header: 'Increased Conversion Rate',
      description: 'Improved customer payment experience',
      label: 'increasedConversion',
    },
    {
      id: 3,
      price: FEE_SAVINGS_MAP[orgCountry],
      header: 'Fee Savings',
      description: 'Reduced credit card & invoicing costs',
      label: 'feeSavings',
    },
    {
      id: 4,
      price: MISSED_REVENUE_MAP[orgCountry],
      header: 'Avoid “Missed” Revenue',
      description: 'Collect every payment on time',
      label: orgCountry === 'US' ? 'missedRevenueSmall' : 'missedRevenue',
    },
  ]

  return (
    <div>
      <BlueInfoCard header={'%{currencySymbol}90 of additional value per created project'}>
        {ADDTL_VALUE_CONTENT.map((content) => {
          return (
            <AddtlValueRow isMobile={isMobile} key={content.id}>
              <AddtlValueContainer addtlValueLabel={content.label}>
                <span>
                  {currencySymbol}
                  {content.price}
                </span>
              </AddtlValueContainer>
              <AddtlValueDescWrapper>
                <Typography textVariant="body1Bold"> {translate(content.header)} </Typography>
                <AddtlValueDesc> {translate(content.description)}</AddtlValueDesc>
              </AddtlValueDescWrapper>
            </AddtlValueRow>
          )
        })}
      </BlueInfoCard>
      <GreyInfoCard content={INFO_CONTENT} />
    </div>
  )
}
export default ControlDescriptionRight
