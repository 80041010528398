import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import useTranslateParse from 'util/useTranslateParse'
import { FormRouteInfoPropTypes } from '../types'

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: 10,
  marginBottom: 16,
})

const BankInfoTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  return (
    <Container>
      <Typography textVariant="h5SemiBold">{translate('Add bank information')}</Typography>
      <Typography textVariant="body1">
        {translateParse(
          "This business bank account is where you'll receive payments from customer transactions. Enter the information <strong> exactly <strong> as it appears in your bank account.",
          {
            strong: (label: string) => <strong>{label}</strong>,
          }
        )}
      </Typography>
    </Container>
  )
}
export default BankInfoTop
