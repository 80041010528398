// in src/users.js
import { makeStyles } from '@material-ui/core/styles'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import DependentInput from 'elements/input/DependentInput'
import { List } from 'elements/react-admin/List'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  FunctionField,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import OverviewInputs from '../../elements/input/OverviewInputs'
// import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import {
  getPerformanceCalculatorChoices,
  PERFORMANCE_CALCULATORS_PVWATTS_V6,
  translateCalculators,
} from 'constants/calculators'
import { authSelectors } from 'ducks/auth'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import TrackedToolbar from 'elements/TrackedToolbar'
import inflection from 'inflection'
import { connect, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import {
  currencySymbolForCountry,
  feetToMeters,
  getMeasurementsFromState,
  getRoleFromState,
  metersToFeet,
  trimDecimalPlaces,
} from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { duplicate as duplicateAction } from '../../actions/restActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import AutoApplyInputs from '../autoApply/AutoApplyInputs'

export const WEATHER_DATASETS = [
  { id: 0, name: 'EnergyPlus' },
  { id: 1, name: 'PVGIS' },
]

const styles = {
  inputStyle: {
    width: 256,
  },
  h2: {
    marginTop: 40,
  },
  detailStyle: {
    width: 200,
    padding: '0 5px 0 0',
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}))

export const ProjectConfigurationList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
    }
  },
  { duplicate: duplicateAction }
)(({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const translate = useTranslate()
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete)

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  return (
    <List
      extraCreateButtonLabel={isAdmin ? translate('ra.page.empty_new', { name: resourceName }) : null}
      {...props}
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
      filters={<ShareabilityFilters />}
    >
      <Datagrid duplicate={props.duplicate} isAdmin={isAdmin}>
        <TextFieldWithSharedEntityIcon source="title" />
        <FunctionField
          source="inverter_modelling_automation"
          skipFromGrid={true}
          displayInCard={true}
          sortable={false}
          label="Inverter Modelling"
          render={(record) => (record.inverter_modelling_automation === 1 ? 'Automatic' : 'Manual')}
          style={{ width: 20 }}
        />
        <BooleanField source="priority" textAlign={'center'} displayInCard={true} label="Auto-apply" />
        <EditOrImportButton
          source="actions"
          label="Actions"
          sortable={false}
          org_id={props.org_id}
          resource="project_configurations"
          duplicate={props.duplicate}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
})

const measurementsProps = (measurements) =>
  measurements === 'imperial'
    ? {
        endAdornment: 'ft',
        // format(): record -> input
        // parse(): input -> record
        // Warning: This is EXTREMELY delicate. So easy to screw it up.
        // Known issue: Trying to enter more than 4 decimal places gives strange behavior but at least it's not unsfae.
        format: (v) => trimDecimalPlaces(metersToFeet(v), 4),
        parse: (v) => trimDecimalPlaces(feetToMeters(v), 6),
      }
    : { endAdornment: 'm' }

const _ProjectConfigurationEdit = ({ accessRights, ...props }) => {
  const translate = useTranslate()
  return useEditShareable({
    accessRights,
    editProps: props,
    suppressNotFound: true,
    render: ({ access }) => (
      <SimpleForm
        validate={(values) => validateTitle(values, translate)}
        toolbar={access.allowEdit ? <TrackedToolbar {...props} /> : false}
      >
        <ProjectConfigurationFormContent props={props} allowEdit={access.allowEdit} />
      </SimpleForm>
    ),
  }).editPage
}

const ProjectConfigurationFormContent = ({ props, allowEdit }) => {
  const formState = useFormState()
  const classes = useStyles()
  const translate = useTranslate()
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''
  const performanceCalculator = formState.values.performance_calculator
  return (
    <>
      <OverviewInputs>
        <CustomField
          component={TextInput}
          inputStyle={styles.inputStyle}
          name={'title'}
          source="title"
          disabled={!allowEdit}
          label="resources.project_configurations.fields.title"
        />
        <CustomField
          component={BooleanInput}
          name={'priority'}
          label="Auto-apply"
          source="priority"
          disabled={!allowEdit}
        />
        <CustomField
          component={TextInput}
          fullWidth={true}
          multiline={true}
          name={'description'}
          source="description"
          disabled={!allowEdit}
          label="resources.project_configurations.fields.description"
        />
      </OverviewInputs>

      <Accordion className={classes.root} classes={{ expanded: 'ExpansionPanelExpanded' }}>
        <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
          {translate('Setbacks & Design Settings')}
          <div className="small" style={{ marginTop: 4, marginLeft: 10 }}>
            {translate('Automation, Setbacks & Tilt Racks')}
          </div>
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails">
          <div
            style={{
              minHeight: 320,
              position: 'relative',
              width: '100%',
              padding: 0,
            }}
          >
            <h2 style={styles.h2}>{translate('Automation')}</h2>
            <CustomField
              component={SelectInput}
              source="inverter_modelling_automation"
              name="inverter_modelling_automation"
              label="resources.project_configurations.fields.inverter_modelling_automation"
              choices={[
                { id: 1, name: 'Automatic' },
                { id: 0, name: 'Manual' },
              ]}
              disabled={!allowEdit}
            />

            <h2 style={styles.h2}>{translate('Setbacks')}</h2>
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_default"
              name="setbacks_default"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_default"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_gutter"
              name="setbacks_gutter"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_gutter"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_ridge"
              name="setbacks_ridge"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_ridge"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_valley"
              name="setbacks_valley"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_valley"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_hip"
              name="setbacks_hip"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_hip"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_rake"
              name="setbacks_rake"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_rake"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_shared"
              name="setbacks_shared"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_shared"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_flat_gutter"
              name="setbacks_flat_gutter"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_flat_gutter"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_skylight"
              name="setbacks_skylight"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_skylight"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_dormer"
              name="setbacks_dormer"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_dormer"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_objects"
              name="setbacks_objects"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_objects"
            />
            <NumberInput
              style={styles.detailStyle}
              source="setbacks_arrays"
              name="setbacks_arrays"
              step={0.1}
              {...props}
              {...measurementsProps(props.measurements)}
              disabled={!allowEdit}
              label="resources.project_configurations.fields.setbacks_arrays"
            />
            <h2 style={styles.h2}>{translate('Tilt Racks')}</h2>
            <NumberInput
              style={styles.detailStyle}
              source="tilt_rack_default_tilt"
              {...props}
              name="tilt_rack_default_tilt"
              step={1}
              endAdornment="°"
              disabled={!allowEdit}
              label="resources.project_configurations.fields.tilt_rack_default_tilt"
            />
            <NumberInput
              style={styles.detailStyle}
              source="apply_tilt_racks_below_slope"
              {...props}
              label="resources.project_configurations.fields.apply_tilt_racks_below_slope"
              step={1}
              endAdornment="°"
              disabled={!allowEdit}
            />
            <CustomField
              component={SelectInput}
              source="tilt_rack_default_orientation"
              name="tilt_rack_default_orientation"
              label="resources.project_configurations.fields.tilt_rack_default_orientation"
              choices={[
                { id: 'landscape', name: 'Landscape' },
                { id: 'portrait', name: 'Portrait' },
              ]}
              disabled={!allowEdit}
            />
            <CustomField
              component={BooleanInput}
              name={'show_tax_effects_of_solar'}
              label="Show Tax Effects of Solar (commercial only)"
              source="show_tax_effects_of_solar"
              disabled={!allowEdit}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.root} classes={{ expanded: 'ExpansionPanelExpanded' }}>
        <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
          {translate('Simulation Settings')}
          <div className="small" style={{ marginTop: 4, marginLeft: 10 }}>
            {translate('Duration, electricity price inflation, incentives')}
          </div>
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails">
          <div
            style={{
              // minHeight: 320,
              position: 'relative',
              width: '100%',
              padding: 0,
            }}
          >
            <h2 style={styles.h2}>{translate('Simulation Settings')}</h2>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <div>
                <CustomField
                  component={BooleanInput}
                  name={'auto_apply_max_simulate_years'}
                  label="Automatically set years to simulate"
                  source="auto_apply_max_simulate_years"
                  disabled={!allowEdit}
                />
                <span style={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: 10 }}>
                  {translate('Simulation years based on maximum module product warranty')}
                </span>
              </div>
              <DependentInput dependsOn="auto_apply_max_simulate_years" resolve={(val) => !val}>
                <NumberInput
                  style={styles.detailStyle}
                  source="years_to_simulate"
                  {...props}
                  name="years_to_simulate"
                  endAdornment={translate('default') + ': 20'}
                  helperText={translate('Maximum simulation years: %{years}', { years: 40 })}
                  disabled={!allowEdit}
                />
              </DependentInput>
            </div>
            <NumberInput
              style={styles.detailStyle}
              source="performance_adjustment"
              {...props}
              name="performance_adjustment"
              endAdornment="%"
              disabled={!allowEdit}
              label="resources.project_configurations.fields.performance_adjustment"
            />
            <NumberInput
              style={styles.detailStyle}
              source="proposed_usage_adjustment"
              name="proposed_usage_adjustment"
              label="resources.project_configurations.fields.usage_adjustment_after_sale"
              {...props}
              defaultValue={'0'}
              endAdornment="+/-%"
              disabled={!allowEdit}
            />
            <NumberInput
              style={styles.detailStyle}
              source="discount_rate"
              name="discount_rate"
              {...props}
              endAdornment="%"
              disabled={!allowEdit}
              label="resources.project_configurations.fields.discount_rate"
            />
            <CustomField
              component={SelectInput}
              source="performance_calculator"
              name="performance_calculator"
              label="Energy Production Calculator"
              choices={translateCalculators(
                getPerformanceCalculatorChoices(performanceCalculator, orgCountry),
                translate
              ).map((pc) => ({
                id: pc.id,
                name: pc.name,
              }))}
              translateChoice={false}
              disabled={!allowEdit}
              helperText={
                // Please remove this after we fully depreciate PVWatts v6 and remove PVWatts v6 as a selectable option!!!
                performanceCalculator === PERFORMANCE_CALCULATORS_PVWATTS_V6 ? (
                  <div style={{ color: 'red' }}>
                    {translate('PVWatts v6 will be decommissioned in early 2024. Please use PVWatts v8 instead.')}
                  </div>
                ) : (
                  ''
                )
              }
            />
            <CustomField
              component={SelectInput}
              source="weather_dataset"
              name="weather_dataset"
              label="Weather Dataset (SAM Only)"
              choices={WEATHER_DATASETS.map((wd) => ({ id: wd.id, name: wd.name }))}
              disabled={!allowEdit}
            />
            <h4>{translate('Utility inflation settings')}</h4>
            <p style={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: 10 }}>
              {translate(
                'These setting relate to how the utility inflation is calculated and displayed on your designs and proposals.'
              )}
            </p>
            <NumberInput
              style={styles.detailStyle}
              source="utility_inflation_annual"
              name="utility_inflation_annual"
              label={'Utility Inflation Annual'}
              defaultValue={'3'}
              endAdornment={'+/-%/' + translate('year')}
              disabled={!allowEdit}
              InputLabelProps={{ shrink: true }}
            />
            <NumberInput
              style={styles.detailStyle}
              source="utility_inflation_annual_lower_bound"
              name="utility_inflation_annual_lower_bound"
              label={'Utility Inflation Annual Lower Bound'}
              endAdornment={'+/-%/' + translate('year')}
              disabled={!allowEdit}
              InputLabelProps={{ shrink: true }}
            />
            <NumberInput
              style={styles.detailStyle}
              source="utility_inflation_annual_upper_bound"
              name="utility_inflation_annual_upper_bound"
              label={'Utility Inflation Annual Upper Bound'}
              endAdornment={'+/-%/' + translate('year')}
              disabled={!allowEdit}
              InputLabelProps={{ shrink: true }}
            />
            <CustomField
              component={TextInput}
              style={styles.detailStyle}
              label={'Inflation Range Label'}
              name="scaling_factors_label"
              source="scaling_factors_label"
              InputLabelProps={{ shrink: true }}
            />

            <NumberInput
              style={styles.detailStyle}
              source="feed_in_tariff_inflation_annual"
              name="feed_in_tariff_inflation_annual"
              label={'resources.project_configurations.fields.feed_in_tariff_inflation'}
              {...props}
              defaultValue={'0'}
              endAdornment={'+/-%/' + translate('year')}
              disabled={!allowEdit}
            />

            <h2>{translate('System Advisor Model (SAM) Losses')}</h2>
            {[
              { heading: 'General' },
              { field: 'sam_ac_wiring', label: 'AC Wiring' },
              { field: 'sam_nameplate', label: 'Nameplate' },
              { field: 'sam_diodes_and_connections', label: 'Diodes and Connections' },
              { field: 'sam_dc_system_availability', label: 'DC System Availability' },
              { field: 'sam_ac_system_availability', label: 'AC System Availability' },
              { heading: 'String Inverters' },
              { field: 'sam_string_soiling', label: 'Soiling' },
              { field: 'sam_string_dc_wiring', label: 'DC Wiring' },
              { field: 'sam_string_mismatch', label: 'Mismatch' },
              { heading: 'Microinverters' },
              { field: 'sam_micro_soiling', label: 'Soiling' },
              { field: 'sam_micro_dc_wiring', label: 'DC Wiring' },
              { field: 'sam_micro_mismatch', label: 'Mismatch' },
              { heading: 'DC Optimizers' },
              { field: 'sam_optimizer_soiling', label: 'Soiling' },
              { field: 'sam_optimizer_dc_wiring', label: 'DC Wiring' },
              { field: 'sam_optimizer_mismatch', label: 'Mismatch' },
            ].map((item, index) =>
              item.heading ? (
                <h3 key={index}>{translate(item.heading)}</h3>
              ) : (
                <NumberInput
                  key={index}
                  style={styles.detailStyle}
                  source={item.field}
                  label={item.label}
                  {...props}
                  name={item.field}
                  endAdornment="%"
                  disabled={!allowEdit}
                />
              )
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      <DependentInput dependsOn="priority" value={true}>
        <AutoApplyInputs
          enableByCountry={true}
          enableByPriority={true}
          enableByComponentCode={false}
          enableByMinSystemSize={false}
          enableByMaxSystemSize={false}
          disabled={!allowEdit}
        />
      </DependentInput>
      {allowEdit && <ShareabilitySelector />}
    </>
  )
}

export const ProjectConfigurationEdit = compose(
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
      currencySymbol: currencySymbolForCountry(getOrgCountryCodeFromState(state)),
    }),
    {}
  )
)(_ProjectConfigurationEdit)

export const ProjectConfigurationCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create {...props}>
      <SimpleForm validate={(values) => validateTitle(values, translate)} toolbar={<TrackedToolbar {...props} />}>
        <OverviewInputs noBorder={true}>
          <CustomField component={TextInput} name={'title'} source="title" />
          <CustomField component={BooleanInput} name={'priority'} label="Auto-apply" source="priority" />
          <CustomField
            component={TextInput}
            fullWidth={true}
            multiline={true}
            name={'description'}
            source="description"
          />
        </OverviewInputs>
      </SimpleForm>
    </Create>
  )
}

const validateTitle = (values, translate) => {
  const errors = {}
  if (!values.title) {
    errors.title = [translate('Title is required.')]
  }
  return errors
}
