import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { useAddComponentToSystem } from 'hooks/components/useAddComponentToSystem'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { HardwareSupplierEnum, HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { getSlotKey, initialFilterSettingsForSlot } from 'projectSections/sections/design/systems/Slots'
import { useTranslate } from 'ra-core'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { StudioInverterType, StudioSystemType } from 'types/global'
import { SelectComponentFilters } from 'types/selectComponent'
import { SlotType } from 'types/slots'
import { ActionStatus, ActionType, EventType } from 'types/tracking'
import { useFeatureFlag } from 'util/split'
import { fetchComponents } from '../hardwareSelector/HardwareSelectionDialog'

export type AutoAddElectricalResults = {
  total: number
}

export const useAutoAddElectrical = (system: StudioSystemType) => {
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const translate = useTranslate()
  const addComponentToSystem = useAddComponentToSystem()
  const orgId = useSelector(authSelectors.getOrgId) as number
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const multiDistributor = useFeatureFlag('design_multi_distributor', 'on')
  const { trackEvent } = useTrackComponent({
    componentKey: 'auto_add_electrical',
    eventName: 'Fired Auto Stringing Error',
  })

  const autoAddElectrical = (
    inverter: StudioInverterType,
    isolatePerMPPT: boolean,
    onSuccess: (results: AutoAddElectricalResults) => void
  ) => {
    setErrorMessage(null)
    const activeComponentsData = window.AccountHelper.getComponentOtherSpecsAvailable()
    const slots: SlotType[] = [{ type: 'ac_isolator', attachToUuid: inverter.uuid }]
    const includeDCIsolators = inverter.inbuilt_dc_isolator !== 'Y'
    system
      .getComponentsForSlot(`ac_isolator_${inverter.uuid}`)
      .map((component) => window.editor.deleteObject(component))
    inverter.mppts().forEach((mppt) => {
      system.getComponentsForSlot(`dc_isolator_${mppt.uuid}`).map((component) => window.editor.deleteObject(component))
      system.getComponentsForSlot(`dc_cable_${mppt.uuid}`).map((component) => window.editor.deleteObject(component))
      slots.push({ type: 'dc_cable', attachToUuid: mppt.uuid })
      if (isolatePerMPPT && includeDCIsolators) {
        slots.push({ type: 'dc_isolator', attachToUuid: mppt.uuid })
      }
      mppt.strings().forEach((string) => {
        system
          .getComponentsForSlot(`dc_isolator_${string.uuid}`)
          .map((component) => window.editor.deleteObject(component))
        system.getComponentsForSlot(`dc_cable_${string.uuid}`).map((component) => window.editor.deleteObject(component))
        if (!isolatePerMPPT && includeDCIsolators) {
          slots.push({ type: 'dc_isolator', attachToUuid: string.uuid })
        }
      })
    })
    var slotsToFill = slots.length
    var errorFillingSlots = false
    let results = { total: 0 }
    slots.forEach((slot) => {
      const filterSettings: SelectComponentFilters = {
        ...{ page: 1, country_iso2: countryIso2, show_exhibited_first: 'true', my_list: 'true' },
        ...initialFilterSettingsForSlot(slot),
      }
      if (supplier && !multiDistributor) {
        let hardwareEnumToFilter: Record<HardwareSupplierEnum, HardwareSupplierFilterKeyType> = {
          1: 'segen',
          2: 'outlet',
          3: 'city_plumbing',
          4: 'hdm',
          5: 'vtac_uk',
          6: 'vtac_pl',
        }
        filterSettings.require_distributor = hardwareEnumToFilter[supplier]
      }
      function fetchComponentsCallback(components, totalPages, includeResults) {
        if (components.length) {
          results.total++
          const component = components[0]
          addComponentToSystemAndSlot(
            component.componentActivationId,
            component.id,
            getSlotKey(slot),
            activeComponentsData
          )
        } else if (supplier && filterSettings.require_distributor) {
          // If no there are no results from the selected distributor, try again without the distributor filter
          delete filterSettings.require_distributor
          fetchComponents(
            'other',
            filterSettings,
            activeComponentsData,
            fetchComponentsCallback,
            orgId,
            sharedWith,
            true
          )
          return
        } else {
          errorFillingSlots = true
        }
        slotsToFill--
        if (!slotsToFill) {
          if (errorFillingSlots) {
            const errorMessage = 'Unable to find compatible components, please add additional components manually'
            setErrorMessage(translate(errorMessage))
            trackEvent(
              EventType.ERROR_EVENT,
              { type: ActionType.UPDATE, status: ActionStatus.ERROR },
              { message: errorMessage }
            )
          }
          onSuccess(results)
        }
      }
      const sharedWith = window.WorkspaceHelper.project?.shared_with || []
      const components = fetchComponents(
        'other',
        filterSettings,
        activeComponentsData,
        fetchComponentsCallback,
        orgId,
        sharedWith,
        true
      )
    })
  }

  function addComponentToSystemAndSlot(componentActivationId, componentId, slotKey, activeComponentsData) {
    const componentType = 'other'
    function closeDialog() {}
    addComponentToSystem({
      componentType,
      componentActivationId,
      componentId,
      activeComponentsData,
      slotKey,
      closeDialog,
    })
  }

  return { errorMessage, autoAddElectrical }
}
