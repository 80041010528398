import { Grid } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import {
  getBestDiscountOfferPerItem,
  getDiscountSavingPerItem,
  getMinimumRequiredQuantityForDiscount,
  getPercentageSavingPerItem,
} from 'pages/ordering/discountUtils'
import PercentageDiscountTag from 'pages/ordering/elements/PercentageDiscountTag'
import React, { memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry } from 'util/misc'
import ProjectOrderPresenter from '../ProjectOrderPresenter/projectOrderPresenter'
import { getSupplierConfig } from '../configs'
import { STOCK_LEVEL_STATUS } from '../constants'
import { StockLevelType } from '../type'
import CatalogItemPrice from './CatalogItemPrice'
import CatalogItemStatus, { getCatalogItemStatus } from './CatalogItemStatus'
import CatalogItemTitle from './CatalogItemTitle'
import type { CatalogItemDataType } from './HardwareCatalog'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: 240,
    minHeight: 320,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: '4px',
    border: '1px solid #E7E7E7',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      border: `1px solid ${theme.openSolarYellow}`,
    },
  },
  content: {
    padding: 10,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tagWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  imageWrapper: {
    position: 'relative',
    width: 240,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid #E7E7E7',
  },
  image: {
    maxWidth: 200,
    maxHeight: 160,
    margin: 'auto',
  },
  imageHolder: {
    width: 240,
    height: 200,
    backgroundColor: '#CCCCCC',
  },
}))

const CatalogItem = ({ data, handleClick }: { data: CatalogItemDataType; handleClick(code: string): void }) => {
  const { code, image_url, manufacturer_name, distributors, short_description } = data
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  // TODO: fix me. Inject selectedSupplierFilterKey as prop. It's too late to check in low-level component
  const selectedSupplierFilterKey = getSupplierConfig(selectedSupplier)?.filterKey

  const onClick = useCallback(() => {
    handleClick(code)
  }, [code])

  // default selected variant quantity always 1
  const variant =
    distributors &&
    selectedSupplierFilterKey &&
    OrderLineItem.getDefaultSelectedVariant(distributors, selectedSupplierFilterKey)
  const hasVariant = !!variant

  const inStockLevel = variant?.stock_levels?.find((stock: StockLevelType) => stock.in_stock)
  const originalPrice = variant?.price
  const isDistributorAvailable = !!variant?.is_available

  const lastPricingRefreshDate = variant?.refresh_date

  const discounts = variant?.volume_discounts

  const bestPossibleDiscountOffer =
    !!originalPrice && discounts && discounts.length > 0
      ? getBestDiscountOfferPerItem({ discounts, pricePerItem: originalPrice })
      : undefined

  const priceAfterDiscount =
    bestPossibleDiscountOffer && originalPrice
      ? originalPrice - getDiscountSavingPerItem({ discount: bestPossibleDiscountOffer, pricePerItem: originalPrice })
      : undefined

  const status = getCatalogItemStatus({
    selectedSupplier,
    hasVariant,
    isDistributorAvailable,
    stockLevels: variant?.stock_levels,
  })

  let plannedDateDays = 0
  if (status === STOCK_LEVEL_STATUS.AVAILABLE_FOR_PRE_ORDER) {
    plannedDateDays = ProjectOrderPresenter.getStockPlannedDateDays(variant?.stock_levels)
  }

  const minQuantityRequiredForDiscount =
    bestPossibleDiscountOffer && originalPrice
      ? getMinimumRequiredQuantityForDiscount({ discount: bestPossibleDiscountOffer, pricePerItem: originalPrice })
      : undefined

  return (
    <CatalogItemLayout
      imageUrl={image_url}
      onClick={onClick}
      Title={
        <CatalogItemTitle code={code} short_description={short_description} manufacturer_name={manufacturer_name} />
      }
      Tag={
        bestPossibleDiscountOffer != null && originalPrice ? (
          <PercentageDiscountTag
            showUpTo={false}
            percentage={getPercentageSavingPerItem({
              discount: bestPossibleDiscountOffer,
              pricePerItem: originalPrice,
            })}
          />
        ) : undefined
      }
      PriceAndStock={
        <Grid container justify="space-between" alignItems="flex-end">
          {!!originalPrice && isDistributorAvailable && (
            <Grid item>
              <CatalogItemPrice
                discountPrefix={
                  minQuantityRequiredForDiscount !== undefined && minQuantityRequiredForDiscount > 1 ? 'From' : ''
                }
                originalPrice={originalPrice}
                priceAfterDiscount={priceAfterDiscount}
                currencySymbol={currencySymbol}
              />
            </Grid>
          )}
          <Grid item>
            <CatalogItemStatus
              supplier={supplier}
              status={status}
              stockQuantity={inStockLevel?.quantity}
              lastPricingRefreshDate={lastPricingRefreshDate}
              plannedDateDays={plannedDateDays}
            />
          </Grid>
        </Grid>
      }
    />
  )
}

export const CatalogItemLayout = memo(
  ({
    Tag,
    imageUrl,
    onClick,
    Title,
    PriceAndStock,
  }: {
    imageUrl?: string
    onClick(): void
    Tag?: React.ReactNode
    Title: React.ReactNode
    PriceAndStock: React.ReactNode
  }) => {
    const placeHolderImage = `${window.PUBLIC_URL}/images/component_photo_holder.svg`
    const initialImage = imageUrl || placeHolderImage
    const [imgSrc, setImageSrc] = useState(initialImage)

    const classes = useStyles()
    return (
      <div className={classes.container} onClick={onClick}>
        <div className={classes.imageWrapper}>
          <div className={classes.tagWrapper}>{Tag}</div>
          <img
            className={classes.image}
            src={imgSrc}
            onError={(e) => {
              setImageSrc(placeHolderImage)
            }}
          />
        </div>
        <div className={classes.content}>
          <div title='Catalog Item Title'>{Title}</div>
          <div>{PriceAndStock}</div>
        </div>
      </div>
    )
  }
)

export default CatalogItem
