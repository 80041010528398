import { BomGeneratorAbstract } from '../../bom_generators/BomGeneratorAbstract'
import { Corner, Edge, PerBlockEdgeBomGeneratorAbstract } from '../../bom_generators/PerBlockEdgeBomGeneratorAbstract'
import { PerOuterCornerBomGeneratorAbstract } from '../../bom_generators/PerOuterCornerBomGeneratorAbstract'
import { PerPanelBomGeneratorAbstract } from '../../bom_generators/PerPanelBomGenerator'
import { PerRowClampBomGeneratorAbstract } from '../../bom_generators/PerRowClampBomGeneratorAbstract'
import { BasicSpacingLayoutAdjusterAbstract } from '../../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../../MountingSystemAbstract'
import { PostProcessorAbstract } from '../../post_processors/PostProcessorAbstract'
import {
  CompatibilityParameters,
  ContiguousPanelRow,
  HasPosition,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  PanelWithPosition,
} from '../../types'
import { addItems, createMountingItem } from '../../utils'
import { getCompatibleTrays, getItemFromTray } from './trays'

// Note : If this is extended into countries beyond the UK, it may require some country-specific changes as some countries have different components.

// Missing H19, H23, H27, and H31 codes
const clampComponentReference = {
  'H16 Mid Clamp': 'ART105904',
  'H16 End Clamp': 'ART105897',
  'H19 Mid Clamp': 'ART100389', //
  'H19 End Clamp': 'ART100389', //
  'H21 Mid Clamp': 'ART100744',
  'H21 End Clamp': 'ART100495',
  'H23 Mid Clamp': 'ART100389', //
  'H23 End Clamp': 'ART100389', //
  'H26 Mid Clamp': 'ART102335',
  'H26 End Clamp': 'ART102328',
  'H27 Mid Clamp': 'ART100389', //
  'H27 End Clamp': 'ART100389', //
  'H31 Mid Clamp': 'ART100389', //
  'H31 End Clamp': 'ART100389', //
}

class GseLayoutAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = {
    interPanel: { horizontalSpacing: 35, verticalSpacing: 35 },
  }
}

class GseBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (this.input.options?.rearSupportBarIncluded) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: 'GSE is not compatible with modules with rear support bars',
        key: `GSEINROOF_PLUGIN_WARNING`,
        severity: 'warning',
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return result
    }

    let panel = result.panelBlocks[0].panel
    let panelOrientation = result.panelBlocks[0].orientation
    let compatibleTrays = getCompatibleTrays(panel, panelOrientation)
    if (!compatibleTrays.length) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: 'No compatible trays',
        key: `GSEINROOF_PLUGIN_WARNING`,
        severity: 'warning',
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return result
    }
    const perPanelBom = await this.generateBomFrom(GsePerPanelBomGenerator, result)
    const perRowBom = await this.generateBomFrom(GsePerRowBomGenerator, perPanelBom)
    const perEdgeBom = await this.generateBomFrom(GsePerEdgeBomGenerator, perRowBom)
    return this.generateBomFrom(GsePerOuterCornerBomGenerator, perEdgeBom)
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    // add 1 black screw per clamp
    const resultWithScrews = new GseBlackScrewPerClampAdder(this.input).process(result)

    // add 1 wedge per end clamps with equal numbers of Left/Right
    const resultWithScrewsAndWedge = new GseWedgePerEndClampAdder(this.input).process(resultWithScrews)

    return resultWithScrewsAndWedge
  }
}

export class GseInRoof extends MountingSystemAbstract {
  layoutAdjusterClass = GseLayoutAdjuster
  bomGeneratorClass = GseBomGenerator

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['Segen'],
      slopeRange: [12, 60],
      roofTypes: ['Flat Concrete', 'Metal Decramastic', 'Tile Clay', 'Tile Slate'],
    }
  }
}

class GsePerPanelBomGenerator extends PerPanelBomGeneratorAbstract {
  generateBomForPanel(panel: PanelWithPosition, blockIndex: number): Item[] {
    var itemsToAdd: Item[] = []
    // Add the frame/tray for each panel
    let compatibleTrays = getCompatibleTrays(panel, panel.orientation)
    itemsToAdd.push(
      createMountingItem(getItemFromTray(compatibleTrays[0]), {
        top: panel.top,
        left: panel.left,
        blockIndex,
      })
    )
    // Two half trays are required per panel
    if (compatibleTrays[0].size === 'half') {
      itemsToAdd.push(
        createMountingItem(getItemFromTray(compatibleTrays[0]), {
          top: panel.top + (panel.orientation === 'portrait' ? panel.height : panel.width) / 2,
          left: panel.left,
          blockIndex,
        })
      )
    }
    // Add fixings for the frame/tray
    const fixingItem: Item = { name: 'VIS2014N', components: [] }
    const fixingsPerPanel = 6
    itemsToAdd.push(...Array(fixingsPerPanel).fill(createMountingItem(fixingItem)))
    return itemsToAdd
  }
}

class GsePerEdgeBomGenerator extends PerBlockEdgeBomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    let newResult = result

    newResult = this.getRightEdges().reduce(
      (resultFromLastEdge, edge) => addItems(resultFromLastEdge, this.generateBomForRightEdge(edge)),
      result
    )
    newResult = this.getLeftEdges().reduce(
      (resultFromLastEdge, edge) => addItems(resultFromLastEdge, this.generateBomForLeftEdge(edge)),
      newResult
    )
    newResult = this.getTopEdges().reduce(
      (resultFromLastEdge, edge) => addItems(resultFromLastEdge, this.generateBomForTopEdge(edge)),
      newResult
    )
    newResult = this.getBottomEdges().reduce(
      (resultFromLastEdge, edge) => addItems(resultFromLastEdge, this.generateBomForBottomEdge(edge)),
      newResult
    )

    return newResult
  }
  generateBomForRightEdge(edge: Edge): Item[] {
    const items = this.getLateralFlashing(edge, 'right')
    return this.addPrecompressedStripForLateralEdges(edge, items)
  }
  generateBomForLeftEdge(edge: Edge): Item[] {
    const items = this.getLateralFlashing(edge, 'left')
    return this.addPrecompressedStripForLateralEdges(edge, items)
  }
  generateBomForTopEdge(edge: Edge): Item[] {
    const preCompressedStripComponent = Array(Math.ceil(edge.length / 5500)).fill({
      name: 'ART101088',
      components: [],
    })

    if (this.input.options?.topConnection === 'flashing' && !edge.hasInnerCorners) {
      const lengthPerTopFlashing = 1660 // 1500 + 160 = flashing length, plus space between flashing for the junction
      const topFlashingComponents = Array(Math.ceil(edge.length / lengthPerTopFlashing)).fill({
        name: 'ART101041',
        components: [],
      })
      const topCornerPieceFlashingComponents = Array(topFlashingComponents.length).fill({
        name: 'ART101409',
        components: [],
      })
      const popRivetQty = topFlashingComponents.length * 4
      const popRivets = Array(popRivetQty).fill({
        name: 'ART100389',
        components: [],
      })
      const topJunctionFlashingQty = topFlashingComponents.length - 1
      const topJunctionFlashingComponents = Array(topJunctionFlashingQty).fill({
        name: 'ART100039',
        components: [],
      })
      const flashingHookQty = topFlashingComponents.length * 2 + topJunctionFlashingQty
      const flashingHooks = Array(flashingHookQty).fill({
        name: 'ART100633',
        components: [],
      })
      const flashingComponents = [
        ...topFlashingComponents,
        ...topCornerPieceFlashingComponents,
        ...popRivets,
        ...topJunctionFlashingComponents,
        ...flashingHooks,
        ...preCompressedStripComponent,
      ]
      return flashingComponents
    } else {
      const topFlexibleStripComponents = Array(Math.ceil(edge.length / 2300)).fill({
        name: this.input.options?.flexibleStrip === 330 ? 'ART100385' : 'ART101405',
        components: [],
      })
      return [...topFlexibleStripComponents, ...preCompressedStripComponent]
    }
  }
  generateBomForBottomEdge(edge: Edge): Item[] {
    return [
      ...Array(Math.ceil(edge.length / 2300)).fill({
        name: this.input.options?.flexibleStrip === 330 ? 'ART100385' : 'ART101405',
        components: [],
      }),
      // pre-compressed seal strip for bottom edges
      ...Array(Math.ceil(edge.length / 5500)).fill({
        name: 'ART101088',
        components: [],
      }),
    ]
  }

  getLateralFlashing(edge: Edge, edgeLocation: 'left' | 'right'): Item[] {
    const flashingLength = 1290
    const flashingBorder = 120 // flashing's placed 120mm below the upper edge of the first row
    const flashingOverlap = 150 // two joined flashing strips overlap by 150mm
    const flashingWidth = 150 // THIS IS AN ESTIMATE, CAN'T FIND IT IN DOCUMENTATION. This value is only relevant for displaying the component within design studio, it doesn't effect the calculated BOM.
    const flashingQty = Math.ceil((edge.length - flashingBorder) / (flashingLength - flashingOverlap))
    var top = edge.top - flashingBorder
    var items: Item[] = []
    for (let flashingCount = 0; flashingCount < flashingQty; flashingCount++) {
      let flashing: Item = {
        name: 'ART104644',
        components: [
          {
            type: MountingComponentType.OTHER,
            name: 'ART104644',
            top,
            left: edgeLocation === 'left' ? edge.left - flashingWidth : edge.left,
          },
        ],
      }
      let flashingHook: Item = {
        name: 'ART100633',
        components: [
          {
            type: MountingComponentType.OTHER,
            name: 'ART100633',
            top: top + flashingLength / 2,
            left: edge.left - flashingWidth / 2,
          },
        ],
      }
      items.push(flashing, flashingHook, flashingHook)
      top += flashingLength - flashingOverlap
    }

    // pre-compressed strip included
    items.push(
      ...Array(Math.ceil(edge.length / 5500)).fill({
        name: 'ART101088',
        components: [],
      })
    )

    return items
  }

  addPrecompressedStripForLateralEdges(edge: Edge, items: Item[]): Item[] {
    items.push(
      ...Array(Math.ceil(edge.length / 5500)).fill({
        name: 'ART101088',
        components: [],
      })
    )
    return items
  }
}

class GsePerOuterCornerBomGenerator extends PerOuterCornerBomGeneratorAbstract {
  generateBomForBottomLeftCorner(corner: Corner): Item[] {
    return []
  }
  generateBomForBottomRightCorner(corner: Corner): Item[] {
    return []
  }
  generateBomForTopLeftCorner(corner: Corner): Item[] {
    return this.getCornerFlashing(corner, 'left')
  }
  generateBomForTopRightCorner(corner: Corner): Item[] {
    return this.getCornerFlashing(corner, 'right')
  }

  getCornerFlashing(corner: Corner, cornerLocation: 'left' | 'right'): Item[] {
    let componentNames = [cornerLocation === 'left' ? 'ART100945' : 'ART100574', 'ART100633', 'ART100633']
    let items: Item[] = []
    componentNames.forEach((component) => {
      items.push({
        name: component,
        components: [
          {
            type: MountingComponentType.OTHER,
            name: component,
            top: corner.top,
            left: corner.left,
          },
        ],
      })
    })
    return items
  }
}

class GsePerRowBomGenerator extends PerRowClampBomGeneratorAbstract {
  // Landscape and portrait use the same components, so we have shared functions

  chooseMidClamp(row: ContiguousPanelRow) {
    return chooseMidClamp(row)
  }

  chooseEndClamp(row: ContiguousPanelRow) {
    return chooseEndClamp(row)
  }

  getClampPositions(input: MountingCalcInput, panel: PanelWithPosition): HasPosition[] {
    // Positions have been estimated visually from the diagram in the installation manual
    const totalEdgeLength = panel.orientation === 'landscape' ? panel.width : panel.height
    var clampPositions = [
      { top: totalEdgeLength / 4, left: 0 },
      { top: totalEdgeLength - totalEdgeLength / 4, left: 0 },
    ]
    const clampQty = input.options?.clampQty
    if (clampQty === '6 or 8') {
      clampPositions.push({ top: totalEdgeLength - totalEdgeLength / 4 - 60, left: 0 })
      if (getCompatibleTrays(panel, panel.orientation)[0].size === 'half') {
        clampPositions.push({ top: totalEdgeLength / 4 + 60, left: 0 })
      }
    }
    return clampPositions
  }
}

function chooseMidClamp(row: ContiguousPanelRow): Item {
  const clampSize = getCompatibleClampSize(row[0])
  const componentCode = clampSize + ' Mid Clamp'
  return {
    name: clampComponentReference[componentCode],
    components: [
      {
        name: clampComponentReference[componentCode],
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseEndClamp(row: ContiguousPanelRow): Item {
  const clampSize = getCompatibleClampSize(row[0])
  const componentCode = clampSize + ' End Clamp'
  return {
    name: clampComponentReference[componentCode],
    components: [
      {
        name: clampComponentReference[componentCode],
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

type ClampSize = 'H16' | 'H19' | 'H21' | 'H23' | 'H26' | 'H27' | 'H31'

function getCompatibleClampSize(panel): ClampSize | null {
  const trays = getCompatibleTrays(panel, panel.orientation)
  const trayVersion = trays[0].version
  const panelThickness = panel.thickness
  var clampSize: ClampSize | null = null
  if (panelThickness) {
    if (trayVersion === '2012') {
      clampSize =
        panelThickness <= 37
          ? 'H16'
          : panelThickness <= 39
          ? 'H19'
          : panelThickness <= 41
          ? 'H21'
          : panelThickness <= 44
          ? 'H23'
          : panelThickness <= 46
          ? 'H26'
          : panelThickness <= 48
          ? 'H27'
          : panelThickness === 50
          ? 'H31'
          : null
    } else if (trayVersion === '2020' || trayVersion === '2022') {
      clampSize =
        panelThickness <= 32
          ? 'H16'
          : panelThickness <= 34
          ? 'H19'
          : panelThickness <= 36
          ? 'H21'
          : panelThickness <= 39
          ? 'H23'
          : panelThickness <= 41
          ? 'H26'
          : panelThickness <= 43
          ? 'H27'
          : panelThickness <= 47
          ? 'H31'
          : null
    }
  }
  if (!clampSize) console.log('err') // TODO : Display an error to the user and stop BOM generation
  return clampSize
}

class GseBlackScrewPerClampAdder extends PostProcessorAbstract {
  process(result: MountingCalcResult): MountingCalcResult {
    // add 1 black screw per clamp
    const componentsToAdd: Item[] = []

    result.items.forEach((item) => {
      item.components.forEach((component) => {
        if (component.type === MountingComponentType.CLAMP) {
          componentsToAdd.push({
            name: 'VIS2014N',
            components: [],
          })
        }
      })
    })

    result.items.concat(componentsToAdd)

    return result
  }
}

class GseWedgePerEndClampAdder extends PostProcessorAbstract {
  process(result: MountingCalcResult): MountingCalcResult {
    // add 1 wedge per end clamp
    const componentsToAdd: Item[] = []
    const clampComponentReferenceCodes = Object.values(clampComponentReference)

    result.items.forEach((item) => {
      const totalEndClamps = item.components.filter((component) =>
        clampComponentReferenceCodes.includes(component.name)
      ).length
      // half the number of end clamps since GSE-WEDGES-TWINPACK contains 2 left-right wedges
      componentsToAdd.push(...Array(totalEndClamps / 2).fill({ name: 'GSE-WEDGES-TWINPACK', components: [] }))
    })

    result.items.concat(componentsToAdd)

    return result
  }
}
