import { jsx as _jsx } from "react/jsx-runtime";
import { BaseTheme } from '../theme/BaseTheme';
// Need to use this version of ThemeProvider to support makeStyles
import { ThemeProvider as ThemeProvider2 } from '@mui/styles';
import { ThemeProvider } from '@mui/material';
// Wrapper on <ThemeProvider> for opensolar-ui
// At some point this will handle theme switching
export var ThemeContext = function (_a) {
    var children = _a.children;
    var theme = BaseTheme;
    return (_jsx(ThemeProvider, { theme: theme, children: _jsx(ThemeProvider2, { theme: theme, children: children }) }));
};
