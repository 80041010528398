import { useNotify } from 'ra-core'
import { useEffect, useState } from 'react'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { reloadEntireApp } from 'util/misc'
import { SolarAppErrorResponsePayload, SolarAppProjectStatus } from './types'
import { getErrorMessage } from './utils'

type useFetchProjectStatusReturnType = {
  isLoading: boolean
  errorMsg?: string
  solarAppProjectStatus?: SolarAppProjectStatus
}

export const useFetchProjectStatus = (project: ProjectType): useFetchProjectStatusReturnType => {
  const [solarAppProjectStatus, setSolarAppProjectStatus] = useState<SolarAppProjectStatus | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>()

  useEffect(() => {
    if (project?.custom_data?._solarapp_permit) {
      const restClientInstance = restClient(window.API_ROOT + '/api')
      setIsLoading(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${project.org_id}/${project.id}/solarapp_project_status/`,
      })
        .then((response: any) => {
          const solarAppProjectStatus: SolarAppProjectStatus = response?.data
          setSolarAppProjectStatus(solarAppProjectStatus)
        })
        .catch((error: any) => {
          const errorResponse = error?.body as SolarAppErrorResponsePayload

          setErrorMsg(getErrorMessage(errorResponse))

          if (errorResponse) {
            if (errorResponse?.error_code) {
              console.error('SolarAPP+ error_code:', errorResponse.error_code)
            } else {
              console.error(error)
            }
          } else {
            console.error('Network or unknown error:', error)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }, [project])

  return { isLoading, errorMsg, solarAppProjectStatus }
}

export const useRemoveSelectedProject = (
  project: ProjectType,
  updateSolarAppProjectDetails?: (newId?: string, newUrl?: string) => void
) => {
  const notify = useNotify()
  const [isLoading, setIsLoading] = useState(false)

  const handleRemoveProject = () => {
    setIsLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${project.org_id}/${project.id}/solarapp_remove_selected_project/`,
    })
      .then((response: any) => {
        if (updateSolarAppProjectDetails) {
          updateSolarAppProjectDetails(undefined, undefined)
        } else {
          reloadEntireApp()
        }
        if (response?.data?.message) notify(response?.data?.message, 'success')
      })
      .catch((error: any) => {
        const errorResponse = error?.body as SolarAppErrorResponsePayload
        const errorMsg = getErrorMessage(errorResponse)

        if (errorResponse) {
          if (errorResponse?.error_code) {
            console.error('SolarAPP+ error_code:', errorResponse.error_code)
          } else {
            console.error(error)
          }
        } else {
          console.error('Network or unknown error:', error)
        }
        notify(errorMsg, 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return { isLoading, handleRemoveProject }
}
