import { HideShowTypes, recurseSections, SectionInfo } from 'constants/uiSections'
import lodash from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSdkSingletonReady } from 'sdk/useSdkSingletonReady'
import appStorage from 'storage/appStorage'
import { RootState } from 'types/state'
import { isNestedWindow } from 'util/isNestedWindow'
import { getFeatureFlagSelector } from 'util/split'
import { authSelectors } from './auth'
import { orgSelectors } from './orgs'
import { permissionsSelectors } from './permissions'

export const SET_IS_USER_LITE = 'SET_IS_USER_LITE'
export const SET_IS_PROJECT_LITE = 'SET_IS_PROJECT_LITE'
export const SET_HIDE_TOUR_BANNER = 'SET_HIDE_TOUR_BANNER'
export const SET_SHOW_PROPOSAL_DRAWER = 'SET_SHOW_PROPOSAL_DRAWER'
export const SET_DESIGN_MODE_STATE = 'SET_DESIGN_MODE_STATE'
export const SET_HOME_DRAWER_STATE = 'SET_HOME_DRAWER_STATE'
export const SET_BRANDING_STATE = 'SET_BRANDING_STATE'
export const SET_VIEW_OVERRIDES = 'SET_VIEW_OVERRIDES'

export type DesignModeState = undefined | 'open-locked' | 'closed-interactive' | 'open-interactive' | 'open-locked'
export type HomeDrawerState = undefined | 'recent' | 'activity' | 'overview' | 'learn'
export type BrandingState = 'ironridge' | 'opensolar'

export type ViewModeType = {
  isUserLite: boolean | undefined
  isProjectLite: boolean | undefined
  uxPreference: number | undefined
  showProposalDrawer: boolean
  designModeState: DesignModeState
  homeDrawerState: HomeDrawerState
  brandingState: BrandingState

  // These get set by the SDK for 3rd parties to control UI elements
  viewOverrides: ViewOverrides
}
// version number in this key is to ignore the value which may be stored from the UX1 > UX2 transition
export const UX_PREF_KEY = 'uxPreference_v3'

const getInitialState = (): ViewModeType => ({
  isUserLite: undefined,
  isProjectLite: undefined,
  uxPreference: appStorage.getNumber(UX_PREF_KEY),
  showProposalDrawer: false,
  designModeState: undefined,
  homeDrawerState: appStorage.getString('recent_active_tab') as HomeDrawerState,
  brandingState: (appStorage.getString('branding_state') || 'opensolar') as BrandingState,
  viewOverrides: {},
})

function validateState(state: ViewModeType) {
  appStorage.setString('recent_active_tab', state.homeDrawerState)
  appStorage.setString('branding_state', state.brandingState || 'opensolar')
}

export default function reducer(state: ViewModeType = getInitialState(), action: ActionType): ViewModeType {
  const ret = innerReducer(state, action)
  if (ret !== state) validateState(ret)
  return ret
}

function innerReducer(state: ViewModeType, action: ActionType): ViewModeType {
  switch (action.type) {
    case SET_IS_USER_LITE:
      return {
        ...state,
        isUserLite: action.payload,
      }
    case SET_IS_PROJECT_LITE:
      return {
        ...state,
        isProjectLite: action.payload,
      }
    case SET_SHOW_PROPOSAL_DRAWER:
      return {
        ...state,
        showProposalDrawer: action.payload,
      }
    case SET_DESIGN_MODE_STATE:
      return {
        ...state,
        designModeState: action.payload,
      }
    case SET_HOME_DRAWER_STATE:
      return {
        ...state,
        homeDrawerState: action.payload,
      }
    case SET_BRANDING_STATE:
      return {
        ...state,
        brandingState: action.payload,
      }
    case SET_VIEW_OVERRIDES:
      return {
        ...state,
        viewOverrides: action.payload,
      }
    default:
      return state
  }
}

interface SetDesignModeState {
  type: typeof SET_DESIGN_MODE_STATE
  payload: DesignModeState
}

interface SetHomeDrawerState {
  type: typeof SET_HOME_DRAWER_STATE
  payload: HomeDrawerState
}

interface SetBrandingState {
  type: typeof SET_BRANDING_STATE
  payload: BrandingState
}

interface SetViewOverridesProp {
  type: typeof SET_VIEW_OVERRIDES
  payload: ViewOverrides
}

interface SetBoolProp {
  type: typeof SET_SHOW_PROPOSAL_DRAWER | typeof SET_IS_USER_LITE | typeof SET_IS_PROJECT_LITE
  payload: boolean
}

type ActionType = SetBoolProp | SetDesignModeState | SetHomeDrawerState | SetBrandingState | SetViewOverridesProp

export const viewModeActions = {
  setIsUserLite(value: boolean): SetBoolProp {
    return {
      type: SET_IS_USER_LITE,
      payload: value,
    }
  },

  setIsProjectLite(value: boolean): SetBoolProp {
    return {
      type: SET_IS_PROJECT_LITE,
      payload: value,
    }
  },
  setShowProposalDrawer(value: boolean): SetBoolProp {
    return {
      type: SET_SHOW_PROPOSAL_DRAWER,
      payload: value,
    }
  },
  setDesignModeState(value: DesignModeState): SetDesignModeState {
    return {
      type: SET_DESIGN_MODE_STATE,
      payload: value,
    }
  },

  setHomeDrawerState(value: HomeDrawerState): SetHomeDrawerState {
    return {
      type: SET_HOME_DRAWER_STATE,
      payload: value,
    }
  },

  setViewOverrides(value: ViewOverrides): SetViewOverridesProp {
    return {
      type: SET_VIEW_OVERRIDES,
      payload: value,
    }
  },
  setBrandingState(value: BrandingState): SetBrandingState {
    return {
      type: SET_BRANDING_STATE,
      payload: value,
    }
  },
}

export function testShow(state: RootState, key: string) {
  if (key.charAt(key.length - 1) === '.') key = key.substring(0, key.length - 1)

  const parts = lodash.toPath(key)
  let pathHere = ''
  let override: ViewOverride | undefined
  for (const part of parts) {
    pathHere += part
    override = state.viewMode.viewOverrides[pathHere]
    pathHere += '.'
  }
  if (override?.show === false) return false

  const isAdmin = authSelectors.getIsAdmin(state)
  const hasAssessRight = permissionsSelectors.hasAssessRight(state)
  const hasAllOrgFeatures = orgSelectors.hasAllOrgFeatures(state)

  let show = true
  recurseSections(key, (info: SectionInfo, key: string, path: string) => {
    const override: ViewOverride = state.viewMode.viewOverrides[path]

    if (
      override?.show === false ||
      (info.feature && !getFeatureFlagSelector(state)(info.feature, 'on')) ||
      (info.show && !matchList(state, info.show)) ||
      (info.hide && matchList(state, info.hide)) ||
      (info.requireAccessRight && !hasAssessRight(info.requireAccessRight)) ||
      (info.orgFeatures && !hasAllOrgFeatures(info.orgFeatures)) ||
      (info.isAdmin !== undefined && isAdmin !== info.isAdmin)
    ) {
      show = false
      return false
    }
  })
  return show
}

export const viewModeSelectors = {
  show: (state: RootState) => (key: string) => {
    return testShow(state, key)
  },
  isUserLite: (state: RootState) => {
    return state.viewMode.isUserLite
  },
  isProjectLite: (state: RootState) => {
    return state.viewMode.isProjectLite
  },
  fujiTourEnabled: (state: RootState) => {
    return state.viewMode.isUserLite === false
  },
  showProposalDrawer: (state: RootState) => {
    return state.viewMode.showProposalDrawer
  },
  designModeState: (state: RootState) => {
    return state.viewMode.designModeState
  },
  homeDrawerState: (state: RootState) => {
    return state.viewMode.homeDrawerState
  },
  brandingState: (state: RootState) => {
    return state.viewMode.brandingState
  },
  getUxPreference: (state: RootState) => {
    return state.viewMode.uxPreference
  },
  viewOverrides: (state: RootState) => {
    return state.viewMode.viewOverrides
  },
}

function matchList(state: RootState, list: HideShowTypes[]): boolean {
  if (
    (state.auth?.is_superuser && list.includes('superuser')) ||
    (state.auth?.is_staff && list.includes('staff')) ||
    (state.viewMode.isUserLite && list.includes('lite')) ||
    (state.auth?.user?.login_authority && list.includes('sso')) ||
    (!state.auth?.user?.login_authority && list.includes('!sso')) ||
    (state.auth?.user?.is_mfa_enabled && list.includes('mfa')) ||
    (state.auth?.user?.login_authority === 'nearmap' && list.includes('nearmap'))
  ) {
    return true
  } else {
    return false
  }
}

// Hooks
export function useViewShow(uiKey: string | undefined) {
  const checker = useViewShowChecker()
  return useMemo(() => {
    return !uiKey || checker(uiKey)
  }, [checker, uiKey])
}

export function useViewShowChecker() {
  const sdk = useSdkSingletonReady()
  const viewOverrides = useSelector(viewModeSelectors.viewOverrides)

  // When using SDK do not show anything until config has been loaded otherwise we may
  // show UI elements that should be hidden but we won't know if they should be hidden
  // until config has loaded. sdk is only populated once config is loaded and ready so
  // as soon as sdk is populated we can be confident that the config will be ready.
  const isNestedWindowAwaitingConfig = isNestedWindow() && !sdk

  const showByKey = useMemo(
    () => (state: RootState) => {
      return (uiKey: string) => {
        if (isNestedWindowAwaitingConfig) {
          return false
        } else {
          return testShow(state, uiKey)
        }
      }
    },
    [viewOverrides, isNestedWindowAwaitingConfig]
  )

  return useSelector(showByKey)
}

export function useViewShowAsDict(uiKeys: string[], finalKeyPartOnly?: boolean): Record<string, boolean> {
  const viewOverrides = useSelector(viewModeSelectors.viewOverrides)
  const showByKeys = useMemo(
    () => (state: RootState) => {
      const result = {}
      for (const uiKey of uiKeys) {
        result[uiKey] = testShow(state, uiKey)
      }
      return result
    },
    [uiKeys, viewOverrides]
  )
  const result = useSelector(showByKeys)
  if (finalKeyPartOnly) {
    // Use the final part of the path only to avoid needing to use the full path.
    // e.g. {"section.area.elementA": true} becomes => {"elementA": true}
    const resultFinalKeyPartsOnly = {}
    for (const key in result) {
      resultFinalKeyPartsOnly[key.split('.').pop() || 'missing'] = result[key]
    }
    return resultFinalKeyPartsOnly
  } else {
    return result
  }
}

export function useShowTabs(): string[] {
  return Object.entries(
    useViewShowAsDict([
      'studio.tabs.summary',
      'studio.tabs.panels',
      'studio.tabs.mounting',
      'studio.tabs.inverters',
      'studio.tabs.batteries',
      'studio.tabs.others',
      'studio.tabs.pricing',
      'studio.tabs.payment_options',
      'studio.tabs.scaffolding',
      'studio.tabs.incentives',
    ])
  )
    .filter(([key, value]) => value)
    .map(([key, value]) => key.replace('studio.tabs.', ''))
}

export const isEmbeddedWithSdk = () => {
  /*
  There are many better ways to detect this but this is quick and reliable for now
   */
  return window !== window.parent
}

// Duplicated in the SDK
export type ViewOverrides = {
  [key: string]: ViewOverride
}
export type ViewOverride = {
  show?: boolean
}
