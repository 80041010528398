import { orgSelectors } from 'ducks/orgs'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import CashFlowConfigurationNav from './CashFlowConfigurationNav'

type PropTypes = {}

const LinksWrapper = styled('div')({
  display: 'flex',
  top: 50,
  height: 50,
  padding: '0 20px',
  borderTop: '1px solid #0000001a',
  boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
  alignItems: 'center',
  flexDirection: 'row',
  position: 'fixed',
  width: '100%',
  justifyContent: 'center',
  background: 'white',
  zIndex: 1100,
})

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  padding: '16px 24px',
  letterSpacing: 0.5,
  display: 'flex',
  alignItems: 'center',
}))

const Badge = styled('div')({
  height: '8px',
  width: '8px',
  marginLeft: '5px',
  borderRadius: '8px',
  backgroundColor: 'rgba(193, 42, 46, 0.95)',
})

const CashFlowSubHeader: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const location = useLocation()

  const configureMatch = useRouteMatch('/cashflow/configure')
  const isConditionallyApproved = useSelector(orgSelectors.getCashFlowOnboardingStatus) === 'conditionally approved'

  return (
    <div style={{ paddingTop: 50 }}>
      <LinksWrapper>
        <StyledNavLink
          to="/cashflow/projects"
          activeStyle={{
            fontWeight: 'bold',
          }}
        >
          {translate('Projects')}
        </StyledNavLink>
        <StyledNavLink
          to="/cashflow/invoices"
          activeStyle={{
            fontWeight: 'bold',
          }}
        >
          {translate('Invoices')}
        </StyledNavLink>
        <StyledNavLink
          to="/cashflow/configure/milestones"
          isActive={() => !!configureMatch}
          activeStyle={{
            fontWeight: 'bold',
          }}
        >
          {translate('Configure')}
          {isConditionallyApproved && <Badge />}
        </StyledNavLink>
      </LinksWrapper>

      {location.pathname.includes('configure') && <CashFlowConfigurationNav />}
    </div>
  )
}

export default CashFlowSubHeader
