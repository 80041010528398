import { Button, FormControlLabel, InputLabel, RadioGroup, Tooltip, Typography } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import _ from 'lodash'
import { BaseTheme, ComponentVersionsInherit, HelpCircleIcon, Radio } from 'opensolar-ui'
import { MAX_HEIGHT_AND_LENGTH_PVF, Scenario } from 'projectSections/sections/manage/scaffoldTile/constants'
import {
  canCalculateScaffoldCost,
  initializePvfCustomData,
  initializeScaffoldProperties,
  validateScaffoldProperties,
} from 'projectSections/sections/manage/scaffoldTile/utils'
import { useEffect, useState } from 'react'
import { showNotification, useLocale } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { MeasurementTypes } from 'types/studio/controllers'
import { doNotTranslate, formatCurrencyWithSymbol } from 'util/misc'
import useStyles from './scaffoldStyle'

const restClientInstance = restClient(window.API_ROOT + '/api')

const ScaffoldingEstimate = () => {
  const project = useFormState().values as ProjectType
  const projectId = project.id
  const form = useForm()
  let customData = project?.custom_data?.scaffolding
  const [errors, setErrors] = useState({})
  const featureConfig = usePublicFeatureConfig('pvf_scaffolding_cost')

  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const locale = useLocale()

  const initializeCustomData = initializePvfCustomData(project)
  if (!project.custom_data || (project.custom_data && Object.keys(project.custom_data).length === 0)) {
    form.change('custom_data.scaffolding', initializeCustomData)
  }

  useEffect(() => {
    window.editor.filter('type', 'OsSystem').forEach((system) => {
      window.Designer.requestSystemCalculations(system)
    })
    form.change('custom_data.scaffolding', initializeCustomData)
  }, [])

  const calculateCost = () => {
    // check if the scaffold cost can be calculated on the spot or not
    if (!canCalculateScaffoldCost(customData, featureConfig)) {
      form.batch(() => {
        form.change('custom_data.scaffolding.estimated_cost', null)
        form.change('custom_data.scaffolding.access_through_house_cost', null)
        form.change('custom_data.scaffolding.pavement_cost', null)
        form.change('custom_data.scaffolding.scenario', Scenario.CREATE_QUOTE)
      })

      window.editor.filter('type', 'OsSystem').forEach((system) => {
        window.Designer.requestSystemCalculations(system)
      })
      return
    }
    let url = `calculate_scaffolding_cost/`
    if (customData?.form_data.scaffold_properties?.height) {
      url = url + `?height=${customData.form_data.scaffold_properties.height}`
    }
    if (customData?.form_data.scaffold_properties?.length) {
      url = url + `&length=${customData.form_data.scaffold_properties.length}`
    }
    if (customData?.form_data.scaffold_properties?.require_access_through_house_building) {
      url =
        url +
        `&access_through_house=${
          customData.form_data.scaffold_properties.require_access_through_house_building === 'Yes' ? 1 : 0
        }`
    }
    if (customData?.form_data.scaffold_properties?.scaffold_on_pavement_or_road) {
      url =
        url +
        `&pavement_permit_non_london=${
          customData.form_data.scaffold_properties.scaffold_on_pavement_or_road === 'Yes' ? 1 : 0
        }`
    }
    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((res) => {
        let accessThroughHouseCost: number | null = null
        let pavementCost: number | null = null
        if (customData?.form_data.scaffold_properties.require_access_through_house_building === 'Yes') {
          accessThroughHouseCost = 100
        }
        if (customData?.form_data.scaffold_properties?.scaffold_on_pavement_or_road === 'Yes') {
          pavementCost = 100
        }
        const updatedCustomData = {
          ...customData,
          estimated_cost: res.data,
          scenario: Scenario.CREATE_QUOTE,
          access_through_house_cost: accessThroughHouseCost,
          pavement_cost: pavementCost,
        }
        form.change('custom_data.scaffolding', updatedCustomData)
        form.submit()
        dispatch(showNotification(doNotTranslate('Pricing has been updated to include scaffolding costs.')))
        window.editor.filter('type', 'OsSystem').forEach((system) => {
          window.Designer.requestSystemCalculations(system)
        })
      })
      .catch((err) => console.log('err', err))
  }

  const handleCalculateAndSave = (customData) => {
    const error = validateScaffoldProperties(customData)
    setErrors(error)
    if (Object.keys(error).length > 0) return
    calculateCost()
  }
  const handleInputChange = (e, name: string) => {
    const value = typeof e === 'number' ? e : e.target.value
    form.change(`custom_data.scaffolding.form_data.scaffold_properties.${name}`, value)
    setErrors({})
  }

  const getRoundedOrMax = (value: unknown): number => {
    const numValue: number = typeof _.toNumber(value) === 'number' ? _.toNumber(value) : 0
    return Math.min(Math.round(numValue * 2) / 2, MAX_HEIGHT_AND_LENGTH_PVF)
  }

  const handleMeasureHeightLength = (type: MeasurementTypes) => {
    window.editor.controllers.Measurement.start(type)
      .then((results) => {
        const measureResult: number = results[0].length
        if (measureResult > MAX_HEIGHT_AND_LENGTH_PVF)
          dispatch(showNotification(doNotTranslate(`Maximum is ${MAX_HEIGHT_AND_LENGTH_PVF}m`)))
        handleInputChange(getRoundedOrMax(measureResult), type)
      })
      .catch((e) => console.warn(e))
  }

  const clearFields = () => {
    const updatedScaffoldProperties = initializeScaffoldProperties()
    const updatedCustomData = {
      ...customData,
      form_data: { ...customData?.form_data, scaffold_properties: updatedScaffoldProperties },
      estimated_cost: 0,
      scenario: Scenario.ESTIMATE_COST,
      pavement_cost: 0,
      access_through_house_cost: 0,
    }
    form.change('custom_data.scaffolding', updatedCustomData)
    form.submit()
    window.editor.filter('type', 'OsSystem').forEach((system) => {
      window.Designer.requestSystemCalculations(system)
    })
  }

  return (
    <>
      <ComponentVersionsInherit versions={{ radio: 2 }}>
        {customData && (
          <div>
            <Typography variant="subtitle2" gutterBottom>
              {doNotTranslate('Start your scaffolding cost estimate')}
            </Typography>
            <div className={`${classes.items} ${classes.displayFlex}`}>
              <div>
                <div className={`${classes.displayFlex} ${classes.gap6}`}>
                  <InputLabel>{doNotTranslate(`Building Height (max ${MAX_HEIGHT_AND_LENGTH_PVF}m)`)}</InputLabel>
                  <Tooltip
                    title={
                      <div className={classes.tooltipContent}>
                        <img
                          alt="scaffold"
                          src={window.PUBLIC_URL + '/images/scaffold_measure_height.gif'}
                          style={{ height: 232, width: 'auto' }}
                        />
                        <Typography>
                          {doNotTranslate(
                            'You can measure scaffold height directly on the design page with our Scaffold tool.'
                          )}
                          <br />
                          {doNotTranslate(
                            `Maximum height for residential scaffolding is ${MAX_HEIGHT_AND_LENGTH_PVF}m.`
                          )}
                        </Typography>
                      </div>
                    }
                    PopperProps={{
                      style: {
                        zIndex: 2002,
                      },
                    }}
                    interactive
                    placement="bottom"
                    arrow
                    classes={{
                      arrow: classes.tooltipArrow,
                      tooltip: classes.tooltip,
                    }}
                  >
                    <span className={classes.tooltipIcon}>
                      <HelpCircleIcon size={14} color={BaseTheme.palette.grey[700]} />
                    </span>
                  </Tooltip>
                </div>
                <CustomNumberField
                  name={'height'}
                  minValue={0}
                  maxValue={MAX_HEIGHT_AND_LENGTH_PVF}
                  step={0.5}
                  maxErrorMsg={'Maximum is 40m'}
                  value={_.toNumber(customData?.form_data?.scaffold_properties?.height)}
                  transformFunc={getRoundedOrMax}
                  onChange={(e) => handleInputChange(e, 'height')}
                  endAdornment={'m'}
                />
                <div className={classes.measurer} onClick={() => handleMeasureHeightLength(MeasurementTypes.Height)}>
                  {doNotTranslate('Measure Height')}
                </div>
              </div>
              <div>
                <div className={`${classes.displayFlex} ${classes.gap6}`}>
                  <InputLabel>{doNotTranslate(`Scaffold Total Length (max ${MAX_HEIGHT_AND_LENGTH_PVF}m)`)}</InputLabel>
                  <Tooltip
                    title={
                      <div className={classes.tooltipContent}>
                        <img
                          alt="scaffold"
                          src={window.PUBLIC_URL + '/images/scaffold_gif.gif'}
                          style={{ height: 232, width: 'auto' }}
                        />
                        <Typography>
                          {doNotTranslate(
                            'You can measure scaffold length directly on the design page with our Scaffold tool.'
                          )}
                          <br />
                          {doNotTranslate(
                            `Maximum length for residential scaffolding is ${MAX_HEIGHT_AND_LENGTH_PVF}m.`
                          )}
                        </Typography>
                      </div>
                    }
                    PopperProps={{
                      style: {
                        zIndex: 2002,
                      },
                    }}
                    interactive
                    placement="bottom"
                    arrow
                    classes={{
                      arrow: classes.tooltipArrow,
                      tooltip: classes.tooltip,
                    }}
                  >
                    <span className={classes.tooltipIcon}>
                      <HelpCircleIcon size={14} color={BaseTheme.palette.grey[700]} />
                    </span>
                  </Tooltip>
                </div>
                <CustomNumberField
                  name={'length'}
                  minValue={0}
                  maxValue={MAX_HEIGHT_AND_LENGTH_PVF}
                  step={0.5}
                  maxErrorMsg={'Maximum is 40m'}
                  value={_.toNumber(customData?.form_data?.scaffold_properties?.length)}
                  transformFunc={getRoundedOrMax}
                  onChange={(e) => handleInputChange(e, 'length')}
                  endAdornment={'m'}
                />
                <div className={classes.measurer} onClick={() => handleMeasureHeightLength(MeasurementTypes.Length)}>
                  {doNotTranslate('Measure Length')}
                </div>
              </div>
              <div>
                <InputLabel className={classes.radioGap}>
                  {doNotTranslate('Is the scaffold on a pavement or road?')}
                </InputLabel>
                <RadioGroup
                  value={customData?.form_data?.scaffold_properties?.scaffold_on_pavement_or_road}
                  onClick={(e) => handleInputChange(e, 'scaffold_on_pavement_or_road')}
                  className={classes.radioGroupPosition}
                >
                  <FormControlLabel
                    label={'Yes'}
                    value="Yes"
                    control={
                      <Radio
                        checked={customData?.form_data?.scaffold_properties?.scaffold_on_pavement_or_road === 'Yes'}
                      />
                    }
                    className={classes.radioButtonPosition}
                  />
                  <FormControlLabel
                    label={'No'}
                    value="No"
                    control={
                      <Radio
                        checked={customData?.form_data?.scaffold_properties?.scaffold_on_pavement_or_road === 'No'}
                      />
                    }
                    className={classes.radioButtonPosition}
                  />
                </RadioGroup>
              </div>
              <div>
                <InputLabel className={classes.radioGap}>
                  {doNotTranslate('Does the scaffold site require access through the house/building?')}
                </InputLabel>
                <RadioGroup
                  value={customData?.form_data?.scaffold_properties?.require_access_through_house_building}
                  onClick={(e) => handleInputChange(e, 'require_access_through_house_building')}
                  className={classes.radioGroupPosition}
                >
                  <FormControlLabel
                    label={'Yes'}
                    value="Yes"
                    control={
                      <Radio
                        checked={
                          customData?.form_data?.scaffold_properties?.require_access_through_house_building === 'Yes'
                        }
                      />
                    }
                    className={classes.radioButtonPosition}
                  />
                  <FormControlLabel
                    label={'No'}
                    value="No"
                    control={
                      <Radio
                        checked={
                          customData?.form_data?.scaffold_properties?.require_access_through_house_building === 'No'
                        }
                      />
                    }
                    className={classes.radioButtonPosition}
                  />
                </RadioGroup>
              </div>
              <div>
                <InputLabel className={classes.radioGap}>
                  {doNotTranslate('Are there any obstructions that exceed 7 metres in width?')}
                  <Tooltip
                    title={
                      <div className={classes.tooltipContent}>
                        <img
                          alt="scaffold"
                          src={window.PUBLIC_URL + '/images/scaffold_obstruction_gif.gif'}
                          style={{ height: 232, width: 'auto' }}
                        />
                        <div>
                          {doNotTranslate(
                            'Obstructions may include conservatories, non load bearing garage roofs, large sheds, hot tubs etc.'
                          )}
                        </div>
                      </div>
                    }
                    PopperProps={{
                      style: {
                        zIndex: 2002,
                      },
                    }}
                    interactive
                    placement="bottom"
                    arrow
                    classes={{
                      arrow: classes.tooltipArrow,
                      tooltip: classes.tooltip,
                    }}
                  >
                    <span className={`${classes.tooltipIcon} ${classes.marginLeft3}`}>
                      <HelpCircleIcon size={14} color={BaseTheme.palette.grey[700]} />
                    </span>
                  </Tooltip>
                </InputLabel>
                <RadioGroup
                  value={customData?.form_data?.scaffold_properties?.obstructions_exceeding_7m}
                  onClick={(e) => handleInputChange(e, 'obstructions_exceeding_7m')}
                  className={classes.radioGroupPosition}
                >
                  <FormControlLabel
                    label={'Yes'}
                    value="Yes"
                    control={
                      <Radio
                        checked={customData?.form_data?.scaffold_properties?.obstructions_exceeding_7m === 'Yes'}
                      />
                    }
                    className={classes.radioButtonPosition}
                  />
                  <FormControlLabel
                    label={'No'}
                    value="No"
                    control={
                      <Radio checked={customData?.form_data?.scaffold_properties?.obstructions_exceeding_7m === 'No'} />
                    }
                    className={classes.radioButtonPosition}
                  />
                </RadioGroup>
              </div>
              <div>
                <div className={`${classes.displayFlex} ${classes.gap6}`}>
                  <InputLabel className={classes.radioGap}>
                    {doNotTranslate('Are there multiple properties that require scaffolding?')}
                    <Tooltip
                      title={doNotTranslate(
                        'If there are multiple buildings, e.g. a carport and a house, that require separate scaffolding, select "Yes"'
                      )}
                      PopperProps={{
                        style: {
                          zIndex: 2002,
                        },
                      }}
                      interactive
                      placement="bottom"
                      arrow
                      classes={{
                        arrow: classes.tooltipArrow,
                        tooltip: classes.tooltip,
                      }}
                    >
                      <span className={`${classes.tooltipIcon} ${classes.marginLeft3}`}>
                        <HelpCircleIcon size={14} color={BaseTheme.palette.grey[700]} />
                      </span>
                    </Tooltip>
                  </InputLabel>
                </div>
                <RadioGroup
                  value={customData?.form_data?.scaffold_properties?.multiple_properties}
                  onClick={(e) => handleInputChange(e, 'multiple_properties')}
                  className={classes.radioGroupPosition}
                >
                  <FormControlLabel
                    label={'Yes'}
                    value="Yes"
                    control={
                      <Radio checked={customData?.form_data?.scaffold_properties?.multiple_properties === 'Yes'} />
                    }
                    className={classes.radioButtonPosition}
                  />
                  <FormControlLabel
                    label={'No'}
                    value="No"
                    control={
                      <Radio checked={customData?.form_data?.scaffold_properties?.multiple_properties === 'No'} />
                    }
                    className={classes.radioButtonPosition}
                  />
                </RadioGroup>
              </div>
              {(customData.scenario === Scenario.CREATE_QUOTE || customData.scenario === Scenario.RECALCULATE_COST) &&
                (customData?.estimated_cost ? (
                  <div className={classes.costContainer}>
                    <div className={classes.costDisplay}>
                      <Typography className={classes.noteTitle}>{doNotTranslate('Scaffolding Cost')}</Typography>
                      <Typography className={classes.noteTitle}>
                        {formatCurrencyWithSymbol(customData.estimated_cost, '£', locale, 2)}
                      </Typography>
                    </div>
                    <div>
                      <div className={classes.costDisplay}>
                        <Typography className={classes.noteText}>
                          {doNotTranslate('Building Width and Scaffold Length')}
                        </Typography>
                        <Typography className={classes.noteText}>
                          {formatCurrencyWithSymbol(
                            customData.estimated_cost -
                              (customData && customData?.access_through_house_cost
                                ? customData.access_through_house_cost
                                : 0) -
                              (customData && customData?.pavement_cost ? customData.pavement_cost : 0),
                            '£',
                            locale,
                            2
                          )}
                        </Typography>
                      </div>
                      <div className={classes.costDisplay}>
                        <Typography className={classes.noteText}>{doNotTranslate('Access through house')}</Typography>
                        <Typography className={classes.noteText}>
                          {formatCurrencyWithSymbol(customData?.access_through_house_cost ?? 0, '£', locale, 2)}
                        </Typography>
                      </div>
                      {customData?.pavement_cost && (
                        <div>
                          <div className={classes.costDisplay}>
                            <Typography className={classes.noteText}>
                              {doNotTranslate('Pavement Permit - Non London')}
                            </Typography>
                            <Typography className={classes.noteText}>
                              {formatCurrencyWithSymbol(customData?.pavement_cost ?? 0, '£', locale, 2)}
                            </Typography>
                          </div>
                          <div className={`${classes.costDisplay} ${classes.noteText}`}>
                            {doNotTranslate(
                              'Because the project location is outside London, we’ve included an additional cost.'
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={classes.scaffoldingCostNote}>
                    <div className={classes.noteTitle}>{doNotTranslate('Scaffolding Cost')}</div>
                    <div className={classes.noteText}>
                      {doNotTranslate(
                        'Given the specifications of the project, our team needs to review everything carefully. This review is to make sure we give you the most accurate estimate possible.'
                      )}
                    </div>
                    <div className={classes.noteText}>
                      {doNotTranslate(
                        "All you need to do is create a quote, and we'll aim to get back to you with the cost within 2 business hours"
                      )}
                    </div>
                  </div>
                ))}
              {(customData.scenario === Scenario.MAKE_PAYMENT || customData.scenario === Scenario.CANCELLED) && (
                <div className={classes.boldDescription}>
                  {doNotTranslate('Cost of Scaffolding:')}{' '}
                  {customData.estimated_cost > 0
                    ? formatCurrencyWithSymbol(customData.estimated_cost, '£', locale, 2)
                    : 'TBC'}
                  <br />
                  {doNotTranslate('Installation date:')}{' '}
                  {new Date(customData?.form_data?.project_installation_date).toLocaleDateString('en-GB')}
                  {customData.order_status_note && (
                    <div>
                      {doNotTranslate('Note:')} {customData.order_status_note}
                    </div>
                  )}
                </div>
              )}
              {Object.keys(errors).length > 0 && (
                <div className={classes.errorText}>{doNotTranslate(errors['form_data'])}</div>
              )}
              <div>
                <Button
                  className={classes.fullWidth}
                  variant="contained"
                  color="default"
                  onClick={clearFields}
                  disabled={customData.scenario === Scenario.MAKE_PAYMENT || customData.scenario === Scenario.CANCELLED}
                >
                  <span>{'Clear Estimate'}</span>
                </Button>
                <Button
                  className={`${classes.fullWidth} ${classes.marginTop8} ${
                    customData.scenario === Scenario.ESTIMATE_COST ? classes.actionButton : ''
                  }`}
                  variant="contained"
                  color="default"
                  onClick={() => handleCalculateAndSave(customData)}
                  disabled={customData.scenario === Scenario.MAKE_PAYMENT || customData.scenario === Scenario.CANCELLED}
                >
                  <span>
                    {customData.scenario === Scenario.ESTIMATE_COST ? 'Calculate and Save Cost' : 'Recalculate Cost'}
                  </span>
                </Button>
                {customData.scenario !== Scenario.ESTIMATE_COST && (
                  <Button
                    className={`${classes.actionButton} ${classes.marginTop8}`}
                    variant="contained"
                    onClick={() => history.push(`/projects/${projectId}/manage`)}
                    endIcon={<OpenInNewIcon />}
                  >
                    <span>
                      {customData.scenario === Scenario.CREATE_QUOTE
                        ? customData.estimated_cost
                          ? 'Order Scaffold'
                          : 'Create Quote'
                        : customData.estimated_cost
                        ? 'Manage Scaffold Order'
                        : 'Manage Scaffold Quote'}
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </ComponentVersionsInherit>
    </>
  )
}

export default ScaffoldingEstimate
