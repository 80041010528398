import { Box, Grid } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { styled } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/query'
import DefaultPieChart from './DefaultPieChart'
import StatusPieChart from './StatusPieChart'
import SummaryCard from './SummaryCard'
import { useGetInvoicesSummary } from './utils'

type PropTypes = {
  refreshKey: number
}

const StyledBox = styled(Box)({
  background:
    'radial-gradient(circle, rgba(81,189,121,0.7511598389355743) 0%, rgba(26,177,82,0.5074623599439776) 0%, rgba(20,173,77,0.7567401960784313) 22%, rgba(19,152,68,0.8603816526610644) 100%)',
  padding: 24,
})

const StyledGrid = styled(Grid)({
  justifyContent: 'space-between',
})

const ChartWrapper = styled(Grid)({
  marginRight: '2rem',
  display: 'flex',
  placeContent: 'flex-end',
})

const InvoicesSummarySection: React.FC<PropTypes> = (props) => {
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const { invoicesSummary, invoiceSettingsLoading } = useGetInvoicesSummary(orgId, props.refreshKey)

  const location = useLocation()
  const params = parseQueryStringToDictionary(location.search)
  let status = params.filter !== undefined && JSON.parse(params.filter).status
  let searchVal = params.filter !== undefined && JSON.parse(params.filter).q

  const [label, setLabel] = useState<string>('')
  const [statusValue, setStatusValue] = useState<number>(0)
  const [statusFilter, setStatusFilter] = useState<string>('')

  useEffect(() => {
    if (status) {
      switch (status) {
        case 'expired':
          setLabel('Overdue')
          setStatusFilter('Overdue')
          setStatusValue(invoicesSummary?.overdue_amount || 0)
          break

        case 'completed':
          setLabel('Paid')
          setStatusFilter('Paid')
          setStatusValue(invoicesSummary?.collected_amount || 0)
          break

        case 'paid in full,pending,partially paid': // pending, approved, partially paid
          setLabel('In Progress')
          setStatusFilter('In Progress')
          setStatusValue(invoicesSummary?.in_progress_amount || 0)
          break

        case 'requested,viewed,payment failed,transfer instructions viewed':
          setLabel('Outstanding')
          setStatusFilter('Outstanding')
          setStatusValue(invoicesSummary?.outstanding_amount || 0)
          break

        default:
          setLabel('')
          setStatusFilter('')
          setStatusValue(0)
          break
      }
    } else {
      setStatusFilter('')
    }
  }, [invoicesSummary, invoiceSettingsLoading, status])

  return (
    <StyledBox>
      {invoiceSettingsLoading ? (
        <LoadingDots />
      ) : (
        <StyledGrid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SummaryCard
                  type="Outstanding"
                  totalCount={invoicesSummary?.outstanding_invoices}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                />
              </Grid>
              <Grid item xs={3}>
                <SummaryCard
                  type="Overdue"
                  totalCount={invoicesSummary?.overdue_invoices}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                />
              </Grid>

              <Grid item xs={3}>
                <SummaryCard
                  type="In Progress"
                  totalCount={invoicesSummary?.in_progress_invoices}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                />
              </Grid>
              <Grid item xs={3}>
                <SummaryCard
                  type="Paid"
                  totalCount={invoicesSummary?.paid_invoices}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                />
              </Grid>
            </Grid>
          </Grid>

          <ChartWrapper item xs={12} sm={9} lg={3}>
            {searchVal ? null : status ? (
              <StatusPieChart
                statusValue={statusValue}
                totalPayableAmount={invoicesSummary?.total_amount}
                statusLabel={label}
              />
            ) : (
              <DefaultPieChart
                totalPaidAmount={invoicesSummary?.collected_amount || 0}
                totalOutstandingAmount={invoicesSummary?.outstanding_amount || 0}
                totalOverdueAmount={invoicesSummary?.overdue_amount || 0}
                totalPayableAmount={invoicesSummary?.total_amount}
                totalInProgressAmount={invoicesSummary?.in_progress_amount || 0}
              />
            )}
          </ChartWrapper>
        </StyledGrid>
      )}
    </StyledBox>
  )
}

export default InvoicesSummarySection
