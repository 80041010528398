import React, { useState } from 'react'
import { ProjectType } from 'types/projects'
import { doNotTranslate } from 'util/misc'
import IntegrationRow, { ActionButton } from '../IntegrationRow'
import SolarAppCreateProjectDialog from './SolarAppDialogs/SolarAppCreateProjectDialog'

interface PropTypes {
  project: ProjectType
  updateSolarAppProjectDetails: (newId?: string, newUrl?: string) => void
}

const SolarAppProjectManager: React.FC<PropTypes> = ({ project, updateSolarAppProjectDetails }) => {
  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(false)

  return (
    <>
      <IntegrationRow
        title={doNotTranslate('SolarAPP+')}
        description={doNotTranslate('Manage permit approvals via SolarAPP+.')}
        logo={<img src={`${window.PUBLIC_URL}/images/solarapp_logo.png`} width="36px" height="23px" />}
      >
        <ActionButton
          onClick={() => {
            setShowCreateProjectDialog(true)
          }}
          color="secondary"
        >
          {doNotTranslate('Create SolarAPP+ Permit')}
        </ActionButton>
      </IntegrationRow>

      {showCreateProjectDialog && (
        <SolarAppCreateProjectDialog
          project={project}
          open={showCreateProjectDialog}
          updateSolarAppProjectDetails={updateSolarAppProjectDetails}
          onClose={() => setShowCreateProjectDialog(false)}
        />
      )}
    </>
  )
}

export default SolarAppProjectManager
