var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var TrashOutlineIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '20' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx("svg", __assign({ viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, rest, iconAttributes, { children: _jsx("g", { id: "trash-03", children: _jsx("path", { id: "Icon", d: "M3.33301 5.14706H16.6663M7.49967 2.5H12.4997M12.9163 17.5H7.08301C6.16253 17.5 5.41634 16.7099 5.41634 15.7353L5.03584 6.06614C5.01612 5.56486 5.3946 5.14706 5.86845 5.14706H14.1309C14.6047 5.14706 14.9832 5.56486 14.9635 6.06614L14.583 15.7353C14.583 16.7099 13.8368 17.5 12.9163 17.5Z", stroke: color, strokeWidth: "1.5", strokeLinecap: "round" }) }) })));
};
