import CustomField from 'elements/field/CustomField'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { styled } from 'opensolar-ui'
import ChipArrayInput from 'projectSections/sections/manage3/common/ChipsArrayInput'
import { ReferenceArrayInput } from 'react-admin'
import { ActionType, EventType } from 'types/tracking'

export const trackTagChanges = ({ addedTags, removedTags, findItemsInChoices, trackEvent }) => {
  if (addedTags.length > 0) {
    const addedItems = findItemsInChoices(addedTags)
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.EDIT },
      { eventName: 'User Added File Tag', tags: addedItems }
    )
  }

  if (removedTags.length > 0) {
    const removedItems = findItemsInChoices(removedTags)
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.EDIT },
      {
        eventName: 'User Removed File Tag',
        tags: removedItems.map((item, index) => (item ? item.title : removedTags[index])),
      }
    )
  }
}

const ChipsInputContainer = styled('div')(({ theme }) => ({
  '& .OSUI-TextField-root': {
    boxSizing: 'border-box',
    width: '100%',
    '& >div:first-of-type': {
      padding: 12,
      '& >div:first-of-type': {
        padding: 0,
        display: 'flex',
        flexFlow: 'row wrap',
        columnGap: 8,
        rowGap: 8,
      },
    },
  },
  '& .OSUI-Chip-root': {
    whiteSpace: 'normal',
    boxSizing: 'content-box',
    minHeight: 'fit-content',
    height: 'fit-content',
    padding: '5px 0px',
  },
  '& .OSUI-Chip-label': {
    height: 'fit-content',
    whiteSpace: 'normal',
    minHeight: 'fit-content',
    textOverflow: 'initial',
    marginRight: 5,
    marginTop: 2,
  },
}))

export const FileTagsChipsInput = (props) => {
  const { trackEvent } = useTrackComponent({
    componentKey: 'chip_array_input',
    eventName: 'User Updated File Tags',
  })

  return (
    <ChipsInputContainer>
      <CustomField
        id="file-tags-chips-input"
        name="file_tags"
        source="file_tags"
        component={ReferenceArrayInput}
        reference="file_tags"
        resource="file_tags"
        optionValueField="url"
        renderChildrenBeforeRecord
        fullWidth
        perPage={100}
        filterToQuery={(searchText) => ({ search: searchText, type: 'private' })}
      >
        <ChipArrayInput
          source="file_tags"
          optionText="title"
          optionValue="url"
          variant="outlined"
          resource="file_tags"
          reference="file_tags"
          trackTagChanges={(changes) => trackTagChanges({ ...changes, trackEvent })}
        />
      </CustomField>
    </ChipsInputContainer>
  )
}
