var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { default as Step } from '@material-ui/core/Step';
import { default as StepLabel } from '@material-ui/core/StepLabel';
import { default as Mui4Stepper } from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
var useStepperStyles = makeStyles({
    root: {
        padding: function (_a) {
            var orientation = _a.orientation;
            return (orientation === 'horizontal' ? '.5rem 0 1rem 0' : '24px');
        },
        '& .MuiButtonBase-root': {
            cursor: 'default',
        },
        '& .MuiStepIcon-root': {
            color: 'rgb(193 193 193 / 38%)',
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: '#FFDD52',
        },
        '& .MuiStepIcon-text': {
            fill: '#000',
        },
        '& .MuiStepLabel-label': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: '#019939',
        },
        '& .MuiTypography-root': {
            color: 'rgba(0, 0, 0, 0.87)',
        },
        '& .MuiStepLabel-label .MuiSvgIcon-root': {
            fontSize: '20px',
            margin: '0 10px',
        },
    },
});
export var Stepper = function (props) {
    var steps = props.steps, stepperProps = __rest(props, ["steps"]);
    var orientation = stepperProps.orientation;
    var stepperClasses = useStepperStyles({ orientation: stepperProps.orientation });
    return (_jsx(Mui4Stepper, __assign({ classes: stepperClasses }, stepperProps, { children: props.steps.map(function (step, index) { return (_jsx(Step, { completed: step.isComplete, children: _jsxs(StepLabel, { children: [step.content, orientation === 'horizontal' && index !== steps.length - 1 && _jsx(ChevronRight, {})] }) }, step.id)); }) })));
};
