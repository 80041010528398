import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import ImportIcon from '@material-ui/icons/PlayForWorkOutlined'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button } from 'opensolar-ui'
import { useCallback } from 'react'
import { SaveButton, Toolbar, useNotify, useRedirect, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { ActionType, EventType } from 'types/tracking'
import { capitalize } from 'util/misc'
const CreateEditToolbar = (props) => {
  const translate = useTranslate()
  const redirect = useRedirect()
  const notify = useNotify()

  const { trackEvent } = useTrackComponent({
    componentKey: props.resource,
    eventName: `User Edited ${capitalize(props.resource)}`,
  })

  const onSuccess = useCallback((response) => {
    const record = response.data
    if (record) {
      trackEvent(EventType.SYSTEM_EVENT, { type: ActionType.SAVE }, { id: record.id, title: record.title })
      notify('ra.notification.updated', 'success')
      redirect(props.redirect, `/${props.resource}`)
    }
  }, [])

  return (
    <Toolbar style={{ padding: 0 }} {...props}>
      {!props.hideCancelButton && (
        <Link style={{ textDecoration: 'none' }} to={`/${props.resource}`}>
          <Button
            variant="contained"
            color="default"
            // classes={createButtonClasses}
            startIcon={<AlertError />}
            style={{ margin: 10, position: 'relative' }}
            // backgroundColor="#D8D8D8"
          >
            <span>{translate('Cancel')}</span>
          </Button>
        </Link>
      )}
      <SaveButton
        //label="Save"
        redirect={false}
        submitOnEnter={true}
        raised={true}
        disabled={
          props.disabled || (props.record.org && props.record.org.indexOf('/orgs/' + props.org_id + '/') === -1)
        }
        onClick={() => {
          trackEvent(
            EventType.USER_INTERACTION,
            { type: ActionType.CLICK },
            { eventName: `User Clicked On ${capitalize(props.resource)} Save Button` }
          )
        }}
        onSave={(values, redirect, form, defaultSaveFunc) => {
          defaultSaveFunc(values, redirect, { onSuccess })
        }}
      />
      {props.record.org && props.record.org.indexOf('/orgs/' + props.org_id + '/') === -1 && (
        <Button
          startIcon={<ImportIcon />}
          // classes={createButtonClasses}
          redirect={false}
          submitOnEnter={false}
          raised={true}
          onClick={(event) =>
            props.duplicate({
              resource: props.resource,
              id: props.record.id,
            })
          }
        >
          <span>{translate('Import')}</span>
        </Button>
      )}
    </Toolbar>
  )
}
export default CreateEditToolbar
