// import { Dialog, DialogContent, DialogTitle, FormLabel, LinearProgress, styled, Typography } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import {
  CameraIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  FileIcon,
  FormLabel,
  styled,
  Typography,
  UploadIcon,
} from 'opensolar-ui'

import { Button, DialogActions } from 'opensolar-ui'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FileField, FileInput, FunctionField, useNotify, useRefresh, useTranslate } from 'react-admin'
import { Field, Form, useField, useForm } from 'react-final-form'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { PrivateFileType } from 'types/privateFile'
import { ActionStatus, ActionType, EventType } from 'types/tracking'
import { FileTagsChipsInput } from '../formFields/FileTagsChipsInput'
import { ContentWrapper, InputRow } from '../styles/dialogAndFieldsStyles'
import { LoadingOverlay } from '../styles/styles'
import { imgOrPdfTypeExtensionsAsString } from '../utils'

export const ErrorArea = styled('div')(({ theme }) => ({
  marginBottom: 14,
}))

export const FileInputContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  marginTop: -20,
  marginBottom: 15,
  '& .previews > div': {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignContent: 'baseline',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 12,
    paddingLeft: 12,
    border: '2px solid',
    borderColor: theme.palette.grey[300],
  },
}))

export const FileInputPlaceholderContainer = styled('div')(({ theme }) => ({
  border: '3px dashed',
  borderColor: theme.palette.grey[300],
  backgroundColor: 'white',
  margin: -7,
  borderRadius: 5,
  padding: 24,
}))

export const FileInputPlaceholderIcon = styled('div')(({ theme }) => ({
  '& svg': {
    width: 50,
    height: 50,
    marginTop: 10,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
    padding: 8,
  },
}))

export const FileInputHelperText = styled('p', { name: 'FileInputHelperText' })(({ theme }) => ({
  '& span': {
    color: theme.palette.blue[600],
  },
}))

export const SelectedFileContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  '& p': {
    fontWeight: 500,
  },
  '& span': {
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: 12,
  },
}))

export const StyledFileIcon = styled(FileIcon)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: 4,
  borderRadius: 5,
  color: theme.palette.grey[700],
}))

type PropTypes = {
  isOpen: boolean
  onDismiss: () => void
  dialogSelected: string
  projectId: string
  projectUrl: string
  orgId: string
  onSuccess: () => void
}

type FileType = {
  part_number: number
  file: File
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const MultipleFilesDialog: React.FunctionComponent<PropTypes> = (props) => {
  const fileTypesAccepted = props.dialogSelected === 'upload_file' ? '' : imgOrPdfTypeExtensionsAsString
  const placeholderIcon = props.dialogSelected === 'upload_file' ? <UploadIcon /> : <CameraIcon />
  //Tag "Site - Photo" (api/file_tags/12/) is applied by default for dialog "upload_site_photos"
  const defaultTags = props.dialogSelected === 'upload_file' ? [] : [`${window.API_ROOT}/api/file_tags/12/`]
  const notify = useNotify()
  const form = useForm()
  const formState = form.getState()
  const formValues = formState.values

  const translate = useTranslate()
  type UploadStatus = 'initial' | 'uploading' | 'uploadFailed' | 'uploadComplete'
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('initial')
  const [uploadedFileRecords, setUploadedFileRecords] = useState<PrivateFileType[] | null>(null)

  const [totalFilesSelected, setTotalFilesSelected] = useState(0)

  const { trackEvent } = useTrackComponent({
    componentKey: 'multiple_files_dialog',
    eventName: 'User Uploaded Document',
  })

  const dismiss = useCallback(() => {
    props.onDismiss()
  }, [])

  const refresh = useRefresh()
  const formRef = useRef<any>()

  const filesInForm: any = useField('private_files_data', { subscription: { value: true } })?.input?.value

  useEffect(() => {
    if (uploadStatus === 'uploadComplete' && uploadedFileRecords) {
      refresh()
      const allInForm = uploadedFileRecords.every((f) => {
        const url = f.url
        const filesInFormAr = filesInForm || []
        const urlsInForm = filesInFormAr.map((f) => f?.url)
        const isInForm = url && urlsInForm.includes(url)
        return isInForm
      })
      if (allInForm) {
        notify('Your changes have been saved', 'success')
        dismiss()
      }
    }
    if (uploadStatus === 'uploadFailed' && totalFilesSelected === 0) {
      dismiss()
    }
  }, [uploadStatus, filesInForm, uploadedFileRecords])

  const getSingleFileFormData = (file, tags): FormData => {
    const formData = new FormData()
    formData.append('title', file.rawFile.name)
    formData.append('file_contents', file.rawFile)
    formData.append('project', props.projectUrl)
    const uploadTags = tags || ''
    formData.append('file_tags', uploadTags)
    return formData
  }

  const getSingleFileData = (file: any, tags: string[]): any => {
    return {
      title: file.rawFile.name,
      project: props.projectUrl,
      file_tags: tags,
    }
  }

  const getFileDataChunked = (file: string, partNumber: number): any => {
    return {
      part_number: partNumber,
      file: file,
    }
  }

  interface UploadInfo {
    fileRecord: PrivateFileType | null
    statusText: string
    fileTitle: string
  }

  const uploadFileData = async (data: any) => {
    try {
      const response = await restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${props.orgId}/private_file_chunked_upload/`,
        data: data,
      })
      return response
    } catch (err) {
      throw err
    }
  }

  const uploadChunkedFile = async (id: string, chunkData: any) => {
    try {
      const response = await restClientInstance('CUSTOM_PUT', 'custom', {
        url: `orgs/${props.orgId}/private_file_chunked_upload/${id}/`,
        data: chunkData,
      })
      return response
    } catch (err) {
      throw err
    }
  }

  const uploadFileComplete = async (id: string) => {
    try {
      const response = await restClientInstance('CUSTOM_PUT', 'custom', {
        url: `orgs/${props.orgId}/private_file_chunked_upload/${id}/complete/`,
      })
      return response
    } catch (err) {
      throw err
    }
  }

  const blobToBase64 = async (blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = (reader?.result as string)?.split(',')[1]
        resolve(base64String)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const uploadFile = async (data: any, fileSize, file_contents, chunkSize = 5 * 1024 * 1024) => {
    const fileTitle = data.title
    const file = file_contents
    const size = fileSize
    try {
      const response = await uploadFileData(data)
      const recordId = response.data.id
      const totalChunks = Math.ceil(size / chunkSize)
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize
        const end = Math.min(size, start + chunkSize)
        const chunk = file.slice(start, end)
        const base64String = await blobToBase64(chunk)
        const chunkData = getFileDataChunked(base64String, i + 1)
        const chunkResponse = await uploadChunkedFile(recordId, chunkData)
        if (totalChunks === i + 1) {
          const completeResponse = await uploadFileComplete(recordId)
          const outstandingFiles = formRef.current.values.file_contents.filter((f) => f.rawFile.name !== fileTitle)
          formRef.current.form.change('file_contents', outstandingFiles)
          return {
            fileTitle,
            fileRecord: file,
            statusText: 'Uploaded',
          }
        }
      }
    } catch (e) {
      console.error(`Error uploading file(s): ${fileTitle} ${e}`)
      return {
        fileTitle,
        fileRecord: null,
        statusText: `${e}`,
      }
    }
  }

  const getUploadResponseData = async (fileFormData: FormData): Promise<UploadInfo> => {
    const fileTitle = fileFormData.get('title') as string
    try {
      const uploadUrl = `${window.API_ROOT}/api/orgs/${props.orgId}/private_file_chunked_upload/`
      const res = await fetch(uploadUrl, {
        method: 'POST',
        body: fileFormData,
        headers: {
          Authorization: 'Bearer ' + appStorage.getToken(),
        },
      })
      const { statusText, ok, status } = res
      if (!ok) {
        throw new Error(`${status} ${statusText}`)
      } else {
        const fileRecord = await res.json()
        const outstandingFiles = formRef.current.values.file_contents.filter((f) => f.rawFile.name !== fileTitle)
        formRef.current.form.change('file_contents', outstandingFiles)
        return {
          fileTitle,
          fileRecord,
          statusText: 'Uploaded',
        }
      }
    } catch (e) {
      console.error(`Error uploading file(s): ${fileTitle} ${e}`)
      return {
        fileTitle,
        fileRecord: null,
        statusText: `${e}`,
      }
    }
  }

  const uploadSingleFile = async (file, tags): Promise<UploadInfo | null | any> => {
    const fileSize = file.rawFile.size
    const file_contents = file.rawFile
    const fileData = getSingleFileData(file, tags)
    const uploadResponse = await uploadFile(fileData, fileSize, file_contents)
    return uploadResponse
  }

  const handleSubmit = (values) => {
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { source: 'default' })
    const tags = values.file_tags
    const uploadResponses = values.file_contents.map((f) => uploadSingleFile(f, tags))
    Promise.all(uploadResponses)
      .then((res: UploadInfo[]) => {
        const failedUploads: UploadInfo[] = res.filter((ui) => !ui.fileRecord)
        const uploadedRecords: any = res.filter((ui) => ui.fileRecord).map((ui) => ui.fileRecord)
        setUploadedFileRecords(uploadedRecords)
        // props.addMultipleFilesToForm(uploadedRecords)
        const eventData = {
          source: props.dialogSelected,
          projectId: props.projectId,
          projectUrl: props.projectUrl,
          count: uploadedRecords.length,
        }
        props.onSuccess()
        trackEvent(EventType.SYSTEM_EVENT, { type: ActionType.SUBMIT, status: ActionStatus.SUCCESS }, eventData)
        if (failedUploads.length === 0) {
          setUploadStatus('uploadComplete')
          props.onDismiss()
        } else {
          notify(`Error uploading file(s): ${failedUploads[0].statusText}`, 'warning', { smart_count: 1 })
          trackEvent(EventType.ERROR_EVENT, { type: ActionType.SUBMIT, status: ActionStatus.ERROR }, eventData)
          setUploadStatus('uploadFailed')
        }
      })
      .catch((e) => {
        notify(`Error uploading file(s): ${e}`)
        console.error(`Error uploading file(s): ${e}`)
        trackEvent(
          EventType.ERROR_EVENT,
          { type: ActionType.SUBMIT, status: ActionStatus.ERROR },
          { projectId: props.projectId }
        )
        setUploadStatus('uploadFailed')
        dismiss()
      })
  }

  const handleExternalSubmit = (e) => {
    e.preventDefault()
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { source: 'external_submit' })
    setUploadStatus('uploading')
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  return (
    <>
      <Dialog
        open={props.isOpen}
        onBackdropClick={() => {
          dismiss()
        }}
        fullWidth
      >
        {uploadStatus === 'uploading' && (
          <LoadingOverlay>
            <LinearProgress variant="indeterminate" />
          </LoadingOverlay>
        )}
        <DialogTitle>
          <span>{translate(props.dialogSelected === 'upload_file' ? 'Upload File' : 'Upload Site Photos')}</span>
        </DialogTitle>
        <DialogContent ref={formRef}>
          <Form
            onSubmit={handleSubmit}
            FormState
            render={({ handleSubmit, values, form }) => {
              formRef.current = { handleSubmit, values, form }
              if (values?.file_contents) {
                setTotalFilesSelected(values?.file_contents.length)
              }
              return (
                <form onSubmit={handleSubmit}>
                  <ContentWrapper>
                    {/* <div className={`${dialogStyles.fieldsStyling}`}> */}
                    <div>
                      {uploadStatus === 'uploadFailed' && (
                        <ErrorArea>
                          <Typography variant="h6" color="error">
                            {translate('There was an error uploading your files.')}
                          </Typography>
                          <Typography variant="subtitle2">
                            {translate('Click "Save" to try again or "Cancel" to discard your changes')}
                          </Typography>
                        </ErrorArea>
                      )}

                      <Field
                        name="files"
                        render={() => (
                          <FileInputContainer>
                            <FileInput
                              source="file_contents"
                              accept={fileTypesAccepted}
                              placeholder={
                                <FileInputPlaceholderContainer>
                                  <FileInputPlaceholderIcon>{placeholderIcon}</FileInputPlaceholderIcon>
                                  <FileInputHelperText>
                                    {translate('Drag & drop or')}
                                    <span> {translate('browse files')}</span>
                                  </FileInputHelperText>
                                </FileInputPlaceholderContainer>
                              }
                              label=""
                              multiple
                              hideDropzone={uploadStatus === 'uploadFailed' || uploadStatus === 'uploading'}
                            >
                              <FunctionField
                                render={(record) => {
                                  const size = record.rawFile.size / 1000000

                                  return (
                                    <SelectedFileContainer>
                                      <StyledFileIcon size={24} />
                                      <p>{record.rawFile.name}</p>
                                      <span>{size.toFixed(2)}MB</span>
                                      <FileField {...record} />
                                    </SelectedFileContainer>
                                  )
                                }}
                              />
                            </FileInput>
                          </FileInputContainer>
                        )}
                      />

                      {uploadStatus !== 'uploading' && (
                        <>
                          <InputRow>
                            <FormLabel>{translate('Tags')}</FormLabel>
                            <Field name="tags" render={() => <FileTagsChipsInput />} />
                          </InputRow>
                        </>
                      )}
                    </div>
                  </ContentWrapper>
                </form>
              )
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={dismiss} variant="contained" color="default">
            <span>{translate('Cancel')}</span>
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleExternalSubmit}
            disabled={totalFilesSelected === 0}
          >
            <span>{translate('Save')}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default withWidth()(MultipleFilesDialog)
