import { orgSelectors } from 'ducks/orgs'
import { omit } from 'lodash'
import { getSupplierConfig, getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import useHandleChangeSelectedDistributor from 'pages/ordering/subheader/useHandleChangeSelectedDistributor'
import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { Confirm } from 'ra-ui-materialui'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import BasicFilter from '../basic/BasicFilter'
import { DISTRIBUTOR_BRAND_FILTER_KEY_V2 } from '../manufacturerV2/DistributorBrandFilterNodeFactoryV2'
import {
  EXHIBITOR_FILTER_KEY_V2,
  MANUFACTURER_FILTER_KEY_V2,
} from '../manufacturerV2/FeatureConfigBrandFilterNodeFactoryV2'
import {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType,
  OptionGenericType,
} from '../typeV2'
import { DISTRIBUTOR_FILTER_KEY_V2 } from './DistributorFilterConfigV2Factory'

import { HardwareSelectorFilterSidebarContext } from 'elements/hardwareSelectorV2/elements/HardwareSelectorFilterSidebar'
import listQueryParamsFormatter from '../../input/listQueryParamsFormatter'

export const GLOBAL_DISTRIBUTOR_NODE_KEY_V2 = 'global_distributors'
const defaultSelectedOptionValue = 'all'
export const SELECT_ALL_OPTION = { id: 'default', title: 'All', value: defaultSelectedOptionValue }

export const getDistributorsOptions = (
  enabledDistributors: HardwareSupplierEnum[],
  addAllOption?: boolean
): OptionGenericType<string>[] => {
  const options: OptionGenericType<string>[] = []

  if (addAllOption) {
    options.push(SELECT_ALL_OPTION)
  }

  enabledDistributors.forEach((distributor) => {
    const config = getSupplierConfig(distributor)
    if (!config) return
    options.push({
      id: config.filterKey,
      title: config.name,
      value: config.filterKey,
    })
  })

  return options
}

const createGlobalDistributorFilterNode = <T,>({
  label = 'View by distributor',
  rendererComponent: RendererComponent,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, string>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [desiredSupplier, setDesiredSupplier] = useState<HardwareSupplierFilterKeyType>()
    const handleSelectDistributor = useHandleChangeSelectedDistributor()
    const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
    const distributorOptions = getDistributorsOptions(enabledDistributors, true)
    let defaultValue = listQueryParamsFormatter.parse<string>(new Set([SELECT_ALL_OPTION.value]))
    const { location } = useContext(HardwareSelectorFilterSidebarContext)

    const selectedValue = filterProps.allFilterValues[DISTRIBUTOR_FILTER_KEY_V2]

    if (distributorOptions.length === 0) {
      return null
    }

    if (location === 'shop' && selectedValue) {
      const distributorLists = JSON.parse(selectedValue)
      defaultValue = listQueryParamsFormatter.parse<string>(new Set(distributorLists))
    }

    // const radioBoxMutator = ({
    //   value,
    //   oldValue,
    // }: {
    //   value: string
    //   oldValue: string
    // }) => {
    //   if (value === defaultSelectedOptionValue) {
    //     // When click on 'All' option, clear the auto apply preference
    //     appStorage.clear('auto_apply_distributor_filter')
    //     return value
    //   } else if (value === getSupplierConfig(selectedValue)?.filterKey) {
    //     // When click on 'distributor' option same as global selected distributor
    //     appStorage.setBool('auto_apply_distributor_filter', true)
    //     return value
    //   } else {
    //     // When click on 'distributor' option different from global selected distributor
    //     setShowConfirmation(true)
    //     setDesiredSupplier(value as HardwareSupplierFilterKeyType)
    //     appStorage.setBool('auto_apply_distributor_filter', true)
    //     return oldValue // Do to allow the new value to be selected, instead we will show a confirmation dialog
    //   }
    // }

    const updateFilter = (newValue) => {
      if (selectedValue === newValue) return
      const newFilterValues = omit(filterProps.modifiableFilterValues, [
        EXHIBITOR_FILTER_KEY_V2,
        MANUFACTURER_FILTER_KEY_V2,
        DISTRIBUTOR_BRAND_FILTER_KEY_V2,
      ])

      newFilterValues[DISTRIBUTOR_FILTER_KEY_V2] = newValue

      filterProps.setModifiableFilters(newFilterValues)
    }

    const handleConfirm = () => {
      if (desiredSupplier) {
        handleSelectDistributor(getSupplierEnumByFilterKey(desiredSupplier))
      }

      updateFilter(desiredSupplier)
      setShowConfirmation(false)
    }

    const sideEffect = <OptionValue, RendererValue>({ filterValues }: { filterValues: FilterValuesType }) => {
      const newFilterValue = { ...filterValues }
      const distributors = listQueryParamsFormatter.parse(filterValues[DISTRIBUTOR_FILTER_KEY_V2])

      if (distributors.has(SELECT_ALL_OPTION.value)) {
        const allDistributors = new Set([...distributorOptions.map((option) => option.value)])
        allDistributors.delete(SELECT_ALL_OPTION.value)

        if (location === 'design') {
          delete newFilterValue[DISTRIBUTOR_FILTER_KEY_V2]
        } else {
          newFilterValue[DISTRIBUTOR_FILTER_KEY_V2] = listQueryParamsFormatter.format({ value: allDistributors })
        }
      }

      return newFilterValue
    }

    return (
      <>
        <BasicFilter
          label={label}
          rendererComponent={RendererComponent}
          defaultSelectedOptionValue={defaultValue}
          filterKey={DISTRIBUTOR_FILTER_KEY_V2}
          options={distributorOptions}
          sideEffect={sideEffect}
          {...restConfigs}
          {...filterProps}
        />
        <Confirm
          isOpen={showConfirmation}
          title={"Change this project's preferred distributor?"}
          content={
            "You can order hardware from leading distributors through OpenSolar's Design page. Changing your hardware distributor will show you stock availability and pricing from the selected distributor and will automatically update your project costs."
          }
          onClose={() => {
            setShowConfirmation(false)
          }}
          onConfirm={handleConfirm}
        />
      </>
    )
  }

  return {
    key: GLOBAL_DISTRIBUTOR_NODE_KEY_V2,
    component: FilterComponent,
  }
}

export default createGlobalDistributorFilterNode
