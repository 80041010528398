import { Grid, Theme, useMediaQuery } from '@material-ui/core'
import { AccountBalance, Business, PeopleAlt, VerifiedUser } from '@material-ui/icons'
import { Box, Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import PaymentChannels from '../components/PaymentChannels'
import { FormRouteInfoPropTypes } from '../types'
interface StyledProps {
  isMobile?: boolean
}
const MainWrapper = styled('div')({
  margin: '0 14px',
})

const ContentWrapper = styled('div')({
  padding: '1rem 0',
})

const DescriptionWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '14px 0',
})

const GreyCointainer = styled('div')<StyledProps>(({ theme, isMobile }) => ({
  background: theme.palette.grey[50],
  borderRadius: '8px',
  padding: '1rem',
  height: isMobile ? 'unset' : '80%',
}))

const BlueContainer = styled('div')<StyledProps>(({ isMobile }) => ({
  background: '#EDF3FF',
  borderRadius: '8px',
  padding: '1rem',
  textAlign: 'center',
  height: '80%',
  display: 'flex',
  flexFlow: 'column',
  gap: 14,
}))

const IconWrapper = styled('div')<StyledProps>(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.grey[700],
    height: '1.25rem',
    marginRight: '.5rem',
  },
}))

const StyledShieldIcon = styled(VerifiedUser)({
  height: '1.25rem',
  color: '#019939',
  padding: '10px',
  background: '#DAE5FF',
  borderRadius: 7,
  margin: '10px 0',
})

const PaymentsWrapper = styled('div')({
  marginBottom: '1rem',
})

const IntroTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <div data-testid="bs-onboarding-intro-top">
      <MainWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <GreyCointainer isMobile={isMobile}>
              <Typography textVariant="h5SemiBold">
                {translate('Information to have on hand before you begin')}
              </Typography>

              <ContentWrapper>
                <DescriptionWrapper>
                  <IconWrapper>
                    <Business />
                  </IconWrapper>
                  <Typography textVariant="body1">{translate('Legal business information')}</Typography>
                </DescriptionWrapper>

                <DescriptionWrapper>
                  <IconWrapper>
                    <PeopleAlt />
                  </IconWrapper>
                  <Typography textVariant="body1">
                    {translate("Owner and/or Company Representative's personal information")}
                  </Typography>
                </DescriptionWrapper>

                <DescriptionWrapper>
                  <IconWrapper>
                    <AccountBalance />
                  </IconWrapper>
                  <Typography textVariant="body1">{translate('Business bank account information')}</Typography>
                </DescriptionWrapper>
              </ContentWrapper>
            </GreyCointainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <BlueContainer isMobile={isMobile}>
              <Box>
                <StyledShieldIcon />
              </Box>
              <Typography textVariant="subtitle1" colorHex="#2C499D">
                {translate('Safe & Secure')}
              </Typography>
              <Typography textVariant="caption2" colorHex="#757575">
                {translate(
                  'Payment information is secure and encrypted. Our platform ensures security and data privacy by partnering with leading payment providers'
                )}
              </Typography>

              <PaymentsWrapper>
                <PaymentChannels />
              </PaymentsWrapper>
            </BlueContainer>
          </Grid>
        </Grid>
      </MainWrapper>
    </div>
  )
}
export default IntroTop
