import { BomGeneratorAbstract } from '../bom_generators/BomGeneratorAbstract'
import { Edge, PerBlockEdgeBomGeneratorAbstract } from '../bom_generators/PerBlockEdgeBomGeneratorAbstract'
import { PerColumnBomGeneratorAbstract } from '../bom_generators/PerColumnBomGeneratorAbstract'
import { PerColumnClampBomGeneratorAbstract } from '../bom_generators/PerColumnClampBomGeneratorAbstract'
import { PerInnerCornerBomGeneratorAbstract } from '../bom_generators/PerInnerCornerBomGeneratorAbstract'
import { Corner, PerOuterCornerBomGeneratorAbstract } from '../bom_generators/PerOuterCornerBomGeneratorAbstract'
import { PerPanelBomGeneratorAbstract } from '../bom_generators/PerPanelBomGenerator'
import { PerRowClampBomGeneratorAbstract } from '../bom_generators/PerRowClampBomGeneratorAbstract'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../MountingSystemAbstract'
import { PostProcessorAbstract } from '../post_processors/PostProcessorAbstract'
import {
  CompatibilityParameters,
  ContiguousPanelRow,
  Fastener,
  HasPosition,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  PanelColumn,
  PanelWithPosition,
} from '../types'

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 24, verticalSpacing: 24 }, //tbc
}
class SunmodoNanoRackSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

class SunmodoNanoRackBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const clampPlacement = this.input.options.clampPlacement
    const panelOrientation = this.block.orientation
    const clampingDirection =
      (clampPlacement === 'long' && panelOrientation === 'landscape') ||
      (clampPlacement === 'short' && panelOrientation === 'portrait')
        ? 'column'
        : 'row'
    const starterBom =
      clampingDirection === 'column'
        ? await this.generateBomFrom(PerColumnClampBomGenerator, result)
        : await this.generateBomFrom(PerRowClampBomGenerator, result)
    const bomWithPanelExtras = await this.generateBomFrom(SunmodoPanelExtrasAdder, starterBom)
    const bomWithLugs = await this.generateBomFrom(SunmodoLugAdder, bomWithPanelExtras)
    const bomWithSkirt = await this.generateBomFrom(SunmodoSkirtBomGenerator, bomWithLugs)
    const bomWithOuterCorners = await this.generateBomFrom(SunmodoPerOuterCornerBomGenerator, bomWithSkirt)
    const bomWithInnerCorners = await this.generateBomFrom(SunmodoPerInnerCornerBomGenerator, bomWithOuterCorners)
    return this.postProcess(bomWithInnerCorners)
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new SunmodoNanoRackRoofHookAdder(this.input).process(result)
    if (this.input.options.skirtConfiguration !== 'none') {
      resultWithRoofHooks.items.push({ name: 'GRND-SMR', components: [] })
    }
    return resultWithRoofHooks
  }
}

export class SunmodoNanoRack extends MountingSystemAbstract {
  layoutAdjusterClass = SunmodoNanoRackSpacingAdjuster
  bomGeneratorClass = SunmodoNanoRackBomGenerator

  getFastener(): Fastener {
    return {
      includedInRoofHookProduct: false,
      name: 'SCREW-B',
      qtyPerRoofHook: 4,
      length: 76.2,
      diameter: 6.3,
      components: [],
    }
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      roofTypes: ['Composition / Asphalt Shingle', 'Trapezoidal', 'Metal Standing Seam'],
      roofTypeRequired: true,
      slopeRange: [0, 45],
    } as CompatibilityParameters
  }
}

const moduleHook = {
  name: 'NANORACK-HOOK',
  components: [
    {
      name: 'NANORACK-HOOK',
      type: MountingComponentType.CLAMP,
      left: -10,
      top: -10,
    },
  ],
}

const universalBracket = {
  name: 'NANORACK-UNI',
  components: [
    {
      name: 'NANORACK-UNI',
      type: MountingComponentType.CLAMP,
      left: -10,
      top: -10,
    },
  ],
}

class PerColumnClampBomGenerator extends PerColumnClampBomGeneratorAbstract {
  chooseMidClamp() {
    return moduleHook
  }

  chooseEndClamp() {
    return universalBracket
  }

  getClampPositions(input: MountingCalcInput, panel: PanelWithPosition): HasPosition[] {
    const totalEdgeLength = panel.orientation === 'landscape' ? panel.height : panel.width
    const clampPositions = [
      { top: 0, left: totalEdgeLength / 4 },
      { top: 0, left: totalEdgeLength - totalEdgeLength / 4 },
    ]
    return clampPositions
  }
}

class PerRowClampBomGenerator extends PerRowClampBomGeneratorAbstract {
  chooseMidClamp(row: ContiguousPanelRow) {
    return moduleHook
  }

  chooseEndClamp(row: ContiguousPanelRow) {
    return universalBracket
  }

  getClampPositions(input: MountingCalcInput, panel: PanelWithPosition): HasPosition[] {
    const totalEdgeLength = panel.orientation === 'landscape' ? panel.width : panel.height
    const clampPositions = [
      { top: totalEdgeLength / 4, left: 0 },
      { top: totalEdgeLength - totalEdgeLength / 4, left: 0 },
    ]
    return clampPositions
  }
}

class SunmodoNanoRackRoofHookAdder extends PostProcessorAbstract {
  process(result: MountingCalcResult): MountingCalcResult {
    const componentsToAdd: Item[] = []

    result.items.forEach((item) => {
      item.components.forEach((component) => {
        if (component.name === 'NANORACK-HOOK') {
          componentsToAdd.push({
            name: 'NANORACK-UNI',
            components: [
              {
                name: 'NANORACK-UNI',
                type: MountingComponentType.CLAMP,
                left: component.left,
                top: component.top,
              },
            ],
          })
        }
        if (component.name === 'NANORACK-HOOK' || component.name === 'NANORACK-UNI') {
          const roofType = this.input.roof.roofTypeName
          if (roofType === 'Trapezoidal' || roofType === 'Metal Standing Seam') {
            componentsToAdd.push({
              name: 'MRB-S',
              components: [
                {
                  name: 'MRB-S',
                  type: MountingComponentType.ROOF_HOOK,
                  left: component.left,
                  top: component.top,
                },
              ],
            })
          } else {
            componentsToAdd.push(
              {
                name: 'NANOPLUS-B',
                components: [
                  {
                    name: 'NANOPLUS-B',
                    type: MountingComponentType.ROOF_HOOK,
                    left: component.left,
                    top: component.top,
                  },
                ],
              },
              ...Array(4).fill({
                name: 'SCREW-B',
                components: [],
              })
            )
          }
        }
      })
    })

    result.items.push(...componentsToAdd)

    return result
  }
}

class SunmodoPanelExtrasAdder extends PerPanelBomGeneratorAbstract {
  generateBomForPanel(panel: PanelWithPosition, blockIndex: number): Item[] {
    let itemsToAdd: Item[] = []
    if (this.input.options.mlpeMount) {
      itemsToAdd.push({
        name: 'MLPE-SMR',
        components: [],
      })
    }
    if (this.input.options.conduitMount) {
      itemsToAdd.push(
        ...Array(2).fill({
          name: this.input.options.conduitMount,
          components: [],
        })
      )
    }
    return itemsToAdd
  }
}

class SunmodoLugAdder extends PerColumnBomGeneratorAbstract {
  generateBomForColumn(resultFromLastColumn: MountingCalcResult, column: PanelColumn): MountingCalcResult {
    if (this.input.options.skirtConfiguration === 'none') {
      resultFromLastColumn.items.push({ name: 'GRND-SMR', components: [] })
    }
    return resultFromLastColumn
  }
}

class SunmodoPerOuterCornerBomGenerator extends PerOuterCornerBomGeneratorAbstract {
  getBom(cornerType: 'bottom' | 'top'): Item[] {
    const skirtConfiguration = this.input.options.skirtConfiguration
    let items: Item[] = []
    if (skirtConfiguration === 'all') {
      items.push({ name: 'SKIRT-OUTER-B', components: [] })
    }
    if (skirtConfiguration === 'frontAndSide' && cornerType === 'bottom') {
      items.push({ name: 'SKIRT-OUTER-B', components: [] })
    }
    return items
  }

  generateBomForTopRightCorner(corner: Corner): Item[] {
    return this.getBom('top')
  }
  generateBomForTopLeftCorner(corner: Corner): Item[] {
    return this.getBom('top')
  }
  generateBomForBottomRightCorner(corner: Corner): Item[] {
    return this.getBom('bottom')
  }
  generateBomForBottomLeftCorner(corner: Corner): Item[] {
    return this.getBom('bottom')
  }
}

class SunmodoPerInnerCornerBomGenerator extends PerInnerCornerBomGeneratorAbstract {
  generateBomForInnerCorners(innerCornerQty: number): Item[] {
    const skirtConfiguration = this.input.options.skirtConfiguration
    if (skirtConfiguration === 'all' || skirtConfiguration === 'frontAndSide') {
      return Array(innerCornerQty).fill({ name: 'SKIRT-INNER-B', components: [] })
    } else {
      return []
    }
  }
}

class SunmodoSkirtBomGenerator extends PerBlockEdgeBomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const skirtConfiguration = this.input.options.skirtConfiguration
    let edges: Edge[] = []
    if (skirtConfiguration === 'frontOnly') {
      edges.push(...this.getBottomEdges())
    } else if (skirtConfiguration === 'frontAndSide') {
      edges.push(...this.getBottomEdges(), ...this.getLeftEdges(), ...this.getRightEdges())
    } else if (skirtConfiguration === 'all') {
      edges.push(...this.getTopEdges(), ...this.getBottomEdges(), ...this.getLeftEdges(), ...this.getRightEdges())
    } else {
      return result
    }

    edges.forEach((edge) => {
      const skirtQty = Math.ceil(edge.length / 2133)
      const clampQty = edge.panelCount * 2
      result.items.push(
        ...Array(skirtQty).fill({
          name: 'SKIRT-84-B',
          components: [],
        }),
        ...Array(skirtQty - 1).fill({
          name: 'SKIRT-SPLICE',
          components: [],
        }),
        ...Array(clampQty).fill({
          name: 'SKIRT-CLAMP',
          components: [],
        }),
        ...(skirtConfiguration === 'frontOnly' ? [{ name: 'SKIRT-CAP-B', components: [] }] : [])
      )
    })
    return result
  }
}
