"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Peers = void 0;
var genid_1 = require("../util/genid");
var logger_1 = require("../util/logger");
var serialise_1 = require("../util/serialise");
var state_1 = require("./state");
var Peers = /** @class */ (function () {
    function Peers() {
        this.logger = new logger_1.Logger('OSSDK.Peers');
        this.hasPeers = new state_1.Value(false);
        this.id = '';
        this.ready = false;
        this.peers = [];
        this.selfOwnerships = {};
        this.ownershipHasChanged = [];
        this.ownershipMap = {};
        this.pendingActions = {};
        this.handlers = {};
    }
    Peers.prototype.join = function (id) {
        var _this = this;
        this.id = id;
        window.addEventListener('message', function (e) {
            var from = _this.peers.find(function (p) { return p.window === e.source; });
            var isSdkMessage = e.data.format === SdkPeersMessageFormat;
            if (e.data.source === 'react-devtools-content-script' ||
                e.data.target === 'Extension' ||
                e.origin === 'https://cdn.walkme.com') {
                // Can ignore these silently to make debugging easier
                // these are known other systems that we can ignore safely
                return;
            }
            if (!from || !isSdkMessage) {
                _this.logger.debug('Ignoring message: ', _this.id, e);
                // Some other system's comms
                return;
            }
            // Forward the message to other peers
            _this.forEach(function (peer) {
                if (peer !== from)
                    _this.sendEnvToPeer(peer, e.data);
            });
            // Process message
            _this.onMessage(e.data, from);
            //TODO: could optimise here by not forwarding action responses targeted at this peer
            // Send message to external handlers
            var handlers = _this.handlers[e.data.type];
            if (handlers)
                handlers.forEach(function (h) { return h(e.data); });
        });
    };
    Peers.prototype.markReady = function () {
        var _this = this;
        if (this.ready) {
            this.logger.error('Already marked ready');
            return;
        }
        this.ready = true;
        this.forEach(function (peer) { return _this.sendMsgToPeer(peer, { type: 'PeerReadyMsg' }); });
    };
    Peers.prototype.add = function (type, handler) {
        var handlers = this.handlers[type];
        if (!handlers) {
            this.handlers[type] = handlers = [];
        }
        handlers.push(handler);
    };
    Peers.prototype.remove = function (type, handler) {
        var handlers = this.handlers[type];
        if (!handlers)
            return;
        var idx = handlers.indexOf(handler);
        if (idx >= 0)
            handlers.splice(idx, 1);
    };
    Peers.prototype.sendToAll = function (msg, target) {
        var _this = this;
        var env = this.envelope(msg, target);
        this.forEach(function (peer) { return _this.sendEnvToPeer(peer, env); });
    };
    Peers.prototype.send = function (window, msg) {
        var peer = this.peers.find(function (p) { return p.window === window; });
        if (!peer) {
            this.logger.error('Unknown peer: ', window);
        }
        else {
            //TODO: should probably target this msg
            this.sendMsgToPeer(peer, msg);
        }
    };
    Peers.prototype.forEach = function (callback) {
        this.peers.forEach(callback);
    };
    Peers.prototype.canHandleMsg = function (peer, msg) {
        var type = msg.msg.type;
        var isEarlyMsg = type === 'PeerReadyMsg' || type === 'PeerLoadedMsg' || type === 'SetOwnershipMsg';
        return (isEarlyMsg && peer.loaded) || (!isEarlyMsg && peer.ready);
    };
    Peers.prototype.envelope = function (msg, target) {
        return {
            format: SdkPeersMessageFormat,
            from: this.id,
            msg: msg,
            target: target,
        };
    };
    Peers.prototype.sendMsgToPeer = function (peer, msg) {
        this.sendEnvToPeer(peer, this.envelope(msg));
    };
    Peers.prototype.sendEnvToPeer = function (peer, env) {
        if (!this.canHandleMsg(peer, env)) {
            this.logger.debug('Queuing message: ', this.id, env);
            peer.pendingMsgs.push(env);
            return;
        }
        this.logger.debug("Send.".concat(env.msg.type, "(me:").concat(this.id, " to:").concat(env.target || 'all', "):"), env.msg);
        try {
            peer.window.postMessage(env, '*');
        }
        catch (e) {
            this.logger.error('Error sending message to peer', e);
        }
    };
    Peers.prototype.addPeer = function (window, loaded, sendLoaded, options) {
        var _this = this;
        var _a;
        var _b = options || {}, onReadinessChange = _b.onReadinessChange, syncState = _b.syncState;
        var peer = { window: window, loaded: loaded, ready: false, pendingMsgs: [], onReadinessChange: onReadinessChange };
        this.peers.push(peer);
        // Check for unloaded peers regularly  but not so much it kills performance. An efficient choice
        // of event is when a new peer is added because it avoids needing to do something more aggressive
        // like listening on each event. Remove existing unloaded peers here.
        //
        // There is a good possibility this many not remove all closed views perfectly if the removed view
        // has not yet fully closed, it may not be marked as closed when the new view is added. But this does
        // not need to be precise, it is ok if some events get routed to windows that are closed/closing because
        // it does not cause any harm.
        //
        // Is this insufficient? We really need to remove the whole OSSDKBootstrap
        // var peersToRemove = this.peers.filter((p) => !p.window.closed === true)
        // peersToRemove.forEach((otherPeer) => this.removePeer(otherPeer), this)
        if (sendLoaded)
            this.sendMsgToPeer(peer, { type: 'PeerLoadedMsg' });
        if (this.ready)
            this.sendMsgToPeer(peer, { type: 'PeerReadyMsg' });
        // Send ownerships to new peer
        for (var path in this.selfOwnerships) {
            this.sendOwnership(path, this.selfOwnerships[path], peer);
        }
        if (syncState) {
            (_a = this.onSyncState) === null || _a === void 0 ? void 0 : _a.call(this, peer);
        }
        this.hasPeers.value = !!this.peers.length;
        // Return cleanup method
        // This never actually gets called which is why we need to check for unloaded peers regularly.
        // An efficient choice is when a new peer is added because it avoids needing to do something more
        // aggressive like listening on each event.
        return function () { return _this.removePeer(peer); };
    };
    Peers.prototype.removePeer = function (peer) {
        var index = this.peers.indexOf(peer);
        if (index < 0) {
            this.logger.error('Unknown peer: ', peer);
        }
        else {
            // Should we also explicitly remove handlers from the peer before simply removing from the list of peers?
            // Perhaps garbage collection can clean it up but could there be some references somewhere related to handlers?
            this.peers.splice(this.peers.indexOf(peer), 1);
        }
        this.hasPeers.value = !!this.peers.length;
    };
    Peers.prototype.onMessage = function (env, from) {
        return __awaiter(this, void 0, void 0, function () {
            var msg, isOwnerChange, idx, ownerMap, promise, ret, response;
            var _this = this;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                msg = env.msg;
                this.logger.debug("Receive.".concat(msg.type, "(me:").concat(this.id, " from:").concat(env.from, "):"), env.msg);
                switch (msg.type) {
                    case 'SignalDispatchedMsg':
                        (_a = this.onSignalDispatched) === null || _a === void 0 ? void 0 : _a.call(this, msg.path, msg.args);
                        // forward signal to peers (except where it's come from)
                        this.forEach(function (peer) {
                            if (peer !== from)
                                _this.sendMsgToPeer(peer, msg);
                        });
                        break;
                    case 'StateChangeMsg':
                        isOwnerChange = this.ownershipHasChanged.includes(msg.path);
                        (_b = this.onStateChange) === null || _b === void 0 ? void 0 : _b.call(this, msg.path, msg.value, msg.old, isOwnerChange);
                        // remove msg.path from this.ownershipHasChanged since it has now loaded a new starting "old" value
                        if (isOwnerChange) {
                            idx = this.ownershipHasChanged.indexOf(msg.path);
                            if (idx >= 0)
                                this.ownershipHasChanged.splice(idx, 1);
                        }
                        break;
                    case 'SetOwnershipMsg':
                        ownerMap = this.ownershipMap[msg.path];
                        if (!ownerMap) {
                            this.ownershipMap[msg.path] = ownerMap = {};
                        }
                        if (!this.ownershipHasChanged.includes(msg.path)) {
                            this.ownershipHasChanged.push(msg.path);
                            // Not currently implemented because we have not yet identified any cases where this is problematic
                            // and clearing messages could be dangerous without solid test coverage with real scenarios.
                            // Beware: env.from should only ever be master and it's actually the OLD master not the new one
                            // that is sending the new SetOwnershipMsg message
                            //
                            // PENDING_MESSAGES_TO_CLEAR_ON_OWNERSHIP_CHANGE = []
                            // if(PENDING_MESSAGES_TO_CLEAR_ON_OWNERSHIP_CHANGE.includes(msg.path)){
                            //   this.clearPendingMsgs(from, msg.path)
                            // }
                        }
                        ownerMap[env.from] = msg.priority;
                        break;
                    case 'UnownedActionMsg':
                        if (env.target === this.id) {
                            this.logger.debug('Process Action:', this.id, msg);
                            promise = (_c = this.onAction) === null || _c === void 0 ? void 0 : _c.call(this, msg.path, msg.args);
                            if (!promise) {
                                this.logger.error('No action handler found for msg', msg);
                                return [2 /*return*/];
                            }
                            // This app is the target for this call, execute it
                            promise
                                .then(function (ret) {
                                // success
                                var response = {
                                    type: 'ActionResponseMsg',
                                    callId: msg.callId,
                                    response: (0, serialise_1.serialiseDeep)(ret),
                                    rejected: false,
                                };
                                _this.sendToAll(response, env.from);
                            })
                                .catch(function (err) {
                                // failure
                                var response = {
                                    type: 'ActionResponseMsg',
                                    callId: msg.callId,
                                    response: (0, serialise_1.serialiseDeep)(err),
                                    rejected: true,
                                };
                                _this.sendToAll(response, env.from);
                            });
                        }
                        break;
                    case 'ActionResponseMsg':
                        if (env.target !== this.id) {
                            // This app is not the target for this call, ignore it
                            break;
                        }
                        ret = this.pendingActions[msg.callId];
                        if (ret) {
                            delete this.pendingActions[msg.callId];
                            response = (0, serialise_1.deserialiseDeep)(msg.response);
                            if (msg.rejected)
                                ret.reject(response);
                            else
                                ret.resolve(response);
                        }
                        else {
                            this.logger.error('No pending action found for callId', msg.callId);
                        }
                        break;
                    case 'PeerLoadedMsg':
                        if (from.loaded) {
                            this.logger.error('Peer already loaded', from);
                            return [2 /*return*/];
                        }
                        from.loaded = true;
                        this.checkPendingMsgs(from);
                        if (from.onReadinessChange)
                            from.onReadinessChange(from.loaded, from.ready);
                        break;
                    case 'PeerReadyMsg':
                        if (from.ready) {
                            this.logger.error('Peer already ready', from);
                            return [2 /*return*/];
                        }
                        from.ready = true;
                        this.checkPendingMsgs(from);
                        if (from.onReadinessChange)
                            from.onReadinessChange(from.loaded, from.ready);
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    Peers.prototype.checkPendingMsgs = function (peer) {
        var _this = this;
        if (!peer.pendingMsgs.length)
            return;
        var stillPending = [];
        peer.pendingMsgs.forEach(function (env) {
            if (_this.canHandleMsg(peer, env)) {
                _this.sendEnvToPeer(peer, env);
            }
            else {
                stillPending.push(env);
            }
        });
        peer.pendingMsgs = stillPending;
    };
    Peers.prototype.clearPendingMsgs = function (peer, path) {
        var stillPending = [];
        peer.pendingMsgs.forEach(function (env) {
            // Only strip messages that are known to be problematic. In future we may confirm that all
            // pending messages can be cleared from the old owner.
            if (env.msg.type === 'StateChangeMsg' && env.msg.path === path) {
                // Skip this message
            }
            else {
                stillPending.push(env);
            }
        });
        peer.pendingMsgs = stillPending;
    };
    Peers.prototype.setOwnership = function (path, priority) {
        if (this.selfOwnerships[path] === priority)
            return;
        this.selfOwnerships[path] = priority;
        this.sendOwnership(path, priority);
        if (!this.ownershipMap[path]) {
            this.ownershipMap[path] = {};
        }
        this.ownershipMap[path][this.id] = priority;
    };
    Peers.prototype.sendOwnership = function (path, priority, peer) {
        var _this = this;
        var msg = {
            type: 'SetOwnershipMsg',
            path: path,
            priority: priority,
        };
        if (peer)
            this.sendMsgToPeer(peer, msg);
        else
            this.forEach(function (peer) { return _this.sendMsgToPeer(peer, msg); });
    };
    Peers.prototype.sendSignal = function (path, args) {
        var _this = this;
        var msg = {
            type: 'SignalDispatchedMsg',
            path: path,
            args: args,
        };
        this.forEach(function (peer) { return _this.sendMsgToPeer(peer, msg); });
    };
    Peers.prototype.isOwner = function (path) {
        var owner = this.findOwner(path);
        return owner === this.id;
    };
    Peers.prototype.isOwnerOrNotOwned = function (path) {
        var owner = this.findOwner(path);
        return !owner || owner === this.id;
    };
    Peers.prototype.sendUnownedAction = function (path, args) {
        var _this = this;
        var _a;
        var owner = this.findOwner(path, 'master');
        if (owner === this.id) {
            return (_a = this.onAction) === null || _a === void 0 ? void 0 : _a.call(this, path, args);
        }
        else {
            var msg_1 = {
                type: 'UnownedActionMsg',
                path: path,
                args: args,
                callId: (0, genid_1.genid)(10),
            };
            var ret = new Promise(function (resolve, reject) {
                _this.pendingActions[msg_1.callId] = { resolve: resolve, reject: reject };
            });
            this.sendToAll(msg_1, owner);
            return ret;
        }
    };
    Peers.prototype.findOwner = function (path, def) {
        var ownerMap = this.ownershipMap[path];
        var bestOwner = def;
        if (ownerMap) {
            var bestPriority = void 0;
            for (var id in ownerMap) {
                if (bestPriority === undefined || ownerMap[id] > bestPriority) {
                    bestOwner = id;
                    bestPriority = ownerMap[id];
                }
            }
        }
        return bestOwner;
    };
    Peers.prototype.sendStateChange = function (path, val, old) {
        var msg = {
            type: 'StateChangeMsg',
            path: path,
            value: val,
            old: old,
        };
        this.sendToAll(msg);
    };
    Peers.prototype.syncStateToPeer = function (path, peer, newValue, oldValue) {
        var msg = {
            type: 'StateChangeMsg',
            path: path,
            value: newValue,
            old: oldValue,
        };
        this.sendMsgToPeer(peer, msg);
    };
    return Peers;
}());
exports.Peers = Peers;
// Update this string to avoid interference between incompatible sdk versions
var SdkPeersMessageFormat = 'ossdk_v1';
