import { CheckCircle } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getAvailableActions } from 'ducks/myEnergy'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import LoadingDots from 'layout/widgets/LoadingDots'
import { makeNextPaymentRequest } from 'pages/cashFlow/utils'
import { useGetPaymentRequests } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useSystem } from 'projectSections/sections/payments/hooks'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HostedPaymentsPage from './HostedPaymentsPage'

type PropTypes = {
  projectId: number
  orgId: number
  paymentOptionId: number
  systemUuid: string
  showAcceptProposalAccordion: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    padding: '10px 10px 40px 10px',
  },
  nothingToDoWrapper: {
    padding: '40px 40px 80px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  acceptProposalRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: `1px solid ${theme.greyLight2}`,
    borderRadius: '5px',
    padding: '10px 5px',
  },
  icon: {
    color: '#019939',
    marginRight: '10px',
  },
  acceptProposalTitle: {
    fontSize: '16px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    width: '100%',
  },
}))

const IntegratedCheckoutWrapper: React.FC<PropTypes> = (props) => {
  const [shouldMakeNewRequest, setShouldMakeNewRequest] = useState<boolean>(false)
  const [refetchRequestsTrigger, setRefetchRequestsTrigger] = useState<string | null>(null)
  const [hasTriedToMakeRequest, setHasTriedToMakeRequest] = useState<boolean>(false)
  const [isMakingRequest, setIsMakingRequest] = useState<boolean>(false)

  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const notify = useNotify()
  const system = useSystem(props.systemUuid)
  const paymentOption = system?.payment_options?.find((pmt) => pmt.id === props.paymentOptionId)
  const { paymentRequests, isLoading } = useGetPaymentRequests(props.projectId, system, paymentOption)

  // first check to see if we already have a request. If none exists yet then rerun this query every time refetchRequestsTrigger so we can pick up
  // a request that we just made via makeNextPaymentRequestBelow
  const existingUnpaidDeposit = useMemo(() => {
    if (paymentRequests && paymentRequests.length) {
      const unpaidDeposits = paymentRequests?.filter(
        (pmtReq) => pmtReq.is_deposit === true && ['requested', 'viewed', 'payment failed'].includes(pmtReq.status)
      )
      if (unpaidDeposits?.length) return unpaidDeposits[0]
      setShouldMakeNewRequest(true)
    }
    return undefined
  }, [paymentRequests, refetchRequestsTrigger])

  // when this component mounts fetch available actions. This ensures that if the user exists the dialog the proposal underneath will reflect the fact that
  // the proposal has been accepted but the payment has not been made
  useEffect(() => {
    dispatch(getAvailableActions(props.orgId, props.projectId))
  }, [])

  useEffect(() => {
    if (!existingUnpaidDeposit && shouldMakeNewRequest && !hasTriedToMakeRequest) {
      setIsMakingRequest(true)
      logAmplitudeEvent('cashflow_invoice_generation_started', {
        projectId: props.projectId,
        location: 'proposal',
      })
      makeNextPaymentRequest(props.orgId, props.projectId, props.systemUuid, props.paymentOptionId, true)
        .then((res) => {
          setRefetchRequestsTrigger(`${new Date()}`)
          dispatch(triggerMilestoneRefresh())
        })
        .catch((err) => notify(err?.body?.message, 'warning', { autoHideDuration: undefined }))
        .finally(() => {
          setHasTriedToMakeRequest(true)
          setIsMakingRequest(false)
        })
    }
  }, [existingUnpaidDeposit, hasTriedToMakeRequest, shouldMakeNewRequest])

  if (isLoading || isMakingRequest) {
    return (
      <div className={classes.loadingContainer}>
        <LoadingDots text="One moment while we prepare your invoice..." />
      </div>
    )
  } else if (!existingUnpaidDeposit) {
    return (
      <div className={classes.nothingToDoWrapper}>
        <div>
          {translate(
            'This proposal does not currently have a deposit awaiting payment. If you believe you do owe a payment please check your email for a different payment link or contact your sales representative.'
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className={classes.wrapper}>
        {props.showAcceptProposalAccordion && (
          <div className={classes.acceptProposalRow}>
            <div className={classes.icon}>
              <CheckCircle />
            </div>
            <div className={classes.acceptProposalTitle}>{translate('Accept Proposal')}</div>
          </div>
        )}

        {existingUnpaidDeposit?.id && (
          <HostedPaymentsPage
            match={{ params: { paymentRequestId: `${existingUnpaidDeposit.id}`, projectId: `${props.projectId}` } }}
            isIntegratedCheckout={true}
          />
        )}
      </div>
    )
  }
}
export default IntegratedCheckoutWrapper
