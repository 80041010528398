import { orgSelectors } from 'ducks/orgs'
import UiRoute from 'elements/UiRoute'
import { HardwareSupplierEnum, HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { NotFound, WithPermissions } from 'react-admin'
import { useSelector } from 'react-redux'
import { Switch, useLocation } from 'react-router-dom'
import type { ComponentTypes, ComponentTypesV2 } from 'types/selectComponent'
import AfterConnectPage from './AfterConnectPage'
import ShopItemsWithFilter from './catalogv3/ShopItemsWithFilter'
import CartPage from './checkout/CartPage'
import CityPlumbingCheckout from './checkout/cityPlumbing/CityPlumbingCheckout'
import CityPlumbingCheckoutV2 from './checkout/cityPlumbing/CityPlumbingCheckoutV2'
import HdmCheckout from './checkout/hdm/HdmCheckout'
import SegenCheckout from './checkout/segen/SegenCheckout'
import OutletCartPage from './checkout/solarOutlet/OutletCartPage'
import SolarJuiceCheckoutPage from './checkout/solarOutlet/solarJuice/SolarJuiceCheckoutPage'
import VtacCheckout from './checkout/vtac/VtacCheckout'
import HdmConnectPage from './connect/HdmConnectPage'
import HdmVerifyCodePage from './connect/HdmVerifyCodePage'
import VtacConnectPage from './connect/VtacConnectPage'
import ProductDetailPage from './detail/ProductDetailPage'
import ShopPage from './home/OrderingHome'
import OrderByProjectPage from './OrderByProjectPage'
import OrderConfirmationPage from './OrderConfirmationPage'
import ShopResultsPage from './ShopResultsPage'

export const getSearchResultsPathname = (searchKey: string) => `/shop/search/${searchKey}`
export const PREFIXED_COMPONENT_TYPE_SEARCH_ROUTES: { searchKey: string; componentType: ComponentTypes }[] = [
  {
    searchKey: 'modules',
    componentType: 'module',
  },
  {
    searchKey: 'inverters',
    componentType: 'inverter',
  },
  {
    searchKey: 'batteries',
    componentType: 'battery',
  },
  {
    searchKey: 'others',
    componentType: 'other',
  },
]

export const useComponentTypeByRoute = () => {
  const location = useLocation()

  const searchRoute = PREFIXED_COMPONENT_TYPE_SEARCH_ROUTES.find(
    (route) => getSearchResultsPathname(route.searchKey) === location.pathname
  )
  return searchRoute?.componentType
}

const OrderingRoutes = () => {
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const cartPageComponent = supplier === HardwareSupplierEnum.SolarOutlet ? OutletCartPage : CartPage

  return (
    <div>
      <Switch>
        <UiRoute
          exact
          path={'/shop'}
          render={() => {
            return <WithPermissions resource={'shop'} component={ShopPage} />
          }}
        />
        <UiRoute
          exact
          path={'/shop/distributor/:distributor'}
          render={({ match }) => {
            const distributor = match.params.distributor || undefined
            return (
              <WithPermissions
                authParams={{ permission: 'is_pro' }}
                resource={'shop_by_distributor'}
                distributor={distributor as HardwareSupplierFilterKeyType}
                component={ShopPage}
              />
            )
          }}
        />
        <UiRoute
          exact
          path={'/shop/search/:componentType/distributor/:distributor'}
          render={({ match }) => {
            const distributor = match.params.distributor
            const componentType = match.params.componentType
            return (
              <ShopItemsWithFilter
                distributor={distributor as HardwareSupplierFilterKeyType}
                componentType={componentType as ComponentTypesV2}
              />
            )
          }}
        />
        <UiRoute
          path={'/shop/product/:code'}
          render={({ match, location }) => {
            const componentCode = match.params.code || ''
            return (
              <WithPermissions
                authParams={{ permission: 'is_pro' }}
                resource={'shop_product_details'}
                componentCode={componentCode}
                component={ProductDetailPage}
              />
            )
          }}
        />
        {PREFIXED_COMPONENT_TYPE_SEARCH_ROUTES.map(({ searchKey, componentType }) => {
          return (
            <UiRoute
              key={searchKey}
              path={getSearchResultsPathname(searchKey)}
              render={({ match, location }) => {
                return (
                  <WithPermissions
                    authParams={{ permission: 'is_pro' }}
                    searchText={''}
                    componentType={componentType}
                    component={ShopResultsPage}
                  />
                )
              }}
            />
          )
        })}
        <UiRoute
          path="/shop/search/:searchText"
          render={({ match, location }) => {
            const searchText = match.params.searchText || ''
            return (
              <WithPermissions
                authParams={{ permission: 'is_pro' }}
                searchText={searchText}
                component={ShopResultsPage}
              />
            )
          }}
        />
        <UiRoute exact path={'/shop/order_confirmation/:orderId'} component={OrderConfirmationPage} />
        <UiRoute exact path="/shop/connect_distributor/hdm/email" component={HdmConnectPage} />
        <UiRoute exact path="/shop/connect_distributor/hdm/verify_code" component={HdmVerifyCodePage} />
        <UiRoute exact path="/shop/connect_distributor/vtac/email" component={VtacConnectPage} />
        <UiRoute exact path="/shop/after_connect/:distributor" component={AfterConnectPage} />
        <UiRoute exact path="/shop/after_connect/:distributor/:page" component={AfterConnectPage} />
        <UiRoute exact path="/shop/order_by_project" component={OrderByProjectPage} />
        <UiRoute exact path={'/shop/cart'} component={cartPageComponent} />
        {supplier === HardwareSupplierEnum.Segen && (
          <UiRoute exact path={'/shop/cart/segen'} component={SegenCheckout} />
        )}
        {supplier !== HardwareSupplierEnum.Hdm && <UiRoute exact path={'/shop/cart/hdm'} component={HdmCheckout} />}
        {supplier === HardwareSupplierEnum.SolarOutlet && (
          <UiRoute exact path={'/shop/cart/outlet'} component={SolarJuiceCheckoutPage} />
        )}
        {supplier === HardwareSupplierEnum.Hdm && <UiRoute exact path={'/shop/cart/hdm'} component={HdmCheckout} />}
        {(supplier === HardwareSupplierEnum.VtacUk || supplier === HardwareSupplierEnum.VtacPoland) && (
          <UiRoute exact path={'/shop/cart/vtac'} component={VtacCheckout} />
        )}
        {supplier === HardwareSupplierEnum.CityPlumbing && (
          <>
            <UiRoute exact path={'/shop/cart/city_plumbing'} component={CityPlumbingCheckoutV2} />
            <UiRoute exact path={'/shop/cart/city_plumbing/:jobListId'} component={CityPlumbingCheckout} />
          </>
        )}
        <UiRoute path="/" component={NotFound} />
      </Switch>
    </div>
  )
}

export default OrderingRoutes
