import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { permissionsSelectors } from 'ducks/permissions'
import { ComponentVersionsInherit } from 'opensolar-ui'
import NoPermissionNotice from 'persistentContent/elements/NoPermissionNotice'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'
import ProductDetail3 from '../detailsv3/ProductDetail3'
import { RootContainer } from '../home/OrderingHome'
import ProductDetailProviderV2 from './ProductDetailProviderV2'

const ProductDetailPage = ({ componentCode }: { componentCode: string }) => {
  const history = useHistory<{ from?: string }>()
  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'product_detail',
    })
  }, [])

  const previousUrl = history.location.state?.from
  const handleBack = useCallback(() => {
    const redirectUrl = previousUrl || '/shop'
    history.push(redirectUrl)
  }, [previousUrl])
  const enabledHardwareShopV3 = useFeatureFlag('enable_multi_distributor_shop', 'on')
  const { allowView } = useSelector(permissionsSelectors.getPermissionByKey('shop_product_details'))

  return enabledHardwareShopV3 ? (
    <RootContainer enabledMultiDistributor={enabledHardwareShopV3}>
      <ComponentVersionsInherit versions={ComponentVersions_3_0}>
        {allowView ? (
          <ProductDetail3 componentCode={componentCode} goBack={handleBack} />
        ) : (
          <NoPermissionNotice text="Oops, you don't have permission to view the content" />
        )}
      </ComponentVersionsInherit>
    </RootContainer>
  ) : (
    <ProductDetailProviderV2 componentCode={componentCode} />
  )
}

export default ProductDetailPage
