import { useMediaQuery } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import { helpCenterActions } from 'ducks/helpCenter'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { useGlobalCart } from 'hooks/useGlobalCart'
import { BaseThemeType, Box, Chip, IconButton, styled } from 'opensolar-ui'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import SearchBarController from 'persistentContent/elements/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setToggleGlobalCart } from 'reducer/designer/view'
import { RootState } from 'types/state'
import { ActionType, EventType } from 'types/tracking'
import { useFeatureFlag } from 'util/split'

export const NavigationIconButton = styled(IconButton)({
  color: '#4F4D55',
  fontSize: 12,
  fontWeight: 700,
  gap: 6,
  padding: 8,
  '&:hover': {
    color: '#0A090B',
  },
  '& svg': {
    height: 20,
    width: 20,
  },
})

const StyledChip = styled(Chip)({
  position: 'absolute',
  right: 4,
  top: 0,
  height: 18,
  fontSize: 10,
  borderRadius: 4,
  background: '#E12121',
  '& span': {
    padding: '0 4px',
    color: '#FFFFFF',
  },
})

const NavigationIconButtons = () => {
  const { orderableLineItems } = useGlobalCart()
  const totalQuantity = ProjectOrderPresenter.getLineItemsTotalQuantity(orderableLineItems)
  const globalCartToggle = useSelector((state: RootState) => state.designer.view.globalCartToggle)
  const history = useHistory()
  const enableMultiDistributorShop = useFeatureFlag('enable_multi_distributor_shop', 'on')

  const dispatch = useDispatch()
  const isTablet = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.up('sm'))
  const { trackEvent } = useTrackComponent({
    componentKey: 'navigation_icon_buttons',
    eventName: 'User Viewed Navigation Icon Buttons',
  })

  return (
    <Box alignItems="center" display="flex" gridGap={6}>
      <SearchBarController
        triggerIconButton={
          <NavigationIconButton size="small" variant="squared">
            <SearchOutlinedIcon />
          </NavigationIconButton>
        }
      />
      <NavigationIconButton
        onClick={() =>
          enableMultiDistributorShop ? dispatch(setToggleGlobalCart(!globalCartToggle)) : history.push('/shop/cart')
        }
        size="small"
        variant="squared"
      >
        <ShoppingCartOutlinedIcon />
        {totalQuantity > 0 && <StyledChip label={`${totalQuantity}`} />}
      </NavigationIconButton>
      {isTablet && (
        <NavigationIconButton
          onClick={() => {
            trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { eventName: 'User Opened Help Center' })
            dispatch(helpCenterActions.setHelpCenterOpen(true))
          }}
          size="small"
          variant="squared"
        >
          <HelpIcon />
        </NavigationIconButton>
      )}
    </Box>
  )
}

export default NavigationIconButtons
