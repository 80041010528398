import { Step, StepButton, Stepper } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { styled } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'

type PropTypes = {
  activeStep: number
  completedSteps: {}
}

const StepperWrapper = styled(Stepper)({
  padding: '.5rem 0 1rem 0',
  borderBottom: '1px #DADBE9 solid',
  overflowX: 'scroll',
  whiteSpace: 'nowrap',
  scrollbarWidth: 'none',

  '& .MuiButtonBase-root': {
    cursor: 'default',
  },
  '& .MuiStep-horizontal': {
    padding: '0',
  },
  '& .MuiStepConnector-root': {
    display: 'none',
  },
  '& .MuiStepIcon-active': {
    color: '#FFDD52',
  },
  '& .MuiStepIcon-active .MuiStepIcon-text': {
    fill: '#0A090B',
  },
  '& .MuiStepIcon-completed': {
    color: '#019939',
  },

  '& .MuiStepLabel-label': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiStepLabel-label .MuiSvgIcon-root': {
    fontSize: '20px',
    margin: '0 10px',
  },
})

const steps = [`About Your Business`, 'Terms & Conditions', 'Owner Info', 'Bank Info']

const SetupStepper: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()

  return (
    <StepperWrapper activeStep={props.activeStep}>
      {steps.map((label, index) => (
        <Step key={label} completed={props.completedSteps[index]}>
          <StepButton color="inherit">
            {translate(label)}
            {index !== steps.length - 1 && <ChevronRight />}
          </StepButton>
        </Step>
      ))}
    </StepperWrapper>
  )
}
export default SetupStepper
