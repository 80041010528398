import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Translate } from 'ra-core'
import React from 'react'
import { useTranslate } from 'react-admin'
import { AnnualBillDataType, BillFrequencyTypeEnum, BillType, IncentivesBillDataType } from 'types/bills'
import { PaymentFrequencyTypeEnum } from 'types/paymentOptions'
import { SystemDataType } from 'types/systems'
import { ActionType, EventType } from 'types/tracking'
import { formatCurrency, isTariffMissing, periodsPerYear, tariffNotSuppliedText } from '../../../../util/misc'
import Disclaimer from '../../../elements/Disclaimer'
import LifeTimeSavingsChart from '../../../elements/LifeTimeSavingsChart'
import { incentiveRow, ItemTableRow } from '../../../elements/PaymentOptions'
import { PropTypes } from './BillSavings.types'
import BillSavingsChart from './BillSavingsChart'
import { billFrequencyLabels, billFrequencyQuantity } from './index'

const BillIncentivesTable = ({ billData, currencySymbol }: { billData: BillType; currencySymbol: string }) => {
  const translate = useTranslate()
  return (
    <div>
      <p style={{ textAlign: 'center' }}>{translate('Utility Bill Incentives (Over System Lifetime)')}</p>

      <table className="standard" style={{ minWidth: 300, width: '100%', margin: '20px auto 40px auto' }}>
        <tbody>
          {billData.incentives.map((incentiveData: IncentivesBillDataType, incentiveIndex: number) => (
            <ItemTableRow
              key={incentiveIndex}
              data={incentiveRow(
                getBillIncentiveTableData(translate, currencySymbol, incentiveData, billData.bill_frequency),
                currencySymbol
              )}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const getBillIncentiveTableData = (
  translate: Translate,
  currencySymbol: string,
  incentiveData: IncentivesBillDataType,
  billFrequency: BillFrequencyTypeEnum
) => {
  return {
    title: incentiveData.title,
    value: incentiveData.inc_tax,
    description: getBillIncentiveDescription(translate, currencySymbol, incentiveData, billFrequency),
  }
}

const getBillIncentiveDescription = (
  translate: Translate,
  currencySymbol: string,
  incentiveData: IncentivesBillDataType,
  billFrequency: BillFrequencyTypeEnum
) => {
  const firstYearIncentiveAmount = incentiveData.inc_taxes[0]
  const averageValuePerBill = (firstYearIncentiveAmount / 12) * billFrequencyQuantity[billFrequency]
  return `${currencySymbol}${formatCurrency(firstYearIncentiveAmount)} ${translate('in year 1')} (${translate(
    'approximately'
  )} ${currencySymbol}${formatCurrency(averageValuePerBill)} ${translate(billFrequencyLabels[billFrequency])})`
}

const getAvgAnnualValue = (annualBill: number, billFrequency: BillFrequencyTypeEnum) => {
  return (annualBill / 12) * billFrequencyQuantity[billFrequency]
}

const getAnnualBill = (annualBillData: AnnualBillDataType, ignoreTax: Boolean) => {
  if (ignoreTax) {
    return annualBillData.total_utility_bill_only
  } else {
    return annualBillData.total_utility_bill_only + annualBillData.tax
  }
}

// Same computation is also done on computeSavingsMap from proposal/savings/utils.ts
// If this will be updated, consider updating computeSavingsMap also
const getAvgFirstYearBillWithFrequency = (
  billData: BillType,
  ignoreTax: Boolean,
  billFrequency: BillFrequencyTypeEnum
) => {
  const annualBill = getAnnualBill(billData.bills_yearly[0].annual, ignoreTax)
  return getAvgAnnualValue(annualBill, billFrequency)
}

const getFirstYearIncentives = (billIncentives: IncentivesBillDataType[], ignoreTax: Boolean) => {
  if (ignoreTax) {
    return billIncentives.reduce((sum, incentive) => sum + incentive.inc_taxes[0], 0)
  } else {
    return billIncentives.reduce((sum, incentive) => sum + incentive.inc_taxes[0], 0)
  }
}

const getAvgFirstYearIncentive = (billData: BillType, ignoreTax: Boolean) => {
  const annualIncentive = getFirstYearIncentives(billData.incentives, ignoreTax)
  return getAvgAnnualValue(annualIncentive, billData.bill_frequency)
}

const getFirstYearRegularPayment = (paymentOption: any, system: SystemDataType) => {
  //assigned payment option any here because it has different data model based on different payment type
  const billFrequency: BillFrequencyTypeEnum = system.bills.proposedSelected.bill_frequency
  let monthlyRegularPayment: number
  if (paymentOption.payment_type === 'regular_payment') {
    const PaymentFrequency: PaymentFrequencyTypeEnum = paymentOption.payment_frequency
    monthlyRegularPayment = (paymentOption.regular_payment * periodsPerYear[PaymentFrequency]) / 12
  } else if (paymentOption.payment_type === 'ppa') {
    monthlyRegularPayment = paymentOption.regular_payment
  } else if (paymentOption.payment_type === 'loan_advanced') {
    const PaymentFrequency: PaymentFrequencyTypeEnum = paymentOption.final_payment_frequency
    monthlyRegularPayment = (paymentOption.regular_payment * periodsPerYear[PaymentFrequency]) / 12
  } else if (paymentOption.payment_type === 'loan' || paymentOption.payment_type === 'lease') {
    const PaymentFrequency: PaymentFrequencyTypeEnum = paymentOption.payment_frequency
    monthlyRegularPayment = (paymentOption.regular_payment * periodsPerYear[PaymentFrequency]) / 12
  } else {
    monthlyRegularPayment = 0
  }
  return monthlyRegularPayment * billFrequencyQuantity[billFrequency]
}

const getRegularPaymentsByYear = (paymentOption) => {
  if (paymentOption.payment_type === 'regular_payment') {
    return paymentOption.cash_flows_payments.slice(1)
  } else {
    return null
  }
}

const showExportCreditLegend = (props: PropTypes): boolean => {
  const include_distributed_generation_rules = [
    'net_billing_with_credit_carryover',
    'netherland_nem_phase_out_2031',
    'Gross Metering with Feed-in-tariff',
    'Gross Metering',
  ]
  const showExportCreditBreakdown = !props.proposalTemplateSettings.hide_export_credit_breakdown
  const feed_in_tariff_annual_proposed = props.selectedSystem.bills.proposedSelected.feed_in_tariff_annual_proposed

  // If distributed_generation_rules is null then set it to 'Gross Metering with Feed-in-tariff' (aka net billing) by default. This
  // is to handle bad data that is currently persisting in our database.
  const distributed_generation_rules = props.selectedSystem.bills.proposedSelected.distributed_generation_rules
    ? props.selectedSystem.bills.proposedSelected.distributed_generation_rules
    : 'Gross Metering with Feed-in-tariff'

  return (
    showExportCreditBreakdown &&
    feed_in_tariff_annual_proposed > 0 &&
    include_distributed_generation_rules.includes(distributed_generation_rules)
  )
}

const showBillIncentiveLegend = (props: PropTypes): boolean => {
  return (
    props.selectedSystem.bills.proposedSelected.incentives.length > 0 &&
    props.selectedPaymentOption.payment_type !== 'regular_payment'
  )
}

const showNetSavingsLegend = (props: PropTypes): boolean => {
  // If the net savings, or the savings (usage charge + regular payments), or the feed-in tariff is negative
  // We will show net savings in the cumulative bill chart. This is because a stacked bar chart cannot represent
  // individual elements with negative values.

  const currentBillByYear = props.selectedSystem.bills.current.bills_yearly.map((v) => v.annual.total)
  const proposedBillByYear = props.selectedSystem.bills.proposedSelected.bills_yearly.map((v) => v.annual.total)
  const regularPaymentsByYear = getRegularPaymentsByYear(props.selectedPaymentOption)
  const regularPaymentsByYearApplied =
    regularPaymentsByYear === null ? proposedBillByYear.map((v) => 0) : regularPaymentsByYear.slice(1)

  const numYears = Math.min(regularPaymentsByYearApplied.length, currentBillByYear.length, proposedBillByYear.length)
  const currentUsageChargeByYear = props.selectedSystem.bills.current.bills_yearly.map((v) => v.annual.usage_charges)
  const proposedUsageChargeByYear = props.selectedSystem.bills.proposedSelected.bills_yearly.map(
    (v) => v.annual.usage_charges
  )
  const currentFeedInTariffByYear = props.selectedSystem.bills.current.bills_yearly.map((v) => v.annual.feed_in_tariff)
  const proposedFeedInTariffByYear = props.selectedSystem.bills.proposedSelected.bills_yearly.map(
    (v) => v.annual.feed_in_tariff
  )

  for (let year = 0; year < numYears; year++) {
    // Note that we add regular payment instead of subtracting since regular payment is stored as a negative value
    const netSavingsForYear = currentBillByYear[year] - proposedBillByYear[year] + regularPaymentsByYearApplied[year]
    const savingsForYear =
      currentUsageChargeByYear[year] - proposedUsageChargeByYear[year] + regularPaymentsByYearApplied[year]
    const feedInTariffSavingsForYear = currentFeedInTariffByYear[year] - proposedFeedInTariffByYear[year]

    if (netSavingsForYear < 0 || savingsForYear < 0 || feedInTariffSavingsForYear < 0) return true
  }
  return false
}

const ExportCreditLegend = ({ countryIso2 }) => {
  const translate = useTranslate()
  return (
    <>
      <div
        className="legendFeedin"
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#007cd5',
          display: 'inline-block',
          marginRight: 10,
        }}
      />
      <div className="small" style={{ height: 10, display: 'inline-block', fontSize: 10 }}>
        {countryIso2 && countryIso2 === 'GB' ? 'Smart Export Guarantee' : translate('Export Credit')}
      </div>
    </>
  )
}

const SavingsLegend = () => {
  const translate = useTranslate()
  return (
    <>
      <div
        className="legendBillSavings"
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#ffd504',
          display: 'inline-block',
          marginRight: 10,
        }}
      />
      <div
        className="small"
        style={{
          height: 10,
          display: 'inline-block',
          marginRight: 20,
          fontSize: 10,
        }}
      >
        {translate('Savings')}
      </div>
    </>
  )
}

const IncentivesLegend = () => {
  const translate = useTranslate()
  return (
    <>
      <div
        className="legendIncentives"
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#e96122',
          display: 'inline-block',
          marginRight: 10,
          marginLeft: 20,
        }}
      />
      <div className="small" style={{ height: 10, display: 'inline-block', fontSize: 10 }}>
        {translate('Incentives')}
      </div>
    </>
  )
}

const NetSavingsLegend = ({ showBillIncentives, showExportCredit, countryIso2 }) => {
  const translate = useTranslate()

  const showIncentiveLabel = showBillIncentives ? translate('Incentives') : ''
  const showExportCreditLabel = showExportCredit
    ? countryIso2 && countryIso2 === 'GB'
      ? 'Smart Export Guarantee'
      : translate('Export Credit')
    : ''
  const legendLabelStart = `${translate('Net Savings')} = ${translate('Savings')}`

  let legendLabel = ''
  if (showBillIncentives === true && showExportCredit === true) {
    legendLabel = legendLabelStart.concat(` + ${showExportCreditLabel} + ${showIncentiveLabel}`)
  } else if (showBillIncentives === true && showExportCredit === false) {
    legendLabel = legendLabelStart.concat(` + ${showIncentiveLabel}`)
  } else if (showBillIncentives === false && showExportCredit === true) {
    legendLabel = legendLabelStart.concat(` + ${showExportCreditLabel}`)
  } else {
    legendLabel = translate('Net Savings')
  }

  return (
    <>
      <div
        className="legendNetSavings"
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#F5A623',
          display: 'inline-block',
          marginRight: 10,
          marginLeft: 20,
        }}
      />
      <div className="small" style={{ height: 10, display: 'inline-block', fontSize: 10 }}>
        {legendLabel}
      </div>
    </>
  )
}

const CumulativeBillSavingsLegends = ({ props }) => {
  const showNetSavings = showNetSavingsLegend(props)
  const showBillIncentives = showBillIncentiveLegend(props)
  const showExportCredit = showExportCreditLegend(props)
  const countryIso2 = props.selectedProject.country_iso2

  return showNetSavings ? (
    <NetSavingsLegend
      showBillIncentives={showBillIncentives}
      showExportCredit={showExportCredit}
      countryIso2={countryIso2}
    />
  ) : (
    <>
      <SavingsLegend />
      {showExportCredit && <ExportCreditLegend countryIso2={countryIso2} />}
      {showBillIncentives && <IncentivesLegend />}
    </>
  )
}

export const BillSavings: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const billFrequency: BillFrequencyTypeEnum = props.selectedSystem.bills.proposedSelected.bill_frequency
  const ignoreTax: Boolean = props.selectedSystem.pricing.ignore_tax_in_financials
  const regularPayment = getFirstYearRegularPayment(props.selectedPaymentOption, props.selectedSystem)
  const regularPaymentsByYear = getRegularPaymentsByYear(props.selectedPaymentOption)

  const { trackEvent } = useTrackComponent({
    componentKey: 'bill_savings',
    eventName: 'Track bill savings',
  })

  return (
    <div>
      <h1 className="mye-section-title">{props.proposalTemplateSettings.heading_electricity_bill_savings}</h1>
      <div style={{ textAlign: 'center', display: 'flex', flexDirection: props.layout <= 2 ? 'column' : 'row' }}>
        <div
          className={props.layout <= 2 ? 'myenergy-layout-small' : ''}
          style={{
            display: 'inline-block',
            width: props.layout <= 2 ? '100%' : '45%',
            marginRight: '5%',
          }}
        >
          <div className="BillSavingsGraph">
            <p style={{ fontSize: 12 }}>
              {props.hasPhoenix && <span>Estimated&nbsp;</span>}
              {translate('First Year')}&nbsp;
              {translate(billFrequencyLabels[billFrequency])}
              &nbsp;
              {translate('Bill Savings')}
            </p>
            <div style={{ display: 'flex' }}>
              {/* <div style={{ display: 'flex', width: 12 }}> */}
              <p
                style={{
                  fontSize: 11,
                  textAlign: 'center',
                  writingMode: 'vertical-rl',
                  transform: 'rotate(180deg)',
                  margin: '0 0 0 0',
                  color: '#9a9a9a',
                }}
              >
                {translate(`${billFrequencyLabels[billFrequency]} Electricity Spend`)}
              </p>

              <BillSavingsChart
                oldBill={getAvgFirstYearBillWithFrequency(props.selectedSystem.bills.current, ignoreTax, billFrequency)}
                newBill={getAvgFirstYearBillWithFrequency(
                  props.selectedSystem.bills.proposedSelected,
                  ignoreTax,
                  billFrequency
                )}
                regularPayment={regularPayment}
                totalBillBasedIncentive={getAvgFirstYearIncentive(
                  props.selectedSystem.bills.proposedSelected,
                  ignoreTax
                )}
                billFrequency={billFrequency}
                paymentType={props.selectedPaymentOption.payment_type}
                paymentTitle={props.selectedPaymentOption.title}
                // selectedPaymentOption={props.selectedPaymentOption}
                currencySymbol={props.currencySymbol}
                // layout={props.layout}
              />
            </div>
          </div>
        </div>
        <div
          className={props.layout <= 2 ? 'myenergy-layout-small' : ''}
          style={{
            display: 'inline-block',
            width: props.layout <= 2 ? '100%' : '45%',
          }}
        >
          <div className="LifeTimeSavingsGraph">
            <p style={{ fontSize: 12 }}>
              {props.hasPhoenix ? 'Estimated Cumulative Bill Savings' : translate('Cumulative Bill Savings')}
            </p>
            <LifeTimeSavingsChart
              myeStyles={props.myeStyles}
              selectedProject={props.selectedProject}
              isIgnoreTax={props.selectedSystem?.pricing?.ignore_tax_in_financials}
              bills_current={props.selectedSystem.bills.current}
              bills_proposed={props.selectedSystem.bills.proposedSelected}
              currencySymbol={props.currencySymbol}
              YAxisRange={props.YAxisRangeAllSystemsAndPaymentOptions.bill_savings}
              regular_payments_yearly={regularPaymentsByYear}
              translate={translate}
              fullCalcReady={props.fullCalcReady}
            />
            <div
              style={{
                margin:
                  props.selectedSystem.bills.proposedSelected.feed_in_tariff_annual_proposed &&
                  props.selectedPaymentOption.payment_type !== 'regular_payment'
                    ? '-10px 0px 20px 35px'
                    : '-10px 0px 20px 90px',
                textAlign: 'center',
              }}
            >
              <CumulativeBillSavingsLegends props={props} />
            </div>
          </div>
        </div>
        <br />
      </div>
      {props.sectionsData.map((BillSavingSection: string) => {
        switch (BillSavingSection) {
          case 'bill_savings_table':
            return (
              <p key={BillSavingSection} style={{ textAlign: 'left' }}>
                <a
                  className="EnergyConsumptionButtons"
                  onClick={() => {
                    trackEvent(
                      EventType.USER_INTERACTION,
                      { type: ActionType.CLICK },
                      { eventName: 'User Clicked On Detailed Savings Table Link' }
                    )
                    props.showDialog('SavingsDetail')
                  }}
                  style={{ textDecorationLine: 'underline', cursor: 'pointer', fontSize: 12 }}
                >
                  {translate('Detailed Savings Table')}
                </a>
              </p>
            )
          default:
            return props.getDefaultSection(
              props.sectionsData,
              BillSavingSection,
              'bill_savings',
              props.proposalTemplateSettings
            )
        }
      })}
      {props.selectedSystem.bills.proposedSelected.incentives.length > 0 && (
        <BillIncentivesTable
          billData={props.selectedSystem.bills.proposedSelected}
          currencySymbol={props.currencySymbol}
        />
      )}

      <div className="BillDisclaimer">
        {props.selectedSystem &&
          props.selectedSystem.bills &&
          props.selectedSystem.bills.current &&
          props.selectedSystem.bills.current.utility_tariff_name !==
            props.selectedSystem.bills.proposedSelected.utility_tariff_name && (
            <p className="small">
              {translate('Utility savings based on switch', {
                from: props.selectedSystem.bills.current.utility_tariff_name,
                to: props.selectedSystem.bills.proposedSelected.utility_tariff_name,
              })}
            </p>
          )}
        {isTariffMissing(props.selectedProject) && (
          <Disclaimer content={tariffNotSuppliedText(props.selectedProject.utility_tariff_or_guess, translate)} />
        )}
        <Disclaimer content={props.selectedSystem.disclaimer_system_performance} />
      </div>
      {/* <ExhibitCustomTile system={props.selectedSystem} categories={['utility_tariffs']} /> */}
    </div>
  )
}
