var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, TransactionActionButton } from 'opensolar-ui';
import { useMemo, useState } from 'react';
var SequenceNumberWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        width: 44,
        height: 0,
        minWidth: 44,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
});
var PlentiApplication = function (_a) {
    var elementData = _a.elementData, translate = _a.translate, proposalData = _a.proposalData, config = _a.config, onAction = _a.onAction, options = _a.options;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var actionData = elementData.actionData;
    var openApplicationDialog = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, onAction({ elementData: elementData, proposalData: proposalData })];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var buttonLabelText = useMemo(function () {
        if (actionData.status_title === 'InitiatedButIncomplete') {
            return translate('Continue with Plenti Finance');
        }
        else if (actionData.status_title === 'ApprovedPendingDocs') {
            return translate('Apply for Plenti Finance ✓');
        }
        else if (actionData.status_title === 'ApprovedNotTrackingSubstate') {
            return translate('Plenti Finance Approved ✓');
        }
        else {
            return translate('Apply for Plenti Finance');
        }
    }, [actionData]);
    var extra_actions = [];
    if (actionData.status_code === 'available') {
        extra_actions.push(_jsx("p", { className: "small", children: 'Clicking "' +
                buttonLabelText +
                '" will take you to the Plenti\'s credit application. OpenSolar does not view or store any of the data you enter in this application.' }, extra_actions.length));
    }
    if (actionData.status_description) {
        extra_actions.push(_jsx("p", { children: actionData.status_description }));
    }
    return (_jsxs("div", { children: [_jsx(TransactionActionButton, { isLoading: isLoading, label: _jsxs(_Fragment, { children: [_jsx(SequenceNumberWrapper, { children: "1" }), buttonLabelText] }), disabled: actionData.status_code !== 'available', onClick: openApplicationDialog }), extra_actions] }));
};
export default PlentiApplication;
