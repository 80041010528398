import { findFormPlacements, FormPlacementInfo } from 'customForm/util/findFormPlacements'
import { AvailableActionsResponseType, doAvailableActionsRequest, markAsSold, myEnergySelectors } from 'ducks/myEnergy'
import {
  clearLoadingTransactionForm,
  hideTransactionForm,
  loadingTransactionForm,
  transactionSelectors,
} from 'ducks/transaction'
import usePhoenixApplicationDialog from 'myenergy/elements/phoenix/phoenixApplication/usePhoenixApplicationDialog'
import useUploadStipulationDialog from 'myenergy/selectionComponent/financeStipulations/useUploadStipulationDialog'
import useLoanAgreementDialog from 'myenergy/selectionComponent/loanAgreement/useLoanAgreementDialog'
import useBrighteApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/brighte/useBrighteApplicationDialog'
import useDividendApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/dividend/useDividendApplicationDialog'
import useDisclaimerRedirectDialog from 'myenergy/selectionComponent/loanApplicationButton/generic/useDisclaimerRedirectDialog'
import useGenericFinanceIframeDialog from 'myenergy/selectionComponent/loanApplicationButton/generic/useGenericFinanceIframeDialog'
import useHostedCreditApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/generic/useHostedCreditApplicationDialog'
import useLoanalApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/loanpal/useLoanalApplicationDialog'
import useMosaicApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/mosaic/useMosaicApplicationDialog'
import useSungageApplicationDialog from 'myenergy/selectionComponent/loanApplicationButton/sungage/useSungageApplicationDialog'
import useSunlightLoanApplication from 'myenergy/selectionComponent/loanApplicationButton/sunlight/useSunlightLoanApplication'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomFormUsageType, FormPlacementEnum } from 'resources/customForms/types'
import restClient from 'restClient'
import { ProposalDataType } from 'types/proposals'
import { DialogHelper } from 'util/misc'
import { useIntegratedCheckoutPaymentDialog } from './cashFlow/IntegratedCheckoutPaymentCTA'
import CustomFormDialog from './customForms/CustomFormDialog'
import DocuSignDialog from './docusign/DocuSignDialog'
import CheckoutDialog from './nativeSigning/CheckoutDialog'
import SuccessDialog from './SuccessDialog'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  projectId: number
  systemUuid: string
  paymentOptionId: number
  orgId: number
  proposalData: ProposalDataType
}

type DialogType = undefined | 'custom_form' | 'checkout' | 'success'
const TransactionDialog: React.FC<PropTypes> = (props) => {
  const [currentDialogType, setCurrentDialogType] = useState<DialogType>(undefined)
  const [actionData, setActionData] = useState<undefined | ActionDataType>(undefined)
  const [allActionData, setAllActionData] = useState<undefined | ActionDataType[]>()
  const [requiresDocuSign, setRequiresDocuSign] = useState<boolean>(false)
  const [customForms, setCustomForms] = useState<FormPlacementInfo[]>([])
  const showDialog = useSelector(transactionSelectors.getShowTransactionDialog)

  const dispatch = useDispatch()
  const isFetchingActionData = useSelector(transactionSelectors.getIsLoading)

  // check to see if we should be showing the DocuSign flow
  // we will show DocuSign as long as the requires_docusign_signature (which comes from the back-end) is true, the action is not marked as complete
  // AND there is no all_signed=true param. This param is added to the return URL by DocuSign once all customers have signed
  // this param allows us to show a completed DocuSign UI before our back-end gets the webhook it needs to mark the transaction as complete
  useEffect(() => {
    const postHashParams = window?.location?.hash?.substring(window?.location?.hash?.indexOf('?') + 1)
    const params = new URLSearchParams(postHashParams)
    if (
      actionData?.status_code !== 'complete' &&
      actionData?.requires_docusign_signature &&
      params.get('all_signed') !== 'true'
    ) {
      setRequiresDocuSign(true)
    } else {
      setRequiresDocuSign(false)
    }
  }, [actionData?.requires_docusign_signature])

  const doSetup = () => {
    dispatch(loadingTransactionForm())
    doAvailableActionsRequest(props.orgId, props.projectId, {
      system_uuid: props.systemUuid,
      payment_option_id: props.paymentOptionId,
    })
      .then((res: AvailableActionsResponseType) => {
        if (res?.data?.length && res.data[0]?.actions_available) {
          let nonFinanceActions = res.data[0]?.actions_available?.filter(
            (act) => !act?.payment_method?.includes('_application') || act.status_code === 'hidden'
          )
          let nonFinanceAndCashFlowActions = nonFinanceActions?.filter((act) => act.payment_method !== 'cashflow')
          // set action data, should ignore finance and cashflow
          setActionData(nonFinanceAndCashFlowActions[0])
          // all action data should ignore finance but should include cashflow since the checkout dialog needs to be aware that a cashflow
          // deposit is required after the proposal is accepted
          setAllActionData(nonFinanceActions)
          // if action is complete then just open the checkout dialog. This means the customer is just reviewing what they've already signed
          if (nonFinanceAndCashFlowActions[0]?.status_code === 'complete') {
            setCurrentDialogType('checkout')
            return
          }
          // if action is not complete then check for applicable custom forms for the org
          restClientInstance('CUSTOM_GET', 'custom', {
            url: `orgs/${props.proposalData.org.id}/proposal/custom_forms/contract/`,
          })
            .then((res) => {
              let filteredForms = [] as FormPlacementInfo[]
              if (res.data.success) {
                // filter down to only custom forms that apply to this specific contract that the user just tried to open
                filteredForms = findFormPlacements({
                  forms: res.data.custom_forms as CustomFormUsageType[],
                  location: FormPlacementEnum.before_contract,
                  entityId: props.proposalData.selectedPaymentOption.contract_template_id,
                })
              }
              // if a custom form is found, open the custom form dialog first
              if (filteredForms?.length) {
                setCustomForms(filteredForms)
                setCurrentDialogType('custom_form')
              } else {
                //otherwise open checkout
                setCurrentDialogType('checkout')
              }
            })
            .catch((err) => {
              console.warn('Failed to load custom forms: ', err)
              setCurrentDialogType('checkout')
            })
        }
      })
      .catch((err) => console.log('err', err))
      .finally(() => {
        dispatch(clearLoadingTransactionForm())
      })
  }

  // wait until dialog is supposed to be open, then fetch everything we need
  useEffect(() => {
    if (showDialog) {
      doSetup()
    }
  }, [showDialog])

  const closeDialog = () => {
    DialogHelper.beforeClose()
    setCurrentDialogType(undefined)
    dispatch(hideTransactionForm())
  }

  const onCheckoutSuccess = () => {
    dispatch(markAsSold(props.systemUuid, props.paymentOptionId))
    setCurrentDialogType('success')
  }

  if (!showDialog) return null
  if (isFetchingActionData || (!actionData && !customForms?.length)) return null
  return (
    <div data-testid="checkout-dialog-main-wrapper">
      {currentDialogType === 'custom_form' && (
        <CustomFormDialog
          onNext={() => setCurrentDialogType('checkout')}
          onClose={closeDialog}
          customForms={customForms}
          project={props.proposalData.selectedProject}
          system={props.proposalData.selectedSystem}
          orgId={props.proposalData.org.id}
        />
      )}
      {currentDialogType === 'checkout' && !requiresDocuSign && actionData && (
        <CheckoutDialog
          proposalData={props.proposalData}
          actionData={actionData}
          allActionData={allActionData}
          onClose={closeDialog}
          isOpen={true}
          onSuccess={onCheckoutSuccess}
        />
      )}
      {currentDialogType === 'checkout' && requiresDocuSign && actionData && (
        <DocuSignDialog proposalData={props.proposalData} actionData={actionData} onClose={closeDialog} isOpen={true} />
      )}
      {currentDialogType === 'success' && <SuccessDialog dismiss={closeDialog} proposalData={props.proposalData} />}
    </div>
  )
}

const OtherCheckoutDialogs = memo(() => {
  const cashFlowDepositDialog = useIntegratedCheckoutPaymentDialog()
  const sunlightApplicationDialog = useSunlightLoanApplication()
  const loanpalApplicationDialog = useLoanalApplicationDialog()
  const mosaicApplicationDialog = useMosaicApplicationDialog()
  const sungageApplicationDialog = useSungageApplicationDialog()
  const brighteApplicationDialog = useBrighteApplicationDialog()
  const dividendApplicationDialog = useDividendApplicationDialog()
  const phoenixApplicationDialog = usePhoenixApplicationDialog()
  const genericFinanceIframeDialog = useGenericFinanceIframeDialog()
  const hostedCreditApplicationDialog = useHostedCreditApplicationDialog()
  const disclaimerRedirectDialog = useDisclaimerRedirectDialog()
  const uploadStipulationDialog = useUploadStipulationDialog()
  const loanAgreementDialog = useLoanAgreementDialog()

  const currentCheckoutDialog = useSelector(myEnergySelectors.getCheckoutDialog)

  const registeredCheckoutDialogs = [
    cashFlowDepositDialog,
    sunlightApplicationDialog,
    loanpalApplicationDialog,
    mosaicApplicationDialog,
    sungageApplicationDialog,
    brighteApplicationDialog,
    dividendApplicationDialog,
    phoenixApplicationDialog,
    genericFinanceIframeDialog,
    hostedCreditApplicationDialog,
    disclaimerRedirectDialog,
    uploadStipulationDialog,
    loanAgreementDialog,
  ]

  const closeAllDialogs = () => {
    registeredCheckoutDialogs.forEach((dialog) => {
      if (dialog.isOpen) {
        dialog.closeDialog()
      }
    })
  }

  useEffect(() => {
    if (currentCheckoutDialog === undefined) {
      closeAllDialogs()
      return
    }
    registeredCheckoutDialogs.forEach((dialog) => {
      if (dialog.type === currentCheckoutDialog.type) {
        // @ts-ignore
        dialog.openDialog(currentCheckoutDialog.config)
      }
    })

    closeAllDialogs()
  }, [currentCheckoutDialog])

  return (
    <>
      {registeredCheckoutDialogs.map((dialog) => {
        return <React.Fragment key={dialog.type}>{dialog.dialogNode}</React.Fragment>
      })}
    </>
  )
})

const CheckoutRoot: React.FC<PropTypes> = (props) => {
  return (
    <>
      <OtherCheckoutDialogs />
      <TransactionDialog {...props} />
    </>
  )
}

export default CheckoutRoot
