import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import CreateButtonWithDialog, { CustomizedToolbar } from 'elements/button/CreateButtonWithDialog'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import TrackedToolbar from 'elements/TrackedToolbar'
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  NotFound,
  SimpleForm,
  TextField,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import CustomFieldInputs from './CustomFieldInputs'
import { FIELD_TYPES, getModelByFieldName, PREDEFINED_MODELS_CONFIG, PREDEFINED_MODELS_TYPES } from './PredefinedModels'

const CustomFieldCreateDialog = ({ mode, onClose }) => {
  const translate = useTranslate()
  return (
    <div>
      <Dialog id="Custom-Field-Create-Dialog" open={!!mode}>
        <DialogTitle>{translate('Create Custom Field')}</DialogTitle>
        <DialogContent>
          <CustomFieldCreate
            resource="custom_fields"
            basePath="/custom_fields/create"
            model={mode}
            toolbar={<CustomizedToolbar onClose={onClose} />}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

const CustomFieldListActions = (props) => {
  return (
    <>
      <ListActions {...props} hasArchived={false} hasCreate={false} />
      <CreateButtonWithDialog
        label={'Add Custom Field'}
        id="create-custom-field-button"
        choices={PREDEFINED_MODELS_TYPES.map((model) => ({
          id: model,
          label: `Create ${PREDEFINED_MODELS_CONFIG[model]?.label} Custom Field`,
        }))}
        dialog={<CustomFieldCreateDialog />}
      />
    </>
  )
}

export const CustomFieldList = (props) => {
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getPermissionByKey('business_process')
  )

  if (!allowView) {
    return <NotFound />
  }
  return (
    <List
      actions={<CustomFieldListActions />}
      filters={null}
      hasSearch={true}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
    >
      <Datagrid>
        <FunctionField
          source={null}
          label="Field Location"
          render={(record, source, rest) => {
            const model = getModelByFieldName(record.field)
            return <span>{PREDEFINED_MODELS_CONFIG[model]?.label || 'Unknown'}</span>
          }}
          sortable={false}
        />
        <TextField source="label" />
        <TextField source="field" label={'Field Placeholder'} />

        <FunctionField
          source={null}
          label="Field Type"
          render={(record, source, rest) => (
            <span>{FIELD_TYPES.find((type) => type.id === record.field_type)?.name || 'Unknown'}</span>
          )}
          sortable={false}
        />
        {
          <FunctionField
            source={null}
            label="Actions"
            render={(record, source, rest) => (
              <div>
                {allowEdit && <EditButton useListStyle={true} record={record} basePath={rest.basePath} />}
                {allowDelete && (
                  <DeleteButton
                    useListStyle={true}
                    undoable={false}
                    record={record}
                    resource={rest.resource}
                    redirect={`/${rest.resource}`}
                  />
                )}
              </div>
            )}
            sortable={false}
          />
        }
      </Datagrid>
    </List>
  )
}

export const formatSubmitValues = (values) => {
  if (values.field_type === 1 || values.field_type === 4) {
    if (values.select_field_choices !== undefined && Array.isArray(values.select_field_choices)) {
      values.select_field_choices = values.select_field_choices.filter((a) => !!a).join(',')
    }
  } else {
    //strike off select_field_choices if field type is not dropdown
    delete values.select_field_choices
  }
  return values
}

const parseDefaultValues = (record) => {
  if (record.field_type === 1 || record.field_type === 4) {
    if (record.select_field_choices === '') {
      record.select_field_choices = []
    } else if (record.select_field_choices !== undefined && typeof record.select_field_choices === 'string') {
      record.select_field_choices = record.select_field_choices.split(',')
    }
  }
  return record
}

export const CustomFieldEdit = (props) => {
  const { allowView, allowEdit, allowDelete } = useSelector(permissionsSelectors.getPermissionByKey('business_process'))

  if (!allowView) {
    return <NotFound />
  }
  return (
    <Edit title={'Edit Custom Fields'} {...props} hasDelete={allowDelete}>
      <SimpleForm
        formatSubmitValues={formatSubmitValues}
        defaultValue={parseDefaultValues}
        validate={validateLabel}
        toolbar={<TrackedToolbar {...props} />}
        disabled={!allowEdit}
      >
        <CustomFieldInputs disableLocationSelector={true} disableFieldSelector={true} isEdit={true} />
      </SimpleForm>
    </Edit>
  )
}

export const CustomFieldCreate = (props) => (
  <Create title={'Create Custom Field'} {...props}>
    <SimpleForm
      validate={validateLabel}
      formatSubmitValues={formatSubmitValues}
      toolbar={<TrackedToolbar {...props} />}
    >
      <CustomFieldInputs model={props.model} disableLocationSelector={false} disableFieldSelector={false} />
    </SimpleForm>
  </Create>
)

export const validateLabel = (values) => {
  const errors = {}
  if (!values.label) {
    errors.label = ['Label is required.']
  }
  return errors
}
