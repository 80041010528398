import { DropdownInput, InputValue, IntegrationData } from 'types/mounting'
import { PossibleInputVariables, ProductCode, RoofTypeName } from './types'

export const findDropdownInput = (allItems: DropdownInput[], varName: PossibleInputVariables): DropdownInput => {
  return allItems.filter((item) => item.variableName === varName)[0]
}

export type RoofTypeCompatibility = Record<ProductCode, Array<RoofTypeName>>
type RailCompatibility = Record<ProductCode, Array<ProductCode>>

export type ComptibilityRules = {
  [key in PossibleInputVariables]?: {
    roofTypeCompatibility?: RoofTypeCompatibility
    railCompatibility?: RailCompatibility
    profileCompatibility?: Record<string, Array<string>>
    roofHookMaterialCompatibility?: Record<string, Array<string>>
  }
}

export type FilterCriteria = {
  filterType: string
  filterValue: string // Can either be the filter value itself or its path relative to integration data, in the form "#/var"
}

// Returns a filtered down version of the dopdownInput and updates the default to the first matching, if no compatibility it throws an error
// If there are multiple compatibility criteria for an input variable, ALL criteria must be met for the input variable to be shown
// If the compatibility rule is undefined, assume compatibility
export const filterOptions = (
  allInputs: DropdownInput[],
  rules: ComptibilityRules,
  criteria: FilterCriteria[],
  integrationData: IntegrationData
): DropdownInput[] => {
  const filteredInputs = allInputs
    .map((dropdownInput, index, currentInputs) => {
      let filteredOptions = dropdownInput.options
      let dropdownInputResult: DropdownInput | null = dropdownInput
      if (!rules.hasOwnProperty(dropdownInput.variableName)) return dropdownInput

      const dropdownInputRules = rules?.[dropdownInput.variableName] as RoofTypeCompatibility | RailCompatibility

      criteria.forEach((filterCriteria) => {
        if (!dropdownInputRules.hasOwnProperty(filterCriteria.filterType)) return
        if (!filterCriteria.filterValue) return
        if (filterCriteria.filterValue.toString()[0] === '#') {
          let ref = filterCriteria.filterValue.toString().slice(2)
          filterCriteria.filterValue =
            integrationData[ref] || currentInputs.filter((input) => input.variableName === ref)[0]?.default
          if (!filterCriteria.filterValue) return
        }
        const inputOptionRules = dropdownInputRules?.[filterCriteria.filterType]

        const checkIfTypeIncluded = (dropdownInputOptionValue: InputValue, filterValue: string) => {
          if (typeof dropdownInputOptionValue === 'boolean')
            dropdownInputOptionValue = dropdownInputOptionValue.toString()
          const rulesForProduct = inputOptionRules?.[dropdownInputOptionValue] as Array<string>
          const result = rulesForProduct ? rulesForProduct?.includes(filterValue) || false : true
          return result
        }

        filteredOptions = filteredOptions.filter((option) =>
          checkIfTypeIncluded(option.value, filterCriteria.filterValue)
        )
      })
      if (filteredOptions.length === 0) {
        dropdownInputResult = null
      } else {
        dropdownInputResult = { ...dropdownInput, options: filteredOptions }
      }
      return dropdownInputResult
    })
    .filter((dropdownInput) => dropdownInput)

  const filteredInputsWithoutNull = filteredInputs.filter((a) => a != null) as DropdownInput[]

  return filteredInputsWithoutNull
}
