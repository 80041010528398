import { CircularProgress } from '@material-ui/core'
import Alert from 'elements/Alert'
import { AutoAddElectricalResults, useAutoAddElectrical } from 'elements/electrical/autoAddElectrical'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { FC, useMemo, useState } from 'react'
import { useStudioInput } from 'Studio/signals/useStudioInput'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioInverterType, StudioSystemType } from 'types/global'
import { ActionType, EventType } from 'types/tracking'

type Props = {
  system: StudioSystemType
  inverter: StudioInverterType
  disabled?: boolean
}

const useStyles = makeOpenSolarStyles(
  () => ({
    subheading: { fontSize: 12, fontWeight: 'bold', margin: '2em 0 0.2em' },
    inputRow: { display: 'flex', gap: 8, alignItems: 'start' },
    select: { padding: 4 },
    updateButton: { flexGrow: 1, marginTop: 16 },
  }),
  { name: 'InverterStringIsolationMethod' }
)

type Option = {
  title: string
  id: string
  isolatePerMPPT: boolean
}

export const InverterStringIsolationMethod: FC<Props> = ({ system, inverter, disabled }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { value, onChange } = useStudioInput<string>({ entity: inverter, field: 'string_isolation' })
  const { errorMessage, autoAddElectrical } = useAutoAddElectrical(system)
  const [addResults, setAddResults] = useState<AutoAddElectricalResults | undefined>()
  const [running, setRunning] = useState(false)
  const { trackEvent } = useTrackComponent({
    componentKey: 'inverter_string_isolation',
    eventName: 'Isolator Auto Applied',
  })

  const updateInverterStringIsolation = () => {
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK })
    if (!option) return
    setRunning(true)
    setAddResults(undefined)
    autoAddElectrical(inverter, option.isolatePerMPPT, (results) => {
      setAddResults(results)
      setRunning(false)
      trackEvent(EventType.SYSTEM_EVENT, { type: ActionType.SAVE }, { isolatePerMPPT: option.isolatePerMPPT, results })

      setTimeout(() => {
        setAddResults(undefined)
      }, 10000)
    })
  }

  const options: Option[] = useMemo(
    () => [
      {
        title: translate('Seperately'),
        id: 'seperately',
        isolatePerMPPT: false,
      },
      {
        title: translate('Parallel'),
        id: 'parallel',
        isolatePerMPPT: true,
      },
    ],
    []
  )
  const option = options.find((option) => option.id === value)

  return (
    <>
      <p className={classes.subheading}>{translate('Select isolating parallel or separately')}</p>
      <div className={classes.inputRow}>
        <SharedEntitySelectInput
          variant="outlined"
          choices={options}
          label="Isolate strings"
          value={value}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string
            setAddResults(undefined)
            setRunning(false)
            onChange(value)
          }}
          standardPadding={true}
          disabled={disabled}
          emptyText={'Choose...'}
          renderValue={(option: Option) => translate(option.title)}
        />
        <Button
          className={classes.updateButton}
          onClick={updateInverterStringIsolation}
          disabled={!option || running}
          size="small"
        >
          {running ? <CircularProgress size={20} /> : translate('Update')}
        </Button>
      </div>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {addResults && <Alert severity="success">{translate('Added %{total} electrical component/s', addResults)}</Alert>}
    </>
  )
}
