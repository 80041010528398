import { useMediaQuery } from '@material-ui/core'
import React, { useEffect } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { Theme } from 'types/themes'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import LoanMilestoneCell from './LoanMilestoneCell'

const useStyles = makeOpenSolarStyles<{ isMobile: boolean }>((theme) => ({
  container: {
    display: 'flex',
    minWidth: '100%',
    padding: '5px 20px',
    overflowX: 'auto',
    boxSizing: 'border-box',
    marginBottom: ({ isMobile }) => (isMobile ? '10px' : '0px'),
    marginRight: ({ isMobile }) => (!isMobile ? '10px' : '0px'),
  },
}))

type PropTypes = {
  onChangeTotalAmount: (amount: number) => void
  milestones: ExpectedMilestonePaymentType[]
  countryIso2: string
  milestonesChecked: { [key: number]: boolean }
  setMilestonesChecked: (value) => void
}

const LoanMilestoneTimeline: React.FC<PropTypes> = (props) => {
  const { onChangeTotalAmount, milestones, countryIso2, setMilestonesChecked, milestonesChecked } = props
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const currencySymbol = currencySymbolForCountry(countryIso2)
  const classes = useStyles({ isMobile })

  useEffect(() => {
    // On load, set all unpaid milestones as checked
    setMilestonesChecked((prev) => {
      return {
        ...milestones.reduce((acc, curr) => {
          acc[curr.payment_number] = !curr.is_paid
          return acc
        }, {}),
      }
    })
  }, [milestones])

  useEffect(() => {
    // Compute total amount of checked milestones
    if (milestones.length && Object.keys(milestonesChecked).length) {
      const sum = milestones
        .filter((m) => milestonesChecked[m.payment_number] && !m.is_paid)
        .reduce((partialSum, m) => partialSum + m.payment_amount, 0)

      onChangeTotalAmount(sum)
    }
  }, [milestonesChecked, milestones])

  const handleOnCheck = (payment_number: number) => {
    setMilestonesChecked((prev) => ({ ...prev, [payment_number]: !prev[payment_number] }))
  }

  return (
    <>
      <div className={classes.container}>
        {milestones.map((milestone, idx) => (
          <LoanMilestoneCell
            key={milestone.payment_number}
            onCheck={handleOnCheck}
            isChecked={!!milestonesChecked?.[milestone.payment_number]}
            isPaid={milestone.is_paid}
            isLast={milestones.length === idx + 1}
            title={milestone.title}
            amount={`${formatCurrencyWithSymbol(milestone.payment_amount, currencySymbol, undefined, 0)}`}
            paymentNumber={milestone.payment_number}
          />
        ))}
      </div>
    </>
  )
}

export default LoanMilestoneTimeline
