export const STEPS = [
  {
    step: 1,
    stepId: 'contact',
    label: 'Select a Contact',
  },
  {
    step: 2,
    stepId: 'configure',
    label: 'Create Custom Invoice',
  },
  {
    step: 3,
    stepId: 'preview',
    label: 'Preview Invoice',
  },
  {
    step: 4,
    stepId: 'email',
    label: 'Review & Send Invoice',
  },
]
