/**
 * This is a list of system fields. There are three layers
 * 1) Type (Proposal, Exhibit) - Some Wysiwyg renders only a Proposal section,
 *    some users may get Exhibit content
 * 2) Section - A local group of fields for visual representation
 * 3) Fields - A list of system variable fields
 */
import { MentionContexts } from './constants'
import { EditorPlaceholderCategory } from './types'

const dataConfigCategories: Record<string, EditorPlaceholderCategory> = {
  invoice: {
    category: 'Invoice',
    items: [
      {
        label: 'Invoice Number',
        desc: '',
        field: 'invoice_number',
        type: 'number',
      },
      {
        label: 'Amount Due',
        desc: '',
        field: 'invoice_amount',
        type: 'number',
      },
      {
        label: 'Contact Name',
        desc: '',
        field: 'customer_name',
      },
      {
        label: 'Company email',
        desc: '',
        field: 'org_email',
      },
      {
        label: 'Company name',
        desc: '',
        field: 'org_name',
      },
      {
        label: 'Payment URL',
        desc: '',
        field: 'payment_page_url',
      },
    ],
  },
  invitation: {
    category: 'MyEnergy Invitation URL',
    items: [
      {
        label: 'MyEnergy Invitation URL',
        desc: '',
        field: 'myenergy_invitation_url',
      },
      {
        label: 'Schedule Meeting URL',
        desc: '',
        field: 'schedule_meeting_url',
      },
      {
        label: 'Schedule Meeting Label',
        desc: '',
        field: 'schedule_meeting_label',
      },
    ],
  },
  acceptance_doc: {
    category: 'Attached File',
    items: [
      {
        label: 'Attached File Type',
        desc: '',
        field: 'attached_file_type',
      },
    ],
  },
  project_role: {
    create_for_each: 'project_role',
    category: '%{label}',
    items: [
      {
        label: '%{label} Full Name',
        desc: '',
        field: '%{path}.display',
      },
      {
        label: '%{label} Last Name',
        desc: '',
        field: '%{path}.family_name',
      },
      {
        label: '%{label} First Name',
        desc: '',
        field: '%{path}.first_name',
      },
      {
        label: '%{label} Email',
        desc: '',
        field: '%{path}.email',
      },
      {
        label: '%{label} Phone',
        desc: '',
        field: '%{path}.phone',
      },
      {
        label: '%{label} Job Title',
        desc: '',
        field: '%{path}.job_title',
      },
      {
        label: '%{label} Accreditation',
        desc: '',
        field: '%{path}.accreditation',
      },
    ],
  },
  customer: {
    create_for_each: 'contact',
    category: 'Contact %{num}',
    items: [
      { label: '%{label} Full Name', desc: '', field: '%{path}.full_name' },
      { label: '%{label} Last Name', desc: '', field: '%{path}.family_name' },
      { label: '%{label} First Name', desc: '', field: '%{path}.first_name' },
      { label: '%{label} Email', desc: '', field: '%{path}.email' },
      { label: '%{label} Phone', desc: '', field: '%{path}.phone' },
      { label: '%{label} Date of Birth', desc: '', field: '%{path}.date_of_birth', type: 'date' },
      { label: '%{label} Gender', desc: '', field: '%{path}.gender' },
      { label: '%{label} Passport Number', desc: '', field: '%{path}.passport_number' },
      { label: '%{label} Licence Number', desc: '', field: '%{path}.licence_number' },
      {
        label: '%{label} Custom Field %{field_num}',
        desc: '',
        field: '%{path}.custom_contact_info_1',
        transSubs: { field_num: 1 },
      },
      {
        label: '%{label} Custom Field %{field_num}',
        desc: '',
        field: '%{path}.custom_contact_info_2',
        transSubs: { field_num: 2 },
      },
    ],
  },
  org: {
    category: 'Business',
    items: [
      { label: 'Business About Content', desc: '', field: 'org.about_content', type: 'richtext' },
      { label: 'Business Address', desc: '', field: 'org.address' },
      { label: 'Business Email', desc: '', field: 'org.company_email' },
      {
        label: 'Business Website',
        desc: '',
        field: 'org.company_website',
      },
      { label: 'Business Country Iso2', desc: '', field: 'org.country.iso2' },
      { label: 'Business Country Name', desc: '', field: 'org.country.name' },
      { label: 'Business Id', desc: '', field: 'org.id' },
      { label: 'Business Locality', desc: '', field: 'org.locality' },
      { label: 'Business Name', desc: '', field: 'org.name|safe' },
      {
        label: 'Business Sales Phone Number',
        desc: '',
        field: 'org.sales_phone_number',
      },
      { label: 'Business State', desc: '', field: 'org.state' },
      { label: 'Business Zip', desc: '', field: 'org.zip' },
      { label: 'Company Logo', desc: '', field: 'snippet_logo_xsmall', type: 'snippet' },
    ],
  },
  project: {
    category: 'Project',
    items: [
      { label: 'Project Address', desc: '', field: 'project.address' },

      {
        label: 'Project-Specific Proposal Content',
        desc: '',
        field: 'project.proposal_content',
        type: 'richtext',
      },
      {
        label: 'Project Valid Until Date',
        desc: '',
        field: 'project.valid_until_date',
        type: 'date',
      },
      {
        label: 'Project Sale Date',
        desc: '',
        field: 'project.contract_date',
        type: 'date',
      },
      {
        label: '3 Days after Sale Date',
        desc: '',
        field: 'project.contract_date|plus_days:3',
        type: 'date',
      },
      {
        label: 'Project Created Date',
        desc: '',
        field: 'project.created_date',
        type: 'date',
      },
      { label: 'Project Id', desc: '', field: 'project.id' },
      { label: 'Project External Identifier', desc: '', field: 'project.identifier' },
      {
        label: 'Project Installation Date',
        desc: '',
        field: 'project.installation_date',
        type: 'date',
      },

      { label: 'Project Lat', desc: '', field: 'project.lat' },
      { label: 'Project Locality', desc: '', field: 'project.locality' },
      { label: 'Project Lon', desc: '', field: 'project.lon' },
      {
        label: 'Project Modified Date',
        desc: '',
        field: 'project.modified_date',
        type: 'date',
      },
      {
        label: 'Project Energy Usage Annual (kWh)',
        desc: '',
        field: 'project.usage_annual_or_guess',
        type: 'number',
      },
      {
        label: 'Project Utility Tariff Name',
        desc: '',
        field: 'project.utility_tariff_or_guess.name',
      },
      {
        label: 'Project Utility Tariff Description',
        desc: '',
        field: 'project.utility_tariff_or_guess.description',
      },
      {
        label: 'Project Proposed Utility Tariff Name',
        desc: '',
        field: 'project.utility_tariff_proposed_or_guess.name',
      },
      {
        label: 'Project Proposed Utility Tariff Description',
        desc: '',
        field: 'project.utility_tariff_proposed_or_guess.description',
      },
      { label: 'Project State', desc: '', field: 'project.state' },
      { label: 'Project Zip', desc: '', field: 'project.zip' },
      {
        label: 'Project Business Name',
        desc: '',
        field: 'project.business_name',
      },
      {
        label: 'Project Business Indentifier',
        desc: '',
        field: 'project.business_identifier',
      },
      {
        label: 'Project Wind Region',
        desc: '',
        field: 'project.wind_region',
      },
      {
        label: 'Site Notes',
        desc: '',
        field: 'project.site_notes',
      },
      {
        label: 'Site Number Of Phases',
        desc: '',
        field: 'project.number_of_phases',
      },
      {
        label: 'Site Number Of Stories',
        desc: '',
        field: 'project.number_of_storeys',
      },
      {
        label: 'Site Meter Identifier',
        desc: '',
        field: 'project.meter_identifier',
      },
      {
        label: 'Site Roof Type',
        desc: '',
        field: 'project.roof_type_name',
      },
      {
        label: 'Mandatory Compliance Message (If Applicable)',
        desc: '',
        field: 'snippet_mandatory_compliance',
      },
      {
        label: 'Embed All System Images',
        desc: '',
        field: 'embed_all_system_images',
        type: 'snippet',
      },
      {
        label: 'Project Custom Field 1',
        desc: '',
        field: 'project.custom_project_info_1',
      },
      {
        label: 'Project Custom Field 2',
        desc: '',
        field: 'project.custom_project_info_2',
      },

      {
        label: 'Project Custom Field 3',
        desc: '',
        field: 'project.custom_project_info_3',
      },
      {
        label: 'Project Custom Field 4',
        desc: '',
        field: 'project.custom_project_info_4',
      },
      {
        label: 'Project Custom Field 5',
        desc: '',
        field: 'project.custom_project_info_5',
      },
      {
        label: 'Project Share Link QR code',
        desc: '',
        field: 'share_link_qrcode',
        type: 'snippet',
      },
      {
        label: 'Project Share Link',
        desc: '',
        field: 'online_proposal_auto_login_url',
      },
      {
        label: 'Is PDF Proposal',
        desc: '',
        field: 'is_pdf_proposal',
      },
      {
        label: 'Roof Plan',
        desc: '',
        field: 'snippet_embed_images_for_roof_plan',
      },
      {
        label: 'Extra File',
        desc: '',
        field: 'snippet_embed_images_for_generic_extra_file',
      },
    ],
  },
  project_change_order: {
    category: 'Change Order',
    items: [
      {
        label: 'Total Transactions',
        desc: '',
        field: 'project.totalTransactions',
      },
      {
        label: 'Total Transactions %{inc_ex_tax}',
        desc: '',
        field: 'project.totalTransactionsExTax',
        transSubs: {
          inc_ex_tax: 'Ex Tax',
        },
      },
      {
        label: 'Total Transactions %{inc_ex_tax}',
        desc: '',
        field: 'project.totalTransactionsIncTax',
        transSubs: {
          inc_ex_tax: 'Inc Tax',
        },
      },
    ],
  },
  project_specific_terms: {
    category: 'Project Specific Terms',
    items: [
      {
        label: 'Project-Specific Contract Terms',
        desc: '',
        field: 'project.contract_terms',
        type: 'richtext',
      },
    ],
  },
  system: {
    category: 'System',
    items: [
      {
        label: 'Solar Cost Per Kwh',
        desc: '',
        field: 'solarCostPerKwh',
        type: 'number',
      },
      {
        label: 'Current Average Utility Rate',
        desc: '',
        field: 'currentAverageUtilityRate',
        type: 'number',
      },
      {
        label: 'Sunpath Diagrams',
        desc: '',
        field: 'snippet_embed_sunpath_diagrams',
        type: 'snippet',
      },
    ],
  },
  system_entities: {
    create_for_each: 'system',
    category: '%{label}',
    items: [
      { label: '%{label} Name', desc: '', field: '%{path}.title' },
      {
        label: '%{label} Price Adders Fixed ($)',
        desc: '',
        field: '%{path}.data.adders_per_system',
        type: 'number',
      },
      {
        label: '%{label} Price Adders Per Panel ($)',
        desc: '',
        field: '%{path}.data.adders_per_panel',
        type: 'number',
      },
      {
        label: '%{label} Price Adders Per Watt ($)',
        desc: '',
        field: '%{path}.data.adders_per_watt',
        type: 'number',
      },
      {
        label: '%{label} Adders Table (including tax)',
        desc: '',
        field: '%{path}.data.adders_table_inc_tax',
        type: 'snippet',
      },
      {
        label: '%{label} Adders Table (excluding tax)',
        desc: '',
        field: '%{path}.data.adders_table_ex_tax',
        type: 'snippet',
      },
      { label: '%{label} Battery Manufacturer', desc: '', field: '%{path}.batteryManufacturer' },
      { label: '%{label} Battery Codes', desc: '', field: '%{path}.batteryCodes' },
      { label: '%{label} Battery Quantity', desc: '', field: '%{path}.data.batteries|length' },
      { label: '%{label} Battery kWh (Total)', desc: '', field: '%{path}.data.battery_total_kwh' },
      { label: '%{label} Battery kWh (Usable)', desc: '', field: '%{path}.systemBatteryUsableKwh' },
      { label: '%{label} Inverter Quantity', desc: '', field: '%{path}.inverterQuantity', type: 'number' },
      { label: '%{label} Inverter Manufacturer', desc: '', field: '%{path}.inverterManufacturer' },
      { label: '%{label} Inverter Codes', desc: '', field: '%{path}.inverterCodes' },
      { label: '%{label} AC Capacity (kW)', desc: '', field: '%{path}.inverterTotalRatingKw', type: 'number' },
      { label: '%{label} Panel Orientations', desc: '', field: '%{path}.panelOrientations' },
      { label: '%{label} Total Losses', desc: '', field: '%{path}.systemTotalLosses', type: 'number' },
      { label: '%{label} Inverter Losses', desc: '', field: '%{path}.inverterLosses', type: 'number' },
      { label: '%{label} Optimizer Losses', desc: '', field: '%{path}.optimizerLosses', type: 'number' },
      { label: '%{label} Shading Losses', desc: '', field: '%{path}.shadingLossAnnual', type: 'number' },
      { label: '%{label} Output Calculator', desc: '', field: '%{path}.outputCalculator', type: 'number' },
      {
        label: '%{label} Performance Adjustment',
        desc: '',
        field: '%{path}.performanceAdjustment',
        type: 'number',
      },
      { label: '%{label} Output Calculation Notes', desc: '', field: '%{path}.outputCalculationNotes' },
      {
        label: '%{label} Capacity Factor',
        desc: '',
        field: '%{path}.systemCapacityFactor',
        type: 'number',
      },
      {
        label: '%{label} Performance Ratio',
        desc: '',
        field: '%{path}.systemPerformanceRatio',
        type: 'number',
      },
      {
        label: '%{label} Bill Frequency',
        desc: '',
        field: "%{path}.data.bills.proposed|first_item_value:'bill_frequency'",
      },
      {
        label: '%{label} Annual Feed In Tariff ($)',
        desc: '',
        field: "%{path}.data.bills.proposed|first_item_value:'feed_in_tariff_annual_proposed'",
        type: 'number',
      },
      {
        label: '%{label} Annual PBI ($)',
        desc: '',
        field: "%{path}.data.bills.proposed|first_item_value:'performance_based_incentive_annual_proposed'",
        type: 'number',
      },
      {
        label: '%{label} Years to Simulate Financials',
        desc: '',
        field: "%{path}.data.bills.proposed|first_item_value:'years_to_simulate'",
        type: 'number',
      },
      {
        label: '%{label} Annual Utility Inflation (%)',
        desc: '',
        field: '%{path}.data.bills.current.utility_inflation_annual',
        type: 'number',
      },
      {
        label: '%{label} Annual Feed-in-tariff Inflation (%)',
        desc: '',
        field: '%{path}.data.bills.current.feed_in_tariff_inflation_annual',
        type: 'number',
      },
      {
        label: '%{label} Consumption Offset Percentage (%)',
        desc: '',
        field: '%{path}.data.consumption.consumption_offset_percentage',
        type: 'number',
      },
      {
        label: '%{label} Self-Consumption Percentage (%)',
        desc: '',
        field: '%{path}.data.bills|get_self_consumption_fraction|multiply:100.0|floatformat:0',
        type: 'number',
      },
      {
        label: '%{label} Grid-Independence Percentage (%)',
        desc: '',
        field: '%{path}.data.bills|get_grid_independence_fraction|multiply:100.0|floatformat:0',
        type: 'number',
      },
      {
        label: '%{label} Consumption Annual (kWh)',
        desc: '',
        field: '%{path}.data.consumption.usage_annual|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} DC Optimiser Efficiency (%)',
        desc: '',
        field: '%{path}.data.dcOptimizerEfficiency',
        type: 'number',
      },
      {
        label: '%{label} Discount ($)',
        desc: '',
        field: '%{path}.data.discount',
        type: 'number',
      },
      {
        label: '%{label} Down Payment ($)',
        desc: '',

        field: '%{path}.downPayment',
      },
      {
        label: '%{label} Net Present Value ($)',
        desc: '',
        field: '%{path}.systemNetPresentValue',
        type: 'number',
      },
      {
        label: '%{label} Payback Year',
        desc: '',
        field: '%{path}.systemPaybackYear',
        type: 'number',
      },
      {
        label: '%{label} Discounted Payback Year',
        desc: '',
        field: '%{path}.systemDiscountedPaybackYear',
        type: 'number',
      },
      {
        label: '%{label} Total Return On Investment (%)',
        desc: '',
        field: '%{path}.systemReturnOnInvestment',
        type: 'number',
      },
      {
        label: '%{label} IRR (%)',
        desc: '',
        field: '%{path}.systemIrr',
        type: 'number',
      },
      {
        label: '%{label} Levelized Cost Of Energy ($)',
        desc: '',
        field: '%{path}.systemLcoe',
        type: 'number',
      },
      {
        label: '%{label} Financial Discount Rate (%)',
        desc: '',
        field: '%{path}.systemFinancialDiscountRate',
        type: 'number',
      },
      {
        label: '%{label} Environmentals CO2 Tons Avoided (Annual)',
        desc: '',
        field: '%{path}.data.environmentals.co2_tons',
        type: 'number',
      },
      {
        label: '%{label} Environmentals Car Km Avoided (Lifetime)',
        desc: '',
        field: '%{path}.data.environmentals.car_km',
        type: 'number',
      },
      {
        label: '%{label} Environmentals Car Miles Avoided (Lifetime)',
        desc: '',
        field: '%{path}.data.environmentals.car_km|multiply:0.621',
        type: 'number',
      },
      {
        label: '%{label} Environmentals Flights Avoided (Lifetime)',
        desc: '',
        field: '%{path}.data.environmentals.flights',
        type: 'number',
      },
      {
        label: '%{label} Environmentals Trees Planted (Lifetime)',
        desc: '',
        field: '%{path}.data.environmentals.trees_planted',
        type: 'number',
      },
      {
        label: '%{label} Data (Proposed or Existing)',
        desc: '',
        field: '{% if %{path}.data.is_current === True %}Existing{% else %}Proposed{% endif %}',
      },
      {
        label: '%{label} DC Capacity (kW)',
        desc: '',
        field: '%{path}.systemKwStc',
        type: 'number',
      },
      {
        label: '%{label} AC/DC Ratio',
        desc: '',
        field: '%{path}.system_dc_ac_ratio',
        type: 'number',
      },
      {
        label: '%{label} Inverter(s) Rated Output Voltage',
        desc: '',
        field: '%{path}.inverters_rated_output_voltage',
        type: 'number',
      },
      {
        label: '%{label} Module Code',
        desc: '',
        field: '%{path}.data.module.code',
      },
      {
        label: '%{label} Module Quantity',
        desc: '',
        field: '%{path}.data.moduleQuantity',
        type: 'number',
      },
      {
        label: '%{label} Array Count',
        desc: '',
        field: '%{path}.array_count',
        type: 'number',
      },
      {
        label: '%{label} Module Description',
        desc: '',
        field: '%{path}.data.module.component_content.description',
      },
      {
        label: '%{label} Module Size STC (kW)',
        desc: '',
        field: '%{path}.data.module.kw_stc',
        type: 'number',
      },
      { label: '%{label} Module Size STC (Watt)', desc: '', field: '%{path}.moduleWattStc', type: 'number' },
      {
        label: '%{label} Module Manufacturer',
        desc: '',
        field: '%{path}.data.module.manufacturer_name',
      },
      {
        label: '%{label} Module Width (mm)',
        desc: '',
        field: '%{path}.data.module.size.0|multiply:1000|floatformat:0',
        type: 'number',
      },
      {
        label: '%{label} Module Height (mm)',
        desc: '',
        field: '%{path}.data.module.size.1|multiply:1000|floatformat:0',
        type: 'number',
      },
      { label: '%{label} Module Efficiency (%)', desc: '', field: '%{path}.moduleEfficiency', type: 'number' },
      {
        label: '%{label} Annual Generation (kWh)',
        desc: '',
        field: '%{path}.data.output.annual|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} Monthly Generation (kWh)',
        desc: '',
        field: '%{path}.data.output.annual|divide:12|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} Pricing Adders',
        desc: '',
        field: '%{path}.data.pricing.adders|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} Battery Price',
        desc: '',
        field: '%{path}.data.pricing.battery_price|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} Incentive To Customer Total',
        desc: '',
        field: '%{path}.data.pricing.incentive_to_customer.total|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} Incentive To Installer STC',
        desc: '',
        field: '%{path}.data.pricing.incentive_to_installer.incentives.0.settings.stc_quantity',
        type: 'number',
      },
      {
        label: '%{label} Incentive To Installer Total',
        desc: '',
        field: '%{path}.data.pricing.incentive_to_installer.total|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} Net System Price After All Incentives',
        desc: '',
        field: '%{path}.data.payment_options.0.pricing.net_system_price_after_all_incentives|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} Price After Incentive To Installer',
        desc: '',
        field: '%{path}.data.payment_options.0.pricing.purchase_price_inc_tax|floatformat:2',
        type: 'number',
      },
      {
        label: '%{label} Price %{inc_ex_tax}',
        desc: '',
        field: '%{path}.data.payment_options.0.pricing.system_price_excluding_tax|floatformat:2',
        type: 'number',
        transSubs: {
          inc_ex_tax: 'Ex Tax',
        },
      },
      {
        label: '%{label} Price %{inc_ex_tax}',
        desc: '',
        field: '%{path}.data.payment_options.0.pricing.system_price_including_tax|floatformat:2',
        type: 'number',
        transSubs: {
          inc_ex_tax: 'Inc Tax',
        },
      },
      {
        label: '%{label} Price Per Watt (%{price})',
        desc: '',
        field: '%{path}.data|get_price_per_watt_total_price|divide:1000|floatformat:2',
        type: 'number',
        transSubs: {
          price: 'Total Price',
        },
      },
      {
        label: '%{label} Price Per Watt (%{price})',
        desc: '',
        field: '%{path}.data|get_price_per_watt_purchase_price|divide:1000|floatformat:2',
        type: 'number',
        transSubs: {
          price: 'Purchase Price',
        },
      },
      {
        label: '%{label} Price Tax',
        desc: '',
        field: '%{path}.data.payment_options.0.pricing.tax|floatformat:2',
        type: 'number',
      },
      {
        label: 'Solar Cost Per Kwh',
        desc: '',
        field: 'solarCostPerKwh',
        type: 'number',
      },
      {
        label: '%{label} Summary',
        desc: '',
        field: '%{path}.summary',
      },
      { label: '%{label} Other Component Codes', desc: '', field: '%{path}.otherComponentCodes' },
      { label: '%{label} Component Warranties', desc: '', field: '%{path}.componentWarranties' },
      {
        label: '%{label} Compliance System Performance Table (If Applicable)',
        desc: '',
        field: '%{path}.systemPerformanceTableCompliance',
        type: 'snippet',
      },
      {
        label: '%{label} Panel Orientation Table',
        desc: '',
        field: '%{path}.data.system_panel_orientation_table',
        type: 'snippet',
      },
      {
        label: '%{label} Cash Payment Milestone',
        desc: '',
        field: '%{path}.data.payment_options.0.payment_milestone_table',
        type: 'snippet',
      },
      {
        label: '%{label} Tariff Summary Table (Proposed)',
        desc: '',
        field: "%{path}.data.bills.proposed|first_item_value:'tariff_summary_table'",
        type: 'snippet',
      },
      {
        label: '%{label} Tariff Summary Table (Current)',
        desc: '',
        field: '%{path}.data.bills.current.tariff_summary_table',
        type: 'snippet',
      },
      {
        label: 'Loan Term',
        desc: '',
        field: "%{path}.data.payment_options.0.loan_term|stringformat:'d years'",
        type: 'number',
      },
      {
        label: 'Loan Interest Rate',
        desc: '',
        field: "%{path}.data.payment_options.0.loan_interest_rate|stringformat:'s%%'",
        type: 'number',
      },
      {
        label: 'Payment Option Name',
        desc: '',
        field: '%{path}.data.payment_options.0.title',
      },
      {
        label: 'Loan Lender Name',
        desc: '',
        field: '%{path}.data.payment_options.0.lender_name',
      },
      {
        label: '%{label} Panel Placement',
        desc: '',
        field: '%{path}.systemPanelPlacement',
      },
      {
        label: '%{label} PTC Rating (calculated from STC)',
        desc: '',
        field: '%{path}.PTCRatingCalculatedFromSTC',
        type: 'number',
      },
      {
        label: 'CEC-AC Rating (California)',
        desc: '',
        field: '%{path}.CECACRatingCalifornia',
        type: 'number',
      },
    ],
  },
  system_change_order: {
    create_for_each: 'system',
    category: '%{label}',
    items: [
      { label: '%{label} Module Performance Warranty', desc: '', field: '%{path}.modulePerformanceWarranty' },
      { label: '%{label} Module Product Warranty', desc: '', field: '%{path}.moduleProductWarranty' },
      { label: '%{label} Module Technology', desc: '', field: '%{path}.data.module.technology' },
      { label: '%{label} Inverter Product Warranty', desc: '', field: '%{path}.inverterProductWarranty' },
      {
        label: '%{label} Inverter Additional Parts Warranty',
        desc: '',
        field: '%{path}.inverterAdditionalPartsWarranty',
      },
      { label: '%{label} Battery Warranty', desc: '', field: '%{path}.batteryProductWarranty' },
      {
        label: '%{label} Net System Price After All Incentives',
        desc: '',
        field: '%{path}.netSystemPriceAfterAllIncentives',
      },
      {
        label: '%{label} Net System Price After All Incentives %{tax}',
        desc: '',
        field: '%{path}.data.payment_options.0.pricing.net_system_price_after_all_incentives|floatformat:2',
        transSubs: {
          tax: 'Ex Tax',
        },
      },
      {
        label: '%{label} Net System Price After All Incentives %{tax}',
        desc: '',
        field: '%{path}.netSystemPriceAfterAllIncentivesIncTax',
        transSubs: {
          tax: 'Inc Tax',
        },
      },
      {
        label: '%{label} Price After Incentive To Installer',
        desc: '',
        field: '%{path}.priceAfterIncentiveToInstaller',
      },
      {
        label: '%{label} Price After Incentive To Installer %{tax}',
        desc: '',
        field: '%{path}.data.payment_options.0.pricing.purchase_price_inc_tax|floatformat:2',
        transSubs: {
          tax: 'Inc Tax',
        },
      },
      {
        label: '%{label} Price After Incentive To Installer %{tax}',
        desc: '',
        field: '%{path}.data.payment_options.0.pricing.purchase_price_ex_tax|floatformat:2',
        transSubs: {
          tax: 'Ex Tax',
        },
      },
      {
        label: '%{label} Incentives to Installer Total %{tax}',
        desc: '',
        field: '%{path}.incentives_to_installer_ex_tax',
        transSubs: {
          tax: 'Ex Tax',
        },
      },
      {
        label: '%{label} Incentives to Installer Total %{tax}',
        desc: '',
        field: '%{path}.incentives_to_installer_inc_tax',
        transSubs: {
          tax: 'Inc Tax',
        },
      },
    ],
  },
  revised_system_change_order: {
    category: 'Revised System',
    items: [
      {
        label: 'Change in System Price',
        desc: '',
        field: 'system.change_in_system_price',
      },
      {
        label: 'Change in System Price (After Incentives to Installer)',
        desc: '',
        field: 'system.change_in_system_price_after_incentive_to_installer',
      },
    ],
  },
  owners_manual_snippets: {
    category: "Owner's Manual Snippets",
    items: [
      {
        label: 'Embed System Image',
        desc: '',
        field: 'snippet_system_image',
        type: 'snippet',
      },
      {
        label: 'Embed System Image with Annotations',
        desc: '',
        field: 'snippet_system_image_with_annotations',
        type: 'snippet',
      },
      {
        label: 'Embed System Image with Setbacks',
        desc: '',
        field: 'snippet_system_image_with_setbacks',
        type: 'snippet',
      },
      {
        label: 'Embed List of Equipment Table',
        desc: '',
        field: 'snippet_list_of_equipment_table',
        type: 'snippet',
      },
      {
        label: 'System Performance Chart',
        desc: '',
        field: 'snippet_system_performance_estimate',
        type: 'snippet',
      },
      {
        label: 'Embed Equipment Documentation',
        desc: '',
        field: 'snippet_embed_images_for_equipment_documentation',
        type: 'snippet',
      },
      {
        label: 'Embed Connection Diagram',
        desc: '',
        field: 'snippet_embed_images_for_connection_diagram',
        type: 'snippet',
      },
      {
        label: 'Embed Array Mounting Certificate',
        desc: '',
        field: 'snippet_embed_images_for_array_mounting_certificate',
        type: 'snippet',
      },
      {
        label: 'Embed Pre-Commissioning Test',
        desc: '',
        field: 'snippet_embed_images_for_pre_commissioning_test',
        type: 'snippet',
      },
      {
        label: 'Embed Test and Commission',
        desc: '',
        field: 'snippet_embed_images_for_test_and_commission',
        type: 'snippet',
      },
      {
        label: 'Embed Installation Photographs',
        desc: '',
        field: 'snippet_embed_images_for_installation_photograph',
        type: 'snippet',
      },
    ],
  },
  exhibit: {
    category: 'Exhibit Variables',
    items: [
      { label: 'System DC Capacity (kW)', desc: '', field: 'systemKwStc' },
      { label: 'Module Code', desc: '', field: 'moduleCode' },
      { label: 'Module Quantity', desc: '', field: 'moduleQuantity' },
      { label: 'Module Efficiency', desc: '', field: 'moduleEfficiency' },
      { label: 'Module Manufacturer', desc: '', field: 'moduleManufacturer' },
      { label: 'Module Watt STC', desc: '', field: 'moduleWattStc' },
      { label: 'Module Product Warranty', desc: '', field: 'moduleProductWarranty' },
      { label: 'Module Performance Warranty', desc: '', field: 'modulePerformanceWarranty' },
      { label: 'System Annual Generation (kWh)', desc: '', field: 'systemOutputAnnualkWh' },
      { label: 'System Lifetime (years)', desc: '', field: 'systemLifetime' },
      { label: 'System Total Generation (kWh)', desc: '', field: 'systemTotalGeneration' },
      { label: 'Inverter Total AC Capacity for Code', desc: '', field: 'inverterTotalRatingKw' },
      { label: 'Inverter Manufacturer', desc: '', field: 'inverterManufacturer' },
      { label: 'Inverter Quantity', desc: '', field: 'inverterQuantity' },
      { label: 'Inverter Codes', desc: '', field: 'inverterCodes' },
      { label: 'Inverter Product Warranty', desc: '', field: 'inverterProductWarranty' },
      { label: 'Inverter Additional Parts Warranty', desc: '', field: 'inverterAdditionalPartsWarranty' },
      { label: 'Inverter Continuous Output (kW)', desc: '', field: 'inverterNominalAcPower' },
      { label: 'Battery Manufacturer', desc: '', field: 'batteryManufacturer' },
      { label: 'Battery Codes', desc: '', field: 'batteryCodes' },
      { label: 'Battery Product Warranty', desc: '', field: 'batteryProductWarranty' },
      { label: 'Battery Throughput', desc: '', field: 'batteryThroughput' },
      { label: 'Battery Cycle Life', desc: '', field: 'batteryCycleLife' },
      { label: 'System Battery kWh Optimal', desc: '', field: 'systemBatteryKwhOptimal' },
      { label: 'System Battery kWh Usable', desc: '', field: 'systemBatteryUsableKwh' },
      { label: 'Other Quantity', desc: '', field: 'quantity' },
    ],
  },
  quotation_snippets: {
    category: 'Customise Label Variables',
    items: [
      { label: 'Currency Symbol', desc: '', field: 'currency_symbol' },
      { label: 'Discount', desc: '', field: 'discount' },
      { label: 'System Details', desc: '', field: 'system_details' },
      { label: 'System Summary', desc: '', field: 'system_summary|as_lines' },
      { label: 'Incentive Title', desc: '', field: 'incentive_title' },
      { label: 'Incentive Description', desc: '', field: 'incentive_description' },
      { label: 'Incentive Value', desc: '', field: 'incentive_value' },
      { label: 'Incentive Value Details', desc: '', field: 'incentive_value_details' },
      { label: 'System Price', desc: '', field: 'system_price' },
      { label: 'Price Per Watt', desc: '', field: 'price_per_watt' },
      { label: 'System Size', desc: '', field: 'system_size_kw' },
      { label: 'Down Payment Amount', desc: '', field: 'down_payment' },
      { label: 'Loan Amount', desc: '', field: 'loan_amount' },
      { label: 'Loan Interest Rate', desc: '', field: 'payment_option.loan_interest_rate' },
      { label: 'Loan Term', desc: '', field: 'loan_term' },
      { label: 'Prepayment Period', desc: '', field: 'prepayment_period' },
      { label: 'Prepayment Amount', desc: '', field: 'prepayment_amount' },

      { label: 'No Interest Period Start', desc: '', field: 'no_interest_period_start' },
      { label: 'No Interest Period End', desc: '', field: 'no_interest_period_end' },
      { label: 'No Payment Period Start', desc: '', field: 'no_payment_period_start' },
      { label: 'No Payment Period End', desc: '', field: 'no_payment_period_end' },
      { label: 'Interest Only Period Start', desc: '', field: 'interest_only_period_start' },
      { label: 'Interest Only Period End', desc: '', field: 'interest_only_period_end' },
      { label: 'Initial Payment Period Start', desc: '', field: 'initial_payment_period_start' },
      { label: 'Initial Payment Period End', desc: '', field: 'initial_payment_period_end' },
      { label: 'Final Payment Period Start', desc: '', field: 'final_payment_period_start' },
      { label: 'Final Payment Period End', desc: '', field: 'final_payment_period_end' },
      { label: 'No Payment Period Amount', desc: '', field: 'no_payment_period_amount' },
      { label: 'Interest Only Period Amount', desc: '', field: 'interest_only_period_amount' },
      { label: 'Payment Frequency Uppercase', desc: '', field: 'payment_frequency_uppercase' },
      { label: 'Regular Payment Amount', desc: '', field: 'regular_payment' },
      { label: 'Payment Frequency', desc: '', field: 'payment_frequency' },
      { label: 'Alternative Loan Repayments Final Amount', desc: '', field: 'payment_option.loan_payment_alternative' },
      { label: 'Payment Frequency', desc: '', field: 'payment_frequency' },
      { label: 'Loan Repayments Frequency', desc: '', field: 'payment_option_payment_frequency' },
      { label: 'Loan Repayments Term', desc: '', field: 'payment_option_loan_term' },
      { label: 'Standard Loan Repayments Final Amount', desc: '', field: 'payment_option.headline' },
      { label: 'Payments Years', desc: '', field: 'years' },
      { label: 'Total Payments Amount', desc: '', field: 'total_payments' },
      { label: 'Tax Explainer Text', desc: '', field: 'including_excluding_tax' },
      { label: 'Regular Payment Frequency Title', desc: '', field: 'payment_frequency_title' },
      { label: 'Regular Payment Amount', desc: '', field: 'headline' },
      { label: 'Cap Total kwh', desc: '', field: 'cap_total_kwh' },
      { label: 'PPA Dollars Per kwh Exports', desc: '', field: 'ppa_dollars_per_kwh_exports' },
      { label: 'PPA Dollars Per kwh', desc: '', field: 'ppa_dollars_per_kwh' },
      { label: 'PPA Upfront Payment', desc: '', field: 'ppa_upfront_payment' },
      { label: 'Financed Amount', desc: '', field: 'financed_amount' },
      { label: 'Unit Singular', desc: '', field: 'unit_singular' },
      { label: 'Unit Plural', desc: '', field: 'unit_plural' },
      { label: 'Additional Details', desc: '', field: 'additional_details' },
      { label: 'Usage Is TOU', desc: '', field: 'usage_is_tou' },
      { label: 'Other Component Codes', desc: '', field: 'otherComponentCodes' },
      { label: 'Inverter Codes', desc: '', field: 'inverterCodes' },
      { label: 'Battery Codes', desc: '', field: 'batteryCodes' },
      { label: 'Module Code', desc: '', field: 'moduleCode' },
    ],
  },
  document_template_snippets: {
    category: 'Document Template',
    items: [
      {
        label: 'Daily energy flows',
        desc: '',
        field: 'snippet_daily_energy_flows',
        type: 'snippet',
      },
    ],
  },
}

/*const duplicateSystemCategoryForChangeOrder = (systemCategory) => {
  let originalSystem = _.cloneDeep(systemCategory)
  let revisedSystem = _.cloneDeep(systemCategory)
  const changeOrderItems = [...systemCategory.items, ...systemCategory.filteredItems.change_order]
  originalSystem.category = 'Original System'
  originalSystem.items = [
    ...changeOrderItems
      ?.filter((x) => x.field.includes('system.'))
      ?.map((item) => {
        return {
          label: `${item.label} (Original)`,
          desc: item.desc,
          field: item.field.replace('system.', 'original_system.'),
          customTranslate: (translateFunc, value) =>
            `${
              item.customTranslate ? item.customTranslate(translateFunc, item.label) : translateFunc(item.label)
            } (${translateFunc('Original')})`,
        }
      }),
  ]
  revisedSystem.category = 'Revised System'
  revisedSystem.items = [
    ...changeOrderItems,
    {
      label: 'Change in System Price',
      desc: '',
      field: 'system.change_in_system_price',
    },
    {
      label: 'Change in System Price (After Incentives to Installer)',
      desc: '',
      field: 'system.change_in_system_price_after_incentive_to_installer',
    },
  ]
  return [originalSystem, revisedSystem]
}*/

const dataConfigs: Record<MentionContexts, EditorPlaceholderCategory[]> = {
  org: [dataConfigCategories.org],
  invitation: [
    dataConfigCategories.invitation,
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
    dataConfigCategories.project_specific_terms,
    dataConfigCategories.system,
  ],
  invitation_without_system: [
    dataConfigCategories.invitation,
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
    dataConfigCategories.project_specific_terms,
  ],
  org_project_and_acceptance_doc: [
    dataConfigCategories.acceptance_doc,
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
    dataConfigCategories.project_specific_terms,
  ],
  org_and_project: [
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
    dataConfigCategories.project_specific_terms,
  ],
  org_and_project_without_project_specific_terms: [
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
  ],
  org_and_project_and_system: [
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
    dataConfigCategories.project_specific_terms,
    dataConfigCategories.system,
    dataConfigCategories.system_entities,
  ],
  org_and_project_and_system_without_project_specific_terms: [
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
    dataConfigCategories.system,
    dataConfigCategories.system_entities,
  ],
  owners_manual: [
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
    dataConfigCategories.project_specific_terms,
    dataConfigCategories.system,
    dataConfigCategories.system_entities,
    dataConfigCategories.owners_manual_snippets,
    dataConfigCategories.document_template_snippets,
  ],
  change_order: [
    dataConfigCategories.project_role,
    dataConfigCategories.customer,
    dataConfigCategories.org,
    dataConfigCategories.project,
    dataConfigCategories.project_change_order,
    dataConfigCategories.project_specific_terms,
    dataConfigCategories.system,
    dataConfigCategories.system_entities,
    dataConfigCategories.system_change_order,
    dataConfigCategories.revised_system_change_order,
  ],
  cashflow_project: [dataConfigCategories.invoice],
  exhibit: [dataConfigCategories.exhibit],
  quotation_configuration: [dataConfigCategories.quotation_snippets],
  all: Object.values(dataConfigCategories),
  empty: [],
}

export default dataConfigs
