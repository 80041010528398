import { authSelectors } from 'ducks/auth'
import { myEnergySelectors, updateCheckoutElementsData } from 'ducks/myEnergy'
import { transactionSelectors } from 'ducks/transaction'
import usePhoenixStatus from 'myenergy/elements/phoenix/usePhoenixStatus'
import useGenericFinanceCheckoutElementOptions from 'myenergy/selectionComponent/loanApplicationButton/generic/useGenericFinanceCheckoutElementOptions'
import { CheckoutElementDataType, createActionElement, SUPPORTED_ELEMENT_TYPES } from 'opensolar-checkout'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProposalDataType } from 'types/proposals'
import { useFeatureFlag } from 'util/split'
import CheckoutActionsEffect from './CheckoutActionsEffect'
import useCheckoutActionCallback from './useCheckoutActionCallback'

const CheckActionsV2 = ({ proposalData }: { proposalData: ProposalDataType }) => {
  const translate = useTranslate()
  const checkoutElements = useSelector(myEnergySelectors.getCheckoutElements)
  const onAction = useCheckoutActionCallback()
  return (
    <>
      <CheckoutActionsEffect checkoutElementsData={checkoutElements.checkoutElementsData} proposalData={proposalData} />
      {checkoutElements.checkoutElementsData.map((elementData, index) => {
        return (
          <div key={elementData.type + index}>
            {createActionElement({
              elementData,
              config: { hostname_api: window.PUBLIC_URL },
              //@ts-ignore mismatched type across different package
              proposalData,
              onAction,
              translate,
              options: checkoutElements.options,
            })}
          </div>
        )
      })}
    </>
  )
}

const useAutoUpdateCheckoutElementsDataStore = ({
  elementsData,
  proposalData,
}: {
  elementsData: CheckoutElementDataType[]
  proposalData: ProposalDataType
}) => {
  const dispatch = useDispatch()
  const phoenixStatus = usePhoenixStatus({ elementsData, proposalData })
  const { ctaDialogConfigJson, transactionActionButtonDisabled } = useGenericFinanceCheckoutElementOptions({
    elementsData,
    proposalData,
  })
  const isPro = !!useSelector(authSelectors.getCurrentRole)
  const isLoading = useSelector(transactionSelectors.getIsLoading)
  const loanDecisionRenderedAt = useSelector(myEnergySelectors.getIsLoanDecisionRendered)
  const elementsDataSignature = JSON.stringify(elementsData)
  const elementsDataRef = useRef(elementsData)
  elementsDataRef.current = elementsData

  const options = useMemo(() => {
    return {
      isLoading,
      loanDecisionRenderedAt,
      phoenixStatus,
      isPro,
      ctaDialogConfigJson,
      transactionActionButtonDisabled,
    }
  }, [isLoading, loanDecisionRenderedAt, phoenixStatus, isPro, ctaDialogConfigJson, transactionActionButtonDisabled])

  useEffect(() => {
    dispatch(updateCheckoutElementsData(elementsDataRef.current, options))
  }, [options, elementsDataSignature])
}

const CheckoutActions = ({
  actionButtons,
  elementsData,
  proposalData,
}: {
  actionButtons: React.ReactNode[]
  elementsData: CheckoutElementDataType[]
  proposalData: ProposalDataType
}) => {
  useAutoUpdateCheckoutElementsDataStore({ elementsData, proposalData })
  const enableCheckoutActionV2 = useFeatureFlag('enable_checkout_action_v2', 'on')

  const showCheckoutActionsV2 =
    elementsData.every((elementData) =>
      SUPPORTED_ELEMENT_TYPES.includes(elementData.type as typeof SUPPORTED_ELEMENT_TYPES[number])
    ) && enableCheckoutActionV2

  if (showCheckoutActionsV2) {
    return <CheckActionsV2 proposalData={proposalData} />
  }
  return actionButtons
}

export default CheckoutActions
