export function zoomOut() {
  if (window.editor && window.editor.controllers.Camera && window.Designer.controlMode !== 'map') {
    var factor = 1.1
    var undoFactor = 1 / 1.1
    window.editor.execute(new window.ZoomCameraByFactorCommand(factor, undoFactor))
    window.editor.signals.sceneGraphChanged.dispatch()
  } else {
    if (window.MapHelper && window.MapHelper.activeMapInstance && window.MapHelper.activeMapInstance.dom) {
      // TODO: Remove these comments after PR gets approved
      // Leaving these here for discussion reference and to ease reverting to old approach
      // var value = window.MapHelper.activeMapInstance.dom.getView
      //   ? window.MapHelper.activeMapInstance.dom.getView().getZoom()
      //   : window.MapHelper.activeMapInstance.dom.getZoom()
      const value = window.MapHelper.activeMapInstance.dom.getZoom()
      window.editor.execute(new window.SetZoomCommand(value - 0.1, value, window.MapHelper))
      window.editor.signals.sceneGraphChanged.dispatch()
    }
  }
}
