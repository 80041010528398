import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { FormRouteInfoPropTypes } from '../types'

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: 10,
  marginBottom: 16,
})

const TermsTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()

  return (
    <Container>
      <Typography textVariant="h5SemiBold">{translate('Accept Terms & Conditions')}</Typography>
      <Typography textVariant="body1">
        {translate(
          'Please read and accept the Merchant Agreement and Pricing Agreement and OpenSolar Cashflow Supplemental Terms & Conditions.'
        )}
      </Typography>
    </Container>
  )
}
export default TermsTop
