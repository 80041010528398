import LaunchIcon from '@material-ui/icons/Launch'
import PowerIcon from '@material-ui/icons/Power'
import { BaseTheme, Button, styled } from 'opensolar-ui'
import { ExtendedHardwareSupplierConfig } from 'projectSections/sections/manage3/hardware/DistributorChip'
import { Confirm } from 'ra-ui-materialui'
import { useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useHistory } from 'react-router-dom'
import { setAuthRedirection, storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import useSegenService from 'services/segen/useSegenServiceV2'
import { useFeatureFlag } from 'util/split'

const ShopButton = styled(Button)(({ theme }) => ({
  background: `${theme.palette.blue[600]} !important`,
  color: `${theme.palette.common.white} !important`,
}))

const FlexWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  maxWidth: 120,
})

const StyledButton = styled(Button, { name: 'StyledButton' })({
  padding: '5px 12px',
})

const ButtonContent = styled('div', { name: 'ButtonContent' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  fontSize: 12,
  fontWeight: 600,
  lineHeight: '16px',
  color: theme.palette.grey[810],
  textWrap: 'nowrap',
  '& .MuiSvgIcon-root': {
    marginRight: 0,
  },
  '&:hover': {
    color: theme.palette.blue[600],
  },
}))

const ConnectButton = ({ config, fromDesign }: { config: ExtendedHardwareSupplierConfig; fromDesign?: boolean }) => {
  const history = useHistory()
  const { name, filterKey } = config
  const [confirmingConnect, setConfirmingConnect] = useState(false)
  const [toPage, setToPage] = useState<'connect' | 'register'>('connect')
  const segenService = useSegenService()
  const useSegenRedirect = !!useFeatureFlag('segen_auth_redirect', 'on')
  const auth = useAuth()

  const popUpRegisterContent = (page: 'connect' | 'register' | 'shop') => {
    const commonDescription = `Please confirm to proceed. Note that any unsaved changes will be lost.`

    if (page === 'shop') {
      return {
        title: `Redirect to ${name} Shop`,
        description: `You are about to be redirected to the ${name} Shop page. ${commonDescription}`,
      }
    }

    const isVtacPage = filterKey === 'vtac_uk' || filterKey === 'vtac_pl'
    const pageType = isVtacPage ? 'Page' : 'Form'

    if (page === 'register') {
      return {
        title: `Redirect to ${name} Register ${pageType}`,
        description: `You are about to be redirected to the ${name} registration ${pageType.toLowerCase()}. ${commonDescription}`,
      }
    }

    return {
      title: `Connect to ${name}`,
      description: `You are about to be redirected to the ${name} connection page to connect your accounts. ${commonDescription}`,
    }
  }

  const directToShop = () => {
    history.push(`/shop/distributor/${filterKey}`)
    window.scrollTo(0, 0)
  }

  const directToConnect = () => {
    if (filterKey === 'segen') {
      segenService.connect({ enableRedirect: useSegenRedirect, source: 'SegenConnectUserButton' })
    } else if (filterKey === 'city_plumbing') {
      setAuthRedirection({ type: 'AUTH_SUCCESS_REDIRECT', redirect: '/shop/after_connect/city_plumbing' })
      storeAuthRedirectionToLocalStorage()
      auth?.signinRedirect()
    } else if (filterKey === 'hdm') {
      history.push('/shop/connect_distributor/hdm/email')
    } else if (filterKey === 'vtac_uk' || filterKey === 'vtac_pl') {
      history.push('/shop/connect_distributor/vtac/email')
    } else if (filterKey === 'outlet') {
      history.push('/shop/connect_distributor/vtac/email')
    } else {
      return
    }
  }

  const directToRegister = () => {
    if (filterKey === 'vtac_uk' || filterKey === 'vtac_pl') {
      history.push(config.registrationUrl)
    } else {
      window.open(config.registrationUrl, '_blank')
    }
  }

  const handleConnect = () => {
    if (fromDesign) {
      setConfirmingConnect(true)
    } else {
      if (config.isConnected) {
        directToShop()
      } else {
        if (toPage === 'connect') {
          directToConnect()
        } else {
          directToRegister()
        }
      }
    }
  }

  if (!config.isVisible) {
    return <></>
  }

  if (config.isConnected) {
    const shopButton = (
      <FlexWrapper>
        <StyledButton variant={'outlined'} color={'default'} onClick={handleConnect}>
          <ButtonContent style={{ fontSize: 14, color: BaseTheme.palette.green[600] }}>
            <PowerIcon style={{ color: BaseTheme.palette.green[600] }} />
            {fromDesign ? 'Connected' : 'Shop now'}
          </ButtonContent>
        </StyledButton>
        <Confirm
          isOpen={confirmingConnect}
          title={popUpRegisterContent('shop').title}
          content={popUpRegisterContent('shop').description}
          confirmColor="warning"
          onConfirm={directToShop}
          onClose={() => setConfirmingConnect(false)}
        />
      </FlexWrapper>
    )

    return shopButton
  }

  if (!config.isConnected) {
    const connectUserButton = (
      <FlexWrapper>
        {filterKey !== 'outlet' && (
          <StyledButton
            variant={'outlined'}
            color={'default'}
            onClick={() => {
              setToPage('connect')
              handleConnect()
            }}
          >
            <ButtonContent>
              <PowerIcon />
              Connect
            </ButtonContent>
          </StyledButton>
        )}
        <StyledButton
          variant={'outlined'}
          color={'default'}
          onClick={() => {
            setToPage('register')
            handleConnect()
          }}
        >
          <ButtonContent>
            <LaunchIcon />
            Register
          </ButtonContent>
        </StyledButton>
        <Confirm
          isOpen={confirmingConnect}
          title={popUpRegisterContent(toPage).title}
          content={popUpRegisterContent(toPage).description}
          confirmColor="warning"
          onConfirm={() => {
            if (toPage === 'connect') {
              directToConnect()
            } else {
              directToRegister()
            }
            setConfirmingConnect(false)
          }}
          onClose={() => setConfirmingConnect(false)}
        />
      </FlexWrapper>
    )

    if (connectUserButton) return connectUserButton
  }

  return <></>
}

export default ConnectButton
