import { Grid } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { useSendPvfActivationEmail } from 'hooks/usePremiumProducts'
import { Button } from 'opensolar-ui'
import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import React, { useState } from 'react'
import { useLocale, useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import PremiumProductTermsDialog from 'resources/premiumProducts/PremiumProductTermsDialog'
import { ProjectType } from 'types/projects'
import { doNotTranslate, formatCurrencyWithSymbol } from 'util/misc'
import { colorMapping, colorMappingText, descriptions, Scenario, scenarioDetails } from './constants'
import CreateQuote from './CreateQuote'
import OrderScaffold from './OrderScaffold'
import useStyles from './scaffoldStyle'

interface ScaffoldProps {
  calculateCost: () => void
  createQuote: () => void
  cancelOrder: () => void
  confirmOrder: () => void
  initializeCustomData: () => void
  isSubmitting: boolean
}

const ScaffoldCard: React.FunctionComponent<ScaffoldProps> = ({
  calculateCost,
  createQuote,
  cancelOrder,
  confirmOrder,
  initializeCustomData,
  isSubmitting,
}) => {
  const classes = useStyles()
  const locale = useLocale()
  const project = useFormState().values as ProjectType
  const form = useForm()
  const orgId = useSelector(authSelectors.getOrgId)
  const projectId = useSelector((state: any) => state.projectId)
  const notify = useNotify()
  const customData = project?.custom_data?.scaffolding

  const [isOrderScaffoldOpen, setIsOrderScaffoldOpen] = useState<boolean>(false)
  const [isCreateQuoteOpen, setIsCreateQuoteOpen] = useState<boolean>(false)
  const [enableDialogOpen, setEnableDialogOpen] = useState<boolean>(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)
  const [orderConfirmDialogOpen, setOrderConfirmDialogOpen] = useState<boolean>(false)

  const openOrderScaffold = () => setIsOrderScaffoldOpen(true)
  const openCreateQuote = () => setIsCreateQuoteOpen(true)

  const closeOrderScaffold = () => setIsOrderScaffoldOpen(false)

  const sendActivationEmail = useSendPvfActivationEmail()

  const onPvfEnable = () => {
    sendActivationEmail()
    form.change(`custom_data.scaffolding.form_data.scaffold_properties.is_pvf_enabled`, true)
    setEnableDialogOpen(false)
    openCreateQuote()
  }

  const closeCreateQuote = () => setIsCreateQuoteOpen(false)
  const closeEnableDialog = () => setEnableDialogOpen(false)

  const handleRecalculateCost = () => {
    const updatedCustomData = { ...customData, scenario: Scenario.RECALCULATE_COST }
    form.mutators.updateField('custom_data.scaffolding', updatedCustomData)
    openOrderScaffold()
  }
  const handleQuoteCreation = async (e) => {
    if (!customData?.is_pvf_enabled) {
      setEnableDialogOpen(true)
      return
    }
    openCreateQuote()
  }
  const openCancelOrderDialog = () => {
    setCancelDialogOpen(true)
  }
  const openConfirmOrderDialog = () => {
    setOrderConfirmDialogOpen(true)
  }
  const costCalculationAfterCancel = () => {
    initializeCustomData()
    openOrderScaffold()
  }
  const handleCancelOrder = async () => {
    await cancelOrder()
    setCancelDialogOpen(false)
  }
  const handleConfirmOrder = async () => {
    await confirmOrder()
    setOrderConfirmDialogOpen(false)
  }

  const is48HoursPassed = (timestampString) => {
    const submissionTime = new Date(timestampString).getTime()
    const currentTime = new Date().getTime()
    const timeDifference = currentTime - submissionTime
    const hoursDifference = timeDifference / (1000 * 60 * 60) // Convert milliseconds to hours
    return hoursDifference >= 48
  }

  const renderDescription = (description: string) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ))
  }

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.wrapper}>
          <div className={classes.titleText}>{doNotTranslate('Order Scaffold')}</div>
          {scenarioDetails[customData?.scenario] && (
            <div>
              <div
                className={classes.tagClass}
                style={{
                  backgroundColor: scenarioDetails[customData?.scenario].backgroundColor,
                  color: scenarioDetails[customData?.scenario].color,
                }}
              >
                {customData?.scenario === Scenario.MAKE_PAYMENT
                  ? customData.estimated_cost > 0
                    ? customData.booked === 'order_confirmed'
                      ? scenarioDetails[customData.scenario].text
                      : scenarioDetails[Scenario.CONFIRM_ORDER].text
                    : scenarioDetails[Scenario.PRICE_NOT_AVAILABLE].text
                  : scenarioDetails[customData?.scenario].text}
              </div>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.descriptionWrapper}>
          {(customData?.scenario === Scenario.CREATE_QUOTE ||
            customData?.scenario === Scenario.PENDING_REVIEW ||
            customData?.scenario === Scenario.RECALCULATE_COST) && (
            <div className={classes.boldDescription}>
              {doNotTranslate('Cost of Scaffolding:')}{' '}
              {customData.estimated_cost > 0
                ? formatCurrencyWithSymbol(customData.estimated_cost, '£', locale, 2)
                : 'TBC'}
            </div>
          )}

          {(customData?.scenario === Scenario.MAKE_PAYMENT ||
            customData?.scenario === Scenario.CANCELLED ||
            customData?.scenario === Scenario.PAYMENT_SETTLED) && (
            <div className={classes.boldDescription}>
              {doNotTranslate('Cost of Scaffolding:')}{' '}
              {customData.estimated_cost > 0
                ? formatCurrencyWithSymbol(customData.estimated_cost, '£', locale, 2)
                : 'TBC'}
              <br />
              {doNotTranslate('Installation date:')}{' '}
              {new Date(customData?.form_data?.project_installation_date).toLocaleDateString('en-GB')}
              {customData.order_status_note && <div>Notes: {customData.order_status_note}</div>}
              {customData.erect_date && (
                <div>
                  {doNotTranslate('Erect Date:')} {new Date(customData.erect_date).toLocaleDateString('en-GB')}
                </div>
              )}
              {customData.dismantle_date && (
                <div>
                  {doNotTranslate('Dismantle Date:')} {new Date(customData.dismantle_date).toLocaleDateString('en-GB')}
                </div>
              )}
              {customData.rag_status && (
                <div>
                  {doNotTranslate('Status:')}{' '}
                  <span style={{ color: colorMapping[customData.rag_status.toLowerCase()] }}>
                    {colorMappingText[customData.rag_status.toLowerCase()]}
                  </span>
                </div>
              )}
              {customData.rag_notes && (
                <div>
                  {doNotTranslate('Order Notes:')} {customData.rag_notes}
                </div>
              )}
              {customData.scenario === Scenario.MAKE_PAYMENT &&
                customData.booked === 'order_confirmed' &&
                is48HoursPassed(customData.order_submission_timestamp) && (
                  <div>{descriptions['ORDER_SUBMITTED_TEXT_AFTER_48_HOURS']}</div>
                )}
            </div>
          )}
          {customData?.scenario === Scenario.CANCELLED && <div className={`${classes.divider} ${classes.fullWidth}`} />}
          <div className={classes.description}>
            {customData?.scenario &&
              descriptions[customData.scenario] &&
              renderDescription(descriptions[customData.scenario])}
          </div>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.buttonWrapper}>
          {(customData?.scenario === Scenario.ESTIMATE_COST || customData?.scenario === Scenario.CANCELLED) && (
            <Button
              onClick={customData.scenario === Scenario.CANCELLED ? costCalculationAfterCancel : openOrderScaffold}
              color={'primary'}
              fullWidth={true}
              className={classes.costCalculateBtnStyle}
            >
              {'Calculate Cost'}
            </Button>
          )}
          {(customData?.scenario === Scenario.CREATE_QUOTE || customData?.scenario === Scenario.RECALCULATE_COST) && (
            <div className={`${classes.wrapper} ${classes.gap16}`}>
              <Button
                onClick={handleRecalculateCost}
                color={'default'}
                fullWidth={true}
                className={classes.recalculateBtnStyle}
                disabled={isSubmitting}
              >
                {'Recalculate Cost'}
              </Button>
              <Button
                onClick={(e) => handleQuoteCreation(e)}
                color={'primary'}
                fullWidth={true}
                className={classes.costCalculateBtnStyle}
                disabled={isSubmitting}
              >
                {customData?.draft ? 'Continue Quoting' : customData?.estimated_cost ? 'Order Scaffold' : 'Get Quote'}
              </Button>
            </div>
          )}
          {customData?.scenario === Scenario.MAKE_PAYMENT &&
            customData.estimated_cost > 0 &&
            customData.booked === 'quoting' && (
              <Button
                onClick={openConfirmOrderDialog}
                color={'info'}
                fullWidth={true}
                className={classes.confirmBtnStyle}
              >
                {'Confirm Order'}
              </Button>
            )}
          {(customData?.scenario === Scenario.PENDING_REVIEW || customData?.scenario === Scenario.MAKE_PAYMENT) && (
            <div className={`${classes.wrapper} ${classes.gap16}`}>
              <Button
                onClick={openCancelOrderDialog}
                color={'default'}
                fullWidth={true}
                className={classes.cancelBtnStyle}
                disabled={
                  customData.booked === 'order_confirmed' && is48HoursPassed(customData.order_submission_timestamp)
                }
              >
                {customData.estimated_cost > 0 ? 'Cancel Order' : 'Cancel Quote'}
              </Button>
            </div>
          )}
        </div>
      </Grid>
      <PremiumProductTermsDialog
        open={enableDialogOpen}
        onClose={closeEnableDialog}
        productTitle={'PVF'}
        isEnableAndAccept
        onAccept={onPvfEnable}
      />
      <ConfirmModal
        open={cancelDialogOpen}
        setOpen={setCancelDialogOpen}
        handleSubmit={handleCancelOrder}
        title={'Are you sure you want to cancel your order?'}
        mainText={'Your order will no longer be processed if you proceed with the cancellation.'}
        submitBtnLabel={customData?.estimated_cost > 0 ? 'Yes, cancel order' : 'Yes, cancel quote'}
      />
      <ConfirmModal
        open={orderConfirmDialogOpen}
        setOpen={setOrderConfirmDialogOpen}
        handleSubmit={handleConfirmOrder}
        title={'Confirm Order?'}
        mainText={'Do you want to confirm your order?'}
        submitBtnLabel={'Yes, confirm order'}
      />
      <OrderScaffold
        isOpen={isOrderScaffoldOpen}
        onClose={closeOrderScaffold}
        calculateCost={calculateCost}
        openQuoteModal={openCreateQuote}
      />
      <CreateQuote isOpen={isCreateQuoteOpen} onClose={closeCreateQuote} createQuote={createQuote} />
    </>
  )
}

export default ScaffoldCard
