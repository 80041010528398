import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '../../styles/styled';
var MessageWrapper = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme, textColor = _a.textColor;
    return _b = {
            color: textColor,
            margin: 0,
            display: 'inline-block'
        },
        _b[theme.breakpoints.up('sm')] = {
            display: 'block',
            textAlign: 'center',
            margin: '15px 0px 15px 0px',
        },
        _b;
});
export var PreQualifiedMessage = function (_a) {
    var textColor = _a.textColor;
    return _jsx(MessageWrapper, { textColor: textColor, children: "\u2713 You're Pre-Qualified!" });
};
