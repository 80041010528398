import { CircularProgress } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    marginLeft: '15px',
  },
}))

const MoveOrgToSandboxModeButton: React.FC<PropTypes> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperUser = useSelector(authSelectors.getIsSuperUser)
  const classes = useStyles()
  const notify = useNotify()

  const moveOrgToSandboxMode = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/payments/move_org_to_sandbox_mode/`,
    })
      .then((res) => {
        console.log('res', res)
        notify('Org successfully moved to sandbox mode', 'success')
      })
      .catch((err) => {
        console.log('err')
        notify('Something went wrong', 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!isStaff && !isSuperUser) return null
  if ('staging4' !== window.ENV) return null

  return (
    <div className={classes.wrapper}>
      <Button onClick={moveOrgToSandboxMode} variant="contained" size="small" disabled={isLoading}>
        {isLoading ? <CircularProgress /> : <span>Move Org to Sandbox Mode</span>}
      </Button>
    </div>
  )
}

export default MoveOrgToSandboxModeButton
