var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var HelpCircleIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
    };
    return (_jsx("svg", __assign({ viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, rest, iconAttributes, { children: _jsx("g", { id: "help-circle-contained-filled", children: _jsx("path", { id: "Icon", fillRule: "evenodd", clipRule: "evenodd", d: "M7.00039 1.40015C3.9076 1.40015 1.40039 3.90735 1.40039 7.00015C1.40039 10.0929 3.90759 12.6001 7.00039 12.6001C10.0932 12.6001 12.6004 10.0929 12.6004 7.00015C12.6004 3.90735 10.0932 1.40015 7.00039 1.40015ZM6.40039 10.0335C6.40039 10.3649 6.66902 10.6335 7.00039 10.6335C7.33176 10.6335 7.60039 10.3649 7.60039 10.0335C7.60039 9.70216 7.33176 9.43353 7.00039 9.43353C6.66902 9.43353 6.40039 9.70216 6.40039 10.0335ZM6.10039 5.95015C6.10039 5.45309 6.50333 5.05015 7.00039 5.05015C7.49745 5.05015 7.90039 5.45309 7.90039 5.95015C7.90039 6.4472 7.49745 6.85015 7.00039 6.85015C6.66902 6.85015 6.40039 7.11878 6.40039 7.45015V8.23355C6.40039 8.56492 6.66902 8.83355 7.00039 8.83355C7.33176 8.83355 7.60039 8.56492 7.60039 8.23355V7.96319C8.46784 7.70502 9.10039 6.90146 9.10039 5.95015C9.10039 4.79035 8.16019 3.85015 7.00039 3.85015C5.84059 3.85015 4.90039 4.79035 4.90039 5.95015C4.90039 6.28152 5.16902 6.55015 5.50039 6.55015C5.83176 6.55015 6.10039 6.28152 6.10039 5.95015Z", fill: color }) }) })));
};
