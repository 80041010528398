import { doNotTranslate } from 'util/misc'
import { SolarAppPermitStates } from '../types'

export const SOLAR_APP_PERMIT_STEPS = [
  {
    step: 1,
    label: 'Initiated',
  },
  {
    step: 2,
    label: 'In Progress',
  },
  {
    step: 3,
    label: 'Outcome',
  },
]

export const PROJECT_STATUS_TO_STEPPER_STATES = {
  // Project has been created and can be updated.
  [SolarAppPermitStates.DRAFT]: 1, // 'Initiated',

  // Project has been validated and is awaiting payment.
  // Project can still be updated; however, it will return to draft state after edits are made.
  [SolarAppPermitStates.QUALIFIED]: 2, // 'In Progress'

  // Project has been paid for, external fees may still apply if permit's AHJ's mode is 'integrated'.
  [SolarAppPermitStates.APPROVED]: 3, // 'Outcome

  // Action was taken by a user to archive the project.
  [SolarAppPermitStates.ARCHIVED]: 3, // 'Outcome'
}

export const PROJECT_STATUS_TO_CTA_STATE = {
  [SolarAppPermitStates.DRAFT]: doNotTranslate('Continue Application'),
  [SolarAppPermitStates.QUALIFIED]: doNotTranslate('Make Payment'),
  [SolarAppPermitStates.APPROVED]: doNotTranslate('View Permit'),
  [SolarAppPermitStates.ARCHIVED]: doNotTranslate('Open SolarAPP+'),
}
