import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button, IconButton } from 'opensolar-ui'
import React from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { FinanceQRCodeWarning } from 'resources/projects/elements/FinanceQRCodeWarning'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { ActionType, EventType } from 'types/tracking'
import { getOrgFromState } from 'util/org'
import {
  selectPvsellProject as selectPvsellProjectAction,
  setPvsellDialogVisibility as setPvsellDialogVisibilityAction,
  setPvsellProposals as setPvsellProposalsAction,
} from '../ducks/pvsell.js'
import GenericButton from '../elements/button/GenericButton'
import SunlightTransferChoice from '../resources/integrations/sunlight/SunlightTransferChoice'
import { getIntegrationsStringFromActions, getRoleFromState, systemNameOrDefault } from '../util/misc'
import { getOrgCountryCodeFromState } from '../util/org'

const restClientInstance = restClient(window.API_ROOT + '/api')

class GenerateProposal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      download_url: props.hasOwnProperty('download_url') ? props.download_url : null,
      loading: props.hasOwnProperty('loading') ? props.loading : null,
      error: null,
      hasRequestedPdf: false,
      pdf_include: 'pc',
    }
  }

  triggerPdfAction(data, language) {
    this.setState({ loading: true, error: null })
    let documentType = 'proposal',
      extra_param = ''
    if (this.state.pdf_include === 'c') {
      documentType = 'contract'
      extra_param = '&action=save'
    }
    if (this.state.pdf_include === 'p') {
      extra_param = '&exclude_contract=1'
    }

    this.props.notify(
      this.props.translate('Document generation started. We will notify you when the document generation is complete.'),
      'info'
    )
    restClientInstance('CUSTOM_POST', 'custom', {
      url:
        'orgs/' +
        this.props.selectedOrg.id +
        '/projects/' +
        this.props.selectedProject.id +
        '/generate_document_pdf/' +
        documentType +
        '/?system_uuid=' +
        this.props.selectedSystem.uuid +
        '&payment_option_id=' +
        this.props.selectedPaymentOption.id +
        '&view_uuid=' +
        this.props.selectedViewUuid +
        extra_param,
      data: data,
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })
      .then((response) => {
        this.setState({ loading: false })

        if (response.data) {
          if (response.data.url) {
            // Cannot use window.open() because it will be ignored or blocked since not user-initiated
            this.setState({ download_url: response.data.url })
            this.props.notify(this.props.translate('Document generation successful'), 'success', {
              buttons: [
                {
                  label: this.props.translate('Download File'),
                  action: () => window.open(response.data.url, '_blank'),
                  icon: <CloudDownloadIcon />,
                },
              ],
            })
          } else {
            //@TODO: Placeholder for responses which do not return url, e.g. sending via email
            console.warn('Unhandled response', response.data)
            this.setState({ error: 'Error', loading: false })
          }
        } else {
          console.warn('Unknown error')
          this.setState({ error: 'Error', loading: false })
        }
      })
      .catch((error) => {
        this.props.notify(this.props.translate('Document Generation Unsuccessful.'), 'warning')
        this.setState({
          error:
            'Document Generation Unsuccessful' + (error.body && error.body.detail ? ': ' + error.body.detail : '.'),
          loading: false,
        })
      })
  }

  handlePdfActionDownload() {
    this.triggerPdfAction(
      {
        return_download_url: true,
      },
      appStorage.getLocale()
    )
    this.setState({ hasRequestedPdf: true })
    logAmplitudeEvent('proposal_pdf_generated', {
      project_id: this.props.selectedProject.id,
      integrated_payment_options: getIntegrationsStringFromActions(
        this.props.selectedProject.available_customer_actions,
        this.props.selectedSystem.uuid
      ),
      page: 'MyEnergy',
      has_cashflow: this.props.hasCashFlow,
    })
  }

  handlePdfActionEmail(email_recipient) {
    this.triggerPdfAction(
      {
        email_recipient: email_recipient,
      },
      appStorage.getLocale()
    )
  }

  renderProposalPDFInfo() {
    const { proposalTemplateSettings, translate, systems, selectedSystem, selectedPaymentOption } = this.props
    const isAllSystemsInclude = proposalTemplateSettings.pdf_systems_to_include === 'all'
    const isAllPaymentOptionsInclude = proposalTemplateSettings.pdf_payment_options_to_include === 'all'
    if (isAllSystemsInclude) {
      return (
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead displaySelectAll={false} enableSelectAll={false} adjustForCheckbox={false}>
            <TableRow selectable={false}>
              <TableCell>{translate('System')}</TableCell>
              <TableCell>
                {isAllPaymentOptionsInclude ? translate('Payment Options') : translate('Selected Payment Option')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            {systems.map((system) => {
              if (isAllPaymentOptionsInclude === false) {
                const selectedPaymentOptionId = selectedPaymentOption.id
                const systemHasSelectedPaymentOption = system.payment_options.some(
                  (payment) => payment.id === selectedPaymentOptionId
                )
                if (systemHasSelectedPaymentOption === false) return null
              }
              return (
                <TableRow key={system.uuid} selectable={false}>
                  <TableCell>
                    {/* {system.name || `${window.roundToDecimalPlaces(system.kwStc, 3)} kW System`} */}
                    {systemNameOrDefault(system)}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'normal' }}>
                    {isAllPaymentOptionsInclude
                      ? system.payment_options.map((payment) => payment.title).join(', ')
                      : selectedPaymentOption.title}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )
    } else {
      return (
        <div>
          <p>
            {translate('Selected System:')} {systemNameOrDefault(this.props.selectedSystem)}
          </p>
          <p>
            {isAllPaymentOptionsInclude ? translate('Payment Options:') : translate('Selected Payment Option:')}{' '}
            {isAllPaymentOptionsInclude
              ? selectedSystem.payment_options &&
                selectedSystem.payment_options.map((payment) => payment.title).join(', ')
              : selectedPaymentOption.title}
          </p>
        </div>
      )
    }
  }

  render() {
    const { translate, trackEvent } = this.props
    const { loading, download_url, error } = this.state
    const { selectedProject, isPro, selectedPaymentOption } = this.props
    const isSunlight = selectedPaymentOption?.integration === 'sunlight'

    const handleChange = (event) => {
      const name = event.target.name
      this.setState({ ...this.state, [name]: event.target.value })
    }

    const isCustomerView = !isPro || window.location.href.includes('mode=customerView')

    return (
      <Dialog style={{ maxHeight: 'calc(100vh - 56px)' }} open={this.props.isOpen} fullWidth={true} maxWidth={'sm'}>
        <IconButton
          id="SiteDetailCloseIcon"
          style={{
            top: 10,
            right: 10,
            position: 'absolute',
            minWidth: 40,
            backgroundColor: 'rgba(255, 255, 255, 0)',
          }}
          onClick={() => this.props.closeDialog()}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{translate('Download Proposal')}</DialogTitle>
        <DialogContent>
          {this.renderProposalPDFInfo()}
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {loading && (
              <CircularProgress
                size={30}
                left={0}
                top={5}
                loadingColor="#fdd400"
                status="loading"
                style={{
                  opacity: 0.5,
                  display: 'inline-block',
                  float: 'none',
                  marginLeft: 5,
                  marginTop: 10,
                  position: 'relative',
                }}
              />
            )}
            {!download_url && !loading && selectedProject && this.props.selectedSystem && (
              <>
                <FormControl variant="outlined" style={{ marginRight: 25, marginTop: 10 }}>
                  <Select
                    native
                    value={this.state.pdf_include}
                    onChange={handleChange}
                    inputProps={{
                      name: 'pdf_include',
                      style: { paddingTop: 10.5, paddingBottom: 10.5 },
                    }}
                  >
                    <option value="pc">{translate('Combined Proposal and Contract PDF')}</option>
                    <option value="p">{translate('Proposal PDF')}</option>
                    <option value="c">{translate('Contract PDF')}</option>
                  </Select>
                </FormControl>
                <Button
                  // label={loading ? translate('Preparing Proposal PDF...') : translate('Generate Proposal PDF')}
                  onClick={() => this.handlePdfActionDownload()}
                  style={{ marginRight: 10, marginTop: 10 }}
                  // labelWrapperStyle={{
                  //   textTransform: 'none',
                  //   fontWeight: 'bold',
                  // }}
                  variant="contained"
                  color="primary"
                  id={this.props.fullCalcReady && 'generateProposalButton'}
                  // labelColor={this.props.myeStyles.textColorOnHighlight}
                  // backgroundColor={this.props.myeStyles.highlightColor}
                  // backgroundColorOnHover={this.props.myeStyles.highlightColorInteraction}
                  disabled={loading || !this.props.fullCalcReady ? true : false}
                  // labelPosition="before"
                >
                  <span>{loading ? translate('Preparing PDF...') : translate('Generate PDF')}</span>
                </Button>
              </>
            )}
            {!isCustomerView && (
              <FinanceQRCodeWarning
                show={!isCustomerView && (this.state.pdf_include === 'p' || this.state.pdf_include === 'pc')}
                location="proposal_download_dialog"
                styles={{ marginTop: 36 }}
              />
            )}
            {download_url && download_url.length > 0 && (
              <GenericButton
                label={translate('PDF Ready. Click to download.')}
                labelWrapperStyle={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
                labelColor="#FFFFFF"
                variant="contained"
                backgroundColor="#46bc41"
                backgroundColorOnHover="rgb(52, 160, 47)"
                onClick={() => {
                  trackEvent(
                    EventType.USER_INTERACTION,
                    { type: ActionType.CLICK },
                    { eventName: 'User Downloaded PDF Proposal', download_url }
                  )
                  window.open(download_url)
                  this.setState({ download_url: null })
                }}
                wrapperStyle={{ marginRight: 10, marginTop: 10 }}
              />
            )}
            {/* {isPro && api_key_pvsell && SUNWIZ_SUPPORT_COUNTRIES.includes(country_iso2) && selectedProject && (
              <GenericButton
                label={translate('Generate SunWiz Proposal')}
                disabled={loading || !this.props.fullCalcReady ? true : false}
                variant="contained"
                onClick={(e) => {
                  listProposals(selectedProject.id, api_key_pvsell, {
                    success: (data) => {
                      // console.log(data)
                      this.props.closeDialog()
                      setPvsellDialogVisibility(true)
                      setPvsellProposals(data.data)
                      selectPvsellProject(selectedProject.id)
                    },
                    error: (err) => {
                      console.log(err)
                      this.setState({
                        error: err.status + err.statusText,
                        loading: false,
                      })
                    },
                    complete: () => {},
                  })
                }}
                style={{ marginRight: 10, marginTop: 10 }}
                labelWrapperStyle={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
                backgroundColorOnHover="#d8b605"
                backgroundColor={'#fdd502'}
                labelPosition="before"
              />
            )} */}
          </div>
          {error && <div style={{ color: 'red', marginTop: 10, fontSize: 14 }}>{error}</div>}
          {isSunlight && isPro && this.state.hasRequestedPdf && (
            <SunlightTransferChoice
              project={selectedProject}
              orgId={this.props.selectedOrg.id}
              systemUuid={this.props.selectedSystem.uuid}
              paymentOptionId={this.props.selectedPaymentOption.id}
              labelColor={this.props.myeStyles.textColorOnHighlight}
              backgroundColor={this.props.myeStyles.highlightColor}
              backgroundColorOnHover={this.props.myeStyles.highlightColorInteraction}
              allowPrequal
              allowCreditApp
            />
          )}
        </DialogContent>
        <Divider style={{ background: 'rgb(136, 133, 133)', marginTop: 20 }} />
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => this.props.closeDialog()}
            style={{
              backgroundColor: '#f0f0f0',
              textTransform: 'none',
            }}
          >
            <span>{translate('Return to My Energy')}</span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const GenerateProposalWrapper = (props) => {
  const { trackEvent } = useTrackComponent({
    componentKey: 'generate_proposal',
    eventName: 'User Generatee Proposal',
  })

  return <GenerateProposal {...props} trackEvent={trackEvent} />
}

const mapStateToProps = (state) => {
  return {
    isPro: Boolean(getRoleFromState(state)),
    api_key_pvsell: getOrgFromState(state) && getOrgFromState(state).api_key_pvsell,
    country_iso2: getOrgCountryCodeFromState(state),
  }
}

const enhance = compose(
  withTranslate,
  connect(mapStateToProps, {
    setPvsellDialogVisibility: setPvsellDialogVisibilityAction,
    setPvsellProposals: setPvsellProposalsAction,
    selectPvsellProject: selectPvsellProjectAction,
  })
)

export default enhance(GenerateProposalWrapper)
