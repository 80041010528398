import { Divider, Paper } from '@material-ui/core'
import { UiSwitch } from 'elements/UiSwitch'
import { styled, ToggleButton, ToggleButtonGroup } from 'opensolar-ui'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import AdvancedSettingsV2 from './AdvancedSettingsV2'
import BasicSettingsV2 from './BasicSettingsV2'

enum TabKeys {
  Basic = 'basic',
  Advanced = 'advanced',
}

const SectionContainer = styled('div')({
  padding: '10px 10px 0px 10px',
})

const ModuleGridSettings = ({ allowEdit, moduleGrids }) => {
  const [activeTab, setActiveTab] = useState<TabKeys>(TabKeys.Basic)
  const translate = useTranslate()

  return (
    <Paper>
      <UiSwitch uiKey="studio.tabs.selected_panel_group.settings_mode_selector">
        <SectionContainer>
          <ToggleButtonGroup
            fullWidth={true}
            exclusive
            value={activeTab}
            onChange={(e, value) => {
              if (!value) return
              setActiveTab(value)
            }}
          >
            <UiSwitch uiKey="studio.tabs.selected_panel_group.basic_settings">
              <ToggleButton value="basic">{translate('Basic Settings')}</ToggleButton>
            </UiSwitch>
            <UiSwitch uiKey="studio.tabs.selected_panel_group.advanced_settings">
              <ToggleButton value="advanced">{translate('Advanced')}</ToggleButton>
            </UiSwitch>
          </ToggleButtonGroup>
        </SectionContainer>
      </UiSwitch>
      <Divider />
      <SectionContainer>
        {activeTab === TabKeys.Basic && (
          <UiSwitch uiKey="studio.tabs.selected_panel_group.basic_settings">
            <BasicSettingsV2 moduleGrids={moduleGrids} allowEdit={allowEdit} />
          </UiSwitch>
        )}
        {activeTab === TabKeys.Advanced && (
          <UiSwitch uiKey="studio.tabs.selected_panel_group.advanced_settings">
            <AdvancedSettingsV2 moduleGrids={moduleGrids} allowEdit={allowEdit} />
          </UiSwitch>
        )}
      </SectionContainer>
    </Paper>
  )
}

export default ModuleGridSettings
