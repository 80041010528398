import { Grid, styled } from 'opensolar-ui'
import React, { useMemo } from 'react'
import {
  FieldPrefixFilterType,
  FormRouteInfoPropTypes,
  OnboardingFormPageInfoType,
  PSPOnboardingFieldsMapType,
} from './types'

type PropTypes = {
  initialPageNumber: number
  fieldMap: PSPOnboardingFieldsMapType
  pageInfo: OnboardingFormPageInfoType
  onPageSubmit: (saveAndContinue?: boolean) => void
  goBack: () => void
  navigateToPage: (routeKey: string) => void
  setFieldPrefixFilter: (filter: FieldPrefixFilterType) => void
  fieldPrefixFilter: FieldPrefixFilterType
  closeDialog: () => void
}

const StyledGrid = styled(Grid)({
  padding: '0 10px',
})

const BlueSnapOnboardingFormContents: React.FC<PropTypes> = (props) => {
  const childProps = useMemo(() => {
    return {
      pageNumber: props.pageInfo?.fieldPageNumber,
      fieldMap: props.fieldMap,
      sectionName: props.pageInfo?.sectionName,
      fieldPrefixFilter: props.fieldPrefixFilter,
      setFieldPrefixFilter: props.setFieldPrefixFilter,
      navigateToPage: props.navigateToPage,
      onPageSubmit: props.onPageSubmit,
      closeDialog: props.closeDialog,
    } as FormRouteInfoPropTypes
  }, [props.pageInfo, props.fieldMap, props.fieldPrefixFilter, props.fieldPrefixFilter])

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12} md={props.pageInfo.right?.component === null ? 12 : 6}>
        {props.pageInfo?.topLeft?.component ? <props.pageInfo.topLeft.component {...childProps} /> : null}
        {props.pageInfo?.bottomLeft?.component ? <props.pageInfo.bottomLeft.component {...childProps} /> : null}
      </Grid>
      <Grid item xs={12} md={6}>
        {props.pageInfo?.right?.component ? <props.pageInfo.right.component {...childProps} /> : null}
      </Grid>
    </StyledGrid>
  )
}
export default BlueSnapOnboardingFormContents
