import { Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button, styled } from 'opensolar-ui'
import { OpenSolarTheme, OpenSolarThemeType } from 'Themes'

interface TagWrapperProps {
  backgroundColor: string
  color: string
}

const spaceToMargin = {
  small: 16,
  medium: 40,
  large: 68,
}

export const Description = styled('div')({
  fontSize: '12px',
  fontWeight: 400,
  color: '#4F4D55',
})

export const BoldDescription = styled('div')({
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '8px',
  color: 'rgba(79, 77, 85, 1)',
  position: 'absolute',
})

export const TitleText = styled('div')({
  fontWeight: 700,
  fontSize: '14px',
})

export const Wrapper = styled('div')({
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const CenterAlign = styled('div')({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  alignItems: 'center',
  whiteSpace: 'nowrap',
})

export const ButtonWrapper = styled(Button)({
  whiteSpace: 'nowrap',
  marginTop: 68,
})

export const LoadingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '32px',
  color: '#4272DD',
})

export const CalculateCostButton = styled(Button, { name: 'CalculateCostButton' })<{
  space?: 'small' | 'medium' | 'large'
}>(({ space, theme }) => ({
  background: `${theme.palette.blue[600]} !important`,
  color: `#FFF !important`,
  whiteSpace: 'nowrap',
  marginTop: spaceToMargin[space || 'small'],
}))

export const RecalculateCostButton = styled(Button)({
  border: `1px solid ${OpenSolarTheme.greyMid2}`,
  backgroundColor: OpenSolarTheme.white,
  borderRadius: 8,
  fontSize: 14,
  whiteSpace: 'nowrap',
  marginTop: 68,
})

export const TagWrapper = styled(CenterAlign)<TagWrapperProps>(({ backgroundColor, color }) => ({
  backgroundColor,
  color,
  marginTop: '8px',
  padding: '4px 12px',
  borderRadius: '5px',
  height: '20px',
  paddingLeft: '8px',
  paddingRight: '8px',
  fontSize: '12px',
  fontWeight: 600,
  display: 'flex',
}))

export const OrderInfoWrapper = styled(CenterAlign)({
  marginTop: 40,
  fontSize: '10px',
  fontWeight: 550,
})

export const CostDetailsWrapper = styled(CenterAlign)({
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  color: 'rgba(79, 77, 85, 1)',
  position: 'absolute',
  marginTop: 40,
})

export const StepperWrapper = styled(Stepper, { name: 'StepperWrapper' })(({ theme }) => ({
  '& .MuiStepIcon-active': {
    color: theme.palette.blue[700],
  },
}))

export const CustomWrapper = styled('div', { name: 'Wrapper' })(({ theme }) => ({
  padding: '20px 0',
  borderBottom: theme.mixins.borderStandard.border,
  '&:first-child': {
    paddingTop: 0,
  },
  '&:last-child': {
    borderBottom: 'none',
  },
}))
export const BlackHeader = styled('h3', { name: 'BlackHeader' })(({ theme }) => ({
  fontSize: 16,
  margin: 0,
  fontWeight: 600,
  color: theme.palette.primary.contrastText,
}))

export const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  description: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#4F4D55',
  },
  buttonWrapper: { display: 'flex', justifyContent: 'center' },
  marginTop40: {
    marginTop: 40,
  },
  marginTop55: {
    marginTop: 55,
  },
  marginTop16: {
    marginTop: 16,
  },
  tagClass: {
    marginTop: '8px',
    padding: '4px 12px',
    borderRadius: '5px',
    height: '20px',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '12px',
    fontWeight: 600,
    display: 'flex',
  },
  centerAlign: {
    position: 'absolute',
    textAlign: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  innerWrapper: {
    display: 'flex',
  },
  detailWrapper: {
    maxWidth: '75%',
  },
}))
