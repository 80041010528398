import { ContiguousPanelColumn, MountingCalcResult, PanelColumn, PanelRow } from '../types'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'

export abstract class PerColumnBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract generateBomForColumn(resultFromLastColumn: MountingCalcResult, column: PanelColumn): MountingCalcResult

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    return getContiguousColumns(this.block.rows).reduce(
      (resultFromLastRow, row) => this.generateBomForColumn(resultFromLastRow, row),
      result
    )
  }
}

// A PanelColumn may have gaps. Much easier to work with columns without gaps
// An alternative would be to check each y-coord for a panel, and neighbours, and add to the bom accordingly
export function getContiguousColumns(rows: PanelRow[]): ContiguousPanelColumn[] {
  const numColumns = rows[0].length

  const contiguousColumns: ContiguousPanelColumn[] = []
  let currentContiguousColumn: ContiguousPanelColumn = []

  for (let columnIndex = 0; columnIndex < numColumns; columnIndex++) {
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const row = rows[rowIndex]
      const panel = row[columnIndex]
      if (panel.isActive) {
        currentContiguousColumn.push(panel)

        if (!rows[rowIndex + 1] || !rows[rowIndex + 1][columnIndex] || !rows[rowIndex + 1][columnIndex].isActive) {
          // We've found the end of the contiguous column...
          // ...so we push it and start over
          contiguousColumns.push(currentContiguousColumn)
          currentContiguousColumn = []
        }
      }
    }
  }

  return contiguousColumns
}
