var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import React from 'react';
var style = {
    fontSize: 20,
    display: 'inline-block',
    fontWeight: 500,
    margin: '10px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};
var Title = function (_a) {
    var defaultTitle = _a.defaultTitle, record = _a.record, title = _a.title, rest = __rest(_a, ["defaultTitle", "record", "title"]);
    var translate = useTranslate();
    if (!title) {
        return _jsx("span", { style: style, children: defaultTitle });
    }
    if (typeof title === 'string') {
        return _jsx("span", { style: style, children: translate(title, { _: title }) });
    }
    return React.cloneElement(title, __assign({ record: record, translate: translate, style: style }, rest));
};
export var TitlePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.element]);
Title.propTypes = {
    defaultTitle: PropTypes.string,
    record: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
export default Title;
