import { orgSelectors } from 'ducks/orgs'
import React, { useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { NEW_MILESTONE_PLACEHOLDER } from '../../constants'

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { CashFlowConfiguration } from 'pages/cashFlow/types'
import { sanitizeCashFlowConfigForm } from '../utils'
import ConfigurationForm from './ConfigurationForm'
import { validateMilestones } from './utils'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  setIsUpdated?: (val: boolean) => void
  setNewConfig?: (val: CashFlowConfiguration | undefined) => void
}

const CreateConfiguration: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [newConfigVals, setNewConfigVals] = useState<CashFlowConfiguration>()

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const notify = useNotify()
  const translate = useTranslate()

  const onSubmit = (vals: any) => {
    const { isValid, milestoneNum } = validateMilestones(vals)

    if (!isValid) {
      notify(translate(`Title for Milestone %{milestoneNum} is required`, { milestoneNum }), 'warning')
    } else if (isValid && orgId) {
      setIsLoading(true)
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/payments/configuration/`,
        data: sanitizeCashFlowConfigForm(vals),
      })
        .then((res) => {
          props.setIsUpdated?.(true)
          props.setNewConfig?.(res.data)
          notify(translate('Your updates have been saved'), 'success')
          {
            let amplitudeFriendlyVals = { ...vals }
            amplitudeFriendlyVals.milestone_count = vals.payment_milestone_configurations.length
            vals?.payment_milestone_configurations?.forEach((milestone) => {
              amplitudeFriendlyVals[`milestone.${milestone.payment_number}.percentage`] = milestone.percentage
              amplitudeFriendlyVals[`milestone.${milestone.payment_number}.min_fixed`] = milestone.min_fixed
              amplitudeFriendlyVals[`milestone.${milestone.payment_number}.max_fixed`] = milestone.max_fixed
              amplitudeFriendlyVals[`milestone.${milestone.payment_number}.fixed_amount`] = milestone.fixed_amount
              amplitudeFriendlyVals[`milestone.${milestone.payment_number}.surcharging_enabled`] =
                milestone.surcharging_enabled
              amplitudeFriendlyVals[`milestone.${milestone.payment_number}.remove_from_percentage_calcs`] =
                milestone.remove_from_percentage_calcs
            })
            logAmplitudeEvent('cashflow_milestone_configuration_saved', amplitudeFriendlyVals)
          }
          setNewConfigVals(undefined)
        })
        .catch((err) => {
          if (err?.body?.message) notify(err.body.message, 'warning')
          else if (err?.body?.non_field_errors?.length) notify(err.body.non_field_errors[0], 'warning')
          else notify(translate('Something went wrong'), 'error')
        })
        .finally(() => {
          props.setIsUpdated?.(false)
          setIsLoading(false)
        })
    }
  }

  const initValues = useMemo(() => {
    if (newConfigVals) {
      return newConfigVals
    } else {
      return { payment_milestone_configurations: [{ ...NEW_MILESTONE_PLACEHOLDER, payment_number: 1 }] }
    }
  }, [newConfigVals])

  return (
    <div>
      <Form
        initialValues={initValues}
        onSubmit={onSubmit}
        render={(formProps) => (
          <ConfigurationForm
            {...formProps}
            onSave={onSubmit}
            isLoading={isLoading}
            setNewConfigVals={setNewConfigVals}
          />
        )}
      />
    </div>
  )
}
export default CreateConfiguration
