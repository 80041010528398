import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import get from 'lodash/get'
import moment from 'moment'
import React, { useMemo } from 'react'
import type { ProposalDataType } from 'types/proposals'
import { detectProposalV2ProposalTemplate } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import type { PropTypes as ModalPropTypes } from './TemplateLibraryModal'
import TemplateLibraryModal from './TemplateLibraryModal'

type PropTypes = Omit<ModalPropTypes, 'openDefault' | 'disableClose' | 'isSystemLimitExceeded'> & {
  proposalData: ProposalDataType
}

const ControlledTemplateLibraryModal: React.FC<PropTypes> = ({ proposalData, ...props }) => {
  const proposalFeatureConfig = usePublicFeatureConfig('proposal_v2')

  const hasSelectedTemplate = useMemo(() => {
    const proposalConfig = proposalData?.selectedProject?.custom_data?.proposal_config_v2
    return get(proposalConfig, 'has_selected_template', false)
  }, [proposalData?.selectedProject?.custom_data])

  const isSystemLimitExceeded = useMemo(() => {
    if (!proposalData) return false
    return proposalData?.systems.length > 6
  }, [proposalData?.systems])

  const showTemplateModalByDefault = useMemo(() => {
    // Modal should be shown by default if:
    //   - project has exceeded # of systems on proposalV2 template, OR
    //   - project is created after configured start date, AND
    //   - project already has NO selected proposal template from modal
    if (!proposalData) return false
    const { isDetected: isProposalV2Template } = detectProposalV2ProposalTemplate(proposalData.proposalTemplateSettings)
    if (isProposalV2Template && isSystemLimitExceeded) return true
    const projectCreateDate = moment(proposalData?.selectedProject?.created_date)
    const showStartDate = moment(proposalFeatureConfig?.proposal_modal_start_date)
    return !hasSelectedTemplate && projectCreateDate >= showStartDate
  }, [proposalData, isSystemLimitExceeded, hasSelectedTemplate])

  return (
    <TemplateLibraryModal
      openDefault={showTemplateModalByDefault}
      disableClose={showTemplateModalByDefault}
      isSystemLimitExceeded={isSystemLimitExceeded}
      {...props}
    />
  )
}

const ControlledTemplateLibraryModalWrapper = (props) => {
  const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')
  if (!enableProposalV2) return null
  return ControlledTemplateLibraryModal(props)
}

export default ControlledTemplateLibraryModalWrapper
