import Tooltip from 'elements/tooltip/Tooltip'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button, styled } from 'opensolar-ui'
import React, { ReactNode } from 'react'
import { BlackHeader, CustomWrapper, useStyles } from './styles'

interface BaseProps {
  title: string
  description: ReactNode
  WarningMsgComponent?: ReactNode
  logo?: ReactNode
}

const IntegrationDesc = styled('p', { name: 'IntegrationDesc' })(({ theme }) => ({
  fontSize: 12,
  marginTop: 8,
  color: theme.palette.grey[810],
  lineHeight: '18px',
}))

const IconWrapper = styled('div', { name: 'IconWrapper' })(({ theme }) => ({
  marginLeft: 'auto',
  border: theme.mixins.borderStandard.border,
  borderRadius: 6,
  padding: 3,
  alignSelf: 'flex-start',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ActionButton = styled(Button, { name: 'ActionButton' })<{
  disabled?: boolean
}>(({ disabled, theme }) =>
  disabled
    ? {
        backgroundColor: `${theme.palette.secondary.dark} !important`,
        color: `${theme.palette.grey[350]} !important`,
      }
    : { backgroundColor: theme.palette.blue[600] + ' !important', color: '#fff !important' }
)

interface WithChildrenProps extends BaseProps {
  children: React.ReactElement
  btnLabel?: never
  onClick?: never
  tooltip?: never
  disabled?: never
}

interface WithoutChildrenProps extends BaseProps {
  children?: never
  tooltip?: string
  btnLabel: string
  onClick?: () => void
  disabled?: boolean
}

type PropTypes = WithChildrenProps | WithoutChildrenProps

const IntegrationRow: React.FC<PropTypes> = ({
  title,
  description,
  btnLabel,
  onClick,
  logo,
  disabled,
  WarningMsgComponent,
  tooltip,
  children,
}) => {
  const classes = useStyles()
  const { trackEvent } = useTrackComponent({
    componentKey: 'integration_row',
    eventName: 'User Clicked On Manage Page Integrations Section',
  })

  return (
    <CustomWrapper>
      <div className={classes.innerWrapper}>
        <div className={classes.detailWrapper}>
          <BlackHeader>{title}</BlackHeader>
          <IntegrationDesc>{description}</IntegrationDesc>
        </div>
        {logo && <IconWrapper>{logo}</IconWrapper>}
      </div>
      <div>
        {WarningMsgComponent}

        {children ? (
          <>{children}</>
        ) : (
          <Tooltip title={tooltip} color={'secondary'}>
            <span>
              <ActionButton onClick={onClick} disabled={!!disabled}>
                {btnLabel}
              </ActionButton>
            </span>
          </Tooltip>
        )}
      </div>
    </CustomWrapper>
  )
}

export default IntegrationRow
