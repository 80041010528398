import Portal from '@material-ui/core/Portal'
import { Grid, LoadingDots, Typography } from 'opensolar-ui'
import { useEffect, useMemo, useRef } from 'react'
import type { ComponentTypesV2 } from 'types/selectComponent'
import { DISTRIBUTOR_FILTER_KEY_V2 } from '../../../elements/hardwareFilter/distributor/DistributorFilterConfigV2Factory'
import { AVAILABILITY_FILTER_KEY_V2 } from '../../../elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactoryV2'
import HardwareSelectorFilterSidebar from '../../../elements/hardwareSelectorV2/elements/HardwareSelectorFilterSidebar'
import useHardwareSelectorFetchEngine from '../../../elements/hardwareSelectorV2/fetch/useHardwareSelectorFetchEngine'
import { HARDWARE_FILTER_SIDEBAR_ID } from '../catalog/HardwareFilterSidebar'
import ProductGrid from '../home/ProductGrid'
import { styled } from '@material-ui/core'
import { BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2 } from 'elements/hardwareFilter/batteryCompatibility/BatteryCompatibilityFilterNodeFactoryV2'
import { HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2 } from 'elements/hardwareFilter/constantsV2'
import { GLOBAL_DISTRIBUTOR_NODE_KEY_V2 } from 'elements/hardwareFilter/distributor/GlobalDistributorFilterFactory'
import { VIEW_FROM_FILTER_NODE_KEY_V2 } from 'elements/hardwareFilter/viewFrom/ViewFromFilterNodeFactoryV2'
import listQueryParamsFormatter from 'elements/input/listQueryParamsFormatter'
import { majorComponentCategories, otherCategories } from '../configs'
import { HardwareSupplierFilterKeyType } from '../type'
import ShopPagination from './ShopPagination'
import { useMultiDistributors } from './useMultiDistributors'
import NoResultFoundPage from '../elements/NoResultFoundPage'

const Container = styled('div')({
  minHeight: '100vh',
  background: '#F6F9FC',
  padding: '24px',
})

const NoResults = ({ text }: { text: string }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography textVariant="h4">Results for "{text}"</Typography>
        <Typography textVariant="body2" colorHex="#6B6A76">
          0 result
        </Typography>
      </Grid>
    </Grid>
  )
}

export const ShopItemsWithFilter = ({
  trackSource,
  searchText,
  componentType,
  distributor,
}: {
  trackSource?: string
  searchText?: string
  componentType: ComponentTypesV2
  distributor?: HardwareSupplierFilterKeyType
}) => {
  let allDistributors = useMultiDistributors()
  const resultTotal = useRef(0)

  const hardware_config_sidebar = useMemo(() => {
    const hiddenSidebar = [VIEW_FROM_FILTER_NODE_KEY_V2, BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2]
    const overrideLabels = { [GLOBAL_DISTRIBUTOR_NODE_KEY_V2]: 'Distributors' }

    return HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2.filter((config) => !hiddenSidebar.includes(config.key)).map(
      (config) => ({
        ...config,
        label: overrideLabels[config.key] || config.label,
      })
    )
  }, [])

  const defaultFilterValues = useMemo(() => {
    const distributorSet = distributor ? new Set([distributor]) : new Set(allDistributors)
    return {
      [DISTRIBUTOR_FILTER_KEY_V2]: listQueryParamsFormatter.format({ value: distributorSet }),
      [AVAILABILITY_FILTER_KEY_V2]: 'true',
    }
  }, [distributor, allDistributors])

  const othersKeyWord = ['other', 'others']

  const ComponentTypes = useMemo(() => {
    if (
      (componentType && othersKeyWord.includes(componentType)) ||
      (searchText && othersKeyWord.includes(searchText))
    ) {
      return otherCategories
    }

    // check whether the search term is a component type category
    const isMajorComponent = searchText && majorComponentCategories.includes(searchText)
    if (!componentType && searchText && isMajorComponent) {
      return searchText
    }

    return componentType ? [componentType] : componentType
  }, [componentType]) as ComponentTypesV2[]

  const {
    componentsData,
    modifiableFilterValues,
    persistentFilterValues,
    allFilterValues,
    setFilters,
    setPage,
    total,
    page,
    loading,
  } = useHardwareSelectorFetchEngine({
    componentTypes: ComponentTypes,
    limit: 20,
    defaultFilterValues,
  })
  const noResult = componentsData?.length === 0

  useEffect(() => {
    const filters = {
      ...modifiableFilterValues,
      [DISTRIBUTOR_FILTER_KEY_V2]: listQueryParamsFormatter.format({ value: allDistributors }),
      search: searchText,
      [AVAILABILITY_FILTER_KEY_V2]: 'true',
    }

    const isMajorComponent = searchText && majorComponentCategories.includes(searchText)
    if (isMajorComponent) {
      delete filters['search']
    }

    setFilters(filters)
  }, [searchText])

  if (loading) return <LoadingDots />

  if (total) {
    resultTotal.current = total
  }

  const portalContainer = document.getElementById(HARDWARE_FILTER_SIDEBAR_ID)

  return (
    <Container>
      <Portal container={portalContainer}>
        <HardwareSelectorFilterSidebar
          hardwareFilterConfigList={hardware_config_sidebar}
          persistentFilterValues={persistentFilterValues}
          modifiableFilterValues={modifiableFilterValues}
          allFilterValues={allFilterValues}
          setModifiableFilterValues={setFilters}
          componentTypes={ComponentTypes}
          location="shop"
        />
      </Portal>
      {noResult ? (
        <NoResults text={searchText || componentType} />
      ) : (
        <Grid container direction="column">
          <Grid item>
            {searchText && <Typography textVariant="h4"> {`Results for "${searchText}"`} </Typography>}
            {!searchText && <Typography textVariant="h4"> {`All ${componentType}`} </Typography>}
            {<Typography textVariant="body2"> {`${resultTotal.current} results`} </Typography>}
          </Grid>
          <Grid item>
            <ShopPagination
              page={page}
              limit={20}
              total={resultTotal.current}
              onNexPage={(nextPage) => setPage(nextPage)}
              onPreviousPage={(previousPage) => setPage(previousPage)}
            />
          </Grid>
        </Grid>
      )}
      {noResult ? (
        <NoResultFoundPage text="No results found" />
      ) : (
        <ProductGrid componentsData={componentsData} carouselMode={false} />
      )}
    </Container>
  )
}

export default ShopItemsWithFilter
