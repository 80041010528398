import { countryMapping } from 'constants/countries'
import { authSelectors } from 'ducks/auth'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete'

type PropTypes = {}

const InvoiceAddressAutocomplete: React.FC<PropTypes> = (props) => {
  const formValues = useFormState().values
  const form = useForm()
  const apiKeyGoogle = useSelector(authSelectors.getApiKeyGoogle)

  const getDefaultValue = () => {
    let defaultValue = ''

    const {
      billing_address_line_1,
      billing_address_city,
      billing_address_state,
      billing_address_country,
      billing_address_postal_code,
    } = formValues

    if (billing_address_line_1) defaultValue += ` ${billing_address_line_1}`
    if (billing_address_city) defaultValue += ` ${billing_address_city}`
    if (billing_address_state) defaultValue += ` ${billing_address_state}`
    if (billing_address_postal_code) defaultValue += ` ${billing_address_postal_code}`
    if (billing_address_country) defaultValue += `, ${countryMapping[billing_address_country]?.name}`

    return defaultValue
  }

  const handlePlaceSelected = (addressFields) => {
    form.change('billing_address_line_1', addressFields.address)
    form.change('billing_address_city', addressFields.locality)
    form.change('billing_address_state', addressFields.state)
    form.change('billing_address_country', addressFields.country_iso2)
    form.change('billing_address_postal_code', addressFields.zip)
  }

  return (
    <AddressAutocomplete
      onPlaceSelected={handlePlaceSelected}
      api_key_google={apiKeyGoogle}
      types={['address']}
      componentRestrictions={null}
      label="Address"
      defaultValue={getDefaultValue()}
      isCashFlowUx
    />
  )
}
export default InvoiceAddressAutocomplete
