export const projectDataSave = () => {
  if (!window.projectForm) {
    console.error('Open a project before using this tool')
    return
  }
  let project = window.projectForm.getState().values
  let projectFiltered: any = {}
  for (var key of Object.keys(project)) {
    if (key === 'design' || key.includes('contact')) {
      continue
    }
    projectFiltered[key] = project[key]
  }
  projectFiltered.design = window.editor.serializeDesign()

  const name = project.id ? 'project_' + project.id : project.address || 'project'

  downloadString(JSON.stringify(projectFiltered), 'application/json', name + '.json')
}

function downloadString(text, fileType, fileName) {
  var blob = new Blob([text], { type: fileType })

  var a = document.createElement('a')
  a.download = fileName
  a.href = URL.createObjectURL(blob)
  a.dataset.downloadurl = [fileType, a.download, a.href].join(':')
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  setTimeout(function () {
    URL.revokeObjectURL(a.href)
  }, 1500)
}
