var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';
import FileInput from './FileInput';
var MAX_IMAGE_SIZE_MB = 4;
var useStyles = makeStyles(function (theme) { return ({
    root: { width: '100%' },
    dropZone: {
        background: theme.palette.background.default,
        cursor: 'pointer',
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
    preview: {
        display: 'inline-block',
    },
    removeButton: {
        display: 'inline-block',
        position: 'relative',
        float: 'left',
        '& button': {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            minWidth: theme.spacing(2),
            opacity: 0,
        },
        '&:hover button': {
            opacity: 1,
        },
    },
}); }, { name: 'RaImageInput' });
var ImageInput = function (props) {
    var classes = useStyles(props);
    var _a = useState(false), showError = _a[0], setError = _a[1];
    var maxSize = props.maxSize, options = props.options, rest = __rest(props, ["maxSize", "options"]);
    var defaultOptions = {
        onDropRejected: function () { return setError(true); },
        onDrop: function () { return showError && setError(false); },
    };
    var FileInputOptions = __assign(__assign({}, defaultOptions), options);
    return (_jsxs(_Fragment, { children: [_jsx(FileInput, __assign({ labelMultiple: "ra.input.image.upload_several", labelSingle: "ra.input.image.upload_single", classes: classes, maxSize: maxSize, options: FileInputOptions }, rest)), showError && (_jsxs("div", { style: {
                    background: 'rgb(253, 237, 237)',
                    margin: '5px 0px',
                    padding: '10px 16px',
                    lineHeight: '1em',
                    color: 'rgb(95, 33, 32)',
                    borderRadius: 4,
                    textAlign: 'left',
                }, children: [_jsx(ErrorIcon, { style: { width: 22, height: 22, color: 'rgb(239 83 80)', verticalAlign: 'middle', marginRight: 10 } }), _jsxs("span", { style: { verticalAlign: 'middle' }, children: ["Please enter a image file with a valid file size no larger than ", MAX_IMAGE_SIZE_MB, "Mb"] })] }))] }));
};
ImageInput.defaultProps = {
    maxSize: MAX_IMAGE_SIZE_MB * 1024 * 1024,
};
export default ImageInput;
