import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button } from 'opensolar-ui'
import { useCallback } from 'react'
import { SaveButton, Toolbar, useNotify, useRedirect, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { ActionType, EventType } from 'types/tracking'
import { capitalize } from 'util/misc'

const TrackedToolbar = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const translate = useTranslate()
  const { trackEvent } = useTrackComponent({
    componentKey: `control.${props.resource}`,
    eventName: `User Interacted With ${props.resource}`,
  })

  const onSuccess = useCallback((response) => {
    const record = response.data
    const eventRecordData = { data: { type: props.resource, id: record.id, title: record.title } }
    if (!record.modified_date) {
      trackEvent(
        EventType.SYSTEM_EVENT,
        { type: ActionType.SAVE },
        { eventName: `User Created ${capitalize(props.resource)}`, ...eventRecordData }
      )
      notify('ra.notification.created', 'info')
      redirect(props.redirect, `/${props.resource}`, record.id, record)
    } else {
      trackEvent(
        EventType.SYSTEM_EVENT,
        { type: ActionType.UPDATE },
        { eventName: `User Edited ${capitalize(props.resource)}`, ...eventRecordData }
      )
      notify('ra.notification.updated', 'success')
      redirect(props.redirect, `/${props.resource}`)
    }
  }, [])

  const redirectLinkOnCancel =
    props.redirect && props.redirect !== 'list' && props.redirect !== 'edit' ? props.redirect : `/${props.resource}`

  return (
    <Toolbar {...props}>
      {!props.hideCancelButton && (
        <Link style={{ textDecoration: 'none' }} to={redirectLinkOnCancel}>
          <Button startIcon={<AlertError />} variant="contained" color="default" style={{ margin: '10px 0 10px 10px' }}>
            <span>{translate('Cancel')}</span>
          </Button>
        </Link>
      )}
      <SaveButton
        {...props}
        redirect={props.redirect}
        submitOnEnter={props.submitOnEnter}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect || props.handleSubmit}
        onClick={() => {
          trackEvent(
            EventType.USER_INTERACTION,
            { type: ActionType.CLICK },
            {
              eventName: `User Clicked On ${capitalize(props.resource)} Save Button`,
            }
          )
        }}
        onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
          defaultSaveFunc(values, redirect, { onSuccess, onFailure: props.onFailure })
        }}
      />
    </Toolbar>
  )
}

export default TrackedToolbar
