import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        backgroundColor: theme.palette.grey[300],
        display: 'flex',
    },
}); }, { name: 'RaPlaceholder' });
var Placeholder = function (props) {
    var classes = useStyles(props);
    return (_jsx("div", { className: classnames(props.className, classes.root), children: "\u00A0" }));
};
export default Placeholder;
