import { authSelectors } from 'ducks/auth'
import { OsView } from 'opensolar-sdk'
import useSendProposalButton from 'projectSections/sections/proposal/useSendProposalButton'
import useViewAsCustomer from 'projectSections/sections/proposal/useViewAsCustomer'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useSdkSingleton } from 'sdk/useSdkSingleton'
import { SdkProposalType, useSdkWithProposalV2 } from './useBindProposal'
import useSaveTemplateSettings from './useSaveTemplateSettings'
import { getProposalV2AppUrlOverride } from './utils'

const PublicProposalV2 = (props: SdkProposalType) => {
  const [view, setView] = useState<OsView | undefined>(undefined)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const isStaff = useSelector(authSelectors.getIsStaff)

  const viewUrl = useMemo(() => {
    return props.proposalData?.proposalTemplateSettings.proposal_v2_config?.view_config.url
  }, [props.proposalData?.proposalTemplateSettings.proposal_v2_config?.view_config.url])

  useSdkWithProposalV2({
    ...props,
    viewAdded: !!view,
  })
  const sdk = useSdkSingleton()

  useEffect(() => {
    if (sdk !== undefined && iframeRef.current && view === undefined) {
      const view = sdk.addIframeView({ iframe: iframeRef.current })
      setView(view)
    }
    return () => {
      if (view) {
        sdk?.removeView(view)
      }
    }
  }, [sdk, view])

  if (!viewUrl) return null

  // This allows staff to override the iframe url to debug the proposal app
  const iframeUrl = isStaff ? getProposalV2AppUrlOverride() || viewUrl : viewUrl

  return (
    <iframe
      ref={iframeRef}
      style={{
        border: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'all',
        backgroundColor: 'white',
        inset: 0,
      }}
      src={iframeUrl}
    />
  )
}

export const SEND_ERROR_MESSAGE = 'This project has unsaved changes. Save your project to enable proposal sending.'

const OnlineProposalV2 = (props: SdkProposalType) => {
  // Add some form logic to the public-facing proposal, to allow edits to proposal
  const form = useForm()
  const translate = useTranslate()
  const onSaveTemplateSettings = useSaveTemplateSettings({ form })
  const { onSend, ...sendButtonOptions } = useSendProposalButton({
    unsavedChangesErrorMessage: translate(SEND_ERROR_MESSAGE),
  })

  const { toggleViewAsCustomer } = useViewAsCustomer()

  return (
    <PublicProposalV2
      {...props}
      sendButtonOptions={sendButtonOptions}
      onSendProposal={onSend}
      onSaveTemplateSettings={onSaveTemplateSettings}
      setIsViewAsCustomer={toggleViewAsCustomer}
    />
  )
}

const ProposalV2 = (
  props: SdkProposalType & {
    isNestedInProjectForm: boolean
  }
) => (props.isNestedInProjectForm ? <OnlineProposalV2 {...props} /> : <PublicProposalV2 {...props} />)

export default ProposalV2
