import { GridSize } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import { useCountryListsTranslated } from 'hooks/useCountryListsTranslated'
import { Grid, styled } from 'opensolar-ui'
import { STATE_CHOICES } from 'projectSections/sections/manage/cashFlowTransactions/invoices/constants'
import { SelectInput, TextInput } from 'ra-ui-materialui'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  label: string
  name: string
  form: any
  size: GridSize
}

const useStyles = makeOpenSolarStyles((theme) => ({
  field: {
    margin: '5px 0',
  },
}))

const FieldWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const InvoiceDetailsField: React.FC<PropTypes> = ({ label, name, form, size, children }) => {
  const classes = useStyles()
  const orgCountry = useSelector(orgSelectors.getOrg)?.country?.iso2
  const { countriesAll } = useCountryListsTranslated()

  const stateChoices = useMemo(() => orgCountry && STATE_CHOICES[orgCountry], [orgCountry])
  const countryChoices = countriesAll?.map((c) => ({ id: c.iso2, name: c.name }))

  const isStateField = label === 'State'
  const isCountryField = label === 'Country'
  const customField = (
    <CustomField
      label={label}
      name={name}
      source={name}
      component={isStateField || isCountryField ? SelectInput : TextInput}
      variant="outlined"
      fullWidth
      className={classes.field}
      disabled={isCountryField}
      choices={isStateField ? stateChoices : isCountryField ? countryChoices : undefined}
      style={isStateField || isCountryField ? { width: '100%' } : undefined}
      onChange={(e) => {
        form.change(name, e.target.value)
      }}
    />
  )

  return (
    <Grid item xs={size}>
      {children ? (
        <FieldWrapper>
          {customField}
          {children}
        </FieldWrapper>
      ) : (
        customField
      )}
    </Grid>
  )
}

export default InvoiceDetailsField
