var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * A compact version of the Mui Stepper component styled for use in integration cards
 */
import { default as Step } from '@material-ui/core/Step';
import { default as StepConnector } from '@material-ui/core/StepConnector';
import { default as StepLabel } from '@material-ui/core/StepLabel';
import { default as Mui4Stepper } from '@material-ui/core/Stepper';
import { StepperCheckIcon } from '../icons';
import { styled } from '../styles';
var StyledStep = styled(Step)({
    padding: 0,
});
var StyledConnector = styled(StepConnector)(function (_a) {
    var theme = _a.theme;
    return ({
        right: '100%',
        left: '-100%',
        '& .MuiStepConnector-active .MuiStepConnector-line': {
            backgroundColor: theme.palette.blue[700],
        },
        '& .MuiStepConnector-completed .MuiStepConnector-line': {
            backgroundColor: theme.palette.blue[700],
        },
        '& .MuiStepConnector-line': {
            height: 3,
            borderWidth: 6,
            borderTop: 'none',
            background: theme.palette.grey[200],
        },
    });
});
var ColorlibStepIconRoot = styled('div', {
    shouldForwardProp: function (prop) { return prop !== 'ownerState'; },
})(function (_a) {
    var theme = _a.theme, ownerState = _a.ownerState;
    return (__assign(__assign({ backgroundColor: theme.palette.grey[50], zIndex: 1, color: 'white', width: 30, height: 30, display: 'flex', borderRadius: '50%', justifyContent: 'center', alignItems: 'center' }, ((ownerState === null || ownerState === void 0 ? void 0 : ownerState.active) && {
        outline: '1px solid white',
        boxShadow: "0px 0px 0px 3px ".concat(theme.palette.blue[500], ", 0px 0px 0px 4px white"),
        backgroundColor: theme.palette.blue[700],
    })), ((ownerState === null || ownerState === void 0 ? void 0 : ownerState.completed) && {
        backgroundColor: theme.palette.blue[700],
    })));
});
function ColorlibStepIcon(props) {
    var active = props.active, completed = props.completed, className = props.className, icon = props.icon;
    var steps = [1, 2, 3];
    var icons = steps.reduce(function (acc, step) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[step] = _jsx(StepperCheckIcon, {}), _a)));
    }, {});
    return (_jsx(ColorlibStepIconRoot, { ownerState: { completed: completed, active: active }, className: className, children: completed ? (icons[String(props.icon)]) : (_jsx("span", { style: { color: active ? 'white' : 'black' }, children: String(props.icon) })) }));
}
var StyledStepLabel = styled(StepLabel)(function (_a) {
    var narrow = _a.narrow;
    return ({
        alignItems: 'start !important',
        marginBottom: '8px',
        '& .MuiStepLabel-label': {
            // @ts-ignore
            textAlign: 'start !important',
            marginTop: narrow ? '10px !important' : '16px !important',
        },
    });
});
var StyledStepper = styled(Mui4Stepper)(function (_a) {
    var theme = _a.theme, narrow = _a.narrow;
    return ({
        padding: '0',
        paddingTop: narrow ? '8px' : '24px',
        '& .MuiStepIcon-active': {
            color: theme.palette.blue[700],
        },
    });
});
export var CompactStepper = function (_a) {
    var steps = _a.steps, activeStep = _a.activeStep, narrow = _a.narrow;
    return (_jsx(StyledStepper, { connector: _jsx(StyledConnector, {}), orientation: "horizontal", alternativeLabel: true, activeStep: activeStep, narrow: narrow, children: steps.map(function (step) { return (_jsxs(StyledStep, { active: activeStep === step.id, children: [_jsx(StyledStepLabel, { StepIconComponent: ColorlibStepIcon, narrow: narrow, children: step.label }), step.content] }, step.id)); }) }));
};
