var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from 'opensolar-ui';
import { FinanceCtaType } from '../genericFinance/constants';
var SEVERITY_MAP = (_a = {},
    _a[FinanceCtaType.ALERT_ERROR] = 'error',
    _a[FinanceCtaType.ALERT_INFO] = 'info',
    _a[FinanceCtaType.ALERT_WARNING] = 'warning',
    _a);
var CheckoutAlertCta = function (props) {
    var actionData = props.elementData.actionData;
    if (!actionData.status_title || !actionData.cta_type || !SEVERITY_MAP[actionData.cta_type])
        return null;
    return (_jsx("div", { children: _jsxs(Alert, { severity: SEVERITY_MAP[actionData === null || actionData === void 0 ? void 0 : actionData.cta_type], children: [_jsx("p", { children: actionData.status_title }), actionData.status_title_secondary && _jsx("p", { children: actionData.status_title_secondary })] }) }));
};
export default CheckoutAlertCta;
