import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import IronRidgeDialog from 'Designer/integrations/ironRidge/IronRidgeDialog'
import { authSelectors } from 'ducks/auth'
import { updateRoleIronridgeEnabled } from 'ducks/auth_roles'
import { orgSelectors } from 'ducks/orgs'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import DownloadBOMButton from 'resources/integrations/ironridge/DownloadBOMButton'
import { RoleType } from 'types/roles'
import { RootState } from 'types/state'

export function ironRidgeIsSelected(ironRidgeIntegration: any): boolean {
  const selectedMountingSystem = window.editor.selectedSystem.mounting
  return (
    selectedMountingSystem === 'ironridge' ||
    (!selectedMountingSystem && ironRidgeHasBeenImported(ironRidgeIntegration))
  )
}

export function ironRidgeHasBeenImported(ironRidgeIntegration: any): boolean {
  return !!ironRidgeIntegration && !!Object.keys(ironRidgeIntegration).length
}
interface IronRidgeBomProps {
  ironRidgeIntegration?: unknown
  systemUuid: string
}

const IronRidgeBom: React.FC<IronRidgeBomProps> = ({ ironRidgeIntegration, systemUuid }) => {
  const [ironRidgeOpen, setIronRidgeOpen] = useState(false)
  const isCurrentOrgLinkedWithIronRidge = useSelector((state: RootState) =>
    orgSelectors.getIsPartnerOrgLinked(state, 'ironridge')
  )
  const ironRidgePairData = useSelector((state: RootState) => state?.auth?.user?.paired_partners?.['ironridge'])
  const role = useSelector(authSelectors.getCurrentRole)
  const forceOpenIRDialog = useSelector((state: RootState) => state.ironRidge?.forceShowDialog)

  const dispatch = useDispatch()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const ironRidgeAccountConnected = useMemo(() => {
    return !!(role?.ironridge_email && role?.ironridge_terms_accepted)
  }, [role?.ironridge_email, !!role?.ironridge_terms_accepted])

  const enableIronRidge = useCallback(async ({ email, roleId }) => {
    dispatch(updateRoleIronridgeEnabled(email, true, roleId))
    await dataProvider.CUSTOM_PATCH('roles', {
      url: `roles/${roleId}/`,
      data: {
        ironridge_email: email,
        ironridge_terms_accepted: new Date(),
      },
    })
  }, [])

  const enableIronRidgeForPairedUser = useCallback(async () => {
    if (!role?.id) return

    if (ironRidgePairData?.pair_metadata?.email) {
      await enableIronRidge({ email: ironRidgePairData.pair_metadata.email, roleId: role.id })
    } else if (isCurrentOrgLinkedWithIronRidge) {
      const roleDetail = await dataProvider
        .getOne<RoleType>('roles', { id: role.id })
        .catch((error) => {
          notify('server error', 'warning')
        })

      if (roleDetail && roleDetail.data) {
        await enableIronRidge({ email: roleDetail.data.user_email, roleId: role.id })
      }
    }
  }, [role, ironRidgePairData])

  const handleClicked = useCallback(async () => {
    const hasPanels = (window.editor?.selectedSystem?.getModules().length || 0) > 0
    if (!hasPanels) {
      notify('Please add panels to the project first')
      return
    }

    if (!ironRidgeAccountConnected) {
      await enableIronRidgeForPairedUser()
    }

    setIronRidgeOpen(true)
  }, [enableIronRidgeForPairedUser, ironRidgeAccountConnected])

  return (
    <>
      <div
        style={{
          margin: '15px 0px 5px 0px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 16px',
          cursor: 'pointer',
          border: '1px solid #ececec',
          boxShadow: 'rgba(0,0,0,0.15) 0px 2px 2px',
        }}
        onClick={handleClicked}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img src={`${window.PUBLIC_URL}/images/ironridge_logo.png`} width="94px" height="18px" />
          <span style={{ marginTop: '10px' }}>
            {ironRidgeAccountConnected
              ? ironRidgeHasBeenImported(ironRidgeIntegration)
                ? 'Update Bill of Materials'
                : 'Import Bill of Materials'
              : 'Connect IronRidge Account'}
          </span>
        </div>
        <div>
          <LaunchIcon />
        </div>
      </div>
      {(ironRidgeOpen || forceOpenIRDialog) && role?.id && (
        <IronRidgeDialog
          showDialog={ironRidgeOpen}
          onClose={() => setIronRidgeOpen(false)}
          roleId={role.id}
          ironridgeEmail={role?.ironridge_email}
        />
      )}
      {ironRidgeHasBeenImported(ironRidgeIntegration) && (
        <div>
          <DownloadBOMButton
            project={window.WorkspaceHelper?.project}
            is_studio={true}
            studio_uuid={systemUuid}
            isIronridge={true}
          />
        </div>
      )}
    </>
  )
}

export default memo(IronRidgeBom)
