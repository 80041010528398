/*
    Stepper should map permit status to steps:
    See https://gosolarapp.org/api/docs#section/Workflow-Examples/For-Installers:-Submit-a-project-for-permit-approval
*/
import type { CompactStepType } from 'opensolar-ui'
import { Chip, CompactStepper } from 'opensolar-ui'
import React from 'react'
import { SolarAppPermitStates, SolarAppProjectStatus } from '../types'
import { PROJECT_STATUS_TO_STEPPER_STATES, SOLAR_APP_PERMIT_STEPS } from './constants'
import { isRevisionInProgress } from './utils'
export * from './StepActions'

interface PropTypes {
  solarAppProjectStatus: SolarAppProjectStatus
  narrow?: boolean
}

const SolarAppStepper: React.FC<PropTypes> = ({ solarAppProjectStatus, narrow }) => {
  const activeStep = PROJECT_STATUS_TO_STEPPER_STATES[solarAppProjectStatus.current_state]

  const permitAsSteps: CompactStepType[] = SOLAR_APP_PERMIT_STEPS.map((step) => ({
    id: step.step,
    label: step.label,
    content: (
      <>
        {step.step === 1 && isRevisionInProgress(solarAppProjectStatus) && <Chip label="Revision" color="warning" />}
        {step.step === 2 && solarAppProjectStatus.current_state === SolarAppPermitStates.QUALIFIED && (
          <Chip label="Qualified" color="success" />
        )}
        {step.step === 3 && solarAppProjectStatus.current_state === SolarAppPermitStates.APPROVED && (
          <Chip label="Approved" color="success" />
        )}
        {step.step === 3 && solarAppProjectStatus.current_state === SolarAppPermitStates.ARCHIVED && (
          <Chip label="Archived" color="secondary" />
        )}
      </>
    ),
  }))

  return <CompactStepper steps={permitAsSteps} activeStep={activeStep} narrow={narrow} />
}

export default SolarAppStepper
