import { CircularProgress } from '@material-ui/core'
import { Alert, styled } from 'opensolar-ui'
import { useFetchProjectStatus } from 'projectSections/sections/manage3/integrations/SolarAppIntegration/hooks'
import SolarAppStepper, {
  StepActions,
} from 'projectSections/sections/manage3/integrations/SolarAppIntegration/SolarAppStepper'
import { LoadingWrapper } from 'projectSections/sections/manage3/integrations/styles'
import type { ProjectType } from 'types/projects'

const ErrorWrapper = styled('div')({
  marginTop: '8px',
  marginBottom: '8px',
})

interface PropTypes {
  project: ProjectType
}

const SolarAppProjectStatus = ({ project }: PropTypes) => {
  const { isLoading: fetchingProjectStatus, errorMsg, solarAppProjectStatus } = useFetchProjectStatus(project)

  if (fetchingProjectStatus && !solarAppProjectStatus)
    return (
      <LoadingWrapper>
        <CircularProgress color="inherit" />
      </LoadingWrapper>
    )

  return (
    <>
      {errorMsg && (
        <ErrorWrapper>
          <Alert severity="error">{errorMsg}</Alert>
        </ErrorWrapper>
      )}
      {solarAppProjectStatus && (
        <>
          <SolarAppStepper solarAppProjectStatus={solarAppProjectStatus} narrow />

          <StepActions project={project} solarAppProjectStatus={solarAppProjectStatus} />
        </>
      )}
    </>
  )
}

export default SolarAppProjectStatus
