import { jsx as _jsx } from "react/jsx-runtime";
import { default as Mui5Button } from '@mui/material/Button';
import { styled } from '../../styles/styled';
var Wrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        padding: '0px 10px',
    };
});
export var DepositCtaButton = function (_a) {
    var label = _a.label, disabled = _a.disabled, onClick = _a.onClick;
    return (_jsx(Wrapper, { children: _jsx(Mui5Button, { onClick: onClick, fullWidth: true, variant: "contained", color: "secondary", disabled: disabled, children: label }) }));
};
