import PrioritySquare from 'elements/PrioritySquare'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { PRIORITY_CHOICES } from 'resources/projects/choices'
import { ActionType, EventType } from 'types/tracking'
import { formatDate } from 'util/date'
import ManageSelectDropdown from '../common/ManageSelectDropdown'
import ProjectContacts from './elements/contacts/ProjectContacts'
import ProjectTags from './elements/ProjectTags'
import ProjectTeams from './elements/teams/ProjectTeams'

/*
Seperator implementation taken from https://stackoverflow.com/questions/64174517/how-to-implement-separators-between-wrapped-flex-items
Hides the last seperator on each row by clipping it (`overflow: 'hidden'`)
*/
const divider = 1
const columnGap = 20
const border = 2

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch',
  marginLeft: columnGap / -2,
  overflow: 'hidden',
  rowGap: 10,
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexWrap: 'nowrap',
    justifyContent: 'normal',
    marginLeft: 0,
    overflow: 'visible',
    rowGap: 0,
    marginTop: 0,
  },
}))

const StyledDetail = styled('div')(({ theme }) => ({
  height: 65,
  marginLeft: columnGap / 2,
  marginRight: columnGap / 2,
  position: 'relative',
  '& p': {
    fontWeight: 500,
    margin: 0,
    color: theme.palette.grey[810],
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    right: '100%',
    top: '50%',
    display: 'inline-block',
    width: divider,
    height: '100%',
    backgroundColor: theme.palette.secondary.light,
    transform: 'translateY(-50%)',
    marginRight: (columnGap - divider) / 2 + border,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 30,
    columnGap: 5,
    margin: '5px 0px',
    '&:after': {
      display: 'none !important',
    },
  },
}))
const StyledDetailProjId = styled(StyledDetail)(({ theme }) => ({
  width: 90,
}))
const StyledDetailProjType = styled(StyledDetail)(({ theme }) => ({
  width: 155,
}))
const StyledCustomWidth = styled(StyledDetail)(({ theme }) => ({
  width: 130,
}))
const StyledDetailContent = styled('div')(({ theme }) => ({
  marginTop: 8,
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[810],
}))

const StyledProjectTypeContent = styled('div')(({ theme }) => ({
  marginTop: 1,
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[810],
}))

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const PRIORITY_COLORS = {
  1: 'info',
  2: 'warning',
  3: 'error',
}
const DetailFooter = () => {
  const translate = useTranslate()
  const project = useFormState().values
  const { change } = useForm()
  const formattedDate = useMemo(() => formatDate(project.created_date), [project.created_date])

  const { trackEvent } = useTrackComponent({ componentKey: 'detail_footer', eventName: 'Changed Project Priority' })

  const handlePriorityChange = (event) => {
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.SELECT }, { priority: event.target.value })
    change('priority', event.target.value)
  }

  return (
    <StyledWrapper>
      <StyledDetailProjId>
        <p>{translate('Project ID')}</p>
        <StyledDetailContent>
          <span>{project.id}</span>
        </StyledDetailContent>
      </StyledDetailProjId>
      <StyledCustomWidth>
        <p>{translate('Date Created')}</p>
        <StyledDetailContent>
          <span>{formattedDate}</span>
        </StyledDetailContent>
      </StyledCustomWidth>
      <StyledCustomWidth>
        <p>{translate('Priority')}</p>
        <StyledDetailContent>
          <ManageSelectDropdown
            choices={PRIORITY_CHOICES}
            source="priority"
            project={project}
            color={PRIORITY_COLORS[project.priority]}
            renderValue={(choice) => `● ${translate(choice.name)}`}
            handlePriorityChange={handlePriorityChange}
            renderChoiceValue={(choice) => (
              <StyledSpan>
                <PrioritySquare priority={choice.id} />
                {translate(choice.name)}
              </StyledSpan>
            )}
          />
        </StyledDetailContent>
      </StyledCustomWidth>
      <StyledDetailProjType>
        <p>{translate('Project Type')}</p>
        <StyledProjectTypeContent>
          <SelectInput
            choices={[
              {
                id: true,
                title: 'Residential',
              },
              {
                id: false,
                title: 'Commercial',
              },
            ]}
            optionText={'title'}
            source="is_residential"
            variant="outlined"
            label={false}
            size="small"
            color={'info'}
          />
        </StyledProjectTypeContent>
      </StyledDetailProjType>
      <ProjectContacts />
      <ProjectTeams />
      <ProjectTags />
    </StyledWrapper>
  )
}

export default DetailFooter
