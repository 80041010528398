import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Button } from 'opensolar-ui';
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import React from 'react';
var useStyles = makeStyles(function (theme) { return ({
    actions: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: 20,
    },
    hellip: { padding: '1.2em' },
}); }, { name: 'RaPaginationActions' });
function PaginationActions(props) {
    var page = props.page, rowsPerPage = props.rowsPerPage, count = props.count, onChangePage = props.onChangePage, color = props.color, size = props.size;
    var classes = useStyles(props);
    var translate = useTranslate();
    var theme = useTheme();
    /**
     * Warning: material-ui's page is 0-based
     */
    var range = function () {
        var nbPages = Math.ceil(count / rowsPerPage) || 1;
        if (isNaN(page) || nbPages === 1) {
            return [];
        }
        var input = [];
        // display page links around the current page
        if (page > 1) {
            input.push(1);
        }
        if (page === 3) {
            input.push(2);
        }
        if (page > 3) {
            input.push('.');
        }
        if (page > 0) {
            input.push(page);
        }
        input.push(page + 1);
        if (page < nbPages - 1) {
            input.push(page + 2);
        }
        if (page === nbPages - 4) {
            input.push(nbPages - 1);
        }
        if (page < nbPages - 4) {
            input.push('.');
        }
        if (page < nbPages - 2) {
            input.push(nbPages);
        }
        return input;
    };
    var getNbPages = function () { return Math.ceil(count / rowsPerPage) || 1; };
    var prevPage = function (event) {
        if (page === 0) {
            throw new Error(translate('ra.navigation.page_out_from_begin'));
        }
        onChangePage(event, page - 1);
    };
    var nextPage = function (event) {
        if (page > getNbPages() - 1) {
            throw new Error(translate('ra.navigation.page_out_from_end'));
        }
        onChangePage(event, page + 1);
    };
    var gotoPage = function (event) {
        var page = parseInt(event.currentTarget.dataset.page, 10);
        if (page < 0 || page > getNbPages() - 1) {
            throw new Error(translate('ra.navigation.page_out_of_boundaries', {
                page: page + 1,
            }));
        }
        onChangePage(event, page);
    };
    var renderPageNums = function () {
        return range().map(function (pageNum, index) {
            return pageNum === '.' ? (_jsx("span", { className: classes.hellip, children: "\u2026" }, "hyphen_".concat(index))) : (_jsx(Button, { size: size, className: "page-number", color: pageNum === page + 1 ? 'default' : color, "data-page": pageNum - 1, onClick: gotoPage, children: _jsx("span", { children: pageNum }) }, pageNum));
        });
    };
    var nbPages = getNbPages();
    if (nbPages === 1) {
        return _jsx("div", { className: classes.actions });
    }
    return (_jsxs("div", { className: classes.actions, children: [page > 0 && (_jsxs(Button, { color: color, size: size, onClick: prevPage, className: "previous-page", children: [theme.direction === 'rtl' ? _jsx(ChevronRight, {}) : _jsx(ChevronLeft, {}), translate('ra.navigation.prev')] }, "prev")), renderPageNums(), page !== nbPages - 1 && (_jsxs(Button, { color: color, size: size, onClick: nextPage, className: "next-page", children: [_jsx("span", { children: translate('ra.navigation.next') }), theme.direction === 'rtl' ? _jsx(ChevronLeft, {}) : _jsx(ChevronRight, {})] }, "next"))] }));
}
/**
 * PaginationActions propTypes are copied over from material-ui’s
 * TablePaginationActions propTypes. See
 * https://github.com/mui-org/material-ui/blob/869692ecf3812bc4577ed4dde81a9911c5949695/packages/material-ui/src/TablePaginationActions/TablePaginationActions.js#L53-L85
 * for reference.
 */
PaginationActions.propTypes = {
    backIconButtonProps: PropTypes.object,
    count: PropTypes.number.isRequired,
    classes: PropTypes.object,
    nextIconButtonProps: PropTypes.object,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    theme: PropTypes.object,
};
PaginationActions.defaultProps = {
    color: 'primary',
    size: 'small',
};
export default React.memo(PaginationActions);
