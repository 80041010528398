import { Grid } from '@material-ui/core'
import GenericInputField, { GenericFieldType } from 'elements/input/GenericInputField'
import { get } from 'lodash'
import { SelectInput } from 'ra-ui-materialui'
import { useFormState } from 'react-final-form'
import { requiredPositiveOrZero } from 'validations'
import { tableStyles } from '../../styles'

type CriteriaChoicesType = {
  id: string | null
  name: string
  variables?: GenericFieldType[]
}

const CRITERIA_CHOICES: CriteriaChoicesType[] = [
  { id: null, name: '-' },
  {
    id: 'system_dc_capacity_kw',
    name: 'PV System Size',
    variables: [
      {
        key: 'criteria_lower_limit',
        source: 'criteria_lower_limit',
        label: 'Lower Limit',
        endAdornment: 'kW',
        component: 'NumberInput',
      },
      {
        key: 'criteria_upper_limit',
        source: 'criteria_upper_limit',
        label: 'Upper Limit',
        endAdornment: 'kW',
        component: 'NumberInput',
      },
    ],
  },
  //   {
  //     id: 'phase_type',
  //     name: 'Phase Type',
  //     variables: [
  //       {
  //         key: 'criteria_option',
  //         source: 'criteria_option',
  //         label: 'Type',
  //         choices: [
  //           { id: 'single_phase', name: 'Single-Phase' },
  //           { id: 'three_phase', name: 'Three-Phase' },
  //         ],
  //         component: 'SelectInput',
  //       },
  //     ],
  //   },
]

const getCriteriaVariables = (criteriaName: any): GenericFieldType[] => {
  const criteriaVariables = CRITERIA_CHOICES.find((i) => i.id === criteriaName)
  return criteriaVariables?.variables || []
}

const CriteriaFields = ({ source, rateIndex }) => {
  const classes = tableStyles()
  const formState = useFormState()
  const rateSource = `${source}.${rateIndex}`
  const criteriaName = get(formState.values, `${rateSource}.criteria_name`)
  const criteriaVariables = getCriteriaVariables(criteriaName)

  return (
    <Grid container spacing={1} className={`${classes.criteriaInputsBlock}`}>
      <Grid item xs={12} md={4} xl={12}>
        <SelectInput
          variant="outlined"
          label="Criteria"
          source={`${rateSource}.criteria_name`}
          choices={CRITERIA_CHOICES}
          options={{
            InputLabelProps: {
              shrink: true,
            },
          }}
          className={`${classes.criteriaInput}`}
        />
      </Grid>

      {criteriaVariables.map(
        (fieldData) =>
          fieldData && (
            <Grid item xs={12} sm={6} md={4} xl={12}>
              <GenericInputField
                data={{
                  ...fieldData,
                  source: `${rateSource}.${fieldData.source}`,
                  variant: 'outlined',
                }}
                className={`${classes.criteriaInput} ${classes.criteriaTextInput}`}
                validate={requiredPositiveOrZero}
              />
            </Grid>
          )
      )}
      {/* </Grid> */}
    </Grid>
  )
}

export default CriteriaFields
