var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        toolbar: (_a = {
                // maxWidth: '70%',
                // flexWrap: 'wrap',
                justifyContent: 'flex-end',
                alignItems: 'center',
                // marginTop: '-16px',
                // marginLeft: '-25px',
                paddingRight: 0,
                paddingTop: 0
            },
            // remove left padding due to select component dialog
            // marginLeft: 'auto',
            _a[theme.breakpoints.up('xs')] = {
                paddingLeft: 0,
                minHeight: 0,
            },
            _a[theme.breakpoints.down('xs')] = {
                // paddingLeft: theme.spacing(2),
                backgroundColor: 'transparent',
            },
            _a),
        actions: (_b = {
                // paddingTop: theme.spacing(1),
                padding: 0,
                minHeight: theme.spacing(5)
            },
            _b[theme.breakpoints.down('xs')] = {
                // padding: theme.spacing(1),
                backgroundColor: 'transparent',
            },
            _b['& .MuiCardActions-spacing > :not(:first-child)'] = {
                margin: 0,
            },
            _b),
    });
}, { name: 'RaListToolbar' });
var ListToolbar = function (props) {
    var classesOverride = props.classes, filters = props.filters, filterValues = props.filterValues, // dynamically set via the UI by the user
    permanentFilter = props.permanentFilter, // set in the List component by the developer
    actions = props.actions, exporter = props.exporter, hasSearch = props.hasSearch, hideActions = props.hideActions, rest = __rest(props, ["classes", "filters", "filterValues", "permanentFilter", "actions", "exporter", "hasSearch", "hideActions"]);
    var classes = useStyles(props);
    return (_jsxs(Toolbar, { className: classnames('Ra-List-Toolbar', classes.toolbar), children: [filters &&
                React.cloneElement(filters, __assign(__assign({}, rest), { filterValues: filterValues, hasSearch: hasSearch, context: 'form' })), _jsx("span", {}), actions &&
                hideActions !== true &&
                React.cloneElement(actions, __assign(__assign(__assign({}, rest), { className: classes.actions, exporter: exporter, // deprecated, use ExporterContext instead
                    filters: filters, hasSearch: hasSearch, filterValues: filterValues, permanentFilter: permanentFilter }), actions.props))] }));
};
ListToolbar.propTypes = {
    classes: PropTypes.object,
    filters: PropTypes.element,
    permanentFilter: PropTypes.object,
    actions: PropTypes.element,
    exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
export default React.memo(ListToolbar);
