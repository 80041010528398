import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { getStockStatusPerDistributor } from 'elements/hardwareSelectorV2/elements/QuantityBlock'
import { STOCK_LEVEL_STATUS } from 'pages/ordering/constants'
import { CartDiscountNotification } from 'pages/ordering/order/CartDiscounts'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useComponentCost } from 'projectSections/hooks/useComponentCost'
import { memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BomComponentDetail from './BomComponentDetail'
import BomDistributorConnection from './BomDistributorConnection'
import BomDrawerFooter from './BomDrawerFooter'
import BomDrawerHeader from './BomDrawerHeader'
import useMapHardwareDetailsToBomLineItemsV3 from './useMapHardwareDetailsToBomLineItemsV3'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flex: 1,
    overflow: 'auto',
    flexShrink: 1,
    padding: 10,
  },
  footer: {},
  divider: {
    height: 10,
    background: 'linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.12))',
  },
}))

const BomDrawerContentV3 = ({ tab = 0 }: { tab: number }) => {
  const classes = useStyles()
  const { updateComponentCosts } = useComponentCost()
  const bomLineItemsHashMap = useMapHardwareDetailsToBomLineItemsV3()
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const [availableLineItems, unavailableLineItems] = useMemo(() => {
    const availableLineItems: LineItemType[] = []
    const unavailableLineItems: LineItemType[] = []
    bomLineItemsHashMap['all'].forEach((lineItem: LineItemType) => {
      const stockLevelStatus = getStockStatusPerDistributor(lineItem.orderingData, enabledDistributors).find(
        ({ distributor }) => distributor === lineItem.selectedDistributor
      )
      if (stockLevelStatus?.status !== STOCK_LEVEL_STATUS.NOT_AVAILABLE) {
        availableLineItems.push(lineItem)
      } else {
        unavailableLineItems.push(lineItem)
      }
    })
    return [availableLineItems, unavailableLineItems]
  }, [bomLineItemsHashMap['all']])

  useEffect(() => {
    logAmplitudeEvent('generic_element_viewed', {
      source: 'design_page',
      context: 'bom_slide_content',
    })
  }, [])

  const handleUpdateSupplier = (
    selectedSupplier: HardwareSupplierFilterKeyType,
    code: string,
    price: number | undefined
  ) => {
    updateComponentCosts([{ source: selectedSupplier, code: code, price: price }])
  }

  return (
    <div className={classes.container}>
      {tab === 0 ? (
        <>
          <div className={classes.content}>
            {/* editor.selectedSystem not react state here 
            but useHardwareDetailsDynamic will trigger re-render once selected system changed */}
            <BomDrawerHeader systemUuid={window.editor.selectedSystem?.uuid} />
            <CartDiscountNotification lineItems={availableLineItems} context={'project'} />
            {!!unavailableLineItems.length && (
              <Alert severity="warning">
                <div>
                  {unavailableLineItems.length === 1 ? '1 item' : `${unavailableLineItems.length} items`} not available.
                  Find a replacement.
                </div>
              </Alert>
            )}
            <BomComponentDetail
              title={'Panels'}
              lineItems={bomLineItemsHashMap.module}
              componentType={'module'}
              location={'design'}
              handleUpdateSupplier={handleUpdateSupplier}
              handleRemoveLineItem={null}
              isExpanded={true}
            />
            <BomComponentDetail
              title={'Mounting'}
              lineItems={bomLineItemsHashMap.mounting}
              componentType={'mounting'}
              location={'design'}
              handleUpdateSupplier={handleUpdateSupplier}
              handleRemoveLineItem={null}
              isExpanded={true}
            />
            <BomComponentDetail
              title={'Inverters'}
              lineItems={bomLineItemsHashMap.inverter}
              componentType={'inverter'}
              location={'design'}
              handleUpdateSupplier={handleUpdateSupplier}
              handleRemoveLineItem={null}
              isExpanded={true}
            />
            <BomComponentDetail
              title={'Batteries'}
              lineItems={bomLineItemsHashMap.battery}
              componentType={'battery'}
              location={'design'}
              handleUpdateSupplier={handleUpdateSupplier}
              handleRemoveLineItem={null}
              isExpanded={true}
            />
            <BomComponentDetail
              title={'Others'}
              lineItems={bomLineItemsHashMap.other}
              componentType={'other'}
              location={'design'}
              handleUpdateSupplier={handleUpdateSupplier}
              handleRemoveLineItem={null}
              isExpanded={true}
            />
          </div>
          <div className={classes.footer}>
            <BomDrawerFooter lineItems={availableLineItems} />
          </div>
        </>
      ) : (
        <div className={classes.content}>
          <BomDistributorConnection />
        </div>
      )}
    </div>
  )
}

export default memo(BomDrawerContentV3)
