import Alert from 'elements/Alert'
import moment from 'moment'
import { styled } from 'opensolar-ui'
import EventIcon from 'projectSections/sections/manage3/details/elements/history/events/EventIcon'
import { useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { StageActionEventType } from 'types/projects'

type PropTypes = {
  events: StageActionEventType[]
  isFetching: boolean
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const EventItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
  padding: '10px 5px',
  minHeight: '30px',
}))

const EventIconWrapper = styled('div')({
  width: '32px',
})

const TextCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flex: 10,
  marginLeft: '10px',
})

const TitleRow = styled('div')({
  marginBottom: '5px',
})

const TitleText = styled('div')({
  fontWeight: 500,
  marginRight: '10px',
})

const TimeStamp = styled('div')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey[700],
  fontWeight: 400,
  marginTop: '2px',
}))

const NotesText = styled('div')(({ theme }) => ({
  color: theme.palette.grey[700],
  whiteSpace: 'pre-line',
  maxHeight: '65px',
  overflowY: 'auto',
  borderRadius: '8px',
  padding: '12px',
  backgroundColor: '#F8F8F8',
}))

const ShowExpandedLink = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: theme.palette.blue[600],
  margin: '15px 0px 0px 0px',
}))

const NothingHereYet = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px 0px',
  color: theme.palette.grey[500],
}))

const MAX_ROWS = 100
const INITIAL_ROW_LIMIT = 10

const CashFlowEventTable: React.FC<PropTypes> = (props) => {
  const [showExpanded, setShowExpanded] = useState<boolean>(false)

  const eventsToShow = useMemo(() => {
    if (showExpanded) return props.events?.slice(0, MAX_ROWS)
    else return props.events?.slice(0, INITIAL_ROW_LIMIT)
  }, [showExpanded, props.events])

  const showTooManyRowsWarning = useMemo(() => {
    return props.events?.length > MAX_ROWS
  }, [props.events?.length])

  const translate = useTranslate()

  const toggleShowExpanded = () => {
    setShowExpanded(!showExpanded)
  }

  if (props.isFetching) {
    return <Skeleton height={200} />
  }
  if (!eventsToShow?.length) {
    return (
      <NothingHereYet>
        <p>{translate('Nothing to show here yet...')}</p>
      </NothingHereYet>
    )
  }

  const getSafeHTML = (value: string | null) => {
    if (value && /^/.test(value)) return value
    return null
  }
  return (
    <Wrapper>
      {eventsToShow?.map((event) => {
        const trustedHTML = getSafeHTML(event.notes)
        return (
          <EventItem key={event.title + event.created_date}>
            <EventIconWrapper>
              <EventIcon event={event} minDisplay />
            </EventIconWrapper>
            <TextCol>
              <TitleRow>
                <TitleText>{event.title}</TitleText>
                <TimeStamp>{moment(event.created_date).format('LLL')} </TimeStamp>
              </TitleRow>
              {trustedHTML ? (
                <NotesText dangerouslySetInnerHTML={{ __html: trustedHTML }} />
              ) : (
                <NotesText>{event.notes}</NotesText>
              )}
            </TextCol>
          </EventItem>
        )
      })}
      {showExpanded ? (
        <ShowExpandedLink onClick={toggleShowExpanded}>{translate('Show Less')}</ShowExpandedLink>
      ) : (
        <>
          {props.events?.length > INITIAL_ROW_LIMIT && (
            <ShowExpandedLink onClick={toggleShowExpanded}>{translate('Show More')}</ShowExpandedLink>
          )}
        </>
      )}
      {showTooManyRowsWarning && (
        <Alert severity="warning">
          {translate('This list of events has been shortened. You can see the complete list in the Manage page')}
        </Alert>
      )}
    </Wrapper>
  )
}
export default CashFlowEventTable
