import { styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'pages/ordering/checkoutv3/preOrder/styles'
import React, { ReactNode } from 'react'

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '40px 24px',
})

const Icon = styled('div')({
  width: 25,
  height: 'unset',
  padding: 15,
  backgroundColor: COLOR_PALETTE.grey2,
  color: COLOR_PALETTE.darkGrey,
  borderRadius: 8,
})

const MainText = styled('p')({
  fontSize: 16,
  fontWeight: 550,
  color: COLOR_PALETTE.darkGrey,
})

const SubText = styled('p')({
  fontSize: 14,
  color: COLOR_PALETTE.darkGrey,
})

const NoticeIcon = styled('div', { name: 'NoticeIcon' })(({ theme }) => ({
  width: 25,
  height: 'unset',
  padding: 15,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.grey[810],
  borderRadius: 8,
}))

interface PropTypes {
  icon?: ReactNode
  mainText?: string
  subText?: string | ReactNode
}

const Notice: React.FC<PropTypes> = ({ icon, mainText, subText }) => {
  if (!icon && !mainText && !subText) return null
  return (
    <Wrapper>
      {icon && <NoticeIcon>{icon}</NoticeIcon>}
      {mainText && <MainText>{mainText}</MainText>}
      {subText && <>{typeof subText === 'string' ? <SubText>{subText}</SubText> : subText}</>}
    </Wrapper>
  )
}
export default Notice
