import CircularProgress from '@material-ui/core/CircularProgress'
import { authSelectors } from 'ducks/auth'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
} from 'opensolar-ui'
import { stringify } from 'query-string'
import React from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ActionType, EventType } from 'types/tracking'
import { useProjectColumns } from '../useProjectColumns'
import { ListColumnBase } from '../views/utils'

const API_URL = window.API_ROOT + '/api'

const StyledFormControlWrapper = styled('div', { name: 'StyledFormControlWrapper' })(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch',
  overflow: 'hidden',
  rowGap: 10,
  marginBottom: 12,
  padding: 2,
}))

const StyledForm = styled('div', { name: 'StyledForm' })(({ theme }) => ({
  padding: 24,
}))

const StyledFormLabel = styled(FormLabel, { name: 'StyledFormLabel' })(({ theme }) => ({
  fontSize: 16,
  marginBottom: 12,
}))

const StyledFormControlLabel = styled(FormControlLabel, { name: 'StyledFormControlLabel' })(({ theme }) => ({
  marginLeft: 1,
}))

const StyledCircularProgress = styled(CircularProgress, { name: 'StyledCircularProgress' })(({ theme }) => ({
  marginRight: 10,
}))

export type ExportReportsDialogPropTypes = {
  showDialog: boolean
  orgId: number | undefined
  setShowDialog: Function
  filterValues: object
  savedColData: undefined | ListColumnBase[]
}

const restClientInstance = restClient(API_URL)

const COLUMN_TO_FIELD_MAP = {
  starred: 'stars',
  address: 'address',
  id: 'id',
  contact: 'contacts_data',
  stage: 'stage',
  priority: 'priority',
  assignee: 'assigned_role_data',
  project_type: 'is_residential',
  lead_source: 'lead_source',
  tags: 'tags_data',
  notes: 'notes',
  created_date: 'created_date',
  modified_date: 'modified_date',
}

const fieldListFromColumns = (columns: ListColumnBase[]): string => {
  return columns
    .filter((column) => column.id in COLUMN_TO_FIELD_MAP && column.enabled)
    .map((column) => COLUMN_TO_FIELD_MAP[column.id])
    .join(',')
}

enum ProjectOptions {
  CurrentView = 'current_view',
  All = 'all',
}

enum ColumnOptions {
  CurrentView = 'current_view',
  All = 'all',
}

const ExportReportsDialog: React.FunctionComponent<ExportReportsDialogPropTypes> = (props) => {
  const { showDialog, setShowDialog, orgId, savedColData, filterValues } = props
  const [selectedProjects, setSelectedProjects] = React.useState<ProjectOptions>(ProjectOptions.CurrentView)
  const [selectedColumns, setSelectedColumns] = React.useState<ColumnOptions>(ColumnOptions.CurrentView)
  const translate = useTranslate()
  const notify = useNotify()
  const [loading, setLoading] = React.useState(false)
  const { trackEvent } = useTrackComponent({
    componentKey: 'project_header',
    eventName: 'Used Header Buttons',
  })
  const { columns } = useProjectColumns(savedColData)
  const isAdmin = useSelector(authSelectors.getIsAdmin)

  if (!showDialog) {
    return null
  }

  const onClose = () => {
    setShowDialog(false)
  }

  const handleSubmit = () => {
    let filter = {}

    if (selectedProjects === 'current_view') {
      filter = { ...filter, ...filterValues }
    }

    if (selectedColumns === 'current_view') {
      filter['fields'] = fieldListFromColumns(columns)
    } else {
      filter['fieldset'] = 'csv'
    }

    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.CLICK },
      { eventName: 'User Exported Projects Data to CSV' }
    )

    setLoading(true)

    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/projects/export_list/?${stringify(filter)}`,
    })
      .then((response: any) => {
        setLoading(false)
        setShowDialog(false)
        notify('Your export has been queued.', 'success')
      })
      .catch((err: any) => {
        setLoading(false)
        setShowDialog(false)
        notify('An error occurred preparing your export. Please try again later.', 'error')
      })
  }

  const allColumnsOptionLabel = isAdmin ? 'All projects data' : 'All projects data (Admin only)'

  return (
    <Dialog open={showDialog} onClose={onClose} aria-labelledby="alert-dialog-title" fullWidth={true} maxWidth="sm">
      <DialogTitle id="alert-dialog-title">{translate('Export Report')}</DialogTitle>
      <DialogContent>
        <StyledForm>
          <StyledFormControlWrapper>
            <FormControl>
              <StyledFormLabel>{translate('Select projects')}</StyledFormLabel>
              <RadioGroup
                name="projects"
                value={selectedProjects}
                onChange={(e) => setSelectedProjects(e.target.value as ProjectOptions)}
              >
                <StyledFormControlLabel label={translate('Current view')} control={<Radio />} value="current_view" />

                <StyledFormControlLabel label={translate('All projects')} control={<Radio />} value="all" />
              </RadioGroup>
            </FormControl>
          </StyledFormControlWrapper>
          <StyledFormControlWrapper>
            <FormControl>
              <StyledFormLabel>{translate('Select data')}</StyledFormLabel>
              <RadioGroup
                name="data"
                value={selectedColumns}
                onChange={(e) => setSelectedColumns(e.target.value as ColumnOptions)}
              >
                <StyledFormControlLabel label={translate('Current view')} control={<Radio />} value="current_view" />

                <StyledFormControlLabel
                  label={translate(allColumnsOptionLabel)}
                  control={<Radio />}
                  value="all"
                  disabled={!isAdmin}
                />
              </RadioGroup>
            </FormControl>
          </StyledFormControlWrapper>

          <div>
            {translate(
              "The exported file will be saved to your organisation's Private Files. You'll get an email when your export is ready. This may take some time based on the amount of data to be exported."
            )}
          </div>
        </StyledForm>

        <DialogActions>
          <Button onClick={onClose} variant="contained" color="default" disabled={loading}>
            <span>{translate('Cancel')}</span>
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            type="submit"
            disabled={loading}
            color={loading ? 'default' : 'primary'}
          >
            {loading && <StyledCircularProgress size={18} thickness={2} />}
            <span>{translate('Export')}</span>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default ExportReportsDialog
