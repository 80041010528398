import { AccordionSummary } from '@material-ui/core'
import { ContractIcon, styled } from 'opensolar-ui'
import { doNotTranslate } from 'util/misc'

const AccordionSummaryCentered = styled(AccordionSummary)({
  alignItems: 'center',
  justifyContent: 'space-between',
})
const HeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const HeaderText = styled('div')({
  margin: '0 10px',
})

const SolarAppAccordionSummary = () => (
  <AccordionSummaryCentered aria-controls="expansion-content-solarapp" id="expansion-header-solarapp">
    <HeaderContainer>
      <ContractIcon />
      <HeaderText>{doNotTranslate('Permitting')}</HeaderText>
    </HeaderContainer>
  </AccordionSummaryCentered>
)

export default SolarAppAccordionSummary
