import { styled } from '@mui/material/styles'
import { ArrowLeftSmIcon, ArrowRightSmIcon, Grid, IconButton, Typography } from 'opensolar-ui'
import React from 'react'
import { NavLink } from 'react-router-dom'
import ProductTile from '../catalogv3/ProductTile'

interface CarouselProductGridProps {
  componentsData: any[]
  carouselMode: boolean
  title?: string
  viewAllLink?: string
}

const CarouselContainer = styled('div')(() => ({
  position: 'relative',
  padding: '16px 0',
}))

const ScrollContainer = styled('div')<{ carouselMode: boolean }>(({ carouselMode }) => ({
  overflowX: carouselMode ? 'hidden' : 'visible',
  overflowY: 'hidden',
  whiteSpace: carouselMode ? 'nowrap' : 'normal',
  scrollbarWidth: 'none',
}))

const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  '&:visited, &:link': {
    color: '#1751D0',
  },
}))

const ProductGrid = ({ title, componentsData, carouselMode, viewAllLink }: CarouselProductGridProps) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null)

  const scroll = (direction: 'left' | 'right') => {
    const container = scrollContainerRef.current
    if (container) {
      const scrollAmount = container.clientWidth
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  return (
    <CarouselContainer>
      {carouselMode && componentsData?.length > 0 && (
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <TitleContainer>
              {title && <Typography textVariant="h5">{title}</Typography>}
              {viewAllLink && (
                <Typography variant="body1" colorHex="#1751D0">
                  <StyledNavLink to={viewAllLink}> View All </StyledNavLink>
                </Typography>
              )}
            </TitleContainer>
          </Grid>
          <Grid item>
            <IconButton onClick={() => scroll('left')}>
              <ArrowLeftSmIcon />
            </IconButton>
            <IconButton onClick={() => scroll('right')}>
              <ArrowRightSmIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <ScrollContainer ref={scrollContainerRef} carouselMode={carouselMode}>
        <Grid container spacing={3} wrap={carouselMode ? 'nowrap' : 'wrap'}>
          {componentsData?.map((data) => (
            <Grid item key={data.id}>
              <ProductTile component={data} />
            </Grid>
          ))}
        </Grid>
      </ScrollContainer>
    </CarouselContainer>
  )
}

export default ProductGrid
