var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var MessageCircleIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
        stroke: color,
    };
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg" }, rest, iconAttributes, { viewBox: "0 0 19 18", fill: "none", children: _jsx("path", { d: "M17.069 12.2316C17.5058 11.2434 17.7485 10.15 17.7485 9C17.7485 4.58172 14.1671 1 9.74924 1C5.33138 1 1.75 4.58172 1.75 9C1.75 13.4183 5.33138 17 9.74924 17C11.1716 17 12.5073 16.6287 13.6646 15.9778L17.75 16.9992L17.069 12.2316Z", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};
