import { Tooltip } from '@material-ui/core'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined'
// import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'
import { SEND_ERROR_MESSAGE } from 'myenergy/mainContent/components/proposalV2/ProposalV2'
import { Box, Button, Chip, ComponentVersionsInherit, styled, Typography } from 'opensolar-ui'
import { useTemplateLibraryModal } from 'projectSections/sections/proposal/templateLibrary'
import useSendProposalButton from 'projectSections/sections/proposal/useSendProposalButton'
import useViewAsCustomer from 'projectSections/sections/proposal/useViewAsCustomer'
import { useTranslate } from 'ra-core'
import React from 'react'

const ActionBarContainer = styled(Box)({
  alignItems: 'center',
  background: '#FFF',
  borderBottom: '1px solid #ECECED',
  display: 'flex',
  justifyContent: 'space-between',
})

const ActionButton = styled(Button)({
  borderLeft: '1px solid #ECECED',
  borderRadius: 0,
  color: '#4C4B53',
  padding: '12px 16px',
  '&:disabled': {
    color: '#E6E6E6',
  },
})

type PropTypes = {
  proposalTitle: string
}

const ProposalV2ActionBar: React.FC<PropTypes> = ({ proposalTitle }) => {
  const translate = useTranslate()
  const { disableButton, errorMessage, onSend } = useSendProposalButton({
    unsavedChangesErrorMessage: translate(SEND_ERROR_MESSAGE),
  })
  const { toggleViewAsCustomer } = useViewAsCustomer()
  const { setOpen: setOpenTemplateLibrary } = useTemplateLibraryModal()

  return (
    <>
      <ComponentVersionsInherit versions={{ chip: 2, button: 3, typography: 2 }}>
        <ActionBarContainer>
          <Box alignItems="center" display="flex" gridGap={8} padding="8px 20px">
            <Typography colorHex="#4C4B53" variant="body2">
              {proposalTitle}
            </Typography>
            <Chip label="Default" />
          </Box>
          <Box>
            <Tooltip title={translate('Open Template Library')}>
              <ActionButton
                color="default"
                startIcon={<DescriptionOutlinedIcon />}
                variant="text"
                onClick={setOpenTemplateLibrary}
              >
                <Typography colorHex="#4C4B53" variant="body2">
                  {translate('Templates')}
                </Typography>
              </ActionButton>
            </Tooltip>
            {/* <Tooltip title="Save Changes">
              <ActionButton color="default" startIcon={<SaveOutlinedIcon />} variant="text">
                <Typography colorHex="#4C4B53" variant="body2">
                  Save
                </Typography>
              </ActionButton>
            </Tooltip> */}
            <Tooltip title={translate('Switch to live mode')}>
              <ActionButton
                color="default"
                onClick={() => {
                  toggleViewAsCustomer(true)
                }}
                startIcon={<PlayArrowOutlinedIcon />}
                variant="text"
              >
                <Typography colorHex="#4C4B53" variant="body2">
                  {translate('Live')}
                </Typography>
              </ActionButton>
            </Tooltip>
            <Tooltip title={disableButton ? errorMessage || '' : translate('Send Proposal')}>
              <span>
                <ActionButton
                  color="default"
                  disabled={disableButton}
                  onClick={onSend}
                  startIcon={<SendOutlinedIcon />}
                  variant="text"
                >
                  <Typography colorHex={disableButton ? '#E6E6E6' : '#4C4B53'} variant="body2">
                    {translate('Send')}
                  </Typography>
                </ActionButton>
              </span>
            </Tooltip>
          </Box>
        </ActionBarContainer>
      </ComponentVersionsInherit>
    </>
  )
}

export default ProposalV2ActionBar
