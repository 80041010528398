import { DialogActions, DialogContent } from '@material-ui/core'
import { Button, Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { SimpleInvoiceType } from './types'

type PropTypes = {
  nextPage: () => void
  previousPage: () => void
  invoice: SimpleInvoiceType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  pdfContainer: {
    minHeight: '600px',
    height: '100%',
    width: '100%',
  },
}))

const HeaderWrapper = styled('div')({
  margin: '11px 0',
})

const ActionsRow = styled('div')(({ theme }) => ({
  padding: '10px',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.grey[300],
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}))

const ContentWrapper = styled('div')({
  marginTop: '10px',
})

const BtnWrapper = styled('div')({
  padding: '0px 10px',
})

const PreviewCustomInvoice: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <DialogContent>
        <div>
          <HeaderWrapper>
            <Typography textVariant="subtitle1">{translate('Preview Invoice')}</Typography>
          </HeaderWrapper>
          <Typography textVariant="body1">
            {translate(
              'Ensure the invoice appears exactly as you want it to. Hit the back button below to make edits.'
            )}
          </Typography>

          {/* IT: we'll have to take the PDF URL as a prop and display it here. If invoice PDFs can be multiple pages use TemplateWizardPDFDisplay as an example of where we handle paginated PDF rendering */}
          {/* RK: I chose not to use the TemplateWizardPDFDisplay because it non-configurably comes with all the drag+drop UI.
          Going to use a minimal browser-native PDF viewer for now. */}
          <ContentWrapper>
            <object className={classes.pdfContainer} data={props.invoice.download_url} type="application/pdf">
              <p>
                {/* alt text: */}
                <a href={`${props.invoice.download_url}#toolbar=0`} target="_blank" rel="noopener noreferrer">
                  {translate('Download PDF here')}
                </a>
              </p>
            </object>
          </ContentWrapper>
        </div>
      </DialogContent>
      <DialogActions>
        <ActionsRow>
          <BtnWrapper>
            <Button onClick={props.previousPage}>{translate('Back')}</Button>
          </BtnWrapper>
          <BtnWrapper>
            <Button onClick={props.nextPage} variant="contained" color="primary">
              {translate('Next')}
            </Button>
          </BtnWrapper>
        </ActionsRow>
      </DialogActions>
    </>
  )
}
export default PreviewCustomInvoice
