import { UiSwitch } from 'elements/UiSwitch'
import useHostConfig from 'host/useHostConfig'
import withMediaQuery from 'layout/withMediaQuery'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { usePermissions } from 'react-admin'
import compose from 'recompose/compose'
import { setAuthRedirection } from 'redirections/authRedirectionStore'
import { useSdkSingletonReady } from 'sdk/useSdkSingletonReady'
import { saveQueryVariable } from 'util/query'
import AuthModalWrapper from './AuthModalWrapper'
import LoginModal from './LoginModal'
import LoginPage from './LoginWizardPage'
import RegisterPage from './RegisterPage'

const PageRegister = (props) => {
  const { permissions } = usePermissions()
  const sdk = useSdkSingletonReady()

  return (
    <AuthModalWrapper>
      <PageRegisterInside {...props} permissions={permissions} loginConfigsOverride={sdk?.getLoginOverrides()} />
    </AuthModalWrapper>
  )
}

// TODO: separate login and register and login-modal
class PageRegisterInside extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname.startsWith('/redirect-to-login-with-nearmap')) {
      console.log('redirect-to-login-with-nearmap')
      saveQueryVariable('is_lite')
      window.location.href = window.API_BASE_URL + 'nearmap/login/'
      return
    }
    const title = this.props.match.params.name
      ? 'OpenSolar | ' + this.props.match.params.name.charAt(0).toUpperCase() + this.props.match.params.name.slice(1)
      : 'OpenSolar'
    document.title = title
  }

  componentDidMount() {
    const { location } = this.props
    const deepLink = location.state?.nextPathname + location.state?.nextSearch
    // Dangerous: this could override all existing redirect logic
    // ONLY transition to this approach when deepLink is set

    if (deepLink) {
      setAuthRedirection({
        type: 'AUTH_SUCCESS_REDIRECT',
        redirect: deepLink,
      })
    }
  }

  render() {
    const { location } = this.props
    const pathname = location.pathname
    const urlFragment = this.props.match.params[0]
    const hostConfig = useHostConfig()
    const loginConfigsOverride = this.props.loginConfigsOverride

    // useMemo when convert this component to FC
    // const getLoginConfigs = () => {
    //   if (theme === 'nearmap' && hostConfig.loginConfig.sso !== undefined) {
    //     const newSsoConfig = { ...hostConfig.loginConfig.sso, showBrandingLogin: true }
    //     return [{ ...hostConfig.loginConfig, sso: newSsoConfig }]
    //   }
    //   return hostConfig.loginConfigs
    // }

    if (pathname.startsWith('/login-modal')) {
      return <LoginModal urlFragment={urlFragment} />
    }

    if (pathname.startsWith('/register')) {
      return (
        <UiSwitch uiKey={'account_modal.register'}>
          <RegisterPage urlFragment={urlFragment} />
        </UiSwitch>
      )
    }

    if (pathname.startsWith('/login')) {
      return <LoginPage loginConfigs={loginConfigsOverride || hostConfig.loginConfigs} urlFragment={urlFragment} />
    }

    return null
  }
}

PageRegister.propTypes = {
  previousRoute: PropTypes.string,
  theme: PropTypes.string.isRequired,
}

const enhance = compose(withMediaQuery)

export default enhance(PageRegister)
