import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { FormRouteInfoPropTypes } from '../types'

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: 10,
  marginBottom: 16,
})

const BusinessInfoTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()

  return (
    <div data-testid="bluesnap-business-info-top">
      <Container>
        <Typography textVariant="h5SemiBold">{translate('Tell us more about your business')}</Typography>
        <Typography textVariant="body1">
          {translate('Confirming your business identity helps us to better protect you and our OpenSolar community.')}
        </Typography>
      </Container>
    </div>
  )
}
export default BusinessInfoTop
