import { Grid, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { AddOutlined, Info, MoreVert, SaveOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import { useGetPspOnboardingData } from 'elements/paymentsOnboarding/utils'
import { Button, styled } from 'opensolar-ui'
import { NEW_MILESTONE_PLACEHOLDER } from 'pages/cashFlow/constants'
import { CashFlowConfiguration } from 'pages/cashFlow/types'
import React, { useCallback, useState } from 'react'
import { BooleanInput, TextInput, useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { getDefaultPaymentMethodsForCountry, sanitizeCashFlowConfigForm } from '../utils'
import MilestoneFields from './MilestoneFields'

type PropTypes = {
  onSave: Function
  isLoading: boolean
  setIsUpdated?: (val: boolean | undefined) => void
  setNewConfig?: (val: CashFlowConfiguration | undefined) => void
  setNewConfigVals?: (val) => void
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const PageWrapper = styled('div')({
  marginBottom: '60px',
})

const Container = styled('div')({
  background: 'white',
  padding: '24px',
  border: '1px solid #e7e7e7',
  borderRadius: '5px',
  margin: '1rem 0',
})

const StyledCustomField = styled(CustomField)({
  margin: 0,
})

const StyledGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const FlexRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '10px 30px',
  position: 'fixed',
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: 'white',
  zIndex: 500,
  boxShadow: `0px -2px 2px #F6F6F6`,
})

const ButtonsWrapper = styled('div')({
  backgroundColor: 'white',
  margin: '10px 20px',
})

const ButtonMilestone = styled('div')({
  backgroundColor: 'white',
  marginTop: '20px',
})

const StyledInfoIcon = styled(Info)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.grey[700],
}))

const StyledCustomSwitchField = styled(CustomField)({
  '& .MuiFormControlLabel-root': {
    flexFlow: 'row',
    marginRight: 10,
  },
})

const ConfigurationForm: React.FC<PropTypes> = (props) => {
  const formValues = useFormState().values
  const form = useForm()
  const translate = useTranslate()
  const notify = useNotify()

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const orgCountry = useSelector(orgSelectors.getOrg)?.country?.iso2
  const bluesnapOnboardingData = useGetPspOnboardingData('bluesnap')
  const businessState = bluesnapOnboardingData?.application_data?.['businessInfo']?.['state'] || ''
  const existingMilestones = formValues.payment_milestone_configurations
  //const [exampleSystemPrice, setExampleSystemPrice] = useState<number>()
  //const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  const onClick = useCallback(() => {
    props.setNewConfigVals?.(formValues)
    props.onSave(formValues)
  }, [formValues])

  const addMilestone = useCallback(() => {
    let defaultPaymentMethods = orgCountry ? getDefaultPaymentMethodsForCountry(orgCountry, false, businessState) : []
    let currentMilestones = existingMilestones ? [...existingMilestones] : []
    let nextMilestone = NEW_MILESTONE_PLACEHOLDER
    nextMilestone.payment_number = currentMilestones?.length + 1
    nextMilestone.accepted_payment_methods = defaultPaymentMethods
    currentMilestones.push(nextMilestone)
    form.change('payment_milestone_configurations', currentMilestones)
  }, [existingMilestones, businessState])

  const isArchived = formValues.is_archived
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleCopyScheme = () => {
    const title = formValues.title
    const data = {
      title: `${title} (${translate('Copy')})`,
      payment_milestone_configurations: formValues.payment_milestone_configurations,
    }

    if (orgId) {
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/payments/configuration/`,
        data: sanitizeCashFlowConfigForm(data),
      })
        .then((res) => {
          props.setIsUpdated?.(true)
          props.setNewConfig?.(res.data)
          notify(translate(title + ' copy created', 'success'))
        })
        .catch((err) => {
          if (err?.body?.message) notify(err.body.message, 'warning')
          else notify(translate('Something went wrong'), 'error')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  // useEffect(() => {
  //   restClientInstance('CUSTOM_POST', 'custom', {
  //     url: `/api/orgs/${orgId}/payments/sample_milestone_calcs/ `,
  //     data: { form_vals: formValues },
  //   })
  //     .then((res) => {
  //       console.log(res, 're')
  //     })
  //     .catch((err) => {})
  // }, [existingMilestones, exampleSystemPrice])

  return (
    <PageWrapper>
      <Container>
        <Grid container spacing={1}>
          <StyledGrid item xs={12}>
            <Grid item xs={7}>
              <StyledCustomField
                label="Title"
                component={TextInput}
                name="title"
                source="title"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleOpenMenu}
            >
              {!isArchived && <MoreVert />}
            </IconButton>

            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleCopyScheme}>Make a copy</MenuItem>
            </Menu>
          </StyledGrid>
          <Grid item xs={10}>
            <StyledCustomField
              label="Description"
              component={TextInput}
              name="description"
              variant="outlined"
              source="description"
              fullWidth
            />
          </Grid>
          <Grid item xs={10}>
            <FlexRow>
              <StyledCustomSwitchField
                label="Auto-Apply?"
                component={BooleanInput}
                name="auto_apply"
                source="auto_apply"
              />
              <span>
                <Tooltip
                  title="Auto-Apply makes this Payment Milestone Scheme the default for cash payment options. At least one Payment Milestone Scheme must be set to Auto-Apply."
                  enterDelay={300}
                >
                  <StyledInfoIcon />
                </Tooltip>
              </span>
            </FlexRow>
          </Grid>
          <Grid item xs={10}>
            <FlexRow>
              <StyledCustomSwitchField
                label="Is Archived"
                component={BooleanInput}
                name="is_archived"
                source="is_archived"
              />
            </FlexRow>
          </Grid>
        </Grid>
      </Container>

      {/* commenting out the sample calcs since this won't be included in the CashFlow beta, but it will be included in the following release */}
      {/* <div className={classes.container}>
        <h2 className={classes.containerHeader}>{translate('Preview')}</h2>
        <div className={classes.row}>
          <p>{translate('Example system price')}:</p>
          <CustomField
            component={TextInput}
            name="example_system_price"
            variant="outlined"
            size="small"
            label=""
            value={exampleSystemPrice}
            onKeyUp={(e) => setExampleSystemPrice(e.target.value)}
          />
        </div>

        <Stepper activeStep={0} alternativeLabel>
          {existingMilestones?.map((milestone) => (
            <Step key={milestone.id}>
              <StepLabel>
                <p className={classes.priceLabel}>{milestone.title}</p>
                <p className={classes.price}>{currencySymbol} 0</p>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div> */}

      {existingMilestones?.map((milestone, i) => (
        <MilestoneFields paymentIndex={i} key={i} businessState={businessState} />
      ))}

      <ButtonMilestone>
        <Button disabled={props.isLoading || existingMilestones.length >= 5} onClick={addMilestone} variant="contained">
          <AddOutlined /> {translate('Add Milestone')}
        </Button>
      </ButtonMilestone>

      <ButtonsContainer>
        <ButtonsWrapper>
          <Button disabled={props.isLoading} onClick={onClick} variant="contained" color="primary" type="submit">
            <SaveOutlined /> {translate('Save')}
          </Button>
        </ButtonsWrapper>
      </ButtonsContainer>
    </PageWrapper>
  )
}
export default ConfigurationForm
