import {
  ContiguousPanelRow,
  HasPosition,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  PanelRow,
  PanelWithPosition,
} from '../types'
import { addItems, createMountingItem } from '../utils'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'
import { getContiguousRows } from './PerRowBomGeneratorAbstract'

export abstract class PerRowClampBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract chooseMidClamp(row: ContiguousPanelRow): Item
  abstract chooseEndClamp(row: ContiguousPanelRow): Item
  abstract getClampPositions(input: MountingCalcInput, panel: PanelWithPosition): HasPosition[]

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    return this.block.rows
      .flatMap(getContiguousRows)
      .reduce((resultFromLastRow, row) => this.generateBomForRow(resultFromLastRow, row), result)
  }

  generateBomForRow(resultFromLastRow: MountingCalcResult, row: PanelRow): MountingCalcResult {
    const resultWithMidClamps = this.addMidClamps(row, resultFromLastRow)
    const resultWithEndClamps = this.addEndClamps(row, resultWithMidClamps)
    return resultWithEndClamps
  }

  addMidClamps(row: ContiguousPanelRow, result: MountingCalcResult): MountingCalcResult {
    if (row.length < 2) return result

    const midClamps: Item[] = []
    const baseClamp = this.chooseMidClamp(row)
    const panelWidth = this.block.panel.width
    const offset = (row[1].left - (row[0].left + panelWidth)) / 2 // half the gap between panels, to position clamps right in the middle

    for (let index = 1; index < row.length; index++) {
      // Start with the second panel
      const left = row[index].left - offset // Halfway point between the panels
      midClamps.push(
        ...this.getClampPositions(this.input, row[0]).map(
          ({ top }) => createMountingItem(baseClamp, { top, left, blockIndex: this.blockIndex }) // Add the clamp at the right position
        )
      )
    }

    return addItems(result, midClamps)
  }

  addEndClamps(row: ContiguousPanelRow, result: MountingCalcResult): MountingCalcResult {
    if (!row.length) return result

    const endClamps: Item[] = []
    const baseClamp = this.chooseEndClamp(row)

    const rowStart = row[0].left
    const rowEnd = getRowEnd(row)

    this.getClampPositions(this.input, row[0]).forEach(({ top }) => {
      endClamps.push(
        createMountingItem(baseClamp, { top, left: rowStart, blockIndex: this.blockIndex }),
        createMountingItem(baseClamp, { top, left: rowEnd, blockIndex: this.blockIndex })
      )
    })

    return addItems(result, endClamps)
  }
}

function getRowEnd(row: ContiguousPanelRow) {
  return row[row.length - 1].left + row[row.length - 1].width
}
