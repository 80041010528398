import { setSelectComponentDialog } from 'ducks/selectComponent'
import { BATTERY_COMPATIBILITY_FILTER_KEY } from 'elements/hardwareFilter/batteryCompatibility/BatteryCompatibilityFilterNodeFactory'
import { getCompatibleCodesForSystem } from 'elements/hardwareSelector/SearchToolbar'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import { ReactNode, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { StudioBatteryType } from 'types/global'
import { useFeatureFlag } from 'util/split'
import { ExternalLink } from '../../PanelSystem'
import ComponentWarningBox from '../../warning/ComponentWarningBox'
import { ComponentSelectorDropdown } from '../common/ComponentSelectorDropdown'
import { RowButtons } from '../ComponentCollection'
interface BatteryBlockPropsType extends WithHardwareSelectorV2Props {
  item: StudioBatteryType
  disabled: boolean
  dragHandle: ReactNode | undefined
}

const BatteryBlock: React.FC<BatteryBlockPropsType> = ({
  disabled = false,
  dragHandle,
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
  item: battery,
}) => {
  const dispatch = useDispatch()
  const enableHardwareFilterV2 = useFeatureFlag('hardware_filter_v2', 'on')

  const filtersToOverride = useMemo(() => {
    const compatibleCodes = getCompatibleCodesForSystem('battery')
    if (!enableHardwareFilterV2) {
      return [
        {
          key: 'compatibility',
          value: compatibleCodes.length === 0 ? 'all' : 'true',
        },
      ]
    }
    if (compatibleCodes.length) {
      return [
        {
          key: BATTERY_COMPATIBILITY_FILTER_KEY,
          value: compatibleCodes.join(','),
        },
      ]
    }
  }, [enableHardwareFilterV2]) // also need to detect if the system has changed

  const handleSelect = () => {
    if (enableHardwareDialogV2)
      handleOpenHardwareSelector({
        title: 'Select Battery',
        componentTypes: ['battery'],
        targetUuids: [battery.uuid],
        defaultFilterValues: filtersToOverride?.reduce((filterValues, { key, value }) => {
          filterValues[key] = value
          return filterValues
        }, {}),
      })
    else
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper.project.org_id,
          window.WorkspaceHelper.project.id,
          [battery.uuid],
          'battery',
          filtersToOverride
        )
      )
  }

  return (
    <div>
      <div style={{ marginTop: '10px' }}>
        <RowButtons
          title={battery.code}
          componentSelector={
            <ComponentSelectorDropdown
              componentType="battery"
              component={window.AccountHelper.getBatteryById(battery.battery_id)}
              availableComponents={window.AccountHelper.getComponentBatterySpecsAvailable()}
              disabled={disabled}
              select={handleSelect}
              margin="dense"
              showLogo={false}
              roundLeft={true}
            />
          }
          component={battery}
          quantity={battery.quantity ?? 1}
          showQuantity={true}
          handleSelect={null}
          handleChoose={null}
          handleDelete={() => {
            window.editor.deleteObject(battery)
          }}
          allowZeroQuantity={true}
        />
        {dragHandle}
      </div>

      <ComponentWarningBox componentIds={[battery.uuid]} />
      <ExternalLink componentData={battery.getComponentData()} />
    </div>
  )
}

export default withHardwareSelectorV2(BatteryBlock)
