import { makeStyles, Theme } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { useNotify, useRefresh } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { AvailableFinanceIntegrationType } from 'types/orgs'
import { RootState } from 'types/state'
import { MANAGE_INTEGRATION_CONFIG_MAP } from '../integrationManagement/constants'
import ManageIntegrationButton from '../integrationManagement/ManageIntegrationButton'
import SetOrgConfigDialog from '../integrationManagement/SetOrgConfigDialog'
import RateChangeLink from '../rateChangeManagement/RateChangeLink'
import FinanceTypeChipsRow from './FinanceTypeChipsRow'
import ImportDialog from './ImportDialog'
import IntegrationBrandedButton from './IntegrationBrandedButton'
import IntegrationPromoImage from './IntegrationPromoImage'
import PromoDialog from './PromoDialog'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  integration: AvailableFinanceIntegrationType
}

const useStyles = makeStyles<Theme, { integration: AvailableFinanceIntegrationType }>(() => ({
  promoCard: {
    maxWidth: '100%',
    height: 'fit-content',
    // flexGrow: 1,
    border: '1px solid #EFEFEF',
    boxSizing: 'border-box',
    background: 'rgb(255, 255, 255)',
    borderRadius: 4,
    margin: '10px',
    padding: 15,
    flex: '1 1 0%',
    position: 'relative',
  },
  cardContentWrapper: {
    height: 35,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  individualButtonWrapper: {
    flex: 1,
    padding: '0px 5px',
  },
  refreshButtonRow: {
    display: 'flex',
    alignItems: 'center',
  },
  refreshButtonWrapper: {
    flex: 7,
  },
  buttonBase: {
    borderRadius: 4,
    height: 40,
    marginBottom: 10,
    marginTop: 20,
    width: '100%',
    backgroundColor: ({ integration }) => integration?.banner_content?.theme_color || '#ffda00',
    color: ({ integration }) => integration?.banner_content?.font_color || '#fff',
    borderWidth: '0px',
    '&:hover': {
      background: ({ integration }) => integration?.banner_content?.theme_color_dark || '#ffda00',
    },
  },
  iconWrapper: { position: 'absolute', right: '-10px', top: '-10px' },
}))

const PromoButton: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showImportDialog, setShowImportDialog] = useState<boolean>(false)
  const [showOrgConfigDialog, setShowOrgConfigDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const org = useSelector(orgSelectors.getOrg)
  const roleId = useSelector(authSelectors.getCurrentRole)?.id
  const isEnabled = useSelector((state: RootState) =>
    authSelectors.getIsGenericIntegrationActive(state, props.integration.integration_name)
  )
  const isActivationIntegrationOnly = ['checkout_finance'].includes(props.integration?.integration_name) // Includes integrations who doesn't have products but only a flag
  const shouldShowActivateButton = isActivationIntegrationOnly
  const shouldShowImportButton =
    !isActivationIntegrationOnly && props.integration.activation_type === 'immediately_available'

  const classes = useStyles({ integration: props.integration })
  const refreshList = useRefresh()
  const notify = useNotify()

  const onRefresh = async (refreshPage: boolean = false) => {
    if (await forceOrgConfigDialog()) {
      setShowOrgConfigDialog(true)
    } else {
      setLoading(true)
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${org?.id}/sync_payment_options/${props.integration.integration_name}/`,
        data: { role_id: roleId },
      })
        .then((response) => {
          logAmplitudeEvent('integrated_finance_products_refreshed', {
            integration: props.integration.integration_name,
          })
          if (refreshPage) {
            refreshList()
            const productsAdded = response.data.message.split(' ')[0]
            const activationOnly = response.data?.activation_only
            let newUrl = `${window.location.origin}${window.location.hash}?products_added=${productsAdded}&partner=${props.integration.integration_name}`
            if (activationOnly) {
              newUrl = `${window.location.origin}${window.location.hash}?activation_only=${activationOnly}&partner=${props.integration.integration_name}&message=${response.data.message}`
            }
            window.history.replaceState(null, '', newUrl)
            window.location.reload()
          } else {
            notify(response.data.message, 'success')
            refreshList()
            setShowImportDialog(false)
          }
        })
        .catch((error) => {
          logAmplitudeEvent('integrated_finance_products_refresh_error', {
            integration: props.integration.integration_name,
          })
          notify(error.message, 'warning')
        })
        .finally(() => setLoading(false))
    }
  }

  const forceOrgConfigDialog = async () => {
    if (props.integration?.integration_name === 'mosaic') {
      // Check to see if we have an existing OrgConfig for how Mosaic products should be imported
      const orgConfigResponse = await restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${org?.id}/configs/`,
      })
      // Force the OrgConfig dialog to appear if we haven't yet stored a mosaic_portal_import_type
      return !orgConfigResponse?.data?.org_configs?.mosaic_portal_import_type
    }
    return false
  }

  const onImportProducts = () => {
    if (props.integration.banner_content?.import_dialog_content) {
      setShowImportDialog(true)
    } else if (props.integration?.banner_content?.skip_step_3) {
      onRefresh(true)
    } else onRefresh()
  }

  return (
    <div id={props.integration.integration_name} className={classes.promoCard}>
      <div className={classes.cardContentWrapper}>
        <IntegrationPromoImage integration={props.integration} />
      </div>
      <FinanceTypeChipsRow
        supports_commercial={props.integration?.support_commercial}
        supports_residential={props.integration?.support_residential}
      />
      <div style={{ maxWidth: '100%' }}>
        {isEnabled ? (
          <div className={classes.refreshButtonRow}>
            <div className={classes.refreshButtonWrapper}>
              {isActivationIntegrationOnly ? (
                <IntegrationBrandedButton
                  integration={props.integration}
                  label="Learn More"
                  loading={loading}
                  onClick={() => setShowDialog(true)}
                />
              ) : (
                <IntegrationBrandedButton
                  integration={props.integration}
                  label="Refresh Products"
                  loading={loading}
                  onClick={onRefresh}
                />
              )}
            </div>

            {props.integration?.integration_name === 'sungage' && (
              <ManageIntegrationButton integration="sungage" onSuccess={onRefresh} />
            )}
            {props.integration?.integration_name === 'mosaic' && (
              <ManageIntegrationButton integration="mosaic" onSuccess={onRefresh} />
            )}
          </div>
        ) : (
          <div className={classes.buttonsWrapper}>
            {(shouldShowActivateButton || shouldShowImportButton) && (
              <div className={classes.individualButtonWrapper}>
                <IntegrationBrandedButton
                  integration={props.integration}
                  label={shouldShowActivateButton ? 'Activate' : 'Import Products'}
                  loading={loading}
                  onClick={onImportProducts}
                />
              </div>
            )}
            <div className={classes.individualButtonWrapper}>
              <IntegrationBrandedButton
                integration={props.integration}
                label="Learn More"
                loading={loading}
                onClick={() => setShowDialog(true)}
              />
            </div>
          </div>
        )}
      </div>
      {showDialog && (
        <PromoDialog integration={props.integration} onClose={() => setShowDialog(false)} isOpen={showDialog} isEnabled={isEnabled} />
      )}
      {showImportDialog && (
        <ImportDialog
          integration={props.integration}
          onClose={() => setShowImportDialog(false)}
          onRefresh={onRefresh}
        />
      )}
      {showOrgConfigDialog && (
        <SetOrgConfigDialog
          isOpen={showOrgConfigDialog}
          integrationMap={MANAGE_INTEGRATION_CONFIG_MAP[props.integration?.integration_name]}
          onClose={() => setShowOrgConfigDialog(false)}
          onSuccess={onRefresh}
        />
      )}
      {isEnabled && (
        <div className={classes.iconWrapper}>
          <CheckCircleIcon htmlColor="#ACE167" fontSize="large" />
        </div>
      )}
      {isEnabled && (
        <RateChangeLink
          service={{
            name: props.integration.integration_name,
            title: props.integration.display_name,
            logo: props.integration.logo_large_contents,
          }}
        />
      )}
    </div>
  )
}
export default PromoButton
