import { Dialog, DialogContent, DialogTitle, Paper } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { removeGreenLancerTokenFromRole } from 'ducks/auth_roles'
import { updateSelectedHardwareSupplier } from 'ducks/orderComponents'
import { orgsActions, orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import Alert from 'elements/Alert'
import { ControlBreadCrumbs } from 'elements/BreadCrumbs'
import CustomField from 'elements/field/CustomField'

import Button from 'elements/proUXButtons/ProUXButton'
import { LOANPAL_OR_GOODLEAP } from 'myenergy/selectionComponent/loanApplicationButton/loanpal/constants'
import { Button as CoreButton, Link } from 'opensolar-ui'
import { useEffect, useState } from 'react'
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  NotFound,
  PasswordInput,
  showNotification as showNotificationAction,
  SimpleForm,
  TextInput,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { connect, useDispatch, useSelector } from 'react-redux'
import DividendIntegrationSection from 'resources/integrations/dividend/DividendIntegrationSection'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { doNotTranslate, reloadEntireApp } from 'util/misc'
import { useSolarAppAvailable } from 'util/solarApp'
import { useFeatureFlag } from 'util/split'
import { authReload as authReloadAction } from '../../actions/authActions'
import { authSelectors } from '../../ducks/auth'
import GenericButton from '../../elements/button/GenericButton'
import Loading from '../../layout/widgets/FullScreenLoading'
import { handleOauthResultIfFound } from '../../util/oauth'
import { getOrgCountryCodeFromState } from '../../util/org'
import { formatSubmitValues, getDefaultSettings } from '../orgs'
import ConnectBrighteButton from './brighte/ConnectBrighteButton'
import GenericIntegrationAccountDialog from './GenericIntegrationAccountDialog'
import IntegrationOauthDialog from './IntegrationOauthDialog'
import LoanpalAccountDialog from './LoanpalAccountDialog'
import MosaicAccountDialog from './MosaicAccountDialog'
import ConnectPhoenixAccount from './phoenix/ConnextPhoenixAccount'
import SolarAppAccountDialog from './SolarAppAccountDialog'
import SungageAccountDialog from './SungageAccountDialog'

export const SUNWIZ_SUPPORT_COUNTRIES = ['AU', 'NZ']
const styles = {
  inputStyle: { width: 256 },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '20px',
    flexWrap: 'wrap',
  },
  centeredRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px 10px 0px',
  },
  rowItem: {
    flex: 1,
    minWidth: '300px',
  },
  reconnectLink: {
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    ':hover': {
      color: 'rgb(255, 218, 0)',
    },
  },
  buttonRowView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '30px 50px',
  },
}
const Subtitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate(
        'OpenSolar partners with select 3rd-party providers to offer integrations that benefit you and your customers. If you have an account with one of these 3rd-party providers, enter the API key here to get the benefits of these integrations. If you have any questions, please reach out to'
      )}{' '}
      <a href="mailto:support@opensolar.com">support@opensolar.com</a>.
      {/* <HelpLink href="https://support.opensolar.com/hc/en-us/articles/360028817911-How-to-Generate-Lead-Capture-Form" /> */}
    </span>
  )
}

const userFriendlyPartnerName = {
  docusign: 'Docusign',
  loanpal: 'Goodleap',
  brighte: 'Brighte',
}

const LEGACY_INTEGRATIONS = ['sunlight', 'sungage', 'mosaic', 'dividend', 'loanpal', 'phoenix', 'brighte']

const checkValueIsNotEmpty = (value) => value && value.length > 0
const _EditExternalKeys = (props) => {
  const { loading, permissions } = usePermissions()
  const [finishingOauth, setFinishingOauth] = useState(false)
  const [docusignLoading, setDocusignLoading] = useState(false)
  const [showDocusignDialog, setShowDocusignDialog] = useState(false)

  const [greenLancerLoading, setGreenLancerLoading] = useState(false)
  const [hideGreenLancer, setHideGreenLancer] = useState(false)
  const [showLoanpalDialog, setShowLoanpalDialog] = useState(false)
  const [loanpalDisconnectLoading, setLoanpalDisconnectLoading] = useState(false)
  const [sungageLoading, setSungageLoading] = useState(false)
  const [brighteLoading, setBrighteLoading] = useState(false)
  const [showSungageDialog, setShowSungageDialog] = useState(false)
  const [showMosaicDialog, setShowMosaicDialog] = useState(false)
  const [solarAppDisconnectLoading, setSolarAppDisconnectLoading] = useState(false)
  const [showSolarAppDialog, setShowSolarAppDialog] = useState(false)

  const showDocusignSandboxOption = window.location.href.includes('show_sandbox=true')

  const org = useSelector(orgSelectors.getOrg)
  const dispatch = useDispatch()
  const notify = useNotify()

  const { allowView, allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('integrations_and_api_keys'))

  const isStaff = useSelector(authSelectors.getIsStaff)
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const isAU = props.country_iso2 === 'AU'

  useEffect(() => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const code = params.get('code')
    if (!finishingOauth && (!props.docusign_account_connected || !!code)) {
      handleOauthResultIfFound(props.roleId, () => setFinishingOauth(true))
    }
  }, [props.docusign_account_connected, finishingOauth])

  useEffect(() => {
    if (showLoanpalDialog) logAmplitudeEvent('loanpal_account_dialog_opened', {})
  }, [showLoanpalDialog])

  //check url params for oauth success
  useEffect(() => {
    const result = props?.match?.params?.result
    const partner = props?.match?.params?.partner
    if (result === 'success' && !!partner && !!userFriendlyPartnerName[partner]) {
      window.Designer.showNotification(
        `Your ${userFriendlyPartnerName[partner]} account has been connected.`,
        'success'
      )
    } else if (result === 'error' && !!partner && !!userFriendlyPartnerName[partner]) {
      window.Designer.showNotification(`Unable to connect your ${userFriendlyPartnerName[partner]} account.`, 'danger')
    }
  }, [])

  const translate = useTranslate()
  const api_key_pvsell = props.location && props.location.state && props.location.state.api_key_pvsell
  const prePopulatedApi = api_key_pvsell && { api_key_pvsell }
  const enableEagleView = useFeatureFlag('eagleview', 'on')
  const enableSolarAPP = useSolarAppAvailable(props.country_iso2)
  const enableCityPlumbing = useFeatureFlag('city_plumbing', 'on')
  const enableHdm = useFeatureFlag('hdm', 'on')
  const enableVtac = useFeatureFlag('vtac', 'on')

  const disconnectdocusign = () => {
    setDocusignLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: 'orgs/' + org?.id + '/disconnect_docusign_account/',
    })
      .then((response) => {
        if (response.data?.success) {
          reloadEntireApp()
          logAmplitudeEvent('docusign_disconnected', {})
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => {
        setDocusignLoading(false)
      })
  }

  const startDocusignOauth = (e, forceSandbox = false) => {
    setDocusignLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    if (forceSandbox) {
      appStorage.setBool('docusign_force_sandbox', true)
    } else {
      appStorage.setBool('docusign_force_sandbox', false)
    }
    const url = forceSandbox
      ? 'orgs/' + org?.id + '/get_docusign_oauth_url/sandbox'
      : 'orgs/' + org?.id + '/get_docusign_oauth_url/'
    restClientInstance('CUSTOM_GET', 'custom', {
      url,
    })
      .then((response) => {
        logAmplitudeEvent('docusign_oauth_started', {})
        if (response && response.data && response.data.oauth_url) {
          window.location.href = response.data.oauth_url
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => {
        setDocusignLoading(false)
      })
  }

  const renderDocusignDisconnectDialog = () => {
    return (
      <Dialog open={showDocusignDialog} style={{ textAlign: 'center' }}>
        <DialogTitle>Disconnect your Docusign Account?</DialogTitle>
        <DialogContent>
          {translate(
            'Disconnecting your Docusign account will immediately remove the ability to request signatures via Docusign. Click "Disconnect" if you would like to proceed'
          )}
          <div style={styles.buttonRowView}>
            <GenericButton
              label={translate('Cancel')}
              onClick={() => setShowDocusignDialog(false)}
              style={{ marginRight: 10, marginTop: 10 }}
              labelWrapperStyle={{
                textTransform: 'none',
                // fontWeight: 'bold',
              }}
              variant="contained"
              color="default"
              disabled={docusignLoading}
              // backgroundColorOnHover={this.props.myeStyles.highlightColorInteraction}
              labelPosition="before"
            />
            <GenericButton
              label={translate('Disconnect')}
              onClick={disconnectdocusign}
              style={{ marginRight: 10, marginTop: 10 }}
              labelWrapperStyle={{
                textTransform: 'none',
                // fontWeight: 'bold',
              }}
              variant="contained"
              color="default"
              // backgroundColor="rgb(255, 218, 0)"
              disabled={docusignLoading}
              // backgroundColorOnHover={this.props.myeStyles.highlightColorInteraction}
              labelPosition="before"
            />
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  const renderConnectedDocusign = () => {
    return (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const disableKBA = formData?.docusign_config?.docusign_contract_sms_auth_enabled
          const disableSMS = formData?.docusign_config?.docusign_contract_kba_enabled
          return (
            <div>
              <span>
                {translate('Your Docusign account has been connected. You can configure this integration below.')}
              </span>
              <br />
              <Alert severity="info">
                {translate(
                  'To fully enable this feature you will need to have a custom contract template with Docusign tags added'
                )}
              </Alert>
              <div style={styles.row}>
                <CustomField
                  component={BooleanInput}
                  source="docusign_config.enable_contracts_in_docusign"
                  name="docusign_config.enable_contracts_in_docusign"
                  label="Use Docusign for Contracts"
                  defaultValue={false}
                  style={styles.rowItem}
                  {...props}
                />
                <CustomField
                  component={BooleanInput}
                  source="docusign_config.docusign_contract_sms_auth_enabled"
                  name="docusign_config.docusign_contract_sms_auth_enabled"
                  label="Enable SMS authentication for contracts"
                  onClick={(e) => {
                    if (e?.target?.checked) {
                      alert(
                        translate(
                          'Before activating this feature please confirm that SMS authentication is enabled on your Docusign account. Otherwise customers will not be able to access documents.'
                        )
                      )
                    }
                  }}
                  defaultValue={false}
                  style={styles.rowItem}
                  {...props}
                  disabled={disableSMS}
                />
                <CustomField
                  component={BooleanInput}
                  source="docusign_config.docusign_contract_kba_enabled"
                  name="docusign_config.docusign_contract_kba_enabled"
                  label="Enable Knowledge-Based-Authentication for contracts (US only)"
                  defaultValue={false}
                  style={styles.rowItem}
                  {...props}
                  disabled={disableKBA}
                />
              </div>
              <div style={styles.centeredRow}>
                <a onClick={() => setShowDocusignDialog(true)} style={styles.reconnectLink}>
                  <i>{translate('Disconnect your Docusign account')}</i>
                </a>
              </div>
              {renderDocusignDisconnectDialog()}
            </div>
          )
        }}
      </FormDataConsumer>
    )
  }

  const disconnectGreenLancer = () => {
    setGreenLancerLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + org?.id + '/roles/' + props.greenlancerRole.id + '/disconnect_greenlancer_account/',
    })
      .then((response) => {
        props.removeGreenLancerTokenFromRole(props.greenlancerRole.id)
        window.Designer.showNotification('Your GreenLancer account has been disconnected', 'success')
        setHideGreenLancer(true)
      })
      .catch((err) => {})
  }

  const startLoanpalDisconnect = () => {
    const confirmed = window.confirm(
      translate(
        `Are you sure you want to disconnect your ${LOANPAL_OR_GOODLEAP} account? Any existing proposals with ${LOANPAL_OR_GOODLEAP} payment options will no longer be able to apply for credit.`
      )
    )
    if (confirmed) {
      setLoanpalDisconnectLoading(true)
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + org?.id + '/disconnect_loanpal/',
      })
        .then((response) => {
          if (response.data?.success) {
            logAmplitudeEvent('loanpal_account_disconnected', {})
            let currentURL = window.location.href
            let urlWithoutParams =
              currentURL.indexOf('/success') > 0 ? currentURL.substring(0, currentURL.indexOf('/success')) : currentURL
            reloadEntireApp(urlWithoutParams)
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          setLoanpalDisconnectLoading(false)
        })
    }
  }

  const startMosaicDisconnect = () => {
    const confirmed = window.confirm(
      translate(
        'Are you sure you want to disconnect your Mosaic account? Any existing proposals with Mosaic payment options will no longer be able to apply for credit.'
      )
    )
    if (confirmed) {
      setLoanpalDisconnectLoading(true)
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + org?.id + '/disconnect_mosaic/',
      })
        .then((response) => {
          if (response.data?.success) {
            logAmplitudeEvent('mosaic_account_disconnected', {})
            let currentURL = window.location.href.replace('?show_disconnect=true', '')
            let urlWithoutParams =
              currentURL.indexOf('/success') > 0 ? currentURL.substring(0, currentURL.indexOf('/success')) : currentURL
            reloadEntireApp(urlWithoutParams)
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          setLoanpalDisconnectLoading(false)
        })
    }
  }

  const renderLoanpalChannels = () => {
    if (!isAdmin) return null
    if (!props.loanpalAccountConnected && !props.loanpalAccountShared) return null
    return (
      <CustomField
        component={BooleanInput}
        source="ignore_loanpal_channels"
        name="ignore_loanpal_channels"
        label="Ignore Goodleap Channels"
        defaultValue={false}
        style={styles.rowItem}
        {...props}
      />
    )
  }

  const renderConnectedLoanpal = () => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const showDisconnect = params.get('show_disconnect')
    if (showDisconnect) {
      return (
        <Button
          onClick={startLoanpalDisconnect}
          label={`Disconnect your ${LOANPAL_OR_GOODLEAP} Account`}
          type="secondary"
          style={{ width: '400px' }}
          loading={loanpalDisconnectLoading}
        />
      )
    } else {
      return (
        <div style={{ marginBottom: '30px' }}>
          <p>{translate('Your account has been successfully connected')}</p>
        </div>
      )
    }
  }

  const renderConnectedMosaic = () => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const showDisconnect = params.get('show_disconnect')
    if (showDisconnect) {
      return (
        <Button onClick={startMosaicDisconnect} label="Disconnect Mosaic" type="secondary" style={{ width: '400px' }} />
      )
    } else {
      return <div>Your Mosaic integration has been activated</div>
    }
  }

  const renderConnectedBrighte = () => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const showDisconnect = params.get('show_disconnect')
    if (showDisconnect) {
      return (
        <Button
          onClick={startBrighteDisconnect}
          label="Disconnect Brighte"
          type="secondary"
          style={{ width: '400px' }}
          loading={brighteLoading}
        />
      )
    } else {
      return <p>Your Brighte account has been connected!</p>
    }
  }

  const startBrighteDisconnect = () => {
    const confirmed = window.confirm(
      translate(
        'Are you sure you want to disconnect your Brighte account? Any existing proposals with Brighte payment options will no longer be able to apply for credit.'
      )
    )
    if (confirmed) {
      setBrighteLoading(true)
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + org?.id + '/roles/' + props.roleId + '/disconnect_brighte/',
      })
        .then((response) => {
          if (response.data?.success) {
            logAmplitudeEvent('brighte_account_disconnected', {})
            let currentURL = window.location.href.replace('?show_disconnect=true', '')
            let urlWithoutParams =
              currentURL.indexOf('/success') > 0 ? currentURL.substring(0, currentURL.indexOf('/success')) : currentURL
            reloadEntireApp(urlWithoutParams)
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          setBrighteLoading(false)
        })
    }
  }

  const startSungageDisconnect = () => {
    const confirmed = window.confirm(
      translate(
        'Are you sure you want to disconnect your Sungage account? Any existing proposals with Sungage payment options will no longer be able to apply for credit.'
      )
    )
    if (confirmed) {
      setSungageLoading(true)
      const restClientInstance = restClient(window.API_ROOT + '/api')
      restClientInstance('CUSTOM_PUT', 'custom', {
        url: 'orgs/' + org?.id + '/roles/' + props.roleId + '/sungage_disconnect/',
      })
        .then((response) => {
          if (response.data?.success) {
            logAmplitudeEvent('sungage_account_disconnected', {})
            let currentURL = window.location.href.replace('?show_disconnect=true', '')
            let urlWithoutParams =
              currentURL.indexOf('/success') > 0 ? currentURL.substring(0, currentURL.indexOf('/success')) : currentURL
            reloadEntireApp(urlWithoutParams)
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => {
          setSungageLoading(false)
        })
    }
  }

  const renderSungageConnected = () => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const showDisconnect = params.get('show_disconnect')
    if (showDisconnect) {
      return (
        <Button
          onClick={startSungageDisconnect}
          label="Disconnect Sungage"
          type="secondary"
          style={{ width: '400px' }}
          loading={sungageLoading}
        />
      )
    } else {
      return <p>{translate('Your account has been successfully connected')}</p>
    }
  }

  const disconnectSolarApp = () => {
    setSolarAppDisconnectLoading(true)
    const restClientInstance = restClient(window.API_ROOT + '/api')
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${org?.id}/disconnect_solarapp/`,
    })
      .then((response) => {
        if (response?.data?.message) {
          props.reloadOrg()
          notify(response?.message, 'success')
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => {
        setSolarAppDisconnectLoading(false)
      })
  }

  if (finishingOauth) return <Loading />

  if (!allowView) {
    return <NotFound />
  }
  return (
    <Edit
      title={'Integrations & API Keys'}
      breadCrumbs={<ControlBreadCrumbs currentPage="Edit Integrations & API Keys" />}
      subtitle={<Subtitle />}
      {...props}
      hasDelete={false}
    >
      <SimpleForm
        hideCancelButton={true}
        redirect="/control"
        defaultValue={getDefaultSettings(prePopulatedApi)}
        formatSubmitValues={(values) => formatSubmitValues(values)}
        disabled={!allowEdit}
      >
        {enableEagleView && ['US', 'CA'].includes(props.country_iso2) && (
          <div>
            <h2 style={{ marginTop: 60 }}>{translate('EagleView Roof Reports')}</h2>
            <p className="small">
              By proceeding you agree to be bound by the{' '}
              <a style={{ textDecoration: 'underline' }}>Terms & Conditions</a>
            </p>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
              <CustomField
                component={TextInput}
                source="eagleview_credentials.username"
                name="eagleview_credentials.username"
                label="Username"
                options={{
                  autoComplete: 'off',
                }}
                defaultValue={''}
                style={styles.inputStyle}
                {...props}
              />
              <CustomField
                component={PasswordInput}
                source="eagleview_credentials.password"
                name="eagleview_credentials.password"
                label="Password"
                options={{
                  autoComplete: 'new-password',
                }}
                defaultValue={''}
                style={styles.inputStyle}
                {...props}
              />
            </div>
          </div>
        )}

        <h2 style={{ marginTop: 60 }}>{translate('Aerial Imagery')}</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {!loading && permissions?.includes('is_staff') && (
            <>
              <CustomField
                component={TextInput}
                source="api_key_google"
                name="api_key_google"
                //label="Google Map API Key"
                defaultValue={''}
                style={styles.inputStyle}
                {...props}
              />
              <CustomField
                component={TextInput}
                source="api_key_bing"
                name="api_key_bing"
                //label="Bing Map API Key"
                defaultValue={''}
                style={styles.inputStyle}
                {...props}
              />
            </>
          )}

          <CustomField
            component={TextInput}
            source="api_key_cyclomedia"
            name="api_key_cyclomedia"
            // label="Cyclomedia API Key"
            defaultValue={''}
            style={styles.inputStyle}
            {...props}
          />
          {!loading && permissions && permissions.includes('is_staff') && props.country_iso2 === 'AU' && (
            <CustomField
              component={TextInput}
              source="api_key_metromap"
              name="api_key_metromap"
              label="MetroMap API key"
              defaultValue={''}
              style={styles.inputStyle}
              {...props}
            />
          )}
        </div>

        {enableSolarAPP && (
          <div style={{ marginBottom: 40 }}>
            <h2>{doNotTranslate('SolarAPP+')}</h2>
            {props.solarapp_account_connected ? (
              <>
                <p>{doNotTranslate('Your SolarAPP+ account is connected')}</p>
                <Button
                  onClick={disconnectSolarApp}
                  label={doNotTranslate('Disconnect SolarAPP+')}
                  type="secondary"
                  disabled={solarAppDisconnectLoading}
                  style={{ width: '400px' }}
                />
              </>
            ) : (
              <>
                <p>
                  {doNotTranslate(
                    'OpenSolar allows you to link your SolarAPP+ account to easily track permit applications within your projects. To get started, connect your SolarAPP+ account below.'
                  )}
                </p>
                <p>
                  {doNotTranslate(
                    'If you need to create a SolarAPP+ account for your organization, click Register on the '
                  )}
                  <Link href="https://gosolarapp.org/" target="_blank">
                    {doNotTranslate('SolarAPP+ website')}
                  </Link>
                  {doNotTranslate('.')}
                </p>
                <CoreButton
                  onClick={() => setShowSolarAppDialog(true)}
                  variant="contained"
                  color="default"
                  style={{ marginRight: 10, marginTop: 10 }}
                >
                  {doNotTranslate('Connect your SolarAPP+ Account')}
                </CoreButton>
              </>
            )}
            {showSolarAppDialog && (
              <SolarAppAccountDialog isOpen={showSolarAppDialog} close={() => setShowSolarAppDialog(false)} />
            )}
          </div>
        )}

        <h2 style={{ marginTop: 60 }}>Docusign</h2>
        {props.docusign_account_connected ? (
          renderConnectedDocusign()
        ) : (
          <>
            <p>
              {translate(
                'OpenSolar allows you to request signatures on your contracts via Docusign. The first step to enabling this is to connect your Docusign account below'
              )}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
              <CoreButton
                onClick={startDocusignOauth}
                style={{ marginRight: 10, marginTop: 10 }}
                variant="contained"
                color="default"
                disabled={docusignLoading}
                // labelPosition="before"
              >
                <span style={{ textTransform: 'none' }}>
                  {props.docusign_account_connected
                    ? translate('Re-connect your Docusign account')
                    : translate('Connect your Docusign account')}
                </span>
              </CoreButton>
              {showDocusignSandboxOption && (
                <CoreButton
                  onClick={(e) => startDocusignOauth(e, true)}
                  style={{ marginRight: 10, marginTop: 10 }}
                  variant="contained"
                  color="default"
                  disabled={docusignLoading}
                  // labelPosition="before"
                >
                  <span style={{ textTransform: 'none' }}>{translate('Connect SANDBOX Docusign account')}</span>
                </CoreButton>
              )}
            </div>
          </>
        )}

        <h2 style={{ marginTop: 60 }}>{translate('Others')}</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <CustomField
            component={TextInput}
            source="api_key_pvsell"
            name="api_key_pvsell"
            label="SunWiz API Key"
            style={styles.inputStyle}
            autoFocus={Boolean(prePopulatedApi)}
            variant={Boolean(prePopulatedApi) ? 'outlined' : 'standard'}
            {...props}
          />
          <CustomField
            component={TextInput}
            source="api_key_utility_api"
            name="api_key_utility_api"
            //label="UtilityAPI API Key"
            defaultValue={''}
            style={styles.inputStyle}
            {...props}
          />

          {!loading &&
          permissions &&
          (permissions.includes('is_staff') || (permissions.includes('is_admin') && props.country_iso2 === 'US')) ? (
            <CustomField
              component={TextInput}
              source="api_key_nearmap"
              name="api_key_nearmap"
              //label="Nearmap API Key"
              defaultValue={''}
              style={styles.inputStyle}
              {...props}
            />
          ) : (
            <div>
              {props.permissions} {props.country_iso}
            </div>
          )}
        </div>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            checkValueIsNotEmpty(formData.api_key_nearmap) && (
              <Paper style={{ width: '100%', margin: '20px 0' }}>
                <div style={{ padding: 20 }}>{translate('Nearmap imagery is enabled.')}</div>
              </Paper>
            )
          }
        </FormDataConsumer>
        <h2 style={{ marginTop: 60 }}>{translate('Olark Chat Integration')}</h2>
        <p className="small">
          {translate('Configure an')}{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.olark.com/pricing?ref=josephabel1&utm_medium=referral-partner&utm_source=josephabel1&utm_campaign=referral-partner"
          >
            Olark Chat
          </a>{' '}
          {translate(
            'account to appear in MyEnergy which shows anytime there is no dedicated Olark Chat account configured for the assigned team member.'
          )}{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.olark.com/pricing?ref=josephabel1&utm_medium=referral-partner&utm_source=josephabel1&utm_campaign=referral-partner"
          >
            {translate('Create a free Olark Chat account to get started.')}
          </a>{' '}
        </p>
        <CustomField
          component={TextInput}
          source="api_key_chat"
          name="api_key_chat"
          label="Olark Chat API Key"
          defaultValue={''}
          style={styles.inputStyle}
          {...props}
        />
        {!loading && (isAdmin || permissions?.includes('is_staff')) && props.country_iso2 === 'US' && (
          <>
            <h2 style={{ marginTop: 60 }}>Sunlight Financial Integration</h2>
            <p className="small"></p>

            {permissions?.includes('is_staff') && (
              <div>
                <div
                  style={{
                    height: 1,
                    overflow: 'hidden',
                    opacity: 0.01,
                    marginBottom: -1,
                  }}
                >
                  <input name="dummyUsernameFixChromeEnsureNotExposed" type="text" style={{}} />
                  <input name="dummyPasswordFixChromeEnsureNotExposed" type="password" style={{}} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ marginRight: '20px' }}>
                    <CustomField
                      component={TextInput}
                      source="api_key_sunlight_email"
                      name="api_key_sunlight_email"
                      label="Sunlight Financial Email"
                      defaultValue={''}
                      autoComplete="oh-chrome-why-did-you-break-autocomplete-off-1"
                      style={styles.inputStyle}
                      {...props}
                    />
                  </div>
                  <div style={{ marginRight: '20px' }}>
                    <CustomField
                      component={PasswordInput}
                      source="api_key_sunlight_password"
                      name="api_key_sunlight_password"
                      label="Sunlight Financial Password"
                      defaultValue={''}
                      autoComplete="oh-chrome-why-did-you-break-autocomplete-off-2"
                      style={styles.inputStyle}
                      {...props}
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              {isAdmin && (
                <CustomField
                  component={BooleanInput}
                  source="org_configs.disable_sunlight_auto_create"
                  name="org_configs.disable_sunlight_auto_create"
                  label="Disable automatic creation of projects in Orange Portal"
                  defaultValue={false}
                  style={styles.rowItem}
                  {...props}
                />
              )}
              {isAdmin && (
                <CustomField
                  component={BooleanInput}
                  source="org_configs.disable_sunlight_contract_transfer"
                  name="org_configs.disable_sunlight_contract_transfer"
                  label="Disable automatic transfer of contract and NTP submittal"
                  defaultValue={false}
                  style={styles.rowItem}
                  {...props}
                />
              )}
              {isAdmin && (
                <CustomField
                  component={BooleanInput}
                  source="org_configs.enable_sunlight_state_specific_products"
                  name="org_configs.enable_sunlight_state_specific_products"
                  label="Enable State-Specific Products"
                  defaultValue={false}
                  style={styles.rowItem}
                  {...props}
                />
              )}
            </div>
          </>
        )}

        <h2 style={{ marginTop: 60 }}>Plenti Integration</h2>
        <p className="small"></p>
        <CustomField
          component={TextInput}
          source="api_key_plenti_partner_id"
          name="api_key_plenti_partner_id"
          label="Plenti Partner ID (Direct)"
          defaultValue={''}
          autoComplete="oh-off-chrome-why-did-you-break-autocomplete-off-3"
          style={styles.inputStyle}
          {...props}
        />

        <p className="small"></p>
        <CustomField
          component={TextInput}
          source="api_key_plenti_staff_partner_id"
          name="api_key_plenti_staff_partner_id"
          label="Plenti Partner ID (Staff led)"
          defaultValue={''}
          autoComplete="oh-off-chrome-why-did-you-break-autocomplete-off-4"
          style={styles.inputStyle}
          {...props}
        />

        {props.country_iso2 === 'US' && <DividendIntegrationSection />}

        {props.country_iso2 === 'US' && (props.loanpalAccountConnected || isStaff) && (
          <div style={{ marginBottom: 40 }}>
            <h2>{LOANPAL_OR_GOODLEAP} Integration</h2>
            {props.loanpalAccountConnected ? (
              renderConnectedLoanpal()
            ) : (
              <Button
                onClick={() => setShowLoanpalDialog(true)}
                label={`Connect your ${LOANPAL_OR_GOODLEAP} Account`}
                variant="outlined"
                color="default"
                style={{ width: '400px' }}
              />
            )}
            {showLoanpalDialog && (
              <LoanpalAccountDialog isOpen={showLoanpalDialog} close={() => setShowLoanpalDialog(false)} />
            )}
          </div>
        )}
        {props.country_iso2 === 'US' && renderLoanpalChannels()}

        {isAU && (
          <>
            <h2>Brighte Integration</h2>
            {props.brighteAccountConnected ? renderConnectedBrighte() : <ConnectBrighteButton />}
          </>
        )}

        {isAdmin &&
          org?.available_integrations &&
          org.available_integrations?.finance?.map((integration) => {
            // temporary hardcode to make sure admins can enter smart eaes api keys. This will be replaced by configurable functionality in ticket #8456
            if (!isStaff && integration.integration_name !== 'energy_ease') return null
            if (integration.activation_type === 'no_payment_options') return null
            if (integration.activation_type === 'oauth2')
              return (
                <IntegrationOauthDialog
                  integrationKey={integration.integration_name}
                  lenderDisplayName={integration.display_name}
                  logoURL={integration.logo_large_contents}
                />
              )
            // legacy integrations have their own dedicated components
            if (LEGACY_INTEGRATIONS.includes(integration.integration_name)) return null
            else
              return (
                <GenericIntegrationAccountDialog
                  integrationKey={integration.integration_name}
                  lenderDisplayName={integration.display_name}
                  field1Name={
                    integration?.banner_content?.field_1_name ? integration?.banner_content?.field_1_name : 'User ID'
                  }
                  field2Name={
                    integration?.banner_content?.field_2_name !== undefined
                      ? integration?.banner_content?.field_2_name
                      : 'API Key'
                  }
                  field3Name={integration?.banner_content?.field_3_name}
                  field4Name={integration?.banner_content?.field_4_name}
                  activation_type={integration.activation_type}
                />
              )
          })}

        {props.country_iso2 === 'US' && (props.mosaicAccountConnected || isStaff) && (
          <div style={{ marginBottom: 40 }}>
            <h2>Mosaic Integration</h2>
            {props.mosaicAccountConnected ? (
              renderConnectedMosaic()
            ) : (
              <Button
                onClick={() => setShowMosaicDialog(true)}
                label="Connect your Mosaic Account"
                // type="primary"
                type="default"
                style={{ width: '400px' }}
              />
            )}
            {showMosaicDialog && (
              <MosaicAccountDialog isOpen={showMosaicDialog} close={() => setShowMosaicDialog(false)} />
            )}
          </div>
        )}

        {props.country_iso2 === 'US' && (props.sungageAccountConnected || isStaff) && (
          <div style={{ marginBottom: 40 }}>
            <h2>Sungage Integration</h2>
            {props.sungageAccountConnected ? (
              renderSungageConnected()
            ) : (
              <Button
                onClick={() => setShowSungageDialog(true)}
                label="Connect your Sungage Account"
                // type="primary"
                type="default"
                style={{ width: '400px' }}
              />
            )}
            {showSungageDialog && (
              <SungageAccountDialog isOpen={showSungageDialog} close={() => setShowSungageDialog(false)} />
            )}
          </div>
        )}

        {props.hasGreenLancerToken && !hideGreenLancer && (
          <>
            <h2>GreenLancer Integration</h2>
            <div style={{ position: 'relative', width: '400px' }}>
              <Button
                loading={greenLancerLoading}
                onClick={disconnectGreenLancer}
                label="Disconnect GreenLancer Account"
                type="secondary"
                style={{ width: '400px' }}
              />
            </div>
          </>
        )}
        {props.country_iso2 === 'GB' && (props.phoenixAccountConnected || isStaff) && (
          <>
            <h2>Phoenix Financial Integration</h2>
            <ConnectPhoenixAccount />
          </>
        )}
        {['GB', 'GG', 'JE'].includes(props.country_iso2) && (
          <div style={{ marginBottom: 40 }}>
            <h2 style={{ marginTop: 60 }}>Segen Integration</h2>
            <CustomField
              component={BooleanInput}
              source="enable_segen"
              name="enable_segen"
              label="Enable Segen"
              onClick={() => dispatch(updateSelectedHardwareSupplier(undefined))}
              defaultValue={false}
              style={styles.rowItem}
              {...props}
            />
          </div>
        )}
        {enableCityPlumbing && props.country_iso2 === 'GB' && (
          <div style={{ marginBottom: 40 }}>
            <h2 style={{ marginTop: 60 }}>City Plumbing Integration</h2>
            <CustomField
              component={BooleanInput}
              source="enable_city_plumbing"
              name="enable_city_plumbing"
              label="Enable City Plumbing"
              onClick={() => dispatch(updateSelectedHardwareSupplier(undefined))}
              defaultValue={false}
              style={styles.rowItem}
              {...props}
            />
          </div>
        )}
        {enableHdm && props.country_iso2 === 'GB' && (
          <div style={{ marginBottom: 40 }}>
            <h2 style={{ marginTop: 60 }}>HDM Integration</h2>
            <CustomField
              component={BooleanInput}
              source="enable_hdm"
              name="enable_hdm"
              label="Enable HDM"
              onClick={() => dispatch(updateSelectedHardwareSupplier(undefined))}
              defaultValue={false}
              style={styles.rowItem}
              {...props}
            />
          </div>
        )}
        {enableVtac && (props.country_iso2 === 'GB' || props.country_iso2 === 'PL') && (
          <div style={{ marginBottom: 40 }}>
            <h2 style={{ marginTop: 60 }}>V-TAC Integration</h2>
            <CustomField
              component={BooleanInput}
              source="enable_vtac"
              name="enable_vtac"
              label="Enable V-TAC"
              onClick={() => dispatch(updateSelectedHardwareSupplier(undefined))}
              defaultValue={false}
              style={styles.rowItem}
              {...props}
            />
          </div>
        )}
        {((props.country_iso2 === 'GB' && org?.enable_uk_address_lookup) || isStaff) && (
          <div style={{ maxWidth: 400 }}>
            <h2 style={{ marginTop: 60 }}>AddressBase Lookup</h2>
            <CustomField
              component={BooleanInput}
              source="enable_address_base_lookup"
              name="enable_address_base_lookup"
              label="Enable AddressBase and agree to share Business name and Business address with Allies Computing Ltd."
              defaultValue={false}
              style={styles.rowItem}
              {...props}
            />
          </div>
        )}
      </SimpleForm>
    </Edit>
  )
}

const mapStateToProps = (state) => {
  const country_iso2 = getOrgCountryCodeFromState(state)
  const creditCardSupportInThisCountry = ['AU', 'US', 'NZ'].indexOf(country_iso2) !== -1
  const orgId = authSelectors.getOrgId(state)
  const defaultMeasurementUnits = country_iso2 === 'US' ? 'Imperial' : 'Metric'
  const docusign_account_connected = authSelectors.getDocusignAccountConnected(state)
  const solarapp_account_connected = authSelectors.getIsSolarAppAccountConnected(state)
  const loanpalAccountConnected = orgSelectors.getLoanpalIntegrationStatus(state) === 'active'
  const loanpalAccountShared = orgSelectors.getLoanpalIntegrationStatus(state) === 'shared'
  const mosaicAccountConnected = orgSelectors.getMosaicIntegrationStatus(state) === 'active'
  const sungageAccountConnected = orgSelectors.getSungageIntegrationStatus(state) === 'active'
  const phoenixAccountConnected = orgSelectors.getPhoenixIntegrationStatus(state) === 'active'
  const brighteAccountConnected = orgSelectors.getBrighteIntegrationStatus(state) === 'active'
  let greenlancerRole = null
  let hasGreenLancerToken = false
  let thisRole = null
  for (var i = 0; i < state.auth?.roles?.length; i++) {
    if (state.auth.roles[i].org_id === orgId) {
      thisRole = state.auth.roles[i]
      break
    }
  }
  if (thisRole && state.auth && state.auth.roles && state.auth.roles.length > 0) {
    state.auth?.roles?.forEach((role) => {
      if (
        role.id === thisRole.id &&
        !hasGreenLancerToken &&
        (role.is_greenlancer_child_account ||
          (role.greenlancer_token_expiration && role.greenlancer_token_expiration !== 'None'))
      ) {
        hasGreenLancerToken = true
        greenlancerRole = role
      }
    })
  }

  return {
    country_iso2,
    creditCardSupportInThisCountry,
    defaultMeasurementUnits,
    docusign_account_connected,
    solarapp_account_connected,
    hasGreenLancerToken,
    greenlancerRole,
    loanpalAccountConnected,
    loanpalAccountShared,
    mosaicAccountConnected,
    sungageAccountConnected,
    roleId: thisRole?.id,
    brighteAccountConnected,
    phoenixAccountConnected,
  }
}

const mapDispatchToProps = {
  showNotification: showNotificationAction,
  authReload: authReloadAction,
  removeGreenLancerTokenFromRole: removeGreenLancerTokenFromRole,
  reloadOrg: orgsActions.reloadOrg,
}

export const EditExternalKeys = connect(mapStateToProps, mapDispatchToProps)(_EditExternalKeys)
