import { Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { Box, Button, Chip, ComponentVersionsInherit, styled, Typography } from 'opensolar-ui'
import { useTemplateLibraryModal } from 'projectSections/sections/proposal/templateLibrary'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { NavLink, useHistory, useParams } from 'react-router-dom'

const ActionBarContainer = styled(Box)({
  alignItems: 'center',
  background: '#FFF',
  borderBottom: '1px solid #ECECED',
  display: 'flex',
  justifyContent: 'space-between',
})

const ActionButton = styled(Button)({
  borderLeft: '1px solid #ECECED',
  borderRadius: 0,
  color: '#4C4B53',
  padding: '12px 16px',
  '&.active': {
    background: '#F5F8FD',
    '& p': {
      color: '#1751D0',
    },
  },
})

const ClassicProposalActionBar = () => {
  const translate = useTranslate()
  const history = useHistory()
  const { id: projectId } = useParams<{ id: string }>()
  const { setOpen: setOpenTemplateLibrary } = useTemplateLibraryModal()
  const section = useSelector((state: any) => state.project?.section)

  const openTemplateModal = () => {
    setOpenTemplateLibrary()
    if (section !== 'proposal') {
      // Template modal is mounted in Proposal page
      history.push(`/projects/${projectId}/proposal`)
    }
  }

  return (
    <ComponentVersionsInherit versions={{ chip: 2, button: 3, typography: 2 }}>
      <ActionBarContainer>
        <Box alignItems="center" display="flex" gridGap={8} padding="8px 20px">
          <Typography colorHex="#4C4B53" variant="body2">
            {translate('Classic OpenSolar Proposal')}
          </Typography>
          <Chip label="Classic" />
        </Box>
        <Box>
          <Tooltip title={translate('View online proposal')}>
            <NavLink to={`/projects/${projectId}/proposal`}>
              <ActionButton className={classNames(section === 'proposal' && 'active')} color="default" variant="text">
                <Typography colorHex="#4C4B53" variant="body2">
                  {translate('Online')}
                </Typography>
              </ActionButton>
            </NavLink>
          </Tooltip>
          <Tooltip title={translate('View PDF proposal')}>
            <NavLink to={`/projects/${projectId}/preview`}>
              <ActionButton className={classNames(section === 'preview' && 'active')} color="default" variant="text">
                <Typography colorHex="#4C4B53" variant="body2">
                  {translate('PDF')}
                </Typography>
              </ActionButton>
            </NavLink>
          </Tooltip>
          <Tooltip title={translate('Open Template Library')}>
            <ActionButton color="default" variant="text" onClick={openTemplateModal}>
              <Typography colorHex="#4C4B53" variant="body2">
                {translate('Templates')}
              </Typography>
            </ActionButton>
          </Tooltip>
        </Box>
      </ActionBarContainer>
    </ComponentVersionsInherit>
  )
}

export default ClassicProposalActionBar
