var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var FileIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '1rem' : _b, _c = _a.color, color = _c === void 0 ? 'currentColor' : _c, rest = __rest(_a, ["size", "color"]);
    var iconAttributes = {
        width: size,
        height: size,
    };
    return (_jsx("svg", __assign({ viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, rest, iconAttributes, { children: _jsx("g", { id: "file-02-filled", children: _jsx("path", { id: "Icon", fillRule: "evenodd", clipRule: "evenodd", d: "M6.11095 3C5.03706 3 4.1665 3.87056 4.1665 4.94444V16.0556C4.1665 17.1294 5.03706 18 6.11095 18H13.8887C14.9626 18 15.8332 17.1294 15.8332 16.0556V4.94444C15.8332 3.87056 14.9626 3 13.8887 3H6.11095ZM6.0415 5.91667C6.0415 5.57149 6.32133 5.29167 6.6665 5.29167H13.3332C13.6783 5.29167 13.9582 5.57149 13.9582 5.91667C13.9582 6.26184 13.6783 6.54167 13.3332 6.54167H6.6665C6.32133 6.54167 6.0415 6.26184 6.0415 5.91667ZM6.0415 8.83333C6.0415 8.48816 6.32133 8.20833 6.6665 8.20833H9.1665C9.51168 8.20833 9.7915 8.48816 9.7915 8.83333C9.7915 9.17851 9.51168 9.45833 9.1665 9.45833H6.6665C6.32133 9.45833 6.0415 9.17851 6.0415 8.83333ZM6.6665 11.125C6.32133 11.125 6.0415 11.4048 6.0415 11.75C6.0415 12.0952 6.32133 12.375 6.6665 12.375H11.6665C12.0117 12.375 12.2915 12.0952 12.2915 11.75C12.2915 11.4048 12.0117 11.125 11.6665 11.125H6.6665Z", fill: color }) }) })));
};
