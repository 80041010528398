import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { ArrowForwardIcon, Button } from 'opensolar-ui'
import { memo, useCallback, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import type { DesignTabs } from 'reducer/designer/view'
import { setSelectedDesignTab, setToggleBom } from 'reducer/designer/view'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ActionType, EventType } from 'types/tracking'
import { useFeatureFlag } from 'util/split'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 2px -2px inset',
    textAlign: 'right',
    backgroundColor: 'rgb(255,255,255)',
    padding: 10,
    position: 'sticky',
    bottom: 0,
    right: 0,
  },
}))

const getDesignHierarchy = ({ enablePaymentTab, enableMountingTab }) => {
  const hierarchy = [
    'panels',
    ...(enableMountingTab ? ['mounting'] : []),
    'inverters',
    'batteries',
    'others',
    'pricing',
    'incentives',
    ...(enablePaymentTab ? ['payment options'] : []),
  ] as DesignTabs[]

  return hierarchy
}

const NextStepAction = ({ currentTabValue }: { currentTabValue: DesignTabs }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const enableMountingTab = useFeatureFlag('studio_tabs_mounting', 'on')
  const enablePaymentTab = !useFeatureFlag('payments_tab', 'on') // Note this is mis-leading flag name, payment_tab is referring the payment options tab in header
  const hierarchy = useMemo(() => getDesignHierarchy({ enablePaymentTab, enableMountingTab }), [
    enablePaymentTab,
    enableMountingTab,
  ])

  const { trackEvent } = useTrackComponent({
    componentKey: 'next-step-action',
    eventName: 'Clicked On Next Step Action',
  })

  const forReviewBomTab = ['incentives', 'others']
  const isSideEffectOpenBOM = forReviewBomTab.includes(currentTabValue)

  const getNextActionButtonLabel = () => {
    switch (currentTabValue) {
      case 'others':
        return 'Review BOM and Pricing'
      case 'incentives':
        return 'Review BOM'
      default:
        return 'Next'
    }
  }

  const handleClick = useCallback(() => {
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { nextTab: currentTabValue })
    if (isSideEffectOpenBOM) {
      dispatch(setToggleBom(true))
      if (currentTabValue === 'incentives') {
        return
      }
    }

    const currentTabIndex = hierarchy.indexOf(currentTabValue)
    const nextTab = hierarchy[currentTabIndex + 1]
    dispatch(setSelectedDesignTab(nextTab))
  }, [hierarchy, currentTabValue])

  const showStickyAction = useMemo(() => {
    if (!hierarchy.includes(currentTabValue)) return false
    if (currentTabValue === 'pricing') return false
    if (hierarchy[hierarchy.length - 1] === currentTabValue) return false
    return true
  }, [hierarchy, currentTabValue])

  if (!showStickyAction) return null

  return (
    <div className={classes.container}>
      <Button onClick={handleClick} size="small" variant="contained" color="primary" endIcon={<ArrowForwardIcon />}>
        {translate(getNextActionButtonLabel())}
      </Button>
    </div>
  )
}

export default memo(NextStepAction)
