import { CircularProgress, Typography } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { FormControl, Grid, MenuItem, Select, styled } from 'opensolar-ui'
import Alert from 'pages/ordering/checkoutv3/preOrder/components/Alert'
import { DISTRBUTOR_KEY_TO_NAME_MAPPING } from 'pages/ordering/checkoutv3/preOrder/constants'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { getSupplierName } from 'projectSections/sections/hardware/util'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ORDER_STATUS } from 'resources/inventoryTransfer/constants'
import IncomingListActionsField from 'resources/inventoryTransfer/fields/IncomingListActionsField'
import { Transfer } from 'resources/inventoryTransfer/type'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import { useDateFormatter } from 'util/date'
import ConnectModal from './ConnectModal'
import DistributorOptions from './DistributorOptions'
import useGetDistributorConnectionStatusAndConfig from './hooks/useGetDistributorConnectionStatusAndConfig'
import useGetOrderDataByProject from './hooks/useGetOrderDataByProject'
import OrderStatusStepper from './OrderStatusStepper'
import PricingBox from './PricingBox'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '97.5%',
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 20,
    gap: 10,
    color: theme.greyDark2,
  },
  content: { maxWidth: 682 },
  font: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  stepContainer: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: 850,
    borderLeft: '1px solid #e7e7e7',
  },
}))

const AlertWrapper = styled('div')(({ theme }) => ({
  margin: '24px 0px',
}))

const ShopSection = ({ project }: { project: Record<string, any> }) => {
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const projectId = useSelector((state: RootState) => state.projectId)
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  const [open, setOpen] = useState<boolean>(false)
  const [selectedSupplier, setSelectedSupplier] = useState<HardwareSupplierFilterKeyType | undefined>(undefined)
  const [selectedOrder, setSelectedOrder] = useState<Transfer | undefined>(undefined)
  const [status, setStatus] = useState<string | undefined>(undefined)
  const {
    isChecking,
    atLeastOneDistributorEnabled,
    atLeastOneDistributorConnected,
    enabledAndConnectedDistributors,
    distributorConfigs,
  } = useGetDistributorConnectionStatusAndConfig(selectedSupplier)
  const { loadedOrderData, loadOrderData } = useGetOrderDataByProject()
  const formatter = useDateFormatter({ variant: 'date' })

  const handleStatus = (status) => {
    setStatus(status)
  }

  const onSelectSupplier = (supplier: HardwareSupplierFilterKeyType) => {
    setSelectedSupplier(supplier)
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (projectId) {
      loadOrderData(projectId)
    }
  }, [projectId])

  useEffect(() => {
    if (enabledAndConnectedDistributors?.length === 0) return
    if (!isChecking) {
      setSelectedSupplier(enabledAndConnectedDistributors?.[0] as HardwareSupplierFilterKeyType | undefined)
    }
  }, [isChecking, enabledAndConnectedDistributors])

  useEffect(() => {
    if (loadedOrderData?.length > 0) {
      setSelectedOrder(loadedOrderData[0])
      setStatus(loadedOrderData[0].status)
    }
  }, [loadedOrderData])

  const handleChange = (event) => {
    const selectedOrderId = event.target.value
    const matchingOrder = loadedOrderData?.find((order) => order.id === selectedOrderId)
    setSelectedOrder(matchingOrder)
  }

  const handleResendOrder = () => {
    history.push({ pathname: `/projects/${projectId}/hardware`, state: { orderId: selectedOrder?.order_id } })
  }

  if (loadedOrderData === undefined) {
    return <CircularProgress variant="indeterminate" />
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} className={classes.leftSection}>
          {selectedOrder ? (
            <Grid container item spacing={1} direction="column">
              <Grid item>
                <Typography variant="subtitle1" className={classes.font}>
                  {loadedOrderData?.length > 1 ? 'Your Orders' : 'Your Order'}
                </Typography>
                <Typography variant="body2">
                  Ordered from {getSupplierName(selectedOrder)} on {formatter(selectedOrder.created_date)}
                </Typography>
              </Grid>
              <Grid item>
                <FormControl size="small">
                  <Typography variant="body2" gutterBottom>
                    Select Purchase Order ID
                  </Typography>
                  <Select value={selectedOrder?.id} onChange={handleChange} defaultValue={loadedOrderData?.[0]?.id}>
                    {loadedOrderData?.map((order) => {
                      return (
                        <MenuItem key={order.id} value={order.id}>
                          {`${getSupplierName(order)} (ID ${
                            order.order_number_display || order.order_number || order.id
                          })`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {selectedOrder.status === ORDER_STATUS.DRAFT && (
                <AlertWrapper>
                  <Alert
                    title={translate(
                      `Hmm, something went wrong with your order and we were not able to process your order with ${
                        DISTRBUTOR_KEY_TO_NAME_MAPPING[selectedOrder?.distributor || '']
                      }. Please resend your order.`
                    )}
                    severity="error"
                    onSubmit={handleResendOrder}
                    onSubmitLabel={translate('Resend Order')}
                    fontWeight="normal"
                  />
                </AlertWrapper>
              )}
              <Grid item>
                <IncomingListActionsField
                  transfer={selectedOrder}
                  transferType={'incoming'}
                  allowReview={true}
                  onManage={true}
                  orderStatus={status}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Typography variant="subtitle1" gutterBottom className={classes.font}>
                {atLeastOneDistributorConnected
                  ? 'Find solar equipment for the best price'
                  : 'Connect Hardware Supplier'}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {atLeastOneDistributorConnected
                  ? orgCountry === 'AU' || orgCountry === 'PL'
                    ? 'See BOM price from our partner hardware distributor'
                    : 'Compare BOM prices from our partner hardware distributors'
                  : 'The easiest way to get the hardware you need to complete your jobs'}
              </Typography>
              <DistributorOptions
                configs={distributorConfigs}
                onSelectSupplier={onSelectSupplier}
                onOpen={() => {
                  if ((orgCountry === 'AU' || orgCountry === 'PL') && !atLeastOneDistributorEnabled) {
                    window.open('https://pages.opensolar.com/partners/solar-outlet#Getstarted')
                    return
                  }
                  if (orgCountry === 'GB' && !atLeastOneDistributorEnabled) {
                    history.push(`/external_api_keys/${orgId}`)
                    return
                  }
                  setOpen(true)
                }}
                atLeastOneDistributorConnected={atLeastOneDistributorConnected}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedOrder ? (
            <div className={classes.stepContainer}>
              <OrderStatusStepper order={selectedOrder} handleStatus={handleStatus} />
            </div>
          ) : (
            <PricingBox
              project={project}
              selectedSupplier={selectedSupplier}
              atLeastOneDistributorConnected={atLeastOneDistributorConnected}
            />
          )}
        </Grid>
      </Grid>
      <ConnectModal open={open} distributorConfigs={distributorConfigs} onClose={onClose} trackSource={'manage_page'} />
    </div>
  )
}

export default ShopSection
