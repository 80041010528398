import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { useGlobalCart } from 'hooks/useGlobalCart'
import { getSupplierConfig } from 'pages/ordering/configs'
import useEnabledHardwareSuppliers from 'pages/ordering/hooks/useEnabledHardwareSuppliers'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatCurrency } from 'util/misc'
import { DISTRIBUTOR_ACCOUNT_MANAGER, DISTRIBUTOR_DEFAULT_VALUES } from './constants'
import usePreOrderService from './hooks/usePreOrderService'
import PreOrderPresenter from './PreOrderPresenter'
import { AccountManagerType, ContactInfoType, DeliveryAddressInfoType, DistributorDataContextType } from './types'

export const PreOrderPresenterContext = createContext<PreOrderPresenter | undefined>(undefined)
export const DistributorDataContext = createContext<DistributorDataContextType>({})
export const TotalItemCountContext = createContext<number>(0)
export const ContactInfoContext = createContext<{ [key: string]: ContactInfoType }>({})
export const DeliveryAddressInfoContext = createContext<{ [key: string]: DeliveryAddressInfoType }>({})
export const AccountManagerContext = createContext<{ [key: string]: AccountManagerType | undefined }>({})
export const ShowConnectDistributorModalContext = createContext<boolean>(false)

const PreOrderProvider = ({ children }) => {
  const [totalItemCount, setTotalItemCount] = useState<number>(0)
  const [distributorData, setDistributorData] = useState<any>({})
  const [contactInfo, setContactInfo] = useState<{ [key: string]: ContactInfoType }>({})
  const [deliveryAddressInfo, setDeliveryAddressInfo] = useState<{ [key: string]: DeliveryAddressInfoType }>({})
  const [accountMangerInfo, setAccountManagerInfo] = useState<{ [key: string]: AccountManagerType | undefined }>(
    DISTRIBUTOR_ACCOUNT_MANAGER
  )
  const [setSystem] = useState<any>({})
  const [showConnectDistributorModal, setShowConnectDistributorModal] = useState<boolean>(false)
  const enabledDistributorsEnum = useEnabledHardwareSuppliers()
  const service = usePreOrderService()
  const enabledDistributors = enabledDistributorsEnum.map((item) => getSupplierConfig(item)?.filterKey)
  const user = useSelector(authSelectors.getCurrentUser)
  const role = useSelector(authSelectors.getCurrentRole)
  const org = useSelector(orgSelectors.getOrg)
  let contactName = `${role?.first_name} ${role?.family_name}`
  if (contactName.trim() === '') {
    contactName = user?.email || 'Unknown name'
  }
  const { orderableLineItems } = useGlobalCart()

  useEffect(() => {
    const componentData = {}
    const distributorInfo = {}
    let itemCount = 0
    orderableLineItems?.forEach((item: OrderLineItem) => {
      const distributor = item.selectedDistributor
      if (!enabledDistributors.includes(distributor)) return
      const distributorItems = componentData[distributor] || []
      componentData[distributor] = [...distributorItems, item]

      // process distributor specific info
      // pricing related
      const distributorOrderingInfo = distributorInfo[distributor] || {}
      const beforeDiscountTotal = distributorOrderingInfo['beforeDiscountTotal'] || 0
      const afterDiscountTotal = distributorOrderingInfo['afterDiscountTotal'] || 0
      const totalItems = distributorOrderingInfo['totalItems'] || 0
      const { beforeDiscount, afterDiscount } = item.getBestDiscountOffer()
      distributorInfo[distributor] = {
        beforeDiscountTotal: beforeDiscountTotal + beforeDiscount,
        afterDiscountTotal: afterDiscountTotal + afterDiscount,
        totalItems: totalItems + item.quantity,
      }
      itemCount += item.quantity
    })
    const parsedData = {}
    Object.keys(componentData).forEach(
      (key) =>
        (parsedData[key] = {
          ...distributorInfo[key],
          ...DISTRIBUTOR_DEFAULT_VALUES[key],
          items: componentData[key],
          shippingInfo: {
            ...DISTRIBUTOR_DEFAULT_VALUES[key]['shippingInfo'],
          },
          subtotalPrice: formatCurrency(distributorInfo[key]['afterDiscountTotal']),
          totalPrice: formatCurrency(
            distributorInfo[key]['afterDiscountTotal'] + distributorInfo[key]['afterDiscountTotal'] * 0.2
          ),
          totalTax: formatCurrency(distributorInfo[key]['afterDiscountTotal'] * 0.2),
          projectIds: [],
        })
    )

    setDistributorData(parsedData)
    setTotalItemCount(itemCount)
  }, [])

  useEffect(() => {
    //set default address and contact info
    const contactData = {}
    const contact: ContactInfoType = {
      name: contactName,
      email: user?.email,
      phoneNumber: role?.phone,
    }
    contactData['general'] = contact

    const deliveryAddressData = {}
    const deliveryAddress: DeliveryAddressInfoType = {
      address: org?.address || '',
      locality: org?.locality || '',
      state: org?.state || '',
      zip: org?.zip || '',
      countryName: org?.country.name || '',
      countryIso2: org?.country_iso2 || '',
    }
    deliveryAddressData['general'] = deliveryAddress
    enabledDistributors.forEach((item) => {
      if (!item) return
      contactData[item] = contact
      deliveryAddressData[item] = deliveryAddress
    })

    setContactInfo(contactData)
    setDeliveryAddressInfo(deliveryAddressData)
  }, [org, user, role])

  const preorderPresenter = useMemo(
    () =>
      new PreOrderPresenter(
        service,
        setSystem,
        setDistributorData,
        setContactInfo,
        setDeliveryAddressInfo,
        setAccountManagerInfo,
        setShowConnectDistributorModal
      ),
    []
  )

  return (
    <AccountManagerContext.Provider value={accountMangerInfo}>
      <DeliveryAddressInfoContext.Provider value={deliveryAddressInfo}>
        <ContactInfoContext.Provider value={contactInfo}>
          <DistributorDataContext.Provider value={distributorData}>
            <TotalItemCountContext.Provider value={totalItemCount}>
              <PreOrderPresenterContext.Provider value={preorderPresenter}>
                <ShowConnectDistributorModalContext.Provider value={showConnectDistributorModal}>
                  {children}
                </ShowConnectDistributorModalContext.Provider>
              </PreOrderPresenterContext.Provider>
            </TotalItemCountContext.Provider>
          </DistributorDataContext.Provider>
        </ContactInfoContext.Provider>
      </DeliveryAddressInfoContext.Provider>
    </AccountManagerContext.Provider>
  )
}

export default PreOrderProvider
