import { ProjectType } from 'types/projects'

export const projectDataLoad = async () => {
  if (!window.projectForm) {
    console.error('Open a project before using this tool')
    return
  }

  const project = await loadJsonFile()

  const keys = Object.keys(project)
  if (!window.confirm(`Import ${keys.length} fields from JSON file?\n\n(This can take some time)`)) {
    console.error('User aborted import')
    return
  }

  const currentValues = window.projectForm.getState().values

  for (const key of Object.keys(project)) {
    if (key === 'design') continue
    const currentValue = currentValues[key]
    if (currentValue === project[key]) {
      console.log('Field already set to value: ', key, project[key])
      continue
    }
    console.log('Setting field: ', key, project[key])
    window.projectForm.mutators.updateField(key, project[key])
  }
  console.info('Form values copied from clipboard!')

  if (project.design) {
    let design
    try {
      design = window.editor.deserializeDesign(project.design)
    } catch (err) {
      console.error('Failed to parse design data')
      return
    }
    window.editor.loadScene(design, window.WorkspaceHelper.params)
    console.info('Design data copied from clipboard!')
  }

  alert('Project data imported successfully')
}

const fileSelect = document.createElement('input')
fileSelect.setAttribute('type', 'file')

function loadJsonFile(): Promise<Partial<ProjectType>> {
  return new Promise((resolve, reject) => {
    // Create file reader Event Listener
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      const result_base_64 = event.target?.result
      if (!result_base_64 || typeof result_base_64 !== 'string') {
        console.error('File is incorrectly formatted')
        reject()
        return
      }
      let data
      try {
        const result_decoded = b64DecodeUnicode(result_base_64.split(',')[1])
        data = JSON.parse(result_decoded)
      } catch (e) {
        console.error('Failed to parse JSON data in file')
        reject()
        return
      }
      resolve(data)
    })

    // Event Listener for file input
    fileSelect.addEventListener('change', (event) => {
      //@ts-ignore
      const fileList = event.target.files
      for (const file of fileList) {
        reader.readAsDataURL(file)
      }
    })
    fileSelect.click()
  })
}

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}
