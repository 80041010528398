import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import DateFormInput from 'elements/input/DateInput'
import EditorComponent from 'elements/wysiwyg/EditorComponentWrapper'
import { useTranslate } from 'ra-core'
import { TextInput } from 'ra-ui-materialui'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import { dateParser } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputWidth: {
    marginLeft: '10px !important',
  },
  textInputLabel: {
    width: '100%',
    height: '100%',
    '& .Mui-focused': {
      color: `${theme.black} !important`,
      opacity: 0.9,
    },
  },
}))

const ChangeOrderPlaceholders: React.FC = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const placeholders = useSelector((state: RootState) => state.generateDocument.dialogOptions.changeOrderPlaceholders)
  const sortedPlaceholders = useMemo(
    () => placeholders.filter((x) => x.type !== 'richtext').concat(placeholders.filter((x) => x.type === 'richtext')),
    [placeholders]
  )

  return (
    <div className={classes.wrapper}>
      {sortedPlaceholders?.map((x, i) => {
        const source = `placeholderData.${x.field.replaceAll('.', '-')}`
        if (x.type === 'date') {
          return (
            <DateFormInput
              key={'co_placeholder_' + i}
              defaultValue={x.value ? new Date(x.value) : undefined}
              className={classes.inputWidth}
              source={source}
              label={x.customTranslate ? x.customTranslate(translate, x.label) : x.label}
              style={{ marginRight: 30 }}
              parse={dateParser}
              options={{
                InputLabelProps: {
                  shrink: true,
                },
              }}
              hideClearButton={true}
            />
          )
        } else if (x.type === 'richtext') {
          return (
            <CustomField
              component={DraftEditorWithContent}
              fieldName={source}
              label={x.label}
              source={source}
              name={source}
              {...props}
            />
          )
        } else {
          return (
            <TextInput
              key={'co_placeholder_' + i}
              type={x.type === 'number' ? 'number' : undefined}
              label={x.customTranslate ? x.customTranslate(translate, x.label) : x.label}
              defaultValue={x.value}
              source={source}
              className={classes.inputWidth}
              options={{
                InputLabelProps: {
                  shrink: true,
                  className: classes.textInputLabel,
                },
              }}
            />
          )
        }
      })}
    </div>
  )
}

const DraftEditorWithContent = (props) => {
  const docusign_account_connected = useSelector(authSelectors.getDocusignAccountConnected)
  return (
    <EditorComponent
      label={props.label}
      fieldName={props.fieldName}
      allowUploadImage={true}
      mentionContext={'change_order'}
      docusign_account_connected={docusign_account_connected}
      {...props}
    />
  )
}
DraftEditorWithContent.defaultProps = {
  addField: true,
}

export default ChangeOrderPlaceholders
