import { Card, FormControlLabel, Tooltip } from '@material-ui/core'
import { AccountCircleOutlined, DeleteOutlined, EditOutlined } from '@material-ui/icons'
import Alert from 'elements/Alert'
import { Switch, Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { BlueSnapOwnerType, BlueSnapRepType, FieldPrefixFilterType } from '../types'
import { getContactErrorMessage, getContactsAreEqual, getNormalizeBlueSnapContact } from '../utils'

type PropTypes = {
  contactData: BlueSnapOwnerType | BlueSnapRepType
  fieldPrefix: FieldPrefixFilterType
  fieldIndex?: number // used for owners (owner1 vs owner 2, etc) and corresponds to the index in the ownership array
  isUniqueRep?: boolean // used to say this contact is a uniue rep (so not an owner as well)
  hasUniqueRep: boolean // used to say that ANY contact is a unique rep
  setFieldPrefixFilter: (val: FieldPrefixFilterType) => void
  navigateToPage: (routeKey: string) => void
}

const StyledCard = styled(Card)({
  flex: 1,
  padding: '1rem',
  borderRadius: '8px',
  textAlign: 'left',
  marginBottom: '1rem',
})

const HeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
})

const IconWrapper = styled('span')(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.grey[700],
    margin: '0px 5px',
    cursor: 'pointer',
  },
}))

const StyledAccountIcon = styled(AccountCircleOutlined)(({ theme }) => ({
  color: theme.palette.grey[700],
  margin: '0px 5px',
  cursor: 'pointer',
}))

const CardHeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
})

const CardHeaderIconsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const ContactDescription = styled('div')({
  margin: '0 0 0 35px',
})

const StyledFormControlLabel = styled(FormControlLabel)({
  lineHeight: 1.5,
  margin: '0 0 0 35px',
  fontSize: '13px',
  width: '80%',
  display: 'flex',
  justifyContent: 'space-between',
})

const ContactCard: React.FC<PropTypes> = ({
  contactData,
  fieldPrefix,
  fieldIndex,
  isUniqueRep,
  setFieldPrefixFilter,
  navigateToPage,
  hasUniqueRep,
}) => {
  const formVals = useFormState().values
  const form = useForm()
  const companyRep = formVals?.companyRep

  const translate = useTranslate()
  const normalizedData = getNormalizeBlueSnapContact(contactData)

  const isCompanyRep = useMemo(() => {
    if (!companyRep) return false
    return getContactsAreEqual(normalizedData, getNormalizeBlueSnapContact(companyRep))
  }, [normalizedData, companyRep])

  const [isRep, setIsRep] = useState<boolean>(false)

  const onRepToggle = useCallback(
    (e) => {
      if (hasUniqueRep) {
        return
      } else {
        setIsRep(!isRep)
        if (e.target.checked) {
          form.change('companyRep', {
            companyRepFirstName: normalizedData.firstName,
            companyRepLastName: normalizedData.lastName,
            companyRepAddress: normalizedData.address,
            companyRepState: normalizedData.state,

            companyRepCity: normalizedData.city,
            companyRepCountry: normalizedData.country,
            companyRepDateOfBirth: normalizedData.dateOfBirth,
            companyRepGovID: normalizedData.govID,
            companyRepPhone: normalizedData.phone,
            companyRepZip: normalizedData.zip,
          })
        } else {
          form.change('companyRep', {})
        }
      }
    },
    [isRep, normalizedData, hasUniqueRep]
  )

  const errorMsg = useMemo(() => {
    return getContactErrorMessage(normalizedData)
  }, [normalizedData])

  const handleDelete = useCallback(() => {
    if (fieldIndex !== undefined) {
      let filtered = formVals?.ownershipInfoArr?.filter((owner, i) => {
        return i !== fieldIndex
      })
      form.change('ownershipInfoArr', filtered)
    }
    if (!hasUniqueRep) form.change('companyRep', undefined) // if deleted owner is company rep too, delete companyRep data
  }, [formVals?.ownershipInfoArr, fieldPrefix, hasUniqueRep])

  const handleEdit = () => {
    setFieldPrefixFilter(fieldPrefix)
    navigateToPage('personInformation')
  }

  return (
    <StyledCard>
      <CardHeaderContainer>
        <HeaderWrapper>
          <StyledAccountIcon />

          <Typography textVariant="subtitle1Bold">
            {normalizedData.firstName} {normalizedData.lastName}
          </Typography>
        </HeaderWrapper>
        <CardHeaderIconsWrapper>
          <IconWrapper>
            <EditOutlined onClick={handleEdit} />
            <DeleteOutlined onClick={handleDelete} />
          </IconWrapper>
        </CardHeaderIconsWrapper>
      </CardHeaderContainer>
      {isUniqueRep ? (
        <ContactDescription>
          <Typography textVariant="body1">
            {translate('This is an individual who oversees the business and has significant control over the entities')}
          </Typography>
        </ContactDescription>
      ) : (
        <ContactDescription>
          <Typography textVariant="body1">
            {translate('This is a person who owns 25% or more of your business')}
          </Typography>
        </ContactDescription>
      )}

      {hasUniqueRep ? (
        <Tooltip
          title={translate(
            '%{name} has already been entered as the designated Company Representative. If you would like to switch the Company Representative please delete %{name}',
            { name: companyRep.companyRepFirstName + ' ' + companyRep.companyRepLastName }
          )}
          enterDelay={300}
        >
          <StyledFormControlLabel
            value="companyRep"
            control={
              <Switch
                color="secondary"
                onChange={onRepToggle}
                checked={isCompanyRep}
                disabled={isCompanyRep ? false : true}
              />
            }
            label={translate('Company Rep')}
            labelPlacement="start"
          />
        </Tooltip>
      ) : (
        <StyledFormControlLabel
          value="companyRep"
          control={<Switch color="secondary" onChange={onRepToggle} checked={isCompanyRep} disabled={hasUniqueRep} />}
          label={translate('Company Rep')}
          labelPlacement="start"
        />
      )}

      {errorMsg && <Alert severity="error">{translate(errorMsg)}</Alert>}
    </StyledCard>
  )
}
export default ContactCard
