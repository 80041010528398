import { MenuItem, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import { hideFullScreenLoader, myEnergySelectors } from 'ducks/myEnergy'
import Alert from 'elements/Alert'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { debounce } from 'lodash'
import IntegratedCheckoutPaymentCTA from 'myenergy/dialogs/checkout/cashFlow/IntegratedCheckoutPaymentCTA'
import PhoenixApplicationButton from 'myenergy/elements/phoenix/PhoenixApplicationButton'
import { getIsPhoenixV2 } from 'myenergy/elements/phoenix/util'
import { populateCheckoutElementsData } from 'opensolar-checkout'
import { PROPOSAL_ACTION_BAR_HEIGHT } from 'pages/home/proposalActionBar'
import ApplyNowPrompt from 'projectSections/sections/proposal/applyNowPrompt'
import React, { useCallback, useEffect, useState } from 'react'
import { usePermissions, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { FinanceCtaType } from 'resources/financeCTAs/constants'
import { ActionType, EventType } from 'types/tracking'
import { useFeatureFlag } from 'util/split'
import { getDocumentHasDocusignTag, getPostHashParams } from '../../util/docusign'
import {
  detectIframeProposalTemplate,
  getCurrentDate,
  removeArchivedFromPmtName,
  systemNameOrDefault,
} from '../../util/misc'
import CheckoutActions from '../checkout/CheckoutActions'
import ActionMessages from '../elements/ActionMessages'
import SalesDetail from '../SalesDetail'
import AcceptProposal from './AcceptProposal'
import AlertCTA from './AlertCTA'
import CheckoutFinancingCard from './checkoutFinancing'
import Divider from './Divider'
import DropDownComponent from './DropDownComponent'
import StipsView from './financeStipulations/MyEStipulationsView'
import LoanAgreementButton from './loanAgreement/LoanAgreementButton'
import LoanApplication from './loanApplicationButton'
import CreditDecisionDialog from './loanApplicationButton/CreditDecisionDialog'
import Prequal from './Prequal'
import StyledButton from './StyledButton'
import { styles } from './styles'

const mapDataToPaymentOptionsMenuItems = (paymentOptions) => {
  return paymentOptions.map((item) => {
    let title =
      item?.integration === 'mosaic' && item?.title?.includes(' (V')
        ? item.title.substring(0, item.title.indexOf(' (V'))
        : item.title
    title = removeArchivedFromPmtName(title)
    return (
      <MenuItem value={item.id} key={item.id}>
        {title}
      </MenuItem>
    )
  })
}

// Leave customerActionsData here in case there are new changes within this method during transitioning to "opensolar-checkout"
export const customerActionsData = (
  project_id,
  system_uuid,
  payment_option_id,
  available_customer_actions,
  myeStyles,
  acceptButtonStyle,
  acceptButtonStyleDisabled,
  paymentOptionData,
  pricing,
  quotationConfiguration,
  systemSize,
  contacts,
  showDialog,
  setWaitingForAction,
  isPro,
  translate,
  additional_costs,
  selectedPaymentOptionName,
  docusignKBAFailures,
  docusignContractEnabled,
  contractTemplate,
  hasAvailableDocusignAction,
  selectedProject,
  selectedPaymentOptionId,
  attachedPhoenixPaymentOption
) => {
  /*
  We inject id into each actionData but this is just a temporary id which can be used to identify a specific action
  instead of needing to use its index in the list which may not be reliable (e.g. if we filter the actions)
  This was added to allow Bespoke proposals to identify and trigger a particular action.
  */

  let actionsData = []

  // If two options are the same but differ by the payment_method (e.g. credit_card_stripe and offline)
  // Only show first button for a particular payment option, others can be accessed inside the payment form

  // Refactor this so its easy to compose actions based on payment type
  // Elements include:
  //    Action buttons (Enabled=Green, Disabled=Grey, can have a status message underneath)
  //    Action links
  //    Custom element (e.g. "OR" divider, etc)
  //    System/Payment summary description

  var actionsForSystemAndPaymentOption = []
  let hasAvailablePhoenixApplication = false

  available_customer_actions
    .filter((aca) => aca.system_uuid === system_uuid)
    .forEach((available_customer_action) => {
      available_customer_action.actions_available
        .filter((aca) => aca.system_uuid === system_uuid)
        .filter(
          (actionData) =>
            actionData.status_code !== 'hidden' &&
            (actionData.payment_option_id === null ||
              actionData.payment_option_id === payment_option_id ||
              actionData.payment_method === 'phoenix_application')
        )
        .forEach((actionData, i) => {
          if (actionData.payment_method === 'phoenix_application') hasAvailablePhoenixApplication = true
          actionsForSystemAndPaymentOption.push(actionData)
        })
    })

  // only used for finance integrations on the new structure
  const genericIntegratedLoanOptions = actionsForSystemAndPaymentOption.filter((a) => {
    if (!a.is_pro_facing && isPro) return false
    else if (!a.is_customer_facing && !isPro) return false
    return a.finance_integration && !a.document_type && !a.stipulation_name
  })

  if (hasAvailablePhoenixApplication && getIsPhoenixV2(attachedPhoenixPaymentOption?.integration_external_reference)) {
    hasAvailablePhoenixApplication = false
  }

  // don't use docusign actions if they just signed, and don't use cashflow actions since those are always separate
  var firstAcceptProposalAction = actionsForSystemAndPaymentOption.filter(
    (a) =>
      a !== 'hidden' &&
      (a.payment_method === 'offline' ||
        a.payment_method === 'none' ||
        a.payment_method === 'credit_card_stripe' ||
        (a.payment_method === 'docusign' &&
          (actionsForSystemAndPaymentOption.length === 1 ||
            !window.location.href.includes('event=signing_complete') ||
            !window.location.href.includes('all_signed=true'))))
  )[0]

  const unpaidCashFlowDepositAction = actionsForSystemAndPaymentOption?.filter(
    (a) => a.payment_method === 'cashflow' && a.status_code === 'available'
  )?.[0]

  var elements = []
  if (paymentOptionData.payment_type === 'cash') {
    // Use the first proposal acceptance action for this system and payment option
    elements = [{ type: 'AcceptProposal', actionData: firstAcceptProposalAction }]
  } else {
    // make a map of stips where the key is review status and the value is an array of all stips with that status
    const stipulationMap = {}
    const stipulationActions = actionsForSystemAndPaymentOption.filter((action) => {
      return action?.stipulation_review_status
    })
    if (stipulationActions && stipulationActions.length > 0) {
      stipulationActions.forEach((action) => {
        if (!stipulationMap[action.stipulation_review_status])
          stipulationMap[action.stipulation_review_status] = [action]
        else stipulationMap[action.stipulation_review_status].push(action)
      })
    }

    // Detect the type of loan
    // only used for finance integrations that are still on the original structure
    const actionIntegratedLoanOptions = actionsForSystemAndPaymentOption.filter(
      (a) =>
        (a.finance_integration ||
          [
            'sunlight_loan_application',
            'sunlight_loan_prequal',
            'loanpal_application',
            'mosaic_application',
            'sungage_application',
            'brighte_application',
            'dividend_application',
            'phoenix_application',
          ].indexOf(a.payment_method) !== -1) &&
        !a.document_type &&
        !a.stipulation_name
    )

    const loanAgreementActionsByPartner = {}
    actionsForSystemAndPaymentOption.forEach((action) => {
      if (action.document_type === 'mosaic_loan_agreement') {
        if (!loanAgreementActionsByPartner['mosaic']) loanAgreementActionsByPartner['mosaic'] = action
      } else if (action.document_type === 'loanpal_loan_agreement') {
        if (!loanAgreementActionsByPartner['loanpal']) loanAgreementActionsByPartner['loanpal'] = action
      } else if (action.document_type === 'dividend_loan_agreement') {
        if (!loanAgreementActionsByPartner['dividend']) loanAgreementActionsByPartner['dividend'] = action
      }
    })

    // Special case: If sunlight_loan_application is available then ensure we use it to prevent accidentally choosing
    // sunlight_loan_prequal which does not show the full loan application option
    // Otherwise, just choose the first available loan application option.
    const actionIntegratedLoan =
      actionIntegratedLoanOptions.indexOf('sunlight_loan_application') !== -1
        ? 'sunlight_loan_application'
        : actionIntegratedLoanOptions[0]

    let loanType = actionIntegratedLoan ? actionIntegratedLoan?.payment_method : 'standard'
    // a hack to say even though the application is done we still have stips to deal with so keep this in integrated finance action mode
    if (
      loanType === 'standard' &&
      paymentOptionData.integration === 'loanpal' &&
      Object.keys(stipulationMap).length > 0
    ) {
      loanType = 'loanpal_application'
    }

    const actionPrequal = actionsForSystemAndPaymentOption.filter(
      (a) => a.payment_method === 'sunlight_loan_prequal'
    )[0]

    elements = {
      sunlight_loan_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },

        // Toggle type for Divider based on other actions
        {
          type: 'Divider',
          hide: !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation:
            actionIntegratedLoan && ['complete', 'disabled'].includes(actionIntegratedLoan?.status_code)
              ? 'button'
              : 'link',
          loanType,
        },
        { type: 'Prequal', actionData: actionPrequal, isPrimaryAction: false, loanType },
      ],
      sunlight_loan_prequal: [
        { type: 'Prequal', actionData: actionPrequal, isPrimaryAction: true, loanType },

        // Toggle type for Divider based on other actions
        {
          type: 'Divider',
          hide: !firstAcceptProposalAction,
          showOr: firstAcceptProposalAction && firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan && actionIntegratedLoan?.status_code === 'complete' ? 'button' : 'link',
          loanType,
        },
      ],
      phoenix_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        {
          type: 'Divider',
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan?.status_code === 'complete' || isPro ? 'button' : 'link',
          loanType,
        },
      ],
      loanpal_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        { type: 'FinanceStipulations', actionData: stipulationMap, variation: 'button', loanType },
        { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner['loanpal'], loanType },
        {
          type: 'Divider',
          hide: !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            !actionIntegratedLoan?.stipulation_review_status &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation:
            actionIntegratedLoan?.status_code === 'available' ||
            stipulationMap['created']?.length > 0 ||
            (loanAgreementActionsByPartner['loanpal'] &&
              loanAgreementActionsByPartner['loanpal'].status_code !== 'complete')
              ? 'link'
              : 'button',
          loanType,
        },
      ],
      mosaic_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        {
          type: 'Divider',
          hide: !stipulationMap,
          showOr: false,
          loanType,
        },
        { type: 'FinanceStipulations', actionData: stipulationMap, variation: 'button', loanType },
        {
          type: 'Divider',
          hide: !stipulationMap,
          showOr: false,
          loanType,
        },
        { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner['mosaic'], loanType },
        {
          type: 'Divider',
          hide:
            (!loanAgreementActionsByPartner['mosaic'] && actionIntegratedLoan?.status_code === 'complete') ||
            !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            (loanAgreementActionsByPartner['mosaic']?.status_code === 'available' ||
              actionIntegratedLoan?.status_code === 'available') &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation:
            actionIntegratedLoan?.status_code === 'complete' &&
            (!loanAgreementActionsByPartner['mosaic'] ||
              loanAgreementActionsByPartner['mosaic'].status_code === 'complete')
              ? 'button'
              : 'link',
          loanType,
        },
      ],
      sungage_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        {
          type: 'Divider',
          hide: actionIntegratedLoan?.status_code === 'complete' || !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan?.status_code === 'complete' ? 'button' : 'link',
          loanType,
        },
      ],
      dividend_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner['dividend'], loanType },
        {
          type: 'Divider',
          hide: actionIntegratedLoan?.status_code === 'complete' || !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan?.status_code === 'complete' ? 'button' : 'link',
          loanType,
        },
      ],
      brighte_application: [
        { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType },
        {
          type: 'Divider',
          hide: actionIntegratedLoan?.status_code === 'complete' || !firstAcceptProposalAction,
          showOr:
            actionIntegratedLoan &&
            actionIntegratedLoan?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType,
        },
        {
          type: 'AcceptProposal',
          actionData: firstAcceptProposalAction,
          variation: actionIntegratedLoan?.status_code === 'complete' ? 'button' : 'link',
          loanType,
        },
      ],
      standard: [{ type: 'AcceptProposal', actionData: firstAcceptProposalAction, variation: 'button', loanType }],
    }[loanType]
  }

  if (hasAvailablePhoenixApplication) {
    elements.push({ type: 'PhoenixApplication', actionData: firstAcceptProposalAction })
  }

  // Iterate over initial elements to make some adjustments to elements and build the final actionData list
  elements?.forEach((element, index) => {
    if (!element.actionData && element.type !== 'Divider') {
      console.warn('Missing element.actionData from customer action', element)
      // skip to next, not possible to render an action without actionData
      return
    }
    //hack to deal with the fact that the user will be returned from Docusign after signing before our db reflects the fact that they have signed
    if (
      element.actionData &&
      element.actionData.status_code === 'available' &&
      window.location.href.includes('event=signing_complete') &&
      window.location.href.includes('all_signed=true')
    ) {
      if (element.actionData.payment_method === 'offline') {
        // if they just signed a DS contract and all they have left is an offline payment don't show that offline payment CTA
        return
      }
      if (element.actionData.payment_method === 'docusign') {
        // Beware this modifies actionData, it would be nicer to handle above
        element.actionData.status_code = 'complete'
      } else if (element.type === 'AcceptProposal') {
        // Beware this modifies actionData, it would be nicer to handle above
        element.actionData.collect_signature = false

        if (element.actionData.payment_method === 'none') {
          // Beware this modifies actionData, it would be nicer to handle above
          element.actionData.status_code = 'complete'
        }
      }
    }

    // @TODO: Consolidate actionData.eror and actionData.messages (errors and warnings) into a single place
    if (element.actionData && element.actionData.error && element.actionData.error.length > 0) {
      actionsData.push({
        type: 'ActionError',
        error: element.actionData.error,
        id: actionsData.length + 1,
      })
    }

    // Only show actionData validation errors/warnings for Pros. Don't show to a customer.
    // If the action will fail for the customer, let them attempt it first.
    if (isPro && element.actionData && element.actionData.messages && element.actionData.messages.length) {
      actionsData.push({
        type: 'ActionMessages',
        messages: element.actionData.messages,
        id: actionsData.length + 1,
      })
    }

    // Refactor to make this standard for all actions
    if (element.type === 'AcceptProposal') {
      element.actionData.transactionRequestData = Object.assign({}, element.actionData, {
        paymentOptionData: paymentOptionData,
        pricing: pricing,
        quotationConfiguration: quotationConfiguration,
        systemSize: systemSize,
        additional_costs,
      })
    }

    var elementTypesToAddAction = [
      'LoanApplication',
      'AcceptProposal',
      'Prequal',
      'FinanceStipulations',
      'LoanAgreement',
      'PhoenixApplication',
    ]

    if (elementTypesToAddAction.includes(element.type) || (element.type === 'Divider' && !element.hide)) {
      element.id = actionsData.length + 1
      actionsData.push(element)
    }
  })

  genericIntegratedLoanOptions?.forEach((loanOption) => {
    // keeping this simple for now since we're only supporting one finance CTA and accept proposal
    const prioritizeApplication = loanOption?.priority === 0 || loanOption?.priority === 1
    const justFinishedDocusign =
      window.location.href.includes('event=signing_complete') && window.location.href.includes('all_signed=true')
    if (firstAcceptProposalAction && justFinishedDocusign) firstAcceptProposalAction.status_code = 'complete'
    let tempActions = []
    let actionType = 'LoanApplication'
    if (['ALERT_WARNING', 'ALERT_INFO', 'ALERT_ERROR'].includes(loanOption.cta_type)) {
      actionType = 'AlertCTA'
    }
    tempActions.push({ type: actionType, actionData: loanOption, loanType: loanOption.payment_method })
    if (firstAcceptProposalAction) {
      if (actionType !== 'AlertCTA') {
        tempActions.push({
          type: 'Divider',
          hide: loanOption?.status_code === 'complete',
          showOr:
            loanOption &&
            loanOption?.status_code === 'available' &&
            firstAcceptProposalAction &&
            firstAcceptProposalAction.status_code === 'available',
          loanType: loanOption.payment_method,
        })
      }
      tempActions.push({
        type: 'AcceptProposal',
        actionData: firstAcceptProposalAction,
        variation: loanOption?.status_code === 'complete' || !prioritizeApplication ? 'button' : 'link',
        loanType: loanOption.payment_method,
      })
    }

    if (!prioritizeApplication && firstAcceptProposalAction) tempActions.reverse()
    actionsData.push(...tempActions)
  })

  // if after giong through the above scenarios we don't have any actions in actionsData but we do
  // have a value in firstAcceptProposalAction, then just add firstAcceptProposalAction directly
  if (!actionsData?.length && firstAcceptProposalAction) {
    actionsData.push({
      type: 'AcceptProposal',
      actionData: firstAcceptProposalAction,
      variation: 'button',
    })
  }

  if (unpaidCashFlowDepositAction) {
    const variation = firstAcceptProposalAction?.status_code === 'available' ? 'link' : 'button'
    if (variation === 'button') {
      actionsData.unshift({
        type: 'CashFlowDeposit',
        actionData: unpaidCashFlowDepositAction,
        variation: variation,
      })
    } else {
      actionsData.push({
        type: 'CashFlowDeposit',
        actionData: unpaidCashFlowDepositAction,
        variation: variation,
      })
    }
  }
  return actionsData
}

export const customerActionButtons = (
  project_id,
  system_uuid,
  payment_option_id,
  available_customer_actions,
  myeStyles,
  acceptButtonStyle,
  acceptButtonStyleDisabled,
  paymentOptionData,
  pricing,
  quotationConfiguration,
  systemSize,
  contacts,
  showDialog,
  setWaitingForAction,
  isPro,
  translate,
  additional_costs,
  selectedPaymentOptionName,
  docusignKBAFailures,
  docusignContractEnabled,
  contractTemplate,
  hasAvailableDocusignAction,
  selectedProject,
  selectedPaymentOptionId,
  attachedPhoenixPaymentOption,
  hideCheckoutButton,
  elements
) => {
  let actions = []

  // let elements = populateCheckoutElementsData(
  //   project_id,
  //   system_uuid,
  //   payment_option_id,
  //   available_customer_actions,
  //   myeStyles,
  //   acceptButtonStyle,
  //   acceptButtonStyleDisabled,
  //   paymentOptionData,
  //   pricing,
  //   quotationConfiguration,
  //   systemSize,
  //   contacts,
  //   showDialog,
  //   setWaitingForAction,
  //   isPro,
  //   translate,
  //   additional_costs,
  //   selectedPaymentOptionName,
  //   docusignKBAFailures,
  //   docusignContractEnabled,
  //   contractTemplate,
  //   hasAvailableDocusignAction,
  //   selectedProject,
  //   selectedPaymentOptionId,
  //   attachedPhoenixPaymentOption,
  //   hideCheckoutButton
  // )

  elements.forEach((element) => {
    var args = {
      ...element,
      translate,
      showDialog,
      setWaitingForAction,
      myeStyles,
      acceptButtonStyle,
      acceptButtonStyleDisabled,
      paymentOptionData,
      pricing,
      quotationConfiguration,
      systemSize,
      isPro,
      additional_costs,
      // Only inject actions which relate to the selected system
      available_customer_actions: available_customer_actions.filter((aca) => aca.system_uuid === system_uuid),
      selectedPaymentOptionName,
      docusignKBAFailures,
      hasAvailableDocusignAction,
      selectedProject,
      selectedPaymentOptionId,
      hideCheckoutButton,
    }

    // @TODO: Consolidate actionData.eror and actionData.messages (errors and warnings) into a single place
    if (element.actionData && element.actionData.error && element.actionData.error.length > 0) {
      actions.push(
        <p key={'ActionDataError' + actions.length} className="small">
          {element.actionData.error}
        </p>
      )
    }

    // Only show actionData validation errors/warnings for Pros. Don't show to a customer.
    // If the action will fail for the customer, let them attempt it first.
    if (isPro && element.actionData && element.actionData.messages && element.actionData.messages.length) {
      actions.push(<ActionMessages key={'ActionMessages' + actions.length} messages={element.actionData.messages} />)
    }

    if (element.type === 'LoanApplication') {
      actions.push(<LoanApplication {...args} key={'LoanApplication' + actions.length} />)
    } else if (element.type === 'Divider' && !element.hide) {
      actions.push(<Divider {...args} key={'Divider' + actions.length} />)
    } else if (element.type === 'AcceptProposal') {
      actions.push(<AcceptProposal {...args} key={'AcceptProposal' + actions.length} />)
    } else if (element.type === 'Prequal') {
      actions.push(<Prequal key={'Prequal' + actions.length} {...args} />)
    } else if (element.type === 'FinanceStipulations') {
      actions.push(
        <StipsView stipulationsMap={element.actionData} loanType={element.loanType} myeStyles={args.myeStyles} />
      )
    } else if (element.type === 'LoanAgreement') {
      actions.push(
        <LoanAgreementButton {...args} loanType={element.loanType} key={`${element.loanType}_loan_agreement`} />
      )
    } else if (element.type === 'PhoenixApplication') {
      actions.push(
        <PhoenixApplicationButton
          {...args}
          key={`phoenix_application_${actions.length}`}
          attachedPhoenixPaymentOption={attachedPhoenixPaymentOption}
        />
      )
    } else if (element.type === 'AlertCTA') {
      actions.push(<AlertCTA actionData={element?.actionData} />)
    } else if (element.type === 'CashFlowDeposit') {
      actions.push(
        <IntegratedCheckoutPaymentCTA
          orgId={element?.actionData?.org_id}
          systemUuid={element?.actionData?.system_uuid}
          paymentOptionId={element?.actionData?.payment_option_id}
          projectId={element?.actionData?.project_id}
          variation={element.variation}
          actionTitle={element?.actionData?.action_title}
        />
      )
    }
  })
  return actions
}

const getHeightOfDomById = (id) => {
  const domRect = document.getElementById(id)?.getBoundingClientRect()
  return domRect ? domRect.height : 0
}

const getSideBarSpareSpace = (isPro) => {
  const heightOfHeader = 100
  const heightOfFooter = isPro ? 40 : 0
  const heightOfSystemOptions = getHeightOfDomById('proposal-system-options')
  const heightOfCustomerActions = getHeightOfDomById('proposal-customer-actions')
  const heightOfSalesDetail = getHeightOfDomById('proposal-sales-detail')
  return (
    window.innerHeight -
    heightOfHeader -
    heightOfFooter -
    heightOfSystemOptions -
    heightOfCustomerActions -
    heightOfSalesDetail
  )
}

//To do: refactor css by using classes

export const SelectionComponent = (props) => {
  const { permissions } = usePermissions()
  const isPro = permissions?.includes('is_pro')
  if (isPro) return <SelectionComponentWithForm {...props} />
  else return <SelectionComponentContent {...props} form={null} formState={null} />
}

const SelectionComponentWithForm = (props) => {
  const form = useForm()
  const isDirty = form ? form.mutators.getFormDirtyFields()?.length > 0 : false
  const formState = useFormState()
  return <SelectionComponentContent {...props} form={form} formState={formState} isDirty={isDirty} />
}

const SelectionComponentContent = React.forwardRef((props, ref) => {
  const [showPaymentOptionButtons, setPaymentOptionButtonsVisibility] = useState(false)
  const [showFullLongNames, setShowFullNames] = useState(false)
  const [pmtIdsFoundTracked, setPmtIdsFoundTracked] = useState([])
  const [hasSkippedInitialPmtOptionEvent, setHasSkippedInitialPmtOptionEvent] = useState(false)
  const [proposalData, setProposalData] = useState(props.proposalData)
  const [hasAvailableDocusignAction, setHasAvailableDocusignAction] = useState(false)
  const viewAsCustomer = useSelector((state) => state.viewAsCustomer)
  const translate = useTranslate()
  const form = props.form || null
  const formValues = props.formState ? props.formState.values : null
  const {
    isMobileFixedSelection,
    isMobile,
    onSystemChange,
    isPro,
    onPaymentMethodChange,
    quotationConfiguration,
    showDialog,
    setWaitingForAction,
    isFixedHeader,
    attachedPhoenixPaymentOption,
  } = props

  var { showButtonsForSinglePaymentOption } = detectIframeProposalTemplate(proposalData.proposalTemplateSettings)

  const showCreditDecisionDialog = useSelector((state) => state.myEnergy.showCreditDecisionDialog)
  const forceSystemUuidSold = useSelector((state) => state.myEnergy.forcedSoldSystemUuid)
  const forcePmtIdSold = useSelector((state) => state.myEnergy.forcedSoldPmtId)
  const hasCalcError = !!(
    (Array.isArray(proposalData.selectedProject?.calculation_error_messages) &&
      proposalData.selectedProject?.calculation_error_messages.length > 0) ||
    proposalData.selectedProject?.last_calculation_error
  )
  useEffect(() => {
    if (!hasAvailableDocusignAction) {
      if (proposalData.selectedProject.available_customer_actions) {
        let foundDocusign = false
        proposalData.selectedProject.available_customer_actions.forEach((actions) => {
          if (actions.actions_available)
            actions.actions_available.forEach((action) => {
              if (action.payment_method === 'docusign' && action.status_code === 'available') foundDocusign = true
            })
        })
        //the endpoint will return docusign if the account is enabled we need to do an extra check to ensure the current template has a docusign tag
        if (foundDocusign && getDocumentHasDocusignTag(proposalData.selectedPaymentOption.contract_template))
          setHasAvailableDocusignAction(true)
      }
    }
  }, [])

  //hook to manually force changes to proposal data when user comes back after signing a contract in Docusign. Use params to select sold system and pmt option
  useEffect(() => {
    const proposalDataToSave = props.proposalData
    const params = getPostHashParams()
    if (params && params.get('event') === 'signing_complete') {
      const soldSystemId = params.get('sold_system')
      if (soldSystemId) {
        const soldSystem = proposalDataToSave.systems?.find((sys) => sys.id === parseInt(soldSystemId))
        proposalDataToSave.selectedSystem = soldSystem
        proposalDataToSave.systems = [soldSystem]
      }

      const soldPmtId = params.get('sold_pmt_option')
      if (soldPmtId) {
        const soldPmtOption = proposalDataToSave.selectedSystem?.payment_options?.find(
          (pmt) => pmt.id === parseInt(soldPmtId)
        )
        if (soldPmtOption) {
          proposalDataToSave.selectedPaymentOption = soldPmtOption
          proposalDataToSave.selectedSystem.payment_options = [soldPmtOption]
        }
      }

      if (form && formValues && soldSystemId && soldPmtId) {
        const soldSystem = formValues.systems.find((x) => x.id === parseInt(soldSystemId))
        form.mutators.updateFieldSilently('project_sold', 2)
        form.mutators.updateFieldSilently('contract_date', getCurrentDate())
        form.mutators.updateFieldSilently('system_sold', soldSystem.url)
        form.mutators.updateFieldSilently(
          'payment_option_sold',
          `${window.API_BASE_URL_ABSOLUTE}orgs/${proposalData.org.id}/payment_options/${soldPmtId}/`
        )
      }
    }
    setProposalData(proposalDataToSave)
  }, [props.proposalData])

  useEffect(() => {
    // no longer logging since we can't log for end customers
    // only run this on mount, don't want to duplicate
    // props.proposalData?.selectedSystem?.payment_options?.forEach((paymentOption, index) => {
    //   const payload = {
    //     index,
    //     payment_type: paymentOption.payment_type,
    //     payment_option_id: paymentOption.id,
    //     project_id: proposalData.selectedProject.id,
    //     integration: paymentOption.integration || 'none',
    //     is_dropdown: !showPaymentOptionButtons,
    //   }
    //   //logAmplitudeEvent('payment_option_in_opened_proposal', payload)
    // })
  }, [])

  const updatePaymentOptionButtonsVisibility = useCallback(() => {
    let { payment_options = [] } = proposalData.selectedSystem

    if (forcePmtIdSold) {
      payment_options = payment_options.filter((pmt) => pmt.id === forcePmtIdSold)
    }
    const heightOfEachPaymentOptionButton = 55
    const heightOfPaymentOptionTitle = 74
    const spareSpace = getSideBarSpareSpace(isPro)
    const heightOfPaymentOptionButtons =
      heightOfPaymentOptionTitle + heightOfEachPaymentOptionButton * payment_options.length
    let displayButtons = false
    if (payment_options.length === 1) {
      displayButtons = true
    } else if (!isMobile) {
      //show payment options as buttons if there is enough spare space in sidebar
      displayButtons = spareSpace > heightOfPaymentOptionButtons
    }

    if (showPaymentOptionButtons !== displayButtons) {
      setPaymentOptionButtonsVisibility(displayButtons)
    }
    let hasFincoWithFullNameReq = payment_options?.filter((pmt) => !!pmt.integration)?.length > 0
    if (hasFincoWithFullNameReq !== showFullLongNames) setShowFullNames(hasFincoWithFullNameReq)
    // now that we know what the UI will be, fire amplitude events that depend upon the UI
    let ids = []

    // temporarily disabling since we cannot track end customer events
    // proposalData.selectedSystem.payment_options.forEach((paymentOption, index) => {
    //   if (pmtIdsFoundTracked.includes(paymentOption.id)) return
    //   const payload = {
    //     index,
    //     payment_type: paymentOption.payment_type,
    //     payment_option_id: paymentOption.id,
    //     project_id: proposalData.selectedProject.id,
    //     integration: paymentOption.integration || 'none',
    //     is_dropdown: !showPaymentOptionButtons,
    //   }
    //   if (displayButtons && !hasFincoWithFullNameReq) {
    //     logAmplitudeEvent('payment_option_visible', payload)
    //   } else if (index === 0) {
    //     logAmplitudeEvent('payment_option_visible', payload)
    //   }
    //   ids.push(paymentOption.id)
    // })
    setPmtIdsFoundTracked([...pmtIdsFoundTracked, ...ids])
  }, [proposalData.selectedSystem, isMobile, showPaymentOptionButtons, pmtIdsFoundTracked])

  useEffect(() => {
    const handleResize = debounce(() => {
      updatePaymentOptionButtonsVisibility()
    }, 500)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    updatePaymentOptionButtonsVisibility()
  }, [proposalData.selectedSystem])

  useEffect(() => {
    if (proposalData.selectedPaymentOption) {
      //don't track the selected payment option on mount, this should only track the user changing the payment option
      if (!hasSkippedInitialPmtOptionEvent) setHasSkippedInitialPmtOptionEvent(true)
      else {
        // temporarily disalbing since we cannot track end customer events
        // const payload = {
        //   payment_type: proposalData.selectedPaymentOption.payment_type,
        //   integration: proposalData.selectedPaymentOption.integration || 'none',
        //   is_dropdown: true,
        // }
        // logAmplitudeEvent('payment_option_selected', payload)
      }
    }
  }, [proposalData?.selectedPaymentOption?.id, hasSkippedInitialPmtOptionEvent])

  const { trackEvent } = useTrackComponent({
    componentKey: 'select_component',
    eventName: 'User Viewed Option',
  })

  const defaultLabelWrapperStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  const mapSystemOptionsToButtons = (onChange) => {
    let systems = proposalData.systems
    if (forceSystemUuidSold) systems = systems.filter((sytem) => sytem.uuid === forceSystemUuidSold)
    const selectedSystemId = proposalData.selectedSystem.uuid

    return systems.map((system, index) => {
      const style = isMobile ? styles.mobile : styles.standard
      const buttonStyle = system.uuid === selectedSystemId ? style.selectedButton : style.optionsButton

      return (
        <Tooltip
          PopperProps={{ style: { zIndex: 2001 } }}
          title={translate(systemNameOrDefault(system))}
          enterDelay={300}
          key={index}
        >
          <StyledButton
            myeStyles={proposalData.myeStyles}
            isSelected={system.uuid === selectedSystemId}
            style={buttonStyle}
            onClick={() => {
              trackEvent(
                EventType.USER_INTERACTION,
                { type: ActionType.SELECT },
                { uuid: system.uuid, system: systemNameOrDefault(system), source: 'system selection' }
              )
              onChange(system.uuid)
            }}
            disableFocusRipple={true}
          >
            <span style={{ ...defaultLabelWrapperStyle }}>{translate(systemNameOrDefault(system))}</span>
          </StyledButton>
        </Tooltip>
      )
    })
  }

  const paymentOptionButton = (onChange, minimumRegularPaymentDisplay) => {
    let paymentOptions = proposalData.selectedSystem.payment_options
    if (forcePmtIdSold) paymentOptions = paymentOptions.filter((pmt) => pmt.id === forcePmtIdSold)
    const selectedPaymentOptionId = forcePmtIdSold ? forcePmtIdSold : proposalData.selectedPaymentOption.id
    const buttonWidth = isMobileFixedSelection ? '100%' : '240px'
    return (
      <>
        {!!minimumRegularPaymentDisplay && (
          <span style={{ ...styles.general.minPmtDisplayText }}>{minimumRegularPaymentDisplay}</span>
        )}
        {paymentOptions.map((paymentOption, index) => {
          const isSelectedPaymentOption = paymentOption.id === selectedPaymentOptionId
          const style = isMobile ? styles.mobile : styles.standard
          const wrapperStyle = isSelectedPaymentOption ? style.selectedButton : style.optionsButton
          const labelWrapperStyle = {
            padding: '0px 0px 0px 4px',
            fontWeight: 'normal',
            textTransform: 'none',
            fontSize: '16px',
            marginRight: 'auto',
            overflowWrap: 'break-word',
          }
          const stylesForThisButton = { ...proposalData.myeStyles }
          if (paymentOption.integration === 'plenti') {
            stylesForThisButton.height = 65
          }

          if (paymentOption.integration === 'brighte')
            paymentOption.description = `${proposalData.currencySymbol || '$'}${Math.round(
              paymentOption.regular_payment
            )} per fortnight`
          return (
            <div
              key={index}
              style={{ display: 'inline-block', wrapperStyle, width: buttonWidth, marginTop: index > 0 ? 10 : 0 }}
            >
              <Tooltip PopperProps={{ style: { zIndex: 2001 } }} title={paymentOption.title} enterDelay={300}>
                <StyledButton
                  key={paymentOption.id}
                  myeStyles={stylesForThisButton}
                  isSelected={isSelectedPaymentOption}
                  onClick={() => {
                    trackEvent(
                      EventType.USER_INTERACTION,
                      { type: ActionType.SELECT },
                      {
                        id: paymentOption.id,
                        paymentOption: paymentOption.title,
                        source: 'payment_option',
                      }
                    )
                    onChange(paymentOption.id)
                  }}
                  disableFocusRipple={true}
                >
                  {['brighte'].includes(paymentOption.integration) ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>{paymentOption.title}</span>
                      <span
                        style={{
                          fontSize: '10px',
                          marginTop: 0,
                        }}
                      >
                        {paymentOption.description}
                      </span>
                    </div>
                  ) : (
                    <span style={{ ...defaultLabelWrapperStyle, labelWrapperStyle }}>{paymentOption.title}</span>
                  )}
                </StyledButton>
              </Tooltip>
            </div>
          )
        })}
      </>
    )
  }

  const mapDataToSystemOptionsMenuItems = (systems) => {
    return systems.map((item) => {
      return (
        <MenuItem value={item.uuid} key={item.uuid}>
          {systemNameOrDefault(item)}
        </MenuItem>
      )
    })
  }

  const renderSystemOptions = () => {
    const displayButtons = proposalData.systems.length === 1 || (!isMobile && proposalData.systems.length <= 4)
    const systemOptionButtons = mapSystemOptionsToButtons(onSystemChange)
    const systemOptionsMenuItems = mapDataToSystemOptionsMenuItems(proposalData.systems)
    return displayButtons ? (
      systemOptionButtons
    ) : (
      <DropDownComponent
        myeStyles={proposalData.myeStyles}
        onChange={onSystemChange}
        menuItems={systemOptionsMenuItems}
        tooltipLabel={systemNameOrDefault(proposalData.selectedSystem)}
        selectedValue={proposalData.selectedSystem.uuid}
        expandedIcon
      />
    )
  }

  const renderPaymentMethodOptions = (minimumRegularPaymentDisplay, isMobileFixedSelection) => {
    let { payment_options } = proposalData.selectedSystem

    if (forcePmtIdSold) payment_options = payment_options.filter((pmt) => pmt.id === forcePmtIdSold)
    const alignItemsVal = isMobile ? 'flex-end' : 'flex-start'
    const paymentOptionsMenuItems = mapDataToPaymentOptionsMenuItems(payment_options)
    const paymentOptionsButton = paymentOptionButton(onPaymentMethodChange, minimumRegularPaymentDisplay)

    const showDescriptionBelow =
      payment_options?.filter(
        (pmt) => pmt.integration === 'loanpal' && pmt.id === proposalData.selectedPaymentOption.id
      )?.length > 0

    const onOpen = () => {
      // const unselectedPaymentOptions = proposalData.selectedSystem.payment_options.filter((opt) => {
      //   return opt.id !== proposalData.selectedPaymentOption?.id
      // })
      // temporarily disabling since we cannot track end customer events
      // unselectedPaymentOptions.forEach((paymentOption) => {
      //   const payload = {
      //     payment_type: paymentOption.payment_type,
      //     integration: paymentOption.integration || 'none',
      //     is_dropdown: true,
      //   }
      //   logAmplitudeEvent('payment_option_visible', payload)
      // })
    }
    return showPaymentOptionButtons && !showFullLongNames ? (
      paymentOptionsButton
    ) : (
      <div style={{ ...styles.general.minPmtDisplayWrapper, alignItems: alignItemsVal }}>
        {!!minimumRegularPaymentDisplay && !isMobileFixedSelection && (
          <span style={{ ...styles.general.minPmtDisplayText }}>{minimumRegularPaymentDisplay}</span>
        )}
        <DropDownComponent
          onChange={onPaymentMethodChange}
          tooltipLabel={proposalData.selectedPaymentOption.title}
          menuItems={paymentOptionsMenuItems}
          selectedValue={proposalData.selectedPaymentOption.id}
          selectedPaymentOption={proposalData.selectedPaymentOption}
          myeStyles={proposalData.myeStyles}
          expandedIcon={true}
          wrapSelectedText={!isMobileFixedSelection}
          onOpen={onOpen}
        />
        {showDescriptionBelow && (
          <span className="small" style={{ marginTop: '2px' }}>
            {proposalData.selectedPaymentOption.description}
          </span>
        )}
        {!!minimumRegularPaymentDisplay && isMobileFixedSelection && (
          <span style={{ ...styles.general.minPmtDisplayText }}>{minimumRegularPaymentDisplay}</span>
        )}
      </div>
    )
  }

  const renderPaymentOptionCTA = () => {
    const { finance_ctas } = proposalData.selectedPaymentOption

    if (finance_ctas?.find(({ type }) => type === FinanceCtaType.CHECKOUT_FINANCE)) {
      const {
        finance_product_category_id,
        disabled_finance_product_category_ids = [],
      } = proposalData.selectedPaymentOption

      if (!finance_product_category_id || disabled_finance_product_category_ids?.includes(finance_product_category_id))
        return null

      return (
        <CheckoutFinancingCard
          projectId={proposalData.selectedProject.id}
          orgId={proposalData?.selectedPaymentOption?.org_id}
          systemUuid={proposalData?.selectedSystem?.uuid}
          paymentOptionId={proposalData?.selectedPaymentOption?.id}
          amountDue={proposalData?.selectedPaymentOption?.pricing?.system_price_including_tax}
          hidden={isMobileFixedSelection}
          isProposal
        />
      )
    }

    return null
  }

  const assigned_team_member = proposalData.selectedProject.assigned_team_member || {}
  const displayContactDetail = assigned_team_member.display
  const style = isMobile ? styles.mobile : styles.standard
  const direction = isMobileFixedSelection ? 'row' : 'column'
  const extraMarginTop = isMobile ? '' : 40

  const elementsData = populateCheckoutElementsData(
    proposalData.selectedProject.id,
    proposalData.selectedSystem.uuid,
    proposalData.selectedPaymentOption.id,
    proposalData.selectedProject.available_customer_actions,
    proposalData.myeStyles,
    styles.general.acceptButton,
    styles.general.acceptButtonDisabled,
    proposalData.selectedPaymentOption,
    proposalData.selectedSystem.pricing,
    quotationConfiguration,
    proposalData.selectedSystem.kw_stc,
    proposalData.selectedProject.contacts,
    showDialog,
    setWaitingForAction,
    isPro,
    translate,
    proposalData.selectedSystem.additional_costs,
    proposalData.selectedPaymentOption.title,
    proposalData.selectedProject.docusign_contract_auth_failures,
    proposalData?.org?.enable_contracts_in_docusign,
    proposalData.selectedPaymentOption.contract_template,
    hasAvailableDocusignAction,
    proposalData.selectedProject,
    proposalData.selectedPaymentOptionId,
    attachedPhoenixPaymentOption,
    proposalData.checkoutHidden
  )
  const actionButtons = customerActionButtons(
    proposalData.selectedProject.id,
    proposalData.selectedSystem.uuid,
    proposalData.selectedPaymentOption.id,
    proposalData.selectedProject.available_customer_actions,
    proposalData.myeStyles,
    styles.general.acceptButton,
    styles.general.acceptButtonDisabled,
    proposalData.selectedPaymentOption,
    proposalData.selectedSystem.pricing,
    quotationConfiguration,
    proposalData.selectedSystem.kw_stc,
    proposalData.selectedProject.contacts,
    showDialog,
    setWaitingForAction,
    isPro,
    translate,
    proposalData.selectedSystem.additional_costs,
    proposalData.selectedPaymentOption.title,
    proposalData.selectedProject.docusign_contract_auth_failures,
    proposalData?.org?.enable_contracts_in_docusign,
    proposalData.selectedPaymentOption.contract_template,
    hasAvailableDocusignAction,
    proposalData.selectedProject,
    proposalData.selectedPaymentOptionId,
    attachedPhoenixPaymentOption,
    proposalData.checkoutHidden,
    elementsData
  )
  const systemStyle = isMobileFixedSelection ? styles.fixedMobileSelection.system : style.system
  const paymentStyle = isMobileFixedSelection ? styles.fixedMobileSelection.payment : style.payment
  const showFullScreenLoader = useSelector(myEnergySelectors.getShowFullScreenLoader)
  const dispatch = useDispatch()
  let minimumRegularPaymentDisplay
  let { payment_options } = proposalData.selectedSystem
  if (forcePmtIdSold && payment_options) payment_options = payment_options.filter((pmt) => pmt.id === forcePmtIdSold)
  if (payment_options && payment_options.length > 1) {
    let indexOfMinPmt
    let minPmt
    const paymentTypesToIgnore = ['ppa', 'cash']
    for (let i = 0, len = payment_options.length; i < len; i++) {
      if (payment_options[i].regular_payment && !paymentTypesToIgnore.includes(payment_options[i].payment_type)) {
        if (minPmt === undefined || payment_options[i].regular_payment < minPmt) {
          minPmt = payment_options[i].regular_payment
          indexOfMinPmt = i
        }
      }
    }
    const currencySymbol = proposalData.currencySymbol || '$'
    const minPmtDisplay = minPmt >= 0 ? `${currencySymbol}${Math.round(minPmt)}` : null
    if (minPmtDisplay) {
      let paymentFrequency = 'per period'
      if (payment_options[indexOfMinPmt].payment_frequency === 'monthly') paymentFrequency = 'per month'
      else if (payment_options[indexOfMinPmt].payment_frequency === 'annually') paymentFrequency = 'per year'
      else if (payment_options[indexOfMinPmt].payment_frequency === 'fortnightly') paymentFrequency = 'per fortnight'
      else if (payment_options[indexOfMinPmt].payment_frequency === 'weekly') paymentFrequency = 'per week'
      minimumRegularPaymentDisplay = `${translate('From')} ${minPmtDisplay} ${translate(paymentFrequency)}`
    }
  }

  //when showing the payment option min payment compress things, otherwise leave as default
  const titleMarginBottom = minimumRegularPaymentDisplay ? '0px' : null

  const extraPaymentBottomMargin = actionButtons.length > 0 ? '0px' : '15px'
  useEffect(() => {}, [proposalData?.selectedSystem?.payment_options])

  // Single Accept Proposal button is 41px high plus 15px margin top and bottom.
  // Size of loading indicator (7px + margins) does not need to match exactly but let's try to get it close.
  if (actionButtons.length === 0 && proposalData.selectedSystem && !proposalData.selectedSystem.id) {
    actionButtons.push(<div className="loading-feature-figure" style={{ margin: '32px auto' }} />)
  }

  const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')
  const sidebarMarginSetback = 100 + (enableProposalV2 ? PROPOSAL_ACTION_BAR_HEIGHT : 0)

  return (
    <div
      id="proposal-selection-component-wrapper"
      style={{
        flexDirection: direction,
        ...style.container,
        alignItems: isMobileFixedSelection ? 'flex-start' : null,
        marginTop: isPro && !viewAsCustomer && isFixedHeader && !isMobile ? sidebarMarginSetback : 0,
        //TODO: This is to temporarily handle the issue regarding the positioning of proposal-selection-component when scrolled down in Mobile View
        paddingBottom: isFixedHeader && !isMobile ? 100 : null,
      }}
    >
      <div id="proposal-system-options" style={systemStyle}>
        {!isMobileFixedSelection && <p style={style.title}>{translate('SYSTEM OPTIONS')}</p>}
        {renderSystemOptions()}
      </div>
      {!isMobileFixedSelection && isMobile && <div style={styles.general.decorativeLine} />}
      {(proposalData.selectedSystem?.payment_options?.length > 1 || showButtonsForSinglePaymentOption) && (
        <div
          id="proposal-payment-options"
          style={{
            ...paymentStyle,
            marginBottom: extraPaymentBottomMargin,
            flexWrap: !isMobileFixedSelection && isMobile ? 'wrap' : null,
          }}
        >
          {!isMobileFixedSelection && (
            <p style={{ ...style.title, marginTop: extraMarginTop, marginBottom: titleMarginBottom }}>
              {translate('PAYMENT OPTIONS')}
            </p>
          )}
          {renderPaymentMethodOptions(minimumRegularPaymentDisplay, isMobileFixedSelection)}
          {renderPaymentOptionCTA()}
        </div>
      )}
      {actionButtons.length > 0 && (
        <div
          id="proposal-customer-actions"
          ref={ref}
          //This is crazy: refactor this file please
          style={{
            ...style.nextStep,
            ...(isPro && isMobile && { bottom: !viewAsCustomer ? 100 : '56px' }),
          }}
        >
          {!isMobile && <p style={{ ...style.title, marginTop: extraMarginTop }}>{translate('NEXT STEP')}</p>}
          {props.isDirty && !hasCalcError && (
            <div className="small" style={{ margin: '10px 0px', textAlign: 'center' }}>
              {translate('Please save the project to enable next steps')}
            </div>
          )}
          {!props.isDirty && hasCalcError && (
            <div className="small" style={{ margin: '10px 0px' }}>
              <Alert severity="warning">
                {translate(
                  'This project has an outstanding calculation error. Please correct this error to enable next steps'
                )}
              </Alert>
            </div>
          )}
          {!props.isDirty && !hasCalcError && (
            <CheckoutActions actionButtons={actionButtons} elementsData={elementsData} proposalData={proposalData} />
          )}
        </div>
      )}
      {!isMobile && displayContactDetail && (
        <div id="proposal-sales-detail" style={{ paddingTop: 20, borderTop: '1px solid #D3D3D3' }}>
          <SalesDetail
            myeStyles={proposalData.myeStyles}
            org={proposalData.org}
            assigned_team_member={assigned_team_member}
            api_key_chat={proposalData.selectedProject.api_key_chat}
            isPro={isPro}
          />
        </div>
      )}
      {showCreditDecisionDialog && (
        <CreditDecisionDialog orgId={proposalData.org.id} projectId={proposalData.selectedProject.id} />
      )}
      {showFullScreenLoader && (
        <div
          style={{
            zIndex: 1999, // just below the 2000 for accepting terms
            position: 'absolute',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              zIndex: 1999,
              position: 'absolute',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
              opacity: 1.0,
              backgroundColor: '#fff',
            }}
          >
            <FullScreenLoading text="One moment while we prepare your Credit Application. Thank you for your patience" />
            <div
              style={{
                position: 'absolute',
                zIndex: 9001,
                top: '20px',
                right: '20px',
                cursor: 'pointer',
              }}
              onClick={() => dispatch(hideFullScreenLoader())}
            >
              <div
                style={{
                  backgroundColor: '#fff',
                  width: '26px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '15px',
                  boxShadow: '0px 1px 3px rgba(0,0,0,0.3)',
                  cursor: 'pointer',
                }}
              >
                <CloseIcon style={{ cursor: 'pointer' }} />
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <ApplyNowPrompt
          system={proposalData?.selectedSystem}
          quotationConfiguration={quotationConfiguration}
          layout={props.layout}
          proposalData={proposalData}
        />
      )}
    </div>
  )
})
export default SelectionComponent
