import { Card, CardActions, CardContent, Typography, useMediaQuery } from '@material-ui/core'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { Theme } from 'types/themes'
import CheckoutFinancingDialog from './CheckoutFinancingDialog'

type PropTypes = {
  projectId: number
  orgId: number
  countryIso2: string
  systemUuid?: string | undefined
  paymentOptionId?: number | undefined
  expectedMilestonePayments?: ExpectedMilestonePaymentType[]
  amountDue?: number
  hidden?: boolean
  isProposal?: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  card: {
    marginTop: '13px',
    border: '1px solid #D3D3D3 !important',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '18px',
  },
  cardContent: {
    padding: '13px',
  },
  icon: {
    marginRight: '8px',
    width: '20px',
    height: '20px',
  },
  cardContentText: {
    fontSize: 13,
    fontWeight: 300,
  },
  mobileContainer: {
    flexBasis: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '6px',
  },
  mobileLeftFiller: {
    minWidth: '60px',
    width: '60px',
  },
  mobileRightContentContainer: {
    color: 'rgb(77, 77, 77)',
    fontSize: '14px',
    textDecoration: 'underline',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    width: '240px',
  },
  mobileIcon: {
    width: '18px',
    height: '18px',
    marginRight: '2px',
  },
}))

const CheckoutFinancingCard: React.FunctionComponent<PropTypes> = (props) => {
  const {
    projectId,
    systemUuid,
    paymentOptionId,
    orgId,
    expectedMilestonePayments,
    amountDue = 0,
    hidden,
    isProposal,
    countryIso2,
  } = props

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const classes = useStyles()
  const translate = useTranslate()

  const [showDialog, setShowDialog] = useState<boolean>(false)

  if (hidden === true) return null

  return (
    <>
      {isProposal && isMobile ? (
        <div className={classes.mobileContainer}>
          <div className={classes.mobileLeftFiller}></div>
          <div onClick={() => setShowDialog(true)} className={classes.mobileRightContentContainer}>
            <AccountBalanceOutlinedIcon className={classes.mobileIcon} />
            <span>{translate('Financing Available')}</span>
          </div>
        </div>
      ) : (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardHeader}>
              <AccountBalanceOutlinedIcon className={classes.icon} />
              <Typography variant="subtitle1">{translate('Financing Available')}</Typography>
            </div>
            <Typography className={classes.cardContentText} variant="body2">
              {translate(
                'Secure the funds you need to begin your home improvement project. Financing is just a click away.'
              )}
            </Typography>
          </CardContent>
          <CardActions>
            <Button color="secondary" variant="contained" fullWidth onClick={() => setShowDialog(true)}>
              {translate('Learn More')}
            </Button>
          </CardActions>
        </Card>
      )}

      {showDialog && (
        <CheckoutFinancingDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          orgId={orgId}
          projectId={projectId}
          systemUuid={systemUuid}
          paymentOptionId={paymentOptionId}
          expectedMilestonePayments={expectedMilestonePayments}
          amountDue={amountDue}
          countryIso2={countryIso2}
        />
      )}
    </>
  )
}

export default CheckoutFinancingCard
