import { DialogActions, DialogContent, Tooltip, makeStyles } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import { Button, Grid, Link, Typography, styled } from 'opensolar-ui'
import ContactAutocomplete from 'projectSections/sections/info/contacts/ContactAutocomplete'
import { useNotify, useTranslate } from 'ra-core'
import { ReferenceInput } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import InvoiceAddressAutocomplete from './InvoiceAddressAutocomplete'
import InvoiceDetailsField from './InvoiceDetailsField'
import { InvoiceContactDetails } from './types'
import { DEFAULT_INVOICE_DETAILS, validateForm } from './utils'

type PropTypes = {
  contactDetails: InvoiceContactDetails
  nextPage: () => void
  saveContactDetails: (details) => void
  setContactDetails: (InvoiceContactDetails) => void
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  icon: {
    color: theme.greyMid1,
    fontSize: 20,
    margin: '10px 0px 10px 6px',
    cursor: 'pointer',
  },
}))

const HeaderWrapper = styled('div')({
  margin: '11px 0',
})

const RowWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  padding: '16px 24px',
  justifyContent: 'end',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
})

const ContactFormContainer = styled('div')(({ theme }) => ({
  marginTop: 5,
  paddingTop: 5,
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.grey[300],
}))

const AddressActionBtn = styled(Link)(({ theme }) => ({
  color: `${theme.palette.grey[700]} !important`,
  marginTop: 5,
  cursor: 'pointer',
}))

const AddressActionBtnWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: 12,
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'baseline',
  gap: 5,
}))

const InvoiceDetails: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const formValues = useFormState().values
  const form = useForm()

  const [showForm, setShowForm] = useState<boolean>(false)
  const [isAddressValid, setIsAddressValid] = useState<boolean>(true)
  const [isCommericalInvoice, setIsCommercialInvoice] = useState<boolean>(formValues.is_commercial_invoice ?? false)
  const [isManualAddressEntryEnabled, setIsManualAddressEntryEnabled] = useState(false)
  const [resetKey, setResetKey] = useState(0)

  const orgCountry = useSelector(orgSelectors.getOrg)?.country?.iso2

  const resetForm = () => {
    form.reset()
    setShowForm(true)
    props.setContactDetails(DEFAULT_INVOICE_DETAILS)
    setResetKey((prevKey) => prevKey + 1)
  }

  const onNext = () => {
    const validation = validateForm(formValues)
    if (validation.isValid) {
      setIsAddressValid(true)
      props.saveContactDetails(formValues)
    } else if (validation?.message) {
      notify(validation?.message, 'warning')
      if (validation.field.includes('billing_address') && formValues.billing_address_line_1) setIsAddressValid(false)
    }
  }

  const onSelectExistingContact = (contactFields) => {
    resetForm()
    form.change('contact_id', contactFields?.id)
    form.change('recipient_first_name', contactFields?.first_name)
    form.change('recipient_last_name', contactFields?.family_name)
    form.change('recipient_email', contactFields?.email)
  }

  const onCheckCommercialInvoice = (val) => {
    setIsCommercialInvoice(val)
    if (!val) form.change('invoice_tax_id', null)
  }

  useEffect(() => {
    if (isManualAddressEntryEnabled && !formValues.billing_address_country) {
      form.change('billing_address_country', orgCountry)
    } else if (!isManualAddressEntryEnabled) {
      form.change('billing_address_country', null)
    }
  }, [isManualAddressEntryEnabled])

  return (
    <>
      <DialogContent>
        <HeaderWrapper>
          <Typography textVariant="subtitle1">{translate('Select a Contact')}</Typography>
        </HeaderWrapper>
        <Typography textVariant="body1">
          {translate(
            'Select an existing contact in OpenSolar or create a new one as the contact to receive this invoice.'
          )}
        </Typography>

        <RowWrapper>
          <ReferenceInput
            label={translate('Search existing contacts...')}
            reference="contacts"
            variant={'outlined'}
            source="existing_contact"
            renderChildrenBeforeRecord={true}
            filter={{ type: 0 }}
            key={resetKey}
          >
            <ContactAutocomplete
              onSelection={(e) => onSelectExistingContact(e)}
              initialValue=""
              showSpinnerWhenRefreshing={true}
            />
          </ReferenceInput>

          <Button onClick={resetForm} variant="text" color="primary" size="small">
            {translate('Create new contact')}
          </Button>
        </RowWrapper>

        {(showForm || props.contactDetails.billing_address_line_1) && (
          <ContactFormContainer>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography textVariant="body1">
                  {translate('Use the fields below to edit invoice information.')}
                </Typography>
              </Grid>
              <InvoiceDetailsField label="First Name" name="recipient_first_name" form={form} size={6} />
              <InvoiceDetailsField label="Last Name" name="recipient_last_name" form={form} size={6} />
              <InvoiceDetailsField label="Email" name="recipient_email" form={form} size={12} />
              {isAddressValid && !isManualAddressEntryEnabled ? (
                <Grid item xs={12}>
                  <InvoiceAddressAutocomplete key={resetKey} />

                  <AddressActionBtnWrapper>
                    <span>{translate('or')} </span>
                    <AddressActionBtn onClick={() => setIsManualAddressEntryEnabled(true)}>
                      {translate('Enter Address Manually')}
                    </AddressActionBtn>
                  </AddressActionBtnWrapper>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <InvoiceDetailsField label="Address" name="billing_address_line_1" form={form} size={12} />
                    <AddressActionBtnWrapper>
                      <span>{translate('or')} </span>
                      <AddressActionBtn onClick={() => setIsManualAddressEntryEnabled(false)}>
                        {translate('Use Google Autocomplete to enter address')}
                      </AddressActionBtn>
                    </AddressActionBtnWrapper>
                  </Grid>
                  <InvoiceDetailsField label="City" name="billing_address_city" form={form} size={6} />
                  <InvoiceDetailsField label="State" name="billing_address_state" form={form} size={6} />
                  <InvoiceDetailsField label="Zip Code" name="billing_address_postal_code" form={form} size={6} />
                  <InvoiceDetailsField label="Country" name="billing_address_country" form={form} size={6} />
                </>
              )}

              <Grid item xs={12}>
                <CustomField
                  name="is_commercial_invoice"
                  source="is_commercial_invoice"
                  label="Commercial Invoice"
                  component={CheckboxInput}
                  onChange={(value) => {
                    onCheckCommercialInvoice(value)
                  }}
                />
              </Grid>

              {isCommericalInvoice && (
                <InvoiceDetailsField label="Tax ID" name="invoice_tax_id" form={form} size={6}>
                  <Tooltip
                    title={translate("Commercial customers' tax ID is required to be included on the invoice")}
                    enterDelay={300}
                  >
                    <Info className={classes.icon} />
                  </Tooltip>
                </InvoiceDetailsField>
              )}
            </Grid>
          </ContactFormContainer>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonWrapper>
          <div>
            <Button color="primary" variant="contained" onClick={onNext}>
              {translate('Next')}
            </Button>
          </div>
        </ButtonWrapper>
      </DialogActions>
    </>
  )
}

const InvoiceContactDetailsPage: React.FC<PropTypes> = (props) => {
  const { contactDetails } = props

  return (
    <Form
      initialValues={{ ...contactDetails }}
      onSubmit={() => {}}
      render={(formProps) => <InvoiceDetails {...formProps} {...props} />}
    />
  )
}

export default InvoiceContactDetailsPage
