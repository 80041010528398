import { TextFieldProps } from '@material-ui/core'
import ChipsInput from 'elements/input/ChipsInput'
import React, { useMemo } from 'react'
import { InputProps, ReferenceArrayInput } from 'react-admin'

//Hooks up ReferenceArrayInput to a ChipsInput field

interface PropTypes {
  source: string
  resource: string
  reference: string
  optionValue?: string
  optionText?: string
  variant?: string
  className?: string
  chipsInputProps?: InputProps<TextFieldProps>
  trackTagChanges?: (values) => void
  onChange?: (values) => void
}
const ChipArrayInput: React.FC<PropTypes> = (props) => {
  const { source, reference, resource } = props
  return (
    <ReferenceArrayInput
      renderChildrenBeforeRecord
      source={source}
      component={ReferenceArrayInput}
      reference={reference}
      resource={resource}
      fullWidth
      id={undefined}
      onBlur={undefined}
      onChange={undefined}
      onFocus={undefined}
      validate={undefined}
      parse={undefined}
      format={undefined}
    >
      <ModifiedChipsInput {...props} />
    </ReferenceArrayInput>
  )
}

const ModifiedChipsInput = (props) => {
  const { source, chipsInputProps, input, onChange, trackTagChanges, optionText, optionValue, variant, choices } = props

  const findItemsInChoices = (values) =>
    values.map((x) => choices.find((item) => (optionValue ? item[optionValue] === x : item.url === x)))

  const handleChange = (values) => {
    if (trackTagChanges) {
      const previousValues = input.value || []
      const addedTags = values.filter((x) => !previousValues.includes(x))
      const removedTags = previousValues.filter((x) => !values.includes(x))

      trackTagChanges({ addedTags, removedTags, findItemsInChoices })
    }

    input.onChange(values)

    if (onChange) {
      const matchingItems = findItemsInChoices(values)
      onChange(matchingItems)
    }
  }

  const inputProps = useMemo(() => ({ ...input, onChange: handleChange }), [input])
  return (
    <ChipsInput
      optionText={optionText || 'title'}
      optionValue={optionValue || 'url'}
      translateChoice={false}
      fullWidth
      variant={variant || 'outlined'}
      source={source}
      {...props}
      {...chipsInputProps}
      input={inputProps}
      label={null}
    />
  )
}

export default ChipArrayInput
