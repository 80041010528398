import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { Confirm } from 'ra-ui-materialui'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { orgSelectors } from '../../ducks/orgs'

const useStyles = makeOpenSolarStyles((theme) => ({
  button: { margin: 5 },
}))

type VtacConnectUserButtonProps = {
  label?: string
  variant: 'outlined' | 'contained' | 'text'
  trackSource: string
  color?: 'default' | 'primary' | 'secondary' | 'warning'
  fullWidth?: boolean
  icon?: React.ReactNode
  onConfirm?: () => void
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const VtacConnectUserButton: React.FC<VtacConnectUserButtonProps> = ({
  label = 'Connect V-TAC',
  variant,
  trackSource,
  color = 'default',
  fullWidth = false,
  icon,
  onConfirm = undefined,
}) => {
  const classes = useStyles()
  const [isConnected, setIsConnected] = useState(false)
  const [confirmingConnect, setConfirmingConnect] = useState(false)
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const history = useHistory()
  const country = useSelector(orgSelectors.getOrgIso2) || 'GB'
  const distributor: HardwareSupplierFilterKeyType = country === 'GB' ? 'vtac_uk' : 'vtac_pl'
  const content = ['project_hardware_page', 'manage_page'].includes(trackSource)
    ? 'You will be redirected to the V-Tac login page to connect your account. Please confirm to proceed and save any project changes.'
    : 'You will be redirected to the V-Tac login page to connect your accounts, please confirm to proceed. Any unsaved changes will be discarded.'

  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({
    orgId,
    distributor,
  })

  useEffect(() => {
    if (isAuthenticated && !isChecking) {
      setIsConnected(true)
    }
  }, [isAuthenticated, isChecking])

  const onClickConnect = () => {
    logAmplitudeEvent('hardware_distributor_connect_button_clicked', {
      source: trackSource,
      distributor: 'vtac',
    })
    setConfirmingConnect(false)
    if (onConfirm) {
      setConfirmingConnect(true)
    } else {
      confirm()
    }
  }

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }
    confirm()
  }

  const confirm = () => {
    if (isConnected) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + orgId + `/distributors/${distributor}/disconnect/`,
      })
        .then((response) => {
          setIsConnected(false)
        })
        .catch((err: any) => {
          console.log(err, err)
        })
    } else {
      history.push('/shop/connect_distributor/vtac/email')
    }
  }

  return (
    <>
      <Button
        onClick={onClickConnect}
        variant={variant}
        color={color}
        disabled={false}
        className={classes.button}
        fullWidth={fullWidth}
      >
        {icon && icon}
        {isConnected ? 'Disconnect V-Tac' : label}
      </Button>
      <Confirm
        isOpen={confirmingConnect}
        title="Connect to VTAC"
        content={content}
        confirmColor="warning"
        onConfirm={handleConfirm}
        onClose={() => setConfirmingConnect(false)}
      />
    </>
  )
}

export default VtacConnectUserButton
