import { DialogTitle, Theme, useMediaQuery } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import Badge from '../sharedComponents/Badge'

type PropTypes = {}

interface StyledProps {
  isMobile: boolean
}

const TitleWrapper = styled('div')<StyledProps>(({ theme, isMobile }) => ({
  display: 'flex',
  justifyContent: isMobile ? 'space-between' : 'flex-start',
  gap: isMobile ? 'unset' : '10px',
  alignItems: 'center',
  paddingTop: '.5rem',
  paddingBottom: '1rem',
  borderBottom: `1px ${theme.palette.grey[300]} solid`,
}))

const IntroTitle: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <DialogTitle>
      <TitleWrapper isMobile={isMobile}>
        <span>{translate('Activate CashFlow')}</span>
        <Badge text={translate('5 min')} />
      </TitleWrapper>
    </DialogTitle>
  )
}
export default IntroTitle
