import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
export var SunlightApplicationLoadingDialog = function (props) {
    return (_jsx(Dialog, { open: props.show, style: { textAlign: 'center' }, children: _jsxs(DialogContent, { style: { padding: '10px 40px' }, children: [_jsx("img", { alt: "Sunlight Financial", src: props.logoUrl, style: { maxHeight: 84, maxWidth: 220 } }), _jsx("div", { style: { margin: '20px 0px' }, children: _jsx(CircularProgress, { size: 60, 
                        //@ts-ignore
                        left: 10, top: 0, style: {
                            display: 'inline-block',
                            position: 'relative',
                        } }) }), props.message] }) }));
};
