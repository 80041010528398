// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import { ActionType, EventType } from 'types/tracking'

const useStyles = makeStyles(
  (theme: any) => ({
    button: {
      margin: 0,
      height: 35,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.87)',
      display: 'flex',
      width: '100%',
      textTransform: 'none',
      justifyContent: 'space-between',
      minWidth: 'auto',
      padding: 10,
      lineHeight: '16px',
      background: 'rgba(255, 255, 255, 0.9)',
      '&$disabled': {
        background: 'rgb(224 224 224)',
        borderRadius: '0px',
      },
      '&:hover': {
        background: 'rgba(250,250,250,0.9)',
      },
    },
    disabled: {},
    startIcon: {
      margin: '2px 2px 2px 5px',
    },
    endIcon: {
      margin: '2px 5px 2px 2px',
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 8,
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    label: {
      verticalAlign: 'middle',
    },
    hotKey: {
      minWidth: 18,
      height: 18,
      display: 'inline-block',
      lineHeight: '18px',
      border: '1px solid #929292',
      color: '#929292',
    },
    plusSymbol: {
      display: 'inline-block',
      lineHeight: '18px',
      margin: '0 5px',
      color: '#929292',
    },
  }),
  { name: 'DrawingToolSelectItem' }
)

interface PropTypes {
  label: string
  icon?: React.ComponentClass<any> | React.FC<any> | undefined
  onClick: React.MouseEventHandler<HTMLButtonElement>
  shortcutKeys?: string[] | undefined
  id: string
  disabled?: boolean
}

export const HotKeys: React.FC<{ shortcutKeys: string[] }> = ({ shortcutKeys }) => {
  const classes = useStyles()
  return (
    <div>
      {shortcutKeys.map((key, index) => {
        const isLastItem = index === shortcutKeys.length - 1
        return (
          <React.Fragment key={key}>
            <span className={classes.hotKey}>{key}</span>
            {!isLastItem && <span className={classes.plusSymbol}>+</span>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const DrawingToolSelectItem: React.FC<PropTypes> = ({ disabled, label, id, icon, onClick, shortcutKeys }) => {
  const translate = useTranslate()
  const classes = useStyles()

  const { trackEvent } = useTrackComponent({
    name: 'drawing_tool_select_item',
    eventName: `Click On '${label}' In The project`,
  })

  return (
    <Button
      id={id}
      classes={{
        root: classes.button,
        disabled: classes.disabled,
      }}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()
        trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { label })
        onClick(e)
      }}
    >
      <span>
        {icon && <img src={icon} style={{}} className={classes.icon} />}
        <span className={classes.label} style={{ textTransform: 'none' }}>
          {translate(label)}
        </span>
      </span>
      {shortcutKeys && <HotKeys shortcutKeys={shortcutKeys} />}
    </Button>
  )
}

export default DrawingToolSelectItem
