export const CONVERSION_RATE_MAP = {
  GB: '20',
  US: '21',
  AU: '15',
  default: '21',
}

export const FEE_SAVINGS_MAP = {
  GB: '27',
  US: '35',
  AU: '23',
  default: '35',
}

export const MISSED_REVENUE_MAP = {
  GB: '15',
  US: '6',
  AU: '11',
  default: '6',
}
