// @ts-nocheck
import { FormControlLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { openEagleViewDialog } from 'ducks/eagleViewOrderForm'
import { permissionsSelectors } from 'ducks/permissions'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button, Checkbox } from 'opensolar-ui'
import { getFujiTourNextStepQuery } from 'persistentContent/tours/fuji/useGetFUJITourSteps'
import { default as React, useCallback, useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PremiumImageryBlockReasonType, PremiumImageryType } from 'types/global'
import { MapDataTypes } from 'types/map'
import { ActionType, EventType } from 'types/tracking'
import {
  getCurrentLonLat,
  getIsImageryPremium,
  getSelectedPremiumImageryProduct,
  getSelectedPremiumImageryProvider,
  restrictToBounds,
  SelectedPremiumImageryResponseType,
  tryAddPremiumImageryIfRequiredAndNotify,
} from 'util/imagery'
import { formatDateString, JSONstringifySortedKeys, labelForImagery } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import {
  getImageryTypeDefault,
  IMAGERY_MATCH_LEVEL,
  scoreImageryTypeAgainstDefault,
} from '../../util/getImageryTypeDefault'
import { setImageryTypeDefault } from '../../util/setImageryTypeDefault'
import filterAvailableImageryTypesWithFeatureFlags from '../filterAvailableImageryTypesWithFeatureFlags'
import PremiumImageryTerms from '../view/PremiumImageryTerms'
import PremiumImageryWarning from './PremiumImageryWarning'

type PropTypes = {
  timezoneOffset: number | null
  selectedDesignMode: string | null
  availableImageryTypes: MapDataTypes[]
  setImageryType: Function
  imageryType: MapDataTypes | null
  close: Function
  sceneOrigin4326: any
  // isPremiumImageryAvailable: boolean
  premiumImageryUnavailableReson: PremiumImageryBlockReasonType
  premiumImageryActivations: PremiumImageryType[]
}

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    marginTop: 'auto',
  },
  disabledMessage: {
    fontSize: 12,
    color: '#ff0000',
    '& div div,a,p': { color: '#ff0000', fontSize: 12 },
  },
  select: {
    padding: 5,
    width: 150,
    fontSize: 12,
  },
  imageryProviderWrapper: {
    margin: '10px 0px',
  },
  imageryProviderLabel: {
    marginRight: 5,
  },
  button: {
    background: 'rgba(255, 255, 255, 1.0)',
    borderRadius: '4px',
    color: 'rgb(0,0,0)',
    padding: '0 5px',
    fontSize: 12,
    width: 90,
    height: 30,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
    '&$disabled': {
      background: 'rgba(208, 208, 208, 0.8)',
    },
  },
  buttonForced: {
    background: 'rgba(66, 195, 16, 1.0)',
    borderRadius: '4px',
    color: 'rgb(0,0,0)',
    padding: '0 5px',
    fontSize: 12,
    width: 90,
    height: 30,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      background: 'rgba(66, 195, 16, 0.9)',
    },
    '&$disabled': {
      background: 'rgba(208, 208, 208, 0.8)',
    },
  },
  bottomActionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nearmapDefaultExplanation: {},
  checkBox: {
    fontSize: 12,
  },
  disabled: {},
}))

const ToolbarDesignModeActions: React.FC<PropTypes> = ({
  availableImageryTypes,
  selectedDesignMode,
  imageryType,
  setImageryType,
  close,
  timezoneOffset,
  // forceSelection,
  sceneOrigin4326,
  // isPremiumImageryAvailable,
  premiumImageryActivations,
  premiumImageryUnavailableReson,
}) => {
  const enableGoogleSolarApi = useFeatureFlag('google_solar_api', 'on')
  const enableGoogleSunroof = useFeatureFlag('google_sunroof', 'on')

  const dispatch = useDispatch()
  const classes = useStyles()
  const notify = useNotify()
  const translate = useTranslate()
  const history = useHistory()
  const [count, setCount] = useState<number>(0)
  const [selectedPremiumProvider, setSelectedPremiumProvider] = useState<SelectedPremiumImageryResponseType>(undefined)
  const [walletProduct, setWalletProduct] = useState<WalletProductType | undefined>()
  const { tour, step } = useSelector((state: any) => state.tour)

  const availableImageryTypesForDesignMode = useMemo(() => {
    const availableImageryTypesWithFeatureFlags = filterAvailableImageryTypesWithFeatureFlags(
      availableImageryTypes,
      enableGoogleSolarApi,
      enableGoogleSunroof
    )
    return availableImageryTypesWithFeatureFlags.filter((m: MapDataTypes) => m.design_mode === selectedDesignMode)
  }, [availableImageryTypes, selectedDesignMode])
  const purchasesForProjectsAccess = useSelector(
    permissionsSelectors.getProjectPermissionByKey('purchases_for_projects')
  )

  const projectData = projectForm.getState().values

  const eagleViewOrderSubmitted = useSelector(
    (state: any) =>
      state.eagleViewOrderForm?.submittedProjectIds?.length > 0 &&
      state.eagleViewOrderForm.submittedProjectIds.includes(projectData?.id)
  )

  const { trackEvent } = useTrackComponent({
    componentKey: 'toolbar_design_mode_actions',
    eventName: 'Select Imagery Provider',
  })

  useEffect(() => {
    if (imageryType) {
      let premiumProvider = getSelectedPremiumImageryProvider(imageryType)
      if (premiumProvider) setSelectedPremiumProvider(premiumProvider)
      else if (selectedPremiumProvider) setSelectedPremiumProvider(undefined)

      setWalletProduct(getSelectedPremiumImageryProduct(imageryType))
    }
  }, [imageryType])

  const currentImageryTypeDataMatchesDefault = useCallback(() => {
    const defaultImageryType = getImageryTypeDefault()
    if (!imageryType || !defaultImageryType) return false
    // any imagery that's similar to the default up to the map type level is considered a match
    return scoreImageryTypeAgainstDefault(imageryType, defaultImageryType) >= IMAGERY_MATCH_LEVEL.MapType
  }, [imageryType])

  const handleImageryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setImageryType(JSON.parse(event.target.value as string))
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.SELECT },
      { imageryType: JSON.parse(event.target.value as string) }
    )
  }

  /*
  Beware: There is a risk this location value may be stale but for now we believe this is not a problem
  because at the time the Select Design mode toolbar is opened the current lon/lat of the viewport is expected
  to be correct. We expect it is very unlikely that a user will significantly pan the viewport while the toolbar
  remains open, which is the only scenario where this value could be materially stale. If this proves to be a
  bigger problem, then we should listen to some studio signals so we can refresh currentLonLat on map pan/zoom/change.
  */
  const currentLonLat = getCurrentLonLat([projectData.lon, projectData.lat])

  const premiumImageryActivationsMatchesSorted = premiumImageryActivations
    .slice()
    .reverse()
    .filter((a) => a.product.id === imageryType?.variation_data?.require_wallet_product?.id)
    .filter((a) => restrictToBounds(a, currentLonLat))

  const selectedPremiumImageryActivation = premiumImageryActivationsMatchesSorted[0]

  const selectedPremiumImageryPeriod = selectedPremiumImageryActivation?.period || 'inactive'

  const selectedPremiumProviderRequiresProjectPurchasesPermission =
    selectedPremiumProvider && walletProduct?.activation_requires_permission

  // Simple hack before we modify selectedPremiumProvider to include the full WalletProduct data
  const selectedPremiumProviderIsAlreadyActivated = premiumImageryActivationsMatchesSorted.some(
    (pia) => pia.period === 'initial'
  )

  const guessEagleViewProduct = (imageryType, projectData) => {
    // If field not found, assume residential
    var is_residential = projectData?.is_residential !== false

    //@TODO: Perhaps we could also auto-select commercial if project is commercial?
    if (imageryType.variation_name) {
      if (imageryType.variation_name.includes('Advanced')) {
        if (is_residential) {
          return 62
        } else {
          return 63
        }
      } else if (imageryType.variation_name.includes('Essentials')) {
        if (is_residential) {
          return 11
        } else {
          return 12
        }
      } else {
        return null
      }
    }
  }

  // enabled, disabled, hidden
  let selectButtonState = 'enabled'
  let orderButtonState = 'hidden'

  let disabledMessage = null

  if (imageryType?.map_type === 'EagleViewInform' && eagleViewOrderSubmitted) {
    disabledMessage = 'Order Submitted'
    selectButtonState = 'disabled'
    orderButtonState = 'hidden'
  } else if (imageryType?.disabled_message === 'ORDER_EAGLEVIEW_INFORM_REPORT') {
    selectButtonState = 'hidden'
    orderButtonState = 'enabled'
  } else if (imageryType?.disabled_message) {
    disabledMessage = imageryType?.disabled_message
    selectButtonState = 'disabled'
    orderButtonState = 'hidden'
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.imageryProviderWrapper}>
        {availableImageryTypesForDesignMode && availableImageryTypesForDesignMode.length > 0 ? (
          <>
            <span className={classes.imageryProviderLabel}>{translate('Imagery Provider:')}</span>
            <Select
              data-testid="imagery-provider-selector"
              classes={{ select: classes.select }}
              onChange={handleImageryChange}
              variant="filled"
              value={JSONstringifySortedKeys(imageryType) || ''}
            >
              {availableImageryTypesForDesignMode.map((m: MapDataTypes, index: number) => (
                <MenuItem key={index} value={JSONstringifySortedKeys(m)}>
                  {/* {[m.provider, m.map_type, m.variation_name].filter(Boolean).join(' ')} */}
                  {labelForImagery(m.map_type, m.variation_name)}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          <span className={classes.disabledMessage}>
            {selectedDesignMode === '3D'
              ? translate('3D data not available at this location')
              : translate('Not available')}
          </span>
        )}
      </div>
      {selectedPremiumProvider &&
      selectedPremiumProviderRequiresProjectPurchasesPermission &&
      !selectedPremiumProviderIsAlreadyActivated &&
      premiumImageryUnavailableReson ? (
        <PremiumImageryWarning message={premiumImageryUnavailableReson} />
      ) : null}
      {selectedPremiumProvider && walletProduct && !selectedPremiumProviderIsAlreadyActivated && (
        <div style={{ marginBottom: '10px' }}>
          <PremiumImageryTerms
            provider={selectedPremiumProvider}
            walletProduct={walletProduct}
            selectedPremiumImageryActivation={selectedPremiumImageryActivation}
          />
        </div>
      )}
      {disabledMessage && (
        <div className={classes.disabledMessage} dangerouslySetInnerHTML={{ __html: disabledMessage || '' }} />
      )}
      <div className={classes.bottomActionWrapper}>
        {imageryType && selectButtonState !== 'hidden' && (
          <FormControlLabel
            value="saveDefault"
            classes={{ label: classes.checkBox }}
            control={
              <Checkbox
                color="primary"
                onChange={(event) => {
                  setCount(count + 1)
                  if (event.target.checked) {
                    setImageryTypeDefault(imageryType)
                  } else if (currentImageryTypeDataMatchesDefault()) {
                    // clear deafult if the current item is the default and we are un-checking the checkbox
                    console.log('Clearing saved default imagery type')
                    setImageryTypeDefault(null)
                  }
                }}
                checked={!!currentImageryTypeDataMatchesDefault()}
                disabled={!imageryType || selectButtonState === 'disabled'}
              />
            }
            label={
              selectedPremiumProvider === 'Nearmap' ||
              selectedPremiumProvider === 'GetMapping' ||
              selectedPremiumProvider === 'GetMappingPremium'
                ? `${translate('Save Default')} (${translate('Requires confirmation for new projects')})`
                : translate('Save Default')
            }
            labelPlacement="end"
          />
        )}
        {selectButtonState !== 'hidden' && (
          <Button
            variant="outlined"
            color="default"
            // classes={{ root: forceSelection ? classes.buttonForced : classes.button, disabled: classes.disabled }}
            disabled={
              !imageryType ||
              selectButtonState === 'disabled' ||
              (getIsImageryPremium(imageryType) &&
                selectedPremiumProviderRequiresProjectPurchasesPermission &&
                !purchasesForProjectsAccess?.allowCreate)
            }
            onClick={async (e) => {
              const fujiTourQuery = tour === 'fuji' ? getFujiTourNextStepQuery(step, false) : ''

              /*
            Special behavior during tour: Avoid reloading 3D if already loaded, just close instead

            @TODO: We could probably skip reloading more aggressively even outside the tour, such as
            when the loaded 3D data matches the imageryType we have selected, but this is tricky to get
            exactly correct so we will ignore this for now.
            */
              if (
                tour === 'fuji' &&
                editor.getTerrain() &&
                ['Google3D', 'Nearmap3D'].indexOf(imageryType?.map_type) !== -1
              ) {
                console.log('Notice: Skip loading 3D again if already loaded/loading and tour is running')
              } else {
                const isActivated = await tryAddPremiumImageryIfRequiredAndNotify(
                  imageryType,
                  premiumImageryActivations,
                  currentLonLat,
                  notify,
                  translate
                )

                if (isActivated) {
                  window.SceneHelper.startDesignMode(
                    sceneOrigin4326,
                    window.WorkspaceHelper.project.country_iso2,
                    window.WorkspaceHelper.project.state,
                    imageryType,
                    timezoneOffset,
                    { refloatModuleGridsToTerrain: true }
                  )
                }
                close()
                if (fujiTourQuery) {
                  history.push({ search: fujiTourQuery })
                }
              }
            }}
          >
            <span>{translate('Select')}</span>
          </Button>
        )}
        {orderButtonState !== 'hidden' && (
          <Button
            variant="outlined"
            color="default"
            onClick={() =>
              dispatch(
                openEagleViewDialog({
                  projectId: projectData.id,
                  address: projectData.address,
                  zip: projectData.zip,
                  state: projectData.state,
                  city: projectData.locality,
                  report_type: guessEagleViewProduct(imageryType, projectData) || null,
                  lon: projectData.lon,
                  lat: projectData.lat,
                })
              )
            }
            disabled={orderButtonState === 'disabled' || !purchasesForProjectsAccess.allowCreate}
          >
            <span>Order {imageryType?.variation_name}</span>
          </Button>
        )}
      </div>
      {selectedPremiumImageryActivation && (
        <PremiumImageryActivationDisplay premiumImageryActivation={selectedPremiumImageryActivation} />
      )}
    </div>
  )
}

const PremiumImageryActivationDisplay = ({ premiumImageryActivation }) => {
  // Includes both Free and API Key products
  const activatedOrPurchasedLabel = premiumImageryActivation?.product?.unit_price_ex_tax ? 'purchased' : 'activated'
  const premiumImageryPeriod = premiumImageryActivation?.period || 'inactive'

  return (
    <div style={{ marginTop: 10 }}>
      <span>
        {premiumImageryActivation.product.title} {activatedOrPurchasedLabel}{' '}
        {formatDateString(premiumImageryActivation.dates.initial_start)}.{' '}
      </span>

      {premiumImageryPeriod === 'initial' && (
        <span>Initial period ends: {formatDateString(premiumImageryActivation.dates.initial_end)}. </span>
      )}

      {['initial', 'extended'].includes(premiumImageryPeriod) && (
        <span>Extended period ends: {formatDateString(premiumImageryActivation.dates.extended_end)}. </span>
      )}

      {premiumImageryPeriod === 'expired' && (
        <span>Extended period ended: {formatDateString(premiumImageryActivation.dates.extended_end)}. </span>
      )}
    </div>
  )
}

export default ToolbarDesignModeActions
