import { Theme, useMediaQuery } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { ironRidgeHasBeenImported } from 'projectSections/sections/design/systems/tabs/other/ironridge/IronRidgeBom'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { ProjectType } from 'types/projects'
import { SystemDataType } from 'types/systems'
import { parseIntegrationJson } from 'util/misc'
import DownloadBOMDialog from './DownloadBOMDialogContent'
const publicUrl: string = window.PUBLIC_URL

type PropTypes = {
  project: ProjectType
  hasUnsavedChanges?: boolean
  is_studio?: boolean
  studio_uuid?: string
  isIronridge: boolean
}

const DownloadBOMButton: React.FunctionComponent<PropTypes> = (props) => {
  const [systemUuid, setSystemUuid] = useState<string | undefined>(props.studio_uuid || undefined)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [systems, setSystems] = useState<any[]>([])
  const [irSystems, setIRSystems] = useState<any[]>([])
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 960))
  const isXxs = useMediaQuery((theme: Theme) => theme.breakpoints.down(332))
  const translate = useTranslate()
  const form = useForm()
  const hasUnsavedChanges = form.mutators.getFormDirtyFields()?.length > 0

  // clear out error when dialog is closed
  useEffect(() => {
    if (!showDialog && errorMsg) setErrorMsg(undefined)
  }, [showDialog, errorMsg])

  useEffect(() => {
    if (showDialog) {
      if (props.isIronridge) {
        logAmplitudeEvent('global_bom_download_dialog_opened', {})
      } else {
        logAmplitudeEvent('ironridge_bom_download_dialog_opened', {})
      }
    }
  }, [showDialog])

  // for ironridge - a bit gross, but depending on what's happening in the app the source of truth for systems can be different. Give preference to project.systems but if not found use systems array from editor
  useEffect(() => {
    if (showDialog) {
      let foundIronridge = false
      const systemsFromStudio = window.editor.getSystems()
      if (systemsFromStudio && systemsFromStudio.length > 0) {
        //the format of system is different when taken from editor. Format it to match project.systems
        const formattedStudioSystems = systemsFromStudio.map((system) => {
          system.title =
            system.name && system.name.length > 0
              ? system.name
              : `${(system.moduleQuantity as any)()} ${translate('Panels System')}`
          return system
        })
        if (props.isIronridge) {
          const withIr = filterNonIrSystems(formattedStudioSystems)
          if (withIr && withIr.length > 0) {
            foundIronridge = true
            setIRSystems(withIr)
          }
        }
        setSystems(formattedStudioSystems)
      } else {
        if (props.isIronridge) {
          if (!foundIronridge) {
            setIRSystems(filterNonIrSystems(props.project.systems as any[]))
          }
        }
        setSystems(props.project.systems as any[])
      }
    }
  }, [showDialog, props.project?.systems])

  //if there is only one viable system, select it for them
  useEffect(() => {
    if (!systemUuid && systems && systems.length === 1 && !!systems[0].uuid) setSystemUuid(systems[0].uuid)
  }, [systems, systemUuid])

  const filterNonIrSystems = (allSystems: any[]) => {
    if (!props.isIronridge) return allSystems
    if (!allSystems || allSystems.length === 0) return []
    let withIr = allSystems.filter((system) => {
      if (!system.integration_json) return false
      return ironRidgeHasBeenImported(parseIntegrationJson(system.integration_json).ironridge)
    })
    return withIr
  }

  //look for a sold system and use that if found
  useEffect(() => {
    if (props.project?.system_sold) {
      let soldSystem: SystemDataType | undefined = undefined
      props.project?.systems?.forEach((system) => {
        //   if (system.url === props.project.system_sold) soldSystem = system
        // })
        // if (soldSystem) setSystemUuid(soldSystem.uuid as string)
        if (system.url === props.project.system_sold) {
          soldSystem = system
          setSystemUuid(soldSystem.uuid)
        }
      })
    }
  }, [props.project?.system_sold])

  return (
    <div style={{ width: '100%', marginTop: '10px' }}>
      {props.is_studio && (
        <div
          style={{
            margin: '15px 0px 5px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 16px',
            cursor: 'pointer',
            border: '1px solid #ececec',
            boxShadow: 'rgba(0,0,0,0.15) 0px 2px 2px',
          }}
          onClick={() => setShowDialog(true)}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={`${window.PUBLIC_URL}/images/ironridge_logo.png`} width="94px" height="18px" />
            <span style={{ marginTop: '10px' }}>{'Download BOM'}</span>
          </div>
          <div>
            <DownloadIcon style={{ marginRight: 0 }} />
          </div>
        </div>
      )}
      {!props.is_studio && (
        <ProUXButton
          type="secondary"
          label="Download BOM"
          onClick={() => setShowDialog(true)}
          backgroundColor="#fff"
          startIcon={
            props.isIronridge ? (
              <img
                style={{ paddingTop: '3px', marginRight: 0 }}
                src={`${publicUrl}/images/ironridge_logo.png`}
                width="94"
                height="18px"
              />
            ) : (
              <DownloadIcon style={{ marginRight: 2 }} />
            )
          }
          labelStyle={{
            display: 'flex',
            flexDirection: isMd || isXxs ? 'column' : 'row',
            alignItems: isMd || isXxs ? 'flex-start' : 'center',
            textTransform: 'none',
          }}
          fullWidth
          fullHeight
          // isWideIcon
          isMarginTop={isMd || isXxs ? true : false}
          justifyContent="flex-start"
        />
      )}
      {showDialog && (
        <DownloadBOMDialog
          showDialog={showDialog}
          onClose={() => setShowDialog(false)}
          project={props.project}
          mode="button"
          hasUnsavedChanges={props.hasUnsavedChanges || hasUnsavedChanges}
          preselected_uuid={systemUuid}
          isIronridge={props.isIronridge}
        />
      )}
    </div>
  )
}

export default DownloadBOMButton
