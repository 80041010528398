var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { makeStyles, Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Downshift from 'downshift';
import get from 'lodash/get';
import { IconButton, TextField } from 'opensolar-ui';
import { FieldTitle, useDebouncedCallback, useInput, useSuggestions, useTranslate, warning } from 'ra-core';
import React, { isValidElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AutocompleteSuggestionItem from './AutocompleteSuggestionItem';
import AutocompleteSuggestionList from './AutocompleteSuggestionList';
import InputHelperText from './InputHelperText';
/**
 * An Input component for an autocomplete field, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'M', name: 'Male' },
 *    { id: 'F', name: 'Female' },
 * ];
 * <AutocompleteInput source="gender" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <AutocompleteInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <AutocompleteInput source="author_id" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * Note that you must also specify the `matchSuggestion` prop
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const matchSuggestion = (filterValue, choice) => choice.first_name.match(filterValue) || choice.last_name.match(filterValue);
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectInput source="gender" choices={choices} optionText={<FullNameField />} matchSuggestion={matchSuggestion} />
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'M', name: 'myroot.gender.male' },
 *    { id: 'F', name: 'myroot.gender.female' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <AutocompleteInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 *
 * @example
 * <AutocompleteInput source="author_id" options={{ color: 'secondary', InputLabelProps: { shrink: true } }} />
 */
var AutocompleteInput = function (props) {
    var allowEmpty = props.allowEmpty, className = props.className, classesOverride = props.classes, _a = props.choices, choices = _a === void 0 ? [] : _a, emptyText = props.emptyText, emptyValue = props.emptyValue, format = props.format, fullWidth = props.fullWidth, autocompleteInputRef = props.autocompleteInputRef, helperText = props.helperText, idOverride = props.id, inputOverride = props.input, isRequiredOverride = props.isRequired, label = props.label, limitChoicesToValue = props.limitChoicesToValue, _b = props.margin, margin = _b === void 0 ? 'dense' : _b, matchSuggestion = props.matchSuggestion, metaOverride = props.meta, customOnInputChange = props.onInputChange, onBlur = props.onBlur, onChange = props.onChange, onFocus = props.onFocus, _c = props.options, _d = _c === void 0 ? {
        suggestionsContainerProps: undefined,
        labelProps: undefined,
        InputProps: undefined,
        placeholder: undefined,
        containerStyle: {},
    } : _c, suggestionsContainerProps = _d.suggestionsContainerProps, labelProps = _d.labelProps, InputProps = _d.InputProps, placeholder = _d.placeholder, options = __rest(_d, ["suggestionsContainerProps", "labelProps", "InputProps", "placeholder"]), _e = props.optionText, optionText = _e === void 0 ? 'name' : _e, inputText = props.inputText, _f = props.optionValue, optionValue = _f === void 0 ? 'id' : _f, parse = props.parse, resource = props.resource, setFilter = props.setFilter, shouldRenderSuggestionsOverride = props.shouldRenderSuggestions, source = props.source, suggestionLimit = props.suggestionLimit, _g = props.translateChoice, translateChoice = _g === void 0 ? true : _g, validate = props.validate, customItem = props.customItem, _h = props.variant, variant = _h === void 0 ? 'standard' : _h, _j = props.freeSolo, freeSolo = _j === void 0 ? false : _j, rest = __rest(props, ["allowEmpty", "className", "classes", "choices", "emptyText", "emptyValue", "format", "fullWidth", "autocompleteInputRef", "helperText", "id", "input", "isRequired", "label", "limitChoicesToValue", "margin", "matchSuggestion", "meta", "onInputChange", "onBlur", "onChange", "onFocus", "options", "optionText", "inputText", "optionValue", "parse", "resource", "setFilter", "shouldRenderSuggestions", "source", "suggestionLimit", "translateChoice", "validate", "customItem", "variant", "freeSolo"]);
    if (isValidElement(optionText) && !inputText) {
        throw new Error("If the optionText prop is a React element, you must also specify the inputText prop:\n        <AutocompleteInput\n            inputText={(record) => record.title}\n        />");
    }
    warning(isValidElement(optionText) && !matchSuggestion, "If the optionText prop is a React element, you must also specify the matchSuggestion prop:\n<AutocompleteInput\n    matchSuggestion={(filterValue, suggestion) => true}\n/>\n        ");
    var translate = useTranslate();
    var classes = useStyles(props);
    var inputEl = useRef();
    var anchorEl = useRef();
    var _k = useInput(__assign({ format: format, id: idOverride, input: inputOverride, meta: metaOverride, onBlur: onBlur, onChange: onChange, onFocus: onFocus, parse: parse, resource: resource, source: source, validate: validate }, rest)), id = _k.id, input = _k.input, isRequired = _k.isRequired, _l = _k.meta, touched = _l.touched, error = _l.error, submitError = _l.submitError;
    var _m = useState(''), filterValue = _m[0], setFilterValue = _m[1];
    var _o = useState(false), openDropDown = _o[0], setDropDownOpen = _o[1];
    var _p = useState(true), isFilterValueMatched = _p[0], setIsFilterValueMatched = _p[1];
    var errorMessage = error || submitError;
    var getSuggestionFromValue = useCallback(function (value) { return choices.find(function (choice) { return get(choice, optionValue) === value; }); }, [
        choices,
        optionValue,
    ]);
    var selectedItem = useMemo(function () { return getSuggestionFromValue(input.value) || null; }, [input.value, getSuggestionFromValue]);
    var _q = useSuggestions({
        allowEmpty: allowEmpty,
        choices: choices,
        emptyText: emptyText,
        emptyValue: emptyValue,
        limitChoicesToValue: limitChoicesToValue,
        matchSuggestion: matchSuggestion,
        optionText: optionText,
        optionValue: optionValue,
        selectedItem: selectedItem,
        suggestionLimit: suggestionLimit,
        translateChoice: translateChoice,
        allowDuplicates: rest.hasOwnProperty('allowDuplicates') ? rest.allowDuplicates : false,
    }), getChoiceText = _q.getChoiceText, getChoiceValue = _q.getChoiceValue, getSuggestions = _q.getSuggestions;
    var handleSetFilter = useDebouncedCallback(function (value) {
        setFilter(value);
    }, 400);
    var handleFilterChange = useCallback(function (eventOrValue) {
        var event = eventOrValue;
        var value = event.target ? event.target.value : eventOrValue;
        if (setFilter) {
            handleSetFilter(value);
        }
    }, [setFilter]);
    var getFilterTextFromItem = function (item) {
        if (inputText) {
            return inputText(getChoiceText(item).props.record);
        }
        else {
            return getChoiceText(item);
        }
    };
    //update filter value
    var matchFilterValue = function () {
        var newFilterValue = getFilterTextFromItem(selectedItem);
        if (newFilterValue !== filterValue) {
            setFilterValue(newFilterValue || '');
        }
        setIsFilterValueMatched(true);
    };
    //update filter value after input value has changed
    useEffect(function () {
        setIsFilterValueMatched(false);
    }, [input.value]);
    useEffect(function () {
        if (!isFilterValueMatched) {
            // It's common that selectedItem will be null initially, as the value won't show up in the first page of results
            if (selectedItem) {
                matchFilterValue();
            }
            else if (freeSolo && // I believe this could be useful in non-freeSolo mode as well, but won't enable to now to be safe
                optionValue === optionText) {
                // If we're using the same field to display and store, then we can be sure that the input value can be used as the filter value
                setFilterValue(input.value);
                setIsFilterValueMatched(true);
            }
        }
    }, [choices]);
    var handleDownshiftChange = useCallback(function (item) {
        input.onChange(getChoiceValue(item));
        setFilterValue(getChoiceText(item));
    }, [getChoiceValue, input]);
    // This function ensures that the suggestion list stay aligned to the
    // input element even if it moves (because user scrolled for example)
    var updateAnchorEl = function () {
        if (!inputEl.current) {
            return;
        }
        var inputPosition = inputEl.current.getBoundingClientRect();
        // It works by implementing a mock element providing the only method used
        // by the PopOver component, getBoundingClientRect, which will return a
        // position based on the input position
        if (!anchorEl.current) {
            anchorEl.current = { getBoundingClientRect: function () { return inputPosition; } };
        }
        else {
            var anchorPosition = anchorEl.current.getBoundingClientRect();
            if (anchorPosition.x !== inputPosition.x || anchorPosition.y !== inputPosition.y) {
                anchorEl.current = {
                    getBoundingClientRect: function () { return inputPosition; },
                };
            }
        }
    };
    var storeInputRef = function (input) {
        inputEl.current = input;
        //used by ExhibitedShowcase
        if (autocompleteInputRef) {
            autocompleteInputRef.current = input;
        }
        updateAnchorEl();
    };
    var handleBlur = useCallback(function (event) {
        setDropDownOpen(false);
        if (freeSolo) {
            var previousFilterValue = selectedItem ? getFilterTextFromItem(selectedItem) : undefined;
            if (previousFilterValue === filterValue) {
                // retain current selection
                return;
            }
            // This behaviour only works when the optionValue and optionText are the same
            // This allows a real item to be selected just by typing it's label (i.e. not clicking on it in the dropdown)
            var newSelectedItem = optionValue === optionText ? getSuggestionFromValue(filterValue) : undefined;
            if (newSelectedItem) {
                handleDownshiftChange(newSelectedItem);
            }
            else {
                // This code is the only place where the input value is set to the filter value
                // This happens in freeSolo mode only and it makes an assumption that the
                // filter value and the input value are allowed to both be used as the input value.
                input.onChange(filterValue);
            }
        }
        else {
            setFilterValue(input.value ? getFilterTextFromItem(selectedItem) : '');
        }
        input.onBlur(event);
    }, [getChoiceText, handleFilterChange, input, inputText, selectedItem, filterValue]);
    var handleFocus = useCallback(function (openMenu) { return function (event) {
        openMenu(event);
        setDropDownOpen(true);
        input.onFocus(event);
    }; }, [input]);
    var shouldRenderSuggestions = function (val) {
        if (shouldRenderSuggestionsOverride !== undefined && typeof shouldRenderSuggestionsOverride === 'function') {
            return shouldRenderSuggestionsOverride(val);
        }
        return true;
    };
    return (_jsx(Downshift, __assign({ inputValue: filterValue, onChange: handleDownshiftChange, selectedItem: selectedItem, itemToString: function (item) { return getChoiceValue(item); } }, rest, { children: function (_a) {
            var getInputProps = _a.getInputProps, getItemProps = _a.getItemProps, getLabelProps = _a.getLabelProps, getMenuProps = _a.getMenuProps, isOpen = _a.isOpen, highlightedIndex = _a.highlightedIndex, openMenu = _a.openMenu;
            var isMenuOpen = openDropDown && isOpen && shouldRenderSuggestions(filterValue);
            var _b = getInputProps(__assign({ onBlur: handleBlur, onFocus: handleFocus(openMenu) }, InputProps)), downshiftId = _b.id, // We want to ignore this to correctly link our label and the input
            value = _b.value, onBlur = _b.onBlur, onChange = _b.onChange, onFocus = _b.onFocus, ref = _b.ref, size = _b.size, color = _b.color, inputProps = __rest(_b, ["id", "value", "onBlur", "onChange", "onFocus", "ref", "size", "color"]);
            // avoid double filter
            // const suggestions = getSuggestions(filterValue)
            var suggestions = getSuggestions('');
            return (_jsxs("div", { style: options.containerStyle, className: classes.container, children: [_jsx(TextField, __assign({ id: id, name: input.name, onClick: function () {
                            if (setFilter) {
                                handleSetFilter(inputEl.current.value);
                            }
                            // setFilterValue(inputEl.current.value)
                        }, autoComplete: "off", placeholder: placeholder && translate(placeholder), InputProps: {
                            inputRef: storeInputRef,
                            onBlur: onBlur,
                            endAdornment: allowEmpty && filterValue && !options.disabled && (_jsx(Tooltip, { title: translate('clear input'), enterDelay: 300, children: _jsx(IconButton, { onClick: function (e) {
                                        e.preventDefault();
                                        if (setFilter) {
                                            handleSetFilter('');
                                        }
                                        setFilterValue('');
                                        input.onChange(null);
                                    }, classes: { root: classes.clearButton }, children: _jsx(CancelIcon, { style: { color: 'rgb(117 117 117)' } }) }) })),
                            onChange: function (event) {
                                // console.log('event', event.target.value)
                                handleFilterChange(event);
                                setFilterValue(event.target.value);
                                if (customOnInputChange) {
                                    customOnInputChange(event);
                                }
                                else {
                                    onChange(event);
                                }
                            },
                            onFocus: onFocus,
                            startAdornment: inputProps === null || inputProps === void 0 ? void 0 : inputProps.startAdornment,
                        }, error: !!(touched && errorMessage), label: label !== '' &&
                            label !== false && (_jsx(FieldTitle, __assign({ label: label }, labelProps, { source: source, resource: resource, isRequired: typeof isRequiredOverride !== 'undefined' ? isRequiredOverride : isRequired }))), InputLabelProps: getLabelProps({
                            htmlFor: id,
                        }), helperText: helperText && _jsx(InputHelperText, { touched: touched, error: errorMessage, helperText: helperText }), variant: variant, margin: margin, fullWidth: fullWidth, value: filterValue, className: className, size: size, color: color }, inputProps, options)), _jsx(AutocompleteSuggestionList, { isOpen: isMenuOpen, menuProps: getMenuProps({}, 
                        // https://github.com/downshift-js/downshift/issues/235
                        { suppressRefError: true }), inputEl: inputEl.current, suggestionsContainerProps: suggestionsContainerProps, children: customItem
                            ? suggestions.map(function (suggestion, index) {
                                return React.cloneElement(customItem, __assign({ key: getChoiceValue(suggestion) + index, suggestion: suggestion, index: index, highlightedIndex: highlightedIndex, isSelected: Boolean(input.value === getChoiceValue(suggestion)), filterValue: filterValue, getSuggestionText: getChoiceText, getSuggestionValue: getChoiceValue, allowEmpty: allowEmpty }, getItemProps({
                                    item: suggestion,
                                })));
                            })
                            : suggestions.map(function (suggestion, index) {
                                //handleBlur helps to correct filterValue(search input text) when selecting 'selected suggestion' from dropdown
                                var _a = getItemProps({
                                    item: suggestion,
                                }), onSuggestionItemClick = _a.onClick, restProps = __rest(_a, ["onClick"]);
                                var finalOnclick = function (event) {
                                    handleBlur(event);
                                    onSuggestionItemClick(event);
                                };
                                return (_jsx(AutocompleteSuggestionItem, __assign({ suggestion: suggestion, index: index, highlightedIndex: highlightedIndex, isSelected: input.value === getChoiceValue(suggestion), filterValue: filterValue, style: options.suggestionItemStyle, getSuggestionText: getChoiceText }, restProps, { onClick: finalOnclick }), getChoiceValue(suggestion) + index));
                            }) })] }));
        } })));
};
var useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: 250,
    },
    container: {
        // flexGrow: 1,
        position: 'relative',
    },
    clearButton: {
        padding: 6,
    },
}, { name: 'RaAutocompleteInput' });
export default AutocompleteInput;
