import {
  FILTER_NODE_KEY_AND_FACTORY,
  HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2,
} from 'elements/hardwareFilter/constantsV2'
import { GLOBAL_DISTRIBUTOR_NODE_KEY_V2 } from 'elements/hardwareFilter/distributor/GlobalDistributorFilterFactory'
import createAccordionRenderer from 'elements/hardwareFilter/renderers/createAccordionRenderer'
import { AVAILABILITY_FILTER_KEY_V2 } from 'elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactoryV2'
import type {
  ConfigurableFilterOptionsType,
  FilterGenericPropsType,
  FilterKeysV2Type,
  FilterValuesType,
} from 'elements/hardwareFilter/typeV2'
import { getLastRecentUsedFilters } from 'elements/hardwareFilter/utils/lruFilterCacheStore'
import { VIEW_FROM_FILTER_NODE_KEY_V2 } from 'elements/hardwareFilter/viewFrom/ViewFromFilterNodeFactoryV2'
import { ComponentVersionsInherit } from 'opensolar-ui'
import React, { useMemo } from 'react'
import { ComponentTypesV2 } from '../../../types/selectComponent'
import { useFeatureFlag } from '../../../util/split'
import { BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2 } from '../../hardwareFilter/batteryCompatibility/BatteryCompatibilityFilterNodeFactoryV2'

export const HardwareSelectorFilterSidebarContext = React.createContext<{ location: 'shop' | 'design' }>({
  location: 'design',
})

const HardwareSelectorFilterSidebar = ({
  hardwareFilterConfigList = HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2,
  persistentFilterValues,
  modifiableFilterValues,
  allFilterValues,
  setModifiableFilterValues,
  componentTypes,
  location,
}: {
  hardwareFilterConfigList?: ConfigurableFilterOptionsType<any, any>[]
  componentTypes?: ComponentTypesV2[]
  persistentFilterValues: FilterValuesType
  modifiableFilterValues: FilterValuesType
  allFilterValues: FilterValuesType
  setModifiableFilterValues(
    newFilterValues: FilterValuesType,
    displayedFilters?: { [key in FilterKeysV2Type]?: boolean }
  ): void
  location: 'shop' | 'design'
}) => {
  const enabledHardwareShopV3 = useFeatureFlag('enable_multi_distributor_shop', 'on')
  const controlVersion = enabledHardwareShopV3 ? 2 : 1

  const expandedFilters = [
    VIEW_FROM_FILTER_NODE_KEY_V2,
    GLOBAL_DISTRIBUTOR_NODE_KEY_V2,
    AVAILABILITY_FILTER_KEY_V2,
    BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2,
  ]

  const filtersConfigs = useMemo(() => {
    return hardwareFilterConfigList.map(({ key, rendererComponent, ...rest }, index, array) => {
      const isLast = index === array.length - 1
      return {
        key,
        rendererComponentWithAccordion: createAccordionRenderer({
          BasicRenderer: rendererComponent,
          filterKey: key,
          options: {
            defaultExpanded: expandedFilters.includes(key) || !!getLastRecentUsedFilters()?.includes(key),
            showDivider: !isLast,
            recordLru: true,
          },
        }) as typeof rendererComponent,
        ...rest,
      }
    })
  }, [hardwareFilterConfigList])

  const filterNodes = useMemo(() => {
    return filtersConfigs.map(({ key, rendererComponentWithAccordion, ...rest }) => {
      const factory = FILTER_NODE_KEY_AND_FACTORY[key]
      return factory({ rendererComponent: rendererComponentWithAccordion, key, ...rest })
    })
  }, [filtersConfigs])

  return (
    <HardwareSelectorFilterSidebarContext.Provider value={{ location }}>
      <div>
        {filterNodes.map(({ key, component }, index: number) => {
          const Filter = component as React.FC<FilterGenericPropsType>

          return (
            <ComponentVersionsInherit versions={{ checkbox: controlVersion, radio: controlVersion }}>
              <Filter
                key={key}
                persistentFilterValues={persistentFilterValues}
                modifiableFilterValues={modifiableFilterValues}
                allFilterValues={allFilterValues}
                setModifiableFilters={setModifiableFilterValues}
                componentTypes={componentTypes}
              />
            </ComponentVersionsInherit>
          )
        })}
      </div>
    </HardwareSelectorFilterSidebarContext.Provider>
  )
}

export default React.memo(HardwareSelectorFilterSidebar)
