import { useTracking } from 'contexts/eventsTracking/TrackingContext'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import useUiKey from 'hooks/useUiKey'
import { useCallback, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { ActionType, EventAction, EventType } from 'types/tracking'

interface UseTrackComponentParams {
  componentKey: string
  eventName: string
  description?: string
  props?: Readonly<Record<string, any>>
  state?: Readonly<Record<string, any>>
}

interface AdditionalData {
  eventName?: string
  description?: string
  uiKey?: string
  [key: string]: any
}

const useTrackComponent = ({
  componentKey,
  eventName: defaultEventName,
  description: defaultDescription,
  props = {},
  state = {},
}: UseTrackComponentParams) => {
  const dispatchEvent = useTracking()
  const uiKey = useUiKey(componentKey)
  const featureConfig = usePublicFeatureConfig('event_config')
  const previousValues = useRef<Readonly<Record<string, any>>>({ ...props, ...state })

  /**
   * Dispatch a tracking event.
   */
  const trackEvent = useCallback(
    (type: EventType, action: EventAction, additionalData: AdditionalData = {}) => {
      const { name = defaultEventName, description = defaultDescription, ...rest } = additionalData

      dispatchEvent({
        key: uiKey,
        type,
        name,
        action,
        description,
        additionalData: rest,
        eventConfig: featureConfig?.backends,
      })
    },
    [dispatchEvent, uiKey, defaultEventName, defaultDescription, featureConfig]
  )

  /**
   * Helper for tracking user interactions.
   */
  const trackUserInteraction = useCallback(
    (action: EventAction, eventData: AdditionalData = {}) => {
      trackEvent(EventType.USER_INTERACTION, action, eventData)
    },
    [trackEvent]
  )

  /**
   * Helper for tracking system events.
   */
  const trackSystemEvent = useCallback(
    (action: EventAction, eventData: AdditionalData = {}) => {
      trackEvent(EventType.SYSTEM_EVENT, action, eventData)
    },
    [trackEvent]
  )

  /**
   * Helper for tracking error events.
   */
  const trackErrorEvent = useCallback(
    (action: EventAction, eventData: AdditionalData = {}) => {
      trackEvent(EventType.ERROR_EVENT, action, eventData)
    },
    [trackEvent]
  )

  /**
   * Track changes in props or state.
   */
  useEffect(() => {
    const combinedValues = { ...props, ...state }

    Object.keys(combinedValues).forEach((key) => {
      if (combinedValues[key] !== previousValues.current[key]) {
        trackSystemEvent(
          { type: ActionType.UPDATE },
          {
            property: key,
            description: defaultDescription,
            eventName: defaultEventName,
            oldValue: previousValues.current[key],
            newValue: combinedValues[key],
          }
        )
      }
    })

    previousValues.current = combinedValues
  }, [props, state, trackSystemEvent, defaultEventName, defaultDescription])

  /**
   * Track component visibility.
   */
  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0,
    onChange: (inView) => {
      if (inView) {
        trackUserInteraction({ type: ActionType.VIEW })
      }
    },
  })

  return {
    trackEvent,
    trackUserInteraction,
    trackSystemEvent,
    trackErrorEvent,
    ref,
  }
}

export default useTrackComponent
