import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { styled } from '../../styles/styled';
var LinkWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        marginTop: '15px',
        textAlign: 'center',
    };
});
var Link = styled('div')(function (_a) {
    var theme = _a.theme, linkColor = _a.linkColor;
    return {
        flex: 3, // Fix me
        color: linkColor,
        cursor: 'pointer',
        textDecoration: 'underline',
    };
});
export var UploadMoreStipulationLink = function (_a) {
    var linkColor = _a.linkColor, onClick = _a.onClick, stipulationName = _a.stipulationName;
    return (_jsx(LinkWrapper, { children: _jsxs(Link, { linkColor: linkColor, onClick: onClick, children: ["Need to upload more ", stipulationName, " documents?"] }) }));
};
