// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import TrackedToolbar from 'elements/TrackedToolbar'
import { BooleanInput, NumberInput, ReferenceArrayInput, SimpleForm, TextInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { required } from 'validations'
import validateCommission from './validation'

const parseFormValues = (record: any) => {
  return record
}

const formatFormValues = (values: any) => {
  return values
}

const useStyles = makeStyles((theme: any) => ({
  inputWrapper: { display: 'flex', alignItems: 'center' },
  inputLabel: { marginRight: 20, width: 180 },
  input: {},
}))

const CommissionsForm = ({ disabled, resource, ...props }) => {
  const translate = useTranslate()
  const countryIso2 = useSelector((state) => getOrgCountryCodeFromState(state))
  const currencySymbol = currencySymbolForCountry(countryIso2)
  const classes = useStyles()
  return (
    <SimpleForm
      validate={(values: any) => validateCommission(values, translate)}
      // onFormChange={handleFormChange}
      formatSubmitValues={formatFormValues}
      defaultValue={parseFormValues}
      disabled={disabled}
      resource={resource}
      toolbar={<TrackedToolbar {...props} />}
      {...props}
    >
      <TextInput validate={required} resource={resource} name={'title'} source="title" label="Title" />
      <h2>{translate('Commission settings')}</h2>
      <div className={classes.inputWrapper}>
        <div className={classes.inputLabel}>{translate('Commission base percentage')}</div>
        <div className={classes.input}>
          <NumberInput
            resource={resource}
            name="base_percentage"
            source="base_percentage"
            endAdornment="%"
            label={false}
          />
        </div>
      </div>

      <div className={classes.inputWrapper}>
        <div className={classes.inputLabel}>{translate('Commission base price')}</div>
        <div className={classes.input}>
          <NumberInput
            resource={resource}
            name="base_amount"
            source="base_amount"
            startAdornment={currencySymbol}
            label={false}
          />
        </div>
      </div>
      {countryIso2 === 'DE' && (
        <div className={classes.inputWrapper}>
          <div className={classes.inputLabel}>{translate('Commission per watt')}</div>
          <div className={classes.input}>
            <NumberInput
              resource={resource}
              name="per_watt"
              source="per_watt"
              startAdornment={currencySymbol}
              endAdornment={translate('per watt')}
              label={false}
            />
          </div>
        </div>
      )}

      <div className={classes.inputWrapper}>
        <div className={classes.inputLabel}>{translate('Surcharge')}</div>
        <div className={classes.input}>
          <NumberInput resource={resource} name="surcharge" source="surcharge" endAdornment={'%'} label={false} />
        </div>
      </div>

      <div className={classes.inputWrapper}>
        <div className={classes.inputLabel}>{translate('Discount')}</div>
        <div className={classes.input}>
          <NumberInput resource={resource} name="discount" source="discount" endAdornment={'%'} label={false} />
        </div>
      </div>

      <div className={classes.inputWrapper}>
        <div className={classes.inputLabel}>{translate('Max Additional Commission Amount')}</div>
        <div className={classes.input}>
          <NumberInput
            resource={resource}
            name="max_additional_commission"
            source="max_additional_commission"
            startAdornment={currencySymbol}
            label={false}
          />
        </div>
      </div>
      <h2 style={{ marginBottom: 0 }}>{translate('Team members this commission applies to')}</h2>
      <div style={{ marginBottom: 40 }}>
        <ReferenceArrayInput
          label={false}
          resource={resource}
          source="roles"
          reference="roles"
          optionValueField="url"
          fullWidth={true}
          renderChildrenBeforeRecord
        >
          {/* @ts-ignore */}
          <ChipsInput label={false} optionText="display" optionValue="url" fullWidth={true} showSpinnerWhenRefreshing />
        </ReferenceArrayInput>
      </div>
      <CustomField
        component={BooleanInput}
        style={{ marginTop: 20 }}
        name="is_archived"
        source="is_archived"
        label="Is Archived"
        {...props}
      />
    </SimpleForm>
  )
}

export default CommissionsForm
