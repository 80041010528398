import { orgSelectors } from 'ducks/orgs'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { useTranslate } from 'ra-core'
import { BooleanInput } from 'react-admin'
import { useSelector } from 'react-redux'
import { styles } from '../styles.js'

export const CheckoutFinance = ({ resource }) => {
  const translate = useTranslate()
  const isCheckoutFinanceEnabledInOrg = useSelector(orgSelectors.getOrg)?.enable_checkout_finance
  const disabled = !isCheckoutFinanceEnabledInOrg

  return (
    <div style={{ ...styles.loanWrapper, ...(!isCheckoutFinanceEnabledInOrg ? { display: 'none' } : {}) }}>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: 50 }}>
        <BooleanInput
          resource={resource}
          formControlLabelStyle={styles.formControlLabelStyle}
          defaultValue={isCheckoutFinanceEnabledInOrg ? true : undefined}
          label="Checkout Finance Enabled"
          name="variable_checkout_finance_enabled"
          source="variable_checkout_finance_enabled"
          disabled={disabled}
        />
        <InfoTooltip
          title={translate(
            'A call to action for your customers to explore available financing to fund cash purchases will be embedded in your proposals and invoices. No sign-up process or fees for your business.'
          )}
        />
      </div>
    </div>
  )
}
