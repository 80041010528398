// @ts-nocheck
import { LinearProgress, MenuItem, Select, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button } from 'opensolar-ui'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { PremiumImageryBlockReasonType } from 'types/global'
import { MapDataTypes } from 'types/map'
import { WalletProductType } from 'types/products'
import { ActionType, EventType } from 'types/tracking'
import {
  getCurrentLonLat,
  getIsImageryPremium,
  getSelectedPremiumImageryProduct,
  getSelectedPremiumImageryProvider,
  restrictToBounds,
  SelectedPremiumImageryResponseType,
  tryAddPremiumImageryIfRequiredAndNotify,
} from 'util/imagery'
import { getMapTypeKey, JSONstringifySortedKeys } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import restClient from '../../../../../restClient'
import PremiumImageryWarning from '../designMode/PremiumImageryWarning'
import filterAvailableImageryTypesWithFeatureFlags from '../filterAvailableImageryTypesWithFeatureFlags'
import PremiumImageryTerms from './PremiumImageryTerms'
const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  mode: 'change' | 'add'
  availableImageryTypes: MapDataTypes[] | []
  forcePopoverReposition: Function
  isPremiumImageryAvailable: boolean
  premiumImageryUnavailableReson: PremiumImageryBlockReasonType
}

type VariantMapDataTypes = {
  field: string
  value: any
  label: any
} | null

const useStyles = makeStyles({
  input: {
    width: '100%',
    margin: '5px 0',
    height: 36,
    background: 'rgb(255,255,255)',
    '& fieldset': {
      border: 'none',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },
  outlined: {
    padding: 5,
  },
  wrapper: {
    margin: '10px 0',
  },
  button: {
    marginTop: 10,
    width: 100,
    fontWeight: 'normal',
    fontSize: 12,
    background: 'rgb(255,255,255)',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  buttonWrapper: {
    textAlign: 'center',
  },
  disabledMessage: {
    fontSize: 12,
    color: '#ff0000',
    '& div div,a,p': { color: '#ff0000', fontSize: 12 },
  },
  customTooltip: {
    backgroundColor: 'rgb(255,255,255)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
  },
  timestampTooltip: {
    whiteSpace: 'pre-wrap',
  },
  customArrow: {
    color: 'rgb(255,255,255)',
  },
})

type TimestampData = {
  first_timestamp: string | undefined
  last_timestamp: string | undefined
  timezone: string | undefined
}

const GROUP_VALUES_TO_LABEL = [
  ['N', 'North'],
  ['NE', 'North-East'],
  ['E', 'East'],
  ['SE', 'South-East'],
  ['S', 'South'],
  ['SW', 'South-West'],
  ['W', 'West'],
  ['NW', 'North-West'],
]

const getVariationForMapData = (mapData: any) => {
  if (mapData.mapType === 'NearmapSource' && mapData.oblique) {
    var direction = window.Designer.classifyDirection(mapData.oblique.inclinationDeg, mapData.oblique.bearingDeg, false)
    var label = GROUP_VALUES_TO_LABEL.filter((g) => g[0] === direction)[0]
    return {
      field: 'direction',
      value: direction,
      label: label ? label[1] : direction,
    }
  } else {
    return null
  }
}

const getVariationForMapTypeData = (m: MapDataTypes) => {
  if (m.map_type === 'NearmapSource') {
    var label = GROUP_VALUES_TO_LABEL.filter((g) => g[0] === m.variation_data.direction)[0]
    return {
      field: 'direction',
      value: m.variation_data.direction,
      label: label ? label[1] : m.variation_data.direction,
    }
  } else {
    return null
  }
}

const DIRECTIONS = GROUP_VALUES_TO_LABEL.map((g) => g[0])

export const usingLegacyGoogle3D = () => {
  // New endpoints use the proxy endpoint instead of direct link to Google endpoint
  return window.editor?.scene?.dsmUrl?.includes('google')
}

const PopoverViewSelector: React.FC<PropTypes> = (props) => {
  // When modifying an existing view we force selectedMapType to equal the current map type
  // and we hide the other dropdowns
  const initialSelectedMapType =
    props.mode === 'change'
      ? window.MapHelper && window.MapHelper.activeMapInstance && window.MapHelper.activeMapInstance.mapData.mapType
      : null

  const initialMapData = window.MapHelper?.activeMapInstance?.mapData
  const initialSelectedMapVariationGroup = initialMapData ? getVariationForMapData(initialMapData) : null
  const translate = useTranslate()
  const notify = useNotify()
  const projectForm = useFormState()
  const premiumImageryActivations = projectForm.values.premium_imagery_activations || []

  const availableMapTypesRaw = useMemo(() => props.availableImageryTypes.map((m: MapDataTypes) => m.map_type), [
    props.availableImageryTypes,
  ])
  const orgId = useSelector(authSelectors.getOrgId)
  const countryCode = useFormState().values?.country_iso2
  const projectId = useFormState().values?.id

  var availableMapTypes: string[] = []
  window.mapTypeToDesignModePriority().forEach((_mapType: string) => {
    // do not add if already added
    if (availableMapTypes.indexOf(_mapType) === -1) {
      // add if any availableMapTypes match this possible map type
      if (availableMapTypesRaw.filter((mapTypeRaw: string) => mapTypeRaw === _mapType).length > 0) {
        availableMapTypes.push(_mapType)
      }
    }
  })

  const setInitialMapType = (): string => {
    if (initialSelectedMapType) {
      // change map type takes priority
      return initialSelectedMapType
    } else {
      return availableMapTypes[0]
    }
  }

  const [selectedMapType, setSelectedMapType] = useState(setInitialMapType())
  const [selectedMapVariationGroup, setSelectedMapVariationGroup] = useState(null)
  const [selectedMapVariation, setSelectedMapVariation] = useState(null)
  const [selectedPremiumProvider, setSelectedPremiumProvider] = useState<SelectedPremiumImageryResponseType>(undefined)
  const [walletProduct, setWalletProduct] = useState<WalletProductType | undefined>()
  const purchasesForProjectsAccess = useSelector(
    permissionsSelectors.getProjectPermissionByKey('purchases_for_projects')
  )
  const [timestampCache, setTimestampCache] = useState({})
  const [timestamp, setTimestamp] = useState('')
  const [loading, setLoading] = useState(false)

  const enableGoogleSolarApi = useFeatureFlag('google_solar_api', 'on')
  const enableGoogleSunroof = useFeatureFlag('google_sunroof', 'on')

  const { trackEvent } = useTrackComponent({
    componentKey: 'popover_view_selector',
    eventName: 'User Clicked On Popover Add View Button',
  })

  const availableImageryTypes = filterAvailableImageryTypesWithFeatureFlags(
    props.availableImageryTypes,
    enableGoogleSolarApi,
    enableGoogleSunroof
  )

  const currentLonLat = getCurrentLonLat([window.WorkspaceHelper.project.lon, window.WorkspaceHelper.project.lat])
  const premiumImageryActivationsMatchesSorted = premiumImageryActivations
    .slice()
    .reverse()
    .filter((a) => a.product.id === walletProduct?.id)
    .filter((a) => restrictToBounds(a, currentLonLat))
  const selectedPremiumProviderIsAlreadyActivated = premiumImageryActivationsMatchesSorted.some(
    (pia) => pia.period === 'initial'
  )

  // Note: We will retain Legacy Google3D support for a long time after we disable it with feature flags
  // to avoid problems with loading old projects
  const SUPPORT_LEGACY_GOOGLE_3D = true

  const getSelectedMapDataTypes = (availableImageryTypes, selectedMapType) => {
    if (SUPPORT_LEGACY_GOOGLE_3D) {
      // See equivalent logic in spa/studio/src/managers/SceneHelper.js
      return availableImageryTypes?.filter((img) => {
        if (img.map_type === selectedMapType && img.map_type === 'Google3D') {
          const isLegacyGoogle3D = usingLegacyGoogle3D()
          if (isLegacyGoogle3D && img.variation_name === 'Google 3D (Legacy)') {
            return true
          } else if (!isLegacyGoogle3D && img.variation_name === 'Google Solar API') {
            return true
          } else {
            return false
          }
        } else {
          return img.map_type === selectedMapType
        }
      })
    } else {
      return availableImageryTypes?.filter((img) => img.map_type === selectedMapType)
    }
  }

  useEffect(() => {
    const selectedMapDataTypes = getSelectedMapDataTypes(availableImageryTypes, selectedMapType)
    const selectedMapData = selectedMapDataTypes?.length > 0 ? selectedMapDataTypes[0] : undefined
    let premiumProvider = getSelectedPremiumImageryProvider(selectedMapData)
    if (premiumProvider) setSelectedPremiumProvider(premiumProvider)
    else if (selectedPremiumProvider) setSelectedPremiumProvider(undefined)

    setWalletProduct(getSelectedPremiumImageryProduct(selectedMapData))
  }, [selectedMapType, selectedMapVariation])

  const selectedPremiumProviderRequiresProjectPurchasesPermission =
    selectedPremiumProvider && walletProduct?.activation_requires_permission

  const classes = useStyles()

  var availableMapVariationGroupsRaw = availableImageryTypes
    .filter((m: MapDataTypes) => m.map_type === selectedMapType)
    .map(getVariationForMapTypeData)
    .filter(Boolean)

  // Force sort order and strip duplicates at the same time
  // This only works because currently the only map types that uses groups is NearmapSource

  var availableMapVariationGroups: any[] = []
  DIRECTIONS.forEach((d) => {
    var groupRaw = availableMapVariationGroupsRaw.filter((g: VariantMapDataTypes) => g && g.value === d)[0]
    if (groupRaw) {
      availableMapVariationGroups.push(groupRaw)
    }
  })

  var selectedMapVariationGroupOrFirst =
    selectedMapVariationGroup ||
    (initialMapData.mapType === selectedMapType ? initialSelectedMapVariationGroup : null) ||
    availableMapVariationGroups[0]

  var availableImageryTypesFiltered = selectedMapVariationGroupOrFirst
    ? availableImageryTypes.filter(
        (m: MapDataTypes) =>
          m.map_type === selectedMapType &&
          m.variation_data[selectedMapVariationGroupOrFirst.field] === selectedMapVariationGroupOrFirst.value
      )
    : availableImageryTypes.filter((m: MapDataTypes) => m.map_type === selectedMapType)

  var selectedMapVariationOrAutoDetectCurrentOrFirst = null

  // if selectedMapVariation is found then use it
  if (!selectedMapVariationOrAutoDetectCurrentOrFirst) {
    if (
      selectedMapVariation &&
      availableImageryTypesFiltered.some((m: MapDataTypes) => getMapTypeKey(m) === getMapTypeKey(selectedMapVariation))
    ) {
      selectedMapVariationOrAutoDetectCurrentOrFirst = selectedMapVariation
    }
  }

  // otherwise if not set, find first variation name which matches current variation
  if (!selectedMapVariationOrAutoDetectCurrentOrFirst) {
    var selectedMapVariationGroupAutoDetected =
      window.MapHelper && window.MapHelper.activeMapInstance && window.MapHelper.activeMapInstance.mapData
        ? MapData.getVariationName(window.MapHelper.activeMapInstance.mapData)
        : null

    if (selectedMapVariationGroupAutoDetected) {
      var availableImageryTypesFilteredMatchingCurrentvariation = availableImageryTypesFiltered.filter(
        (m: MapDataTypes) => m.variation_name === selectedMapVariationGroupAutoDetected
      )[0]
      if (availableImageryTypesFilteredMatchingCurrentvariation) {
        selectedMapVariationOrAutoDetectCurrentOrFirst = availableImageryTypesFilteredMatchingCurrentvariation
      }
    }
  }

  // For Nearmap3D we actually need to incorporate some scene data to determine the current selection
  if (!selectedMapVariationOrAutoDetectCurrentOrFirst && initialMapData?.mapType === 'Nearmap3D') {
    var currentCaptureDate = window.editor.scene.groundVariationData().capture_date
    if (currentCaptureDate) {
      selectedMapVariationOrAutoDetectCurrentOrFirst = availableImageryTypesFiltered.find(
        (m: MapDataTypes) => m?.variation_data?.capture_date === currentCaptureDate
      )
    }
  }

  // otherwise just use first
  if (!selectedMapVariationOrAutoDetectCurrentOrFirst) {
    selectedMapVariationOrAutoDetectCurrentOrFirst = availableImageryTypesFiltered[0]
  }

  // Only correct at the highest level, this may not be the correct specific variation

  var selectedImageryType = availableImageryTypesFiltered[0]

  if (SUPPORT_LEGACY_GOOGLE_3D) {
    // Special override to distinguish between Legacy and New Google3D.
    // If no match happens here it will just use the first item which was selected above
    const isLegacyGoogle3D = usingLegacyGoogle3D()

    availableImageryTypesFiltered.forEach((availableImageryType) => {
      if (isLegacyGoogle3D && availableImageryType.variation_name === 'Google 3D (Legacy)') {
        selectedImageryType = availableImageryType
        if (!selectedMapVariation) {
          selectedMapVariationOrAutoDetectCurrentOrFirst = availableImageryType
        }
      } else if (!isLegacyGoogle3D && availableImageryType.variation_name === 'Google Solar API') {
        selectedImageryType = availableImageryType
        if (!selectedMapVariation) {
          selectedMapVariationOrAutoDetectCurrentOrFirst = availableImageryType
        }
      }
    })
  }

  var disabled =
    Boolean(selectedImageryType && selectedImageryType.disabled_message) ||
    (selectedPremiumProviderRequiresProjectPurchasesPermission &&
      getIsImageryPremium(selectedMapVariationOrAutoDetectCurrentOrFirst) &&
      !purchasesForProjectsAccess?.allowCreate)

  const handleSelection = (event: ChangeEvent) => {
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.SELECT },
      { eventName: 'User Selected Map Type', mapType: event.target.value }
    )
    setSelectedMapType(event.target.value)
    props.forcePopoverReposition()
  }

  const onAdd = async () => {
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK })
    var isActivated = await tryAddPremiumImageryIfRequiredAndNotify(
      selectedMapVariationOrAutoDetectCurrentOrFirst,
      premiumImageryActivations,
      getCurrentLonLat([window.WorkspaceHelper.project.lon, window.WorkspaceHelper.project.lat]),
      notify,
      translate
    )
    if (isActivated) {
      trackEvent(
        EventType.SYSTEM_EVENT,
        { type: ActionType.ADD },
        { eventName: 'User Added Popover View ', selectedMapVariationOrAutoDetectCurrentOrFirst }
      )
      window.SceneHelper.addOrChangeView(selectedMapVariationOrAutoDetectCurrentOrFirst, 'add')
    }
  }

  const formatDateTime = (dateTime) => {
    var hours = dateTime.getHours()
    var minutes = dateTime.getMinutes()
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ampm
    var months = parseInt(dateTime.getMonth() + 1)
    months = months < 10 ? '0' + months : months
    var days = parseInt(dateTime.getDate())
    days = days < 10 ? '0' + days : days
    return dateTime.getFullYear() + '-' + months + '-' + days + ' ' + strTime
  }

  const formatVariationNameForDisplay = (mapData: MapDataTypes) => {
    const variationName = mapData.variation_name
    // only continue if first letter is direction, second letter ir space, and last letter is Z which indicates it's a raw timestamp
    if (variationName[1] === ' ' && variationName.substr(-1) === 'Z') {
      var [direction, variationNameConvertedToDatetime] = variationName.split(' ')
      return direction + ' ' + formatDateTime(new Date(variationNameConvertedToDatetime))
    } else {
      return variationName
    }
  }

  const formatTimeTaken = (data: TimestampData) => {
    if (!data.first_timestamp) {
      return 'No Details Found'
    } else if (data.first_timestamp === data.last_timestamp) {
      return `${data.first_timestamp}\n(${data.timezone})`
    } else {
      return `${data.first_timestamp} - ${data.last_timestamp}\n(${data.timezone})`
    }
  }

  const onTooltipOpen = (e: Event, mapData: MapDataTypes) => {
    let capture_date = mapData.variation_data.capture_date || ''
    let timestampCacheKey = btoa(
      encodeURIComponent(`${mapData.variation_data.lon}${mapData.variation_data.lat}${capture_date}`)
    )
    let tooltipId = e.target.getAttributeNode('aria-describedby').value
    let tooltipElement = document.getElementById(tooltipId)
    if (typeof timestampCache[timestampCacheKey] !== 'undefined') {
      setTimestamp(formatTimeTaken(timestampCache[timestampCacheKey]))
      return
    }

    setLoading(true)

    let url =
      'orgs/' +
      orgId +
      `/maps/get_imagery_timestamp/?lon=${mapData.variation_data.lon}&lat=${mapData.variation_data.lat}&capture_date=${capture_date}&project_id=${projectId}`
    let nearmap_token = window.getStorage().getItem('nearmap_token')
    if (nearmap_token && nearmap_token.length > 0) {
      url += '&nearmap_token=' + encodeURIComponent(nearmap_token)
    }

    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((response: any) => {
        setTimestampCache({ ...timestampCache, [timestampCacheKey]: response.data })
        if (tooltipElement) {
          setTimestamp(formatTimeTaken(response.data))
          setLoading(false)
        }
      })
      .catch((err: any) => {
        if (tooltipElement) {
          setTimestamp('No Details Found')
          setLoading(false)
        }
      })
  }

  return (
    <div className={classes.wrapper}>
      {props.mode === 'add' && (
        <Select
          classes={{ outlined: classes.outlined }}
          variant="outlined"
          className={classes.input}
          onChange={handleSelection}
          value={selectedMapType}
        >
          {availableMapTypes.map((mapType, index) => (
            <MenuItem key={index} value={mapType}>
              {mapType}
            </MenuItem>
          ))}
        </Select>
      )}
      {props.mode === 'add' && availableMapVariationGroups.length > 0 && (
        <Select
          classes={{ outlined: classes.outlined }}
          variant="outlined"
          className={classes.input}
          onChange={(event) => {
            setSelectedMapVariationGroup(JSON.parse(event.target.value as string))
            props.forcePopoverReposition()
          }}
          value={JSONstringifySortedKeys(selectedMapVariationGroupOrFirst)}
        >
          {availableMapVariationGroups.map((group, index) => (
            <MenuItem key={index} value={JSONstringifySortedKeys(group)}>
              {group.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {availableImageryTypesFiltered && availableImageryTypesFiltered.length > 1 && (
        <Select
          classes={{ outlined: classes.outlined }}
          variant="outlined"
          className={classes.input}
          onChange={(event) => {
            var mapVariationData = JSON.parse(event.target.value as string)
            setSelectedMapVariation(mapVariationData)
            props.forcePopoverReposition()
            if (props.mode === 'change') {
              window.SceneHelper.addOrChangeView(mapVariationData, 'change')
            }
          }}
          value={JSONstringifySortedKeys(selectedMapVariationOrAutoDetectCurrentOrFirst)}
        >
          {availableImageryTypesFiltered.map((m: MapDataTypes, index: number) =>
            selectedMapType !== 'Nearmap' || countryCode !== 'AU' ? (
              <MenuItem key={index} value={JSONstringifySortedKeys(m)}>
                {formatVariationNameForDisplay(m)}
              </MenuItem>
            ) : (
              <Tooltip
                title={
                  <div
                    className={classes.timestampTooltip}
                    style={{
                      minHeight: 20,
                      minWidth: 100,
                      maxWidth: 220,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {loading ? <LinearProgress style={{ width: '100%' }} /> : <div>{timestamp}</div>}
                  </div>
                }
                placement="right"
                arrow
                onOpen={(e) => onTooltipOpen(e, m)}
                key={index}
                value={JSONstringifySortedKeys(m)}
                enterDelay={500}
                enterNextDelay={500}
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <MenuItem>{formatVariationNameForDisplay(m)}</MenuItem>
              </Tooltip>
            )
          )}
        </Select>
      )}

      {Boolean(selectedImageryType && selectedImageryType.disabled_message && props.mode === 'add') && (
        <div
          className={classes.disabledMessage}
          dangerouslySetInnerHTML={{ __html: selectedImageryType.disabled_message || '' }}
        />
      )}

      {props.mode === 'add' &&
        selectedPremiumProvider &&
        walletProduct &&
        !selectedPremiumProviderIsAlreadyActivated && (
          <PremiumImageryTerms
            provider={selectedPremiumProvider}
            walletProduct={walletProduct}
            selectedPremiumImageryActivation={premiumImageryActivations[premiumImageryActivations.length - 1]}
          />
        )}
      {props.mode === 'add' &&
        selectedPremiumProvider &&
        selectedPremiumProviderRequiresProjectPurchasesPermission &&
        props.premiumImageryUnavailableReson && (
          <PremiumImageryWarning message={props.premiumImageryUnavailableReson} />
        )}

      {props.mode === 'add' && (
        <div className={classes.buttonWrapper}>
          <Button
            //   style={{ width: 100, height: 40, borderRadius: 2, padding: 0, marginLeft: 10 }}
            classes={{ root: classes.button }}
            disabled={disabled}
            onClick={onAdd}
          >
            <span>{translate('Add View')}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default PopoverViewSelector
