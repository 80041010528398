export enum ProductType {
  PV = 'PV',
  PV_ST = 'PV+ST',
  BIPV = 'BIPV',
  BIPV_ST = 'BIPV+ST',
  EV = 'EV',
}

type SolarAppAddress = {
  street_number: string
  street_name: string
  street_name_2: string | null
  city: string
  state: string
  zip: string
}

export enum SolarAppPermitStates {
  DRAFT = 'draft',
  QUALIFIED = 'qualified',
  APPROVED = 'approved',
  ARCHIVED = 'archived',
}

export enum SolarAppAHJIntegrationModes {
  STANDALONE = 'standalone',
  INTEGRATED = 'integrated',
}

export interface SolarAppErrorResponsePayload {
  error_code: string
  message: string
  errors?: {
    [key: string]: string[]
  }
}

export interface SolarAppProjectSubmittedResponsePayload {
  uuid: string
  title: string
  product_type: string
  code: string
  url: string
  address: SolarAppAddress
}

export type FieldError = {
  [fieldName: string]: string | undefined
}

export type FieldErrors = FieldError | undefined

export interface SolarAppProject {
  uuid: string
  title: string
  address: SolarAppAddress
  installer: {
    company_name: string
    email: string
    first_name: string
    last_name: string
  }
  ahj: {
    name: string
    address: SolarAppAddress
    phone: string
    email: string
  }
  approval_id: string
  revision: string
  current_state: SolarAppPermitStates
}

export interface SolarAppProjectStatus {
  uuid: string
  url: string
  product_type: string
  title: string
  address: SolarAppAddress
  installer: {
    company_name: string
    email: string
    first_name: string
    last_name: string
  }
  ahj: {
    uuid: string
    name: string
    address: SolarAppAddress
    phone: string
    email: string
    mode: SolarAppAHJIntegrationModes
    payment_url?: string
  }
  approval_id: string
  flags: {
    name: string
    description: string
    solution: string
    type: string
  }[]
  revision: string
  current_state: SolarAppPermitStates
}
