import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import isEmpty from 'lodash/isEmpty'
import {
  getProposalV2AppUrlOverride,
  toggleDebugAppUrlByCurrentEnvironment,
} from 'myenergy/mainContent/components/proposalV2/utils'
import {
  Box,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  styled,
  Switch,
  Typography,
} from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useDataProvider, useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getDefaultProposalSettings, getProposalV2Settings } from 'resources/proposalTemplates/ProposalV2FromContent'
import { ProposalTemplateTypeEnum } from 'resources/proposalTemplates/types'
import type { ProposalTemplateType } from 'types/proposalTemplate'
import { doNotTranslate, reloadEntireApp } from 'util/misc'
import { onChangeProposalTemplateSettings } from '../ProposalInputs.js'
import SystemLimitProposalAlert from './SystemLimitProposalAlert'
import useTemplateLibraryModal, { TemplateLibraryTypeEnum } from './useTemplateLibraryModal'

const StyledDialog = styled(Dialog)({
  // override z-index to show on top of other dialogs
  zIndex: '2002 !important',
  '.MuiDialog-paper': {
    width: '600px',
    maxWidth: '600px',
  },
  '.MuiDialogTitle-root': {
    color: '#4C4B53',
    fontWeight: 600,
    svg: {
      stroke: 'none',
    },
  },
})

const StyledSection = styled('div')({
  width: 'calc(50% - 12px)',
  '> p': {
    fontWeight: 600,
    marginBottom: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

export type PropTypes = {
  disableClose: boolean
  openDefault: boolean
  handleChange: () => void
  isSystemLimitExceeded: boolean
  setWaitingForAction: (value: boolean) => void
}

/**
 * Use hook to control modal
 *
 * Sample:
 * const templateModal = useTemplateLibraryModal()
 * templateModal.setOpen()
 */
const TemplateLibraryModal: React.FC<PropTypes> = ({
  disableClose,
  handleChange,
  openDefault,
  isSystemLimitExceeded,
  setWaitingForAction,
}) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const org = useSelector(orgSelectors.getOrg)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const debugProposalV2AppUrl = getProposalV2AppUrlOverride()
  const form = useForm()
  const formState = useFormState()
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const { open, setOpen, setClose, loading, templateSections } = useTemplateLibraryModal()

  useEffect(() => {
    setWaitingForAction(loading)
  }, [loading])

  useEffect(() => {
    if (loading) return

    if (openDefault) setOpen()
  }, [loading, openDefault])

  const onClose = () => {
    if (disableClose) return // do nothing
    setClose()
  }

  const registerAndUpdateField = (form, fieldName, value) => {
    const isFieldExist = form.getFieldState(fieldName)
    if (!isFieldExist) {
      form.registerField(fieldName, () => {}, {})
    }
    form.change(fieldName, value)
  }

  const handleSelect = async (updatedTemplate, proposalTemplateData) => {
    if (updatedTemplate === TemplateLibraryTypeEnum.ProposalV2 && isEmpty(proposalTemplateData)) {
      // Create a default ProposalV2 template, if it doesn't exist yet
      setWaitingForAction(true)
      let newTemplateData: ProposalTemplateType | undefined
      try {
        const createResult = await dataProvider.CUSTOM_POST('proposal_templates_create', {
          url: 'proposal_templates/',
          data: {
            title: `${org!.name} Proposal`,
            type: ProposalTemplateTypeEnum.proposalV2,
            settings: JSON.stringify(getProposalV2Settings(getDefaultProposalSettings())),
          },
        })
        newTemplateData = createResult?.data
      } catch (e) {
        console.error('Failed to create Default Proposal V2 template', e)
      }
      if (!newTemplateData?.url) {
        notify(translate('Failed to select proposal template. Please try again later.'), 'error')
        setWaitingForAction(false)
        return
      }
      proposalTemplateData = newTemplateData
      setWaitingForAction(false)
    }

    onChangeProposalTemplateSettings(form, proposalTemplateData)
    registerAndUpdateField(form, 'proposal_template', proposalTemplateData?.url || '')

    const oldCustomData = formState.values.custom_data || {}
    registerAndUpdateField(form, 'custom_data', {
      ...oldCustomData,
      proposal_config_v2: {
        ...(oldCustomData?.proposal_config_v2 || {}),
        has_selected_template: true,
      },
    })

    setSelectedTemplate(updatedTemplate)
    handleChange() // reflect form changes
    setClose()
  }

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>{translate('Template Library')}</span>
          <IconButton disabled={disableClose} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isSystemLimitExceeded && <SystemLimitProposalAlert />}

        <Typography textVariant="body1">
          {translate('Choose a template to create a proposal for your customer')}
        </Typography>
        <Box display="flex" gridGap={24} padding="24px 0">
          {templateSections.map(({ key, label, templateCard, value }) => (
            <StyledSection key={key}>
              <Typography textVariant="body2" colorHex="#0A090B" gutterBottom>
                {label}
              </Typography>
              {templateCard({
                selected: key === selectedTemplate,
                onSelect: async () => await handleSelect(key, value),
                disabled: isSystemLimitExceeded && key === TemplateLibraryTypeEnum.ProposalV2,
              })}
            </StyledSection>
          ))}
        </Box>
        {isStaff && (
          <Box>
            <p>
              <Typography textVariant="h5">{doNotTranslate('Staff Only:')}</Typography>
            </p>
            <div>
              {debugProposalV2AppUrl
                ? doNotTranslate(`You are currently debugging the SM proposal app with ${debugProposalV2AppUrl}`)
                : doNotTranslate(
                    `Enable this following toggle overrides the SM proposal app url to ${window.PROPOSAL_V2_BASE_URL}/v1/`
                  )}
            </div>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  onChange={() => {
                    const setOverride = !debugProposalV2AppUrl
                    toggleDebugAppUrlByCurrentEnvironment(setOverride)
                    reloadEntireApp()
                  }}
                  checked={!!debugProposalV2AppUrl}
                />
              }
              label={doNotTranslate('Overrides SM Proposal App Url')}
            />
          </Box>
        )}
      </DialogContent>
    </StyledDialog>
  )
}

export default TemplateLibraryModal
