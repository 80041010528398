import { Divider, Typography } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { useCallback, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { MountingID, MountingSystemType, MountingSystemTypeOptions } from 'types/mounting'
import { clear as clearMountingSystem } from '../nativeMounting/calculate'
import MountingSystemSelector from './MountingSystemSelector'
import MountingSystemTypeSelector from './MountingSystemTypeSelector'
import useApplyMountingSystem from './useApplyMountingSystem'

export const allMountingTypeOptions: MountingSystemTypeOptions[] = [
  {
    type: 'onRoof',
    label: 'On-Roof',
    mountingIds: [
      'ironridge',
      'sunmodoSMRPitched',
      'sunmodoNanoRack',
      'variosole',
      'metasole',
      'esdec',
      'schletter',
      'fastensol',
      'solarRoofPro',
      'msPitchedProLine',
      'msTrapezeProLine',
      'renusol',
    ],
  },
  { type: 'inRoof', label: 'In-Roof', mountingIds: ['viridian', 'gseInRoof'] },
  { type: 'flatRoof', label: 'Flat-Roof', mountingIds: ['renusol', 'flatFix'] },
  { type: 'ground', label: 'Ground', mountingIds: ['renusol'] },
]

export const mountingIdAvailabilityByIso2: Record<MountingID, string[]> = {
  ironridge: ['US'],
  sunmodoSMRPitched: ['US'],
  sunmodoNanoRack: ['US'],
  solarRoofPro: ['GB'],
  variosole: ['GB'],
  schletter: ['GB'],
  fastensol: ['GB'],
  renusol: ['GB', 'PL', 'DE', 'NL', 'BE'],
  esdec: ['GB'],
  flatFix: ['GB'],
  viridian: ['GB'],
  gseInRoof: [],
  msPitchedProLine: ['GB', 'PL', 'DE', 'NL', 'ES'],
  msTrapezeProLine: ['GB'],
  metasole: ['GB'],
}

export const useGetMountingOptsInCountry = (): MountingSystemTypeOptions[] => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2) || ''
  return useMemo(() => {
    return allMountingTypeOptions.reduce(
      (acc: MountingSystemTypeOptions[], mountingOption: MountingSystemTypeOptions) => {
        const availableSuppliersInCountry = mountingOption.mountingIds.filter((supplier) =>
          mountingIdAvailabilityByIso2[supplier].includes(countryIso2)
        )
        if (availableSuppliersInCountry.length === 0) {
          return acc
        } else {
          acc.push({ ...mountingOption, mountingIds: availableSuppliersInCountry })
          return acc
        }
      },
      []
    )
  }, [countryIso2])
}

const useStyles = makeOpenSolarStyles((theme) => ({
  selector: {
    margin: '10px 0',
    backgroundColor: theme.greyLight3,
  },
  divider: {
    margin: '10px 0',
  },
}))

const useSelectedMountingSystemType = ({
  systemMountingType,
  systemMountingSystem,
  mountingOpts,
}): MountingSystemType => {
  return useMemo(() => {
    if (systemMountingType) {
      return systemMountingType
    } else if (systemMountingSystem !== undefined && systemMountingType === undefined) {
      // Inferred mounting type from selected mounting system
      const inferred = mountingOpts.find((option) => option.mountingIds.includes(systemMountingSystem))
      return inferred?.type
    } else if (systemMountingSystem === undefined && systemMountingType === undefined) {
      // Defaults to only type available (currently 'onRoof' is first item in mountingOpts array so all stays the same except it won't default if no suppliers are found for that type in MountingSystemSelector.tsx)
      return mountingOpts[0]?.type
    }
  }, [systemMountingType, systemMountingSystem])
}

const MountingSelector = ({ system, allowEdit }: { system: StudioSystemType; allowEdit: boolean }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const mountingTypeOptions = useGetMountingOptsInCountry()

  const applyMountingSystem = useApplyMountingSystem()
  const selectedMountingSystem = system.mounting
  const selectedType = useSelectedMountingSystemType({
    systemMountingType: system.mounting_type,
    systemMountingSystem: selectedMountingSystem,
    mountingOpts: mountingTypeOptions,
  })
  const handleSelectMountingType = useCallback((value: MountingSystemType): void => {
    const system = window.editor.selectedSystem
    const commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
    window.editor.execute(new window.SetValueCommand(system, 'mounting_type', value, commandUUID))
    window.editor.signals.objectChanged.dispatch(system, 'mounting_type')
  }, [])

  const handleUpdateMountingSystem = (mountingId: MountingID) => {
    applyMountingSystem({ mountingId, system })

    if (!mountingId) clearMountingSystem(system)
  }
  return (
    <div>
      <Divider className={classes.divider} />
      <Typography variant="subtitle2" gutterBottom>
        {translate('Selected Mounting System Type')}
      </Typography>
      <MountingSystemTypeSelector
        disabled={!!selectedMountingSystem || !allowEdit}
        selectedType={selectedType}
        handleSelectMountingType={handleSelectMountingType}
      />
      <Divider className={classes.divider} />
      <MountingSystemSelector
        selectedType={selectedType}
        selectedMountingSystem={selectedMountingSystem}
        handleUpdateMountingSystem={handleUpdateMountingSystem}
      />
    </div>
  )
}

export default MountingSelector
