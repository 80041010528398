/*
Usage:

Insert two items in the parent state and inside stateFromObject():
    {
    ...
      shadingOverrideType: shadingTypeFromShadingOverride(
        yourObjectReference.shadingOverride
      ),
      shadingOverride: yourObjectReference.shadingOverride ? yourObjectReference.shadingOverride : [],
    }

Add control:

        <ShadingControls
          setStateParent={_state => this.setState(_state)}
          object={yourObjectReference}
          state={this.state}
          handleSetShadingOverride={handleSetShadingOverride.bind(this)}
        />


*/
import { MenuItem, Slider, TextField } from '@material-ui/core'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import _ from 'lodash'
import { useTranslate } from 'react-admin'
import { ActionType, EventType } from 'types/tracking'
import CustomSelectField from '../elements/field/CustomSelectField'
import { disableScroll, enableScroll } from '../Studio/Utils'
import { monthNames } from '../util/misc'

/*
Bind this into the parent to set the objects shading overrides
See how handleSetShadingOverride is added as property of <ShadingControls /> control
*/
export const handleSetShadingOverride = function (value, uuid) {
  var object = window.editor.objectByUuid(uuid)
  window.editor.execute(new window.SetValueCommand(object, 'shadingOverride', value))
  // object.shadingOverride = value
  // dispatchSignalObjectChanged(uuid)
}

export const defaultShadingOverrideForType = {
  inherit: [],
  annual: [0.0],
  monthly: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  seasonal: [0.0, 0.0, 0.0, 0.0],
}

export const numberOfFieldsForShadingOverrideType = {
  inherit: 0,
  annual: 1,
  monthly: 12,
  seasonal: 4,
}
// @TODO Show season name based on hemisphere.
const getSeasons = (hemisphere) =>
  hemisphere === 'north' ? ['Winter', 'Spring', 'Summer', 'Fall'] : ['Summer', 'Autumn', 'Winter', 'Spring']

export const generatelabel = function (value, i, hemisphere) {
  if (value === 'inherit') {
    return ''
  }
  switch (value) {
    case 'monthly':
      return monthNames[i]
    case 'annual':
      return 'Annual'
    case 'seasonal':
      return getSeasons(hemisphere)[i]
    case 'inherit':
      return 'Auto'
    default:
      return ''
  }
}

export const shadingTypeFromShadingOverride = (shadingOverride) => {
  if (shadingOverride === null) {
    return 'annual'
  }
  switch (shadingOverride.length) {
    case 288:
      return 'inherit'
    case 12:
      return 'monthly'
    case 4:
      return 'seasonal'
    case 1:
      return 'annual'
    case 0:
    default:
      return 'inherit'
  }
}

const rawToDisplay = (raw) => {
  if (!raw && !window.Designer.typingInField) {
    return '100'
  } else if (!raw && !_.isNumber(raw)) {
    return raw
  } else if (raw < 0) {
    return '100'
  } else {
    return Math.round((1.0 - raw) * 100)
  }
}

const displayToRaw = (displayValue) => {
  if (displayValue === null) {
    return 1.0
  } else {
    return (100.0 - displayValue) / 100
  }
}

const getHemisphere = () => {
  //Hack to avoid needing to pass hemisphere around everywhere.
  //Not too dangerous because the worst that will happen is that
  // hemisphere will default to 'south'
  try {
    if (window.WorkspaceHelper.project.lat > 0) {
      return 'north'
    } else {
      return 'south'
    }
  } catch (e) {
    //
  }
  return 'south'
}

const ShadingControls = ({ setStateParent, state, handleSetShadingOverride, object, allowEdit }) => {
  const translate = useTranslate()
  const { trackEvent } = useTrackComponent({
    componentKey: 'shading_controls',
    eventName: 'User Changed Shading Method',
  })
  return (
    <div>
      <CustomSelectField
        style={{ width: 220, marginRight: 10, marginBottom: 20 }}
        label={translate('Shading Method')}
        floatingLabelFixed={true}
        value={state.shadingOverrideType}
        onChange={(event) => {
          const value = event.target.value
          trackEvent(EventType.USER_INTERACTION, { type: ActionType.SELECT }, { value })
          var newShadingOverride = defaultShadingOverrideForType[value]

          setStateParent({
            shadingOverrideType: value,
            shadingOverride: newShadingOverride,
          })
          handleSetShadingOverride(newShadingOverride, object.uuid)
        }}
        disabled={!allowEdit}
      >
        {[
          ['inherit', 'Auto'],
          ['annual', 'Annual Sun Access %'],
          ['monthly', 'By Month (SunEye) %'],
          ['seasonal', 'By Season %'],
        ].map((value, index) => (
          <MenuItem key={value[0]} value={value[0]}>
            {translate(value[1])}
          </MenuItem>
        ))}
      </CustomSelectField>

      {[
        ...Array(numberOfFieldsForShadingOverrideType[shadingTypeFromShadingOverride(state.shadingOverride)]).keys(),
      ].map((i) => (
        <div key={generatelabel(state.shadingOverrideType, i, getHemisphere())} style={{ clear: 'both' }}>
          <div style={{ float: 'left', width: 40, fontSize: 9, marginTop: 3, textTransform: 'uppercase' }}>
            {translate(generatelabel(state.shadingOverrideType, i, getHemisphere()))}
          </div>
          <TextField
            disabled={!allowEdit}
            type="number"
            style={{
              float: 'left',
              width: 45,
              height: 32,
              marginTop: -9,
              marginRight: 0,
            }}
            inputProps={{ max: '100', min: '0' }}
            name={'shadingOverride' + i}
            value={rawToDisplay(state.shadingOverride[i])}
            onFocus={function () {
              try {
                window.editor.signals.typingInField.dispatch(true)
                disableScroll()
              } catch (err) {}
            }}
            onBlur={function () {
              try {
                enableScroll()
                window.editor.signals.typingInField.dispatch(false)
                setStateParent({ shadingOverride: state.shadingOverride })
              } catch (err) {}
            }}
            onChange={(event) => {
              var value = event.target.value > 100 ? 100 : event.target.value
              var valueToSave = value === '' ? null : displayToRaw(parseFloat(value))

              var shadingOverride = object.shadingOverride.slice(0)
              shadingOverride[i] = valueToSave

              setStateParent({ shadingOverride })

              if (!isNaN(valueToSave) || valueToSave === null) {
                handleSetShadingOverride.bind(this)(shadingOverride, object.uuid)
              }
            }}
            errorText={
              state.shadingOverride[i] &&
              state.shadingOverride[i].length > 0 &&
              (isNaN(parseFloat(state.shadingOverride[i])) ||
                parseFloat(state.shadingOverride[i]) > 100.0 ||
                parseFloat(state.shadingOverride[i]) < 0)
                ? translate('Supply a shading value between 0 and 100')
                : null
            }
          />

          {object && (
            <Slider
              style={{
                float: 'left',
                width: 150,
                marginTop: -6,
                marginBottom: 0,
              }}
              sliderStyle={{ marginTop: 4, marginBottom: 0 }}
              value={rawToDisplay(state.shadingOverride[i])}
              defaultValue={rawToDisplay(state.shadingOverride[i])}
              step={1.0}
              min={0}
              max={100}
              disabled={!allowEdit}
              onChange={(event, newValue) => {
                var newShadingValue = displayToRaw(newValue)

                //Is this safe to pull from object when everything else is coming from state?
                //object.shadingOverride??
                var shadingOverride = state.shadingOverride.slice(0)
                shadingOverride[i] = newShadingValue

                setStateParent({ shadingOverride })
                handleSetShadingOverride.bind(this)(shadingOverride, object.uuid)
              }}
              onDragStart={() => setStateParent({ transparent: true })}
              onDragStop={() => setStateParent({ transparent: false })}
            />
          )}
          <div style={{ clear: 'both' }} />
        </div>
      ))}
    </div>
  )
}

export default ShadingControls
