import { Tooltip } from '@material-ui/core'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { ListControllerProps } from 'ra-core/src/controller/useListController'
import React, { ReactNode, useContext, useMemo } from 'react'
import { Edit, SimpleForm, useTranslate } from 'react-admin'
import { PrivateFileType } from 'types/privateFile'
import { ActionType, EventType } from 'types/tracking'
import { FilesContext, FilesContextProps } from '../../../common/FilesContextWrapper'
import { FieldContainer, InlineFormContainer } from '../../../common/styles/styles'
import { EditabledFieldSelectedType } from '../../ProjectFilesList'
import { EditInlineRowButtons } from './EditInlineRowButtons'

interface EditableFieldProps {
  editComponent: ReactNode
  selectComponent: ReactNode
  fieldName: string
  allowEdit: boolean
  record: PrivateFileType
  editableFieldSelected: EditabledFieldSelectedType
  setEditableFieldSelected: (selectedField: EditabledFieldSelectedType) => void
  fieldId: string
}

export const EditableField: React.FC<EditableFieldProps> = ({
  editComponent,
  selectComponent,
  fieldName,
  allowEdit = true,
  record,
  editableFieldSelected,
  setEditableFieldSelected,
  fieldId,
  ...rest
}) => {
  const { onSuccess } = useContext(FilesContext) as FilesContextProps
  const { trackEvent } = useTrackComponent({
    componentKey: 'edit_inline_field',
    eventName: 'Edited Title Of Document In Project',
  })

  const listControllerProps = rest as ListControllerProps

  const translate = useTranslate()

  const handleStartEditing = () => {
    listControllerProps.onSelect([])
    setEditableFieldSelected({ record: record, fieldName: fieldName })
  }

  const onSuccessEdit = () => {
    onSuccess()
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.EDIT })
    setEditableFieldSelected(null)
  }

  const onDismiss = () => {
    setEditableFieldSelected(null)
  }

  const showSelectField = useMemo(() => {
    const isSelectedId = editableFieldSelected?.record?.id === record.id
    const isSelectedField = editableFieldSelected?.fieldName === fieldName
    const isEditField = isSelectedId && isSelectedField
    return !isEditField
  }, [editableFieldSelected?.record?.id, editableFieldSelected?.fieldName])

  const editTooltip = useMemo(() => translate(allowEdit ? 'Click to edit' : 'Edit not allowed'), [])

  return (
    <div key={`${record.id}--${fieldName}--editInlineField`}>
      {showSelectField ? (
        <Tooltip title={editTooltip} placement={'bottom-start'}>
          <div onClick={handleStartEditing}> {selectComponent} </div>
        </Tooltip>
      ) : (
        <>
          <InlineFormContainer>
            <Edit
              //@ts-ignore
              id={record.id}
              hideTitle={true}
              hideBreadCrumbs={true}
              resource="private_files"
              location={{
                hash: '',
                pathname: '/private_files',
              }}
            >
              <SimpleForm
                redirect={false}
                toolbar={
                  <EditInlineRowButtons
                    //@ts-ignore
                    record={record}
                    onSuccessEdit={onSuccessEdit}
                    onDismiss={onDismiss}
                    fieldId={fieldId}
                  />
                }
              >
                <FieldContainer>{editComponent}</FieldContainer>
              </SimpleForm>
            </Edit>
          </InlineFormContainer>
        </>
      )}
    </div>
  )
}
