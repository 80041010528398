import { makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import Search from '@material-ui/icons/Search'
import { permissionsSelectors } from 'ducks/permissions'
import { permission } from 'elements/WithPermissionsCheck'
import inflection from 'inflection'
import {
  ArrowUpRightSquareOutlineIcon,
  BaseTheme,
  CancelCircleIcon,
  DeleteOutlineIcon,
  DownloadIcon,
  EditOutlineIcon,
  EyeIcon,
  IconButton,
  Tooltip,
} from 'opensolar-ui'
import React, { useCallback, useMemo, useState } from 'react'
import { CRUD_DELETE, Link, useDelete, useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { PermissionKey } from 'types/roles'
import ConfirmModal from './ConfirmModal'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '32px !important',
    height: '32px !important',
  },
  btn: {
    '& .MuiTypography-root': {
      width: '100% !important',
    },
    '& div, & button, & > button, & > div, & span': {
      padding: '0px !important',
      margin: '0px !important',
      marginLeft: '0px !important',
      marginRight: '0px !important',
      minWidth: 'fit-content',
    },
    minWidth: 32,
    width: 32,
    height: 32,
    minHeight: 32,
    display: 'grid',
  },
  round: {
    backgroundColor: BaseTheme.palette.secondary.main,
    borderRadius: 20,
  },
  disabled: {
    cursor: 'not-allowed !important',
    pointerEvents: 'auto',
    '& button': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto',
    },
  },
  squared: {
    '& div, & button, & > button, & > div, & span': {
      padding: '4px !important',
    },
    '& svg': {
      width: '1.5rem !important',
      height: '1.5rem !important',
    },
  },
  linkBtnSquared: {
    maxHeight: 32,
    maxWidth: 32,
    width: 32,
    height: 32,
    '& svg': {
      width: 18,
      height: 18,
      overflow: 'visible',
      paddingTop: 6,
    },
  },
}))

type BtnType = 'delete' | 'edit' | 'preview' | 'download' | 'cancel' | 'confirm' | 'search' | 'add' | 'link'

interface IconBtnWithDefaultIconsProps {
  tooltip?: string
  disabledTooltip?: string
  isDisabled?: boolean
  onClick?: any
  btnType: BtnType
  confirmTitle?: string
  confirmContent?: string
  variant?: 'squared' | 'rounded'
  to?: string
}

export const IconBtnWithDefaults: React.FC<IconBtnWithDefaultIconsProps> = ({
  tooltip = null,
  disabledTooltip,
  isDisabled = false,
  onClick,
  btnType,
  variant = 'rounded',
  to,
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  const iconColor = isDisabled
    ? BaseTheme.palette.grey[350]
    : btnType === 'delete' || btnType === 'cancel'
    ? BaseTheme.palette.error.contrastText
    : btnType === 'confirm'
    ? BaseTheme.palette.success.contrastText
    : BaseTheme.palette.primary.contrastText

  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1)
  }

  const btnTooltip = useMemo(
    () => (isDisabled && disabledTooltip ? disabledTooltip : tooltip ? tooltip : capitalize(btnType)),
    [isDisabled, disabledTooltip, disabledTooltip]
  )

  const btnStyle = isDisabled
    ? `${classes.btn} ${classes.disabled}`
    : variant === 'squared'
    ? `${classes.btn} ${classes.squared}`
    : `${classes.btn} ${classes.round}`

  const linkBtnStyle = isDisabled
    ? `${classes.btn} ${classes.disabled}`
    : variant === 'squared'
    ? `${classes.btn} ${classes.linkBtnSquared}`
    : `${classes.btn}`

  const icons = {
    delete: <DeleteOutlineIcon color={iconColor} />,
    edit: <EditOutlineIcon variant={2} color={iconColor} />,
    preview: <EyeIcon color={iconColor} />,
    download: <DownloadIcon color={iconColor} />,
    cancel: <CancelCircleIcon color={iconColor} />,
    search: <Search />,
    add: <Add />,
    link: <ArrowUpRightSquareOutlineIcon />,
  }

  const icon = useMemo(() => icons[btnType], [btnType])

  return (
    <Tooltip title={translate(btnTooltip)}>
      <div className={classes.wrapper}>
        {to ? (
          <div className={linkBtnStyle}>
            <IconButton variant={variant} onClick={onClick}>
              <Link to={to}>{icon}</Link>
            </IconButton>
          </div>
        ) : (
          <div className={btnStyle}>
            <IconButton variant={variant}>{icon}</IconButton>
          </div>
        )}
      </div>
    </Tooltip>
  )
}

interface IconBtnDeleteProps extends IconBtnWithDefaultIconsProps {
  resource: string
  record: any
  onSuccess?: () => void
}

const IconBtnDelete: React.FC<IconBtnDeleteProps> = (props) => {
  const { resource, record, onSuccess } = props
  const notify = useNotify()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const translate = useTranslate()

  const [deleteOne, { loading }] = useDelete(resource, record?.id, record, {
    action: CRUD_DELETE,
    onSuccess: (r) => {
      notify('ra.notification.deleted', 'info', { smart_count: 1 })
      if (onSuccess) onSuccess()
      setShowDialog(false)
    },
    onFailure: (error: any) => {
      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
    },
    undoable: false,
  })
  const handleDelete = useCallback(
    (e) => {
      deleteOne()
    },
    [deleteOne]
  )

  const { confirmTitle = 'ra.message.delete_title' } = props

  const translateOptions = {
    name: inflection.humanize(
      translate(`resources.${resource}.name`, {
        smart_count: 1,
        _: inflection.singularize(resource),
      }),
      true
    ),
    id: record.title || record.id,
  }

  return (
    <>
      <IconBtnWithDefaults
        tooltip={props.tooltip}
        onClick={() => setShowDialog(true)}
        btnType={'delete'}
        isDisabled={props.isDisabled}
        disabledTooltip={props.disabledTooltip}
      />
      <ConfirmModal
        open={showDialog}
        setOpen={setShowDialog}
        handleSubmit={handleDelete}
        title={translate(confirmTitle, { _: confirmTitle, ...translateOptions })}
        mainText={translate('Are you sure you want to delete this %{object}?', { object: translateOptions.name })}
        subText={translate('This action is permanent and cannot be undone.')}
        submitBtnLabel={translate('Yes, delete')}
        submitBtnProps={{ variant: 'contained', color: 'error' }}
      />
    </>
  )
}

interface IconBtnWithPermissionsProps extends IconBtnWithDefaultIconsProps {
  permissions: permission[]
  permissionToCheck: PermissionKey
  projectMode?: boolean
  resource?: any
  record?: any
  onDelete?: any
}

export const IconBtnWithPermissions: React.FC<IconBtnWithPermissionsProps> = ({
  tooltip,
  disabledTooltip,
  onClick,
  isDisabled = false,
  permissions,
  permissionToCheck,
  projectMode,
  record,
  resource,
  onDelete,
  btnType,
  confirmTitle,
  confirmContent,
  variant,
  to,
}) => {
  const accessRights = useSelector(
    projectMode
      ? permissionsSelectors.getProjectPermissionByKey(permissionToCheck)
      : permissionsSelectors.getPermissionByKey(permissionToCheck)
  )

  const hasPermissions = permissions?.every((permission) => accessRights[permission])
  const btnDisabled = isDisabled || !hasPermissions

  const btnDisabledTooltip = !hasPermissions
    ? 'No premissions found'
    : isDisabled && disabledTooltip
    ? disabledTooltip
    : tooltip

  return (
    <>
      {btnType === 'delete' ? (
        <IconBtnDelete
          tooltip={tooltip}
          onClick={onClick}
          btnType={'delete'}
          isDisabled={btnDisabled}
          disabledTooltip={btnDisabledTooltip}
          resource={resource}
          record={record}
          onSuccess={onDelete}
          confirmTitle={confirmTitle}
          confirmContent={confirmContent}
        />
      ) : (
        <>
          <IconBtnWithDefaults
            tooltip={tooltip}
            onClick={onClick}
            btnType={btnType}
            isDisabled={btnDisabled}
            disabledTooltip={btnDisabledTooltip}
            variant={variant}
            to={to}
          />
        </>
      )}
    </>
  )
}
