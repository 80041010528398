import { makeStyles, useMediaQuery } from '@material-ui/core'
import { CheckOutlined } from '@material-ui/icons'
import { COLOR_PALETTE } from 'elements/input/styles'
import { Checkbox, Chip } from 'opensolar-ui'
import React, { useCallback, useMemo } from 'react'
import { Theme } from 'types/themes'

const useStyles = makeStyles<
  Theme,
  {
    isMobile: boolean
    isPaid: boolean
    isLast: boolean
    isChecked: boolean
  }
>((theme) => ({
  milestoneWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '0px 40px 0px 0px',
    paddingRight: ({ isLast }) => (isLast ? '0px' : '40px'),
    position: 'relative',
    minWidth: '100px',
  },
  iconWrapper: {
    zIndex: 5,
  },
  milestoneTitle: {
    fontWeight: 500,
    color: ({ isPaid, isChecked }) => {
      if (isChecked) {
        return '#4272DD'
      } else if (isPaid) {
        return '#ADACB0'
      } else {
        return theme.greyDark2
      }
    },
  },
  pmtAmount: {
    fontSize: '15px',
    marginBottom: '5px',
    color: theme.greyDark2,
  },
  milestoneRow: {
    textAlign: 'left',
    padding: '3px 0px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  statusAndButtonRow: {
    display: 'flex',
  },
  invoicePaid: {
    color: theme.alertIcon_success,
  },
  invoiceSent: {
    color: '#4272DD',
    fontSize: '12px',
  },
  blueCheck: {
    height: '16px',
    width: '16px',
    color: 'white',
    backgroundColor: '#1751D0',
    borderRadius: '50px',
    padding: '11px',
    marginBottom: '6px',
  },
  blueCircle: {
    borderRadius: '50px',
    backgroundColor: '#1751D0',
    color: theme.white,
    height: '36px',
    width: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    marginTop: '-3px',
    border: '2px solid #618DF2',
    backgroundClip: 'content-box',
    padding: '2px',
    marginBottom: '5px',
  },
  blueBtn: {
    backgroundColor: '#4272DD',
    color: 'white',
  },
  timelineBar: {
    position: 'absolute',
    top: '16px',
    left: '0px',
    right: '0px',
    height: '4px',
    backgroundColor: ({ isPaid }) => {
      if (isPaid) {
        return '#4272DD'
      } else {
        return COLOR_PALETTE.lightGrey
      }
    },
    zIndex: 4,
  },
  checkbox: {
    padding: '0px 5px 0px 0px',
  },
  paidStatusChip: {
    backgroundColor: 'rgb(215, 233, 220)',
    color: 'rgb(43, 100, 46)',
  },
}))

type PropTypes = {
  onCheck: (payment_amount: number) => void
  isChecked: boolean
  isPaid: boolean
  isLast: boolean
  title: string
  amount: string
  paymentNumber: number
}

const LoanMilestoneCell: React.FC<PropTypes> = (props) => {
  const { onCheck, isChecked, isPaid, isLast, title, amount, paymentNumber } = props
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles({ isPaid, isLast, isMobile, isChecked })

  const renderIcon = useCallback(() => {
    if (isPaid) {
      return <CheckOutlined className={classes.blueCheck} />
    } else {
      return <div className={classes.blueCircle}>{paymentNumber}</div>
    }
  }, [isPaid, paymentNumber])

  const showTimelineBar = useMemo(() => {
    return !isLast
  }, [isLast])

  const toggleCheckbox = () => {
    onCheck(paymentNumber)
  }

  return (
    <div className={classes.milestoneWrapper}>
      <div className={classes.iconWrapper}>{renderIcon()}</div>

      <div className={classes.milestoneRow}>
        {!isPaid && <Checkbox className={classes.checkbox} checked={isChecked} onChange={(e) => toggleCheckbox()} />}
        <span className={classes.milestoneTitle}>{title}</span>
      </div>

      <div className={classes.milestoneRow}>
        <div className={classes.pmtAmount}>{amount}</div>
      </div>
      <div>{isPaid && <Chip label="Paid" variant="default" className={classes.paidStatusChip} size="small" />}</div>
      {showTimelineBar && <div className={classes.timelineBar} />}
    </div>
  )
}

export default LoanMilestoneCell
