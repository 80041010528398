import { MenuItem } from '@material-ui/core'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import CustomSelectField from 'elements/field/CustomSelectField'
import { DistanceNumberField } from 'elements/field/DistanceNumberField'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { ActionType, EventType } from 'types/tracking'
import Panel, { StudioPanelContext } from '../Designer/Panel'
import { refreshPanelGeneric, stripKeyForActiveTextField } from '../Studio/Utils'

import { feetToMeters, getMeasurementsFromState, metersToFeet, trimDecimalPlaces } from '../util/misc'

const DIMENSION_LABELS = {
  x: 'Length',
  y: 'Width',
  z: 'Height',
}

class PanelTree extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      tree: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.tree) {
      injectState = this.stateFromObject(props.tree)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  stateFromObject(tree) {
    if (!tree || tree.type !== 'OsTree') {
      return { visible: false }
    }
    var rotationZPositive = (-tree.rotation.z * window.THREE.Math.RAD2DEG + 360.0) % 360

    var rotationZRoundedPositive = Math.round(rotationZPositive * 100) / 100

    var _state = {
      visible: true,
      tree: tree,
      ...this.getDimensions(tree),
      rotationZ: rotationZRoundedPositive,
    }

    stripKeyForActiveTextField.call(this, _state, tree)
    return _state
  }

  refreshPanel() {
    if (this.props.tree) {
      this.setState(this.stateFromObject(this.props.tree))
    }
    refreshPanelGeneric.call(this, 'OsTree')
  }

  format = (value) => {
    const { measurements } = this.props
    if (measurements === 'imperial') {
      return trimDecimalPlaces(metersToFeet(value), 4)
    }
    return Math.round(value * 1000) / 1000
  }

  parse = (value) => {
    const { measurements } = this.props
    if (measurements === 'imperial') {
      return trimDecimalPlaces(feetToMeters(value), 6)
    }
    return value
  }

  disableRotation() {
    const { tree } = this.state
    let disabled = false
    if (tree && tree.rotation) {
      const x = tree.rotation.x
      const y = tree.rotation.y
      if (Math.abs(x, 0.1) > 0.1 || Math.abs(y, 0.1) > 0.1) {
        return true
      }
    }
    return disabled
  }

  getComputeSizeBeforeScale(object) {
    return window.Utils.getBoundingBoxLocal(object).getSize(new window.THREE.Vector3())
  }

  getComputeSize(object) {
    return this.getComputeSizeBeforeScale(object).multiply(object.scale)
  }

  getDimensions(tree) {
    const result = {
      width: 0,
      height: 0,
      depth: 0,
    }
    if (tree) {
      const size = this.getComputeSize(tree)
      Object.keys(DIMENSION_LABELS).forEach((dimension) => {
        result[DIMENSION_LABELS[dimension]] = this.format(size[dimension])
      })
    }
    return result
  }

  applyScaleValue(tree, scaleAxis, value) {
    const sizeBeforeScale = this.getComputeSizeBeforeScale(tree)
    const scaleValue = value / sizeBeforeScale[scaleAxis]
    const newScale = tree.scale.clone()
    Object.assign(newScale, { [scaleAxis]: scaleValue })
    const { trackEvent } = this.props
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.EDIT }, { source: 'Scale Tree' })
    window.editor.execute(new window.SetScaleCommand(tree, newScale))
  }

  applyRotationZ(tree, value) {
    value = -value / window.THREE.Math.RAD2DEG
    const rotation = tree.rotation.clone()
    rotation.z = value
    const { trackEvent } = this.props
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.EDIT }, { source: 'Rotation Z' })
    window.editor.execute(new window.SetRotationCommand(tree, rotation))
  }

  render() {
    if (this.state.visible !== true) {
      return null
    } else if (this.state.tree && this.state.tree.ghostMode()) {
      const { translate } = this.props
      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.tree}
          title={translate('Placing Tree')}
          summary={null}
          content={<p style={{ marginTop: 0 }}>{translate('Click anywhere on the imagery to drop the tree.')}</p>}
        />
      )
    } else {
      const { tree, rotationZ } = this.state
      const { translate } = this.props
      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.tree}
          title={translate('Tree')}
          summary={null}
          content={null}
          feature={
            <StudioPanelContext.Provider value={{ context: this, object: tree }}>
              <div>
                {Object.keys(DIMENSION_LABELS).map((scaleAxis) => {
                  const key = DIMENSION_LABELS[scaleAxis]
                  const value = this.state[key]
                  return (
                    <DistanceNumberField
                      disabled={!this.state.allowEdit}
                      name={`tree-${key}`}
                      label={key}
                      value={value}
                      maxDecimalPlaces={3}
                      minValue={0.001}
                      minErrorMsg={translate('Supply a value greater than 0.')}
                      measurementStd={this.props.measurements}
                      convertTo="metric"
                      onChange={(newValue) => {
                        this.applyScaleValue(tree, scaleAxis, newValue)
                      }}
                    />
                  )
                })}

                <CustomNumberField
                  disabled={!this.state.allowEdit || this.disableRotation()}
                  name="tree-rotation"
                  label="Rotation"
                  value={rotationZ}
                  minValue={0}
                  maxValue={360}
                  maxDecimalPlaces={2}
                  resettable={true}
                  resetValue={0}
                  endAdornment="°"
                  onChange={(newValue) => {
                    this.applyRotationZ(tree, newValue)
                  }}
                />

                <CustomSelectField
                  style={{ width: 230, marginTop: 20 }}
                  label={translate('Show Customer')}
                  value={tree.override_show_customer}
                  defaultValue={null}
                  displayEmpty={true}
                  disabled={!this.state.allowEdit}
                  onChange={(event) =>
                    window.editor.execute(
                      new window.SetValueCommand(
                        tree,
                        'override_show_customer',
                        event.target.value,
                        window.Utils.generateCommandUUIDOrUseGlobal()
                      )
                    )
                  }
                >
                  <MenuItem value={null}>{translate('Default')}</MenuItem>
                  <MenuItem value={true}>{translate('Show')}</MenuItem>
                  <MenuItem value={false}>{translate('Hide')}</MenuItem>
                </CustomSelectField>
              </div>
            </StudioPanelContext.Provider>
          }
        />
      )
    }
  }
}

const PanelTreeWrapper = (props) => {
  const { trackEvent } = useTrackComponent({
    componentKey: 'panel_tree',
    eventName: 'User Interacted With Panel Tree',
  })

  return <PanelTree {...props} trackEvent={trackEvent} />
}

PanelTree.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {}
  )
)(PanelTreeWrapper)
