import StarBorderIcon from '@material-ui/icons/StarBorderOutlined'
import StarIcon from '@material-ui/icons/StarOutlineOutlined'
import { starAdd as starAddAction, starRemove as starRemoveAction } from 'ducks/stars'
import { IconButton } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

class StarButton extends Component {
  handleStarAdd = () => {
    const { starAdd, record } = this.props
    starAdd(record.id)
  }

  handleStarRemove = () => {
    const { starRemove, record } = this.props
    starRemove(record.id)
  }

  render() {
    const { record } = this.props

    if (this.props.hasStarUseOverrideIfSet(record) === false) {
      return (
        <IconButton onClick={this.handleStarAdd} style={Object.assign({ minWidth: '', width: 40 }, this.props.style)}>
          {<StarBorderIcon style={{ color: '#D8D8D8' }} />}
        </IconButton>
      )
    } else {
      return (
        <IconButton
          label={null}
          onClick={this.handleStarRemove}
          style={Object.assign({ minWidth: '', width: 40 }, this.props.style)}
        >
          {<StarIcon style={{ color: 'rgba(0, 148, 255, 1)' }} />}
        </IconButton>
      )
    }
  }
}

StarButton.propTypes = {
  record: PropTypes.object,
  starAdd: PropTypes.func,
  starRemove: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    hasStarUseOverrideIfSet: (record) => {
      if (!state.stars) {
        return false
      } else if (state.stars.hasOwnProperty(record.id)) {
        return state.stars[record.id]
      } else {
        return record.stars && record.stars.length > 0
      }
    },
  }
}

const enhance = compose(
  connect(mapStateToProps, {
    starAdd: starAddAction,
    starRemove: starRemoveAction,
  })
)

export default enhance(StarButton)
