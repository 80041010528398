import { Grid } from '@material-ui/core'
import { SwapHoriz } from '@material-ui/icons'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import { StatusPerDistributorType } from 'elements/hardwareSelectorV2/elements/QuantityBlock'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import { styled, Typography } from 'opensolar-ui'
import { STOCK_LEVEL_STATUS } from 'pages/ordering/constants'
import LowStockWarning from 'pages/ordering/order/table/quantity/LowStockWarning'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { useDispatch } from 'react-redux'
import { ComponentTypesV2 } from 'types/selectComponent'

type AlternativePickerProps = WithHardwareSelectorV2Props & {
  component: LineItemType
  stockStatus: StatusPerDistributorType | undefined
}

const Wrapper = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 8,
})

const Box = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const ButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 6,
  border: `1px solid #E6E6E6`,
  padding: '0px 8px',
  color: '#4F4D55',
  cursor: 'pointer',
})

const AlternativePicker = ({
  component,
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
  stockStatus,
}: AlternativePickerProps) => {
  const dispatch = useDispatch()
  const chooseAlternative =
    stockStatus?.status === STOCK_LEVEL_STATUS.OUT_OF_STOCK || stockStatus?.status === STOCK_LEVEL_STATUS.NOT_AVAILABLE

  const handleOpenSelector = () => {
    if (enableHardwareDialogV2) {
      handleOpenHardwareSelector({
        componentTypes: [component?.componentTypeV2 || (component?.componentType as ComponentTypesV2)],
        title: 'Select Component',
      })
    } else {
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper.project.org_id,
          window.WorkspaceHelper.project.id,
          [component?.uuid],
          component?.componentType
        )
      )
    }
  }

  return (
    <>
      <Grid xs={3}></Grid>
      <Wrapper xs={9} item container direction="row" alignItems="center" style={{ padding: 0 }}>
        {component.selectedDistributor === 'segen' && stockStatus?.status === STOCK_LEVEL_STATUS.AVAILABLE ? (
          <Typography textVariant="helper1" colorHex="#113B98">
            {stockStatus?.infoText}
          </Typography>
        ) : (
          <Box>
            <LowStockWarning lineItem={component} />
            {chooseAlternative && (
              <ButtonContainer onClick={handleOpenSelector}>
                <SwapHoriz style={{ marginRight: 8 }} />
                <Typography variant="subtitle2">{`Choose Alternative`}</Typography>
              </ButtonContainer>
            )}
          </Box>
        )}
      </Wrapper>
    </>
  )
}

export default withHardwareSelectorV2(AlternativePicker)
