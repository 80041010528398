export const LENDER_STATUS_CHOICES = [
  { id: 'pending', label: 'Pending' },
  { id: 'active_staff_only', label: 'Active - Staff Only' },
  { id: 'active_public', label: 'Active - Public' },
  { id: 'disabled', label: 'Disabled' },
]

export const ACTIVATION_TYPE_CHOICES = [
  {
    id: 'api_credentials',
    label: 'API Credentials',
  },
  {
    id: 'simple',
    label: 'Simple (No credentials, staff will enable)',
  },
  {
    id: 'immediately_available',
    label: 'Immediately Available (No credentials, pro can enable)',
  },
  {
    id: 'no_payment_options',
    label: 'No Payment Options - Promo and CTA only',
  },
  {
    id: 'oauth2',
    label: 'Oauth2',
  },
]

// this is only used to ensure that we have logos for every lender in the brief period after deployment where all hardcoded promos have been removed but
// the logos haven't been uploaded to the FinancePartner records yet
export const LENDER_LOGO_PATHS = {
  sunlight: {
    small: 'logo-sunlight-financial-small.png',
    large: 'logo-sunlight-financial.png',
  },
  mosaic: {
    small: 'Mosaic-Logo-Small.png',
    large: 'Mosaic-Logo.png',
  },
  sungage: {
    small: 'sungage-logo-small.png',
    large: 'sungage-logo-wide.png',
  },
  dividend: {
    small: 'dividend-logo-small.jpeg',
    large: 'dividend-logo-wide.svg',
  },
  loanpal: {
    small: 'loanpal_logo.svg',
    large: 'loanpal_logo.svg',
  },
  plenti: {
    small: 'plenti-logo.svg',
    large: 'plenti-logo.svg',
  },
  brighte: {
    small: 'brighte_logo.png',
    large: 'brighte_logo.png',
  },
  phoenix: {
    small: 'phoenix-logo-small.png',
    large: 'phoenix-logo.png',
  },
  energy_ease: {
    small: 'smart-ease-logo.png',
    large: 'smart-ease-logo.png',
  },
  checkout_finance: {
    small: 'opensolar-checkout-financing-logo.png',
    large: 'opensolar-checkout-financing-logo.png',
  },
}
