import { CircularProgress, DialogActions, DialogContent, TextField } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { Button, Chip, Typography, styled } from 'opensolar-ui'
import { InvoiceContactDetails } from 'pages/cashFlow/invoices/standaloneInvoices/types'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { Field, Form, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import InvoiceEmailCustomization from '../InvoiceEmailCustomization'
import InvoiceRecipientField from './InvoiceRecipientField'
import { InvoiceEmail, SimpleInvoiceType } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

interface BasePropTypes {
  sendInvoice: (emailConfiguration: InvoiceEmail) => Promise<void>
  previousPage: () => void
  invoice: SimpleInvoiceType | undefined
  isLoading: boolean
  isNoProjectInvoice?: boolean
  contactDetails?: InvoiceContactDetails
}

interface ContentPropTypes extends BasePropTypes {
  project: ProjectType
}

const HeaderWrapper = styled('div')({
  margin: '11px 0',
})

const HelpTextWrapper = styled('div')({
  marginTop: '10px',
  marginBottom: '20px',
})

const ToWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '15px',
  gap: '6px',
})

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '10px',
})

const BtnWrapper = styled('div')({
  padding: '0px 10px',
})

const ActionsRow = styled('div')(({ theme }) => ({
  padding: '10px',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.grey[300],
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}))

const ReviewAndSendCustomInvoiceContents: React.FC<ContentPropTypes> = (props) => {
  const translate = useTranslate()
  const formState = useFormState()

  return (
    <>
      <DialogContent>
        <div>
          <HeaderWrapper>
            <Typography textVariant="subtitle1">{translate('Review and Send Invoice Email')}</Typography>
          </HeaderWrapper>
          <Typography textVariant="body1">
            {translate('Make any final edits and send invoice email to customer(s)')}
          </Typography>
        </div>
        <ContentWrapper>
          <div>
            <ToWrapper>
              <div>{translate('To:')}</div>
              {formState.values?.to?.map((email) => (
                <Chip key={email} label={email} />
              ))}
            </ToWrapper>
            {!props.isNoProjectInvoice && (
              <>
                <HelpTextWrapper>
                  <Typography textVariant="helper1" colorHex="#757575">
                    {translate(
                      'Change customer contact on the project or in the edit invoice details workflow to update'
                    )}
                  </Typography>
                </HelpTextWrapper>

                <InvoiceRecipientField project={props.project} />

                <HelpTextWrapper>
                  <Typography textVariant="helper1" colorHex="#757575">
                    {translate("All contacts except for the customer are bcc'ed on customer emails")}
                  </Typography>
                </HelpTextWrapper>
              </>
            )}
            <div>
              <Field name={`subject`}>
                {(fieldProps) => (
                  <div>
                    <TextField
                      name={fieldProps.input.name}
                      label="Subject"
                      value={fieldProps.input.value}
                      onChange={fieldProps.input.onChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder=""
                    />
                  </div>
                )}
              </Field>
            </div>
            <div>
              <InvoiceEmailCustomization
                label="Message"
                fieldName="message"
                initialValue={formState?.values?.message}
                hideToolbar={true}
              />
            </div>
          </div>
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        <ActionsRow>
          <BtnWrapper>
            <Button onClick={props.previousPage} disabled={props.isLoading}>
              {translate('Back')}
            </Button>
          </BtnWrapper>
          <BtnWrapper>
            <Button
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={() =>
                props.sendInvoice({
                  email_subject: formState.values.subject,
                  email_body: formState.values.message,
                  to_addresses: formState.values.to,
                  bcc_addresses: formState.values.bcc,
                })
              }
            >
              {props.isLoading ? <CircularProgress /> : <span>{translate('Send Invoice')}</span>}
            </Button>
          </BtnWrapper>
        </ActionsRow>
      </DialogActions>
    </>
  )
}

const ReviewAndSendCustomInvoice: React.FC<BasePropTypes> = (props) => {
  const org = useSelector(orgSelectors.getOrg)
  const project = useFormState().values as ProjectType
  const [subjectTemplate, setSubjectTemplate] = useState<string>('')
  const [bodyTemplate, setBodyTemplate] = useState<string>('')

  useEffect(() => {
    if (props?.invoice?.id) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${org?.id}/invoices/${props?.invoice?.id}/preview_email/?template=issue_invoice`,
      })
        .then((res) => {
          setSubjectTemplate(res?.data?.subject)
          setBodyTemplate(res?.data?.body)
        })
        .catch((error) => console.error(error.message))
    }
  }, [])

  const initialToValue = useMemo(() => {
    if (props.isNoProjectInvoice) {
      return [props.contactDetails?.recipient_email]
    } else {
      return project?.contacts_data
        ?.filter((contact) => !!contact.email && !contact.email.includes('@os.code'))
        ?.map((con) => con.email)
    }
  }, [props.isNoProjectInvoice, props.contactDetails, project?.contacts_data])

  return (
    <Form onSubmit={() => {}} initialValues={{ to: initialToValue, subject: subjectTemplate, message: bodyTemplate }}>
      {({ handleSubmit }) => <ReviewAndSendCustomInvoiceContents {...props} project={project} />}
    </Form>
  )
}
export default ReviewAndSendCustomInvoice
