import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import useTranslateParse from 'util/useTranslateParse'
import { FormRouteInfoPropTypes } from '../types'

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: 10,
  marginBottom: 16,
})

const VerifyBusinessTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  return (
    <div data-testid="bluesnap-verify-business-top">
      <Container>
        <Typography textVariant="h5SemiBold">{translate("Let's verify your business")}</Typography>
        <Typography textVariant="body1">
          {translate('We use this information to confirm your business identity.')} <br />
          {translateParse(
            'Please enter your information <strong> exactly <strong> as it appears on your official tax documents.',
            {
              strong: (label: string) => <strong>{label}</strong>,
            }
          )}
        </Typography>
      </Container>
    </div>
  )
}
export default VerifyBusinessTop
