import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { orgSelectors } from 'ducks/orgs'
import ComponentTypeImg from 'elements/field/ComponentTypeImag'
import { isEmpty } from 'lodash'
import { Grid } from 'opensolar-ui'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Theme } from 'types/themes'
import { formatCurrency, mapComponentTypesV2ToV1 } from 'util/misc'
import { useTranslate } from 'ra-core'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'
import { majorComponentCategories } from '../../../configs'

const COMPONENT_TYPE_NAME_MAPPING = {
  module: 'Panels',
  inverter: 'Inverters',
  battery: 'Batteries',
  other: 'Others',
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  imageWrapper: {
    height: '40px',
    width: '40px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 14,
    fontWeight: 475,
  },
  label: {
    fontSize: 12,
    color: COLOR_PALETTE.grey2,
  },
  label1: {
    fontSize: 12,
    color: COLOR_PALETTE.grey2,
    fontWeight: 475,
  },
  midDot: {
    fontSize: 18,
    fontWeight: 'bold',
    color: COLOR_PALETTE.grey2,
  },
  priceWrapper: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  price: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 4,
    color: COLOR_PALETTE.grey2,
  },
  boldTitle: {
    fontSize: 14,
    fontWeight: 550,
  },
  accordion: {
    border: 'none !important',
    borderRadius: 0,
    margin: '0 !important',
    background: COLOR_PALETTE.grey3,
  },
}))

const PartsListComponent = ({ lineItem }: { lineItem: OrderLineItem }) => {
  const lineItemObj = new OrderLineItem(lineItem)
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const { beforeDiscount, afterDiscount, discount } = lineItemObj.getBestDiscountOffer()
  const hasDiscount = beforeDiscount !== afterDiscount

  const imageUrl = useMemo(() => {
    switch (lineItemObj.componentType) {
      case 'module':
        return window.PUBLIC_URL + '/images/panels.svg'
      case 'inverter':
        return window.PUBLIC_URL + '/images/inverters.svg'
      case 'battery':
        return window.PUBLIC_URL + '/images/batteries.svg'
      case 'other':
        return window.PUBLIC_URL + '/images/other_components.svg'
      default:
        return 'componentImage'
    }
  }, [lineItemObj.componentType])

  return (
    <Grid container>
      <Grid item xs={12} className={sectionStyles.row}>
        <Grid container>
          <Grid item xs={9} className={sectionStyles.row} style={{ alignItems: 'center' }}>
            <div className={`${sectionStyles.column} ${classes.imageWrapper}`}>
              {isEmpty(lineItemObj?.data?.image_url) && (
                <ComponentTypeImg componentType={lineItem.componentType} size="medium" />
              )}
              {!isEmpty(lineItemObj?.data?.image_url) && (
                <img src={lineItemObj?.data?.image_url} alt={imageUrl} style={{ width: 25, height: 35 }} />
              )}
            </div>

            <div className={sectionStyles.column}>
              <span className={classes.title}>{lineItem.data?.short_description}</span>
              <div className={sectionStyles.row} style={{ gap: '8px' }}>
                <span className={classes.label1}>{lineItem.data?.code}</span>
                <span className={classes.midDot}>&middot;</span>
                <span className={classes.label}>{`Qty: ${lineItem.quantity}`}</span>
                <span className={classes.midDot}>&middot;</span>
                <span className={classes.label}>{`Unit Price: ${currencySymbol}${formatCurrency(
                  lineItem.pricePerUnit
                )}`}</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={3} className={`${sectionStyles.column} ${classes.priceWrapper}`}>
            <span
              className={classes.title}
              style={{ color: hasDiscount ? COLOR_PALETTE.green : COLOR_PALETTE.black }}
            >{`£${formatCurrency(afterDiscount)}`}</span>
            {hasDiscount && (
              <span className={`${sectionStyles.column} ${classes.price}`}>
                {`Was ${currencySymbol}${formatCurrency(beforeDiscount)}`}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const PartsList = ({ data }: { data: any }) => {
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const translate = useTranslate()
  const partslistHashMap: { [key: string]: OrderLineItem[] } = {
    module: [],
    inverter: [],
    battery: [],
    other: [],
  }

  data?.items?.forEach((item: OrderLineItem) => {
    if (item.componentType) {
      const isMajorComponent = majorComponentCategories.some(categoryType => categoryType === item?.componentType)
      const componentTypeMapping = isMajorComponent ? item?.componentType : 'other'
      partslistHashMap[componentTypeMapping].push(item)
    }
  })

  return (
    <Accordion defaultExpanded className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <span className={classes.boldTitle}>{`${translate('Parts List')} (${data?.totalItems})`}</span>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container style={{ gap: '16px' }}>
          {Object.keys(partslistHashMap).map((key) => {
            if (!isEmpty(partslistHashMap[key])) {
              return (
                <Grid item xs={12} className={sectionStyles.column} style={{ gap: '16px' }} key={key}>
                  <span style={{ fontSize: 14, fontWeight: 550 }}>{COMPONENT_TYPE_NAME_MAPPING[key]}</span>
                  {partslistHashMap[key].map((item: OrderLineItem) => (
                    <PartsListComponent lineItem={item} key={item.code} />
                  ))}
                </Grid>
              )
            }
            return null
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default memo(PartsList)
