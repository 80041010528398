import { orgSelectors } from 'ducks/orgs'
import { projectListViewSelectors, saveProjectView, ViewType } from 'ducks/projectListViews'
import { useNotify } from 'ra-core'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import { generateUUID } from 'util/misc'
import { ProjectFiltersType } from './filtering/type'
import ProjectsSkeleton from './ProjectsSkeleton'
import ProjectsTable from './ProjectsTable'
import SearchToolbar from './SearchToolbar'
import { useProjectColumns } from './useProjectColumns'
import { getViewCount, mapFilterArrayToFilterObject } from './utils'
import { ListColumnBase } from './views/utils'
import ViewToolbar from './ViewToolbar'

interface PropTypes {
  viewId: string
}

const ViewPortal: FC<PropTypes> = ({ viewId }) => {
  const notify = useNotify()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isTableView, setTableView] = useState(true)
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const views = useSelector(projectListViewSelectors.getProjectListViewData)
  const viewSelected = useMemo(() => views.find((x) => x.id.toString() === viewId), [viewId, views])
  const defaultView = useSelector(projectListViewSelectors.getDefaultViewId)?.toString()

  const [savedColData, setColData] = useState<undefined | ListColumnBase[]>(appStorage.getJSON('projects_columns'))
  const { columns } = useProjectColumns(savedColData)

  const handleViewChange = (viewId) => {
    const view = views?.find((x) => x.id === viewId)
    if (view) {
      history.push('/projects/view/' + view.id)
      setTableView(view.view_type === ViewType.Table)
    }
  }
  const handleAddView = (viewCount = 0) => {
    const newId = generateUUID()
    let newView = {
      ...viewSelected,
      name: 'New View',
      order: (Math.max(...views.map((x) => x.order)) || 0) + 1,
      id: newId,
      not_saved: true,
      view_count: viewCount,
    }
    dispatch(saveProjectView(newView))
    history.push('/projects/view/' + newId)
  }

  //reset selected view type when view is changed
  useEffect(() => {
    if (viewSelected) {
      setTableView(viewSelected.view_type === ViewType.Table)
    }
  }, [viewSelected])

  const defaultFilters: ProjectFiltersType = useMemo(
    () => (viewSelected ? mapFilterArrayToFilterObject(viewSelected?.view_settings?.filters) : {}),
    [viewSelected]
  )
  const [filterValues, setFilters] = useState<ProjectFiltersType>(defaultFilters)

  //reset selected view filters when view is changed
  useEffect(() => {
    setFilters(defaultFilters)
  }, [defaultFilters])

  //populate view_count cache where necessary
  useEffect(() => {
    const viewForQuery = views.find((x) => x.view_count === undefined)
    if (viewForQuery) getViewCount(viewForQuery, dispatch, notify)
  }, [views])

  const updateTableView = (value) => {
    if (value === false && !filterValues.workflow_id && orgWorkflows?.length) {
      const defaultWorkflow = orgWorkflows.find((x) => !x.is_archived && x.is_default)
      if (defaultWorkflow) {
        setFilters({ ...filterValues, workflow_id: defaultWorkflow.id?.toString() })
      }
    }
    setTableView(value)
  }

  if (!viewSelected && defaultView && viewId !== defaultView) return <Redirect to={'/projects/view/' + defaultView} />
  if (!viewSelected) return <ProjectsSkeleton />
  return (
    <ProjectsTable
      savedColData={savedColData}
      filterValues={filterValues}
      isTableView={isTableView}
      viewSelected={viewSelected || views[0]}
      toolbars={
        <>
          <ViewToolbar viewSelected={viewSelected} handleViewChange={handleViewChange} handleAddView={handleAddView} />
          <SearchToolbar
            viewSelected={viewSelected}
            filterValues={filterValues}
            setFilters={setFilters}
            viewSettings={{
              isTableView,
              setTableView: updateTableView,
              columns,
              setColData,
            }}
          />
        </>
      }
    />
  )
}

export default ViewPortal
