var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { flip, size } from '@floating-ui/react-dom';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import { MenuItem } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { Button, ButtonGroup } from 'opensolar-ui';
import * as React from 'react';
import { useEffect } from 'react';
import { ArrowDropDownIcon } from '../icons';
export var SplitButton = function (_a) {
    var options = _a.options, _b = _a.selectedIndex, selectedIndex = _b === void 0 ? 0 : _b, optionKey = _a.optionKey, optionDisabled = _a.optionDisabled, onClick = _a.onClick, showSelected = _a.showSelected, updateSelected = _a.updateSelected, dontClose = _a.dontClose, size = _a.size, variant = _a.variant, color = _a.color, disabled = _a.disabled;
    var _c = React.useState(false), open = _c[0], setOpen = _c[1];
    var anchorRef = React.useRef(null);
    var _d = React.useState(selectedIndex), realSelected = _d[0], setRealSelected = _d[1];
    var buttonProps = {
        disabled: disabled,
        size: size,
        variant: !variant ? 'contained' : variant,
        color: color === 'default' ? 'secondary' : color,
    };
    var handleClickMain = function () {
        if (onClick)
            onClick(options[selectedIndex], selectedIndex);
    };
    useEffect(function () { return setRealSelected(selectedIndex); }, [selectedIndex]);
    var handleMenuItemClick = function (event, index) {
        if (updateSelected)
            setRealSelected(index);
        if (onClick)
            onClick(options[index], index);
        if (!dontClose)
            setOpen(false);
    };
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClose = function (event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var renderOption = function (index, isButton) {
        var item = options[index];
        if (item && typeof item === 'object' && item['label'] && item['value'] !== undefined) {
            if (isButton)
                return (_jsx(Button, __assign({}, buttonProps, { onClick: handleClickMain, children: item['label'] })));
            else {
                var key = optionKey ? item[optionKey] : index;
                return (_jsx(MenuItem, { disabled: optionDisabled ? item[optionDisabled] : false, selected: index === realSelected, onClick: function (event) { return handleMenuItemClick(event, index); }, children: item['label'] }, key));
            }
        }
        else {
        }
    };
    var id = open ? 'simple-popper' : undefined;
    return (_jsxs(_Fragment, { children: [_jsxs(ButtonGroup, __assign({}, buttonProps, { ref: anchorRef, "aria-label": "Button group with a nested menu", children: [renderOption(realSelected, true), _jsx(Button, __assign({}, buttonProps, { "aria-describedby": id, "aria-controls": open ? 'split-button-menu' : undefined, "aria-expanded": open ? 'true' : undefined, "aria-label": "select merge strategy", "aria-haspopup": "menu", onClick: handleToggle, children: _jsx(ArrowDropDownIcon, {}) }))] })), _jsx(Popup, { id: id, anchor: anchorRef.current, open: open, middleware: popupMiddleware, children: _jsx(Paper, { children: _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(MenuList, { id: "split-button-menu", children: options.map(function (option, index) {
                                if (!showSelected && index === realSelected)
                                    return null;
                                return renderOption(index, false);
                            }) }) }) }) })] }));
};
var popupMiddleware = [
    // Sets the width of the dropdown to the width of the button
    size({
        apply: function (_a) {
            var rects = _a.rects, elements = _a.elements;
            Object.assign(elements.floating.style, {
                width: "".concat(rects.reference.width, "px"),
            });
        },
    }),
    // Flips the dropdown from the bottom to the top if there is not enough space
    flip(),
];
