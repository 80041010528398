import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import Tooltip from 'elements/tooltip/Tooltip'
import { Button, styled } from 'opensolar-ui'
import { useDispatch, useSelector } from 'react-redux'
import { doNotTranslate } from 'util/misc'
import SolarAppPreviewStepper from './SolarAppPreviewStepper'

const ButtonWrapper = styled('div')({
  width: '100%',
  marginTop: 12,
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'center',
})

const SolarAppCTA = () => {
  const dispatch = useDispatch()
  const orgId = useSelector(authSelectors.getOrgId)
  const { allowView: canViewAPIKeysPage } = useSelector(
    permissionsSelectors.getPermissionByKey('integrations_and_api_keys')
  )

  return (
    <>
      <SolarAppPreviewStepper />
      <ButtonWrapper>
        <Tooltip
          title={
            canViewAPIKeysPage
              ? doNotTranslate(
                  'You will need to set up SolarAPP+ in Control > Other > Integrations & API Keys before creating a permit pack.'
                )
              : doNotTranslate('You will need to set up SolarAPP+ before creating a permit pack.')
          }
        >
          <Button
            onClick={
              canViewAPIKeysPage
                ? () => {
                    const redirect = `/external_api_keys/${orgId}`
                    dispatch(
                      setStudioSavePrompt({
                        show_prompt: true,
                        prompt_heading: doNotTranslate('Leave Project to Integrations & API Keys page?'),
                        redirect,
                      })
                    )
                  }
                : undefined
            }
            disabled={!canViewAPIKeysPage}
            variant="contained"
          >
            {doNotTranslate('Create SolarAPP+ Permit')}
          </Button>
        </Tooltip>
      </ButtonWrapper>
    </>
  )
}

export default SolarAppCTA
