import { Grid } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { triggerSDKTokenRefresh } from 'ducks/projectMilestones'
import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { SelectInput, TextInput } from 'ra-ui-materialui'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import SignaturePad from 'react-signature-pad'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import BlueSnapHostedField from '../commonComponents/BlueSnapHostedField'
import { initBlueSnapSecurePayments } from '../creditCards/utils'
import { ACHPaymentDataType, PaymentRequestType } from '../types'
import { isValidUSRoutingNumber, useGetBlueSnapToken } from '../utils'

type PropTypes = {
  projectId?: string
  paymentRequestData: PaymentRequestType
  doSubmitPayment: (args?: ACHPaymentDataType) => void
  countryIso2: string
  orgName: string
  revocationText: string
  esignatureText: string
  cancelEdit: () => void
  setPaymentInfoEntered: (boolean) => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {},
  nextBtn: {
    background: '#4272DD',
    color: theme.white,
    fontSize: 13,
    border: '1px solid #4272DD',
    float: 'right',
    margin: '10px 0',

    '&:hover': {
      background: '#4272DD !important',
      color: theme.white,
      fontSize: 13,
      border: '1px solid #4272DD',
    },
  },
  input: {
    backgroundColor: '#fff',
  },
  cancelBtn: {
    fontSize: 13,
    margin: '10px 0',
  },
}))

const ACHForm: React.FC<PropTypes> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [paymentDetailsAreValidated, setPaymentDetailsAreValidated] = useState<boolean>(false)
  const [shouldTriggerTokenRefresh, setShouldTriggerTokenRefresh] = useState<boolean>(false)

  const classes = useStyles()
  const translate = useTranslate()
  const currencySymbol = currencySymbolForCountry(props.countryIso2)
  const formState = useFormState()
  const form = useForm()
  const dispatch = useDispatch()
  const signatureRef = useRef(null)

  const { token } = useGetBlueSnapToken(
    props.projectId || '',
    props.paymentRequestData.payment_request_id,
    props.paymentRequestData?.org_id
  )

  const onFieldValidationChange = useCallback(
    (errors: any[], warnings: any[]) => {
      if (errors?.length) {
        if (errors[0] === 'tokenAlreadyUsed') {
          setShouldTriggerTokenRefresh(true)
        } else {
          setErrorMessage(errors[0])
        }
      }
    },
    [formState.values]
  )

  const requireCompanyName = useMemo(() => {
    return (
      formState?.values?.ecpAccountType === 'CORPORATE_CHECKING' ||
      formState?.values?.ecpAccountType === 'CORPORATE_SAVINGS'
    )
  }, [formState?.values?.ecpAccountType])

  useEffect(() => {
    if (shouldTriggerTokenRefresh) {
      dispatch(triggerSDKTokenRefresh())
    }
  }, [shouldTriggerTokenRefresh])

  useEffect(() => {
    if (paymentDetailsAreValidated && token) {
      props.doSubmitPayment({
        first_name: formState.values.first_name,
        last_name: formState.values.last_name,
        signature_data: formState.values.signature_data,
        company_name: requireCompanyName ? formState.values.company_name : undefined,
        authorizedByShopper: formState.values.authorizedByShopper,
        token,
      })
    }
  }, [paymentDetailsAreValidated, token, requireCompanyName])

  useEffect(() => {
    if (errorMessage) {
      logAmplitudeEvent('cashflow_payment_method_error', {
        payment_method: 'ach',
        project_id: props.projectId,
        payment_reuest_id: props.paymentRequestData.payment_request_id,
        error_message: errorMessage,
      })
    }
  }, [errorMessage])

  const onPaymentReady = (sdkResponse: any) => {
    if (sdkResponse.code === '1') {
      setPaymentDetailsAreValidated(true)
    } else {
      setPaymentDetailsAreValidated(false)
    }
  }

  useEffect(() => {
    if (token) {
      initBlueSnapSecurePayments(token, onFieldValidationChange, onPaymentReady)

      if (shouldTriggerTokenRefresh) {
        if (!errorMessage) submitACH()
        setShouldTriggerTokenRefresh(false)
      }
    }
  }, [token])

  const isEditing = Boolean(formState.values?.saved_payment_method_type)

  const validatePaymentData = () => {
    let newError: string | undefined = undefined
    if (!formState.values?.ecpRoutingNumber) newError = 'Routing number is required'
    else if (formState.values?.ecpRoutingNumber?.length !== 9) newError = 'Routing number must be exactly 9 digits'
    else if (!isValidUSRoutingNumber(formState.values?.ecpRoutingNumber)) newError = 'Invalid routing number'
    else if (!formState.values?.ecpAccountNumber || !formState.values?.ecpAccountNumberConfirm) {
      newError = 'Both account number fields are required'
    } else if (formState.values?.ecpAccountNumber?.length < 4 || formState.values?.ecpAccountNumber?.length > 17) {
      newError = 'Account number must be between 4 and 17 digits'
    } else if (formState.values?.ecpAccountNumber !== formState.values?.ecpAccountNumberConfirm) {
      newError = 'Both account numbers must match exactly'
    } else if (!formState.values?.ecpAccountType) newError = 'Account Type is required'
    else if (!formState.values?.first_name) {
      newError = 'First Name is required'
    } else if (!formState.values?.last_name) {
      newError = 'Last Name is required'
    } else if (!formState.values?.authorizedByShopper) {
      newError = 'You must agree to use electronic signature before you can continue'
    } else if (!formState.values.signature_data) {
      newError = 'Please sign your name in the box above'
    } else if (requireCompanyName && !formState.values.company_name) {
      newError = 'Company Name is required for business accounts'
    }
    setErrorMessage(newError)
    return newError
  }

  const submitACH = (e?: React.MouseEventHandler<HTMLButtonElement>) => {
    // @ts-ignore this gets a bit weird because of bluesnap's iframe form
    if (e) e.preventDefault()
    if (!validatePaymentData()) {
      const data = {
        amount: props.paymentRequestData.payment_amount,
        currency: props.paymentRequestData.currency,
      }
      //@ts-ignore
      window.bluesnap.securedPaymentCollectorSubmitData(data)
    }

    logAmplitudeEvent('cashflow_payment_info_entered', {
      payment_method: 'ach',
      project_id: props.projectId,
    })
    props.setPaymentInfoEntered(true)
  }

  const translatedEsignatureText = useMemo(() => {
    const adjustedPlaceholders = props.esignatureText.replace('{', '%{')
    return translate(adjustedPlaceholders, { org_name: props.orgName })
  }, [props.orgName, props.esignatureText])

  const translatedRevocationText = useMemo(() => {
    const adjustedPlaceholders = props.revocationText.replace('{', '%{')
    return translate(adjustedPlaceholders, { org_name: props.orgName })
  }, [props.orgName, props.revocationText])

  const saveIsDisabled = useMemo(() => {
    return (
      !formState.values?.ecpAccountNumber ||
      !formState.values?.ecpAccountNumberConfirm ||
      !formState.values?.ecpRoutingNumber ||
      !formState.values?.first_name ||
      !formState.values?.last_name ||
      !formState.values?.authorizedByShopper ||
      !formState.values.signature_data ||
      (requireCompanyName && !formState.values.company_name)
    )
  }, [formState.values, requireCompanyName])

  const accountNumber = formState?.values?.ecpAccountNumber
  const routingNumber = formState?.values?.ecpRoutingNumber
  const accountType = formState?.values?.ecpAccountType

  // there is a weird bug that I haven't been able to get to the root cause of where the signaturepad
  // renders the canvas component with no height or width when it's rendered outside of proposal
  // all of the css needed for the signaturepad is available in this page though. But to get around
  // this I just set the height and width manually
  useEffect(() => {
    if (signatureRef?.current) {
      const canvasParent = document.getElementsByClassName('m-signature-pad--body')?.[0]
      // @ts-ignore
      const canvas = canvasParent.children?.[0]
      if (canvas) {
        // @ts-ignore
        if (!canvas?.height) canvas.height = 100
        // @ts-ignore
        if (!canvas?.width) canvas.width = 396
      }
    }
  }, [signatureRef])

  return (
    <div className={classes.wrapper}>
      <div>
        {translate('Amount')}: {formatCurrencyWithSymbol(props.paymentRequestData.payment_amount, currencySymbol)}
      </div>
      <div>
        {translate('Frequency of Debits')}: {translate('Single')}
      </div>
      <div>
        {translate('Date of Authorization')}: {new Date().toLocaleDateString()}
      </div>
      <p>
        {translate(
          'By entering bank details and clicking Next below, I authorize %{org_name} to to debit my account (and, if necessary, electronically credit my account to correct erroneous debits as follows):',
          { org_name: props.orgName }
        )}
      </p>
      <form action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BlueSnapHostedField
              inputType="input"
              fieldName="ecpRoutingNumber"
              errorCode={undefined}
              fieldLabel={'Routing Number'}
              isHidden={true}
              value={routingNumber}
            />
            <TextInput
              variant="outlined"
              label="Routing Number"
              name="ecpRoutingNumber"
              source="ecpRoutingNumber"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <BlueSnapHostedField
              inputType="input"
              fieldName="ecpAccountNumber"
              errorCode={undefined}
              fieldLabel={'Account Number'}
              isHidden={true}
              value={accountNumber}
            />
            <TextInput
              variant="outlined"
              label="Account Number"
              name="ecpAccountNumber"
              source="ecpAccountNumber"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              label="Confirm Account Number"
              name="ecpAccountNumberConfirm"
              source="ecpAccountNumberConfirm"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <BlueSnapHostedField
              inputType="input"
              fieldName="ecpAccountType"
              errorCode={undefined}
              fieldLabel={'Account Type'}
              fullWidth
              isHidden={true}
              value={accountType}
            />
            <SelectInput
              name="ecpAccountType"
              source="ecpAccountType"
              label="Account Type"
              style={{ height: '45px', width: '100%', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              variant="outlined"
              fullWidth
              choices={[
                { name: '', id: undefined },
                { name: 'Checking', id: 'CONSUMER_CHECKING' },
                { name: 'Savings', id: 'CONSUMER_SAVINGS' },
                { name: 'Business Checking', id: 'CORPORATE_CHECKING' },
                { name: 'Business Savings', id: 'CORPORATE_SAVINGS' },
              ]}
            />
          </Grid>
          {requireCompanyName && (
            <Grid item xs={12}>
              <TextInput
                variant="outlined"
                label="Company Name"
                name="company_name"
                source="company_name"
                style={{ height: '45px', padding: '0px', margin: '0px' }}
                inputProps={{ className: classes.input }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextInput
              variant="outlined"
              label="First Name"
              name="first_name"
              source="first_name"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              variant="outlined"
              label="Last Name"
              name="last_name"
              source="last_name"
              style={{ height: '45px', padding: '0px', margin: '0px' }}
              inputProps={{ className: classes.input }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <p>{translatedRevocationText}</p>
          </Grid>
          <Grid item xs={12}>
            <CustomField
              name="authorizedByShopper"
              source="authorizedByShopper"
              label={translatedEsignatureText}
              component={(checkboxProps: any) => <CheckboxInput {...checkboxProps} elStyle={{ maxWidth: '900px' }} />}
              fullWidth
              required
              style={{ width: '100%', margin: '5px 0px', padding: '0px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ position: 'relative' }}>
              <SignaturePad
                clearButton="true"
                ref={(element: any) => {
                  if (signatureRef && element) {
                    signatureRef.current = element
                    window.SignaturePad = element
                  }
                }}
                onEnd={(e: MouseEvent) => {
                  if (signatureRef?.current) {
                    // @ts-ignore
                    form.change('signature_data', signatureRef.current.toDataURL())
                  }
                }}
              />
            </div>
            <span className="small">{translate('Sign with your finger or mouse')}</span>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{translate(errorMessage)}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <div>
              {isEditing && (
                <Button onClick={props.cancelEdit} variant="contained" color="default" className={classes.cancelBtn}>
                  {translate('Cancel')}
                </Button>
              )}
              <Button
                className={classes.nextBtn}
                // @ts-ignore this event ends up being overridden by bluesnap's SDK
                onClick={submitACH}
                type="submit"
                variant="contained"
                disabled={saveIsDisabled}
              >
                {translate('Next')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
export default ACHForm
