import { Grid, Theme, useMediaQuery } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

type PropTypes = {}

interface StyledProps {
  isMobile?: boolean
}
const StyledNavLink = styled(NavLink)<StyledProps>(({ theme }) => ({
  textDecoration: 'none',
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  padding: '14px',
  letterSpacing: 0.5,
  display: 'flex',
  alignItems: 'center',
}))

const ConfigureLinksWrapper = styled('div')<StyledProps>(({ isMobile }) => ({
  display: 'flex',
  marginTop: '26px',
  gap: '1rem',
  marginLeft: isMobile ? '1rem' : '3.5rem',
  overflow: isMobile ? 'scroll' : 'unset',
}))

const Badge = styled('div')({
  height: '8px',
  width: '8px',
  marginLeft: '5px',
  borderRadius: '8px',
  backgroundColor: 'rgba(193, 42, 46, 0.95)',
})

const CashFlowConfigurationNav: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const translate = useTranslate()
  const hasApplied = useSelector(orgSelectors.getBlueSnapOnboardingIsSubmitted)
  const isConditionallyApproved = useSelector(orgSelectors.getCashFlowOnboardingStatus) === 'conditionally approved'

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <ConfigureLinksWrapper isMobile={isMobile}>
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/milestones"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Payment Milestones')}
          </StyledNavLink>
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/financed_deposits"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Financed Project Deposits')}
          </StyledNavLink>
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/communications"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Customer Communications')}
          </StyledNavLink>
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/notifications"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Internal Notifications')}
          </StyledNavLink>
          {!hasApplied && (
            <StyledNavLink
              isMobile={isMobile}
              to="/cashflow/configure/registration"
              activeStyle={{
                fontWeight: 'bold',
                background: '#F1F1F1',
                borderRadius: 10,
              }}
            >
              {translate('Registration')}
            </StyledNavLink>
          )}
          <StyledNavLink
            isMobile={isMobile}
            to="/cashflow/configure/documents"
            activeStyle={{
              fontWeight: 'bold',
              background: '#F1F1F1',
              borderRadius: 10,
            }}
          >
            {translate('Documents')}
            {isConditionallyApproved && <Badge />}
          </StyledNavLink>
        </ConfigureLinksWrapper>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  )
}

export default CashFlowConfigurationNav
