import { useTracking } from 'contexts/eventsTracking/TrackingContext'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import useUiKey from 'hooks/useUiKey'
import { useCallback } from 'react'
import { EventAction, EventType } from 'types/tracking'

interface UseTrackCustomHandlerParams {
  componentKey: string
  eventType: EventType
  eventName: string
  action: EventAction
  handler: (...args: any[]) => void
}

const useTrackCustomHandler = ({
  componentKey,
  action,
  eventName,
  eventType,
  handler,
}: UseTrackCustomHandlerParams) => {
  const dispatchEvent = useTracking()
  const featureConfig: Record<string, any> = usePublicFeatureConfig('event_config')
  const uiKey = useUiKey(componentKey)

  const trackCustomHandler = useCallback(
    (...args: any[]) => {
      dispatchEvent({
        key: uiKey,
        action,
        name: eventName,
        type: eventType,
        additionalData: {},
        eventConfig: featureConfig?.backends,
      })
      handler(...args)
    },
    [uiKey, eventType, handler, dispatchEvent]
  )

  return trackCustomHandler
}

export default useTrackCustomHandler
