import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Grid, IconButton } from 'opensolar-ui'
import { ProductDetailNotFound } from '../detail/ProductDetail'
import { ProductDetailCheckoutMultiDistributor } from '../detail/content/ProductDetailCheckoutMultiDistributor'
import ProductCard from './ProductCard3'
import { useEffect, useMemo, useState } from 'react'
import {
  ComponentLikeType,
  DistributorDataTypeV2,
  HardwareComponentType,
} from '../../../elements/hardwareSelectorV2/types'
import { sortByPriceOrderingV2 } from '../utils'
import TechnicalDetails3 from './TechnicalDetails3'
import ProductDetailDescription3 from '../detail/content/ProductDetailDescription3'
import { mapComponentTypesV2ToV1 } from '../../../util/misc'
import FullScreenLoading from '../../../layout/widgets/FullScreenLoading'
import useFetchComponentsData from 'elements/hardwareSelectorV2/fetch/useFetchComponentsData'

const ProductDetail3 = ({ componentCode, goBack }: { componentCode: string; goBack?(): void }) => {
  const decodedComponentCode = decodeURIComponent(componentCode)
  const { fetchComponentsData, isFetching } = useFetchComponentsData()
  const [componentsData, setComponentsData] = useState<ComponentLikeType[]>([])
  const [selectedDistributor, setSelectedDistributor] = useState<DistributorDataTypeV2 | null>(null)

  const component = useMemo(() => (
    componentsData[0] as HardwareComponentType
  ), [componentsData])

  const displayDetailsDistributor = useMemo(() => {
    const lowestPriceDistributor = sortByPriceOrderingV2<DistributorDataTypeV2>(componentsData[0]?.ordering_v2 || [])
      .find((ordering) => ordering.price)

    return selectedDistributor || lowestPriceDistributor
  }, [componentsData, selectedDistributor])

  useEffect(() => {
    fetchComponentsData({
      parameters: { codes: [decodedComponentCode], limit: 10, show_archived: 1 },
      onReady: (data: ComponentLikeType[]) => {
        setComponentsData(data)
      },
    })
  }, [decodedComponentCode])

  if (isFetching) {
    return <FullScreenLoading />
  }
  if (!isFetching && (!componentsData || componentsData.length === 0)) {
    return <ProductDetailNotFound />
  }

  return (
    <Grid container xs spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <IconButton onClick={goBack} size="small">
          <ArrowBackIosIcon />
          Back
        </IconButton>
      </Grid>
      <Grid item xs>
        <ProductCard
          manufacturerUrl={component.manufacturer_logo}
          selectedDistributorData={displayDetailsDistributor}
          outputPower={(component.rating || 0) * 1000}
        />
      </Grid>
      <Grid item xs={4}>
        <ProductDetailCheckoutMultiDistributor
          component={componentsData[0]}
        />
      </Grid>
      <Grid item xs={12}>
        <TechnicalDetails3
          productDetailData={component.data}
          componentType={mapComponentTypesV2ToV1(component.type)}
          quantityPerItem={displayDetailsDistributor?.quantity_per_item || 0}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductDetailDescription3 selectedDistributor={displayDetailsDistributor} />
      </Grid>
    </Grid>
  )
}

export default ProductDetail3
