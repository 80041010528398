import { CircularProgress } from '@material-ui/core'
import { Alert, styled } from 'opensolar-ui'
import React from 'react'
import { ProjectType } from 'types/projects'
import { doNotTranslate } from 'util/misc'
import { BlackHeader, CustomWrapper, LoadingWrapper } from '../styles'
import { useFetchProjectStatus } from './hooks'
import SolarAppStepper, { StepActions } from './SolarAppStepper'

const ErrorWrapper = styled('div')({
  margin: '12px 0',
})

interface PropTypes {
  project: ProjectType
  updateSolarAppProjectDetails: (newId?: string, newUrl?: string) => void
}

const SolarAppProjectStatus: React.FC<PropTypes> = ({ project, updateSolarAppProjectDetails }) => {
  const { isLoading, errorMsg, solarAppProjectStatus } = useFetchProjectStatus(project)

  return (
    <>
      <CustomWrapper>
        <BlackHeader>{doNotTranslate('SolarAPP+')}</BlackHeader>

        {isLoading && !solarAppProjectStatus ? (
          <LoadingWrapper>
            <CircularProgress color="inherit" />
          </LoadingWrapper>
        ) : errorMsg ? (
          <ErrorWrapper>
            <Alert severity="error">{errorMsg}</Alert>
          </ErrorWrapper>
        ) : (
          <>
            {solarAppProjectStatus && (
              <>
                <SolarAppStepper solarAppProjectStatus={solarAppProjectStatus} />

                <StepActions
                  project={project}
                  solarAppProjectStatus={solarAppProjectStatus}
                  updateSolarAppProjectDetails={updateSolarAppProjectDetails}
                />
              </>
            )}
          </>
        )}
      </CustomWrapper>
    </>
  )
}

export default SolarAppProjectStatus
