import { InputAdornment, LinearProgress } from '@material-ui/core'
import { DraggableFlow } from 'elements/dnd/DraggableFlow'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, styled, TextField } from 'opensolar-ui'
import ChipArrayInput from 'projectSections/sections/manage3/common/ChipsArrayInput'
import React, { useCallback, useState } from 'react'
import { useNotify, useRefresh, useTranslate } from 'react-admin'
import { Field, Form, useField } from 'react-final-form'
import { useSelector } from 'react-redux'
import { projectInfoSelector } from 'reducer/project/projectInfoReducer'
import appStorage from 'storage/appStorage'
import { PrivateFileType } from 'types/privateFile'
import { ActionType, EventType } from 'types/tracking'
import { ContentWrapper, FormSubSection, InputRow, InputsCol } from '../styles/dialogAndFieldsStyles'
import { LoadingOverlay } from '../styles/styles'

export const DraggableTextField = styled(TextField)({
  marginTop: 8,
  '& fieldset': {
    pointerEvents: 'none',
  },
})

type PropTypes = {
  isOpen: boolean
  onDismiss: () => void
  recordsArraySelected: PrivateFileType[]
  onSuccess: () => void
  setRecordsArraySelected: (rs: PrivateFileType[]) => void
}

const FilesOrderField = () => {
  const order = useField('private_file_ids', { subscription: { value: true } }).input.value as PrivateFileType[]
  const { trackEvent } = useTrackComponent({
    componentKey: 'file_order_field',
    eventName: 'Reordered PDF Before merge',
  })
  return (
    <Field name="private_file_ids" validateFields={[]}>
      {({ input }) => (
        <DraggableFlow
          items={order}
          handleReorder={(newOrder) => {
            input.onChange(newOrder)
            trackEvent(EventType.USER_INTERACTION, { type: ActionType.EDIT }, { newOrder })
          }}
          {...input}
          dragMode="drag-handle"
          render={(item, { dragHandle }) => {
            return (
              <DraggableTextField
                value={item.title}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start" children={dragHandle} />,
                }}
              />
            )
          }}
        />
      )}
    </Field>
  )
}

const MergePdfsDialog: React.FunctionComponent<PropTypes> = (props) => {
  const project_id = useSelector(projectInfoSelector.getProjectId)
  const orgId = appStorage.getOrgId()
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { trackEvent } = useTrackComponent({
    componentKey: 'merge_pdfs_dialog',
    eventName: 'Merged files in project',
  })

  const dismiss = useCallback(() => {
    props.onDismiss()
  }, [])

  const fileResponseInfo = async (vals) => {
    const private_file_ids = vals.private_file_ids.map((r) => r.url)
    const reqBody = { ...vals, private_file_ids }
    const res = await fetch(`${window.API_ROOT}/api/orgs/${orgId}/projects/${project_id}/merge_pdfs/`, {
      method: 'POST',
      body: JSON.stringify(reqBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + appStorage.getToken(),
      },
    })
    const { statusText, ok, status } = res
    if (!ok) {
      throw new Error(`${status} ${statusText}`)
    } else {
      const fileRecord = await res.json()
      trackEvent(
        EventType.SYSTEM_EVENT,
        { type: ActionType.SAVE },
        { mergedFileRecord: vals, countPdfs: fileRecord.private_file_ids.length }
      )
      return fileRecord
    }
  }

  const handleSubmit = (values) => {
    trackEvent(EventType.USER_INTERACTION, { type: ActionType.SUBMIT }, { values })
    setIsLoading(true)
    fileResponseInfo(values)
      .then((res) => {
        props.onSuccess()
        props.setRecordsArraySelected([])
        setIsLoading(false)
        refresh()
        dismiss()
      })
      .catch((e) => {
        notify(`${e}`, 'error')
        setIsLoading(false)
      })
  }

  return (
    <>
      <Dialog
        open={props.isOpen}
        onBackdropClick={() => {
          if (!isLoading) {
            dismiss()
          }
        }}
      >
        {isLoading && (
          <LoadingOverlay>
            <LinearProgress variant="indeterminate" />
          </LoadingOverlay>
        )}
        <DialogTitle>{translate('Merge to PDF')}</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handleSubmit}
            FormState
            initialValues={{ private_file_ids: props.recordsArraySelected, title: '' }}
            render={({ handleSubmit, values }) => {
              return (
                <form>
                  <ContentWrapper>
                    <InputsCol>
                      <FormSubSection>
                        <h2>{translate('Basic Information')}</h2>
                        <InputsCol>
                          <InputRow>
                            <FormLabel>{translate('PDF Title')}</FormLabel>
                            <Field name="title">
                              {({ input }) => <TextField variant="outlined" fullWidth {...input} />}
                            </Field>
                          </InputRow>
                          <InputRow>
                            <FormLabel>{translate('File Tags')}</FormLabel>
                            <Field
                              name="tags"
                              id={'tags'}
                              render={() => (
                                <ChipArrayInput source={'file_tags'} resource={'file_tags'} reference={'file_tags'} />
                              )}
                            />
                          </InputRow>
                        </InputsCol>
                      </FormSubSection>
                      <FormSubSection>
                        <h2>{translate('Reorder Fields')}</h2>
                        <InputRow>
                          <FormLabel>
                            {translate(
                              'Drag and drop files to determine their sequence in the merged PDF. This PDF will contain copies of the files, leaving the originals unchanged.'
                            )}
                          </FormLabel>
                          <FilesOrderField />
                        </InputRow>
                      </FormSubSection>
                    </InputsCol>
                  </ContentWrapper>

                  <DialogActions>
                    <Button onClick={dismiss} variant="contained" color="default">
                      <span>{translate('Cancel')}</span>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      type="submit"
                      disabled={!values?.title || values.title === ''}
                    >
                      <span>{translate('Save')}</span>
                    </Button>
                  </DialogActions>
                </form>
              )
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default MergePdfsDialog

// export default withWidth()(MergePdfsDialog)
