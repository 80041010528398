import { getSdkSingleton_v1, onSdkSingletonCreated, OSSDK } from 'opensolar-sdk'
import { useEffect, useState } from 'react'

/**
 * Returns the SDK, but only once useSdkInit has been used elsewhere.
 * not guaranteed to be ready for use, see useSdkSingletonReady for that.
 */
export const useSdkSingleton = (): OSSDK | undefined => {
  const [sdk, setSdk] = useState<OSSDK | undefined>(getSdkSingleton_v1())

  useEffect(() => {
    if (sdk) {
      // no need to add callback
      return
    }
    return onSdkSingletonCreated(setSdk)
  }, [sdk])

  return sdk
}
