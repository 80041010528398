import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import arrayMutators from 'final-form-arrays'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from 'opensolar-ui'
import { useProjectFormMutators } from 'projectSections/form/mutations'
import { useNotify } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { Form, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { doNotTranslate } from 'util/misc'
import { ApplicationBegin } from './ApplicationBegin'
import { ApplicationEnd } from './ApplicationEnd'
import { ENA_STATIC_DATA, SCHEMA_MAPPING, SectionId } from './consts'
import { StepContentWrapper, StyledStepper } from './enaStyles'

import { AddressFields } from './fields/AddressFields'
import { SchemaFields } from './fields/SchemaFields'

interface ExistinAppInfo {
  draft_form_step: string
  draft_form_name: string
  application_id: string
  status: string
  created_at: string
  application_data: any
}

interface QuoteProps {
  isOpen: boolean
  onClose: () => void
  onSave: () => void
  existingAppInfo?: ExistinAppInfo | null
}

export const EnaDialog: React.FunctionComponent<QuoteProps> = (props) => {
  const { isOpen, onClose, onSave } = props
  const all_schemas = usePublicFeatureConfig('ena_schemas')
  const [schemaSelected, setSchemaSelected] = useState<any>()

  const project = useFormState().values as any
  const org = useSelector(orgSelectors.getOrg)
  const contact = (project?.contacts_data && project?.contacts_data[0]) || ({} as any)
  const orgId = useSelector(authSelectors.getOrgId)
  const notify = useNotify()
  const [step, setStep] = useState(() => Number(props.existingAppInfo?.draft_form_step) || 0)
  const [isSaving, setIsSaving] = useState(false)
  const mutations = useProjectFormMutators()

  const initialValues = useMemo(() => {
    if (props?.existingAppInfo?.application_data) {
      const { cutOutImages } = props?.existingAppInfo?.application_data

      // cutOutImages accepts just 1 file but the application requires it is sent as an array
      // this check is to ensure drafts saved before we started uploading files individually still work
      return Array.isArray(cutOutImages)
        ? { ...props?.existingAppInfo?.application_data, cutOutImages: cutOutImages[0] }
        : props?.existingAppInfo?.application_data
    }
    const { zip, address } = project
    const { email, first_name, family_name, phone } = contact
    const formatPhoneNumber = (phone?: string | null) => (phone ? phone.replace(/^\(44\)/, '') : undefined)
    const roleData = project?.assigned_role_data
    const installerName =
      roleData?.first_name || roleData?.family_name ? `${roleData.first_name} ${roleData.family_name}` : undefined
    const initialInstallerCustomerDetails = {
      installerCompany: org?.name,
      installerName,
      installerPhone: formatPhoneNumber(org?.sales_phone_number),
      customerEmail: email,
      customerName: first_name && family_name && `${first_name} ${family_name}`,
      customerPhone: formatPhoneNumber(phone),
      installerEmail: roleData?.user_email,
      mainAddress: address !== '' ? address : undefined,
      installationPostCode: zip !== '' ? zip : undefined,
    }
    return {
      lcts: [],
      subInstallerUsername: org?.name !== '' ? org?.name : undefined,
      applicationClass: undefined,
      applicationClassVersion: 1,
      schemaId: undefined,
      installerCustomerDetails: initialInstallerCustomerDetails,
      supplyDetails: { declaredVoltageAtConnectionPoint: '230 V' },
      existingDevices: [],
      devicesToInstall: [],
      additionalAttachments: [],
      newPremisesMaxDemand: {
        newPremisesMaxDemand: undefined,
      },
    }
  }, [])

  useEffect(() => {
    if (initialValues.applicationClass) {
      updateSchema(initialValues.applicationClass)
    }
  }, [initialValues.applicationClass])

  const saveApplication = async (appData, action: 'save_draft' | 'submit') => {
    setIsSaving(true)
    const { supplyDetails, installerCustomerDetails, ...restVals } = appData
    const declaredVoltageAtConnectionPoint = supplyDetails.phaseCode === 3 ? '400 V' : '230 V'
    installerCustomerDetails.installationPostCode = installerCustomerDetails.installationPostCode
      ? installerCustomerDetails.installationPostCode.replace(/(\S*)\s*(\d)/, '$1 $2')
      : '' // API doesn't take accept postcode without spaces

    supplyDetails.declaredVoltageAtConnectionPoint = declaredVoltageAtConnectionPoint

    const { exportLimitDeviceDetails } = supplyDetails

    if (exportLimitDeviceDetails && !supplyDetails?.isExportLimitDevicePresent) {
      delete supplyDetails.exportLimitDeviceDetails
    }

    const formData = new FormData()

    formData.append('json_payload', JSON.stringify({ ...restVals, supplyDetails, installerCustomerDetails }))

    const draftName = props?.existingAppInfo ? props?.existingAppInfo.draft_form_name : new Date().toISOString()
    formData.append('form_draft_name', draftName)

    formData.append('action', action)
    formData.append('current_step', `${step}`)
    try {
      const url = `${window.API_ROOT}/api/orgs/${orgId}/projects/${project.id}/create_ena_application/`
      const res = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + appStorage.getToken(),
        },
      })
      if (res.ok) {
        notify(action === 'save_draft' ? `Application saved` : `Application sent`)
        onSave()
      } else {
        console.error(res)
        const resJson = await res.json()
        throw new Error(resJson?.detail || 'Application submission failed.')
      }
    } catch (e) {
      notify(`${e}`, 'warning', { autoHideDuration: undefined })
      setIsSaving(false)
    }
  }

  const handleSubmit = async (applicationData) => {
    saveApplication(applicationData, 'submit')
  }

  const handleSaveDraft = (appData) => {
    saveApplication(appData, 'save_draft')
  }

  const sectionContent: { schema: any; fieldName: SectionId } = useMemo(() => {
    const fieldName = ENA_STATIC_DATA[step].sectionId
    const schema = schemaSelected?.properties?.[fieldName]
    return { schema, fieldName }
  }, [schemaSelected, step])

  const updateSchema = (applicationClass) => {
    if (!all_schemas) {
      notify(`Error retriving schema, try again later`, 'error')
      onClose()
    } else {
      const schemaId = SCHEMA_MAPPING[applicationClass]
      const selected = all_schemas[schemaId]
      setSchemaSelected(selected)
    }
  }

  const handleNextStep = (applicationClass) => {
    if (!all_schemas) {
      notify(`Error retriving schema, try again later`, 'error')
      onClose()
    } else {
      if (step === 0) {
        updateSchema(applicationClass)
      }
      setStep(step + 1)
    }
  }

  const handlePreviousStep = () => setStep(step - 1)

  const formRef = React.useRef(null)

  const steps = ENA_STATIC_DATA.slice(1).map((item, index) => ({
    content: item.stepLabel,
    isComplete: step > index + 1,
    id: index,
  }))

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{doNotTranslate('ENA Connect Direct Application')}</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={handleSubmit}
          mutators={{ ...mutations, ...arrayMutators }}
          initialValues={initialValues}
          keepDirtyOnReinitialize={true}
          render={({ handleSubmit, form, values }) => {
            return (
              <form {...form} onSubmit={handleSubmit}>
                <StepContentWrapper ref={formRef}>
                  {step > 0 && (
                    <StyledStepper steps={steps} activeStep={step - 1} orientation={'horizontal'} alternativeLabel />
                  )}
                  <div>
                    <Typography textVariant="subtitle1">{ENA_STATIC_DATA[step].title}</Typography>
                    <Typography>{ENA_STATIC_DATA[step].subtitle}</Typography>
                  </div>

                  {step === 0 && <ApplicationBegin />}
                  <SchemaFields {...sectionContent} formRef={formRef} />
                  {step === 1 && <AddressFields {...sectionContent} />}
                  {step === 2 && (
                    <SchemaFields
                      fieldName={'newPremisesMaxDemand'}
                      schema={schemaSelected?.properties?.['newPremisesMaxDemand']}
                    />
                  )}
                  {step + 1 === ENA_STATIC_DATA.length && <ApplicationEnd />}
                </StepContentWrapper>

                <DialogActions>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                      {step > 0 && (
                        <Button
                          type="button"
                          disabled={isSaving}
                          onClick={handlePreviousStep}
                          color={'default'}
                          variant="outlined"
                        >
                          {'Back'}
                        </Button>
                      )}
                      {step > 0 && (
                        <Button
                          type="button"
                          onClick={() => handleSaveDraft(values)}
                          color={'default'}
                          variant="outlined"
                          disabled={isSaving}
                        >
                          {'Save Draft'}
                        </Button>
                      )}
                      <Button disabled={isSaving} onClick={() => onClose()} variant="outlined" color="error">
                        <span>{doNotTranslate('Cancel')}</span>
                      </Button>
                    </div>
                    <div>
                      {step < 7 && (
                        <Button
                          onClick={() => handleNextStep(values.applicationClass)}
                          color={'primary'}
                          variant="contained"
                          disabled={isSaving || values.lcts.length === 0 || form.getState().hasValidationErrors}
                        >
                          {'Next'}
                        </Button>
                      )}
                      {step === 7 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          type="button"
                          disabled={isSaving}
                        >
                          <span>{doNotTranslate('Submit')}</span>
                        </Button>
                      )}
                    </div>
                  </div>
                </DialogActions>
              </form>
            )
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
