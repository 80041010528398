export const validateMilestones = (vals: any) => {
  const milestones = vals.payment_milestone_configurations
  let milestoneNum = 0

  const isValid = !milestones.some((milestone, index) => {
    if (!milestone.title.trim()) {
      milestoneNum = index + 1
      return true
    }
    return false
  })

  return { isValid, milestoneNum }
}
