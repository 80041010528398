export const DEFAULT_INVOICE_DETAILS = {
  billing_address_line_1: null,
  billing_address_line_2: null,
  billing_address_city: null,
  billing_address_state: null,
  billing_address_country: null,
  billing_address_postal_code: null,
  recipient_email: null,
  recipient_first_name: null,
  recipient_last_name: null,
  recipient_tax_id: null,
  is_commercial_invoice: false,
}

export const validateForm = (values) => {
  const requiredFields = [
    { field: 'recipient_first_name', message: 'First name is required' },
    { field: 'recipient_last_name', message: 'Last name is required' },
    { field: 'recipient_email', message: 'Email is required' },
    { field: 'billing_address_line_1', message: 'Street address is required' },
    { field: 'billing_address_city', message: 'City is required' },
    { field: 'billing_address_state', message: 'State is required' },
    { field: 'billing_address_country', message: 'Country is required' },
    { field: 'billing_address_postal_code', message: 'Zip code is required' },
  ]

  for (const { field, message } of requiredFields) {
    if (values[field] == null || values[field] === '') {
      return { isValid: false, field, message }
    }
  }

  if (values['is_commercial_invoice'] && !values['invoice_tax_id']) {
    return { isValid: false, field: 'invoice_tax_id', message: 'Tax ID is required for commercial invoices' }
  }

  return { isValid: true }
}
