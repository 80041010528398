import {
  Checkbox,
  Dialog,
  FormControlLabel,
  LinearProgress,
  makeStyles,
  Switch,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import PencilIcon from '@material-ui/icons/EditOutlined'
import LaunchIcon from '@material-ui/icons/LaunchOutlined'
import ManageListIcon from '@material-ui/icons/MoreVertOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { push as pushAction } from 'connected-react-router'
import { supportsAnyShading } from 'constants/calculators'
import ModuleGridsList from 'Designer/components/ModuleGridsList'
import ExpansionPanelShading from 'Designer/ExpansionPanelShading'
import { useSystemName } from 'Designer/hooks/useSystemName'
import { PricingDetail } from 'Designer/PanelSystem/Pricing'
import { handleSetShadingOverride, shadingTypeFromShadingOverride } from 'Designer/ShadingControls'
import { ToolsOthers } from 'Designer/Toolbars'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { setSelectComponentDialog as setSelectComponentDialogAction } from 'ducks/selectComponent'
import { setStudioSavePrompt as setStudioSavePromptAction } from 'ducks/studioPrompt'
import Alert from 'elements/Alert'
import AssociatedComponentsAlert from 'elements/associatedComponents/AssociatedComponentsAlert'
import { PrimaryButton } from 'elements/button/GenericButton'
import { DraggableList } from 'elements/dnd/DraggableList'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import { withHardwareSelectorV2 } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import { UiSwitch } from 'elements/UiSwitch'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import _ from 'lodash'
import { Button, IconButton } from 'opensolar-ui'
import { getFujiTourNextStepQuery } from 'persistentContent/tours/fuji/useGetFUJITourSteps'
import PropTypes from 'prop-types'
import React, { Component, useEffect, useState } from 'react'
import { useNotify, useTranslate, withTranslate } from 'react-admin'
import { connect, useDispatch, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { designViewSelectors, setSelectedDesignTab } from 'reducer/designer/view'
import { COMPONENT_TYPE_OPTIONS } from 'resources/components/others/constants'
import appStorage from 'storage/appStorage'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { clearTypingInFieldOnUnmount, refreshPanelGeneric, stripKeyForActiveTextField } from 'Studio/Utils'
import { ActionType, EventType } from 'types/tracking'
import { useSystemIsEditable } from 'util/changeOrder'
import { closablePanels } from 'util/closablePanels'
import { useDebouncedCallback } from 'util/Debounce'
import {
  currencySymbolForCountry,
  formatCurrency,
  getNumberWithOrdinal,
  getRoleFromState,
  inputValueToInt,
  isMountingComponent,
  isPricingLockedForStage,
} from 'util/misc'
import { getOrgCountryIso2FromState } from 'util/org'
import { withFeatureFlag } from 'util/withFeatureFlag.tsx'
import withForm from 'util/withForm'
import SystemAccordion from './accordion/SystemAccordion'
import {
  checkEnableEnphaseBomGeneratorForSystem,
  EnphaseBomGenerator,
  systemMatchesRecommendedComponents,
} from './EnphaseBomGenerator'
import PriceLock from './PriceLock'
import SunlightDealerFeeSwitch from './SunlightDealerFeeSwitch'
import { BatteryPanel } from './tabs/battery/BatteryPanel'
import { ComponentSelectorDropdown } from './tabs/common/ComponentSelectorDropdown'
import ComponentCollection from './tabs/ComponentCollection'
import Incentives from './tabs/incentives/Incentives'
import { InverterPanel } from './tabs/inverter/InverterPanel'
import MountingTab from './tabs/mounting/MountingTab'
import { refreshSystemFromObjectDebounced } from './tabs/mounting/nativeMounting/calculate'
import OtherBlock from './tabs/other/OtherBlock'
import PanelTabs from './tabs/PanelTabs'
import PaymentOptions from './tabs/paymentOptions/PaymentOptions'
import ResponsiveAccordionContentWrapper from './tabs/ResponsiveAccordionContentWrapper'
import ScaffoldingEstimate from './tabs/scaffolding/ScaffoldingEstimate'
import SystemSummary from './tabs/summary/Summary'
import { TabPanel } from './tabs/TabPanel'
import ComponentWarningBox from './warning/ComponentWarningBox'
import OthersPanelWarningBox from './warning/OthersPanelWarningBox'
import PanelGenericWarningBox from './warning/PanelGenericWarningBox'

window.monthIndex = 0

export const ManageIconButton = ({ url, tooltipLabel, heading = 'Leave Studio?', ...otherProps }) => {
  const dispatch = useDispatch()
  return (
    <Tooltip PopperProps={{ style: { zIndex: 2001 } }} title={tooltipLabel} enterDelay={300}>
      <IconButton
        id="ButtonManage"
        onClick={() => {
          //To do: fix prompt dialog
          dispatch(
            setStudioSavePromptAction({
              show_prompt: true,
              redirect: url,
              prompt_heading: heading,
              prompt_message: 'Any unsaved changes will be discarded.',
            })
          )
        }}
        style={{
          width: 24,
          padding: 0,
          height: 25,
          flex: '',
          top: 28,
          left: 5,
          opacity: 0.55,
        }}
        iconStyle={{ fill: 'rgb(0,0,0)' }}
        // hoveredStyle={{ opacity: 1 }}
        {...otherProps}
      >
        <ManageListIcon />
      </IconButton>
    </Tooltip>
  )
}

export const ExternalLink = ({ componentData }) => {
  if (componentData && componentData.external_link && componentData.external_label) {
    return (
      <div style={{ margin: '5px 0 15px' }}>
        <a
          onClick={() => {
            const urlRegExp = new RegExp('^(http|https)://', 'i')
            const openUrl = urlRegExp.test(componentData.external_link)
              ? componentData.external_link
              : `https://${componentData.external_link}`
            window.open(openUrl, '_blank')
          }}
          style={{ cursor: 'pointer', textDecoration: 'underline', frontSize: 16 }}
        >
          {componentData.external_label}
        </a>
      </div>
    )
  } else {
    return null
  }
}

const getDownPayment = (paymentOption) => {
  let result
  switch (paymentOption.payment_type) {
    case 'cash':
      result = paymentOption.deposit_amount
      break
    case 'regular_payment':
      result = paymentOption.down_payment_override || paymentOption.down_payment || 0
      break
    default:
      result = paymentOption.down_payment || 0
  }
  return result
}

const getRemainingBalance = (paymentOption, isChecked) => {
  let result = 0
  switch (paymentOption.payment_type) {
    case 'cash':
      result = paymentOption.purchase_price - paymentOption.deposit_amount
      break
    case 'loan':
      result = paymentOption.loan_amount
      break
    case 'loan_advanced':
      result = paymentOption.loan_amount
      break
    case 'lease':
      result = paymentOption.financed_amount
      break
    default:
      result = isChecked ? <i>N/A</i> : undefined
  }
  return result
}

const togglePaymentOptionOverrides = (uuid, enabled) => {
  const system = window.editor.objectByUuid(uuid)
  if (enabled) {
    if (!system.payment_options_override || system.payment_options_override === null) {
      const autoApplyHelper = new window.AutoApplyHelper(system, window.projectForm.getState().values)
      const autoAppliedPaymentOptions = autoApplyHelper?.generate('paymentOptions')?.paymentOptions || []
      autoAppliedPaymentOptions.sort((a, b) => {
        return (a.priority || 9999) - (b.priority || 9999)
      })
      const autoAppliedPaymentOptionsId = autoAppliedPaymentOptions.map((a) => a.id)
      window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', autoAppliedPaymentOptionsId))
    }
  } else {
    window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', null))
  }
}

const handleAddPaymentOptionOverride = (uuid, url) => {
  const system = window.editor.objectByUuid(uuid)
  var newValue = [...system['payment_options_override'], url]
  window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', newValue))
}

const handleRemovePaymentOptionOverride = (uuid, url) => {
  const system = window.editor.objectByUuid(uuid)
  var newValue = [...system['payment_options_override']]
  newValue.splice(system['payment_options_override'].indexOf(url), 1)
  window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', newValue))
}

const dialogStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
}))(MuiDialogActions)

export const DialogTitle = withStyles(dialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h1>{children}</h1>
      {onClose ? (
        <IconButton id="TransactionDialogClose" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const useStyle = makeStyles({
  root: {
    borderCollapse: 'collapse',
    position: 'relative',
    height: '100%',
    tableLayout: 'fixed',
    margin: '20px 0px',
    width: '100%',
    '& th': {
      border: '1px solid #e4e4e4',
      padding: 5,
    },
    '& td': {
      border: '1px solid #e4e4e4',
      padding: 5,
    },
  },
  header: {
    background: '#eeeeee',
  },
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: 5,
  },
}))(MuiDialogContent)

const PriceOverrideRow = ({
  payment,
  system,
  systems,
  availablePaymentOptions,
  updateExtraPaymentOptions,
  currencySymbol,
  isAutoApplied,
  paymentOptions,
  extraPaymentOptions,
  isSystemLoading,
  index,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [hasPhoenix, setHasPhoenix] = useState(false)

  useEffect(() => {
    let foundPhoenix = false
    system?.payment_options?.forEach((pmt) => {
      if (pmt?.integration === 'phoenix') foundPhoenix = true
    })
    if (foundPhoenix && !hasPhoenix) setHasPhoenix(true)
    else if (!foundPhoenix && hasPhoenix) setHasPhoenix(false)
  }, [system?.payment_options])

  useEffect(() => {
    if (!isSystemLoading && isLoading) {
      setLoading(false)
    }
  }, [isSystemLoading])
  const translate = useTranslate()
  const overrideSetting = system.payment_options_settings_overrides
  const systemName = useSystemName(system)
  const isArchived = !Boolean(
    availablePaymentOptions?.find((availablePaymentOption) => availablePaymentOption.id === payment.id)
  )
  const paymentOptionIds = window.AccountHelper.loadedData?.paymentOptions.map((x) => x.id)
  const isOwnPayment = paymentOptionIds?.includes(payment.id)
  const paymentName = `${payment.title}${
    system.payment_options_override && system.payment_options_override.indexOf(payment.id) !== -1
      ? ' (' + getNumberWithOrdinal(system.payment_options_override.indexOf(payment.id) + 1) + ')'
      : ''
  }${isArchived && isOwnPayment ? ' (archived)' : ''}`
  const isChecked = isAutoApplied
    ? Boolean(
        system.payment_options &&
          system.payment_options.find((assignedPaymentOption) => assignedPaymentOption.id === payment.id)
      )
    : Boolean(system.payment_options_override && system.payment_options_override.indexOf(payment.id) !== -1)

  const discountsAndAddersVisibleToCustomer =
    payment && payment.pricing && payment.pricing.line_items
      ? payment.pricing.line_items
          .filter((li) => li.show_customer)
          .map((li) => li.inc_tax)
          .reduce((a, b) => a + b, 0) - (payment.pricing && payment.pricing.discount ? payment.pricing.discount : 0)
      : 0

  if (payment?.integration === 'phoenix') return null

  return (
    <tr key={payment.id + payment.title}>
      {index === 0 && (
        <th
          style={{
            position: 'sticky',
            left: 0,
            color: '#4D4D4D',
            background: isAutoApplied ? '#efefef' : '#ffffff',
            zIndex: 2,
          }}
          rowSpan={paymentOptions.length}
        >
          {systemName}
          {
            <FormControlLabel
              control={
                <Checkbox
                  labelPosition="right"
                  size="small"
                  checked={!isAutoApplied}
                  onChange={(event) => {
                    setLoading(true)
                    const newValue = event.target.checked
                    if (newValue) {
                      /* --- show all payment options has been displayed in other systems ---*/
                      //1.get paymentOptions from all systems
                      //2.flatten into an array of payment option
                      //3.filter out archived payment option
                      //4.exclude calc result which belong to other systems
                      //5.remove duplicated payment option
                      const SystemsPaymentOptions = systems.map((system) => system.payment_options)
                      const allPaymentOptions = _.flattenDeep(SystemsPaymentOptions)
                      const allAvailablePaymentOptions = allPaymentOptions.filter((payment) =>
                        availablePaymentOptions?.some(
                          (availablePaymentOption) => availablePaymentOption.id === payment.id
                        )
                      )
                      const formattedAvailablePaymentOptions = allAvailablePaymentOptions.map((payment) => ({
                        id: payment.id,
                        title: payment.title,
                      }))
                      const uniqPaymentOptions = _.uniqBy(formattedAvailablePaymentOptions, (a) => a.id)
                      //merge with existing extra payment options
                      const newExtraPaymentOptions = _.unionBy(
                        uniqPaymentOptions,
                        Object.values(extraPaymentOptions[system.uuid]),
                        (a) => a.id
                      )
                      updateExtraPaymentOptions({ ...extraPaymentOptions, [system.uuid]: newExtraPaymentOptions })
                    }
                    togglePaymentOptionOverrides(system.uuid, newValue)
                  }}
                />
              }
              label={<span style={{ fontSize: 12 }}>{translate('Override this system')}</span>}
            />
          }
        </th>
      )}
      <td colSpan="2">
        {
          <FormControlLabel
            style={{ display: 'flex', alignItems: 'flex-start' }}
            control={
              <Checkbox
                style={{ padding: '0 9px' }}
                checked={isChecked}
                size="small"
                disabled={isAutoApplied}
                onChange={(event) => {
                  setLoading(true)
                  if (event.target.checked) {
                    handleAddPaymentOptionOverride(system.uuid, payment.id)
                  } else {
                    handleRemovePaymentOptionOverride(system.uuid, payment.id)
                  }
                }}
              />
            }
            label={<p style={{ marginTop: 4, fontSize: 12 }}>{paymentName}</p>}
          />
        }
      </td>
      <ReadOnlyTableCell
        isLoading={isLoading}
        currencySymbol={currencySymbol}
        value={payment.pricing && payment.pricing.top_line_system_price}
      />
      <ReadOnlyTableCell
        isLoading={isLoading}
        currencySymbol={currencySymbol}
        value={discountsAndAddersVisibleToCustomer}
      />
      {hasPhoenix ? (
        <ReadOnlyTableCell
          isLoading={isLoading}
          currencySymbol={currencySymbol}
          value={payment.pricing && payment.pricing.system_price_including_tax}
        />
      ) : (
        <EditableTableCell
          currencySymbol={currencySymbol}
          field="price"
          system={system}
          isChecked={isChecked}
          isLoading={isLoading}
          setLoading={setLoading}
          overrideSetting={overrideSetting}
          paymentOptionId={payment.id}
          displayValue={payment.pricing && payment.pricing.system_price_including_tax}
        />
      )}
      <EditableTableCell
        field="down_payment"
        system={system}
        isChecked={isChecked}
        isLoading={isLoading}
        setLoading={setLoading}
        currencySymbol={currencySymbol}
        overrideSetting={overrideSetting}
        paymentOptionId={payment.id}
        displayValue={getDownPayment(payment)}
      />
      <ReadOnlyTableCell
        currencySymbol={currencySymbol}
        isLoading={isLoading}
        value={getRemainingBalance(payment, isChecked)}
      />
    </tr>
  )
}

const ReadOnlyTableCell = ({ isLoading, value, currencySymbol }) => {
  const isEmpty = Boolean(value) === false && value !== 0
  const label = isNaN(value) ? value : currencySymbol + formatCurrency(value)
  return (
    <td style={{ position: 'relative' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {!isEmpty && <span>{label}</span>}
        {isLoading && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
      </div>
    </td>
  )
}

const EditableTableCell = ({
  displayValue,
  currencySymbol,
  overrideSetting = {},
  paymentOptionId,
  isChecked,
  isLoading,
  field,
  system,
  setLoading,
}) => {
  const [isEditMode, toggleEditMode] = useState(false)
  const [inputValue, setInputValue] = useState(undefined)
  useEffect(() => {
    const isEditing =
      overrideSetting[paymentOptionId] &&
      overrideSetting[paymentOptionId][field] &&
      !isNaN(overrideSetting[paymentOptionId][field])
    toggleEditMode(Boolean(isEditing))
  }, [])

  const logDebouncedDownPaymentChange = useDebouncedCallback((downPaymentVal) => {
    logAmplitudeEvent('down_payment_overridden', { project_id: window.WorkspaceHelper?.project?.id })
  }, 500)

  const updateValue = (value) => {
    const newValue = _.cloneDeep(overrideSetting)
    if (isNaN(value)) {
      _.unset(newValue, `${paymentOptionId}.${field}`)
    } else {
      _.merge(newValue, { [paymentOptionId]: { [field]: parseFloat(value) } })
    }
    setInputValue(value)
    setLoading(true)
    window.editor.execute(new window.SetValueCommand(system, 'payment_options_settings_overrides', newValue))

    system.saveDiffFromPriceOverrideVersusCalculatedPrice()
    logDebouncedDownPaymentChange(newValue)
  }

  const value = isNaN(inputValue) ? displayValue : inputValue

  return (
    <td style={{ position: 'relative' }}>
      {isChecked ? (
        <div>
          {isEditMode ? (
            <TextField
              type="number"
              value={value}
              onChange={(e) => updateValue(e.target.value)}
              onFocus={() => {
                window.editor.signals.typingInField.dispatch(true)
              }}
              onBlur={() => {
                window.editor.signals.typingInField.dispatch(false)
              }}
              InputProps={{
                startAdornment: currencySymbol,
                endAdornment: (
                  <CloseIcon
                    style={{ width: 18, height: 18, cursor: 'pointer' }}
                    onClick={() => {
                      toggleEditMode(false)
                      updateValue()
                    }}
                  />
                ),
              }}
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{currencySymbol + formatCurrency(value)}</span>
              <PencilIcon
                style={{ width: 18, height: 18, cursor: 'pointer', color: '#4d4d4d' }}
                onClick={() => toggleEditMode(true)}
              />
            </div>
          )}
        </div>
      ) : (
        isLoading && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />
      )}
    </td>
  )
}

const PriceOverrideTable = ({ system, index, extraPaymentOptions, ...rest }) => {
  const classes = useStyle()
  const translate = useTranslate()
  const isAutoApplied = !Boolean(system.payment_options_override !== null)
  const autoAppliedPaymentOptions = window.AccountHelper.getAutoAppliedPaymentOptions()
  const assignedPaymentOptions = system.payment_options
  const isSystemLoading = rest.systemsLoadingUuids.includes(system.uuid)
  const paymentOptions = _.unionBy(
    assignedPaymentOptions,
    autoAppliedPaymentOptions,
    Object.values(extraPaymentOptions[system.uuid]),
    (a) => a.id
  )

  return (
    <table className={classes.root}>
      <colgroup>
        <col width="200px" />
        <col width="50px" />
        <col width="120px" />
        <col width="95px" />
        <col width="75px" />
        <col width="100px" />
        <col width="90px" />
        <col width="80px" />
      </colgroup>
      {index === 0 && (
        <thead>
          <tr className={classes.header}>
            <th style={{ position: 'sticky', left: 0, background: '#eeeeee', zIndex: 2 }}>System</th>
            <th>{translate('Enable')}</th>
            <th>{translate('Payment Option')}</th>
            <th>{translate('Standard System Price')}</th>
            <th>
              {translate('Discount & Adders')}
              <p style={{ margin: 2, fontWeight: 'normal' }} className="small">
                (shown to customer)
              </p>
            </th>
            <th>{translate('Total System Price')}</th>
            <th>{translate('Down Payment')}</th>
            <th>{translate('Remaining Balance')}</th>
          </tr>
        </thead>
      )}
      <tbody
        style={{
          color: isAutoApplied ? 'rgba(77,77,77,0.5)' : '#4D4D4D',
          background: isAutoApplied ? '#efefef' : '#ffffff',
        }}
      >
        {paymentOptions.map((payment, paymentIndex) => (
          <PriceOverrideRow
            key={paymentIndex}
            payment={payment}
            system={system}
            isSystemLoading={isSystemLoading}
            paymentOptions={paymentOptions}
            extraPaymentOptions={extraPaymentOptions}
            isAutoApplied={isAutoApplied}
            index={paymentIndex}
            {...rest}
          />
        ))}
      </tbody>
    </table>
  )
}

export const PriceOverrideLink = React.memo(
  ({ currencySymbol, systemsLoadingUuids, systems, linkVisibility, linkStyle = {}, tab = undefined }) => {
    const translate = useTranslate()
    const notify = useNotify()
    const [open, setOpen] = useState(false)

    return (
      <>
        {linkVisibility && (
          <span style={linkStyle}>
            {'(' + translate('Price overridden for one or more payment options') + '. '}
            <a
              onClick={() => {
                if (window.Designer.systemsQueued()?.length > 0) {
                  notify('Please wait until calculations are complete', 'warning')
                } else {
                  setOpen(true)
                }
              }}
            >
              {translate('Click here to see')}
            </a>
            {')'}
          </span>
        )}
        {open && (
          <PriceOverrideDialog
            currencySymbol={currencySymbol}
            systemsLoadingUuids={systemsLoadingUuids}
            systems={systems}
            open={open}
            onClose={() => setOpen(false)}
            tab={tab}
          />
        )}
      </>
    )
  }
)

export const PriceOverrideButton = React.memo(
  ({ currencySymbol, systemsLoadingUuids, pricingIsLocked, systems, tab = undefined, disabled = false }) => {
    const [hasSunlight, setHasSunlight] = useState(false)
    const [thisSystem, setThisSystem] = useState(undefined)

    const { trackEvent } = useTrackComponent({
      componentKey: 'price_override_button',
      eventName: 'Design - Price Override',
    })

    useEffect(() => {
      const thisSystemUuid = window.editor.selectedSystem?.uuid
      if (thisSystemUuid && systems?.length > 0) {
        const selectedSystem = systems.find((sys) => sys.uuid === thisSystemUuid)
        setThisSystem(selectedSystem)
      }
    }, [systems])

    useEffect(() => {
      if (thisSystem && thisSystem.payment_options_override && thisSystem.payment_options_override.length > 0) {
        try {
          let availablePaymentOptions = window.AccountHelper.getPaymentOptionAvailable()
          const sunlightOptions = availablePaymentOptions
            ?.filter((pmt) => thisSystem.payment_options_override?.indexOf(pmt.id) >= 0)
            ?.filter((pmt) => JSON.parse(pmt.configuration_json)?.integration === 'sunlight')
          if (!hasSunlight && sunlightOptions?.length > 0) setHasSunlight(true)
          else if (hasSunlight && (!sunlightOptions || sunlightOptions.length === 0)) setHasSunlight(false)
        } catch (ex) {}
      } else if (hasSunlight) setHasSunlight(false)
    }, [hasSunlight, thisSystem?.payment_options_override])

    const notify = useNotify()
    const [open, setOpen] = useState(false)

    return (
      <>
        <PrimaryButton
          variant="contained"
          style={{
            margin: '10px auto',
            padding: '0px 4px',
            border: '1px solid #dcdcdc',
            width: '100%',
          }}
          disabled={pricingIsLocked || disabled}
          labelWrapperStyle={{
            padding: '6px 2px',
            fontSize: 13,
            textTransform: 'none',
            fontWeight: 'normal',
            whiteSpace: 'normal',
            textOverflow: 'normal',
          }}
          onClick={() => {
            trackEvent(
              EventType.USER_INTERACTION,
              { type: ActionType.CLICK },
              { eventName: 'User Customised Price By System And Payment Option' }
            )
            if (window.Designer.systemsQueued()?.length > 0) {
              notify('Please wait until calculations are complete', 'warning')
            } else {
              setOpen(true)
            }
          }}
          label={'Customise price by system and payment option'}
        />
        {hasSunlight && thisSystem?.pricing && (
          <SunlightDealerFeeSwitch
            initialValue={thisSystem?.pricing?.force_apply_dealer_fee_discounts}
            systemUuid={window.editor.selectedSystem?.uuid}
          />
        )}
        <PriceOverrideDialog
          currencySymbol={currencySymbol}
          systemsLoadingUuids={systemsLoadingUuids}
          systems={systems}
          open={open}
          onClose={() => setOpen(false)}
          tab={tab}
        />
      </>
    )
  }
)

const PriceOverrideDialog = React.memo(
  ({ currencySymbol, systemsLoadingUuids, systems, open, onClose, tab = undefined }) => {
    const loanpalChannels = useSelector((state) => authSelectors.getLoanpalChannels(state))
    const ignoreChannels = useSelector(authSelectors.getIgnoreLoanpalChannels)
    const translate = useTranslate()

    const mosaicConnected = useSelector((state) => {
      const currentRole = authSelectors.getCurrentRole(state)
      return currentRole?.mosaic_enabled
    })

    const sungageConnected = useSelector((state) => {
      const currentRole = authSelectors.getCurrentRole(state)
      return currentRole?.sungage_enabled
    })

    const thisRoleConnectedToBrighte = useSelector(authSelectors.getBrighteConnected)
    const brighteConnected =
      window.WorkspaceHelper.project?.brighte_role_connection_status === 'connected' ||
      (window.WorkspaceHelper?.project.brighte_role_connection_status === 'no_role' && thisRoleConnectedToBrighte)

    const [availablePaymentOptions, setPaymentOptionsAvailable] = useState([])
    const [warningMsg, setWarningMsg] = useState(undefined)
    const [selectedPaymentId, selectPayment] = useState(null)
    const [extraPaymentOptions, updateExtraPaymentOptions] = useState({})
    const [errorMsg, setErrorMsg] = useState(undefined)
    const [disableButton, setDisabledButton] = useState(false)

    useEffect(() => {
      if (open) {
        logAmplitudeEvent('payment_option_pricing_override_dialog_opened', {
          project_id: window.WorkspaceHelper.project?.id,
          tab,
        })
      }
    }, [open])

    useEffect(() => {
      let showWarning = false
      const paymentOptions = window.AccountHelper.getPaymentOptionAvailable()?.filter((pmt) => {
        if (!pmt.configuration_json) return true
        else {
          try {
            let pmtConfig = JSON.parse(pmt.configuration_json)
            if (pmtConfig?.integration === 'sungage' && !sungageConnected) {
              showWarning = true
              return false
            } else if (pmtConfig?.integration === 'brighte' && !brighteConnected) {
              showWarning = true
              return false
            } else if (pmtConfig?.integration === 'mosaic' && !mosaicConnected) {
              showWarning = true
              return false
            } else if (pmtConfig?.integration === 'loanpal') {
              const channels = pmtConfig?.channels

              if (!ignoreChannels && channels && channels.length > 0) {
                let hasChannelMatch = false
                if (!loanpalChannels || loanpalChannels.length < 1) return true
                loanpalChannels.forEach((roleChannel) => {
                  if (channels.includes(roleChannel)) hasChannelMatch = true
                })
                if (hasChannelMatch) return true
                else {
                  showWarning = true
                  return false
                }
              }
              return true
            }
            return true
          } catch (ex) {
            console.log('ex', ex)
            return true
          }
        }
      })
      setPaymentOptionsAvailable(paymentOptions)
      if (showWarning)
        setWarningMsg(
          'At least one integrated payment option is hidden from this dropdown because your user is not yet connected to the financing company. You can view and add these payment options via the Override Default Payment Options button.'
        )
    }, [])

    useEffect(() => {
      const systemsUuids = systems.map((system) => system.uuid)
      let hasChange = false
      systemsUuids.forEach((id) => {
        if (extraPaymentOptions[id]) {
        } else {
          extraPaymentOptions[id] = {}
          hasChange = true
        }
      })
      hasChange && updateExtraPaymentOptions(extraPaymentOptions)
    }, [systems])

    const handlePaymentSelection = (e) => {
      let chosenPmt = availablePaymentOptions?.find((pmt) => pmt.id === e.target.value)
      let chosenPmtConfig
      if (chosenPmt && chosenPmt.configuration_json) {
        try {
          chosenPmtConfig = JSON.parse(chosenPmt.configuration_json)
        } catch (ex) {
          console.log('ex', ex)
        }
      }
      if (chosenPmtConfig && chosenPmtConfig.integration === 'sungage') {
        let otherVersionAlreadyAdded = false
        systems?.forEach?.((sys) =>
          sys?.payment_options?.forEach((pmt) => {
            try {
              if (
                pmt?.integration === 'sungage' &&
                pmt.interest_rate === chosenPmtConfig.interest_rate &&
                pmt.term === chosenPmtConfig.term
              )
                otherVersionAlreadyAdded = true
            } catch (ex) {
              console.log('ex', ex)
            }
          })
        )
        if (otherVersionAlreadyAdded) {
          setDisabledButton(true)
          setErrorMsg('A Sungage payment option with this term and rate has already been added.')
        } else {
          setErrorMsg(undefined)
          setDisabledButton(false)
        }
      }

      selectPayment(e.target.value)
    }
    return (
      <>
        <Dialog fullWidth={true} maxWidth={'md'} open={open}>
          <DialogTitle onClose={onClose}>{translate('Price Override for Payment Option')}</DialogTitle>
          <DialogContent dividers>
            {systems.map((system, index) => (
              <PriceOverrideTable
                key={system.id}
                index={index}
                system={system}
                systems={systems}
                systemsLoadingUuids={systemsLoadingUuids}
                updateExtraPaymentOptions={updateExtraPaymentOptions}
                extraPaymentOptions={extraPaymentOptions}
                currencySymbol={currencySymbol}
                availablePaymentOptions={availablePaymentOptions}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <div
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}
            >
              {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: '0 20px 0 10px' }}>{translate('Enable Payment Option')}:</p>
                <div style={{ display: 'flex' }}>
                  <SharedEntitySelectInput
                    choices={availablePaymentOptions.filter((payment) => {
                      const config = payment?.configuration_json && JSON.parse(payment.configuration_json)
                      return config?.integration !== 'phoenix'
                    })}
                    style={{ width: 250, marginRight: 20, marginTop: 0 }}
                    classes={'null'}
                    value={selectedPaymentId ? selectedPaymentId : ''}
                    onChange={handlePaymentSelection}
                  />
                  <Button
                    disabled={!selectedPaymentId || disableButton}
                    variant="contained"
                    size="small"
                    color="default"
                    onClick={() => {
                      const paymentOptions = _.cloneDeep(extraPaymentOptions)
                      const newValue = window.AccountHelper.getPaymentOptionsById(selectedPaymentId)
                      const newExtraPaymentOptions = _.mapValues(paymentOptions, (payments) => ({
                        ...payments,
                        [selectedPaymentId]: newValue,
                      }))
                      updateExtraPaymentOptions(newExtraPaymentOptions)
                    }}
                  >
                    <span>{translate('Add')}</span>
                  </Button>
                </div>
              </div>
              {warningMsg && <p className="small">{warningMsg}</p>}
            </div>
          </DialogActions>
        </Dialog>
      </>
    )
  }
)

const getAutoAppliedIncentives = (system, incentiveType) => {
  return (
    (system &&
      system.pricing &&
      system.pricing['incentive_to_' + incentiveType] &&
      system.pricing['incentive_to_' + incentiveType].incentives.filter((i) => i.automatic === true)) ||
    []
  )
}

export const styles = {
  lightHeading: {
    fontSize: '12px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.3)',
  },
  inputField: {
    width: '100%',
    marginTop: 10,
  },
  h4: {
    fontSize: '14px',
    marginTop: 30,
    marginBottom: 10,
  },
  h4FloatLeft: {
    fontSize: '14px',
    float: 'left',
    marginTop: 30,
    marginBottom: 10,
  },
  h5: {
    marginTop: 0,
    marginBottom: 10,
  },
  h5FloatLeft: {
    float: 'left',
    marginLeft: 5,
    marginTop: 0,
    marginBottom: 10,
  },
  paperTight: {
    width: '100%',
    margin: '6px 0 10px',
  },
  expansionPanelDetailsContainer: {
    position: 'relative',
    width: '100%',
    padding: 0,
  },
  addRight: {
    float: 'left',
    marginLeft: -3,
    marginTop: -3,
    minWidth: 10,
  },
  plus: {
    minWidth: 10,
  },
  panelLarge: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: 320,
    padding: 10,
    overflow: 'scroll',
    border: '1px solid transparent',
    backgroundColor: '#EFEFEF',
  },
  panelSmall: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: 320,
    padding: 10,
    overflow: 'scroll',
    border: '1px solid transparent',
    backgroundColor: '#EFEFEF',
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '10px 0',
  },
  button: {
    minWidth: 40,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    marginRight: 10,
  },
  subHeading: {
    marginTop: 2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 200,
    display: 'inline-block',
    fontSize: 12,
    lineHeight: '14px',
    color: '#6C6C6C',
  },
  smallSubHeading: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 140,
    lineHeight: '14px',
    display: 'inline-block',
    fontSize: 12,
    color: '#6C6C6C',
  },
  basicPriceItem: {
    fontSize: 12,
    marginBottom: 8,
  },
  textFieldHalfSize: {
    width: 100,
    margin: '20px 0',
    marginLeft: 5,
  },
  textFieldHalfSizeLabel: {
    fontSize: '12px',
  },
}

export const valueOrEmptyStringIfNull = (value, multiplierIfNumber) => {
  if (value === null || value === '') {
    return ''
  } else if (value === '0' || parseFloat(value) === 0) {
    return '0'
  } else {
    return multiplierIfNumber ? parseFloat((value * multiplierIfNumber).toFixed(2)) : value
  }
}

export const valueCsvOrEmptyStringIfNull = (value) => {
  if (value === null || value === '') {
    return ''
  } else {
    return value.join(',')
  }
}

export const inputValueToFloatOrEmptyStringWhenNull = (value) => {
  if (value === '') {
    return null
  } else {
    return parseFloat(value)
  }
}

export const valueOrEmptyStringIfZero = (value) => {
  if (value === '0' || parseFloat(value) === 0) {
    return ''
  } else {
    return value
  }
}

function isInt(n) {
  return n % 1 === 0
}

export const inputValueToFloat = (value) => {
  if (typeof value === 'string') {
    if (value === '') {
      return 0
    } else if (value.length > 1 && parseFloat(value) === 0) {
      //handle 0.001

      return parseFloat('Do Not Use')
    } else {
      return parseFloat(value)
    }
  } else if (typeof value === 'number') {
    if (isInt(value) && parseFloat(value) !== 0) {
      return parseFloat(value)
    } else {
      return value
    }
  } else {
    return 0
  }
}

function upperCaseSafe(value) {
  return value && value.toUpperCase ? value.toUpperCase() : value
}

const hasMaxeonAcPanel = (panelCode) => {
  if (!panelCode) {
    return false
  }
  const MaxeonAComponentCodes = window.pluginConfigs?.find((config) => config.name === 'pluginMaxeonAcPanels')
  if (MaxeonAComponentCodes?.componentCodes?.map((v) => upperCaseSafe(v)).includes(upperCaseSafe(panelCode))) {
    return true
  } else {
    return false
  }
}

window.SystemSummaryEnergyFlowsDataForDayCached = {
  data: null,
  hashedArgsPlusMonthIndex: null,
}

export const handleObjectChangedAsCommand = (uuid, attributeName, newValue) => {
  if (uuid && !isNaN(newValue) && !window.editor.changingHistory) {
    var object = window.editor.objectByUuid(uuid)
    if (object) {
      window.editor.execute(new window.SetValueCommand(object, attributeName, newValue, undefined, true))
    }
  }
}

const name = 'PanelSystem'

class PanelSystem extends Component {
  constructor(props) {
    super(props)
    var _state = {
      basicShadingRaw: '',
      unstrungModulesInverterEfficiencyRaw: '',
      basicPriceRaw: '',
      basicPriceRawPerWatt: '',
      visible: false,
      system: null,
      profileDataLoaded: false,
      moduleTypes: [],
      inverterTypes: [],
      batteryTypes: [],
      otherTypes: [],
      pricingSchemes: [],
      dcOptimizerEfficiencyRaw: '',
      showHeadings: true,
      addersPerSystemRaw: null,
      addersPerPanelRaw: null,
      addersPerWattRaw: null,
      nonSolarPriceIncludedRaw: '',
      nonSolarProjectType: undefined,
      discountRaw: '',
      incentiveToInstallerRaw: '',
      incentiveToCustomerRaw: '',
      exportLimitRaw: '',
      generationOverrideRaw: '',
      selfConsumptionOverrideRaw: '',
      // batteryOffsettableLoadFractionRaw: '',
      // batteryOffsettableLoadCapRaw: '',
      editSystemName: false,

      // typingInField records which field is currently being typed in
      // typingInFieldForObjectUuid records the system_uuid that they typingInField relates to
      // Required because if we add a new system while still typing, we need to only apply
      // typingInField for the corresponding system
      typingInField: null,
      typingInFieldForObjectUuid: null,
      shadingOpen: false,
      invertersOpen: false,
      batteriesOpen: false,
      othersOpen: false,
      costsOpen: false,
      advancedOpen: false,
      panelsOpen: false,
      paymentOptionsOpen: false,
      incentivesOpen: false,
      addSystemOpen: false,
      enphaseBomGeneratorOpen: false,
      enphaseBomGeneratorRan: false,

      taxOverrideHardwareRaw: '',
      taxOverrideNonHardwareRaw: '',
      systemUuidsAwaitingTrigger: '',

      // Allows switching back to original other components view which allows re-ordering (not supported by the categorised view)
      reorderOtherComponents: false,
    }

    var injectState = props.state ? props.state : null
    if (props.selectedObject) {
      injectState = this.stateFromObject()
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        _state[key] = injectState[key]
      }
      console.log('PanelSystem Init with state:', this.state)
    }

    this.state = _state
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //onBlur doesn't fire if whole component unmounts first
    //cleanup just in case
    if (prevState.visible === true && this.state.visible === false) {
      clearTypingInFieldOnUnmount.call(this)
    }
  }

  componentDidMount() {
    closablePanels.add(name, this.close, this)
    // referenceSave.call(this, 'PanelSystem', [
    //   'sceneLoaded', //Mostly used to attempt to load component from profile data
    //   'sceneGraphChanged',
    //   'objectChanged',
    //   'objectSelected',
    //   'objectAdded',
    //   'objectRemoved',
    //   'controlModeChanged',
    //   'systemCalculationsAddedToQueue',
    //   'systemCalculationsRemovedFromQueue',
    //   'sequenceUpdated',
    //   'viewsChanged',
    // ])
    // referenceSave.call(this, 'PanelSystem')
    this.props.useStudioSignalsLazy(
      this.refreshPanel,
      [
        'sceneLoaded', //Mostly used to attempt to load component from profile data
        'sceneGraphChanged',
        'objectChanged',
        'objectSelected',
        'objectAdded',
        'objectRemoved',
        'controlModeChanged',
        'systemCalculationsAddedToQueue',
        'systemCalculationsRemovedFromQueue',
        'sequenceUpdated',
        'viewsChanged',
      ],
      this
    )

    this.props.useStudioSignals(
      ({ label, cancelFunction }) => {
        if (label === 'Paint Modules (P)') {
          this.setState({
            ...this.state,
            cancelFunction: cancelFunction,
          })
        }
      },
      ['placementModeChanged'],
      this
    )

    this.props.useStudioSignals(
      (object, attributeName) => refreshSystemFromObjectDebounced(this.props.form, object, attributeName),
      ['objectAdded', 'objectChanged', 'objectRemoved', 'setPanelOrientation'],
      {
        debounce: 0,
      }
    )
  }

  componentWillUnmount() {
    closablePanels.remove(name)
    clearTypingInFieldOnUnmount.call(this)
    // referenceClear.call(this)
  }

  stateFromObject() {
    var selected = window.editor ? window.editor.selected : null
    var selectedSystem = window.editor ? window.editor.selectedSystem : null
    var system
    if (!selected) {
      if (!selectedSystem) {
        // nothing selected and no systems are present
        // be sure to show the sidebar so we have access to the "New System" button otherwise we can never create a system
        return { visible: true, system: null, systems: [], systemIndex: null }
      } else {
        system = selectedSystem
      }
    } else if (['ViewDummy'].indexOf(selected.type) !== -1) {
      // object does not belong to a system and does not have it's own panel
      system = selectedSystem
    } else if (selected.type === 'OsSystem') {
      system = selected
    } else if (['OsInverter', 'OsMppt', 'OsString', 'OsBattery', 'OsOther'].indexOf(selected.type) !== -1) {
      system = selected.getSystem()

      if (!system) {
        //this is possible, e.g. when an object was just deleted it no longer has a system assigned
        return { visible: false }
      }
    } else {
      return { visible: false }
    }

    //should use form
    var project = window.WorkspaceHelper ? window.WorkspaceHelper.project : null
    var systems = window.editor ? window.editor.getSystems() : []
    var systemIndex = systems && systems.length > 0 && system ? systems.indexOf(system) + 1 : 0

    var _state = {
      basicShadingRaw:
        system && system.shadingOverride && system.shadingOverride.length === 1
          ? Math.round(system.shadingOverride[0] * 100)
          : '',
      unstrungModulesInverterEfficiencyRaw:
        system && system.unstrungModulesInverterEfficiency ? system.unstrungModulesInverterEfficiency : '',
      basicPriceRaw: system ? valueOrEmptyStringIfNull(system.basicPriceOverride) : '',
      basicPriceRawPerWatt: system ? valueOrEmptyStringIfNull(system.basicPriceOverridePerWatt) : '',
      taxOverrideHardwareRaw: system ? valueOrEmptyStringIfNull(system.tax_override_hardware) : '',
      taxOverrideNonHardwareRaw: system ? valueOrEmptyStringIfNull(system.tax_override_non_hardware) : '',
      visible: true,
      system: system,
      systems: systems,
      systemIndex: systemIndex,

      //@TODO: Avoid storing this as separate variable but we need to allow invalid states while typing?
      dcOptimizerEfficiencyRaw: system ? system.dcOptimizerEfficiency : '',
      discountRaw: system ? system.discount : '',
      incentiveToInstallerRaw: system ? system.incentive_to_installer : '',
      incentiveToCustomerRaw: system ? system.incentive_to_customer : '',
      addersPerSystemRaw: system ? system.adders_per_system : '',
      addersPerPanelRaw: system ? system.adders_per_panel : '',
      addersPerWattRaw: system ? system.adders_per_watt : '',
      nonSolarPriceIncludedRaw: system ? system.non_solar_price_included : '',
      nonSolarProjectType: system?.non_solar_project_type,
      otherQuantityRaw: '',
      systemsLoadingUuids: window.Designer ? window.Designer.systemsQueued() : [],
      shadingOverrideType: shadingTypeFromShadingOverride(system && system.shadingOverride),
      shadingOverride: system?.shadingOverride || [],
      exportLimitRaw: system ? valueOrEmptyStringIfNull(system.export_limit) : '',
      generationOverrideRaw: system ? valueCsvOrEmptyStringIfNull(system.generation_override) : '',
      selfConsumptionOverrideRaw: system ? valueOrEmptyStringIfNull(system.self_consumption_override, 100) : '',
      //should replace project by form data
      currencySymbol: this.props.currencySymbol,
      countryIso2: project?.country_iso2,
      showReturnBasicButton: appStorage.has('startupBasicMode')
        ? appStorage.getBool('startupBasicMode')
        : window.AccountHelper.hasNearmap(),
    }

    // inject each entry for otherQuantityRaw
    system && system.others().forEach((other) => (_state['otherQuantityRaw' + other.uuid] = other.quantity))

    stripKeyForActiveTextField.call(this, _state, system)

    return _state
  }

  updateCheck() {
    this.setState((oldState) => {
      return {
        checked: !oldState.checked,
      }
    })
  }

  select = (object) => {
    window.editor.select(object)
  }

  handleOverridePriceLocking = (uuid, value) => {
    var system = window.editor.objectByUuid(uuid)
    // automatically enable overrides for incentives & payment options
    if (system.payment_options && !system.payment_options_override) {
      const paymentOptionsOverride = system.payment_options.map((paymentOption) => paymentOption.id)
      window.editor.execute(new window.SetValueCommand(system, 'payment_options_override', paymentOptionsOverride))
    }

    if (!system.incentives) {
      const customerIncentives = getAutoAppliedIncentives(system, 'customer')
      const installerIncentives = getAutoAppliedIncentives(system, 'installer')
      const incentivesOverride = [...customerIncentives, ...installerIncentives]
      const overrideId = incentivesOverride
        .map((incentive) => {
          if (incentive.incentive_id) {
            return incentive.incentive_id
          } else {
            // use incentive title to identify auto applied incentives in old design data (when incentive_id is missing)
            return (
              window.AccountHelper.getIncentiveByTitle(incentive.title) &&
              window.AccountHelper.getIncentiveByTitle(incentive.title).id
            )
          }
        })
        .filter((id) => !isNaN(id))
      window.editor.execute(new window.SetValueCommand(system, 'incentives', overrideId))
    }
    window.editor.execute(new window.SetValueCommand(system, 'override_price_locking', value))

    //clear price lock warning
    window.WorkspaceHelper?.refreshPriceLockError(system)
  }

  handleDcOptimizer = (value, uuid) => {
    var system = window.editor.objectByUuid(uuid)
    var dcOptimizerOther = system.dcOptimizer()

    if (value === false) {
      window.editor.execute(new window.RemoveObjectCommand(dcOptimizerOther))
    } else if (value === true && !dcOptimizerOther) {
      // Use first default & non-archived match. If none, then use first archived match instead
      var optimizers = window.AccountHelper.getComponentDcOptimizerSpecsAvailable()
      var dcOptimizerOtherType = optimizers.filter((o) => !o.is_archived && o.is_default)[0] || optimizers[0]
      window.editor.execute(new window.UpdateElectricalsCommand(system, 'dcOptimizer', dcOptimizerOtherType.id))
    }

    //window.editor.signals.objectChanged.dispatch(system)
  }

  handleAutoString = (value, uuid) => {
    var system = window.editor.objectByUuid(uuid)
    if (value) {
      window.editor.execute(new window.SystemClearElectricalsCommand(system))
    }
    window.editor.execute(new window.SetValueCommand(system, 'autoString', value))
    //system.autoString = value
    // if (value) {
    //   system.clearElectricals(window.editor)
    // }
    //window.editor.signals.objectChanged.dispatch(system)
  }

  handleIsBatteryDcConnected = (value, uuid) => {
    var system = window.editor.objectByUuid(uuid)
    window.editor.execute(new window.SetValueCommand(system, 'is_battery_dc_connected_auto', false))
    window.editor.execute(new window.SetValueCommand(system, 'is_battery_dc_connected', value))
  }

  refreshPanel() {
    if (window.Designer && !window.Designer.allowUiUpdates()) {
      return
    }

    if (!window.Designer || window.Designer.allowUiUpdates()) {
      this.refreshSystemUuidsAwaitingTriggerAsync()
    }

    if (window.editor.controllers['Sequence']?.active) {
      if (this.state.visible) {
        this.setState({ visible: false })
      }
      return
    }

    if (window.AccountHelper && window.AccountHelper.isLoaded()) {
      const { system, editSystemName } = this.state
      const selectedSystem = window.editor.selectedSystem
      if (system && selectedSystem && system.uuid !== selectedSystem.uuid && editSystemName) {
        this.setState({
          editSystemName: false,
          name: '',
        })
      }
      //Try to load profile data as soon as it is ready
      if (!this.state.profileDataLoaded) {
        this.setState({
          moduleTypes: window.AccountHelper.getComponentModuleSpecsAvailable(),
          inverterTypes: window.AccountHelper.getComponentInverterSpecsAvailable(),
          batteryTypes: window.AccountHelper.getComponentBatterySpecsAvailable(),
          otherTypes: window.AccountHelper.getComponentOtherSpecsAvailable(),
          pricingSchemes: window.AccountHelper.getPricingSchemesAvailable(),
          profileDataLoaded: true,
        })
      }
    }
    if (!window.editor.selected) {
      const newState = this.stateFromObject()
      if (
        (newState.systems?.length === 0 && newState.system !== this.state.system) ||
        newState.visible !== this.state.visible
      ) {
        // re-render after deleting last system OR whenever visible changes
        this.setState(newState)
      }
    } else {
      refreshPanelGeneric.call(this, [
        'OsSystem',
        'OsInverter',
        'OsMppt',
        'OsString',
        'OsBattery',
        'OsOther',
        'ViewDummy',
      ])
    }
  }

  setSystemUuidsAwaitingTriggerDebounced = window.Utils.debounce((systemUuidsAwaitingTrigger) => {
    this.setState({
      systemUuidsAwaitingTrigger: window.ShadeHelper.systemUuidsAwaitingTrigger(),
    })
  }, 100)

  refreshSystemUuidsAwaitingTriggerAsync = () => {
    if (this.state.systemUuidsAwaitingTrigger !== window.ShadeHelper.systemUuidsAwaitingTrigger()) {
      this.setSystemUuidsAwaitingTriggerDebounced()
    }
  }

  handleSaveSystemName = (system) => {
    window.editor.execute(
      new window.SetValueCommand(system, 'name', this.state.name, window.Utils.generateCommandUUIDOrUseGlobal())
    )
    this.setState({
      editSystemName: false,
      name: '',
    })
  }

  close = (preventCancel) => {
    if (preventCancel !== true && this.state.cancelFunction) {
      if (typeof this.state.cancelFunction === 'function') {
        try {
          this.state.cancelFunction()
        } catch (e) {
          console.log('Error calling cancelFunction', e)
        }
      } else {
        console.log('this.state.cancelFunction not a function:', this.state.cancelFunction)
      }
    }

    this.setState({
      cancelFunction: null,
    })
  }

  handleTabChange = (value, paymentsTabEnabled) => {
    if (this.props.tour === 'fuji' && value === 'payment options') {
      const fujiTourQuery = getFujiTourNextStepQuery(this.props.step, paymentsTabEnabled)
      if (fujiTourQuery) {
        this.props.push({ search: fujiTourQuery })
      }
    }
    if (this.props.selectedTab !== value) {
      // this.setState({ selectedTab: value })
      this.props.setSelectedDesignTab(value)
    }
    // nothing listening to this signal, do we still need it?
    if (window.editor && window.editor.signals) {
      window.editor.signals.expansionPanelChanged.dispatch(value)
    }
  }

  handleOpenSelectComponentDialog = ({
    componentType,
    componentTypes,
    defaultFilterValues,
    defaultFilterValuesV2,
    targetUuids,
    title,
  }) => {
    const { enableHardwareDialogV2, handleOpenHardwareSelector, setSelectComponentDialog } = this.props
    if (enableHardwareDialogV2) {
      handleOpenHardwareSelector({
        componentTypes,
        defaultFilterValues: defaultFilterValuesV2,
        targetUuids,
        title,
      })
    } else
      setSelectComponentDialog(
        true,
        window.WorkspaceHelper.project.org_id,
        window.WorkspaceHelper.project.id,
        targetUuids,
        componentType,
        defaultFilterValues
      )
  }

  getDefaultSelectedTab(showTabs) {
    if (this.state.system?.output && showTabs.includes('summary')) {
      return 'summary'
    } else if (showTabs.includes('panels')) {
      return 'panels'
    } else if (showTabs.length) {
      return showTabs[0]
    } else {
      return null
    }
  }

  render() {
    var _this = this
    if (this.state.visible === false) {
      return null
    }

    const { translate, accessRightsSetting, trackEvent } = this.props
    const allowEdit = !!accessRightsSetting['design'].edit
    var {
      system,
      systems,
      currencySymbol,
      exportLimitRaw,
      generationOverrideRaw,
      selfConsumptionOverrideRaw,
    } = this.state
    const selectedTab = this.props.selectedTab || this.getDefaultSelectedTab(this.props.showTabs)
    const projectValues = this.props.formState.values
    const pricingIsLocked =
      isPricingLockedForStage(projectValues, this.props.orgWorkflows) && system?.override_price_locking !== true
    const showOtherComponentCategories =
      this.props.useFeatureFlag('electrical_slots', 'on') || this.props.useFeatureFlag('studio_tabs_mounting', 'on')
    const showMountingTab = this.props.useFeatureFlag('studio_tabs_mounting', 'on')
    const enabled_hardware_dialog_v2 = this.props.useFeatureFlag('hardware_dialog_v2', 'on')
    const enableEnphaseBomGenerator = this.props.useFeatureFlag('enphasebomgenerator', 'on')

    const paymentsTabEnabled = this.props.useFeatureFlag('payments_tab', 'on')
    const enablePvf = this.props.useFeatureFlag('pvf_integration', 'on')
    const hasShadingCalcsAwaitingTrigger = this.state.systemUuidsAwaitingTrigger.includes(system?.uuid)
    const disableTabContents = !useSystemIsEditable(system, projectValues)

    const getDisabledContentMessage = () => {
      if (disableTabContents) return 'Updating sold system is disabled during Change Order mode'
      return undefined
    }

    const otherComponentsCollection = [
      { other_component_type: 'isolator', title: 'Isolators' },
      { other_component_type: 'cable', title: 'Cables' },
      { other_component_type: 'meter', title: 'Meters' },
    ]

    if (!enabled_hardware_dialog_v2) {
      otherComponentsCollection.unshift({ other_component_type: 'dc_optimizer', title: 'DC Optimisers' })
    }

    const labelWrapperStyle = {
      minWidth: 0,
      top: 12,
      left: 0,
      right: 0,
      fontSize: '11px',
      fontWeight: 'bold',
      marginLeft: -4,
      marginRight: -4,
      paddingRight: 0,
      paddingLeft: 0,
      textTransform: 'none',
      lineHeight: '13px',
    }
    return (
      <SystemAccordion
        selectedObject={system}
        hasShadingCalcsAwaitingTrigger={hasShadingCalcsAwaitingTrigger}
        disableAddPanelButton={disableTabContents}
        content={
          system && (
            <ResponsiveAccordionContentWrapper systemLength={systems.length}>
              <PanelTabs
                hasShadingCalcsAwaitingTrigger={hasShadingCalcsAwaitingTrigger}
                selectedTab={selectedTab}
                onChange={this.handleTabChange}
              />
              <TabPanel selectedTab={selectedTab} value="summary">
                <SystemSummary
                  systems={systems}
                  system={system}
                  exportLimitRaw={exportLimitRaw}
                  updateExportLimit={(value) => this.setState({ exportLimitRaw: value })}
                  updateSelfConsumptionOverride={(value) => this.setState({ selfConsumptionOverrideRaw: value })}
                  selfConsumptionOverrideRaw={selfConsumptionOverrideRaw}
                  currencySymbol={currencySymbol}
                  systemsLoadingUuids={this.state.systemsLoadingUuids}
                  pricingIsLocked={pricingIsLocked}
                  hasShadingCalcsAwaitingTrigger={this.state.systemUuidsAwaitingTrigger.includes(system?.uuid)}
                  generationOverrideRaw={generationOverrideRaw}
                  updateGenerationOverride={(value) =>
                    this.setState({ generationOverrideRaw: valueCsvOrEmptyStringIfNull(value) })
                  }
                  allowEdit={!disableTabContents}
                />
              </TabPanel>
              <TabPanel
                selectedTab={selectedTab}
                value="panels"
                disableContents={disableTabContents}
                disableContentsMessage={getDisabledContentMessage()}
              >
                <PanelGenericWarningBox category="module" />
                <ComponentWarningBox componentIds={system.moduleGrids().map((mg) => mg.uuid)} />
                <AssociatedComponentsAlert associationType="require" filter={{ componentTypes: ['module'] }} />
                <AssociatedComponentsAlert associationType="recommend" filter={{ componentTypes: ['module'] }} />
                <div style={styles.expansionPanelDetailsContainer}>
                  <ModuleGridsList moduleGrids={system.moduleGrids()} allowSelect={allowEdit} allowDelete={allowEdit} />
                  <UiSwitch uiKey="studio.tabs.panels.select_panel_type">
                    {
                      <ComponentSelectorDropdown
                        componentType="module"
                        availableComponents={window.AccountHelper.getComponentModuleSpecsAvailable()}
                        component={system.moduleType()}
                        disabled={!allowEdit}
                        selectorConfig={{
                          systemUuids: [system.uuid],
                          componentType: 'module',
                        }}
                        select={(e) => {
                          this.handleOpenSelectComponentDialog({
                            title: 'Select Panels',
                            componentType: 'module',
                            componentTypes: ['module'],
                            targetUuids: [system.uuid],
                            defaultFilterValues: undefined,
                          })
                        }}
                      />
                    }
                  </UiSwitch>
                  <UiSwitch uiKey="studio.tabs.panels.select_panel_type">
                    <ExternalLink componentData={system.moduleType()} />
                  </UiSwitch>
                  {/* <UiSwitch uiKey="studio.tabs.panels.buildable_panels">
                    <BuildablePanels disabled={!allowEdit} system={system} />
                  </UiSwitch> */}
                  <UiSwitch uiKey="studio.tabs.panels.select_dc_optimizer">
                    {!enabled_hardware_dialog_v2 && !hasMaxeonAcPanel(system.moduleType()?.code) && (
                      <Button
                        variant="contained"
                        color="default"
                        isActive={Boolean(system.dcOptimizer())}
                        style={{
                          minWidth: 75,
                          height: 48,
                          marginTop: 10,
                          lineHeight: '25px',
                        }}
                        disabled={
                          !allowEdit || window.AccountHelper.getComponentDcOptimizerSpecsAvailable().length === 0
                        }
                        onClick={() => this.handleDcOptimizer(!system.dcOptimizer(), system.uuid)}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            transform: 'translate(-50%, -50%)',
                            top: '50%',
                            left: '50%',
                          }}
                        >
                          <span style={labelWrapperStyle}>{translate('Use DC')}</span>
                          <span style={labelWrapperStyle}>{translate('Optimiser')}</span>
                        </div>
                      </Button>
                    )}
                    {!enabled_hardware_dialog_v2 &&
                      window.AccountHelper.getComponentDcOptimizerSpecsAvailable().length === 0 &&
                      !hasMaxeonAcPanel(system.moduleType()?.code) && (
                        <div style={{ marginTop: 10 }}>
                          No DC Optimizers activated.{' '}
                          {allowEdit && (
                            <a
                              href="#"
                              onClick={(event) => {
                                event.preventDefault()

                                this.props.setStudioSavePrompt({
                                  show_prompt: true,
                                  redirect: '/component_other_activations',
                                  prompt_heading: 'Leave Studio?',
                                  prompt_message: 'Any unsaved changes will be discarded.',
                                })
                              }}
                            >
                              {translate('Manage')}
                            </a>
                          )}
                        </div>
                      )}
                    {((!enabled_hardware_dialog_v2 && system.dcOptimizer()) || system.dcOptimizerEfficiency() > 0) && (
                      <div>
                        <ComponentSelectorDropdown
                          componentType="other"
                          component={system.dcOptimizer()}
                          availableComponents={window.AccountHelper.getComponentOtherSpecsAvailable().filter(
                            (o) => o.other_component_type === 'dc_optimizer'
                          )}
                          disabled={!allowEdit}
                          select={(e) =>
                            this.handleOpenSelectComponentDialog({
                              title: 'Select Other Components',
                              componentType: 'other',
                              componentTypes: ['dc_optimizer'],
                              targetUuids: [system.uuid],
                              defaultFilterValues: [
                                { key: 'other_component_type', value: 'dc_optimizer', hidden: true },
                              ],
                              defaultFilterValuesV2: { other_component_type: 'dc_optimizer' },
                            })
                          }
                        />
                        <TextField
                          type="number"
                          disabled={true}
                          fullWidth={true}
                          label={translate('DC Optimizer Efficiency (%)')}
                          name="dcOptimizerEfficiency"
                          value={valueOrEmptyStringIfZero(system.dcOptimizerEfficiency())}
                        />
                      </div>
                    )}
                  </UiSwitch>
                  {(system.hasShadingOverride() || supportsAnyShading(system.calculator)) && (
                    <ExpansionPanelShading
                      id="ExpansionPanelShading"
                      uiKey="studio.tabs.panels.shading"
                      style={{ margin: '20px 0' }}
                      setStateParent={(_state) => this.setState(_state)}
                      object={system}
                      state={this.state}
                      allowEdit={allowEdit}
                      handleSetShadingOverride={handleSetShadingOverride.bind(this)}
                      handleAllowShadeMitigation={(value) => {
                        handleObjectChangedAsCommand(system.uuid, 'allow_shade_mitigation', value)
                      }}
                      allow_shade_mitigation={system.allow_shade_mitigation}
                      onChange={(event, expanded) => {
                        if (window.editor && window.editor.signals) {
                          window.editor.signals.expansionPanelChanged.dispatch(expanded)
                        }
                      }}
                    />
                  )}
                </div>
              </TabPanel>
              {showMountingTab && (
                <MountingTab
                  selectedTab={selectedTab}
                  containerStyle={styles.expansionPanelDetailsContainer}
                  allowEdit={allowEdit && !disableTabContents}
                  system={system}
                />
              )}
              <TabPanel selectedTab={selectedTab} value="inverters" disableContents={disableTabContents}>
                <InverterPanel system={system} allowEdit={allowEdit} panelSystem={_this} />
              </TabPanel>
              <TabPanel selectedTab={selectedTab} value="batteries" disableContents={disableTabContents}>
                <BatteryPanel
                  allowEdit={allowEdit}
                  system={system}
                  countryIso2={this.state.countryIso2}
                  panelSystem={_this}
                />
              </TabPanel>
              <TabPanel selectedTab={selectedTab} value="others" disableContents={disableTabContents}>
                {this.state.enphaseBomGeneratorOpen && enableEnphaseBomGenerator && (
                  <EnphaseBomGenerator
                    system={system}
                    handleClose={() => this.setState({ enphaseBomGeneratorOpen: false })}
                  />
                )}
                {enableEnphaseBomGenerator && checkEnableEnphaseBomGeneratorForSystem(system) && (
                  <>
                    {window.WorkspaceHelper?.project?.number_of_phases !== 2 &&
                      !systemMatchesRecommendedComponents(system) && (
                        <Alert severity="warning">
                          {translate(
                            'This system is using an Enphase inverter but it is missing some of the necessary BOM. Please click "Enphase Bill of Materials" to add the necessary items.'
                          )}
                        </Alert>
                      )}
                    {window.WorkspaceHelper?.project?.number_of_phases === 2 && (
                      <Alert severity="warning">
                        {translate('Enphase BOM Generator is not available for 2-phase systems.')}
                      </Alert>
                    )}

                    <div
                      style={{
                        margin: '0px 0px 5px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px 16px',
                        cursor: 'pointer',
                        border: '1px solid #ececec',
                        boxShadow: 'rgba(0,0,0,0.15) 0px 2px 2px',
                        opacity: window.WorkspaceHelper?.project?.number_of_phases === 2 ? 0.5 : 1.0,
                      }}
                      onClick={(e) => {
                        if (window.WorkspaceHelper?.project?.number_of_phases === 2) {
                          return
                        }
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ enphaseBomGeneratorOpen: true, enphaseBomGeneratorRan: true })
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{}}>{'Enphase Bill of Materials'}</span>
                      </div>
                      <div>
                        <LaunchIcon />
                      </div>
                    </div>
                  </>
                )}
                <AssociatedComponentsAlert associationType="require" filter={{ componentTypes: ['other'] }} />
                <AssociatedComponentsAlert associationType="recommend" filter={{ componentTypes: ['other'] }} />
                <div style={styles.expansionPanelDetailsContainer}>
                  {(!showOtherComponentCategories || this.state.reorderOtherComponents) && (
                    <ToolsOthers
                      disabled={!allowEdit}
                      system={system}
                      plusOnClick={() =>
                        this.handleOpenSelectComponentDialog({
                          componentType: 'other',
                          componentTypes: COMPONENT_TYPE_OPTIONS.map((option) => option.id),
                          targetUuids: null,
                          defaultFilterValues: null,
                          title: 'Select Other Components',
                        })
                      }
                    />
                  )}
                  <OthersPanelWarningBox showMountingTab={showMountingTab} />
                  {showOtherComponentCategories &&
                    !this.state.reorderOtherComponents &&
                    otherComponentsCollection
                      .concat(
                        showMountingTab
                          ? []
                          : [{ heading: 'Mounting' }].concat(
                              COMPONENT_TYPE_OPTIONS.filter((x) => isMountingComponent(x.id)).map((type) => ({
                                other_component_type: type.id,
                                title: type.name,
                              }))
                            )
                      )
                      .concat([
                        { other_component_type: 'ev_charger', title: 'EV Chargers' },
                        { other_component_type: 'heat_pump', title: 'Heat Pumps' },
                        { other_component_type: 'all', title: 'Other' },
                      ])
                      .map((params) => (
                        <>
                          {params.heading && <h2 style={{ fontSize: 16 }}>{params.heading}</h2>}
                          {params.other_component_type && (
                            <ComponentCollection
                              key={params.other_component_type}
                              title={translate(params.title)}
                              titleSize={params.title_size || 'medium'}
                              dialogSelectorTitle="Select Other Components"
                              systemUuid={system.uuid}
                              others={system.others()}
                              filterParams={{
                                other_component_type:
                                  params.other_component_type === 'all' ? undefined : params.other_component_type,
                              }}
                              disabled={!allowEdit}
                              componentType="other"
                            />
                          )}
                        </>
                      ))}
                  {/* Or systemwarningbox using addprojecterrortoreduxstore, but I don't know if there's button options */}
                  {(!showOtherComponentCategories || this.state.reorderOtherComponents) && (
                    <DraggableList
                      handleReorder={(oldOrder, newOrder) => {
                        window.editor.execute(new window.ReorderChildrenCommand(system, oldOrder, newOrder))
                      }}
                      list={system.others()}
                      render={(item, { dragHandle }) => (
                        <OtherBlock
                          key={item.uuid}
                          item={item}
                          disabled={!allowEdit}
                          state={this.state}
                          _this={_this}
                          dragHandle={dragHandle}
                          handleChange={(event, other) => {
                            const value = event.target.value
                            var otherKey = 'otherQuantityRaw' + other.uuid
                            var _state = {}
                            _state[otherKey] = value
                            this.setState(_state)

                            var valueAsInt = inputValueToInt(value)
                            if (valueAsInt !== 0) {
                              handleObjectChangedAsCommand(other.uuid, 'quantity', valueAsInt)
                            }
                          }}
                          handleSelect={(event, other) => {
                            if (other.selectable) {
                              this.select(other)
                            }
                          }}
                        />
                      )}
                      applyDragStyles={true}
                    />
                  )}

                  {showOtherComponentCategories && (
                    <div style={{ fontSize: 12 }}>
                      {translate('Bundled view')}
                      <Switch
                        defaultChecked={this.state.reorderOtherComponents}
                        disabled={!allowEdit}
                        onChange={(e) => {
                          _this.setState({ reorderOtherComponents: e.target.checked })
                          trackEvent(
                            EventType.USER_INTERACTION,
                            { type: ActionType.CLICK },
                            {
                              eventName: 'User Toggled Bundled/Unbundled View',
                              checked: e.target.checked,
                            }
                          )
                        }}
                      />
                      {translate('Unbundled view')}
                    </div>
                  )}
                </div>
              </TabPanel>
              {enablePvf && (
                <TabPanel selectedTab={selectedTab} value="scaffolding" disableContents={disableTabContents}>
                  <ScaffoldingEstimate />
                </TabPanel>
              )}
              <TabPanel selectedTab={selectedTab} value="pricing" disableContents={disableTabContents}>
                {pricingIsLocked && (
                  <PriceLock
                    disabled={!allowEdit}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      this.handleOverridePriceLocking(system.uuid, true)
                    }}
                  />
                )}
                <PanelGenericWarningBox category="price" />

                <PricingDetail
                  systemsLoadingUuids={this.state.systemsLoadingUuids}
                  discountRaw={this.state.discountRaw}
                  basicPriceRaw={this.state.basicPriceRaw}
                  basicPriceRawPerWatt={this.state.basicPriceRawPerWatt}
                  taxOverrideHardwareRaw={this.state.taxOverrideHardwareRaw}
                  taxOverrideNonHardwareRaw={this.state.taxOverrideNonHardwareRaw}
                  addersPerPanelRaw={this.state.addersPerPanelRaw}
                  addersPerWattRaw={this.state.addersPerWattRaw}
                  nonSolarPriceIncludedRaw={this.state.nonSolarPriceIncludedRaw}
                  nonSolarProjectType={this.state.nonSolarProjectType}
                  addersPerSystemRaw={this.state.addersPerSystemRaw}
                  updateFieldState={(field, value) => this.setState({ [field]: value })}
                  system={system}
                  systems={this.state.systems}
                  panelSystem={_this}
                  pricingSchemes={this.state.pricingSchemes}
                  currencySymbol={this.state.currencySymbol}
                  countryIso2={this.state.countryIso2}
                />
              </TabPanel>
              <TabPanel selectedTab={selectedTab} value="incentives" disableContents={disableTabContents}>
                <Incentives pricingIsLocked={pricingIsLocked} system={system} panelSystem={_this} />
              </TabPanel>
              {!paymentsTabEnabled && (
                <TabPanel selectedTab={selectedTab} value="payment options" disableContents={disableTabContents}>
                  <PaymentOptions
                    system={system}
                    systemsLoadingUuids={this.state.systemsLoadingUuids}
                    systems={this.state.systems}
                    currencySymbol={this.state.currencySymbol}
                    togglePaymentOptionOverrides={togglePaymentOptionOverrides}
                    pricingIsLocked={pricingIsLocked}
                    handleAddPaymentOptionOverride={handleAddPaymentOptionOverride}
                    handleRemovePaymentOptionOverride={handleRemovePaymentOptionOverride}
                    countryIso2={this.state.countryIso2}
                  />
                </TabPanel>
              )}
            </ResponsiveAccordionContentWrapper>
          )
        }
      />
    )
  }
}

const PanelSystemWrapper = (props) => {
  const { trackEvent } = useTrackComponent({
    componentKey: 'panel_system',
    eventName: 'Panel system actions',
    description: 'User interacted with panel system actions',
  })

  return <PanelSystem {...props} trackEvent={trackEvent} />
}

PanelSystemWrapper.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withFeatureFlag,
  withStudioSignals,
  withForm,
  withHardwareSelectorV2,
  connect(
    (state) => {
      var orgCountryIso2 = getOrgCountryIso2FromState(state)
      var role = getRoleFromState(state)
      const orgWorkflows = state?.orgs?.workflows

      return {
        theme: state.theme,
        accessRightsSetting: permissionsSelectors.getProjectPermissionsSetting(state),
        tour: state.tour?.tour,
        step: state.tour?.step,
        isAdmin: role && role.is_admin === true,
        roleId: role?.id,
        currencySymbol: currencySymbolForCountry(orgCountryIso2),
        selectedTab: designViewSelectors.selectedTab(state),
        orgWorkflows: orgWorkflows,
      }
    },
    {
      push: pushAction,
      setSelectComponentDialog: setSelectComponentDialogAction,
      setStudioSavePrompt: setStudioSavePromptAction,
      setSelectedDesignTab: setSelectedDesignTab,
    }
  )
)(PanelSystemWrapper)
