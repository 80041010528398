import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { createStyles, withStyles } from '@material-ui/core/styles'
import { ErrorOutlineOutlined } from '@material-ui/icons'
import TuneIcon from '@material-ui/icons/TuneOutlined'
import Tooltip from 'elements/tooltip/Tooltip'
import { UiSwitch } from 'elements/UiSwitch'
import { Switch } from 'opensolar-ui'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import compose from 'recompose/compose'
import withStudioSignals, { WithStudioSignalsProps } from 'Studio/signals/withStudioSignals'
import { OpenSolarTheme } from 'Themes'
import { closablePanels } from 'util/closablePanels'
import { handleRestart } from '../../util/handleRestart'
import { ToolbarDesignModeName } from '../designMode/ToolbarDesignMode'
import ToolbarSelector from '../ToolbarSelector'
import DrawingToolSelectItem, { HotKeys } from './DrawingToolSelectItem'
import tool_aframe from './images/tool_aframe.svg'
import tool_dormer_aframe from './images/tool_dormer_aframe.svg'
import tool_dormer_hip from './images/tool_dormer_hip.svg'
import tool_dormer_shed from './images/tool_dormer_shed.svg'
import tool_hip from './images/tool_hip.svg'
import tool_shed from './images/tool_shed.svg'

const classStyles = (theme: any) =>
  createStyles({
    divider: { fontWeight: 400, margin: 0, padding: '10px 0', background: 'rgba(255, 255, 255, 0.9)' },
    wrapper: {
      position: 'absolute',
      width: 230,
      top: 40,
      right: 0,
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 160px)',
      textAlign: 'center',
      background: 'rgba(255, 255, 255, 0.9)',
      [theme.breakpoints.down('sm')]: {
        top: 'unset',
        position: 'absolute',
        width: 230,
        bottom: 40,
        right: 0,
        textAlign: 'center',
        background: 'rgba(255, 255, 255, 0.9)',
      },
    },
    showGuidesContainer: {
      backgroundColor: OpenSolarTheme.white,
      display: 'flex',
      padding: '0px 10px 5px 10px',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: OpenSolarTheme.typography.button.fontSize,
    },
    flexWithAlignCenteredItems: {
      display: 'flex',
      alignItems: 'center',
    },
    warningIcon: {
      width: 20,
      height: 20,
      color: OpenSolarTheme.alertIcon_warning,
      marginRight: 5,
    },
  })

type ClassesTypes = {
  divider: string
  wrapper: string
  showGuidesContainer: string
  warningIcon: string
  flexWithAlignCenteredItems: string
}

type PropTypes = WithStudioSignalsProps & {
  classes: ClassesTypes
  handleClick: Function
  state?: StateTypes
  disabled: boolean
  translate: (term: string) => string
}
type StateTypes = {
  isOpen: boolean
  enableToolbarAdvanced: boolean
  cancelFunction: null | Function
  activeButton: null | string
  enableDrawingTools?: boolean
  enableDrawOther3D?: boolean
  propsDisabled?: boolean
  editorGuidesVisible?: boolean
}

const STRUCTURE_OPTIONS = [
  { key: 'Structure A-frame', label: 'A-frame', icon: tool_aframe, shortcutKeys: ['Q'] },
  { key: 'Structure Hip', label: 'Hip', icon: tool_hip, shortcutKeys: ['Q,Q'] },
  { key: 'Structure Shed', label: 'Shed', icon: tool_shed, shortcutKeys: ['Q,Q,Q'] },
]

const DORMER_OPTIONS = [
  { key: 'Dormer A-frame', label: 'A-frame', icon: tool_dormer_aframe, shortcutKeys: ['D'] },
  { key: 'Dormer Hip', label: 'Hip', icon: tool_dormer_hip, shortcutKeys: ['D,D'] },
  { key: 'Dormer Shed', label: 'Shed', icon: tool_dormer_shed, shortcutKeys: ['D,D,D'] },
]

//Removed: 'Tree (T)'
const getDrawingToolButtons = (enableDrawingTools: boolean, enableDrawOther3D: boolean) => {
  //Format: key:label, value:enabled/disabled
  return [
    { key: 'Wire (W)', label: 'Wire', enabled: enableDrawingTools, shortcutKeys: ['W'] },
    { key: 'Annotation (N)', label: 'Annotation', enabled: enableDrawingTools, shortcutKeys: ['N'] },
    { key: 'Tree (T)', label: 'Tree', enabled: enableDrawOther3D, shortcutKeys: ['T'] },
    { key: 'Obstruction (O)', label: 'Obstruction', enabled: enableDrawOther3D, shortcutKeys: ['O'] },
    { key: 'Tree Trimmer (I)', label: 'Tree Trimmer', enabled: enableDrawOther3D, shortcutKeys: ['I'] },
    {
      key: 'Roof (R)',
      label: 'Roof',
      enabled: enableDrawingTools,
      shortcutKeys: ['R'],
    },
  ]
}

const DrawingToolSelectItemDownloadDxf = ({ close }) => {
  return (
    <DrawingToolSelectItem
      id={'download-dfx-button'}
      disabled={!window.editor.extensions.DxfExporter.isAvailable()}
      onClick={(e) => {
        e.preventDefault()
        window.editor.extensions.DxfExporter.generateAndDownload()
        close()
      }}
      label="Download DXF (AutoCAD)"
    />
  )
}
export const ToolbarDrawingToolsName = 'ToolbarDrawingTools'

class ToolbarDrawingTools extends Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props)
    this.state = props.state
      ? props.state
      : {
          isOpen: false,
          enableToolbarAdvanced: props.disabled ? !props.disabled : true,
          propsDisabled: props.disabled ? props.disabled : false,
          activeButton: null,
          cancelFunction: null,
          editorGuidesVisible: window.editor?.getDesignGuidesVisibility() || true,
        }
  }

  componentDidMount() {
    if (window.Designer) {
      window.Designer.ToolbarDrawingTools = this
    } else {
      console.log('Warning: window.Designer not available, unable to link ToolbarDrawingTools')
    }

    closablePanels.add(ToolbarDrawingToolsName, this.close, this)

    this.props.useStudioSignalsLazy(
      this.refreshPanel,
      [
        'viewsChanged',
        'sceneLoaded',
        'sceneGraphChanged',
        'objectChanged',
        'objectSelected',
        'systemSelected',
        'controlModeChanged',
        'displayModeChanged',
        'mapChanged',
        'designGuidesVisibilityChanged',
      ],
      this
    )
  }

  componentWillUnmount(): void {
    closablePanels.remove(ToolbarDrawingToolsName)

    if (window.Designer) window.Designer.ToolbarDrawingTools = undefined
  }

  refreshPanel() {
    if (!window.Designer || window.Designer.allowUiUpdates()) {
      setTimeout(() => this.refreshPanelAsync(), 1)
    }
  }

  refreshPanelAsync() {
    const _enableToolbarAdvanced =
      !this.state.propsDisabled && window.editor.getSystems().length > 0 && window.editor.displayMode === 'interactive'

    const _enableDrawingTools = window.ViewHelper.enableDrawingTools()
    const _enableDrawOther3D = window.ViewHelper.enableDrawOther3D()
    const _editorGuidesVisible = window.editor.getDesignGuidesVisibility()

    if (
      this.state.enableDrawingTools !== _enableDrawingTools ||
      this.state.enableDrawOther3D !== _enableDrawOther3D ||
      this.state.enableToolbarAdvanced !== _enableToolbarAdvanced ||
      this.state.editorGuidesVisible !== _editorGuidesVisible
    ) {
      this.setState({
        enableDrawingTools: _enableDrawingTools,
        enableDrawOther3D: _enableDrawOther3D,
        enableToolbarAdvanced: _enableToolbarAdvanced,
        editorGuidesVisible: _editorGuidesVisible,
      })
    }
  }

  handleClick = (label: string) => {
    console.log('handleClick(e) label:', label)

    if (this.props.handleClick) {
      this.props.handleClick(label)
    }
    //switch off advanced settings dropdown after selecting the option
    this.close()
  }

  open = () => {
    // Be sure to call handleCloseOtherMenus before setState because otherwise
    // `this` is corrupted with and old version from before setState
    // this.props.handleCloseOtherMenus(this)
    // closablePanels.closeAllExcept(ToolbarDrawingToolsName)
    closablePanels.close(ToolbarDesignModeName)
    this.setState({ isOpen: true })
  }

  close = (preventCancel?: boolean) => {
    if (preventCancel !== true && this.state.cancelFunction) {
      if (typeof this.state.cancelFunction === 'function') {
        try {
          this.state.cancelFunction()
        } catch (e) {
          console.log('Error calling cancelFunction', e)
        }
      } else {
        console.log('this.state.cancelFunction not a function:', this.state.cancelFunction)
      }
    }

    this.setState({
      isOpen: false,
      activeButton: null,
      cancelFunction: null,
    })
  }

  handleDropdown = () => {
    // AP: Not sure why but sometimes when button is clicked `this` is populated
    // but other times (especially when we just finished painting panels)
    // it is undefined. Use _this to be safe.
    if (this.state.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  onShowGuidesToggled = (visible: boolean) => {
    if (!window.editor) return
    window.editor.setDesignGuidesVisibility({
      visible: visible,
      renderWhenDone: true,
      showNotifWhenDone: true,
      includeAnnotations: true,
      includeMeasurements: true,
    })
  }

  render() {
    var _this = this

    const { enableToolbarAdvanced, isOpen, enableDrawingTools, enableDrawOther3D, editorGuidesVisible } = this.state
    const { classes, translate } = this.props
    const hasFacets = window.editor.filter('type', 'OsFacet').length > 0
    return (
      <>
        <ToolbarSelector
          id="advanced-tools-button"
          disabled={!enableToolbarAdvanced}
          label="Advanced"
          icon={<TuneIcon />}
          dropdownOpen={isOpen}
          onClick={_this.handleDropdown}
          displayWarning={!editorGuidesVisible}
        />

        {enableToolbarAdvanced && isOpen && (
          <Paper data-testid="advanced-tools" classes={{ root: classes.wrapper }}>
            {getDrawingToolButtons(!!enableDrawingTools, !!enableDrawOther3D)
              .reverse()
              .map((buttonItem) => (
                <DrawingToolSelectItem
                  id={'ButtonAdd' + buttonItem.key}
                  key={buttonItem.key}
                  shortcutKeys={buttonItem.shortcutKeys}
                  disabled={Boolean(
                    !buttonItem.enabled || (this.state.activeButton && this.state.activeButton !== buttonItem.label)
                  )}
                  onClick={() => this.handleClick(buttonItem.key)}
                  label={buttonItem.label}
                />
              ))}
            <h3 className={classes.divider}>{translate('Quick Roof')}</h3>
            {STRUCTURE_OPTIONS.map((structure) => (
              <DrawingToolSelectItem
                id={'ButtonAdd' + structure.key}
                key={structure.key}
                //@ts-ignore
                icon={structure.icon}
                shortcutKeys={structure.shortcutKeys}
                disabled={
                  !enableDrawingTools || Boolean(this.state.activeButton && this.state.activeButton !== structure.label)
                }
                onClick={() => this.handleClick(structure.key)}
                label={structure.label}
              />
            ))}
            <h3 className={classes.divider}>{translate('Dormer')}</h3>
            {DORMER_OPTIONS.map((dormer) => (
              <DrawingToolSelectItem
                id={'ButtonAdd' + dormer.key}
                key={dormer.key}
                shortcutKeys={dormer.shortcutKeys}
                //@ts-ignore
                icon={dormer.icon}
                disabled={
                  !enableDrawingTools ||
                  !hasFacets ||
                  Boolean(this.state.activeButton && this.state.activeButton !== dormer.label)
                }
                onClick={() => this.handleClick(dormer.key)}
                label={dormer.label}
              />
            ))}
            <UiSwitch uiKey="studio.advanced.DesignGuides">
              <h3 className={classes.divider}>{translate('View')}</h3>
              <div className={classes.showGuidesContainer}>
                <div className={classes.flexWithAlignCenteredItems}>
                  {!this.state.editorGuidesVisible && (
                    <Tooltip
                      title={<span> {translate('There are hidden objects in the design.')}</span>}
                      style={{ padding: 0 }}
                    >
                      <ErrorOutlineOutlined classes={{ root: classes.warningIcon }} />
                    </Tooltip>
                  )}
                  {translate('Design Guides')}
                </div>
                <div className={classes.flexWithAlignCenteredItems}>
                  <Switch
                    checked={this.state.editorGuidesVisible}
                    onChange={(_event, checked) => {
                      this.onShowGuidesToggled(checked)
                    }}
                  />
                  <HotKeys shortcutKeys={['E']} />
                </div>
              </div>
            </UiSwitch>
            <Divider />
            <UiSwitch uiKey="studio.advanced.Restart">
              <DrawingToolSelectItem
                id={'restart-button'}
                onClick={(e) => {
                  e.preventDefault()
                  handleRestart()
                  this.close()
                }}
                label="Restart Design"
              />
            </UiSwitch>
            <UiSwitch uiKey="studio.advanced.Recalculate">
              <DrawingToolSelectItem
                id={'recalculate-button'}
                onClick={() => {
                  window.editor.filter('type', 'OsSystem').forEach((system: any) => {
                    window.Designer.requestSystemCalculations(system)
                  }, this)
                }}
                label="Recalculate"
              />
            </UiSwitch>
            <UiSwitch uiKey="studio.advanced.DownloadDxf">
              <DrawingToolSelectItemDownloadDxf close={this.close} />
            </UiSwitch>
          </Paper>
        )}
      </>
    )
  }
}

export default compose(
  withTranslate,
  withStudioSignals,
  withStyles(classStyles, { name: 'toolbar-advanced' })
  //@ts-ignore
)(ToolbarDrawingTools)
