// in src/users.js
import { makeStyles } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import { ControlBreadcrumbLink, ListBreadCrumbs } from 'elements/BreadCrumbs'
import DependentInput from 'elements/input/DependentInput'
import { List } from 'elements/react-admin/List'
import TrackedToolbar from 'elements/TrackedToolbar'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  NotFound,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'

const inputStyle = {
  width: 256,
}

const useStyles = makeStyles({
  description: {
    marginLeft: 20,
    color: '#949494',
    marginBottom: 20,
    maxWidth: 400,
  },
})

export const TagList = (props) => {
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getPermissionByKey('business_process')
  )

  const breadcrumbs = [
    ControlBreadcrumbLink,
    { title: 'Business Process', link: '/business-process' },
    { title: 'Project Tags' },
  ]

  if (!allowView) {
    return <NotFound />
  }
  return (
    <List breadCrumbs={<ListBreadCrumbs links={breadcrumbs} />} {...props} hasCreate={allowCreate}>
      <Datagrid>
        <TextField source="title" />
        <BooleanField source="allow_fetch" textAlign={'center'} />
        {
          <FunctionField
            source={null}
            label="Actions"
            render={(record, source, rest) => (
              <div>
                {allowEdit && <EditButton useListStyle={true} record={record} basePath={rest.basePath} />}
                {allowDelete && (
                  <DeleteButton
                    useListStyle={true}
                    undoable={false}
                    record={record}
                    resource={rest.resource}
                    redirect={`/${rest.resource}`}
                  />
                )}
              </div>
            )}
            sortable={false}
          />
        }
      </Datagrid>
    </List>
  )
}

const AllowFetchToggle = () => {
  const form = useForm()
  const handleChange = (newValue) => {
    if (newValue === true) {
      form.batch(() => {
        form.change('create_activity_when_fetched', true)
        form.change('remove_tag_when_fetched', true)
      })
    }
  }
  return <BooleanInput source="allow_fetch" resource="tags" onChange={handleChange} />
}

export const TagEdit = (props) => {
  const translate = useTranslate()
  const classes = useStyles()

  const { allowView, allowEdit, allowDelete } = useSelector(permissionsSelectors.getPermissionByKey('business_process'))

  if (!allowView) {
    return <NotFound />
  }
  return (
    <Edit {...props} hasDelete={allowDelete}>
      <SimpleForm
        formatSubmitValues={formatSubmitValues}
        validate={(values, form) => validateTitle(values, form, translate)}
        toolbar={<TrackedToolbar {...props} />}
        disabled={!allowEdit}
      >
        <TextInput source="title" style={inputStyle} />

        <AllowFetchToggle />
        <div className={classes.description}>
          {translate(
            'Turning this ON allows Pros to automatically take a project from a queue of projects that have this tag added.'
          )}
        </div>
        <DependentInput dependsOn="allow_fetch" value={true}>
          <>
            <BooleanInput source="remove_tag_when_fetched" />
            <div className={classes.description}>
              {translate(
                'Turning this ON will mean that this tag is removed from the project when it is fetched so it removed from the queue.'
              )}
            </div>
            <BooleanInput source="create_activity_when_fetched" />
            <div className={classes.description}>
              {translate(
                'Turning this ON will mean that an activity is automatically created for the Pro that fetched a project with this tag so they can mark it as complete when done.'
              )}
            </div>
          </>
        </DependentInput>
      </SimpleForm>
    </Edit>
  )
}

const formatSubmitValues = (values) => {
  if (!values.allow_fetch) {
    values.remove_tag_when_fetched = false
    values.create_activity_when_fetched = false
  }
  return values
}

export const TagCreate = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm
        formatSubmitValues={formatSubmitValues}
        validate={(values, form) => validateTitle(values, form, translate)}
        toolbar={<TrackedToolbar {...props} />}
      >
        <TextInput source="title" style={inputStyle} />
        <AllowFetchToggle />
        <div className={classes.description}>
          {translate(
            'Turning this ON allows Pros to automatically take a project from a queue of projects that have this tag added.'
          )}
        </div>
        <DependentInput dependsOn="allow_fetch" value={true}>
          <>
            <BooleanInput source="remove_tag_when_fetched" />
            <div className={classes.description}>
              {translate(
                'Turning this ON will mean that this tag is removed from the project when it is fetched so it removed from the queue.'
              )}
            </div>
            <BooleanInput source="create_activity_when_fetched" />
            <div className={classes.description}>
              {translate(
                'Turning this ON will mean that an activity is automatically created for the Pro that fetched a project with this tag so they can mark it as complete when done.'
              )}
            </div>
          </>
        </DependentInput>
      </SimpleForm>
    </Create>
  )
}

const validateTitle = (values, _, translate) => {
  const errors = {}
  if (!values.title) {
    errors.title = [translate('Title is required.')]
  }
  return errors
}
