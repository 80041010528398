import { authSelectors } from 'ducks/auth'
import { orgsActions } from 'ducks/orgs'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  styled,
  TextField,
  VisibilityOffOutlineIcon,
  VisibilityOutlineIcon,
} from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { doNotTranslate } from 'util/misc'

const ErrorText = styled('p')({
  color: 'red',
  width: '100%',
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  margin: '20px',
})

const CenterText = styled('div')({
  textAlign: 'center',
})

type PropTypes = {
  isOpen: boolean
  close: () => void
}

const SolarAppAccountDialog: React.FunctionComponent<PropTypes> = (props) => {
  const notify = useNotify()
  const [loading, setLoading] = useState<boolean>(false)

  const [clientIdErrorMsg, setClientIdErrorMsg] = useState<string | undefined>(undefined)
  const [clientSecretErrorMsg, setClientSecretErrorMsg] = useState<string | undefined>(undefined)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [clientID, setClientID] = useState<string | undefined>(undefined)
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined)

  const [showClientSecret, setShowClientSecret] = useState<boolean>(false)

  const dispatch = useDispatch()
  const orgId = useSelector(authSelectors.getOrgId)

  const save = () => {
    setLoading(true)
    setErrorMsg(undefined)
    setClientIdErrorMsg(undefined)
    setClientSecretErrorMsg(undefined)
    const restClientInstance = restClient(window.API_ROOT + '/api')

    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/connect_solarapp/`,
      data: {
        client_id: clientID,
        client_secret: clientSecret,
      },
    })
      .then((response: any) => {
        if (response?.data) {
          dispatch(orgsActions.reloadOrg())
          if (response?.data?.message) notify(response?.data?.message, 'success')
          props.close()
        }
      })
      .catch(async (error: any) => {
        let errorMsg = doNotTranslate(
          'An error occurred while connecting your SolarAPP+ account. Please try again later.'
        )

        const errorResponse = error?.body
        if (errorResponse) {
          if (errorResponse?.message) {
            errorMsg = errorResponse.message
          }
          if (errorResponse?.errors) {
            if (errorResponse.errors.client_id) {
              setClientIdErrorMsg(errorResponse.errors.client_id)
            }
            if (errorResponse.errors.client_secret) {
              setClientSecretErrorMsg(errorResponse.errors.client_secret)
            }
          }
          if (errorResponse?.error_code) {
            console.error('SolarAPP+ error_code:', errorResponse.error_code)
          } else {
            console.error(error)
          }
        } else {
          console.error('Network or unknown error:', error)
        }
        setErrorMsg(errorMsg)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog open={props.isOpen} onClose={props.close}>
      <DialogTitle>{doNotTranslate('Connect your SolarAPP+ Account')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>
              {doNotTranslate(
                'Enter your SolarAPP+ API credentials below to connect your account. You can find these credentials within your SolarAPP+ account via the "API Token" page within the profile menu dropdown, provided you have the correct '
              )}
              <Link href="https://help.solar-app.org/article/355-user-management-installer-roles" target="_blank">
                {doNotTranslate('permissions within SolarAPP+')}
              </Link>
              {doNotTranslate('.')}
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="solarapp-client-id"
              label={doNotTranslate('SolarAPP+ Client ID')}
              fullWidth={true}
              value={clientID}
              error={!!clientIdErrorMsg}
              required
              onChange={(e) => setClientID(e.target.value)}
            />
            {clientIdErrorMsg && <ErrorText>{clientIdErrorMsg}</ErrorText>}
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="solarapp-client-secret"
              label={doNotTranslate('SolarAPP+ Client Secret')}
              fullWidth={true}
              error={!!clientSecretErrorMsg}
              required
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              type={showClientSecret ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowClientSecret(!showClientSecret)}>
                      {showClientSecret ? <VisibilityOutlineIcon /> : <VisibilityOffOutlineIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {clientSecretErrorMsg && <ErrorText>{clientSecretErrorMsg}</ErrorText>}
          </Grid>
          {errorMsg && (
            <Grid item xs={12}>
              <ErrorText>
                <CenterText>{errorMsg}</CenterText>
              </ErrorText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonWrapper>
          <ProUXButton type="secondary" label="Cancel" onClick={props.close} />
          <ProUXButton
            type="primary"
            label="Submit"
            disabled={loading || !clientID || !clientSecret}
            onClick={save}
            loading={loading}
          />
        </ButtonWrapper>
      </DialogActions>
    </Dialog>
  )
}
export default SolarAppAccountDialog
