var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { jssPreset, StylesProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider } from '@material-ui/styles';
import { jssPreset as Mui5jssPreset, StylesProvider as Mui5StylesProvider } from '@mui/styles';
import { create } from 'jss';
import { ComponentVersionsStoredRoot } from './ComponentVersionsContext';
import { ThemeContext as OpenSolarThemeContext } from './ThemeContext';
var createMui4ThemeContext = function (_a) {
    var document = _a.document;
    return function (_a) {
        var children = _a.children;
        return (_jsx(StylesProvider
        // @ts-ignore
        , { 
            // @ts-ignore
            jss: create(__assign(__assign({}, jssPreset()), { 
                // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
                // @ts-ignore
                insertionPoint: document.head.firstChild })), children: _jsx(ThemeProvider
            // TODO: move mui4 theme to a separate file in opensolar-ui and then re-use it here
            , { 
                // TODO: move mui4 theme to a separate file in opensolar-ui and then re-use it here
                theme: createMuiTheme({
                    overrides: {
                        MuiButton: {
                            root: {
                                marginLeft: 0,
                                boxSizing: 'border-box',
                                height: 40,
                                background: '#FFD800',
                                fontWeight: 400,
                                lineHeight: '16px',
                                padding: '10.5px 15px',
                                '&$disabled': {
                                    color: '#B4B4B4',
                                    backgroundColor: '',
                                    background: '#FFFFFF',
                                },
                            },
                        },
                    },
                }), children: children }) }));
    };
};
var createMui5ThemeContext = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var jss, cache;
    var document = _b.document;
    return __generator(this, function (_c) {
        jss = create(__assign(__assign({}, Mui5jssPreset()), { 
            // @ts-ignore
            insertionPoint: document.head }));
        cache = createCache({
            key: 'css',
            prepend: true,
            container: document.head,
            speedy: false, // <--- key setting to make it work
        });
        return [2 /*return*/, function (_a) {
                var children = _a.children;
                return (_jsx(Mui5StylesProvider, { jss: jss, children: _jsx(CacheProvider, { value: cache, children: _jsx(OpenSolarThemeContext, { children: _jsxs(ComponentVersionsStoredRoot, { children: [children, " "] }) }) }) }));
            }];
    });
}); };
/**
 * Targeting the correct styling context inside the canvas iframe
 */
export var createThemeContextByDocument = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var Mui4ThemeContext, Mui5ThemeContext;
    var document = _b.document;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                Mui4ThemeContext = createMui4ThemeContext({ document: document });
                return [4 /*yield*/, createMui5ThemeContext({ document: document })];
            case 1:
                Mui5ThemeContext = _c.sent();
                return [2 /*return*/, function (_a) {
                        var children = _a.children;
                        return (_jsx(Mui4ThemeContext, { children: _jsx(Mui5ThemeContext, { children: children }) }));
                    }];
        }
    });
}); };
