import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button, PlusIcon, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { ActionType, EventType } from 'types/tracking'
import { FilterField } from './fields'

const AddFilterBtn = styled(Button)({
  marginTop: 10,
})

const ColumnWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const SuggestedFilterSection = () => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const filtersList: FilterField[] = useMemo(() => formValues.filters, [formValues.filters])
  const { trackEvent } = useTrackComponent({
    componentKey: 'suggested_filter_section',
    eventName: 'User Sorted and Filtered Projects',
  })

  const suggestedFilterList = [
    { fieldId: 'assigned', value: 'Assigned' },
    { fieldId: 'lead_source', value: 'Lead Sources' },
    { fieldId: 'recentlyViewed', value: 'Recently Viewed' },
    { fieldId: 'starred', value: 'Starred' },
    { fieldId: 'tags', value: 'Tags' },
  ]
  const checkFilter = (fieldId) => !filtersList.some((filter) => filter.fieldId === fieldId)
  return (
    <ColumnWrapper>
      {suggestedFilterList
        ?.filter((suggestedFilter) => checkFilter(suggestedFilter.fieldId))
        .map((suggestedFilter) => (
          <div key={suggestedFilter.fieldId}>
            <AddFilterBtn
              startIcon={<PlusIcon width="12" height="12" />}
              variant={'contained'}
              color={'secondary'}
              onClick={() => {
                trackEvent(
                  EventType.USER_INTERACTION,
                  { type: ActionType.CLICK },
                  { selectedOption: suggestedFilter, filterSource: 'suggested' }
                )
                form.change('filters', [...filtersList, { fieldId: suggestedFilter.fieldId, value: {} }])
              }}
            >
              {translate(suggestedFilter.value)}
            </AddFilterBtn>
          </div>
        ))}
    </ColumnWrapper>
  )
}

export default SuggestedFilterSection
