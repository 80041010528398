import appStorage, { clear } from 'storage/appStorage'

const PROPOSAL_V2_APP_URL_OVERRIDE_KEY = 'proposal_v2_app_url_override'

export const getProposalV2AppUrlOverride = () => {
  return appStorage.getString(PROPOSAL_V2_APP_URL_OVERRIDE_KEY)
}

export const toggleDebugAppUrlByCurrentEnvironment = (setOverride) => {
  if (setOverride) {
    const overrideUrl = `${window.PROPOSAL_V2_BASE_URL}/v1/`
    appStorage.setString(PROPOSAL_V2_APP_URL_OVERRIDE_KEY, overrideUrl)
  } else {
    clear(PROPOSAL_V2_APP_URL_OVERRIDE_KEY)
  }
}
