import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ActionHide from '@material-ui/icons/HighlightOff';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import React from 'react';
var emptyRecord = {};
var useStyles = makeStyles(function (theme) { return ({
    body: { display: 'flex', alignItems: 'center', flexWrap: 'wrap', minHeight: 56 },
    spacer: { width: theme.spacing(2) },
    hideButton: { padding: 5 },
}); }, { name: 'RaFilterFormInput' });
var FilterFormInput = function (props) {
    var filterElement = props.filterElement, handleHide = props.handleHide, resource = props.resource, variant = props.variant, margin = props.margin;
    var translate = useTranslate();
    var classes = useStyles(props);
    return (_jsxs("div", { "data-source": filterElement.props.source, className: classnames('filter-field', classes.body), children: [!filterElement.props.alwaysOn && (_jsx(IconButton, { className: classnames('hide-filter', classes.hideButton), onClick: handleHide, "data-key": filterElement.props.source, title: translate('ra.action.remove_filter'), children: _jsx(ActionHide, {}) })), React.cloneElement(filterElement, {
                allowEmpty: filterElement.props.allowEmpty === undefined ? true : filterElement.props.allowEmpty,
                resource: resource,
                record: emptyRecord,
                variant: variant,
                margin: margin,
                helperText: false,
                // ignore defaultValue in Field because it was already set in Form (via mergedInitialValuesWithDefaultValues)
                defaultValue: undefined,
            }), _jsx("div", { className: classes.spacer, children: "\u00A0" })] }));
};
FilterFormInput.propTypes = {
    filterElement: PropTypes.node,
    handleHide: PropTypes.func,
    classes: PropTypes.object,
    resource: PropTypes.string,
};
export default FilterFormInput;
