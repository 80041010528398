import { Card, Theme, Tooltip, useMediaQuery } from '@material-ui/core'
import { AccountCircleOutlined } from '@material-ui/icons'
import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import useTranslateParse from 'util/useTranslateParse'
import { CONTACT_FIELD_MAP } from '../constants'
import { FieldPrefixFilterType, FormRouteInfoPropTypes } from '../types'
import { contactIsNotEmpty, getContactsAreEqual, getNormalizeBlueSnapContact } from '../utils'

interface StyledProps {
  isMobile?: boolean
  isCardSelected?: boolean
  isCardDisabled?: boolean
}

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: 10,
  marginBottom: 16,
})

const StyledCard = styled(Card)<StyledProps>(({ theme, isCardSelected, isCardDisabled }) => ({
  flex: 1,
  padding: '1rem',
  borderRadius: '8px',
  cursor: isCardDisabled ? 'default' : 'pointer',
  color: isCardDisabled ? theme.palette.grey[500] : 'inherit',
  border: isCardSelected ? '2px solid #4272DD !important' : 'none',
}))

const Row = styled('div')<StyledProps>(({ isMobile }) => ({
  display: 'flex',
  gap: isMobile ? '10px' : '16px',
  paddingBottom: '1rem',
  flexFlow: isMobile ? 'column' : 'row',
}))

const CardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  margin: 0,
})

const FormHeader = styled('div')({
  borderTop: '1px solid #DADBE9',
  paddingTop: 25,
  marginTop: 15,
  paddingBottom: 15,
})

const ContactDescription = styled('div')({
  margin: '0 0 0 35px',
})

const PersonInfoTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const formState = useFormState()
  const form = useForm()

  const [ownerIndexToShow, setOwnerIndexToShow] = useState<number>(0)
  const [ownerFieldToShow, setOwnerFieldToShow] = useState<FieldPrefixFilterType>(null)
  const [hideContent, setHideContent] = useState<boolean>(false)

  const OWNER_FIELD_PREFIX = {
    0: 'ownershipInfoArr[0]',
    1: 'ownershipInfoArr[1]',
    2: 'ownershipInfoArr[2]',
    3: 'ownershipInfoArr[3]',
  }

  const companyRep = formState?.values?.companyRep
  const owner1 = formState?.values?.ownershipInfoArr?.[0]
  const owner2 = formState?.values?.ownershipInfoArr?.[1]
  const owner3 = formState?.values?.ownershipInfoArr?.[2]
  const owner4 = formState?.values?.ownershipInfoArr?.[3]

  useEffect(() => {
    let indexToShow = formState.values?.ownershipInfoArr?.length ? formState.values?.ownershipInfoArr?.length : 0
    let prevIndex = 0

    if (indexToShow > 0 && prevIndex < 4) {
      while (prevIndex !== indexToShow) {
        const checkPrevIndex = contactIsNotEmpty(formState.values.ownershipInfoArr[prevIndex])
        !checkPrevIndex ? (indexToShow = prevIndex) : prevIndex++
      }
    }

    setOwnerIndexToShow(indexToShow)
    setOwnerFieldToShow(OWNER_FIELD_PREFIX[indexToShow])
  }, [])

  useEffect(() => {
    if (props.fieldPrefixFilter && props.fieldPrefixFilter?.startsWith('ownershipInfoArr')) {
      setHideContent(true)
    }
  }, [])

  const repIsUnique = useMemo(() => {
    if (!companyRep) return false
    else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner1))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner2))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner3))) {
      return false
    } else if (getContactsAreEqual(getNormalizeBlueSnapContact(companyRep), getNormalizeBlueSnapContact(owner4))) {
      return false
    }
    return true
  }, [])

  const onChangeContactType = (type: 'owner' | 'rep') => {
    if (type === 'owner') {
      props.setFieldPrefixFilter(ownerFieldToShow)
      const newOwnerFields = {}
      if (formState.values.companyRep && !repIsUnique) {
        CONTACT_FIELD_MAP?.forEach((field) => {
          newOwnerFields[field.owner] = formState.values.companyRep?.[field.companyRep]
        })
      }
      form.change(`ownershipInfoArr[${ownerIndexToShow}]`, newOwnerFields)
      form.change('companyRep', {}) // clear form data copied over when switching contact types to avoid duplicates
    } else {
      props.setFieldPrefixFilter('companyRep')
      const newCompanyRepValue = {}
      CONTACT_FIELD_MAP?.forEach((field) => {
        newCompanyRepValue[field.companyRep] = formState.values.ownershipInfoArr?.[ownerIndexToShow]?.[field.owner]
      })
      form.change('companyRep', newCompanyRepValue)
      // completely remove the owner in this index sinc they are switching to companyrep
      let newOwnershipInfoArr = formState.values?.ownershipInfoArr?.filter((owner, i) => i !== ownerIndexToShow)
      form.change(`ownershipInfoArr`, newOwnershipInfoArr)
    }
  }

  if (hideContent) return null
  else
    return (
      <div>
        <Container>
          <Typography textVariant="h5SemiBold">{translate('Add owner or company representative')}</Typography>
          <Typography textVariant="body1">
            {translateParse('<strong>Who would you like to add?<strong>', {
              strong: (label: string) => <strong>{label}</strong>,
            })}
            <br />
            {translate(
              'You can add up to 4 contacts. All individuals who own 25% or more of your business must be added.'
            )}
          </Typography>
        </Container>

        <Row isMobile={isMobile}>
          <StyledCard
            isCardSelected={props.fieldPrefixFilter !== 'companyRep' && props.fieldPrefixFilter !== null}
            onClick={() => onChangeContactType('owner')}
          >
            <CardHeader>
              <AccountCircleOutlined />
              <Typography textVariant="subtitle1Bold">{translate('Owner')}</Typography>
            </CardHeader>
            <ContactDescription>
              <Typography textVariant="body1">
                {translate('This is a person who owns 25% or more of your business')}
              </Typography>
            </ContactDescription>
          </StyledCard>

          {repIsUnique ? (
            <Tooltip
              title={translate(
                '%{name} has already been entered as the designated Company Representative. If you would like to switch the Company Representative please delete %{name}',
                { name: companyRep.companyRepFirstName + ' ' + companyRep.companyRepLastName }
              )}
              enterDelay={300}
            >
              <StyledCard isCardDisabled={true}>
                <CardHeader>
                  <AccountCircleOutlined />
                  <Typography textVariant="subtitle1Bold" colorHex="#A6A6A6">
                    {translate('Company Representative')}
                  </Typography>
                </CardHeader>

                <ContactDescription>
                  <Typography textVariant="body1" colorHex="#A6A6A6">
                    {translate(
                      'This is an individual who oversees the business and has significant control over the entities'
                    )}
                  </Typography>
                </ContactDescription>
              </StyledCard>
            </Tooltip>
          ) : (
            <StyledCard
              isCardSelected={props.fieldPrefixFilter === 'companyRep' && props.fieldPrefixFilter !== null}
              onClick={() => onChangeContactType('rep')}
            >
              <CardHeader>
                <AccountCircleOutlined />
                <Typography textVariant="subtitle1Bold">{translate('Company Representative')}</Typography>
              </CardHeader>

              <ContactDescription>
                <Typography textVariant="body1">
                  {translate(
                    'This is an individual who oversees the business and has significant control over the entities'
                  )}
                </Typography>
              </ContactDescription>
            </StyledCard>
          )}
        </Row>

        {props.fieldPrefixFilter !== null && (
          <FormHeader>
            <Typography textVariant="body1">
              {translateParse(
                "Please provide contact's <strong>personal information<strong> below. You will be able to add more contacts after saving.",
                {
                  strong: (label: string) => <strong>{label}</strong>,
                }
              )}
            </Typography>
          </FormHeader>
        )}
      </div>
    )
}
export default PersonInfoTop
