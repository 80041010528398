import { InputAdornment } from '@material-ui/core'
//sarai gridicon
//linkicon
import { permissionsSelectors } from 'ducks/permissions'
import ListActions from 'elements/react-admin/ListActions'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'

import {
  AddIcon,
  ArrowVerticalCenterIcon,
  Button,
  DownloadIcon,
  FilterIcon,
  GridViewIcon,
  LinkIcon,
  SearchOutlineIcon,
  TableViewIcon,
  ToggleButton,
  ToggleButtonGroup,
} from 'opensolar-ui'
import BulkDeleteWithConfirmButtonV3 from 'projectSections/sections/manage3/common/BulkDeleteWithConfirmButton'
import { useTranslate } from 'ra-core'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ActionType, EventType } from 'types/tracking'
import { FilesContext, FilesContextProps } from '../../../common/FilesContextWrapper'
import { NewFileMenu } from '../../../common/menus/NewFileMenu'
import { downloadFile, getFileExtension } from '../../../common/utils'
import { ViewMode } from '../../FilesPage'
import { ListPopperFilter } from './ListPopperFilter'
import {
  ActionBtn,
  ButtonsContainer,
  ListActionsContainer,
  MainToolbarContainer,
  SearchAndFilterContainer,
  SearchInputStyled,
  ToggleContainer,
} from './mainToolBarStyles'

export interface MainToolBarProps {
  viewMode: ViewMode
  setViewMode: (v: ViewMode) => void
  disabled: boolean
}

export const MainToolBar = (props) => {
  const translate = useTranslate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [searchTerm, setSearchTerm] = useState(props.filterValues?.q)
  const { allowEdit, allowDelete, allowCreate } = useSelector(permissionsSelectors.getPermissionByKey('info_documents'))
  const { onSuccess, setRecordsArraySelected, recordsArraySelected, setDialogSelected, dialogSelected } = useContext(
    FilesContext
  ) as FilesContextProps

  useEffect(() => {
    return () => {
      props.setFilters({})
    }
  }, [])

  useEffect(() => {
    // After merged pdfs, unselect all items
    if (!dialogSelected && recordsArraySelected.length === 0) {
      props.onUnselectItems([])
    }
  }, [dialogSelected, recordsArraySelected])

  const showBulkActionsBtns = props.selectedIds.length > 0 && !props.disabled
  const { trackEvent } = useTrackComponent({
    componentKey: 'main_toolbar',
    eventName: 'Main toolbar',
  })

  const multiplePdfsSelected = useMemo(() => {
    if (!showBulkActionsBtns || props.selectedIds.length <= 1) {
      return false
    }
    const fileTypes = props.selectedIds.map((id) => getFileExtension(props.data[id])) || []
    const allPdfs = fileTypes.every((f) => f === 'pdf')
    return allPdfs
  }, [props.selectedIds])

  const toggleViewDisabled = props.disabled || showBulkActionsBtns
  const searchDisabled = props.disabled || showBulkActionsBtns
  const createDisabled = !allowCreate || props.disabled || showBulkActionsBtns
  const deleteDisabled = !allowDelete
  const mergeDisabled = !multiplePdfsSelected || !allowEdit
  const filterDisabled = showBulkActionsBtns

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const q = event.target.value
    setSearchTerm(q)
    props.setFilters({ ...props.filterValues, q })
  }

  const handleToggleViewMode = (e: unknown, viewSelected: ViewMode) => {
    props.onSelect([])
    props.setEditableFieldSelected(null)
    props.setViewMode(viewSelected)
  }

  const handleMergePdfs = () => {
    const selectedRecords = props.selectedIds.map((id) => props.data[id])
    setRecordsArraySelected(selectedRecords)
    trackEvent(
      EventType.USER_INTERACTION,
      { type: ActionType.CLICK },
      { eventName: 'Opened merge pdfs dialog', countPdfs: selectedRecords.length }
    )
    setDialogSelected('merge_pdfs')
  }

  const handleDownload = () => {
    props.selectedIds.forEach((id) => {
      const record = props.data[id]
      downloadFile(record)
    })
    // if (props.selectedIds.length === 1) {
    //   const record = props.data[props.selectedIds[0]]
    //   console.log('-------XXXXX record ', record)
    //   downloadFile(record)
    // } else {
    //   const selectedUrls = props.selectedIds.map((id) => props.data[id].url)
    //   downloadMultipleFilesAsZip(selectedUrls)
    // }
  }

  const handleShareUploadClick = (e: React.MouseEvent<HTMLElement>) => {
    setDialogSelected('share_upload_link')
  }

  const handleOpenNewFileMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const filesListFilters = {
    file_tags: {
      displayName: 'Tag',
      optionsSource: 'file_tags',
      optionsFilter: 'private',
      optionValue: 'title',
    },
  }

  return (
    <MainToolbarContainer id="main-toolbar-container">
      <ListActions
        id="list-actions"
        hasCreate={true}
        hasSearch={false}
        {...props}
        disabled={true}
        CustomButton={
          <ListActionsContainer>
            <SearchAndFilterContainer id="search-and-filter-container">
              <SearchInputStyled
                disabled={searchDisabled}
                id={'search-files-input'}
                value={searchTerm}
                onChange={handleSearch}
                placeholder={'Search files'}
                variant={'outlined'}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{<SearchOutlineIcon />}</InputAdornment>,
                }}
              />
              <ListPopperFilter
                filtersApplied={props.filterValues}
                setListFilter={props.setFilters}
                filtersAvailable={filesListFilters}
              >
                <Button
                  color={!!Object.keys(props.filterValues).length ? 'primary' : 'secondary'}
                  variant={!!Object.keys(props.filterValues).length ? 'contained' : 'outlined'}
                  startIcon={<FilterIcon />}
                  disabled={filterDisabled}
                >
                  {translate('Filter')}
                </Button>
              </ListPopperFilter>
            </SearchAndFilterContainer>

            <ButtonsContainer id="buttons-container">
              {showBulkActionsBtns && (
                <>
                  <ActionBtn>
                    <BulkDeleteWithConfirmButtonV3 {...props} disabled={deleteDisabled} onSuccessDelete={onSuccess} />
                  </ActionBtn>
                  <ActionBtn>
                    <Button
                      color={'secondary'}
                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      onClick={handleDownload}
                    >
                      {translate('Download')}
                    </Button>
                  </ActionBtn>
                  <ActionBtn>
                    <Button
                      color={'secondary'}
                      variant="outlined"
                      startIcon={<ArrowVerticalCenterIcon />}
                      onClick={handleMergePdfs}
                      disabled={mergeDisabled}
                    >
                      {translate('Merge to PDF')}
                    </Button>
                  </ActionBtn>
                </>
              )}
              <ActionBtn>
                <Button
                  color={'secondary'}
                  variant="outlined"
                  startIcon={<LinkIcon />}
                  onClick={handleShareUploadClick}
                  disabled={createDisabled}
                >
                  {translate('Share Upload Link')}
                </Button>
              </ActionBtn>
              <ActionBtn>
                <Button
                  color={'secondary'}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleOpenNewFileMenu}
                  disabled={createDisabled}
                >
                  {translate('Document')}
                </Button>
              </ActionBtn>
            </ButtonsContainer>
            <NewFileMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          </ListActionsContainer>
        }
      />

      <ToggleContainer id="toggle-container">
        <ToggleButtonGroup value={props.viewMode}>
          <ToggleButton disabled={toggleViewDisabled} value="tableView" onClick={handleToggleViewMode}>
            <TableViewIcon /> {translate('Table')}
          </ToggleButton>
          <ToggleButton disabled={toggleViewDisabled} value="gridView" onClick={handleToggleViewMode}>
            <GridViewIcon /> {translate('Grid')}
          </ToggleButton>
        </ToggleButtonGroup>
      </ToggleContainer>
    </MainToolbarContainer>
  )
}
