var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker as Mui4DateTimePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import { DateTimePicker as Mui5DateTimePicker } from '@mui/x-date-pickers';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
// * MUI DateTimePicker API Spec  https://mui.com/x/react-date-pickers/date-time-picker/
export var DateTimePicker = function (props) { return (_jsx(Inspector, { name: "DateTimePicker", propDefs: DateTimePickerPropsDefs, showExample: false, component: OsDateTimePicker, props: props })); };
export var OsDateTimePickerVersion;
(function (OsDateTimePickerVersion) {
    OsDateTimePickerVersion[OsDateTimePickerVersion["V1"] = 1] = "V1";
    OsDateTimePickerVersion[OsDateTimePickerVersion["V2"] = 2] = "V2";
})(OsDateTimePickerVersion || (OsDateTimePickerVersion = {}));
var OsDateTimePicker = forwardRef(function (props, ref) {
    var version = useComponentVersion('date_picker');
    switch (version) {
        case OsDateTimePickerVersion.V2:
            return (
            //@ts-ignore
            _jsx(Mui5DateTimePicker, __assign({ ref: ref, slotProps: {
                    dialog: {
                        sx: {
                            zIndex: 2000,
                        },
                    },
                    popper: {
                        sx: {
                            zIndex: 2000,
                        },
                    },
                    textField: {
                        InputProps: {
                            color: 'secondary',
                        },
                    },
                } }, props)));
        default:
            return (_jsx(MuiPickersUtilsProvider, { utils: DateFnsUtils, children: _jsx(Mui4DateTimePicker, __assign({ ref: ref }, props)) }));
    }
});
var DateTimePickerPropsDefs = {};
