import { InfoOutlined } from '@material-ui/icons'
import { Button, styled, Typography } from 'opensolar-ui'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { ExtendedHardwareSupplierConfig } from 'projectSections/sections/manage3/hardware/DistributorChip'
import useGetDistributorConnectionStatusAndConfig from 'projectSections/sections/manage3/hardware/hooks/useGetDistributorConnectionStatusAndConfig'
import { FC, useContext } from 'react'
import { DISTRBUTOR_KEY_TO_NAME_MAPPING, SOURCE_CONTEXT_MAPPING } from '../constants'
import { CheckoutSourceType } from '../types'

interface Props {
  distributor: HardwareSupplierFilterKeyType
  checkoutSource: CheckoutSourceType
  borderColor?: `#${string}`
  backgroundColor?: `#${string}`
  fontColor?: `#${string}`
}

const Container = styled('div')<{ borderColor: `#${string}`; backgroundColor: `#${string}` }>(
  ({ borderColor, backgroundColor }) => ({
    display: 'flex',
    flexDirection: 'row',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: borderColor,
    borderRadius: 4,
    padding: '8px',
    background: backgroundColor,
    alignItems: 'flex-start',
  })
)

const IconContainer = styled('div')({
  marginRight: '8px',
})

const Icon = styled(InfoOutlined)<{ fontColor: `#${string}` }>(({ fontColor }) => ({
  fontSize: '20px',
  color: fontColor,
}))

const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'flex-start',
})

const DistributorConnectionAlert: FC<Props> = ({
  distributor,
  checkoutSource,
  backgroundColor = '#D6E0F7',
  borderColor = '#F5F8FD',
  fontColor = '#113B98',
}) => {
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const { distributorConfigs } = useGetDistributorConnectionStatusAndConfig()
  const distributorConfig = distributorConfigs.find(
    (item: ExtendedHardwareSupplierConfig) => item.filterKey === distributor
  )

  const content =
    distributor === 'segen'
      ? 'You are not logged in to Segen. Log in to confirm trade pricing, and complete order.'
      : `You are not logged in to ${DISTRBUTOR_KEY_TO_NAME_MAPPING[distributor]}. If you have an account with ${DISTRBUTOR_KEY_TO_NAME_MAPPING[distributor]}, you can log in to access your own pricing, including trade pricing. Otherwise, you can proceed with checkout publicly.`

  const handleClickConnectToDistributor = () => {
    preOrderPresenter?.updateShowConnectDistributorModal(true)
  }

  if (distributorConfig?.isConnected) {
    return null
  }

  return (
    <Container borderColor={borderColor} backgroundColor={backgroundColor}>
      <IconContainer>
        <Icon fontColor={fontColor} />
      </IconContainer>
      <TextContainer>
        <Typography textVariant="body1Bold" colorHex={fontColor}>
          {content}
        </Typography>
        <Button variant="contained" color="default" fullWidth={false} onClick={handleClickConnectToDistributor}>
          <Typography textVariant="caption2" colorHex="#4C4B53">
            {`Connect Account to ${DISTRBUTOR_KEY_TO_NAME_MAPPING[distributor]}`}
          </Typography>
        </Button>
      </TextContainer>
    </Container>
  )
}

export default DistributorConnectionAlert
