import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import useTranslateParse from 'util/useTranslateParse'

type GreyCardContentType = {
  id: number
  icon: JSX.Element
  header: string
  description: string
}

type PropTypes = {
  content: GreyCardContentType[]
}

const StyledCard = styled('div')(({ theme }) => ({
  background: theme.palette.grey[50],
  border: 'none !important',
  borderRadius: '8px',
  padding: '1rem',
  margin: '1rem 0 1rem 0',
}))

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  margin: '10px 0',
})

const DescriptionWrapper = styled('div')({
  margin: '12px 12px 12px 35px',
})

const GreyInfoCard: React.FC<PropTypes> = ({ content, children }) => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  return (
    <StyledCard>
      {content.map((cont) => {
        return (
          cont.description && (
            <div key={cont.id}>
              <Row>
                {cont.icon}
                <Typography textVariant="subtitle1Bold">{translate(cont.header)}</Typography>
              </Row>

              <DescriptionWrapper>
                <Typography textVariant="body1">
                  {translateParse(cont.description, {
                    strong: (label: string) => <strong>{label}</strong>,
                  })}
                </Typography>
              </DescriptionWrapper>
            </div>
          )
        )
      })}
      {children}
    </StyledCard>
  )
}
export default GreyInfoCard
