// import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import {
  clearDownloadUrl as clearDownloadUrlAction,
  generateDocumentSelectors,
  setGenerateDocumentDialog as setGenerateDocumentDialogAction,
  setOpenDialog,
} from 'ducks/generateDocument'
import Alert from 'elements/Alert'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { ComponentVersionsInherit, Dialog, DialogContent, DialogTitle, styled } from 'opensolar-ui'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDataProvider, useNotify, useTranslate } from 'react-admin'
import { Form, useFormState } from 'react-final-form'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormPlacementEnum } from 'resources/customForms/types'
import ChangeOrderPlaceholders from 'resources/projects/elements/documentGeneration/ChangeOrderPlaceholders'
import GenerateDocumentButtons from 'resources/projects/elements/documentGeneration/GenerateDocumentButtons'
import { GenerateDocumentInitialDialog } from 'resources/projects/elements/documentGeneration/GenerateDocumentInitialDialog'
import { GenerateDocumentInputDialog } from 'resources/projects/elements/documentGeneration/GenerateDocumentInputDialog'
import { RootState } from 'types/state'
import { ActionStatus, ActionType, EventType } from 'types/tracking'
import { ContentWrapper } from '../styles/dialogAndFieldsStyles'
import { LoadingOverlay } from '../styles/styles'

export const StyledTitle = styled(DialogTitle)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
  borderBottom: '1px solid',
  borderColor: theme.palette.grey[200],
}))

const GenerateDocumentDialog = (props) => {
  const translate = useTranslate()
  const formValues = useFormState().values
  const selectedDocumentTemplateId = formValues?.document_template_id
  const saveCustomFormRef = useRef()
  const customSubFormRef = useRef()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const isDocusign = useSelector((state: RootState) => state.generateDocument.dialogOptions.isDocusign)
  const projectFormDirtyFields = props.projectForm.mutators.getFormDirtyFields()
  const openDialog = useSelector((state: RootState) => state.generateDocument.dialogOptions.openDialog)
  const [docId, setDocId] = useState()
  const doc = useSelector(generateDocumentSelectors.getDocById)(docId)
  const customForms = useGetCustomForms({
    location: FormPlacementEnum.before_document_generation,
    entityId: selectedDocumentTemplateId,
    omitNoEntityId: true,
  })
  const dispatch = useDispatch()

  const closeDialog = () => {
    setDocId(undefined)
    props.setGenerateDocumentDialog(false)
    props.onDismiss()
    dispatch(setOpenDialog('initial'))
  }
  const showDocuSignInfo = useMemo(() => {
    if (formValues.document_type === 'change_order') return isDocusign && openDialog === 'CO-input'
    else return isDocusign
  }, [formValues.document_type, isDocusign, openDialog])

  const { trackEvent } = useTrackComponent({
    componentKey: 'generate_document_dialog',
    eventName: 'User Generated Document',
  })

  useEffect(() => {
    const eventData = {
      projectId: props.projectId,
      documentType: formValues.document_type,
      documentTemplateId: formValues.document_template_id,
      selectedPaymentOptionId: formValues.selected_payment_option_id,
    }
    if (doc?.status === 'failure') {
      trackEvent(
        EventType.ERROR_EVENT,
        { type: ActionType.CLICK, status: ActionStatus.ERROR },
        {
          eventName: 'User Document Generation Failed',
          ...eventData,
        }
      )
      notify(translate('Error saving your file'), 'error')
      props.onDismiss()
    } else if (doc?.status === 'success') {
      trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, eventData)
      props.onSuccess()
      props.onDismiss()
    } else {
      return
    }
    return () => {
      props.onDismiss()
    }
  }, [dataProvider, doc])

  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      {doc?.status && (
        <LoadingOverlay>
          <LinearProgress variant="indeterminate" />
        </LoadingOverlay>
      )}
      <Dialog
        //@ts-ignore
        autoDetectWindowHeight={true}
        actions={null}
        open={props.isOpen}
        onBackdropClick={closeDialog}
        maxWidth={openDialog === 'CO-input' ? 'xl' : undefined}
        fullWidth
        disableScrollLock
      >
        <DialogTitle>
          <span>{translate('Generate Document')}</span>
        </DialogTitle>
        {doc?.status === 'failure' && (
          <Alert severity="error" styles={{ margin: '0 20px' }}>
            {translate('Document Generation Unsuccessful.') +
              ' ' +
              (doc?.error?.length && doc?.error?.length > 0 ? doc?.error : '')}
          </Alert>
        )}
        <DialogContent>
          <ContentWrapper>
            {openDialog === 'initial' && (
              <GenerateDocumentInitialDialog
                clearDownloadUrl={props.clearDownloadUrl}
                {...props}
                closeDialog={closeDialog}
                doc={doc}
              />
            )}
            {openDialog === 'CO-input' && <ChangeOrderPlaceholders />}
            {openDialog === 'input' && (
              <GenerateDocumentInputDialog
                parentForm={props.projectForm}
                customFormsForDoc={customForms}
                projectFormDirtyFields={projectFormDirtyFields}
                //@ts-ignore
                saveRef={saveCustomFormRef}
                //@ts-ignore
                formRef={customSubFormRef}
              />
            )}
            {showDocuSignInfo && (
              <Alert severity="info">
                {translate(
                  "You've selected a Docusign template. Clicking the button below will email a Docusign envelope to all recipients. Please note that changes to the system design will not invalidate this document."
                )}
              </Alert>
            )}
          </ContentWrapper>
        </DialogContent>

        <GenerateDocumentButtons
          {...props}
          setDocId={setDocId}
          docId={docId}
          doc={doc}
          hasForms={!!customForms.length}
          saveCustomFormRef={saveCustomFormRef}
          customSubFormRef={customSubFormRef}
          projectFormDirtyFields={projectFormDirtyFields}
        />
      </Dialog>
    </ComponentVersionsInherit>
  )
}

const FormWrapperGenerateDocumentDialog = (props) => {
  if (!props.isOpen) return null
  return (
    <Form
      onSubmit={() => {}}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <GenerateDocumentDialog {...props} />
        </form>
      )}
    />
  )
}

export default connect(
  (state) => ({
    //@ts-ignore
    isOpen: state.generateDocument ? state.generateDocument.isOpen : false,
  }),
  {
    setGenerateDocumentDialog: setGenerateDocumentDialogAction,
    clearDownloadUrl: clearDownloadUrlAction,
  }
)(FormWrapperGenerateDocumentDialog)
