import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useMemo } from 'react'
import BasicFilter from '../basic/BasicFilter'
import { DISTRIBUTOR_FILTER_KEY_V2 } from '../distributor/DistributorFilterConfigV2Factory'
import {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  FilterValuesType, type OptionGenericType,
} from '../typeV2'
import useMultiDistributorsAvailableBrands from './useMultiDistributorsAvailableBrands'
import { parseStringifiedListFilterValue } from '../utils/utils'
import type { ComponentTypesV2 } from '../../../types/selectComponent'
import { OtherComponentTypes } from '../../../types/otherComponent'
import listQueryParamsFormatter from '../../input/listQueryParamsFormatter'
import { usePublicFeatureConfig } from '../../../hooks/usePublicFeatureConfig'
import { useSelector } from 'react-redux'
import { orgSelectors } from '../../../ducks/orgs'
import { getConfigByComponentType } from '../manufacturer/FeatureConfigManufacturerFilter'
import { mapComponentTypesV2ToV1 } from '../../../util/misc'

export const DISTRIBUTOR_BRAND_FILTER_KEY_V2 = 'manufacturer_names'
const defaultSelectedOptionValue = 'all'
export const SELECT_ALL_OPTION = { id: 'default', title: 'All', value: defaultSelectedOptionValue }


type ManufacturerFilterOptionsType = OptionGenericType<string>[]


const getManufacturerOptions = ({
                                  configs,
                                  orgIso2,
                                }: {
  configs
  orgIso2: string | undefined
}): ManufacturerFilterOptionsType | undefined => {
  if (!configs || configs.length === 0) {
    return undefined
  }

  const options: ManufacturerFilterOptionsType = configs.reduce((result, option) => {
    if (option.include_country && !option.include_country.includes(orgIso2)) {
      return result
    }

    result.push({ title: option.title, value: option.value, id: option.value })

    return result
  }, [])

  if (options.length === 0) {
    return undefined
  }

  return options
}

type DistributorBrandManufacturerFilterPropsType = {
  componentTypes?: ComponentTypesV2[]
}

const DistributorBrandManufacturerFilter = <T, >({
                                                   label = 'Brand',
                                                   distributors,
                                                   componentTypes,
                                                   otherComponentTypes,
                                                   rendererComponent: RendererComponent,
                                                   ...props
                                                 }: FilterGenericPropsType &
  DistributorBrandManufacturerFilterPropsType &
  ConfigurableFilterOptionsType<string, T> & {
  distributors: HardwareSupplierFilterKeyType[]
  otherComponentTypes: Set<OtherComponentTypes>
}) => {
  const defaultValue = new Set([SELECT_ALL_OPTION.value])
  let availableBrands = useMultiDistributorsAvailableBrands({
    distributors: distributors,
    componentType: componentTypes,
    otherComponentTypes: otherComponentTypes,
  })

  const manufacturerConfig = usePublicFeatureConfig('manufacturer_filter')
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)

  let manufacturerOptions: ManufacturerFilterOptionsType | undefined = []

  if (componentTypes && componentTypes.length > 0) {
    const componentTypeV1 = mapComponentTypesV2ToV1(componentTypes[0])
    manufacturerOptions =
      manufacturerConfig === undefined
        ? undefined
        : getManufacturerOptions({
          configs: getConfigByComponentType({ manufacturerConfig, componentType: componentTypeV1 }),
          orgIso2,
        })
  }

  if (manufacturerOptions && manufacturerOptions.length > 0) {
    const manufacturerOptionsValues = manufacturerOptions.map(option => option.title)
    availableBrands  = availableBrands.concat(manufacturerOptionsValues)
    availableBrands.sort((brandA, brandB) => (brandA > brandB ? 1 : -1))
    availableBrands = Array.from(new Set(availableBrands)) // remove duplicates
  }

  const options = useMemo(() => {
    return availableBrands.sort().reduce(
      (options, brand) => {
        return options.concat({ id: brand, value: encodeURIComponent(brand), title: brand })
      },
      [SELECT_ALL_OPTION],
    )
  }, [availableBrands])

  const sideEffect = <OptionValue, RendererValue>({ filterValues }: { filterValues: FilterValuesType }) => {
    const newFilterValue = { ...filterValues }

    const brandNames = listQueryParamsFormatter.parse(filterValues[DISTRIBUTOR_BRAND_FILTER_KEY_V2])

    if (brandNames.has(SELECT_ALL_OPTION.value)) {
      const allBrands = new Set([...options.map((option) => option.value)])
      allBrands.delete(SELECT_ALL_OPTION.value)
      newFilterValue[DISTRIBUTOR_BRAND_FILTER_KEY_V2] = listQueryParamsFormatter.format({ value: allBrands })
    }

    return newFilterValue
  }

  return (
    <BasicFilter
      label={label}
      rendererComponent={RendererComponent}
      defaultSelectedOptionValue={defaultValue}
      filterKey={DISTRIBUTOR_BRAND_FILTER_KEY_V2}
      options={options}
      sideEffect={sideEffect}
      {...props}
    />
  )
}

const createDistributorBrandFilterNode = <T, >(
  config: ConfigurableFilterOptionsType<string, T>,
): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const setDistributors = listQueryParamsFormatter.parse<HardwareSupplierFilterKeyType>(filterProps.allFilterValues[DISTRIBUTOR_FILTER_KEY_V2])
    const distributorList = Array.from(setDistributors) as HardwareSupplierFilterKeyType[]

    // The `types` on query parameters for componentsV2 endpoint is shared by 'other' sub-components
    // and Major component such as Panel and inverters, Battery.
    // Here, we can separate it using filterProps, which isn't available in the downstream components.
    const selectorConfigTypes = parseStringifiedListFilterValue<OtherComponentTypes>({
      filterValues: filterProps.persistentFilterValues, // Hardware selector sets what available at creation time
      key: 'types',
    })

    if (!!distributorList) {
      return (
        <DistributorBrandManufacturerFilter
          componentTypes={filterProps.componentTypes ? filterProps.componentTypes : []}
          distributors={distributorList}
          otherComponentTypes={selectorConfigTypes}
          {...config}
          {...filterProps}
        />
      )
    } else {
      return null
    }
  }

  return {
    key: DISTRIBUTOR_BRAND_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createDistributorBrandFilterNode
