import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getFormsToRenderAsButton } from 'customForm/dialog/getFormsToRenderAsButton'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import RenusolDialog from 'Designer/integrations/renusol/RenusolDialog'
import { orgSelectors } from 'ducks/orgs'
import { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormPlacementEnum } from 'resources/customForms/types'
import { StudioSystemType } from 'types/global'
import { MountingID, NativeMountingID } from 'types/mounting'
import { parseIntegrationJson } from 'util/misc'
import IronRidgeBom, { ironRidgeIsSelected } from '../other/ironridge/IronRidgeBom'
import { MountingInputs } from './nativeMounting/Inputs'
import getNativeMountingSystems from './nativeMounting/mountingSystems'
import LaunchRenusolButton from './renusol/LaunchRenusolButton'
import ViridianInputs from './viridian/ViridianInputs'

type MountingTabPropsType = {
  mountingId: MountingID
  allowEdit: boolean
  system: StudioSystemType
}

const MountingSettings: FC<MountingTabPropsType> = ({ mountingId, allowEdit, system }) => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const customForms = useGetCustomForms({ location: FormPlacementEnum.designpanel_mounting_button })

  const formsToRender = useMemo(() => {
    return getFormsToRenderAsButton([], customForms, { system })
  }, [system, customForms])

  const [renusolOpen, setRenusolOpen] = useState(false)

  const openRenusol = () => {
    if (window.editor?.selectedSystem?.moduleQuantity() > 0) {
      setRenusolOpen(true)
      logAmplitudeEvent('integrated_racking_dialog_opened', { integration: 'Renusol' })
    } else {
      alert('Please add panels to the project first')
    }
  }

  const nativeMountingSystems = getNativeMountingSystems()

  return (
    <>
      {allowEdit &&
        countryIso2 === 'US' &&
        ironRidgeIsSelected(parseIntegrationJson(system.integration_json)?.ironridge) && (
          <IronRidgeBom
            systemUuid={system.uuid}
            ironRidgeIntegration={parseIntegrationJson(system.integration_json)?.ironridge}
          />
        )}
      {window.editor.selectedSystem?.integration_json?.viridian && mountingId === 'viridian' && (
        <ViridianInputs allowEdit={allowEdit} />
      )}
      {nativeMountingSystems[mountingId] &&
        window.editor.selectedSystem?.integration_json &&
        window.editor.selectedSystem.integration_json[mountingId] && (
          <MountingInputs
            allowEdit={allowEdit}
            mountingName={window.editor.selectedSystem.mounting as NativeMountingID}
          />
        )}
      {allowEdit && mountingId === 'renusol' && <LaunchRenusolButton openRenusol={openRenusol} system={system} />}
      {(renusolOpen || window.debugRenusolDialogShow) && <RenusolDialog onClose={() => setRenusolOpen(false)} />}
      {formsToRender}
    </>
  )
}

export default MountingSettings
