export const US_FINCOS = ['sunlight', 'dividend', 'commonbond', 'sungage', 'mosaic', 'loanpal'] as const
export const AU_FINCOS = ['brighte', 'plenti', 'energy_ease'] as const
export const UK_FINCOS = ['phoenix']
export const ALL_FINCOS = [...US_FINCOS, ...AU_FINCOS, ...UK_FINCOS]

export const INTEGRATION_TO_LOGO = {
  mosaic: {
    url: window.PUBLIC_URL + '/images/Mosaic-Logo.png',
    relativeHeight: 1,
    relativeWidth: 1,
  },
  sunlight: {
    url: window.PUBLIC_URL + '/images/logo-sunlight-financial.png',
    relativeHeight: 1,
    relativeWidth: 1,
  },
  sungage: {
    url: window.PUBLIC_URL + '/images/sungage-logo-wide.png',
    relativeHeight: 1.33,
    relativeWidth: 1,
  },
  dividend: {
    url: window.PUBLIC_URL + '/images/dividend-logo-wide.svg',
    relativeHeight: 1,
    relativeWidth: 1,
  },
  loanpal: {
    url: window.PUBLIC_URL + '/images/loanpal_logo.svg',
    relativeHeight: 1,
    relativeWidth: 1,
  },
  brighte: {
    url: window.PUBLIC_URL + '/images/brighte_logo.png',
    relativeHeight: 1,
    relativeWidth: 1,
  },
  plenti: {
    url: window.PUBLIC_URL + '/images/plenti-logo.svg',
    relativeHeight: 1,
    relativeWidth: 1,
  },
  energy_ease: {
    url: window.PUBLIC_URL + '/images/smart-ease-logo.png',
    relativeHeight: 1,
    relativeWidth: 1,
  },
  checkout_finance: {
    url: window.PUBLIC_URL + '/images/opensolar-checkout-financing-logo.png',
    relativeHeight: 1,
    relativeWidth: 1,
  },
}

export const INTEGRATION_TO_DISPLAY_NAME = {
  energy_ease: 'Smart Ease',
  plenti: 'Plenti',
  brighte: 'Brighte',
  loanpal: 'Goodleap',
  mosaic: 'Mosaic',
  sungage: 'Sungage',
  sunlight: 'Sunlight',
  dividend: 'Dividend',
  phoenix: 'Phoenix',
  checkout_finance: 'Checkout Finance'
}
