import _ from 'lodash'
import { useTranslate } from 'ra-core'
import { Button } from 'ra-ui-materialui'
import { FC, useEffect, useState } from 'react'
import { COLOR_LEGEND_CONFIGS, scheduleSelectorStyles } from './styles'
import {
  days,
  formatDayKey,
  MINUTES_PER_HOUR,
  parseCoordinate,
  timeLabels,
  TIMES_LABELS_PLUS_ONE,
  translateTimeLabel,
} from './utils'

interface ScheduleData {
  frequency: number
  data: {
    [day: string]: Boolean[]
  }
}

type PropsType = {
  data?: ScheduleData
  unavailable?: {
    [day: string]: Boolean[]
  }[]
  onChange: Function
}

const eraserStyles = { color: '#fff', backgroundColor: COLOR_LEGEND_CONFIGS.assignedBlend }

type pendingType = Record<string, number[]>

const ScheduleSelector: FC<PropsType> = ({ data, unavailable, onChange }) => {
  const translate = useTranslate()
  const classes = scheduleSelectorStyles()
  const schedule = _.cloneDeep(data?.data)
  const [toggleEraser, setToggleEraser] = useState(false)
  const [slotsToBeChanged, setSlotsToBeChanged] = useState<pendingType>({})
  const [containerEl, setContainerEl] = useState<HTMLDivElement | undefined>()
  const [startPos, setStartPos] = useState({ x: 0, y: 0 })
  const [selectBox, setSelectBox] = useState({ x: 0, y: 0, w: 0, h: 0 })

  const interval = MINUTES_PER_HOUR / (data?.frequency || MINUTES_PER_HOUR)
  const handleChange = (day, index) => {
    let currSched = { ...schedule }
    currSched[day.toLowerCase()][index] = !toggleEraser
    onChange({ ...data, data: currSched })
  }
  const clearAll = () => {
    let resetField = {}
    const interval = (24 * MINUTES_PER_HOUR) / (data?.frequency || 30)
    days.forEach((day) => {
      resetField[day] = [...Array(interval).fill(false)]
    })
    onChange({ ...data, data: resetField })
  }

  const getEventPos = (e, element) => {
    const bounds = element.getBoundingClientRect()
    const x = (e.clientX - bounds.left) / bounds.width
    const y = (e.clientY - bounds.top) / bounds.height
    return { x, y }
  }

  const onDragStart = (e) => {
    setStartPos(getEventPos(e, e.currentTarget))
    setContainerEl(e.currentTarget)
  }

  useEffect(() => {
    if (!containerEl) return

    let newSlots: pendingType

    const onDrag = (e) => {
      const endPos = getEventPos(e, containerEl)
      let l = Math.round(Math.min(startPos.x, endPos.x) * 100)
      let t = Math.round(Math.min(startPos.y, endPos.y) * 100)
      let r = Math.round(Math.max(startPos.x, endPos.x) * 100)
      let b = Math.round(Math.max(startPos.y, endPos.y) * 100)

      setSelectBox({ x: l, y: t, w: r - l, h: b - t })

      newSlots = {}

      l = parseCoordinate(Math.floor(Math.min(startPos.x, endPos.x) * timeLabels.length), timeLabels.length)
      t = parseCoordinate(Math.floor(Math.min(startPos.y, endPos.y) * days.length), days.length)
      r = parseCoordinate(Math.ceil(Math.max(startPos.x, endPos.x) * timeLabels.length), timeLabels.length)
      b = parseCoordinate(Math.ceil(Math.max(startPos.y, endPos.y) * days.length), days.length)

      days.forEach((day, i) => {
        if (i >= t && i < b) newSlots[day] = [...Array(r - l)].map((_, i) => l + i)
      })
      setSlotsToBeChanged(newSlots)
    }
    const onDragEnd = () => {
      if (newSlots) {
        let currSched = { ...schedule }
        for (const day in newSlots) {
          newSlots[day].forEach((index) => {
            currSched[day.toLowerCase()][index] = !toggleEraser
          })
        }
        onChange({ ...data, data: currSched })
      }
      setContainerEl(undefined)
      setSlotsToBeChanged({})
    }
    function onKeyDown(e) {
      if (e.keyCode === 27) {
        setContainerEl(undefined)
        setSlotsToBeChanged({})
      }
    }

    document.addEventListener('mousemove', onDrag)
    document.addEventListener('mouseup', onDragEnd)
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('mousemove', onDrag)
      document.removeEventListener('mouseup', onDragEnd)
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [startPos, containerEl])

  return (
    <div className={classes.column}>
      <div className={`${classes.row} ${classes.justifyCenter}`}>
        <Button
          onClick={() => {
            setToggleEraser(false)
          }}
          variant="outlined"
          disabled={!toggleEraser}
          label={toggleEraser ? 'Assign Time' : 'Assigning Time'}
          style={!toggleEraser ? eraserStyles : {}}
        />
        <Button
          onClick={() => {
            setToggleEraser(true)
          }}
          variant="outlined"
          disabled={toggleEraser}
          label={toggleEraser ? 'Unassigning Time' : 'Unassign Time'}
          style={toggleEraser ? eraserStyles : {}}
        />
        <Button onClick={clearAll} label={'Clear'} variant="outlined" />
      </div>
      <div className={`${classes.row} ${classes.justifyCenter}`}>
        <div>
          {schedule &&
            days.map((day) => (
              <div className={`${classes.row} ${classes.dayRow}`}>
                <p className={classes.dayLabel}>{translate(formatDayKey(day))}</p>
              </div>
            ))}
        </div>
        <div className={`${classes.selectorContainer} ${classes.column}`} onMouseDown={onDragStart}>
          {containerEl && (
            <div
              key="selection"
              className={classes.selectionBox}
              style={{
                top: selectBox.y + '%',
                left: selectBox.x + '%',
                width: selectBox.w + '%',
                height: selectBox.h + '%',
              }}
            ></div>
          )}
          {schedule &&
            days.map((day) => {
              const value = schedule[day]
              return (
                <>
                  <div key={day} className={`${classes.slotsRow} ${classes.row}`}>
                    {value.map((slot, i) => {
                      const isEnd = i === value.length - 1
                      const isUnavailable = unavailable && unavailable.find((x) => x.hasOwnProperty(day) && x[day][i])

                      const pendingSlot = !!containerEl && slotsToBeChanged[day]?.includes(i)

                      const selected = pendingSlot ? !toggleEraser : !!slot

                      let setColor
                      if (selected && isUnavailable) {
                        setColor = COLOR_LEGEND_CONFIGS.overlapping
                      } else if (selected) {
                        setColor = '#87E97F'
                      } else if (isUnavailable) {
                        setColor = COLOR_LEGEND_CONFIGS.unavailable
                      } else {
                        setColor = COLOR_LEGEND_CONFIGS.available
                      }

                      return (
                        <div
                          key={i}
                          onClick={() => {
                            handleChange(day, i)
                          }}
                          className={`${classes.timeSlot} ${isEnd ? classes.endBorder : ''}  ${
                            i === 0 ? classes.startBorder : ''
                          } `}
                          style={{
                            backgroundColor: setColor,
                          }}
                        />
                      )
                    })}
                  </div>
                </>
              )
            })}
        </div>
      </div>

      {/* Time Labels */}
      <div className={classes.row + ' ' + classes.timeLabels}>
        <p className={classes.dayLabel}></p>
        <div className={`${classes.slotsRow} ${classes.labelRow}`}>
          {TIMES_LABELS_PLUS_ONE.map((label, i) => {
            const isStart = i % interval === 0
            const isEnd = i === timeLabels.length

            const timeLabel = translateTimeLabel(label, translate)
            return (
              <div
                key={i}
                className={`${classes.labelSlot} ${isEnd ? classes.endTimeLabel : ''}  ${
                  i === 0 ? classes.startBorder : ''
                } `}
              >
                {isStart && (
                  <div className={classes.labelContainer}>
                    <p className={classes.timeLabel}>{timeLabel}</p>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ScheduleSelector
