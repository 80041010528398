import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'pages/ordering/checkoutv3/preOrder/styles'
import Notice from 'projectSections/sections/manage3/common/Notice'
import { useTranslate } from 'ra-core'

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '40px 28px',
  width: '100%',
  maxWidth: 1440,
  boxSizing: 'border-box',
  flexGrow: 1,
  height: '100vh',
})

const CardWrapper = styled('div')<{ hasBGColor?: boolean }>(({ theme, hasBGColor = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  padding: '40px 24px',
  background: hasBGColor ? COLOR_PALETTE.grey : theme.palette.common.white,
  width: '100%',
  height: '200px',
}))

const NoPermissionNotice = ({ text, hasBGColor }: { text: string; hasBGColor?: boolean }) => {
  const translate = useTranslate()

  return (
    <Wrapper>
      <CardWrapper hasBGColor={hasBGColor}>
        <Notice
          icon={<LockOutlinedIcon htmlColor={COLOR_PALETTE.darkGrey} />}
          mainText={translate(text)}
          subText={translate('Please contact your administrator for access')}
        />
      </CardWrapper>
    </Wrapper>
  )
}

export default NoPermissionNotice
