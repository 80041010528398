import { makeStyles } from '@material-ui/core'
import { Grid } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { ExtendedHardwareSupplierConfig } from 'projectSections/sections/manage3/hardware/DistributorChip'
import useGetDistributorConnectionStatusAndConfig from 'projectSections/sections/manage3/hardware/hooks/useGetDistributorConnectionStatusAndConfig'
import { useTranslate } from 'ra-core'
import { FC, memo, useContext } from 'react'
import { Theme } from 'types/themes'
import AccordionCardSummaryContent from '../components/AccordionCardSummaryContent'
import AccordionCardTitle from '../components/AccordionCartTitle'
import Alert from '../components/Alert'
import DeliveryInstruction from '../components/DeliveryInstruction'
import DistributorConnectionAlert from '../components/DistributorConnectionAlert'
import DistributorDeliveryDetail from '../components/DistributorDeliveryDetail'
import PartsList from '../components/PartsList'
import { SOURCE_CONTEXT_MAPPING } from '../constants'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'
import { CartSectionContentPropType, CartSectionPropType } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    gap: '24px',
    padding: '8px',
  },
  deliveryMethodDisclaimer: {
    fontSize: '14px',
    color: COLOR_PALETTE.grey5,
  },
}))

const HDMCartSection: FC<CartSectionPropType> = ({ sortOrder, checkoutSource, distributorKey }) => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const data = distributorData[distributorKey]

  return (
    <AccordionCard
      name={'distributor-hdm'}
      defaultExpanded={true}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={<AccordionCardTitle distributorKey={distributorKey} sortOrder={sortOrder} />}
      summaryContent={
        <AccordionCardSummaryContent
          totalItems={data?.totalItems}
          subtotal={data?.subtotalPrice}
          shippingFee={data?.shippingInfo.fee}
        />
      }
      contentProps={{ distributorKey, checkoutSource }}
      content={HDMCartSectionContent}
      lazyRender={true}
    />
  )
}

const HDMCartSectionContent: FC<CartSectionContentPropType> = ({ distributorKey, checkoutSource }) => {
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const data = distributorData[distributorKey]
  const translate = useTranslate()
  const { distributorConfigs } = useGetDistributorConnectionStatusAndConfig()
  const distributorConfig = distributorConfigs.find(
    (item: ExtendedHardwareSupplierConfig) => item.filterKey === distributorKey
  )

  const handleUpdateDeliveryInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryInstructions: event.target.value,
        },
      },
    })
  }

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        {distributorConfig?.isConnected ? (
          <Alert
            title={translate('What’s next after sending my order?')}
            subtitle={translate(
              'Once sent, your order will be transferred to the HDMs portal for payment processing and final shipping confirmation. You will then receive a HDM order reference ID for tracking purposes.'
            )}
            severity="info"
          />
        ) : (
          <DistributorConnectionAlert distributor={distributorKey} checkoutSource={checkoutSource} />
        )}
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12}>
        <DistributorDeliveryDetail distributorKey={distributorKey} checkoutSource={checkoutSource} />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.accordionHeaderTitle}>{translate('Delivery Method')}</span>
        <span className={classes.deliveryMethodDisclaimer}>
          {translate(
            'Your delivery options will be confirmed by HDM staff members once order is confirmed and payment is received.'
          )}
        </span>
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <DeliveryInstruction
          handleUpdateDeliveryInstructions={handleUpdateDeliveryInstructions}
          value={data?.shippingInfo?.deliveryInstructions}
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.headerTitle}>{translate('Review Parts List')}</span>
        <PartsList data={data} />
      </Grid>
      <Grid item className={`${sectionStyles.row}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span>{translate('By placing your order, you agree to HDM’s Privacy Policy and Conditions of Use')}</span>
      </Grid>
    </Grid>
  )
}

export default memo(HDMCartSection)
