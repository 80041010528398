import PowerIcon from '@material-ui/icons/Power'
import { authSelectors } from 'ducks/auth'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import useTrackCustomHandler from 'hooks/eventTracking/useTrackCustomHandler'
import { BaseTheme, Button, styled } from 'opensolar-ui'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ActionType, EventType } from 'types/tracking'
import DistributorChip, { ExtendedHardwareSupplierConfig } from './DistributorChip'

const SetupButton = styled(Button, { name: 'SetupButton' })(({ theme }) => ({
  background: `${theme.palette.blue[600]} !important`,
  color: `white !important`,
}))

const Container = styled('div', { name: 'Container' })({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 10,
})

const DistributorOptions = ({
  configs,
  onSelectSupplier,
  onOpen,
  atLeastOneDistributorConnected,
}: {
  configs: ExtendedHardwareSupplierConfig[]
  onSelectSupplier: (supplier: HardwareSupplierFilterKeyType) => void
  onOpen: () => void
  atLeastOneDistributorConnected: boolean
}) => {
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId)
  const { trackEvent } = useTrackComponent({
    componentKey: 'hardware_supplier_filter',
    eventName: 'User Filtered Hardware Supplier',
    props: { configs },
  })

  const trackSupplierSelection = useTrackCustomHandler({
    componentKey: 'hardware_supplier_filter',
    eventName: 'User Filtered Hardware Supplier',
    eventType: EventType.USER_INTERACTION,
    action: { type: ActionType.SELECT },
    handler: onSelectSupplier,
  })

  if (!atLeastOneDistributorConnected) {
    return (
      <SetupButton size="small" variant="contained" onClick={onOpen}>
        Setup
      </SetupButton>
    )
  }

  return (
    <Container>
      {configs
        .filter((config) => config.isVisible)
        .map((config, index) => (
          <DistributorChip
            key={index}
            isWrapped={false}
            config={config}
            button={
              <Button
                size="small"
                variant="contained"
                color="info"
                onClick={() => {
                  trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { config })
                  if (!config.isEnabled) {
                    history.push(`/external_api_keys/${orgId}`)
                    return
                  }
                  onOpen()
                }}
              >
                {config.isEnabled ? 'Connect' : 'Enable'}
              </Button>
            }
            icon={<PowerIcon htmlColor={BaseTheme.palette.green[600]} />}
            action={trackSupplierSelection}
            numberOfAvailableDistributor={configs.length}
          />
        ))}
    </Container>
  )
}

export default DistributorOptions
