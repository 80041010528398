import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { Confirm } from 'ra-ui-materialui'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  button: { margin: 5 },
}))

type HdmConnectUserButtonProps = {
  variant: 'outlined' | 'contained' | 'text'
  trackSource: string
  color?: 'default' | 'primary' | 'secondary' | 'warning'
  fullWidth?: boolean
  label?: string
  icon?: React.ReactNode
  onConfirm?: () => void
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const HdmConnectUserButton: React.FC<HdmConnectUserButtonProps> = ({
  variant,
  trackSource,
  color = 'default',
  fullWidth = false,
  label = 'Connect HDM',
  icon,
  onConfirm = undefined,
}) => {
  const classes = useStyles()
  const [isConnected, setIsConnected] = useState(false)
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const history = useHistory()
  const [confirmingConnect, setConfirmingConnect] = useState(false)
  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({ orgId, distributor: 'hdm' })
  const content = ['project_hardware_page', 'manage_page'].includes(trackSource)
    ? 'You will be redirected to the HDM login page to connect your account. Please confirm to proceed and save any project changes.'
    : 'You will be redirected to the HDM login page to connect your accounts, please confirm to proceed. Any unsaved changes will be discarded.'

  useEffect(() => {
    if (isAuthenticated && !isChecking) {
      setIsConnected(true)
    }
  }, [isAuthenticated, isChecking])

  const onClickConnect = () => {
    logAmplitudeEvent('hardware_distributor_connect_button_clicked', {
      source: trackSource,
      distributor: 'hdm',
    })
    if (onConfirm) {
      setConfirmingConnect(true)
    } else {
      confirm()
    }
  }

  const handleConfirm = () => {
    setConfirmingConnect(false)
    if (onConfirm) {
      onConfirm()
    }
    confirm()
  }

  const confirm = () => {
    if (isConnected) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + orgId + '/distributors/hdm/disconnect/',
      })
        .then((response) => {
          setIsConnected(false)
        })
        .catch((err: any) => {
          console.log(err, err)
        })
    } else {
      history.push('/shop/connect_distributor/hdm/email')
    }
  }

  return (
    <>
      <Button
        onClick={onClickConnect}
        variant={variant}
        color={color}
        disabled={false}
        className={classes.button}
        fullWidth={fullWidth}
      >
        {icon && icon}
        {isConnected ? 'Disconnect HDM' : label}
      </Button>
      <Confirm
        isOpen={confirmingConnect}
        title="Connect to HDM"
        content={content}
        confirmColor="warning"
        onConfirm={handleConfirm}
        onClose={() => setConfirmingConnect(false)}
      />
    </>
  )
}

export default HdmConnectUserButton
