import { Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { MountingID, MountingSystemType, MountingSystemTypeOptions } from 'types/mounting'
import { useFeatureFlag } from 'util/split'
import { RemoveComponentButton } from '../../common/RemoveComponentButton'
import { useGetMountingOptsInCountry } from './MountingSelector'
import MountingSystemCapsule from './MountingSystemCapsule'

const useSelectedMountingSystemStyles = makeOpenSolarStyles((theme) => ({
  selectedMountingSystem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const SelectedMountingSystem = ({
  selectedMountingSystem,
  clearMountingSystem,
}: {
  selectedMountingSystem: MountingID
  clearMountingSystem(): void
}) => {
  const classes = useSelectedMountingSystemStyles()
  const translate = useTranslate()

  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        {translate('Selected Mounting System')}
      </Typography>
      <div className={classes.selectedMountingSystem}>
        <MountingSystemCapsule integrationName={selectedMountingSystem} />
        <RemoveComponentButton onClick={clearMountingSystem} />
      </div>
    </>
  )
}

const SelectableMountingSystems = ({
  selectedTypeOption,
  handleUpdateMountingSystem,
}: {
  selectedTypeOption: MountingSystemTypeOptions
  handleUpdateMountingSystem(mountingId: MountingID): void
}) => {
  const translate = useTranslate()

  // TEMPORARY
  // We are hiding the Fastensol mounting option from users who do not have City Plumbing enabled
  // Otherwise they will see a message prompting them to enable City Plumbing, but they won't be able to because we are in a beta phase
  // Please replace the following two lines with the commented out one below once the City Plumbing integration has been released to the public
  // const mountingIds = selectedTypeOption?.mountingIds
  const cityPlumbingEnabled = useFeatureFlag('city_plumbing', 'on')
  const gseEnabled = useFeatureFlag('mounting_GseInRoof', 'on')
  const sunmodoEnabled = useFeatureFlag('sunmodo_beta', 'on')

  const mountingIds = selectedTypeOption?.mountingIds

  const filteredMountingIds = mountingIds.filter((id) => {
    if (
      (!cityPlumbingEnabled && id === 'fastensol') ||
      (!gseEnabled && id === 'gseInRoof') ||
      (!sunmodoEnabled && (id === 'sunmodoSMRPitched' || id === 'sunmodoNanoRack'))
    ) {
      return false
    } else {
      return true
    }
  })

  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        {translate('Select Mounting System')}
      </Typography>
      {filteredMountingIds.map((mountingId: MountingID) => {
        return (
          <MountingSystemCapsule
            key={mountingId}
            integrationName={mountingId}
            onClick={() => handleUpdateMountingSystem(mountingId)}
          />
        )
      })}
    </>
  )
}

const MountingSystemSelector = ({
  selectedType,
  selectedMountingSystem,
  handleUpdateMountingSystem,
}: {
  selectedType: MountingSystemType
  selectedMountingSystem: MountingID | undefined
  handleUpdateMountingSystem(mountingId: MountingID | undefined): void
}) => {
  const clearMountingSystem = () => {
    handleUpdateMountingSystem(undefined)
  }

  const mountingTypeOptions = useGetMountingOptsInCountry()

  const selectedTypeOption = useMemo((): MountingSystemTypeOptions | null => {
    return mountingTypeOptions.find((option) => option?.type === selectedType) || null
  }, [selectedType])

  if (selectedMountingSystem !== undefined) {
    return (
      <SelectedMountingSystem
        selectedMountingSystem={selectedMountingSystem}
        clearMountingSystem={clearMountingSystem}
      />
    )
  }

  if (selectedTypeOption) {
    return (
      <SelectableMountingSystems
        selectedTypeOption={selectedTypeOption}
        handleUpdateMountingSystem={handleUpdateMountingSystem}
      />
    )
  }
  return null
}

export default MountingSystemSelector
