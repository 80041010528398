import { makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button } from 'opensolar-ui'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { ActionType, EventType } from 'types/tracking'

type PropTypes = {
  anchorEl: HTMLElement | null
  setPopover: Function
  disabled: boolean
}
const useStyles = makeStyles((theme: any) => ({
  buttonWrapper: {
    pointerEvents: 'auto',
    display: 'inline-block',
    width: 85,
    marginRight: 5,
    marginTop: 10,
  },
  buttonIcon: {
    maxHeight: 35,
    maxWidth: 35,
    marginLeft: 0,
  },
  button: {
    minWidth: 85,
    width: 85,
    height: 40,
    borderRadius: 0,
    color: 'rgb(0,0,0)',
    background: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      background: 'rgba(250,250,250,0.9)',
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    '&:last-child': {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  buttonLabel: {
    textTransform: 'none',
  },
}))

const AddNewViewButton: React.FC<PropTypes> = ({ anchorEl, setPopover, disabled = false }) => {
  // const [anchorEl, setPopover] = React.useState<HTMLElement | null>(null)
  const { trackEvent } = useTrackComponent({
    componentKey: 'add_new_view_button',
    eventName: 'Add View Button Click',
  })

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      trackEvent(EventType.USER_INTERACTION, { type: ActionType.SELECT })
      setPopover(event.currentTarget.parentElement, 'new')
    },
    [anchorEl]
  )
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <>
      <div className={classes.buttonWrapper}>
        <Button
          startIcon={<AddIcon className={classes.buttonIcon} />}
          classes={{ root: classes.button, label: classes.buttonLabel }}
          onClick={handleClick}
          disabled={disabled}
        >
          <span>{translate('View')}</span>
        </Button>
      </div>
    </>
  )
}

export default AddNewViewButton
