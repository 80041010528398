import { Card } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'

type PropTypes = {
  header: string
  description?: string
}

const StyledCard = styled(Card)({
  backgroundColor: '#EDF3FF',
  border: 'none',
  textAlign: 'center',
  padding: '1rem',
  marginBottom: '1rem',
})

const BlueInfoCard: React.FC<PropTypes> = ({ header, description, children }) => {
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  return (
    <StyledCard variant="outlined">
      <Typography textVariant="subtitle1" colorHex="#2C499D">
        {header.includes('%{currencySymbol}') ? translate(header, { currencySymbol }) : translate(header)}
      </Typography>
      {children}

      {description && (
        <Typography textVariant="caption2" colorHex="#757575">
          {translate(description)}
        </Typography>
      )}
    </StyledCard>
  )
}

export default BlueInfoCard
