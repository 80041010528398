import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import inflection from 'inflection';
import PropTypes from 'prop-types';
import { usePermissions, useTranslate } from 'ra-core';
import React from 'react';
import { Link } from 'react-router-dom';
var useStyles = makeStyles(function (theme) {
    return {
        selected: {
            fontWeight: 'bold',
            color: 'black',
        },
    };
});
var BreadCrumbs = function (_a) {
    var _b, _c;
    var hasList = _a.hasList, resource = _a.resource, detail = _a.detail, _d = _a.options, options = _d === void 0 ? {} : _d;
    var _e = usePermissions(), loading = _e.loading, permissions = _e.permissions;
    var translate = useTranslate();
    var classes = useStyles();
    var listBreadCrumb = ((_b = options.list) === null || _b === void 0 ? void 0 : _b.breadCrumb) || "ra.page.breadCrumb_list";
    var detailBreadCrumb = ((_c = options[detail]) === null || _c === void 0 ? void 0 : _c.breadCrumb) || "ra.page.breadCrumb_" + detail;
    var resourceName = translate('resources.' + resource + '.name', {
        smart_count: 1,
        _: inflection.humanize(inflection.singularize(resource)),
    });
    return (_jsx("div", { children: !loading && permissions && (_jsxs(Breadcrumbs, { "aria-label": "breadcrumb", children: [permissions.includes('is_admin') && (_jsx(Link, { color: "inherit", to: '/control', children: translate('Control') })), hasList &&
                    (detail === 'list' ? (_jsx(Typography, { className: classes.selected, children: translate(listBreadCrumb, { name: resourceName, detail: detail }) })) : (_jsx(Link, { color: "inherit", to: "/".concat(resource), children: translate(listBreadCrumb, { name: resourceName, detail: detail }) }))), detail !== 'list' && (_jsx(Typography, { className: classes.selected, children: translate(detailBreadCrumb, { name: resourceName, detail: detail }) }))] })) }));
};
BreadCrumbs.propTypes = {
    detail: PropTypes.string,
};
export default BreadCrumbs;
