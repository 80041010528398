import { Button } from 'opensolar-ui'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import EditPaymentMethodsDialog from './EditPaymentOptionsDialog'

type PropTypes = {
  paymentRequest: PaymentRequestType
  setActionsAreShown: (val: boolean) => void
}

const EditPaymentOptions: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const translate = useTranslate()

  const openDialog = () => {
    setShowDialog(true)
  }

  const closeDialog = () => {
    props.setActionsAreShown(false)
    setShowDialog(false)
  }
  if (!props.paymentRequest?.id) return null
  return (
    <Button size="small" onClick={openDialog}>
      {translate('Adjust Payment Settings')}
      {showDialog && <EditPaymentMethodsDialog paymentRequestId={props.paymentRequest.id} closeDialog={closeDialog} />}
    </Button>
  )
}
export default EditPaymentOptions
