const getIconPathByMapType = (type: string) => {
  let fileName = ''
  switch (type) {
    case 'Google':
    case 'Google3D':
    case 'GoogleTop':
    case 'GoogleRoadMap':
      fileName = 'Google.png'
      break
    case 'Nearmap':
    case 'Nearmap3D':
    case 'NearmapSource':
      fileName = 'Nearmap.png'
      break
    case 'GetMapping':
    case 'GetMapping3D':
    case 'GetMapping2D':
    case 'GetMappingPremium':
    case 'GetMappingPremium3D':
    case 'GetMappingPremium2D':
      fileName = 'GetMapping.png'
      break
    case 'Bing':
      fileName = 'BingIcon.svg'
      break
    case 'MetroMap':
      fileName = 'MetroMap.svg'
      break
    case 'Kadastraal':
      fileName = 'pdok.png'
      break
    case 'IgnPotential':
      fileName = 'ign-potential.png'
      break
    case 'IgnMedian':
      fileName = 'ign-median.png'
      break
    case 'IgnCatastral':
      fileName = 'ign-catastral.png'
      break
    case 'IgnOrtofotos':
      fileName = 'ign-orto.png'
      break
    default:
      fileName = 'DefaultMap.png'
      break
  }
  return `${window.PUBLIC_URL}/images/${fileName}`
}

export default getIconPathByMapType
