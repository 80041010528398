import { useUiPath } from 'contexts/uiKey/UiContext'

const useUiKey = (suffix?: string) => {
  const { path } = useUiPath()
  if (!suffix) return path

  return path ? `${path}.${suffix}` : suffix
}

export default useUiKey
