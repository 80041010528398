import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import SideDrawer from 'elements/drawer/SideDrawer'
import { COLOR_PALETTE } from 'elements/input/styles'
import { Button, Chip, styled } from 'opensolar-ui'
import NoPermissionNotice from 'persistentContent/elements/NoPermissionNotice'
import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { setToggleBom } from '../../../../../reducer/designer/view'
import useGetDistributorConnectionStatusAndConfig from '../../../manage3/hardware/hooks/useGetDistributorConnectionStatusAndConfig'
import BomDrawerContentV3 from '../bom3/BomDrawerContentV3'

const Wrapper = styled('div', { name: 'Wrapper' })(({ theme }) => ({
  margin: '10px',
  padding: '10px 0',
  overflowX: 'auto',
  borderBottom: '1px solid ' + theme.palette.secondary.light,
  display: 'flex',
}))

const ViewButton = styled(Button, { name: 'ViewButton' })(({ theme }) => ({
  fontWeight: 450,
  color: theme.palette.grey[700],
  '&.active': {
    color: theme.palette.info.contrastText,
  },
  '& .btnLabel': {
    margin: '0 5px',
  },
}))

const StyledChip = styled(Chip, { name: 'StyledChip' })<{
  isColored: boolean
}>(({ isColored, theme }) => ({
  height: 18,
  fontSize: 10,
  borderRadius: 3,
  color: isColored ? theme.palette.error.dark : undefined,
  backgroundColor: isColored ? theme.palette.error.contrastText : undefined,
}))

const ButtonContainer = styled('div', { name: 'ButtonContainer' })({
  position: 'relative',
  marginRight: 15,
  flex: '0 0 auto',
})

const Highlight = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -10,
  width: '100%',
  borderBottom: '2px solid ' + theme.palette.info.contrastText,
}))

const tabs = ['Parts List', 'Distributor Connections']

const BomDrawerV3 = () => {
  const [viewSelected, setViewSelected] = useState(0)
  const { allowView } = useSelector(permissionsSelectors.getPermissionByKey('design_parts_list'))
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const { enabledAndConnectedDistributors } = useGetDistributorConnectionStatusAndConfig()
  const isConnectedDistributors = enabledAndConnectedDistributors && enabledAndConnectedDistributors.length > 0
  const dispatch = useDispatch()
  const bomToggle = useSelector((state: RootState) => state.designer.view.bomToggle)

  return (
    <SideDrawer
      unmountOnExit={true}
      open={bomToggle}
      setOpen={(open) => dispatch(setToggleBom(open))}
      side={'right'}
      disabled={false}
      DrawerPullLabel={'Parts List'}
    >
      {allowView ? (
        <>
          <Wrapper>
            {tabs.map((tab, index) => {
              return (
                <ButtonContainer key={'parts-list-' + index}>
                  <ViewButton
                    onClick={() => {
                      setViewSelected(index)
                    }}
                    variant="text"
                    color="secondary"
                    className={index === viewSelected ? 'active' : undefined}
                  >
                    <span className="btnLabel">{tab}</span>
                    {index === 1 && (
                      <StyledChip
                        color={index === viewSelected ? 'info' : 'default'}
                        label={`${enabledAndConnectedDistributors?.length || 0}/${enabledDistributors.length}`}
                        isColored={!isConnectedDistributors}
                        style={{
                          color: !isConnectedDistributors ? COLOR_PALETTE.darkRed : undefined,
                          backgroundColor: !isConnectedDistributors ? COLOR_PALETTE.red : undefined,
                        }}
                      />
                    )}
                  </ViewButton>
                  {index === viewSelected && <Highlight />}
                </ButtonContainer>
              )
            })}
          </Wrapper>
          <BomDrawerContentV3 tab={viewSelected} />
        </>
      ) : (
        <NoPermissionNotice text="Oops, you don't have permission to view the content" hasBGColor={true} />
      )}
    </SideDrawer>
  )
}

export default memo(BomDrawerV3)
