// in src/users.js
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { StandardInputs } from 'elements/input/StandardInputs'
import PaginationWithCsvActions from 'elements/PaginationWithCsvActions'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import TrackedToolbar from 'elements/TrackedToolbar'
import inflection from 'inflection'
import { useEffect, useState } from 'react'
import {
  BooleanField,
  Create,
  Datagrid,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useTranslate,
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { getModelByFieldName } from 'resources/customFields/PredefinedModels'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState, getOrgFromState } from 'util/org'
import { duplicate as duplicateAction } from '../../actions/restActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import DependentInput from '../../elements/input/DependentInput'
import AutoApplyInputs from '../autoApply/AutoApplyInputs'
import CreateCustomCOGSFieldDialog from './CreateCustomCOGSFieldDialog'
import GeneralHelperCard from './GeneralHelperCard'

const styles = {
  inputStyle: {
    width: 256,
  },
  label: {
    clear: 'both',
    float: 'left',
    width: '12em',
    paddingTop: 18,
  },
  left: {
    marginLeft: '2em',
    float: 'left',
  },
  leftEl: {
    width: '8em',
  },
  right: {
    marginLeft: '2em',
    float: 'left',
  },
  rightEl: {
    width: '4em',
  },
  h2: {
    marginTop: 40,
  },
  detailStyle: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '1em',
    minWidth: '12em',
  },
  elStyle: { width: '12em', marginRight: '2em' },
  elStyleWide: { width: '16em', marginRight: '2em' },
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  description: {
    lineHeight: '1.5',
    color: '#626262',
  },
}))

const cost_fields = {
  COGS: [
    ['Racking', 'racking'],
    ['BOS', 'bos'],
  ],
  Labour: [['Labour General', 'labor']],
  'Acquisition Cost': [
    ['Allocation of Lead Gen', 'allocation_of_lead_gen'],
    ['Allocation of Salary', 'allocation_of_salary'],
    ['Presale Software and Design', 'presale_software_and_design'],
    ['Commission', 'commission'],
  ],
  Fulfillment: [
    ['Supplier Shipping', 'supplier_shipping'],
    ['Job Site Shipping and Warehousing', 'job_site_shipping_and_warehousing'],
    ['Project Management', 'project_management'],
    ['Design Drawings', 'design_drawings'],
    ['Permit Costs', 'permit_costs'],
    ['Other Costs', 'other_costs'],
  ],
}

const CostsHeaderRow = ({ tableCellPadding }) => {
  const translate = useTranslate()
  return (
    <TableHead displaySelectAll={false} adjustForCheckbox={false}>
      <TableRow key={'header'}>
        <TableCell style={{ padding: tableCellPadding, width: '35%' }}>{translate('Type')}</TableCell>
        <TableCell style={{ padding: tableCellPadding }}>{translate('Per System')}</TableCell>
        <TableCell style={{ padding: tableCellPadding }}>{translate('Per Panel')}</TableCell>
        <TableCell style={{ padding: tableCellPadding }}>{translate('Per Watt')}</TableCell>
      </TableRow>
    </TableHead>
  )
}
CostsHeaderRow.muiName = 'TableHead'

export const CostingList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
    }
  },
  { duplicate: duplicateAction }
)(({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete, allowDelete)

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  return (
    <List
      extraCreateButtonLabel={translate('ra.page.empty_new', { name: resourceName })}
      actions={<ListActions hasArchived={true} />}
      filters={<ShareabilityFilters />}
      {...props}
      pagination={
        <PaginationWithCsvActions
          csvUploadDialogTitle="Cost Scheme File Upload"
          csvUploadDialogDescription={
            <div className={classes.description}>
              <p>
                {translate(
                  'Upload a formatted CSV file to quickly make updates to your cost schemes. Best practice is to download a CSV file of your cost schemes, make changes and then upload the updated file.'
                )}
              </p>
              <p>
                {translate(
                  'Note that if you want to add new records, simply leave the ID column blank. You cannot remove existing records via .csv file upload - you must delete them in the table above.'
                )}
              </p>
            </div>
          }
        />
      }
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
    >
      <Datagrid duplicate={props.duplicate}>
        <TextFieldWithSharedEntityIcon source="title" label="Title" />
        <BooleanField source="priority" label="Auto-apply" textAlign={'center'} displayInCard={true} sortable={false} />
        <EditOrImportButton
          source="actions"
          org_id={props.org_id}
          allowDelete={allowDelete}
          allowEdit={allowEdit}
          label="Actions"
          resource="costings"
          duplicate={props.duplicate}
          sortable={false}
          hideShared={true}
        />
      </Datagrid>
    </List>
  )
})

const getCustomCostingFieldsByType = (customFields, type) => {
  if (!customFields) return []
  else
    return customFields
      .filter((config) => getModelByFieldName(config.field) === type)
      .map((config) => [config.label, config.field])
}

const _CostingEdit = ({ accessRights, ...props }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [customFieldDialogMode, setCustomFieldDialogMode] = useState('')
  const orgId = useSelector(authSelectors.getOrgId)
  const org = useSelector(getOrgFromState)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  let [customCogsBuildupFields, setCustomCogsBuildupFields] = useState([])
  let [customCogsAcquisitionFields, setCustomCogsAcquisitionFields] = useState([])
  let [customCogsFulfillmentFields, setCustomCogsFulfillmentFields] = useState([])
  useEffect(() => {
    setCustomCogsBuildupFields(getCustomCostingFieldsByType(org?.custom_fields, 'COGS'))
    setCustomCogsAcquisitionFields(getCustomCostingFieldsByType(org?.custom_fields, 'Acquisition'))
    setCustomCogsFulfillmentFields(getCustomCostingFieldsByType(org?.custom_fields, 'Fulfillment'))
  }, [org])

  const tableCellPadding = isMobile ? '16px 4px 16px 0px' : '16px 16px 16px 0'

  return useEditShareable({
    accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm
        validate={(values) => validateTitle(values, translate)}
        toolbar={<TrackedToolbar {...props} />}
        disabled={!access.allowEdit}
      >
        <StandardInputs />
        <Accordion className={classes.root} classes={{ expanded: 'ExpansionPanelExpanded' }}>
          <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
            {translate('COGS')}
          </AccordionSummary>
          <AccordionDetails className="AccordionDetails">
            <div
              style={{
                minHeight: 320,
                position: 'relative',
                width: '100%',
                padding: 0,
              }}
            >
              <h2 style={styles.h2}>
                {translate('COGS')}
                <span style={{ marginLeft: 5, fontSize: 12 }}>({translate('Excluding Tax')})</span>
              </h2>
              <Table style={{ tableLayout: 'fixed' }} selectable={false}>
                <CostsHeaderRow tableCellPadding={tableCellPadding} />

                <TableBody displayRowCheckbox={false}>
                  {cost_fields['COGS'].concat(customCogsBuildupFields).map((item) => (
                    <TableRow key={item[1]}>
                      <TableCell style={{ padding: tableCellPadding, width: '35%' }}>{translate(item[0])}</TableCell>
                      {['per_system', 'per_panel', 'per_watt'].map((units, index2) => (
                        <TableCell key={index2} style={{ padding: tableCellPadding }}>
                          <NumberInput
                            margin={'none'}
                            inputProps={{ style: { textOverflow: 'ellipsis' } }}
                            name={item[1] + '_' + units}
                            startAdornment={props.currencySymbol}
                            step={1}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <p>
                {translate('You can create upto 5 custom COGS buildup types.')}{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setCustomFieldDialogMode('COGS')}
                >
                  {translate('Click here to create')}
                </span>
              </p>
              <div style={{ clear: 'both' }} />

              <h2 style={styles.h2}>
                {translate('Labour')}
                <span style={{ marginLeft: 5, fontSize: 12 }}>({translate('Excluding Tax')})</span>
              </h2>
              <Table style={{ tableLayout: 'fixed' }} selectable={false}>
                <CostsHeaderRow tableCellPadding={tableCellPadding} />

                <TableBody displayRowCheckbox={false}>
                  {cost_fields['Labour'].map((item) => (
                    <TableRow key={item[1]}>
                      <TableCell style={{ padding: tableCellPadding, width: '35%' }}>{translate(item[0])}</TableCell>
                      {['per_system', 'per_panel', 'per_watt'].map((units, index2) => (
                        <TableCell key={index2} style={{ padding: tableCellPadding }}>
                          <NumberInput
                            margin={'none'}
                            inputProps={{ style: { textOverflow: 'ellipsis' } }}
                            name={item[1] + '_' + units}
                            startAdornment={props.currencySymbol}
                            step={1}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div style={{ clear: 'both' }} />

              <h2 style={styles.h2}>
                {translate('Acquisition Cost')}
                <span style={{ marginLeft: 5, fontSize: 12 }}>({translate('Excluding Tax')})</span>
              </h2>
              <Table style={{ tableLayout: 'fixed' }} selectable={false}>
                <CostsHeaderRow tableCellPadding={tableCellPadding} />

                <TableBody displayRowCheckbox={false}>
                  {cost_fields['Acquisition Cost'].concat(customCogsAcquisitionFields).map((item) => (
                    <TableRow key={item[1]}>
                      <TableCell style={{ padding: tableCellPadding, width: '35%' }}>{translate(item[0])}</TableCell>
                      {['per_system', 'per_panel', 'per_watt'].map((units, index2) => (
                        <TableCell key={index2} style={{ padding: tableCellPadding }}>
                          <NumberInput
                            margin={'none'}
                            inputProps={{ style: { textOverflow: 'ellipsis' } }}
                            name={item[1] + '_' + units}
                            startAdornment={props.currencySymbol}
                            step={1}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                  <TableRow key={'commission_percentage_of_cogs_and_labor'}>
                    <TableCell style={{ padding: tableCellPadding }}>
                      {translate('Commission percentage COGS and labour')}
                    </TableCell>
                    <TableCell style={{ padding: tableCellPadding }} colSpan={2}>
                      <NumberInput
                        margin={'none'}
                        name={'commission_percentage_of_cogs_and_labor'}
                        endAdornment={'%'}
                        step={1}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <p>
                {translate('You can create upto 3 custom Acquisition types.')}{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setCustomFieldDialogMode('Acquisition')}
                >
                  {translate('Click here to create')}
                </span>
              </p>
              <div style={{ clear: 'both' }} />
              <h2 style={styles.h2}>
                {translate('Fulfillment')}
                <span style={{ marginLeft: 5, fontSize: 12 }}>({translate('Excluding Tax')})</span>
              </h2>
              <Table style={{ tableLayout: 'fixed' }} selectable={false}>
                <CostsHeaderRow tableCellPadding={tableCellPadding} />

                <TableBody displayRowCheckbox={false}>
                  {cost_fields['Fulfillment'].concat(customCogsFulfillmentFields).map((item) => (
                    <TableRow key={item[1]}>
                      <TableCell style={{ padding: tableCellPadding, width: '35%' }}>{translate(item[0])}</TableCell>
                      {['per_system', 'per_panel', 'per_watt'].map((units, index2) => (
                        <TableCell key={index2} style={{ padding: tableCellPadding }}>
                          <NumberInput
                            margin={'none'}
                            inputProps={{ style: { textOverflow: 'ellipsis' } }}
                            name={item[1] + '_' + units}
                            startAdornment={props.currencySymbol}
                            step={1}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <p>
                {translate('You can create upto 3 custom Fulfillment types.')}'{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setCustomFieldDialogMode('Fulfillment')}
                >
                  {translate('Click here to create')}
                </span>
              </p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.root} classes={{ expanded: 'ExpansionPanelExpanded' }}>
          <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
            {translate('Extra Costs')}
          </AccordionSummary>
          <AccordionDetails className="AccordionDetails">
            <div
              style={{
                minHeight: 320,
                position: 'relative',
                width: '100%',
                padding: 0,
              }}
            >
              <h2 style={styles.h2}>
                {translate('Extra Costs')} -{translate('Job Difficulty')}{' '}
                <span style={{ marginLeft: 5, fontSize: 12 }}>({translate('Excluding Tax')})</span>
              </h2>

              <Table style={{ tableLayout: 'fixed' }} selectable={false} {...props}>
                <CostsHeaderRow tableCellPadding={tableCellPadding} />
                <TableBody displayRowCheckbox={false}>
                  {[
                    { name: 'storeys_two_cost', label: 'Two Stories' },
                    {
                      name: 'storeys_three_plus_cost',
                      label: 'Three+ Stories',
                    },
                    {
                      name: 'phase_1_cost',
                      label: '1 Phase',
                    },
                    {
                      name: 'phase_2_cost',
                      label: '2 Phase',
                    },
                    {
                      name: 'phase_3_cost',
                      label: '3 Phase',
                    },
                    { name: 'steep_pitch_1_cost', label: 'Steep Pitch Slope' },
                    {
                      name: 'steep_pitch_2_cost',
                      label: 'Very Steep Pitch Slope',
                    },
                    // 'split_array_layout_cost',
                    { name: 'tilt_rack_cost', label: 'Tilt Rack' },
                    { name: 'steep_rack_1_cost', label: 'Moderate Rack Slope' },
                    { name: 'steep_rack_2_cost', label: 'Steep Rack Slope' },
                    { name: 'steep_rack_3_cost', label: 'Very Steep Rack Slope' },
                  ].map((field, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ padding: tableCellPadding, width: '35%' }}>
                        {translate(field.label)}
                      </TableCell>
                      {['per_system', 'per_panel', 'per_watt'].map((units, index2) => (
                        <TableCell key={index2} style={{ padding: tableCellPadding }}>
                          <NumberInput
                            margin={'none'}
                            inputProps={{ style: { textOverflow: 'ellipsis' } }}
                            name={field.name + '_' + units}
                            startAdornment={props.currencySymbol}
                            step={1}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <h2 style={styles.h2}>{translate('Steep Pitch Range')}</h2>
              <NumberInput
                {...props}
                label={translate('Steep Pitch Slope')}
                name="steep_pitch_1_slope"
                source="steep_pitch_1_slope"
                endAdornment="°"
              />
              <NumberInput
                {...props}
                label={translate('Very Steep Pitch Slope')}
                name="steep_pitch_2_slope"
                source="steep_pitch_2_slope"
                endAdornment="°"
              />

              <h2 style={styles.h2}>{translate('Steep Rack Range')}</h2>
              <NumberInput
                {...props}
                label={translate('Moderate Rack Slope')}
                name="steep_rack_1_slope"
                source="steep_rack_1_slope"
                endAdornment="°"
              />
              <NumberInput
                {...props}
                label={translate('Steep Rack Slope')}
                name="steep_rack_2_slope"
                source="steep_rack_2_slope"
                endAdornment="°"
              />
              <NumberInput
                {...props}
                label={translate('Very Steep Rack Slope')}
                name="steep_rack_3_slope"
                source="steep_rack_3_slope"
                endAdornment="°"
              />

              <h2 style={styles.h2}>
                {translate('Extra Cost')} -{translate('Roof Type')}
                <span style={{ marginLeft: 5, fontSize: 12 }}>({translate('Excluding Tax')})</span>
              </h2>

              <Table style={{ tableLayout: 'fixed' }} selectable={false} {...props}>
                <CostsHeaderRow tableCellPadding={tableCellPadding} />
                <TableBody displayRowCheckbox={false}>
                  {[1, 2, 3, 4, 5].map((index) => (
                    <TableRow key={index}>
                      {
                        <TableCell style={{ padding: tableCellPadding, width: '35%' }}>
                          <CustomField
                            key={index}
                            component={ReferenceInput}
                            label={translate('Roof Type Adder number', { number: index })}
                            name={'roof_type_adder_' + index + '_roof_type'}
                            reference="roof_types"
                            filter={{
                              fav: 1,
                              org_id: orgId,
                            }}
                            optionValueField="url"
                            allowEmpty
                            {...props}
                          >
                            <CustomField
                              component={SelectInput}
                              options={{ style: { width: '100%', maxWidth: 256 } }}
                              name="name"
                              optionText={(choice) => translate(choice?.name)}
                              optionValue="url"
                            />
                          </CustomField>
                        </TableCell>
                      }
                      {['per_system', 'per_panel', 'per_watt'].map((units, index2) => (
                        <TableCell key={index2} style={{ padding: tableCellPadding }}>
                          <NumberInput
                            margin={'none'}
                            inputProps={{ style: { textOverflow: 'ellipsis' } }}
                            name={'roof_type_adder_' + index + '_cost_' + units}
                            startAdornment={props.currencySymbol}
                            step={1}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </AccordionDetails>
        </Accordion>
        <GeneralHelperCard />
        <DependentInput dependsOn="priority" value={true}>
          <AutoApplyInputs enableByPriority={true} />
        </DependentInput>

        <CreateCustomCOGSFieldDialog mode={customFieldDialogMode} handleClose={() => setCustomFieldDialogMode('')} />
        <ShareabilitySelector />
      </SimpleForm>
    ),
  }).editPage
}

export const CostingEdit = compose(
  connect(
    (state) => ({
      currencySymbol: currencySymbolForCountry(getOrgCountryCodeFromState(state)),
    }),
    {}
  )
)(_CostingEdit)

export const CostingCreate = (props) => {
  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm validate={(values) => validateTitle(values, translate)} toolbar={<TrackedToolbar {...props} />}>
        <StandardInputs archive={false} />
      </SimpleForm>
    </Create>
  )
}

const validateTitle = (values, translate) => {
  const errors = {}
  if (!values.title) {
    errors.title = [translate('Title is required.')]
  }
  return errors
}
