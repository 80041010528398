import { CircularProgress } from '@material-ui/core'
import Tooltip from 'elements/tooltip/Tooltip'
import { Alert, Button, DialogActions, DialogContent, DialogTitle, styled, Typography } from 'opensolar-ui'
import React from 'react'
import { required, SelectInput, TextInput } from 'react-admin'
import { doNotTranslate } from 'util/misc'
import { InputRow, InputWrapper } from '../../../styles'
import { ProductType } from '../types'

const InfoWrapper = styled('div')({
  marginBottom: '12px',
})

const DialogContentWrapper = styled('div')({
  margin: '20px',
})

const productTypeChoices = Object.entries(ProductType).map(([key, value]) => ({
  id: value,
  name: value,
}))

interface PropTypes {
  isLoading: boolean
  hasValidationErrors: boolean
  submitError?: string
  handleSubmit: (formVals: Object) => void
  onClose: () => void
}
const SolarAppCreateProjectForm: React.FunctionComponent<PropTypes> = ({
  isLoading,
  hasValidationErrors,
  submitError,
  handleSubmit,
  onClose,
}) => (
  <>
    <DialogTitle>{doNotTranslate('Review Project Information')}</DialogTitle>
    <DialogContent>
      <DialogContentWrapper>
        <InfoWrapper>
          <Typography colorHex="#4D4D4D" textVariant="body2">
            {doNotTranslate(
              'Please review the details below before submitting the project to SolarAPP+. Ensure all information is accurate and up-to-date.'
            )}
          </Typography>
        </InfoWrapper>

        {submitError && <Alert severity="error">{submitError}</Alert>}

        <InputWrapper>
          <InputRow>
            <TextInput source="title" variant="outlined" label="Title" validate={[required()]} fullWidth />
          </InputRow>
          <InputRow>
            <SelectInput
              choices={productTypeChoices}
              source="product_type"
              variant="outlined"
              label="Product Type"
              validate={[required()]}
              fullWidth
            />
          </InputRow>
          <InputRow>
            <TextInput
              source="street_number"
              variant="outlined"
              label="Street Number"
              validate={[required()]}
              fullWidth
            />
            <TextInput source="street_name" variant="outlined" label="Street Name" validate={[required()]} fullWidth />
          </InputRow>
          <InputRow>
            <TextInput source="street_name_2" variant="outlined" label="Street Name 2" fullWidth />
          </InputRow>
          <InputRow>
            <TextInput source="city" variant="outlined" label="City" validate={[required()]} fullWidth />
            <TextInput source="state" variant="outlined" label="State" validate={[required()]} fullWidth />
          </InputRow>
          <InputRow>
            <TextInput source="zip" variant="outlined" label="Zip Code" validate={[required()]} fullWidth />
          </InputRow>
        </InputWrapper>
      </DialogContentWrapper>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="default" onClick={onClose}>
        {doNotTranslate('Discard')}
      </Button>

      <Tooltip
        title={
          hasValidationErrors
            ? doNotTranslate('To proceed, please fill in the required fields or correct the highlighted errors.')
            : ''
        }
      >
        <div>
          <Button
            startIcon={isLoading ? <CircularProgress size={18} color="inherit" /> : undefined}
            disabled={hasValidationErrors || isLoading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {doNotTranslate('Confirm')}
          </Button>
        </div>
      </Tooltip>
    </DialogActions>
  </>
)

export default SolarAppCreateProjectForm
