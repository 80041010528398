/**
 * Provides a non-functional placeholder stepper to preview the SolarApp integration.
 */
import type { CompactStepType } from 'opensolar-ui'
import { Checkbox, CompactStepper, styled } from 'opensolar-ui'
import { SOLAR_APP_PERMIT_STEPS } from 'projectSections/sections/manage3/integrations/SolarAppIntegration/SolarAppStepper/constants'

const CheckboxWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.grey[400],
  fontWeight: 500,
}))

const CheckboxLabelText = styled('span')({
  whiteSpace: 'nowrap',
})

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: `${theme.palette.grey[300]} !important`,
  padding: '2px',
  paddingRight: '4px',
  [theme.breakpoints.down('md')]: {
    paddingRight: 0,
  },
}))

const SolarAppPreviewStepper = () => {
  const permitAsSteps: CompactStepType[] = SOLAR_APP_PERMIT_STEPS.map((step) => ({
    id: step.step,
    label: (
      <CheckboxWrapper>
        <CustomCheckbox disabled />
        <CheckboxLabelText>{step.label}</CheckboxLabelText>
      </CheckboxWrapper>
    ),
    content: <></>,
  }))

  return <CompactStepper steps={permitAsSteps} activeStep={0} narrow />
}

export default SolarAppPreviewStepper
