import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { ListColumnBase, ProjectsListColumn, PROJECTS_LIST_COLUMNS_DEFAULT } from './views/utils'

export const useProjectColumns = (savedColData: undefined | ListColumnBase[]): { columns: ProjectsListColumn[] } => {
  const translate = useTranslate()
  const defaultCols = PROJECTS_LIST_COLUMNS_DEFAULT(translate)
  const defaultColMap = defaultCols.reduce((acc, col) => {
    acc[col.id] = col
    return acc
  }, {})

  const columns: ProjectsListColumn[] | undefined = useMemo(() => {
    if (savedColData === undefined) {
      return defaultCols
    }

    return reduceColsPreserveOrder(savedColData, defaultCols, defaultColMap)
  }, [savedColData, defaultCols])

  return { columns }
}

const reduceColsPreserveOrder = (
  savedColData: ListColumnBase[],
  defaultCols: ProjectsListColumn[],
  defaultColMap: Record<string, ProjectsListColumn>
) => {
  const colData: ProjectsListColumn[] = []
  const colIds = new Set()

  // Ensure we preserve the order of cols from savedColData
  savedColData.forEach((savedCol) => {
    const defautlCol = defaultColMap[savedCol.id]
    colData.push({ ...defautlCol, enabled: savedCol.enabled })
    colIds.add(savedCol.id)
  })

  // preserve order from default cols
  defaultCols.forEach((defaultCol) => {
    if (!colIds.has(defaultCol.id)) {
      colData.push(defaultCol)
      colIds.add(defaultCol.id)
    }
  })

  return colData
}
