import { BomGeneratorAbstract } from '../bom_generators/BomGeneratorAbstract'
import { PerPanelBomGeneratorAbstract } from '../bom_generators/PerPanelBomGenerator'
import { PerRowBomGeneratorAbstract } from '../bom_generators/PerRowBomGeneratorAbstract'
import {
  HorizontalRailPosition,
  PerRowRailBomGeneratorAbstract,
} from '../bom_generators/PerRowRailBomGeneratorAbstract'
import {
  BasicSpacingLayoutAdjusterAbstract,
  SpacingRuleSet,
} from '../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../MountingSystemAbstract'
import { CutAndSplicerAbstract } from '../post_processors/CutAndSplicerAbstract'
import { RoofHookAdderAbstract } from '../post_processors/RoofHookAdderAbstract'
import {
  CompatibilityParameters,
  Component,
  Direction,
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  Panel,
  PanelBlock,
  PanelRow,
  PanelWithPosition,
  RailComponent,
  RailType,
} from '../types'

const spacingRuleSet: SpacingRuleSet = {
  interPanel: { horizontalSpacing: 12, verticalSpacing: 12 },
}
class SunmodoSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = spacingRuleSet
}

// Note we are using two values for railOverhang. Sunmodo stipulates the overhang should be between 38.1 and 1/3 * mounting spacing. To match Sunmodo's design tool as closely as possible, we use the min value for the unsupported span between the rail end and the first roof fixing, and a larger value for the span that protrudes beyond the array.
const railOverhangMin = 38.1
const railOverhang = 300

class SunmodoBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const starterBom = await this.generateStarterBom(result)
    const bomWithPanelExtras = await this.generateBomFrom(SunmodoPanelExtrasAdder, starterBom)
    const bomWithLugs = await this.generateBomFrom(SunmodoLugAdder, bomWithPanelExtras)
    return this.postProcess(bomWithLugs)
  }

  generateStarterBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    if (this.block.orientation === 'portrait') {
      return this.generateBomFrom(SunmodoPortraitBomGenerator, result)
    } else {
      return this.generateBomFrom(SunmodoLandscapeBomGenerator, result)
    }
  }

  postProcess(result: MountingCalcResult): MountingCalcResult {
    const resultWithRoofHooks = new SunmodoRoofHookAdder(this.input).process(result)
    const resultWithSplices = new SunmodoRailSplicer(this.input).process(resultWithRoofHooks)
    // Add conduit mount with roof fixing at the quantity specified by the user
    const conduitMountQty = this.input.options.conduitMount ? Number(this.input.options.conduitMountQty) || 0 : 0
    const roofHook = chooseHook(this.input)
    const fastener = chooseFastener(roofHook, this.input.options.mountType as string)
    if (conduitMountQty) {
      resultWithSplices.items.push(
        ...Array(conduitMountQty).fill({
          name: this.input.options.conduitMount,
          components: [],
        }),
        ...Array(conduitMountQty).fill(roofHook).flat(),
        ...(fastener.includedInRoofHookProduct
          ? []
          : Array(conduitMountQty * fastener.qtyPerRoofHook)
              .fill(fastener)
              .flat())
      )
    }
    return resultWithSplices
  }
}

class SunmodoPanelExtrasAdder extends PerPanelBomGeneratorAbstract {
  generateBomForPanel(panel: PanelWithPosition, blockIndex: number): Item[] {
    let itemsToAdd: Item[] = []
    if (this.input.options.mlpeMount) {
      itemsToAdd.push({
        name: 'MLPE-SMR',
        components: [],
      })
    }
    if (this.input.options.wireClipQty) {
      itemsToAdd.push(
        ...Array(this.input.options.wireClipQty).fill({
          name: 'WIRE-SMR100-B',
          components: [],
        })
      )
    }
    return itemsToAdd
  }
}

class SunmodoLugAdder extends PerRowBomGeneratorAbstract {
  generateBomForRow(resultFromLastRow: MountingCalcResult, row: PanelRow): MountingCalcResult {
    resultFromLastRow.items.push({ name: 'GRND-SMR', components: [] })
    return resultFromLastRow
  }
}

export class SunmodoSMRPitched extends MountingSystemAbstract {
  layoutAdjusterClass = SunmodoSpacingAdjuster
  bomGeneratorClass = SunmodoBomGenerator

  getFastener(): Fastener {
    return chooseFastener(chooseHook(this.input), this.input.options.mountType as string)
  }

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      slopeRange: [0, 45],
      roofTypes: [
        'Tile Clay',
        'Tile Concrete',
        'Tile Slate',
        'Composition / Asphalt Shingle',
        'Trapezoidal',
        'Metal Standing Seam',
      ],
      roofTypeRequired: true,
    }
  }
}

class SunmodoPortraitBomGenerator extends PerRowRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.height * 0.6) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.height - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

class SunmodoLandscapeBomGenerator extends PerRowRailBomGeneratorAbstract {
  chooseRail() {
    return chooseRail(this.input, 'horizontal')
  }

  chooseMidClamp() {
    return chooseMidClamp(this.input)
  }

  chooseEndClamp() {
    return chooseEndClamp(this.input)
  }

  chooseEndCap() {
    return chooseEndCap(this.input)
  }

  getRailPositions(panel: Panel): HorizontalRailPosition[] {
    const distanceFromRailToPanelEdge = (panel.width * 0.6) / 2

    return [
      {
        top: distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
      {
        top: panel.width - distanceFromRailToPanelEdge,
        left: -railOverhang,
        right: railOverhang,
      },
    ]
  }
}

function chooseRail(input: MountingCalcInput, direction: Direction): RailComponent {
  return {
    type: MountingComponentType.RAIL,
    railType: 'continuous',
    direction,
    name: input.options.colour === 'black' ? 'SMR100-168-B' : 'SMR100-168-M',
    length: 0,
    top: 0,
    left: 0,
  }
}

function getFootAdapter(input: MountingCalcInput): string {
  if (input.options.colour === 'black') {
    return 'LFA-SMR100-B'
  } else {
    return 'LFA-SMR100-S'
  }
}

function chooseHook(input: MountingCalcInput) {
  const mountType = input.options.mountType
  const footAdapter = getFootAdapter(input)
  let result = [
    {
      name: footAdapter,
      components: [
        {
          name: footAdapter,
          type: MountingComponentType.OTHER,
          left: -10,
          top: -10,
        },
      ],
    },
  ]
  if (mountType === 'nanoRafterMount' || mountType === 'nanoDeckMount') {
    result.push({
      name: 'NANOMOUNT-B',
      components: [
        {
          name: 'NANOMOUNT-B',
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    })
  } else if (mountType === 'nanoBit') {
    result.push({
      name: 'NANOBIT-B', // includes lag bolt
      components: [
        {
          name: 'NANOBIT-B',
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    })
  } else if (mountType === 'soloFlash') {
    if (input.options.colour === 'black') {
      result.push({
        name: 'SOLO-B',
        components: [
          {
            name: 'SOLO-B',
            type: MountingComponentType.OTHER,
            left: -10,
            top: -10,
          },
        ],
      })
    } else {
      result.push({
        name: 'SOLO-S',
        components: [
          {
            name: 'SOLO-S',
            type: MountingComponentType.OTHER,
            left: -10,
            top: -10,
          },
        ],
      })
    }
  } else if (mountType === 'fiveTopTile' || mountType === 'sevenTopTile') {
    if (mountType === 'fiveTopTile') {
      result.push({
        name: 'TOPTILE-5-B',
        components: [
          {
            name: 'TOPTILE-5-B',
            type: MountingComponentType.ROOF_HOOK,
            left: -10,
            top: -10,
          },
        ],
      })
    } else {
      result.push({
        name: 'TOPTILE-7-B',
        components: [
          {
            name: 'TOPTILE-7-B',
            type: MountingComponentType.ROOF_HOOK,
            left: -10,
            top: -10,
          },
        ],
      })
    }
    if (input.options.colour === 'black') {
      result.push({
        name: 'LFT-OPEN-3-B',
        components: [
          {
            name: 'LFT-OPEN-3-B',
            type: MountingComponentType.OTHER,
            left: -10,
            top: -10,
          },
        ],
      })
    } else {
      result.push({
        name: 'LFT-OPEN-3-S',
        components: [
          {
            name: 'LFT-OPEN-3-S',
            type: MountingComponentType.OTHER,
            left: -10,
            top: -10,
          },
        ],
      })
    }
  } else if (mountType === 'mrbMount') {
    result.push({
      name: 'MRB-S',
      components: [
        {
          name: 'MRB-S',
          type: MountingComponentType.ROOF_HOOK,
          left: -10,
          top: -10,
        },
      ],
    })
  }
  return result
}

function chooseEndCap(input: MountingCalcInput): Item | null {
  if (input.options.endCap) {
    if (input.options.colour === 'black') {
      return {
        name: 'CAP-SMR100-B',
        components: [],
      }
    } else {
      return {
        name: 'CAP-SMR100-G',
        components: [],
      }
    }
  } else {
    return null
  }
}

function chooseEndClamp(input: MountingCalcInput): Item {
  const endClamp =
    input.options.endClamp === 'bottom'
      ? 'CLMP-BOT-SMR100-B'
      : input.options.colour === 'black'
      ? 'CLMP-END-SMR-B'
      : 'CLMP-END-SMR-S'
  return {
    name: endClamp,
    components: [
      {
        name: endClamp,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseMidClamp(input: MountingCalcInput): Item {
  const midClamp = input.options.colour === 'black' ? 'CLMP-MID-SMR-B' : 'CLMP-MID-SMR-S'
  return {
    name: midClamp,
    components: [
      {
        name: midClamp,
        type: MountingComponentType.CLAMP,
        left: -10,
        top: -10,
      },
    ],
  }
}

function chooseFastener(hook: Item[], mountType: string): Fastener {
  let qtyPerRoofHook, includedInRoofHookProduct, name, length, diameter
  if (mountType === 'nanoDeckMount') {
    includedInRoofHookProduct = false
    name = 'SCREW-B'
    qtyPerRoofHook = 4
    length = 76.2
    diameter = 6.3
  } else if (mountType === 'nanoRafterMount' || mountType === 'nanoBit') {
    includedInRoofHookProduct = mountType === 'nanoBit' ? true : false
    name = 'LAG-B'
    qtyPerRoofHook = 1
    length = 110
    diameter = 8
  } else if (mountType === 'fiveTopTile' || mountType === 'sevenTopTile') {
    includedInRoofHookProduct = true
    name = '#14 X 5” Wood Screw'
    qtyPerRoofHook = 3
    length = mountType === 'fiveTopTile' ? 127 : 177.8
    diameter = 6.15
  } else if (mountType === 'soloFlash') {
    includedInRoofHookProduct = true
    name = '4" 5/16” [M8] Lag Screw'
    qtyPerRoofHook = 1
    length = 102
    diameter = 8
  } else {
    // mountType = mrbMount
    includedInRoofHookProduct = true
    name = '1/4 x 1” Hex Washer Head Self-drilling Screw'
    qtyPerRoofHook = 4
    length = 25.4
    diameter = 6.35
  }
  return {
    includedInRoofHookProduct,
    name,
    qtyPerRoofHook,
    length,
    diameter,
    components: [],
  }
}

class SunmodoRoofHookAdder extends RoofHookAdderAbstract {
  spacingRuleSet = spacingRuleSet
  railOverhang = railOverhangMin

  getRailType(input: MountingCalcInput): RailType {
    return 'continuous'
  }

  chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent) {
    return chooseHook(input)
  }

  chooseFastener(hooks) {
    return chooseFastener(hooks, this.input.options.mountType as string)
  }
}

class SunmodoRailSplicer extends CutAndSplicerAbstract {
  isTarget(component: Component): boolean {
    return component?.type === MountingComponentType.RAIL
  }

  chooseSplice(railName: string, input: MountingCalcInput): Item[] {
    return [
      {
        name: 'SPLC-SMR100',
        components: [
          {
            type: MountingComponentType.SPLICE,
            name: 'SPLC-SMR100',
            top: 0,
            left: 0,
          },
        ],
      },
    ]
  }

  getFullLength(railName: string): number {
    return 4267 // 168 inches in mm
  }
}
