import { Button, List, ListItem, ListItemText } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Typography, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import useTranslateParse from 'util/useTranslateParse'
import ContactCardWrapper from '../components/ContactCardWrapper'
import { FormRouteInfoPropTypes } from '../types'
import { contactIsNotEmpty } from '../utils'

const DescriptionWrapper = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: 10,
  marginBottom: 16,
})

const StyledList = styled(List)({
  padding: 0,
})

const StyledItem = styled(ListItemText)({
  display: 'block',
  margin: 0,
  '& span': {
    lineHeight: 1.5,
  },
})

const RepsContainer = styled('div')(({ theme }) => ({
  padding: '1rem',
  margin: '1rem 0',
  background: theme.palette.grey[50],
  textAlign: 'center',
}))

const BtnContainer = styled('div')({
  textAlign: 'center',
  paddingBottom: '16px',
})

const ControlDescriptionTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  const formVals = useFormState().values
  const companyRep = formVals?.companyRep
  const owner1 = formVals.ownershipInfoArr?.[0]
  const owner2 = formVals.ownershipInfoArr?.[1]
  const owner3 = formVals.ownershipInfoArr?.[2]
  const owner4 = formVals.ownershipInfoArr?.[3]

  const contactIsFilled = useMemo(() => {
    return (
      (owner1 && contactIsNotEmpty(owner1)) ||
      (owner2 && contactIsNotEmpty(owner2)) ||
      (owner3 && contactIsNotEmpty(owner3)) ||
      (owner4 && contactIsNotEmpty(owner4)) ||
      (companyRep && contactIsNotEmpty(companyRep))
    )
  }, [companyRep, owner1, owner2, owner3, owner4])

  const allContactsFilled = useMemo(() => {
    return (
      owner1 &&
      contactIsNotEmpty(owner1) &&
      owner2 &&
      contactIsNotEmpty(owner2) &&
      owner3 &&
      contactIsNotEmpty(owner3) &&
      owner4 &&
      contactIsNotEmpty(owner4) &&
      companyRep &&
      contactIsNotEmpty(companyRep)
    )
  }, [companyRep, owner1, owner2, owner3, owner4])

  const onAddContact = () => {
    props.navigateToPage('personInformation')
    props.setFieldPrefixFilter(null)
    logAmplitudeEvent('psp_onboarding_add_contact_clicked', {})
  }

  return (
    <div>
      <DescriptionWrapper>
        <Typography textVariant="h5SemiBold">{translate('Who controls your business?')}</Typography>
        <Typography textVariant="body1">
          {translate(
            'To protect you and our community, we need to verify the information of two types of key contacts:'
          )}
        </Typography>

        <StyledList>
          <ListItem>
            <StyledItem>
              {translateParse(
                '<strong>Owner(s)<strong> - All individuals who own 25% or more of your business must be added. If the Owner(s) also serve as the Company Representative, you do not need to enter a Company Representative.',
                {
                  strong: (label: string) => <strong>{label}</strong>,
                }
              )}
            </StyledItem>
          </ListItem>
          <ListItem>
            <StyledItem>
              {translateParse(
                '<strong>Company Representative<strong> - The person who oversees the business and has significant control over the entity (e.g. a CEO, COO, CFO, or Director).',
                {
                  strong: (label: string) => <strong>{label}</strong>,
                }
              )}
            </StyledItem>
          </ListItem>
        </StyledList>
        <Typography textVariant="body1">
          {translateParse(
            'All Information provided in this section must be the personal information of the representative, not for the business. Up to four contacts can be entered. Owner Info and Bank Info <strong>must be completed in a single session<strong> - please ensure you have all of the information available.',
            {
              strong: (label: string) => <strong>{label}</strong>,
            }
          )}
        </Typography>
      </DescriptionWrapper>
      {!contactIsFilled ? (
        <RepsContainer>
          <Typography textVariant="body1Bold">{translate("You haven't added any contacts yet")}</Typography>
          <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={onAddContact}>
            {translate('Add Contact')}
          </Button>
        </RepsContainer>
      ) : (
        <>
          <RepsContainer>
            <ContactCardWrapper
              ownersData={formVals.ownershipInfoArr}
              companyRepData={companyRep}
              setFieldPrefixFilter={props.setFieldPrefixFilter}
              navigateToPage={props.navigateToPage}
            />
          </RepsContainer>

          {!allContactsFilled && (
            <BtnContainer>
              <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={onAddContact}>
                {translate('Add Contact')}
              </Button>
            </BtnContainer>
          )}
        </>
      )}
    </div>
  )
}

export default ControlDescriptionTop
