import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markCreditAppAsOpened, myEnergySelectors, showCheckoutDialog } from 'ducks/myEnergy'
import type { CTAConfigJson } from 'myenergy/selectionComponent/loanApplicationButton/generic/DisclaimerRedirectDialog'
import { getInitialValuesByIntegration } from 'myenergy/selectionComponent/loanApplicationButton/generic/hostedForms/initialValueSetters'
import { doFormRedirect } from 'myenergy/selectionComponent/loanApplicationButton/generic/utils'
import { CheckoutActionCallback, FinanceCtaType, LoanApplicationType } from 'opensolar-checkout'
import { useCallback } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'

var restClientInstance = restClient(window.API_ROOT + '/api')

type IframeResponseType = {
  data: {
    success: boolean
    url: string | undefined
    contents: string | undefined
    form_request_body: Object | undefined
  }
}

const useApiSideEffect = () => {
  return useCallback(
    async ({ orgId, actionData }: { orgId: number; actionData: LoanApplicationType['actionData'] }) => {
      await restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/finance/${actionData.finance_integration}/cta_side_effect/`,
        data: {
          project_id: actionData.project_id,
          system_uuid: actionData.system_uuid,
          payment_option_id: actionData.payment_option_id,
          cta_id: actionData.cta_id,
        },
      })
        .then((res) => console.log('res', res))
        .catch((err) => console.log('err', err))
    },
    []
  )
}

const useOpenIframeDialog = () => {
  const dispatch = useDispatch()

  return useCallback(
    ({
      iframeURL,
      iframeContents,
      selectedPaymentOptionName,
    }: {
      iframeURL?: string
      iframeContents?: string
      selectedPaymentOptionName: string
    }) => {
      dispatch(
        showCheckoutDialog({
          type: 'GenericFinanceIframeDialog',
          config: {
            iframeURL,
            iframeContents,
            selectedPaymentOptionName,
          },
        })
      )
    },
    []
  )
}

const useOpenHostedCreditApplicationDialog = () => {
  const dispatch = useDispatch()

  return useCallback(
    ({
      orgId,
      actionData,
      project,
      initialValues,
    }: {
      orgId: number
      actionData: LoanApplicationType['actionData']
      project: ProjectType
      initialValues: object
    }) => {
      dispatch(
        showCheckoutDialog({
          type: 'HostedCreditApplicationDialog',
          config: {
            orgId,
            projectId: actionData.project_id,
            systemUuid: actionData.system_uuid,
            paymentOptionId: actionData.payment_option_id,
            integration: actionData.finance_integration!,
            project,
            initialValues,
          },
        })
      )
    },
    []
  )
}

const useOpenIframeFromOpenSolarAPI = () => {
  const notify = useNotify()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const openIframeDialog = useOpenIframeDialog()

  return useCallback(
    async ({
      orgId,
      actionData,
      selectedPaymentOptionName,
      onSuccess,
    }: {
      orgId: number
      actionData: LoanApplicationType['actionData']
      selectedPaymentOptionName: string
      onSuccess(): void
    }) => {
      await restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/projects/${actionData.project_id}/systems/${actionData.system_uuid}/${actionData.payment_option_id}/application_iframe/`,
      })
        .then(
          (response: IframeResponseType) => {
            if (response.data.url && response?.data.form_request_body) {
              doFormRedirect(response.data.url, response.data.form_request_body)
            } else if (response?.data?.url) {
              openIframeDialog({ iframeURL: response.data.url, selectedPaymentOptionName })
            } else if (response.data.contents) {
              openIframeDialog({ iframeContents: response.data.contents, selectedPaymentOptionName })
            }
            onSuccess()
          },
          (reject: any) => {
            console.log(reject)
            notify(translate(reject.message), 'warning')
          }
        )
        .catch((err) => {
          if (err?.body?.message) {
            notify(translate(err.body.message), 'warning')
            logAmplitudeEvent('integrated_finance_application_open_error', {
              integration: actionData?.finance_integration,
            })
          }
        })
        .finally(() => {
          dispatch(hideFullScreenLoader())
          dispatch(markCreditAppAsOpened())
        })
    },
    []
  )
}

const useOpenDisclaimerRedirectDialog = () => {
  const dispatch = useDispatch()
  return useCallback(
    ({
      orgId,
      actionData,
      ctaDialogConfigJson,
    }: {
      orgId: number
      actionData: LoanApplicationType['actionData']
      ctaDialogConfigJson: CTAConfigJson
    }) => {
      dispatch(
        showCheckoutDialog({
          type: 'DisclaimerRedirectDialog',
          config: {
            orgId,
            projectId: actionData.project_id,
            systemUuid: actionData.system_uuid,
            paymentOptionId: actionData.payment_option_id,
            ctaDialogConfigJson,
          },
        })
      )
    },
    []
  )
}

const useRedirectLenderDocsURL = () => {
  const notify = useNotify()
  const translate = useTranslate()
  const dispatch = useDispatch()

  return useCallback(
    async ({
      orgId,
      actionData,
      onSuccess,
    }: {
      orgId: number
      actionData: LoanApplicationType['actionData']
      onSuccess(): void
    }) => {
      await restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/projects/${actionData.project_id}/systems/${actionData.system_uuid}/${actionData.payment_option_id}/lender_docs/`,
      })
        .then(
          (response: IframeResponseType) => {
            if (response.data.url) {
              window.location.href = response.data.url
            }
            onSuccess()
          },
          (reject: any) => {
            console.log(reject)
            notify(translate(reject.message), 'warning')
          }
        )
        .catch((err) => {
          if (err?.body?.message) {
            notify(translate(err.body.message), 'warning')
            logAmplitudeEvent('integrated_finance_application_open_error', {
              integration: actionData?.finance_integration,
            })
          }
        })
        .finally(() => {
          dispatch(hideFullScreenLoader())
          dispatch(markCreditAppAsOpened())
        })
    },
    []
  )
}

const useGenericApplicationCallback = (): CheckoutActionCallback => {
  const apiSideEffect = useApiSideEffect()
  const openIframeDialog = useOpenIframeDialog()
  const openHostedCreditApplicationDialog = useOpenHostedCreditApplicationDialog()
  const openIframeFromOpenSolarAPI = useOpenIframeFromOpenSolarAPI()
  const redirectLenderDocsURL = useRedirectLenderDocsURL()
  const openDisclaimerRedirectDialog = useOpenDisclaimerRedirectDialog()
  const checkoutElements = useSelector(myEnergySelectors.getCheckoutElements)
  return useCallback(
    async ({ elementData, proposalData, additionalActionData }) => {
      if (elementData.type !== 'LoanApplication') {
        Sentry.captureException(new Error('Invalid element data for LoanApplication callback'))
        return
      }
      if (!elementData.actionData.finance_integration) {
        Sentry.captureException(new Error('Invalid element data for generic application callback'))
        return
      }
      const { actionData } = elementData
      const orgId = proposalData.selectedPaymentOption?.org_id
        ? proposalData.selectedPaymentOption.org_id
        : actionData.org_id
      const project = proposalData.selectedProject as ProjectType
      const selectedPaymentOptionName = proposalData.selectedPaymentOption.title
      const initialValues = getInitialValuesByIntegration(actionData?.finance_integration, project)

      const logOpenSuccess = () => {
        logAmplitudeEvent('integrated_finance_application_opened', {
          integration: actionData?.finance_integration,
          project_id: actionData.project_id,
          payment_option_id: actionData.payment_option_id,
          system_id: actionData.system_id,
        })
      }

      if (actionData.application_url) {
        logOpenSuccess()
        openIframeDialog({ iframeURL: actionData.application_url, selectedPaymentOptionName })
      } else if (actionData.cta_type === 'submit_credit_application_hosted') {
        openHostedCreditApplicationDialog({ orgId, actionData, project, initialValues })
      } else if (
        actionData.cta_type === FinanceCtaType.FETCH_IFRAME_URL ||
        actionData.cta_type === FinanceCtaType.FORM_REDIRECT ||
        actionData.cta_type === FinanceCtaType.SUBMIT_CREDIT_APPLICATION_IFRAME
      ) {
        await openIframeFromOpenSolarAPI({
          orgId,
          actionData,
          selectedPaymentOptionName,
          onSuccess: logOpenSuccess,
        })
      } else if (actionData.cta_type === FinanceCtaType.VIEW_LENDER_DOCS) {
        await redirectLenderDocsURL({
          orgId,
          actionData,
          onSuccess: logOpenSuccess,
        })
      } else if (actionData.cta_type === FinanceCtaType.DISCLAIMER_BEFORE_REDIRECT) {
        const ctaDialogConfigJson = checkoutElements.options?.ctaDialogConfigJson
        const error = new Error('Failed to open disclaimer redirect dialog due to ctaDialogConfigJson is undefined')
        if (ctaDialogConfigJson === undefined) {
          Sentry.captureException(error)
          console.error(error)
          return
        }
        openDisclaimerRedirectDialog({
          orgId,
          actionData,
          ctaDialogConfigJson,
        })
      }

      if (actionData.has_api_side_effect) {
        apiSideEffect({ orgId, actionData })
      }
    },
    [checkoutElements]
  )
}

export default useGenericApplicationCallback
