import { InfoOutlined, VisibilityOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { styled } from 'opensolar-ui'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import BlueInfoCard from '../components/BlueInfoCard'
import GreyInfoCard from '../components/GreyInfoCard'
import { FormRouteInfoPropTypes } from '../types'

const ChartImgWrapper = styled('img')({
  height: '7rem',
  margin: '10px 0',
})

const ImageWrapper = styled('img')({
  width: '90%',
  display: 'block',
  margin: 'auto',
})

const VerifyBusinessRight: React.FC<FormRouteInfoPropTypes> = (props) => {
  const orgCountry: string = useSelector(orgSelectors.getOrgIso2)?.toString() || ''

  const GOV_ID_DESCRIPTION_MAP = {
    GB:
      "A VAT number is a unique ID that HMRC provides to businesses when they register for VAT. In the UK, VAT numbers are nine digits long and always have the prefix 'GB'.<strong> Do not include the 'GB' country code in this field when submitting.<strong>",
    US: 'Your Federal Employer Identification Number (FEIN) is a unique number assigned to your business by the IRS.',
    AU: null,
    default:
      'Your Federal Employer Identification Number (FEIN) is a unique number assigned to your business by the IRS.',
  }

  const GOV_ID_DOCUMENT_MAP = {
    GB:
      "VAT registration certificate issued by HMRC contains your VAT number and it's included on VAT returns. If you're unable to find it, check your accounting software or you can contact HMRC for assistance.",
    US: 'Your business legal name and FEIN are in the header of your IRS Form CP-575',
    AU:
      'ACN is a unique nine-digit number issued by the Australian Securities and Investments Commission (ASIC) to companies registered in Australia. ABN is a unique 11-digit number issued by the Australian Taxation Office (ATO) to all businesses in Australia.',
    default: 'Your business legal name and FEIN are in the header of your IRS Form CP-575',
  }

  const CC_SAVINGS_MAP = {
    GB: '30%',
    US: '20%',
    AU: '40%',
    default: '20%',
  }

  const govIdDescription = useMemo(() => {
    return orgCountry in GOV_ID_DESCRIPTION_MAP ? GOV_ID_DESCRIPTION_MAP[orgCountry] : GOV_ID_DESCRIPTION_MAP.default
  }, [orgCountry])

  const govIdDocument = useMemo(() => {
    return GOV_ID_DOCUMENT_MAP[orgCountry] || GOV_ID_DOCUMENT_MAP.default
  }, [orgCountry])

  const ccSavings = useMemo(() => {
    return CC_SAVINGS_MAP[orgCountry] || CC_SAVINGS_MAP.default
  }, [orgCountry])

  const infoContent = [
    {
      id: 1,
      icon: <InfoOutlined />,
      header: 'What Business Tax ID is required?',
      description: govIdDescription,
    },
    {
      id: 2,
      icon: <VisibilityOutlined />,
      header: 'Where can I find this information?',
      description: govIdDocument,
    },
  ]

  return (
    <div>
      <BlueInfoCard
        header={`Solar businesses can save up to ${ccSavings} on credit card transactions with CashFlow`}
        description={'Compared to consumer point of sale transaction fees'}
      >
        <ChartImgWrapper src={`${window.PUBLIC_URL}/images/cashflow/pie-chart.svg`} />
      </BlueInfoCard>

      <GreyInfoCard content={infoContent}>
        {orgCountry !== 'GB' && <ImageWrapper src={`${window.PUBLIC_URL}/images/cashflow/IRS Form.svg`} />}
      </GreyInfoCard>
    </div>
  )
}
export default VerifyBusinessRight
