import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/styles';
import { StyleWarningLevel } from './types';
var useWarningStyles = makeStyles({
    badStyles: {
        marginLeft: 10,
        whiteSpace: 'pre',
    },
    badStyle: {
        '&.info': {
            color: 'lightblue',
        },
        '&.warning': {
            color: 'orange',
        },
        '&.error': {
            color: 'red',
        },
        '&::before': {
            content: '""',
            display: 'inline-block',
            width: 20,
        },
        '&.error::before': {
            content: '"⚠"',
        },
    },
}, { name: 'StyleWarnings' });
export var StyleWarnings = function (_a) {
    var name = _a.name, notices = _a.notices;
    var classes = useWarningStyles();
    if (!(notices === null || notices === void 0 ? void 0 : notices.length))
        return null;
    return (_jsxs("div", { className: classes.badStyles, children: [_jsx("div", { children: name }), notices.map(function (notice) { return (_jsxs("div", { className: classes.badStyle + ' ' + getLevelStyle(notice.level), children: [notice.key, ": ", notice.value] }, String(notice.key))); })] }));
};
var getLevelStyle = function (level) {
    return StyleWarningLevel[level];
};
