import { addAccordionFormsAndSort } from 'customForm/card/addAccordionFormsAndSort'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import React, { useMemo } from 'react'
import { BooleanInput, FormDataConsumer, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { FormPlacementEnum } from 'resources/customForms/types'
import { titleCase } from '../../util/misc'
import { ExtraFieldsWrapper } from './PaymentOptions'
import PaymentOptionsOverviewInputs from './PaymentOptionsOverviewInputs'
import { payment_types_and_variables } from './fields'
import { AdvanceSettings } from './widgets/AdvanceSettings'
import { DealerFee } from './widgets/DealerFee'
import { DownPayment } from './widgets/DownPayment'
import { LoanParameters } from './widgets/LoanParameters'
import PaymentMilestones from './widgets/PaymentMilestones'
import { PaymentOptionAutoApply } from './widgets/PaymentOptionAutoApply'
import { Prepayment } from './widgets/Prepayment'
import { PromotionalPeriod } from './widgets/PromotionalPeriod'
import { ReversionRate } from './widgets/ReversionRate'
import { CheckoutFinance } from './widgets/CheckoutFinance'

const paymentTypeToFormPlacementEnum = {
  default: FormPlacementEnum.payment_option_form,
  cash: FormPlacementEnum.payment_option_form_cash,
  loan: FormPlacementEnum.payment_option_form_loan,
  ppa: FormPlacementEnum.payment_option_form_ppa,
  regular_payment: FormPlacementEnum.payment_option_form_regular,
  lease: FormPlacementEnum.payment_option_form_lease,
}

const payment_frequency_variables = [
  { id: 'weekly', name: 'Weekly (52 per year)' },
  { id: 'fortnightly', name: 'Fortnightly (26 per year)' },
  { id: 'bimonthly', name: 'Bimonthly (24 per year)' },
  { id: 'monthly', name: 'Monthly (12 per year)' },
  { id: 'quarterly', name: 'Quarterly (4 per year)' },
  { id: 'yearly', name: 'Yearly (1 per year)' },
]

export const PaymentOptionFormContent = (props) => {
  const { isEdit, enableCheckout, layout, currencySymbol, resource, ...rest } = props
  const formState = useFormState()
  const paymentType = formState.values['payment_type']

  const locations = [FormPlacementEnum.payment_option_form]
  if (paymentType) locations.push(paymentTypeToFormPlacementEnum[paymentType])

  const customForms = useGetCustomForms({ locations })

  const sortAndRenderComponents = useMemo(() => {
    let toRender = [
      {
        component: PaymentOptionsOverviewInputs,
        shouldRender: true,
        priority: 10,
        props: { isEdit },
      },
      {
        component: PaymentTypeFields,
        shouldRender: true,
        priority: 20,
        props: { ...props },
      },
      {
        component: PaymentMilestones,
        shouldRender: true,
        priority: 30,
        props: { ...props },
      },
      {
        component: AdvanceSettings,
        shouldRender: true,
        priority: 40,
        props: { ...props },
      },
      {
        component: ExtraFieldsWrapper,
        shouldRender: true,
        priority: 50,
        props: { ...props },
      },
      {
        component: PaymentOptionAutoApply,
        shouldRender: true,
        priority: 60,
        props: { ...props },
      },
      {
        component: ShareabilitySelector,
        shouldRender: true,
        priority: 70,
        props: { ...rest, dependsOn: 'payment_type' },
      },
    ]

    const allowedCustomForms = customForms.filter((cf) => {
      const placement = cf.placements.find((c) => {
        return locations.includes(c.form_location)
      })

      return (
        isEdit ||
        (placement && (!('hide_during_create' in placement.view_data) || !placement.view_data.hide_during_create))
      )
    })

    return addAccordionFormsAndSort(toRender, allowedCustomForms)
  }, [customForms, paymentType])

  return (
    <>
      {sortAndRenderComponents}
      <TextInput source="configuration_json" style={{ display: 'none' }} />
    </>
  )
}

export const buildField = (pf, pfvar, currencySymbol, translate, resource) => {
  if (pfvar.cb) {
    return (
      <DependentInputWithConditions
        key={pfvar.key}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <div style={{ clear: 'both' }} />
      </DependentInputWithConditions>
    )
  } else if (pfvar.h1) {
    return (
      <DependentInputWithConditions
        key={pfvar.h1}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <h1 style={{ clear: 'both', marginTop: 40, marginBottom: -10 }}>{translate(pfvar.h1)}</h1>
      </DependentInputWithConditions>
    )
  } else if (pfvar.h2) {
    return (
      <DependentInputWithConditions
        key={pfvar.h2}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <h2 style={{ clear: 'both', marginTop: 20, marginBottom: -10 }}>{translate(pfvar.h2)}</h2>
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'DealerFee') {
    return (
      <DependentInputWithConditions
        key={pfvar.type}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <DealerFee resource={resource} />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'CheckoutFinance') {
    return (
      <DependentInputWithConditions
        key={pfvar.type}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <CheckoutFinance resource={resource} />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'DownPayment') {
    return (
      <DependentInputWithConditions
        key={pfvar.type}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <DownPayment resource={resource} />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'PromotionalPeriod') {
    return (
      <DependentInputWithConditions
        key={pfvar.type}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <PromotionalPeriod resource={resource} />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'LoanParameters') {
    return (
      <DependentInputWithConditions
        key={pfvar.type}
        selectedPaymentType={pf.title}
        hiddenOnDisabled={pfvar.hiddenOnDisabled}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <LoanParameters resource={resource} />
        <ReversionRate resource={resource} />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'Prepayment') {
    return (
      <DependentInputWithConditions
        key={pfvar.type}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <Prepayment resource={resource} />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'RegularPaymentFrequencySelectInput') {
    return (
      <DependentInputWithConditions
        key={pfvar.name}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <SelectInput
          style={pfvar.style}
          resource={resource}
          //label={pfvar.label ? undefined : titleCase(pfvar.name.split('_').join(' '))}
          source={'variable_' + pfvar.name}
          translateChoice={true}
          choices={payment_frequency_variables}
        />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'LoanAdvancedPaymentFrequencySelectInput') {
    return (
      <DependentInputWithConditions
        key={pfvar.name}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <SelectInput
          style={pfvar.style}
          resource={resource}
          //label={pfvar.label ? undefined : titleCase(pfvar.name.split('_').join(' '))}
          source={'variable_' + pfvar.name}
          choices={payment_frequency_variables.concat({ id: 'none', name: 'None' })}
          translateChoice={true}
        />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'LoanSimplePaymentFrequencySelectInput') {
    return (
      <DependentInputWithConditions
        key={pfvar.name}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <SelectInput
          style={pfvar.style}
          resource={resource}
          //label={pfvar.label ? undefined : titleCase(pfvar.name.split('_').join(' '))}
          source={'variable_' + pfvar.name}
          choices={payment_frequency_variables}
          translateChoice={true}
        />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'LeasePaymentTiming') {
    return (
      <DependentInputWithConditions
        key={pfvar.name}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <SelectInput
          style={pfvar.style}
          resource={resource}
          //label={pfvar.label ? undefined : titleCase(pfvar.name.split('_').join(' '))}
          source={'variable_' + pfvar.name}
          choices={[
            { id: 'begin', name: 'Beginning' },
            { id: 'end', name: 'End' },
          ]}
        />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'BooleanInput') {
    return (
      <DependentInputWithConditions
        key={pfvar.name}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
      >
        <BooleanInput
          style={pfvar.style}
          resource={resource}
          label={pfvar.label ? pfvar.label : titleCase(pfvar.name.split('_').join(' '))}
          source={'variable_' + pfvar.name}
        />
      </DependentInputWithConditions>
    )
  } else if (pfvar.type === 'TextInput') {
    return (
      <DependentInputWithConditions
        key={pfvar.name}
        name={pfvar.name}
        selectedPaymentType={pf.title}
        dependsOn={pfvar.dependsOn}
        resolve={pfvar.resolve}
        disabled={pfvar.disabled}
        hiddenOnDisabled={pfvar.hiddenOnDisabled}
      >
        <TextInput
          style={pfvar.style}
          label={translate(pfvar.label)}
          resource={resource}
          source={'variable_' + pfvar.name}
          startAdornment={pfvar.units === '$' ? pfvar.units.replace('$', currencySymbol) : null}
          endAdornment={pfvar.units !== '$' ? pfvar.units : null}
          helperText={pfvar.helperText ? translate(pfvar.helperText) : null}
        />
      </DependentInputWithConditions>
    )
  } else {
    // console.log('Unknown field type: ' + pfvar.type, pfvar)
    return <div key={pf.title + pfvar.name} />
  }
}

const DependentInputWithConditions = (props) => {
  // const dependsOn = props.dependsOn
  //   ? props.dependsOn.concat(['payment_type'])
  //   : ['variable_initial_loan_enabled', 'payment_type']
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        const disabled = props.disabled ? Boolean(props.disabled(formData)) : false
        const options: any = {}
        if (props.hiddenOnDisabled && props.hiddenOnDisabled(formData)) {
          options.style = { display: 'none' }
        }
        return (
          props.selectedPaymentType === formData.payment_type &&
          (!props.resolve || props.resolve(formData)) &&
          props.children &&
          React.Children.map(
            props.children,
            (field) =>
              field &&
              React.cloneElement(field, {
                ...rest,
                disabled,
                ...options,
              })
          )
        )
      }}
    </FormDataConsumer>
  )
}

const PaymentTypeFields = (props) => {
  const translate = useTranslate()
  const fields = payment_types_and_variables.map((pf) => {
    return (
      <div key={pf.title} style={{ margin: 'auto' }}>
        {/*@ts-ignore*/}
        {pf.variables.map((pfvar) => buildField(pf, pfvar, props.currencySymbol, translate, props.resource))}
      </div>
    )
  })

  return <>{fields}</>
}
