import { OSSDK } from 'opensolar-sdk'
import { useEffect, useState } from 'react'
import { useSdkSingleton } from './useSdkSingleton'

/**
 * Returns the SDK, but only once it is ready for use.
 * This relies on the useSdkInit hook being called elsewhere in the host application.
 */
export const useSdkSingletonReady = (): OSSDK | undefined => {
  const sdk = useSdkSingleton()
  const [ready, setReady] = useState(!!sdk?.isReady.value)

  useEffect(() => {
    if (!sdk) return
    const readyHandler = (value) => setReady(value)
    sdk.isReady.add(readyHandler)
    return () => sdk.isReady.remove(readyHandler)
  }, [sdk])

  return ready ? sdk : undefined
}
