export enum EventType {
  USER_INTERACTION = 'user_interaction',
  SYSTEM_EVENT = 'system_event',
  ERROR_EVENT = 'error_event',
}

export enum UserActions {
  VIEW = 'view',
  CLICK = 'click',
  SELECT = 'select',
  SUBMIT = 'submit',
  HOVER = 'hover',
  EDIT = 'edit',
  TOGGLE = 'toggle',
}

export enum SystemActions {
  UPDATE = 'update',
  SAVE = 'save',
  REMOVE = 'remove',
  ADD = 'add',
}

export const ActionType = {
  ...UserActions,
  ...SystemActions,
} as const

export enum ActionStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  IN_PROGRESS = 'in_progress',
}

export type EventAction = {
  type: typeof ActionType[keyof typeof ActionType]
  status?: ActionStatus
}
