import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Theme, useMediaQuery } from '@material-ui/core'
import { Check, ChevronRight, VerifiedUser } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import ResponsiveVimeoPlayer from 'elements/ResponsiveVimeoPlayer'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button, Typography, styled } from 'opensolar-ui'
import { useNotify, useRedirect, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useTranslateParse from 'util/useTranslateParse'
import PaymentChannels from './components/PaymentChannels'
import { useQuickSubmit } from './utils'

interface StyledProps {
  isMobile: boolean
}

const StyledDialog = styled(Dialog)({
  zIndex: '10 !important',
})

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: 10,
  paddingBottom: '1rem',
  borderBottom: '1px #DADBE9 solid',
})

const SideContainer = styled('div')({
  background: '#EDF3FF',
  borderRadius: '8px',
  padding: '1rem',
  minHeight: '70%',
})

const HeaderContainer = styled('div')({
  textAlign: 'center',
})

const StyledCheckIcon = styled(Check)({
  fontSize: 18,
  color: '#2C499D',
  marginTop: '2.5px',
})

const StyledShieldIcon = styled(VerifiedUser)({
  height: '1.25rem',
  color: '#019939',
  padding: '10px',
  background: '#DAE5FF',
  borderRadius: 7,
  marginBottom: '10px',
})

const ListContainer = styled('div')({
  display: 'flex',
  gap: 15,
  margin: 18,
  lineHeight: 1.5,
})

const BtnWrapper = styled('div')({
  padding: '10px 0 15px 10px',
})

const BtnRow = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
  alignItems: 'center',
  padding: '0px 10px',
})

const VideoWrapper = styled('div')<StyledProps>(({ isMobile }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 840,
  maxHeight: 472,
  '& iframe': {
    position: isMobile ? 'relative !important' : 'absolute',
  },
}))

const ImageWrapper = styled('div')({
  margin: '1rem',
})

const StyledSubmitBtn = styled(Button)({
  width: '100%',
  fontSize: 13,
})

type PropTypes = {
  setShowDialog: (boolean) => void
}

const MarketingPage: React.FC<PropTypes> = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()

  const quickSubmit = useQuickSubmit()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperUser = useSelector(authSelectors.getIsSuperUser)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const translate = useTranslate()
  const translateParse = useTranslateParse()

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isloading, setIsloading] = useState<boolean>(false)

  const onNext = () => {
    setIsOpen(false)
    props.setShowDialog(true)
  }

  const onQuickSubmit = async () => {
    setIsloading(true)
    const submitted = await quickSubmit()
    setIsloading(false)

    if (!submitted) {
      notify('Error while sumitting quick onboarding', 'error')
      return
    }

    notify('Org successfully onboarded. Please sync PSP Webhooks from dev!', 'warning')
    redirect('/configuration')
  }

  return (
    <StyledDialog open={isOpen} maxWidth="lg">
      <DialogTitle>
        <Row>{translate('Welcome to CashFlow, the only payments system built for solar professionals')}</Row>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={isMobile ? 2 : 1}>
          <Grid item xs={12} sm={8}>
            <VideoWrapper isMobile={isMobile}>
              <ResponsiveVimeoPlayer
                videoUrl="https://player.vimeo.com/video/986846106?h=a7b7fca7f0"
                trackingSource={'cashflow_onboarding'}
              />
            </VideoWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SideContainer>
              <HeaderContainer>
                <StyledShieldIcon />
                <Typography textVariant="subtitle1" colorHex="#2C499D">
                  {translate('Effortless cash collection and accounting')}
                </Typography>
              </HeaderContainer>
              <ListContainer>
                <StyledCheckIcon />
                <Typography textVariant="body1">
                  {translateParse('<strong> Save money <strong> on credit card fees', {
                    strong: (label: string) => <strong>{label}</strong>,
                  })}
                </Typography>
              </ListContainer>
              <ListContainer>
                <StyledCheckIcon />
                <Typography textVariant="body1">
                  {translateParse('<strong> Save time <strong> and hassle processing all payments', {
                    strong: (label: string) => <strong>{label}</strong>,
                  })}
                </Typography>
              </ListContainer>
              <ListContainer>
                <StyledCheckIcon />
                <Typography textVariant="body1">
                  {translateParse('Present a <strong> secure and easy-to-use <strong> payment interface', {
                    strong: (label: string) => <strong>{label}</strong>,
                  })}
                </Typography>
              </ListContainer>

              <ImageWrapper>
                <PaymentChannels />
              </ImageWrapper>
            </SideContainer>
          </Grid>
        </Grid>
      </DialogContent>

      {isloading ? (
        <LoadingDots text="Please wait while we’re quickly submitting the Org onboarding..." />
      ) : (
        <DialogActions>
          <BtnRow>
            <BtnWrapper>
              <StyledSubmitBtn onClick={onNext} variant={'contained'} color={'primary'}>
                {translate("Let's Go")} <ChevronRight />
              </StyledSubmitBtn>
            </BtnWrapper>

            {!['production', 'staging4'].includes(window.ENV) && (isStaff || isSuperUser) && (
              <BtnWrapper>
                <StyledSubmitBtn onClick={onQuickSubmit} variant={'contained'} color={'primary'}>
                  {'Onboarding Quick Submit'} <ChevronRight />
                </StyledSubmitBtn>
              </BtnWrapper>
            )}
          </BtnRow>
        </DialogActions>
      )}
    </StyledDialog>
  )
}
export default MarketingPage
