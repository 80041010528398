import { BLUESNAP_PREMIUM_CATEGORIES, THREE_DS_ENABLED_COUNTRIES } from '../constants'
import {
  BlueSnapBinCategoryType,
  BlueSnapCardDataType,
  BlueSnapCardSubType,
  BlueSnapError,
  CreditCardPaymentMethodType,
  DebitCardPaymentMethodType,
  PaymentMethodType,
  PaymentRequestType,
} from '../types'

export const initBlueSnapSecurePayments = (
  token: string,
  onFieldValidationChange: (errors: any[], warnings: any[]) => void,
  onCardTypeReady: (sdkResponse: any) => void
) => {
  // @ts-ignore
  const result = window.bluesnap.securedPaymentCollectorSetup(
    token,
    function (sdkResponse) {
      if (sdkResponse.code === '1') {
        onCardTypeReady(sdkResponse)
        // Data submission was a success
        // Continue form submission to your server & complete the transaction
      } else {
        // Data submission resulted in error or warning
        const { errors, warnings } = sdkResponse.info
        onFieldValidationChange(errors, warnings)
      }
    },
    false
  )
}

export const initBlueSnapCreditCardForm = (
  token: string,
  onFieldValidationChange: (
    fieldName: string,
    errorCode: number | undefined,
    errorDescription: string | undefined
  ) => void,
  onCardTypeReady: (
    cardType: string,
    cardSubType: BlueSnapCardSubType,
    issuingCountry: string,
    binCategory: BlueSnapBinCategoryType
  ) => void,
  countryIso2: string
) => {
  var bsObj = {
    token,
    onFieldEventHandler: {
      /*OPTIONAL*/ setupComplete: function () {
        console.warn('setupComplete')
      },
      /*OPTIONAL*/ threeDsChallengeExecuted: function () {
        console.warn('threeDsChallengeExecuted')
      },
      // tagId returns: "ccn", "cvv", "exp"
      onFocus: function (tagId) {}, // Handle focus
      onBlur: function (tagId) {}, // Handle blur
      onError: function (tagId, errorCode, errorDescription /*, errorDescription, eventOrigin*/) {
        onFieldValidationChange(tagId, errorCode, errorDescription)
      }, // Handle a change in validation
      /*errorCode returns:
                  "10" --> invalid | empty where errorDescription will return invalid if input field invalid or empty if input field is empty (tagId will indicate which input);
                  "22013" --> "CC type is not supported by the merchant"; 
                  "14040" --> " Token is expired";
                  "14041" --> " Could not find token";
                  "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
                  "400" --> "Session expired please refresh page to continue";
                  "403", "404", "500" --> "Internal server error please try again later"; 
              */

      /* errorDescription is optional. Returns BlueSnap's standard error description */
      /* eventOrigin is optional. Returns BlueSnap's event origin: onBlur | onSubmit */

      onType: function (tagId, cardType, cardData) {
        /* cardType will give card type, and only applies to ccn: AMEX, VISA, MASTERCARD, AMEX, DISCOVER, DINERS, JCB */
        if (null != cardData) {
          /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType, binCategory and ccBin details (only applies to ccn) in a JsonObject */
        }
        if (cardData && cardType) {
          onCardTypeReady(cardType, cardData?.cardSubType, cardData.issuingCountry, cardData.binCategory)
        }
      },
      onEnter: function (tagId) {}, // Will trigger when shopper presses enter while inside one of the inputs
      onValid: function (tagId) {
        onFieldValidationChange(tagId, undefined, undefined)
      }, // Handle a change in validation
    },
    /* example:
              style: {
              "Selector": {
              "Property": "Value",
              "Property2": "Value2"
              },                                                                                                                                                             
              "Selector2": {
              "Property": "Value"
              } 
          }, */
    style: {
      input: {
        //style for all input elements
        height: 'auto !important',
        padding: '10px',
        'margin-top': '5px',
        'border-style': 'solid',
        'border-color': '#e7e7e7',
        'border-width': '2px',
        'border-radius': '5px',
        'background-color': '#fff',
      },
      '.invalid': {
        //style for all input elements when invalid
        color: 'red',
      },
    },
    ccnPlaceHolder: '1234 5678 9012 3456', //for example
    cvvPlaceHolder: '123', //for example
    expPlaceHolder: 'MM/YY', //for example
  }
  if (THREE_DS_ENABLED_COUNTRIES.includes(countryIso2)) {
    bsObj['3DS'] = true
  }
  // @ts-ignore
  window.bluesnap.hostedPaymentFieldsCreate(bsObj)
}

export const submitBlueSnapCardData = (
  paymentRequestData: PaymentRequestType,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  cardholder_address: string,
  cardholder_city: string,
  cardholder_country: string,
  zipCode: string,
  onSuccess: (cardData: BlueSnapCardDataType) => void,
  onError: (errMsgs: Array<BlueSnapError>) => void
) => {
  const threeDSecureObj = {
    amount: paymentRequestData.payment_amount,
    currency: paymentRequestData.currency,
    billingZip: zipCode,
    billingFirstName: firstName,
    billingLastName: lastName,
    billingAddress: cardholder_address,
    billingCity: cardholder_city,
    billingCountry: cardholder_country,
    email: email,
    phone: phone,
  }

  // @ts-ignore
  window.bluesnap.hostedPaymentFieldsSubmitData(function (callback) {
    if (null != callback.cardData) {
      const fraudSessionId = callback.transactionFraudInfo.fraudSessionId
      const authResult = callback?.threeDSecure?.authResult
      const threeDSecureReferenceId = callback?.threeDSecure?.threeDSecureReferenceId
      const cardData = callback.cardData
      cardData.fraudSessionId = fraudSessionId
      cardData.authResult = authResult
      cardData.threeDSecureReferenceId = threeDSecureReferenceId
      onSuccess(callback.cardData)
      // submit the form
    } else {
      console.log('error', callback.error)
      onError(callback.error)
    }
  }, threeDSecureObj)
}

export const getOpenSolarCCType = (
  ccType: string,
  cardSubType: BlueSnapCardSubType,
  issuingCountry: string,
  orgCountry: string | undefined,
  binCategory: BlueSnapBinCategoryType
): CreditCardPaymentMethodType | DebitCardPaymentMethodType | undefined => {
  if (!ccType || !issuingCountry) return undefined

  const prefix = cardSubType === 'DEBIT' ? 'debit' : 'credit'
  let category = 'international'

  if (issuingCountry.toUpperCase() === orgCountry) {
    if (BLUESNAP_PREMIUM_CATEGORIES.includes(binCategory.toUpperCase())) category = 'premium'
    else if (['VISA', 'MASTERCARD'].includes(ccType.toUpperCase())) category = 'standard'
    else category = 'premium'
  }

  return `${prefix}_card_${category}` as CreditCardPaymentMethodType | DebitCardPaymentMethodType
}

export const getCCTypeError = (
  ccType: CreditCardPaymentMethodType | DebitCardPaymentMethodType | undefined,
  acceptedPaymentMethods: PaymentMethodType[]
) => {
  if (!ccType) return undefined
  else if (!acceptedPaymentMethods?.length) {
    return 'This payment link is not configured to accept any payment methods. Please contact your representative and request a new payment link.'
  } else if (!acceptedPaymentMethods.includes(ccType)) {
    if (ccType === 'credit_card_international') {
      return 'Please use a credit card that was issued in the country where this project is being installed.'
    } else if (ccType === 'credit_card_premium') {
      return 'This credit card brand is not accepted. Please use either Visa or Mastercard.'
    } else if (ccType === 'credit_card_standard') {
      return 'Visa and Mastercard are not supported, please use another credit card brand.'
    } else if (ccType === 'debit_card_standard') {
      return 'Visa and Mastercard debit cards are not supported, please use another card.'
    } else if (ccType === 'debit_card_premium') {
      // I don't actually think preimum debit cards exist, but we should handle it either way
      return 'Debit cards are not supported for this card brand. Please use another card.'
    } else if (ccType === 'debit_card_international') {
      return 'Debit cards that are issued outside of the project country are not supported.'
    }
  }
  return undefined
}

export const getBlueSnapFieldError = (fieldLabel: string, errorCode: string) => {
  if (errorCode === '10') {
    return `Invalid %{fieldLabel}`
  } else if (errorCode === '22013') {
    return 'CC type is not supported by the merchant'
  } else if (errorCode === '14040') {
    return 'Token is expired'
  } else if (errorCode === '14041') {
    return 'Could not find token'
  } else if (errorCode === '400') {
    return 'Token is not associated with a payment method, please verify your client integration or contact BlueSnap support'
  } else if (errorCode === '14042') {
    return 'Session expired please refresh page to continue'
  } else if (errorCode === '403' || errorCode === '404' || errorCode === '500') {
    return 'Internal server error please try again later'
  } else {
    return ''
  }
}
