import { Accordion } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { styled } from 'opensolar-ui'
import { useSelector } from 'react-redux'
import type { ProjectType } from 'types/projects'
import SolarAppAccordionSummary from './SolarAppAccordionSummary'
import SolarAppCTA from './SolarAppCTA'
import SolarAppProjectManager from './SolarAppProjectManager'
import SolarAppProjectStatus from './SolarAppProjectStatus'

const AccordionContentWrapper = styled('div')({
  margin: '0 16px',
})

interface PropTypes {
  project: ProjectType
}

const SolarAPP = ({ project }: PropTypes) => {
  const solarapp_account_connected = useSelector(authSelectors.getIsSolarAppAccountConnected)

  return (
    <Accordion key="solarappAccordion" expanded={true}>
      <SolarAppAccordionSummary />
      <AccordionContentWrapper>
        {!solarapp_account_connected ? (
          <SolarAppCTA />
        ) : (
          <>
            {/* SolarAPP+ account is connected */}
            {project?.custom_data?._solarapp_permit?.uuid ? (
              // SolarAPP+ project exists
              <SolarAppProjectStatus project={project} />
            ) : (
              // SolarAPP+ project does not exist
              <SolarAppProjectManager project={project} />
            )}
          </>
        )}
      </AccordionContentWrapper>
    </Accordion>
  )
}

export default SolarAPP
