import { Link } from 'react-router-dom'
import { StudioSystemType } from 'types/global'
import { getFeatureFlagSelector } from 'util/split'
import { CompatibilityParameters } from './types'

export const getSystemRoofType = (): string | null => {
  const projectData = window.projectForm.getState().values
  var roofTypeName
  const roofTypeUrl = projectData.roof_type
  if (roofTypeUrl?.length) {
    let roofId = Number(roofTypeUrl.split('roof_types/')[1].split('/')[0])
    roofTypeName = window.AccountHelper.getRoofTypeById(roofId)?.name
  }
  return roofTypeName ? roofTypeName : null
}

function getRoofSlopeCompatibility(system: StudioSystemType, slopeRange: [number, number]): boolean {
  const roofSlopesAreIncompatible = system.moduleGrids().some((moduleGrid) => {
    const slope = moduleGrid.getSlope()
    return slope <= slopeRange[0] || slope >= slopeRange[1]
  })
  return !roofSlopesAreIncompatible
}

function arrayToStringWithOr(arr) {
  if (arr.length === 1) {
    return arr[0]
  } else {
    const lastElement = arr.pop()
    const joinedArray = arr.join(', ')
    return `${joinedArray} or ${lastElement}`
  }
}

function getCompatibility(system: StudioSystemType, compatibilityParameters: CompatibilityParameters): boolean {
  const mountingName = system?.mounting
  if (!mountingName) return true

  window.WorkspaceHelper.removeProjectErrorFromReduxStore(
    `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
    system.uuid,
    'plugin'
  )

  let enabledByFeatureFlag = false
  if (compatibilityParameters.featureFlag) {
    const reduxState = window.reduxStore?.getState()
    enabledByFeatureFlag = getFeatureFlagSelector(reduxState)(compatibilityParameters.featureFlag, 'on') || false
  }
  if (!enabledByFeatureFlag) {
    let enabledByIntegration = false
    if (compatibilityParameters.integrations?.includes('Segen')) {
      const segenEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_segen
      if (segenEnabled) enabledByIntegration = true
    }
    if (compatibilityParameters.integrations?.includes('City Plumbing')) {
      const cityPlumbingEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_city_plumbing
      if (cityPlumbingEnabled) enabledByIntegration = true
    }
    if (compatibilityParameters.integrations?.includes('HDM')) {
      const hdmEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_hdm
      if (hdmEnabled) enabledByIntegration = true
    }
    if (compatibilityParameters.integrations?.length && !enabledByIntegration) {
      const compatibleIntegrationString = arrayToStringWithOr(compatibilityParameters.integrations)
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: (
          <>
            Please
            <Link target="_blank" to={`/external_api_keys/${window.getStorage().getItem('org_id')}`}>
              {` enable the ${compatibleIntegrationString} integration `}
            </Link>
            to access this feature
          </>
        ),
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }
  if (compatibilityParameters.slopeRange) {
    if (!getRoofSlopeCompatibility(system, compatibilityParameters.slopeRange)) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: `Mounting system is incompatible with roof slope. Roof slopes must be between ${compatibilityParameters.slopeRange[0]}-${compatibilityParameters.slopeRange[1]} degrees.`,
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }

  const module = window.AccountHelper.getModuleById(system.moduleId)

  if (compatibilityParameters.moduleHasSpecs) {
    let missingSpecs: string[] = []
    compatibilityParameters.moduleHasSpecs.forEach((spec) => {
      if (typeof module[spec] === 'undefined' || module[spec] === null) {
        missingSpecs.push(spec)
      }
    })
    if (missingSpecs.length) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: `Module is missing ${missingSpecs.join(
          ', '
        )} from it's specs. Please update the component in the Control > Design & Hardware section or contact us to update our database.`,
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }

  if (compatibilityParameters.moduleThicknessRange) {
    const moduleThickness = module.thickness
    if (
      moduleThickness &&
      (moduleThickness < compatibilityParameters.moduleThicknessRange[0] ||
        moduleThickness > compatibilityParameters.moduleThicknessRange[1])
    ) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: `Mounting system is incompatible with module thickness. Module thickness must be between ${compatibilityParameters.moduleThicknessRange[0]}-${compatibilityParameters.moduleThicknessRange[1]} mm.`,
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }

  if (compatibilityParameters.moduleWidthRanges) {
    const moduleWidth = module.width
    const incompatibleWithModuleWidth =
      moduleWidth &&
      !compatibilityParameters.moduleWidthRanges.reduce(
        (accumulator, range) => (moduleWidth * 1000 >= range[0] && moduleWidth * 1000 <= range[1]) || accumulator,
        false
      )
    if (incompatibleWithModuleWidth) {
      let message = 'Mounting system is incompatible with module width. Module width must be between'
      compatibilityParameters.moduleWidthRanges.forEach((range) => {
        message += ` ${range[0]}-${range[1]} mm`
      })
      message += '.'

      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message,
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }

  if (compatibilityParameters.moduleHeightRange) {
    const moduleHeight = module.height
    if (
      moduleHeight &&
      (moduleHeight * 1000 < compatibilityParameters.moduleHeightRange[0] ||
        moduleHeight * 1000 > compatibilityParameters.moduleHeightRange[1])
    ) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: `Mounting system is incompatible with module height. Module height must be between ${compatibilityParameters.moduleHeightRange[0]}-${compatibilityParameters.moduleHeightRange[1]} mm.`,
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }

  if (compatibilityParameters.roofHeightRange) {
    // TODO!
  }

  if (compatibilityParameters?.roofTypeRequired) {
    const roofType = getSystemRoofType()
    if (!roofType) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: `Roof Type must be selected in the dropdown above to enable auto-racking.`,
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }

  if (compatibilityParameters.roofTypes) {
    const projectData = window.projectForm.getState().values
    var roofTypeCompatibility: boolean = true
    if (!projectData.roof_type?.length && compatibilityParameters.roofTypeRequired === false) {
      // Pass
    } else {
      var roofTypeName
      const roofTypeUrl = projectData.roof_type
      if (roofTypeUrl?.length) {
        let roofId = Number(roofTypeUrl.split('roof_types/')[1].split('/')[0])
        roofTypeName = window.AccountHelper.getRoofTypeById(roofId)?.name
      }
      if (!compatibilityParameters.roofTypes.includes(roofTypeName)) {
        roofTypeCompatibility = false
      }
    }
    if (!roofTypeCompatibility) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: `Mounting system is incompatible with roof type. Roof type must be one of the following; ${compatibilityParameters.roofTypes.join(
          ', '
        )}.`,
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }

  if (compatibilityParameters.panelOrientation) {
    var panelOrientationCompatibility: boolean = true
    const panelOrientations = system.moduleGrids().map((moduleGrid) => moduleGrid.userData.moduleLayout)
    const uniquePanelOrientations = panelOrientations.filter((v, i, a) => a.indexOf(v) === i)
    uniquePanelOrientations.forEach((panelOrientation) => {
      if (panelOrientation !== compatibilityParameters.panelOrientation) {
        panelOrientationCompatibility = false
      }
    })
    if (!panelOrientationCompatibility) {
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: `Mounting system is incompatible with panel orientation. Panel orientation must be ${compatibilityParameters.panelOrientation}`,
        key: `${mountingName.toUpperCase()}_PLUGIN_WARNING`,
        severity: 'warning',
        systemId: system.uuid,
        source: 'plugin',
        category: 'mounting',
        options: {},
      })
      return false
    }
  }
  return true
}

export default getCompatibility
