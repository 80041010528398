import { identifierAsId, useDataProvider, useNotify } from 'ra-core'
import { useCallback } from 'react'
import { getProposalV2Settings } from 'resources/proposalTemplates/ProposalV2FromContent'

const useSaveTemplateSettings = ({ form }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  return useCallback(
    async (rawTemplateSettings: string) => {
      // Do nothing if not inside project form
      if (!form) return
      const proposalTemplateUrl = form.getState().values.proposal_template

      if (!proposalTemplateUrl) return

      const proposalTemplateId = identifierAsId(proposalTemplateUrl)
      const result = await dataProvider.CUSTOM_PATCH(
        'proposal_templates',
        {
          url: `proposal_templates/${proposalTemplateId}/`,
          data: { settings: JSON.stringify(getProposalV2Settings(rawTemplateSettings)) },
          // previousData should be optional, restClient never handles previousData
          previousData: { id: proposalTemplateId },
        },
        {
          onSuccess: () => {
            notify('Design Saved', 'success')
          },
          onFailure: () => {
            notify('Something went wrong"', 'warning')
            return
          },
        }
      )
      return result
    },
    [dataProvider, form]
  )
}

export default useSaveTemplateSettings
