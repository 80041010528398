// @ts-nocheck
import { useMediaQuery } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import { JsonFormsInput } from 'elements/jsonForms/JsonFormsInput'
import EditorComponent from 'elements/wysiwyg/EditorComponentWrapper'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import {
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry, renderDraftEditor, renderRawHtmlEditor } from 'util/misc'
import DependentInput from '../../../elements/input/DependentInput'
import { PaymentOptionType } from '../../../types/paymentOptions'
import { BULK_ACTION_ALL_OR_NOTHING_INTEGRATIONS } from '../constants'
import { styles } from '../styles.js'
import PaymentFactorsWrapper from './PaymentFactorsWrapper'

type Toggle = {
  extraFields: string[]
  extraFieldsDefaultValues?: any
}

type PropTypes = {
  resource: 'payment_options'
  enableCheckout: boolean
  record: PaymentOptionType
}

export const AdvanceSettings = ({ resource, enableCheckout, record, style: disabledStyle }: PropTypes) => {
  const translate = useTranslate()
  const form = useForm()
  const formState = useFormState()
  const isMD = useMediaQuery((theme: any) => theme.breakpoints.down(860))
  const isStaff = useSelector(authSelectors.getIsStaff)
  //show fields if advanced settings is on, or if auto apply zip/state is currently on but unsaved, or was on and the user cleared the field but has not yet saved
  const isLoan = formState.values['payment_type'] === 'loan'
  const orgCountry = useSelector(orgSelectors.getOrg)?.country?.iso2
  const currencySymbol = currencySymbolForCountry(orgCountry)
  let isIntegration = false
  let isLightreach = false
  let disabled = false
  let treatAllOptionsAsOne = false
  try {
    disabled =
      record && !!record.configuration_json && Boolean(JSON.parse(record.configuration_json).integration && !isStaff)

    let integration = JSON.parse(record.configuration_json).integration
    isIntegration = !!integration
    isLightreach = integration === 'lightreach'
    treatAllOptionsAsOne = BULK_ACTION_ALL_OR_NOTHING_INTEGRATIONS.includes(integration)
  } catch (err) {
    //continue, leave hasIntegration false
  }

  if (isLightreach) return null
  return (
    <AccordionCard
      title={translate('Advanced Settings')}
      render={() => {
        return (
          <>
            <div>
              {isStaff && (
                <BooleanInput
                  resource={resource}
                  style={disabled ? disabledStyle : styles.inputStyle}
                  label={translate('Use Payment Factors?')}
                  source={'variable_payment_factors_enabled'}
                  name={'variable_payment_factors_enabled'}
                  disabled={disabled}
                />
              )}
              <DependentInput dependsOn="variable_payment_factors_enabled" value={true}>
                <Alert severity="info">
                  When payment factors are enabled the interest rate will be ignored while running calculations
                </Alert>
              </DependentInput>
              <DependentInput dependsOn="variable_payment_factors_enabled" value={true}>
                <PaymentFactorsWrapper variableRoot="variable_payment_factors" disabled={disabled} />
              </DependentInput>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {isLoan && (
                  <CustomField
                    component={SelectInput}
                    resource={resource}
                    source={'variable_payment_frequency'}
                    name={'variable_payment_frequency'}
                    style={styles.inputStyle}
                    choices={[
                      { id: 'weekly', name: 'Weekly (52 per year)' },
                      { id: 'fortnightly', name: 'Fortnightly (26 per year)' },
                      { id: 'bimonthly', name: 'Bimonthly (24 per year)' },
                      { id: 'monthly', name: 'Monthly (12 per year)' },
                      { id: 'quarterly', name: 'Quarterly (4 per year)' },
                      { id: 'yearly', name: 'Yearly (1 per year)' },
                    ]}
                    disabled={disabled}
                  />
                )}

                <CustomField
                  component={ReferenceInput}
                  name="utility_tariff_override"
                  source="utility_tariff_override"
                  reference="utility_tariffs"
                  optionValueField="url"
                  label={translate('Utility Tariff Override')}
                  // style={styles.inputStyle}
                  allowEmpty
                >
                  <AutocompleteInput
                    optionText="name"
                    optionValue="url"
                    options={{ style: styles.inputStyle, placeholder: translate('Enter a utility tariff') }}
                  />
                </CustomField>
                <NumberInput
                  resource={resource}
                  style={styles.inputFieldLessWide}
                  label={translate('Priority')}
                  source={'priority'}
                  name={'priority'}
                />
                {enableCheckout && (
                  <CustomField
                    component={BooleanInput}
                    resource={resource}
                    label={translate('Collect Signature')}
                    source={'variable_collect_signature'}
                    name={'variable_collect_signature'}
                  />
                )}

                <div>
                  <CustomField
                    component={BooleanInput}
                    resource={resource}
                    label={translate('Make Standard System Prices Consistent')}
                    source={'use_highest_standard_system_price'}
                    name={'use_highest_standard_system_price'}
                    disabled={disabled}
                  />
                  <p className="small">
                    {translate(
                      'Adjust Standard System Price for all payment options on a given system up to the Standard System Price of the most expensive payment option. Applies to all payment options when enabled on any payment option being used on a given system.'
                    )}
                  </p>
                </div>

                <div>
                  <CustomField
                    component={BooleanInput}
                    resource={resource}
                    label={translate('Auto-discount excess dealer fees')}
                    source={'auto_discount'}
                    name={'auto_discount'}
                    disabled={disabled}
                  />
                  <p className="small">
                    {translate(
                      "Auto-discount Total System Price of this payment option to eliminate excess dealer fees when Standard System Price has been grossed-up to match a payment option with higher dealer fees (via the 'Make Standard System Prices Consistent' toggle above). Discount is only applied when all other payment options also have this auto-discount enabled."
                    )}
                  </p>
                </div>
                <div>
                  <NumberInput
                    resource={resource}
                    inputLabelProps={{
                      shrink: true,
                    }}
                    style={styles.inputFieldLessWide}
                    label={translate('Fixed Fee Per Period')}
                    startAdornment={currencySymbol}
                    source={'variable_fixed_fee_per_period'}
                    name={'variable_fixed_fee_per_period'}
                    disabled={disabled}
                  />
                </div>
              </div>

              <h4 style={styles.h4}>{translate('Supplementary Terms & Conditions')}</h4>
              <DependentInput dependsOn={'contract_template'} resolve={renderDraftEditor}>
                <EditorComponent
                  fieldName="contract_template"
                  mentionContext="org_and_project_and_system"
                  allowUploadImage={true}
                />
              </DependentInput>

              <DependentInput dependsOn={'contract_template'} resolve={renderRawHtmlEditor}>
                <TextInput fullWidth={true} multiline={true} source="contract_template" />
              </DependentInput>
              <DependentInput dependsOn={'is_archived'} value={true}>
                {isIntegration && (
                  <div style={{ width: '100%', marginTop: '20px' }}>
                    <h2>Payment Option Replacement</h2>
                    <p className="small">
                      If you would like to automatically replace this payment option with another on all existing
                      proposals that use this payment option you can do so by selecting the replacement below. When a
                      replacement is selected our system will automatically swap payment options and recalculate as soon
                      as a user (whether it's a memeber of your account or a customer) views the online proposal.
                    </p>
                    <ReferenceInput
                      source="payment_option_replacement"
                      reference="payment_options"
                      label={translate('Payment Option Replacement')}
                      optionValueField="url"
                      sort={{ field: 'title', order: 'ASC' }}
                      allowEmpty
                      style={{ width: '100%' }}
                      translateChoice={false}
                      fullWidth={true}
                    >
                      <AutocompleteInput
                        optionText="title"
                        optionValue="url"
                        options={{
                          style: { width: '100%' },
                          placeholder: 'Enter a Payment Option',
                        }}
                      />
                    </ReferenceInput>
                  </div>
                )}
              </DependentInput>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMD ? 'column' : 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <CustomField
                  component={BooleanInput}
                  style={{ ...styles.inputStyle, marginTop: 20, width: isMD ? '100%' : '45%' }}
                  name="is_archived"
                  source="is_archived"
                  resource={resource}
                  defaultValue={false}
                />
              </div>
              {treatAllOptionsAsOne && formState.values['is_archived'] && (
                <div style={{ fontSize: 12, color: 'red' }}>
                  {`Archiving will take effect for all ${formState.values['variable_integration']} products.`}
                </div>
              )}
              <JsonFormsInput
                label={false}
                source="custom_data.extra_quote_acceptance_content"
                name="custom_data.extra_quote_acceptance_content"
                resource={resource}
                schema={{
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      message: {
                        type: 'string',
                      },
                    },
                  },
                }}
                uischema={{
                  type: 'VerticalLayout',
                  elements: [
                    {
                      type: 'Control',
                      scope: '#/',
                      label: translate('Additional Quote Acceptance Content'),
                    },
                  ],
                }}
                fullWidth={true}
              />
            </div>
          </>
        )
      }}
    />
  )
}
