import { Button, styled } from 'opensolar-ui'
import SolarAppCreateProjectDialog from 'projectSections/sections/manage3/integrations/SolarAppIntegration/SolarAppDialogs/SolarAppCreateProjectDialog'
import React, { useState } from 'react'
import type { ProjectType } from 'types/projects'
import { doNotTranslate } from 'util/misc'
import SolarAppPreviewStepper from './SolarAppPreviewStepper'

const ButtonWrapper = styled('div')({
  marginTop: 12,
  marginBottom: 20,
})

interface PropTypes {
  project: ProjectType
}

const SolarAppProjectManager: React.FC<PropTypes> = ({ project }) => {
  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(false)

  return (
    <>
      <SolarAppPreviewStepper />
      <ButtonWrapper>
        <Button
          onClick={() => {
            setShowCreateProjectDialog(true)
          }}
          variant="contained"
        >
          {doNotTranslate('Create SolarAPP+ Permit')}
        </Button>
      </ButtonWrapper>

      {showCreateProjectDialog && (
        <SolarAppCreateProjectDialog
          project={project}
          open={showCreateProjectDialog}
          onClose={() => setShowCreateProjectDialog(false)}
        />
      )}
    </>
  )
}

export default SolarAppProjectManager
