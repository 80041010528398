import { jsx as _jsx } from "react/jsx-runtime";
import { AcceptProposalButton, AcceptProposalLink, ProposalAccepted, ProposalAcceptedLink } from 'opensolar-ui';
import { getAcceptProposalText } from '../../util';
var useAcceptProposalStatus = function (_a) {
    var _b;
    var status_code = _a.status_code, variation = _a.variation, proposalData = _a.proposalData;
    if (status_code === 'hidden') {
        return 'hidden';
    }
    if (status_code === 'complete' && variation === 'link') {
        return 'accepted_link';
    }
    if (((_b = proposalData.selectedProject) === null || _b === void 0 ? void 0 : _b.project_sold) || !proposalData.checkoutHidden) {
        if (status_code === 'complete') {
            return 'accepted_button';
        }
        else if (variation === 'link') {
            return 'available_link';
        }
        else {
            return 'available_button';
        }
    }
    return 'hidden';
};
var AcceptProposal = function (_a) {
    var onAction = _a.onAction, proposalData = _a.proposalData, options = _a.options, elementData = _a.elementData, translate = _a.translate;
    var actionData = elementData.actionData, variation = elementData.variation, loanType = elementData.loanType;
    var status = useAcceptProposalStatus({ status_code: actionData.status_code, variation: variation, proposalData: proposalData });
    var handleAction = function () {
        onAction({ elementData: elementData, proposalData: proposalData });
    };
    var isLoading = (options === null || options === void 0 ? void 0 : options.isLoading) ? true : false;
    if (status === 'hidden') {
        return null;
    }
    else if (status === 'accepted_link') {
        return _jsx(ProposalAcceptedLink, { textColor: proposalData.myeStyles.textColor });
    }
    else if (status === 'accepted_button') {
        return (_jsx(ProposalAccepted, { myeStyles: proposalData.myeStyles, isLoading: isLoading, buttonLabel: translate(getAcceptProposalText(actionData.review_action_title, actionData.quote_acceptance_content, actionData.payment_amount)) + ' ✓', acceptedLinkLabel: translate('View Statement'), onAction: handleAction }));
    }
    else if (status === 'available_link') {
        return (_jsx(AcceptProposalLink, { myeStyles: proposalData.myeStyles, isLoading: isLoading, label: translate(getAcceptProposalText(actionData.review_action_title, actionData.quote_acceptance_content, actionData.payment_amount)), onAction: handleAction }));
    }
    else if (status === 'available_button') {
        return (_jsx(AcceptProposalButton, { myeStyles: proposalData.myeStyles, isLoading: isLoading, label: translate(getAcceptProposalText(actionData.review_action_title, actionData.quote_acceptance_content, actionData.payment_amount)), disabled: actionData.status_code === 'disabled', onAction: handleAction }));
    }
    return null;
};
export default AcceptProposal;
