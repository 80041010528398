import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import pure from 'recompose/pure';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'ra-core';
var PaginationLimit = function () {
    var translate = useTranslate();
    return (_jsx(CardContent, { children: _jsx(Typography, { variant: "body2", children: translate('ra.navigation.no_results') }) }));
};
export default pure(PaginationLimit);
