import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { useTranslate } from 'react-admin'
import { ActionType, EventType } from 'types/tracking'
import EnergyConsumptionAndGenerationChart, { maxAxisYForAllSystems } from './EnergyConsumptionAndGenerationChart'

const isSolarZero = (country_iso2, system) =>
  country_iso2 === 'NZ' && system.payment_options.filter((po) => po.payment_type === 'regular_payment').length > 0

const calculateExportPercent = (annualBill) => {
  if (annualBill?.battery_discharge_to_grid_kwh > 0) {
    return Math.round((1 - annualBill.self_consumption_fraction) * 100)
  } else {
    return Math.round((annualBill.total_kwh_export_to_grid / annualBill.solar_generation_kwh) * 100)
  }
}

const EnergySupply = (props) => {
  const translate = useTranslate()
  const annualBill = props.selectedSystem.bills.proposedSelected.bills_yearly[0].annual
  const selfConsumptionPercent = Math.round(annualBill.self_consumption_fraction * 100)
  const exportPercent = calculateExportPercent(annualBill)
  const { trackEvent } = useTrackComponent({
    componentKey: 'energy_supply',
    eventName: 'User Clicked On Daily Energy Profile In Proposal',
  })

  const exportLimitPercent = 100 - selfConsumptionPercent - exportPercent
  const hasExportLimit =
    props.selectedSystem.export_limit !== null &&
    props.selectedSystem.export_limit >= 0 &&
    annualBill?.battery_discharge_to_grid_kwh === 0

  return (
    <div style={{ position: 'relative' }}>
      <h1 className="mye-section-title">{props.proposalTemplateSettings.heading_system_performance}</h1>

      <div style={{ textAlign: 'center' }} className={props.layout <= 2 ? 'myenergy-layout-small' : ''}>
        <div className="EnergyConsumptionFacts">
          <div className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
            {Math.round(props.solarOutputAsPercentage)}%
          </div>
          <div style={{ fontWeight: 300 }}>{translate('Energy From Solar')}</div>
          <div style={{ marginTop: 20 }}>
            {false && (
              <div
                id="MyEnergyButtonEditUsage"
                onClick={(e) => {
                  e.preventDefault()
                  props.showDialog('EditUsage')
                }}
                className="EnergyConsumptionButtons"
              >
                {translate('Edit Consumption')}
              </div>
            )}

            {props.dailyEnergyFlows && !isSolarZero(props.org.country_iso2, props.selectedSystem) && (
              <div
                id="MyEnergyButtonDailyEnergyFlowsPopup"
                onClick={(e) => {
                  e.preventDefault()
                  trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { isSolarZero: false })
                  props.showDialog('DailyEnergyFlowsDialog')
                }}
                className="EnergyConsumptionButtons"
              >
                {translate('Daily Energy Profile')}
              </div>
            )}

            {props.dailyEnergyFlows && isSolarZero(props.org.country_iso2, props.selectedSystem) && (
              <div
                id="MyEnergyButtonDailyEnergyFlowsPopup"
                onClick={(e) => {
                  e.preventDefault()
                  trackEvent(EventType.USER_INTERACTION, { type: ActionType.CLICK }, { isSolarZero: true })
                  props.showDialog('ImageDialog')
                }}
                className="EnergyConsumptionButtons"
              >
                {translate('Daily Energy Profile')}
              </div>
            )}
          </div>
        </div>
        <div className="EnergyConsumptionGraph">
          <EnergyConsumptionAndGenerationChart
            myeStyles={props.myeStyles}
            selectedSystem={props.selectedSystem}
            selectedProject={props.selectedProject}
            maxMonthlyEnergy={maxAxisYForAllSystems(props.systems, props.selectedProject)}
          />
        </div>
      </div>

      {props.selfConsumptionBreakdown && (
        <div
          style={{
            display: 'flex',
            width: '70%',
            minWidth: 300,
            margin: '0px auto 0px auto',
            border: '1px solid #c9c9c9',
            borderRadius: 5,
          }}
        >
          <div
            style={{
              flex: '1 0 auto',
              textAlign: 'center',
              padding: '10px 0px',
              borderRight: '1px solid #c9c9c9',
              fontSize: 12,
            }}
          >
            <span className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
              {selfConsumptionPercent}
            </span>
            <span style={{ fontSize: 15, top: 18, marginLeft: 2 }}>%</span>
            <br />
            {translate('Self-consumption')}
          </div>
          <div
            style={{
              flex: '1 0 auto',
              textAlign: 'center',
              padding: '10px 0px',
              fontSize: 12,
            }}
          >
            {' '}
            <span className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>{exportPercent}</span>
            <span style={{ fontSize: 15, top: 18, marginLeft: 2 }}>%</span> <br />
            {translate('Export to grid')}
          </div>
          {hasExportLimit ? (
            <div
              style={{
                flex: '1 0 auto',
                textAlign: 'center',
                padding: '10px 0px',
                borderLeft: '1px solid #c9c9c9',
                fontSize: 12,
              }}
            >
              {' '}
              <span className={props.layout <= 2 ? 'feature-figure-medium' : 'feature-figure'}>
                {exportLimitPercent}
              </span>
              <span style={{ fontSize: 15, top: 18, marginLeft: 2 }}>%</span> <br />
              {translate('Export limit')} ({props.selectedSystem.export_limit}kW)
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  )
}

export default EnergySupply
