var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import { InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FieldTitle, useInput } from 'ra-core';
import InputHelperText from './InputHelperText';
import ResettableTextField from './ResettableTextField';
import sanitizeRestProps from './sanitizeRestProps';
/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
var TextInput = function (_a) {
    var label = _a.label, format = _a.format, helperText = _a.helperText, onBlur = _a.onBlur, onFocus = _a.onFocus, onChange = _a.onChange, options = _a.options, parse = _a.parse, resource = _a.resource, source = _a.source, validate = _a.validate, InputPropsOverride = _a.InputProps, startAdornment = _a.startAdornment, endAdornment = _a.endAdornment, rest = __rest(_a, ["label", "format", "helperText", "onBlur", "onFocus", "onChange", "options", "parse", "resource", "source", "validate", "InputProps", "startAdornment", "endAdornment"]);
    var _b = useInput(__assign({ format: format, onBlur: onBlur, onChange: onChange, onFocus: onFocus, parse: parse, resource: resource, source: source, type: 'text', validate: validate }, rest)), id = _b.id, input = _b.input, isRequired = _b.isRequired, _c = _b.meta, error = _c.error, touched = _c.touched, submitError = _c.submitError;
    var errorMessage = error || submitError;
    var adornment = {
        startAdornment: startAdornment && _jsx(InputAdornment, { position: "start", children: startAdornment }),
        endAdornment: endAdornment && _jsx(InputAdornment, { position: "end", children: endAdornment }),
    };
    var InputProps = __assign(__assign({}, adornment), InputPropsOverride);
    return (_jsx(ResettableTextField, __assign({ id: id }, input, { label: label !== '' &&
            label !== false && _jsx(FieldTitle, { label: label, source: source, resource: resource, isRequired: isRequired }), error: !!(touched && errorMessage), helperText: _jsx(InputHelperText, { touched: touched, error: errorMessage, helperText: helperText }), InputProps: InputProps }, sanitizeRestProps(rest), options)));
};
TextInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};
TextInput.defaultProps = {
    options: {},
};
export default TextInput;
