import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { ReferenceInput, SelectInput, TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ActionType, EventType } from 'types/tracking'
import TemperatureInput from '../../common/TemperatureInput'
import { InputRow } from '../styles'

const LocationStructureFields: React.FC = (props) => {
  const translate = useTranslate()
  const project = useFormState().values
  const orgId = useSelector(authSelectors.getOrgId)
  const [default_min_temp, default_max_temp] = useMemo(() => project?.temperature_min_max || [], [
    project?.temperature_min_max,
  ])

  const { trackEvent } = useTrackComponent({
    componentKey: 'location_structure_fields',
    eventName: 'Section: Location and Structural Details',
  })

  return (
    <>
      <InputRow>
        <CustomField
          component={ReferenceInput}
          resource="projects"
          source="roof_type"
          name="roof_type"
          reference="roof_types"
          label="Roof Type"
          perPage={1000}
          fullWidth
          filter={{
            fav: 1,
            org_id: orgId,
          }}
          id="roof_type_field"
          allowEmpty={true}
          emptyText={<i style={{ color: '#aaa' }}>{translate('Select Roof Type')}</i>}
        >
          <SelectInput
            optionText={(choice: any) => translate(choice?.name)}
            optionValue="url"
            id="roof_type_select_input"
            variant="outlined"
            source={'roof_type'}
            onChange={(event: any) => {
              const value = event.target.value
              trackEvent(
                EventType.USER_INTERACTION,
                { type: ActionType.SELECT },
                { eventName: 'Changed Roof Type In Manage Page', value }
              )
            }}
          />
        </CustomField>
        <TextInput source={'wind_region'} variant="outlined" label={'Wind Region'} fullWidth />
        <TemperatureInput
          value={project?.temperature_min_override}
          source="temperature_min_override"
          defaultTemp={default_min_temp}
          label={'Min Temperature Override'}
          fullWidth
        />
        <TemperatureInput
          value={project?.temperature_max_override}
          source="temperature_max_override"
          defaultTemp={default_max_temp}
          label={'Max Temperature Override'}
          fullWidth
        />
      </InputRow>
    </>
  )
}

export default LocationStructureFields
