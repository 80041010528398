import { Tooltip } from '@material-ui/core'
import { CheckCircle, InfoOutlined } from '@material-ui/icons'
import { FormControl, Typography, styled } from 'opensolar-ui'
import { SelectInput } from 'ra-ui-materialui'
import React, { useEffect, useMemo, useState } from 'react'
import { parseIntegrationJson } from 'util/misc'
import { LightReachVendorMap } from './types'
import { useValidateLightReachEquipment } from './utils'

type PropTypes = {
  systemUuid: string | null
  icon: JSX.Element
  label: string
  selectedProduct: string | null
  manufacturerChoices
  skuChoices
  manufacturerMatch?: string | null
  skuMatch?: string | null
  vendors: LightReachVendorMap | undefined
}

interface StyledProps {
  isValid?: boolean
}

const NONE = 'None'

const StyledFormControl = styled(FormControl)<StyledProps>(({ isValid }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: !isValid ? 'unset' : 'rgb(230, 37, 0) !important',
  },
}))

const ProductContainer = styled('div')({
  background: '#FBFAFC',
  padding: '5px 35px 10px 20px',
  borderRadius: 8,
  margin: '15px 0',
})

const ProductHeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ProductHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

const SelectedProduct = styled('p')({
  background: '#F1F1F1',
  padding: '2px 8px',
  borderRadius: 5,
})

const ApprovedLabel = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  color: theme.palette.success.contrastText,
}))

const StyledCheckCircleIcon = styled(CheckCircle)({
  fontSize: 16,
})

const StyledInfoOutlineIcon = styled(InfoOutlined)({
  fontSize: 16,
})

const StyledSelectInput = styled(SelectInput)({
  width: '100%',
  margin: '10px 0',
})

const SelectLabelWrapper = styled('div')({
  marginTop: 5,
  marginBottom: 0,
})

const ErrorMsgWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.error.contrastText,
  marginTop: '-5px',
  fontSize: 'smaller',
}))

const LightReachEquipmentDropdown: React.FC<PropTypes> = ({
  systemUuid,
  icon,
  label,
  selectedProduct,
  manufacturerChoices,
  skuChoices,
  manufacturerMatch,
  skuMatch,
  vendors,
}) => {
  const system = window.editor.getSystems().find((sys) => sys.uuid === systemUuid)
  const newIntegrationJSON = parseIntegrationJson(system?.integration_json) || {}
  const validationErrors = useValidateLightReachEquipment(systemUuid, vendors)

  const [manufacturer, setManufacturer] = useState<string>(manufacturerMatch || '')
  const [sku, setSku] = useState<string>(skuMatch || '')

  const isApproved =
    !validationErrors.includes(`${label}_manufacturer`) &&
    !validationErrors.includes(`${label}_product_code`) &&
    Boolean(manufacturer)

  if (manufacturer !== '') {
    skuChoices = skuChoices?.filter((sku) => sku.manufacturer === manufacturer)
    skuChoices = [{ id: NONE, modelNumber: NONE }, ...skuChoices]
  }

  const field = useMemo(() => {
    switch (label) {
      case 'Modules':
        return { manuf: 'panelManufacturer', model: 'panelModel' }

      case 'Inverters':
        return { manuf: 'inverterManufacturer', model: 'inverterModel' }

      case 'Batteries':
        return { manuf: 'storageManufacturer', model: 'storageModel' }

      case 'Mounting':
        return { manuf: 'mountingManufacturer', model: '' }

      default:
        return { manuf: '', model: '' }
    }
  }, [label])

  const onFieldChange = (fieldName: string, newVal: string) => {
    if (newVal === NONE) newVal = ''
    if (!newIntegrationJSON.lightreach) {
      newIntegrationJSON.lightreach = {}
    }
    if (!newIntegrationJSON.lightreach[fieldName]) {
      newIntegrationJSON.lightreach[fieldName] = ''
    }
    newIntegrationJSON.lightreach[fieldName] = newVal

    if (fieldName.includes('Manufacturer')) {
      setManufacturer(newVal)
      newIntegrationJSON.lightreach[field.model] = ''
    } else {
      setSku(newVal)
    }
  }

  const manufacturerChoicesWithEmpty = useMemo(() => {
    if (manufacturerChoices && manufacturerChoices.length > 0) {
      return [NONE, ...manufacturerChoices]
    }

    return []
  }, [label, manufacturerChoices])

  useEffect(() => {
    if (manufacturer === '') {
      setSku('')
    }
  }, [manufacturer])

  return (
    <ProductContainer>
      <ProductHeaderWrapper>
        <ProductHeader>
          {icon}
          <Typography textVariant="body1Bold">{label}</Typography>
          <SelectedProduct>{selectedProduct} </SelectedProduct>
          <Tooltip title="Go to Design Studio and choose a replacement item from a list of Lightreach approved hardware">
            <StyledInfoOutlineIcon />
          </Tooltip>
        </ProductHeader>
        {isApproved && (
          <ApprovedLabel>
            <StyledCheckCircleIcon />
            <span>Approved</span>
          </ApprovedLabel>
        )}
      </ProductHeaderWrapper>

      <StyledFormControl sx={{ m: 1, width: '45%' }} isValid={validationErrors.includes(`${label}_manufacturer`)}>
        <SelectLabelWrapper>Manufacturer</SelectLabelWrapper>
        <StyledSelectInput
          name={`${label}_manufacturer`}
          id={`${label}_manufacturer`}
          source=""
          label=""
          variant="outlined"
          size="small"
          choices={manufacturerChoicesWithEmpty?.map((manuf) => {
            return {
              id: manuf,
              name: manuf,
            }
          })}
          value={manufacturer}
          onChange={(e) => onFieldChange(field.manuf, e.target.value)}
        />
        {validationErrors.includes(`${label}_manufacturer`) && (
          <ErrorMsgWrapper>LightReach approved manufacturer required.</ErrorMsgWrapper>
        )}
      </StyledFormControl>

      {label !== 'Mounting' && (
        <StyledFormControl sx={{ m: 1, width: '45%' }} isValid={validationErrors.includes(`${label}_manufacturer`)}>
          <SelectLabelWrapper>Product Code/Sku</SelectLabelWrapper>
          <StyledSelectInput
            name={`${label}_product_code`}
            id={`${label}_product_code`}
            source=""
            label=""
            size="small"
            choices={skuChoices?.map((sku) => {
              return {
                id: sku.id,
                name: sku.modelNumber,
              }
            })}
            value={sku}
            variant="outlined"
            onChange={(e) => onFieldChange(field.model, e.target.value)}
          />
          {validationErrors.includes(`${label}_product_code`) && (
            <ErrorMsgWrapper>LightReach approved hardware required</ErrorMsgWrapper>
          )}
        </StyledFormControl>
      )}
    </ProductContainer>
  )
}

export default LightReachEquipmentDropdown
