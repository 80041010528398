import { DropdownInput, IntegrationData, NativeMountingSystemsType, TextFieldInput } from 'types/mounting'
import { FilterCriteria, filterOptions, RoofTypeCompatibility } from './inputFilters'
import { getRoofHookCalculationMethodInputs } from './Inputs'
import { SolarRoofPro } from './mounting_systems/ClenergySolarRoofPro'
import { ClickFit, FlatFix } from './mounting_systems/Esdec/Esdec'
import { Fastensol } from './mounting_systems/FastensolPitched'
import { GseInRoof } from './mounting_systems/GSE/GseInRoof'
import { MSPitchedProLine } from './mounting_systems/MSPitchedProLine/MSPitchedProLine'
import {
  gmbhDropdowns,
  compatibilityRules as msPitchedProlineCompatibilityRules,
} from './mounting_systems/MSPitchedProLine/MSPitchedProLine_products'
import { MSTrapezeProLine } from './mounting_systems/MSTrapezeProLine/MSTrapezeProLine'
import { Metasole } from './mounting_systems/RenusolMetasole'
import { Variosole } from './mounting_systems/RenusolVariosole'
import {
  allSchletterInputs,
  Schletter,
  compatibilityRules as schletterCompatibilityRules,
  compatibleRoofTypes as schletterCompatibleRoofTypes,
} from './mounting_systems/SchletterOnRoof'
import { SunmodoNanoRack } from './mounting_systems/SunmodoNanoRack'
import { SunmodoSMRPitched } from './mounting_systems/SunmodoSMRPitched'
import { getPanelOrientationsInSystem, getRailDirectionInputs, getRailOrientationsInSystem } from './railing'
import { getRoofName } from './utils'

export function getNativeMountingSystems(): NativeMountingSystemsType {
  const roofTypeName = getRoofName(window.projectForm.getState().values.roof_type)

  return {
    sunmodoSMRPitched: {
      class: SunmodoSMRPitched,
      manufacturer: 'Sunmodo',
      getInputs: (projectForm, system) => {
        const integrationData = system.integration_json?.sunmodoSMRPitched as IntegrationData
        const roofHookInputs = getRoofHookCalculationMethodInputs(system)
        const inputs = [
          ...(integrationData.mountType &&
          ['fiveTopTile', 'sevenTopTile', 'mrbMount'].includes(integrationData.mountType)
            ? []
            : [
                {
                  label: 'What will you attach to?',
                  variableName: 'attachmentType',
                  default: 'rafters',
                  options: [
                    { label: 'Rafters', value: 'rafters' },
                    { label: 'Decking', value: 'decking' },
                  ],
                },
              ]),
          {
            label: 'Mount',
            variableName: 'mountType',
            default: 'nanoRafterMount',
            options: [
              { label: 'Nano Rafter Mount', value: 'nanoRafterMount' },
              { label: 'NanoBit', value: 'nanoBit' },
              { label: 'SoloFlash', value: 'soloFlash' },
              { label: 'Nano Deck Mount', value: 'nanoDeckMount' },
              { label: '5" TopTile', value: 'fiveTopTile' },
              { label: '7" TopTile', value: 'sevenTopTile' },
              { label: 'MRB Mount', value: 'mrbMount' },
            ],
          },
          {
            label: 'Colour',
            variableName: 'colour',
            default: 'black',
            options: [
              { label: 'Black', value: 'black' },
              { label: 'Silver', value: 'silver' },
            ],
          },
          {
            label: 'End clamp type',
            variableName: 'endClamp',
            default: 'standard',
            options: [
              { label: 'Standard end clamp', value: 'standard' },
              { label: 'Bottom end clamp', value: 'bottom' },
            ],
          },
          {
            label: 'Include end caps?',
            variableName: 'endCap',
            default: true,
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
          },
          {
            label: 'Wire management clip quantity',
            variableName: 'wireClipQty',
            default: 1,
            options: [
              { label: '1 / module', value: 1 },
              { label: '2 / module', value: 2 },
              { label: 'None', value: 0 },
            ],
          },
          {
            label: 'Include MLPE mount?',
            variableName: 'mlpeMount',
            default: true,
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
          },
          {
            label: 'Conduit mount',
            variableName: 'conduitMount',
            default: false,
            options: [
              { label: 'None', value: false },
              { label: '3/4" conduit mount', value: 'CLMP-CON-075-S' },
              { label: '1" conduit mount', value: 'CLMP-CON-100-S' },
            ],
          },
        ] as DropdownInput[]

        const compatibilityRules = {
          attachmentType: {
            roofTypeCompatibility: {
              rafters: ['Composition / Asphalt Shingle'],
              decking: ['Composition / Asphalt Shingle'],
            } as RoofTypeCompatibility,
          },
          mountType: {
            roofTypeCompatibility: {
              nanoRafterMount: ['Composition / Asphalt Shingle'],
              nanoBit: ['Composition / Asphalt Shingle', 'Trapezoidal', 'Metal Standing Seam'],
              soloFlash: ['Composition / Asphalt Shingle'],
              nanoDeckMount: ['Composition / Asphalt Shingle'],
              fiveTopTile: ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
              sevenTopTile: ['Tile Clay', 'Tile Concrete', 'Tile Slate'],
              mrbMount: ['Trapezoidal', 'Metal Standing Seam'],
            } as RoofTypeCompatibility,
            attachmentTypeCompatibility: {
              nanoRafterMount: ['rafters'],
              nanoBit: ['rafters'],
              soloFlash: ['rafters'],
              nanoDeckMount: ['decking'],
            },
          },
        }
        const textFieldInputs = [
          ...(integrationData.conduitMount
            ? [
                {
                  label: 'Conduit mount quantity',
                  variableName: 'conduitMountQty',
                  default: 0,
                  type: 'number',
                  min: 0,
                },
              ]
            : []),
        ] as TextFieldInput[]

        const filterCriteria = [
          {
            filterType: 'roofTypeCompatibility',
            filterValue: roofTypeName,
          },
          {
            filterType: 'attachmentTypeCompatibility',
            filterValue: '#/attachmentType',
          },
        ] as FilterCriteria[]

        // Clear attachment type when the input is hidden to ensure the compatibility filtering works
        if (
          integrationData.mountType &&
          ['fiveTopTile', 'sevenTopTile', 'mrbMount'].includes(integrationData.mountType)
        )
          delete integrationData.attachmentType

        const inputsToDisplay = filterOptions(inputs, compatibilityRules, filterCriteria, integrationData)

        return {
          dropdownInputs: [...inputsToDisplay, ...(roofHookInputs.dropdownInputs ? roofHookInputs.dropdownInputs : [])],
          textFieldInputs: [
            ...textFieldInputs,
            ...(roofHookInputs.textFieldInputs ? roofHookInputs.textFieldInputs : []),
          ],
        }
      },
    },
    sunmodoNanoRack: {
      class: SunmodoNanoRack,
      manufacturer: 'Sunmodo',
      getInputs: (projectForm, system) => {
        const integrationData = system.integration_json?.sunmodoNanoRack as IntegrationData
        const projectData = projectForm.getState().values
        const roofTypeName = getRoofName(projectData.roof_type)
        const roofHookInputs = getRoofHookCalculationMethodInputs(system)
        const inputs: DropdownInput[] = [
          {
            label: 'Skirt configuration',
            variableName: 'skirtConfiguration',
            default: 'frontOnly',
            options: [
              { label: 'No Skirt', value: 'none' },
              { label: 'Front Skirt Only', value: 'frontOnly' },
              { label: 'Front & Side Skirt', value: 'frontAndSide' },
              { label: '360 Skirt', value: 'all' },
            ],
          },
          {
            label: 'Include MLPE mount?',
            variableName: 'mlpeMount',
            default: true,
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
          },
          {
            label: 'Conduit mount',
            variableName: 'conduitMount',
            default: false,
            options: [
              { label: 'None', value: false },
              { label: '3/4" conduit mount', value: 'CLMP-CON-075-S' },
              { label: '1" conduit mount', value: 'CLMP-CON-100-S' },
            ],
          },
          {
            label: 'Clamp placement',
            variableName: 'clampPlacement',
            default: 'long',
            options: [
              { label: 'Long side', value: 'long' },
              { label: 'Short side', value: 'short' },
            ],
          },
        ]

        return {
          dropdownInputs: [...inputs, ...(roofHookInputs.dropdownInputs ? roofHookInputs.dropdownInputs : [])],
          textFieldInputs: [],
        }
      },
    },
    solarRoofPro: {
      class: SolarRoofPro,
      manufacturer: 'Clenergy',
      getInputs: (projectForm, system) => {
        const roofHookInputs = getRoofHookCalculationMethodInputs(system)
        const railInputs = getRailDirectionInputs(system)
        return {
          dropdownInputs: [
            ...(railInputs.dropdownInputs ? railInputs.dropdownInputs : []),
            {
              label: 'Rail Length (mm)',
              variableName: 'railLength',
              default: 3500,
              options: [
                { label: 3500, value: 3500 },
                { label: 4600, value: 4600 },
              ],
            },
            {
              label: 'Clamp Colour',
              variableName: 'clampColour',
              default: 'black',
              options: [
                { label: 'Black', value: 'black' },
                { label: 'Silver', value: 'silver' },
              ],
            },
            {
              label: 'End Cap Colour',
              variableName: 'endCapColour',
              default: 'black',
              options: [
                { label: 'Black', value: 'black' },
                { label: 'Grey', value: 'grey' },
              ],
            },
            {
              label: 'Roof Hook',
              variableName: 'roofHook',
              default: 'ER-I-PRO/01',
              options: [
                {
                  label: 'Tile Interface for light to medium',
                  value: 'ER-I-PRO/01',
                },
                { label: 'Universal Adjustable Roof Hook For Tiles', value: 'ER-I-PRO-01A' },
                { label: 'Universal Landscape Adjustable Roof Hook For Tiles', value: 'ER-I-PRO-01A-LS' },
                { label: 'Flat Tile Roof Hook', value: 'ER-I-PRO-02' },
                { label: 'Slate/Prefa Roof Hook', value: 'ER-I-PRO-04' },
                { label: 'Flat Tile Tegalit Roof Hook', value: 'ER-I-PRO-07' },
              ],
            },
            ...(roofHookInputs.dropdownInputs ? roofHookInputs.dropdownInputs : []),
          ],
          textFieldInputs: roofHookInputs.textFieldInputs ? roofHookInputs.textFieldInputs : [],
        }
      },
    },
    variosole: {
      class: Variosole,
      manufacturer: 'Renusol',
      getInputs: (projectForm, system) => {
        const roofHookInputs = getRoofHookCalculationMethodInputs(system)
        const railInputs = getRailDirectionInputs(system)

        var options = [
          { label: '420172 RH Flat Roof Hook', value: '420172' },
          { label: '420171 RH1 Roof Hook', value: '420171' },
          { label: '420182 UK Flexible Roof Hook', value: '420182' },
          { label: '420183 UK Flexible 90 degrees Roof Hook', value: '420183' },
        ]

        if (roofTypeName) {
          if (roofTypeName === 'Tile Clay') {
            options.push(
              ...[
                { label: '420150 Stainless Steel Roof Hook', value: '420150' },
                { label: '420151 Aluminium Roof Hook', value: '420151' },
                { label: '420180 UK Pantiles Roof Hook', value: '420180' },
              ]
            )
          } else if (roofTypeName === 'Tile Concrete') {
            options.push(
              ...[
                { label: '420153 Plain Tiles Roof Hook', value: '420153' },
                { label: '420181 UK Plain Tiles / Slates Roof Hook', value: '420181' },
                { label: '420184 High Load UK Plain Tiles / Slates Roof Hook', value: '420184' },
              ]
            )
          } else if (roofTypeName === 'Tile Slate') {
            options.push(
              ...[
                { label: '420155 Slate Tiles Roof Hook', value: '420155' },
                { label: '420181 UK Plain Tiles / Slates Roof Hook', value: '420181' },
                { label: '420184 High Load UK Plain Tiles / Slates Roof Hook', value: '420184' },
              ]
            )
          }
        }

        return {
          dropdownInputs: [
            ...(railInputs.dropdownInputs ? railInputs.dropdownInputs : []),
            {
              label: 'Roof Hook',
              variableName: 'roofHook',
              default: '420172',
              options,
            },
            {
              label: 'Rail Length (mm)',
              variableName: 'railLength',
              default: 4400,
              options: [
                { label: 3300, value: 3300 },
                { label: 4400, value: 4400 },
              ],
            },
            {
              label: 'Rail Colour',
              variableName: 'railColour',
              default: 'black',
              options: [
                { label: 'Silver', value: 'silver' },
                { label: 'Black', value: 'black' },
              ],
            },
            {
              label: 'Clamp Colour',
              variableName: 'clampColour',
              default: 'black',
              options: [
                { label: 'Silver', value: 'silver' },
                { label: 'Black', value: 'black' },
              ],
            },
            {
              label: 'Genius Solution',
              variableName: 'geniusKit',
              default: 'None',
              options: [
                { label: 'None', value: 'None' },
                { label: 'Flat Tiled Kit', value: 'SFS003' },
                { label: 'Slate Refit Kit', value: 'SKI001' },
                { label: 'Slate Kit for New Build', value: 'SFS001' },
              ],
              // Note: The Genius refit kits are compatible with most roof anchors but be aware a 30mm step up distance is required from the anchor fixing plate and the arm of the roof anchor. Generally this means anchors designed for plain tiles are most suited and certain products are not compatible. Non compatible components are: SCH-256313, SCH-257125, SCH-256315, SLT-102001-000, SLT-102001-001, REN-920005..
            },
            ...(roofHookInputs.dropdownInputs ? roofHookInputs.dropdownInputs : []),
          ],
          textFieldInputs: roofHookInputs.textFieldInputs ? roofHookInputs.textFieldInputs : [],
        }
      },
    },
    schletter: {
      class: Schletter,
      manufacturer: 'Schletter',
      getInputs: (projectForm, system) => {
        if (!roofTypeName || !schletterCompatibleRoofTypes.includes(roofTypeName)) {
          return { dropdownInputs: [], textFieldInputs: [] }
        }

        const roofHookInputs = getRoofHookCalculationMethodInputs(system)
        const railInputs = getRailDirectionInputs(system)

        const integrationData = system.integration_json?.schletter as IntegrationData

        const filterCriteria = [
          {
            filterType: 'roofTypeCompatibility',
            filterValue: roofTypeName,
          },
          {
            filterType: 'railCompatibility',
            filterValue: '#/mountingRail',
          },
        ] as FilterCriteria[]

        const schletterInputsWithDefaultRoofHook = allSchletterInputs.map((input) => {
          if (roofTypeName === 'Tile Slate' && input.variableName === 'roofHook') {
            return { ...input, default: '109019-024' }
          }
          return input
        })

        var inputsToDisplay = filterOptions(
          schletterInputsWithDefaultRoofHook,
          schletterCompatibilityRules,
          filterCriteria,
          integrationData
        )

        const railOrientationsInSystem = getRailOrientationsInSystem(system, integrationData)
        if (!railOrientationsInSystem.horizontal)
          inputsToDisplay = inputsToDisplay.filter((input) => input.variableName !== 'horizontalEndClamp')
        if (!railOrientationsInSystem.vertical)
          inputsToDisplay = inputsToDisplay.filter((input) => input.variableName !== 'verticalEndClamp')

        return {
          dropdownInputs: [
            ...(railInputs.dropdownInputs ? railInputs.dropdownInputs : []),
            ...inputsToDisplay,
            ...(roofHookInputs.dropdownInputs ? roofHookInputs.dropdownInputs : []),
          ],
          textFieldInputs: roofHookInputs.textFieldInputs ? roofHookInputs.textFieldInputs : [],
        }
      },
    },
    fastensol: {
      class: Fastensol,
      manufacturer: 'Fastensol',
      getInputs: (projectForm, system) => {
        const roofHookInputs = getRoofHookCalculationMethodInputs(system)

        return {
          dropdownInputs: [
            {
              label: 'Railing',
              variableName: 'railColour',
              default: 'F-MR-S',
              options: [{ label: 'Silver Mounting Rail 3.3m', value: 'F-MR-S' }],
            },
            {
              label: 'Roof Hook',
              variableName: 'roofHook',
              default: 'F-TH02',
              options: [
                { label: 'Flat Tile Roof Hook Portrait Including 2PCS Wood Screw', value: 'F-TH02' },
                { label: 'Flat Tile Roof Hook Landscape Including 2PCS Wood Screw', value: 'F-TH02A' },
                { label: 'Pantile Roof Hook Portrait Including 3PCS Wood Screw', value: 'F-TH01' },
                { label: 'Pantile Roof Hook Landscape Including 3PCS Wood Screw', value: 'F-TH06' },
              ],
            },
            {
              label: 'End Cap',
              variableName: 'endCap',
              default: 'F-REC-S',
              options: [{ label: 'Rail End Cap Silver', value: 'F-REC-S' }],
            },
            {
              label: 'End Clamp',
              variableName: 'endClamp',
              default: 'F-BEC',
              options: [
                { label: 'Black End Clamp', value: 'F-BEC' },
                { label: 'Universal Clamp for 30-40mm Panel Thickness', value: 'F-UNIVERSAL-CLAMP' },
              ],
            },
            {
              label: 'Mid Clamp',
              variableName: 'midClamp',
              default: 'F-BMC',
              options: [
                { label: 'Black Mid Clamp', value: 'F-BMC' },
                { label: 'Universal Clamp for 30-40mm Panel Thickness', value: 'F-UNIVERSAL-CLAMP' },
              ],
            },
          ],
          textFieldInputs: roofHookInputs.textFieldInputs ? roofHookInputs.textFieldInputs : [],
        }
      },
    },
    msPitchedProLine: {
      class: MSPitchedProLine,
      manufacturer: 'Mounting Systems GmbH',
      getInputs: (projectForm, system) => {
        const roofHookInputs = getRoofHookCalculationMethodInputs(system)
        const getDefaultRange = (thickness): '30-40' | '33-45' | '38-50' =>
          thickness < 40 ? '30-40' : thickness < 45 ? '33-45' : '38-50'
        const defaultThickness = getDefaultRange(system?.moduleType()?.thickness || 1)

        const integrationData = system.integration_json?.msPitchedProLine as IntegrationData

        const filterCriteria = [
          {
            filterType: 'profileCompatibility',
            filterValue: '#/profile',
          },
          {
            filterType: 'roofHookMaterialCompatibility',
            filterValue: '#/roofHookMaterial',
          },
        ] as FilterCriteria[]

        var inputsToDisplay = filterOptions(
          gmbhDropdowns,
          msPitchedProlineCompatibilityRules,
          filterCriteria,
          integrationData
        )

        return {
          dropdownInputs: [
            {
              label: 'Clamp Colour',
              variableName: 'clampColour',
              default: 'black',
              options: [
                { label: 'Black', value: 'black' },
                { label: 'Silver', value: 'silver' },
              ],
            },
            {
              label: 'End Cap Colour',
              variableName: 'endCapColour',
              default: 'black',
              options: [
                { label: 'Black', value: 'black' },
                { label: 'Silver', value: 'silver' },
              ],
            },
            {
              label: 'Profile',
              variableName: 'profile',
              default: '4/35',
              options: [
                { label: '4/35', value: '4/35' },
                { label: '5/41', value: '5/41' },
                { label: '6/40', value: '6/40' },
                { label: '10/48', value: '10/48' },
              ],
            },
            {
              label: 'Panel Thickness Range',
              variableName: 'panelThicknessRange',
              default: defaultThickness,
              options: [
                { label: '30-40mm', value: '30-40' },
                { label: '33-45mm', value: '33-45' },
                { label: '38-50mm', value: '38-50' },
              ],
            },
            {
              label: 'Roof Hook Material',
              variableName: 'roofHookMaterial',
              default: 'stainlessSteel',
              options: [
                { label: 'Stainless Steel', value: 'stainlessSteel' },
                { label: 'Aluminium', value: 'aluminium' },
              ],
            },
            ...inputsToDisplay,
          ],
          textFieldInputs: roofHookInputs.textFieldInputs ? roofHookInputs.textFieldInputs : [],
        }
      },
    },
    msTrapezeProLine: {
      class: MSTrapezeProLine,
      manufacturer: 'Mounting Systems GmbH',
      getInputs: (projectForm, system) => {
        const spacingDisabled = system.integration_json?.msTrapezeProLine?.mountingRail === '712-0252'
        const roofHookInputs = getRoofHookCalculationMethodInputs(system, spacingDisabled)
        console.log(roofHookInputs)
        return {
          dropdownInputs: [
            {
              variableName: 'mountingRail',
              label: 'Rail Type',
              default: '712-0252',
              options: [
                {
                  value: '712-0252',
                  label: 'Mini',
                },
                {
                  value: '712-0254',
                  label: 'Continuous',
                },
              ],
            },
          ],
          textFieldInputs: roofHookInputs.textFieldInputs ? roofHookInputs.textFieldInputs : [],
        }
      },
    },
    esdec: {
      class: ClickFit,
      manufacturer: 'ESDEC',
      getInputs: (projectForm, system) => {
        const integrationData = system.integration_json?.esdec as IntegrationData

        const inputs = [
          {
            label: 'Rail Direction',
            variableName: 'railDirection',
            default: 'horizontal',
            options: [
              { label: 'Horizontal', value: 'horizontal' },
              { label: 'Vertical', value: 'vertical' },
            ],
          },
          {
            label: 'Eurocode Terrain Category',
            variableName: 'terrainCategory',
            default: 'EU_CATEGORY_3',
            options: [
              { label: 'Category 0', value: 'EU_CATEGORY_0' },
              { label: 'Category 1', value: 'EU_CATEGORY_1' },
              { label: 'Category 2', value: 'EU_CATEGORY_2' },
              { label: 'Category 3', value: 'EU_CATEGORY_3' },
              { label: 'Category 4', value: 'EU_CATEGORY_4' },
            ],
          },
          ...(roofTypeName?.includes('Tile')
            ? [
                {
                  label: 'Roof Hook',
                  variableName: 'roofHook',
                  default: 'NORMAL',
                  options: [
                    { label: 'Normal', value: 'NORMAL' },
                    { label: 'Truss', value: 'TRUSS' },
                  ],
                },
              ]
            : []),
          ...(roofTypeName === 'Metal Standing Seam'
            ? [
                {
                  label: 'Seam Type',
                  variableName: 'seamType',
                  default: 'SNAP',
                  options: [
                    { label: 'Single Folded', value: 'SINGLE_FOLDED' },
                    { label: 'Double Folded', value: 'DOUBLE_FOLDED' },
                    { label: 'Rounded', value: 'ROUNDED' },
                    { label: 'Snap', value: 'SNAP' },
                  ],
                },
              ]
            : []),
          {
            label: 'Supply black parts if possible?',
            variableName: 'blackArticles',
            default: false,
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
          },
        ] as DropdownInput[]

        const compatibilityRules = {
          system: {
            roofTypeCompatibility: {
              CLICKFIT_EVO: ['Tile Clay', 'Tile Concrete', 'Tile Slate', 'Trapezoidal', 'Metal Standing Seam'],
              FLATFIX_FUSION: ['Tar and Gravel / Bitumen', 'Membrane TPO'],
            } as RoofTypeCompatibility,
          },
        }

        const filterCriteria = [
          {
            filterType: 'roofTypeCompatibility',
            filterValue: roofTypeName,
          },
        ] as FilterCriteria[]

        const inputsToDisplay = filterOptions(inputs, compatibilityRules, filterCriteria, integrationData)

        return {
          dropdownInputs: [...inputsToDisplay] as DropdownInput[],
          textFieldInputs: [
            ...(roofTypeName === 'Metal Standing Seam'
              ? [
                  {
                    label: 'Flange Distance (mm)',
                    variableName: 'flangeDistance',
                    type: 'number',
                    default: 400,
                  },
                ]
              : []),
            {
              label: 'Wind Speed (m/s)',
              variableName: 'windSpeed',
              type: 'number',
              default: 15,
            },
            {
              label: 'Roof Height (m)',
              variableName: 'roofHeight',
              type: 'number',
              default: 5,
              max: 20,
            },
          ] as TextFieldInput[],
        }
      },
    },
    flatFix: {
      class: FlatFix,
      manufacturer: 'ESDEC',
      getInputs: (projectForm, system) => {
        const integrationData = system.integration_json?.esdec as IntegrationData

        const inputs = [
          {
            label: 'Panel Configuration',
            variableName: 'panelConfiguration',
            default: 'SINGLE',
            options: [
              { label: 'Single', value: 'SINGLE' },
              // {label: 'Dual', value:'DUAL'} // Dual is an East/West system which we don't support yet
            ],
          },
          {
            label: 'Eurocode Terrain Category',
            variableName: 'terrainCategory',
            default: 'EU_CATEGORY_3',
            options: [
              { label: 'Category 0', value: 'EU_CATEGORY_0' },
              { label: 'Category 1', value: 'EU_CATEGORY_1' },
              { label: 'Category 2', value: 'EU_CATEGORY_2' },
              { label: 'Category 3', value: 'EU_CATEGORY_3' },
              { label: 'Category 4', value: 'EU_CATEGORY_4' },
            ],
          },
          {
            label: 'Row Distance',
            variableName: 'rowDistance',
            default: 'ROW_DISTANCE_1700',
            options: [
              { label: '1320', value: 'ROW_DISTANCE_1320' },
              { label: '1500', value: 'ROW_DISTANCE_1500' },
              { label: '1700', value: 'ROW_DISTANCE_1700' },
              { label: '1890', value: 'ROW_DISTANCE_1890' },
              // These options above are only compatible with SINGLE panelConfiguration, the options for DUAL panelConfiguration (not yet supported in OpenSolar) are below
              // { label: '2100', value: 'ROW_DISTANCE_2100' },
              // { label: '2260', value: 'ROW_DISTANCE_2260' },
              // { label: '2440', value: 'ROW_DISTANCE_2440' },
              // { label: '2640', value: 'ROW_DISTANCE_2640' },
              // { label: '2830', value: 'ROW_DISTANCE_2830' },
            ],
          },
          ...(roofTypeName?.includes('TPO')
            ? [
                {
                  label: 'Use TPO Mats?',
                  variableName: 'tpoMats',
                  default: false,
                  options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ],
                },
              ]
            : []),
        ] as DropdownInput[]

        const compatibilityRules = {
          system: {
            roofTypeCompatibility: {
              CLICKFIT_EVO: ['Tile Clay', 'Tile Concrete', 'Tile Slate', 'Trapezoidal', 'Metal Standing Seam'],
              FLATFIX_FUSION: ['Tar and Gravel / Bitumen', 'Membrane TPO'],
            } as RoofTypeCompatibility,
          },
        }

        const filterCriteria = [
          {
            filterType: 'roofTypeCompatibility',
            filterValue: roofTypeName,
          },
        ] as FilterCriteria[]

        const inputsToDisplay = filterOptions(inputs, compatibilityRules, filterCriteria, integrationData)

        return {
          dropdownInputs: [...inputsToDisplay] as DropdownInput[],
          textFieldInputs: [
            {
              label: 'Wind Speed (m/s)',
              variableName: 'windSpeed',
              type: 'number',
              default: 15,
            },
            {
              label: 'Roof Height',
              variableName: 'roofHeight',
              type: 'number',
              default: 5,
            },
          ] as TextFieldInput[],
        }
      },
    },
    metasole: {
      class: Metasole,
      manufacturer: 'Renusol',
      getInputs: (projectForm, system) => {
        const panelOrientationsInSystem = getPanelOrientationsInSystem(system)

        const metasoleDropDownInputs = [] as DropdownInput[]
        const metasoleTextFieldInputs = [] as TextFieldInput[]

        if (panelOrientationsInSystem.landscape) {
          metasoleDropDownInputs.push({
            label: 'Include corrugated roof adapter for landscape panels? ',
            variableName: 'includeRoofAdapter',
            default: 'false',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ],
          })
        }

        if (panelOrientationsInSystem.portrait) {
          metasoleTextFieldInputs.push({
            label: 'Roof rib distance',
            variableName: 'roofRibDistance',
            default: 250,
            type: 'number',
            min: 10,
            max: 370,
          })
        }

        return {
          dropdownInputs: [...metasoleDropDownInputs],
          textFieldInputs: [...metasoleTextFieldInputs],
        }
      },
    },
    gseInRoof: {
      class: GseInRoof,
      manufacturer: 'GSE',
      getInputs: (projectForm, system) => {
        const items = [
          {
            label: 'Clamps per panel',
            variableName: 'clampQty',
            default: '6 or 8',
            options: [
              { label: '4', value: '4' },
              { label: '6 or 8 (determined by frame)', value: '6 or 8' },
            ],
          },
          {
            label: 'Top Connection',
            variableName: 'topConnection',
            default: 'flexalu',
            options: [
              { label: 'Top Flashing', value: 'flashing' },
              { label: 'Flexible Strip', value: 'flexalu' },
            ],
          },
          {
            label: 'Flexible Strip',
            variableName: 'flexibleStrip',
            default: 330,
            options: [
              { label: 'Flexible Sealing Strip 330mm x 5m', value: 330 },
              { label: 'Flexible Sealing Strip 500mm x 5m', value: 500 },
            ],
          },
          {
            label: 'Does the module have a rear support bar?',
            variableName: 'rearSupportBarIncluded',
            default: false,
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
          },
        ] as DropdownInput[]

        return {
          dropdownInputs: [...items],
          textFieldInputs: [],
        }
      },
    },
  }
}

export default getNativeMountingSystems
