import { orderSelectors } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { ShippingRateDataType } from 'pages/ordering/checkout/solarOutlet/solarJuice/type'
import type { LegacyDataProvider } from 'react-admin'

class SolarOutletService {
  private SHOPIFY_TRACKING_QUERY = '?utm_source=opensolar&utm_medium=app&utm_campaign=design_order'
  private shopifyDomain: string
  private createCheckout
  private applyDiscountCodeToCheckout
  private restClientInstance: LegacyDataProvider
  private orgId: number

  constructor(
    shopifyDomain: string,
    createCheckout,
    applyDiscountCodeToCheckout,
    restClientInstance: LegacyDataProvider,
    orgId: number
  ) {
    this.shopifyDomain = shopifyDomain
    this.createCheckout = createCheckout
    this.applyDiscountCodeToCheckout = applyDiscountCodeToCheckout
    this.restClientInstance = restClientInstance
    this.orgId = orgId
  }

  private toBase64 = (input) => btoa(unescape(encodeURIComponent(input)))
  private variantIdtoBase64 = (variantId) => this.toBase64('gid://shopify/ProductVariant/' + variantId)

  private applyDiscountCode = async ({ checkoutId, discountCode }: { checkoutId: string; discountCode: string }) => {
    await this.applyDiscountCodeToCheckout({
      variables: {
        checkoutId,
        discountCode,
      },
    })
  }

  private applyDiscountCodes = async ({
    checkoutId,
    discountCodes,
  }: {
    checkoutId: string
    discountCodes: string[]
  }) => {
    if (discountCodes.length === 0) {
      return
    }
    /*
        Unfortunately, have to call it in sequence because concurrently calling applyDiscountCode receive GraphQL errors.
    */
    for (const discountCode of discountCodes) {
      await this.applyDiscountCode({
        checkoutId,
        discountCode,
      })
    }
  }

  generateShopifyCartUrl = (lineItems: LineItemType[]) => {
    const orderVariantsAndQuantifiesForShopify = lineItems
      .filter((lineItem: LineItemType) => lineItem.variantId && lineItem.quantity > 0)
      .map((lineItem) => ({
        id: lineItem.variantId,
        quantity: lineItem.quantity,
      }))

    const variantsAndQuantitiesString = orderVariantsAndQuantifiesForShopify
      .map((item) => `${item.id}:${item.quantity}`)
      .join(',')

    return `${this.shopifyDomain}/cart/${variantsAndQuantitiesString}${this.SHOPIFY_TRACKING_QUERY}`
  }

  syncProduct = async (): Promise<void> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `ordering/outlet/sync/`,
    })
  }

  checkout = async (lineItems: LineItemType[], version?: string) => {
    const orderFormatted = lineItems
      .filter((lineItem) => lineItem.variantId && lineItem.quantity > 0)
      .map((lineItem) => ({
        variantId:
          version === '3'
            ? this.variantIdtoBase64(lineItem.selectedDistributorOrderingData?.distributor_variant_id)
            : this.variantIdtoBase64(lineItem.variantId),
        quantity: lineItem.quantity,
        customAttributes: [
          {
            key: 'Org',
            value: 'Open Solar',
          },
          {
            key: 'OrgDiscount',
            value: '0.04',
          },
        ],
      }))
    const osExclusiveDiscountCodes: string[] = []
    lineItems.forEach((lineItem: LineItemType) => {
      const osExclusiveDiscountCode = lineItem.getBestDiscountOffer().discount?.codes
      if (!!osExclusiveDiscountCode) osExclusiveDiscountCodes.push(osExclusiveDiscountCode)
    })

    const createCheckoutResult = await this.createCheckout({
      variables: {
        input: {
          lineItems: orderFormatted,
        },
      },
    })
    const checkout = createCheckoutResult?.data?.checkoutCreate?.checkout
    if (checkout == null) {
      return undefined
    }

    const projectIds = orderSelectors.getProjectListByLineItems(lineItems)
    await Promise.all([
      this.recordCheckout({ checkout, projectIds }),
      this.applyDiscountCodes({
        checkoutId: checkout.id,
        discountCodes: osExclusiveDiscountCodes,
      }),
    ])
    return checkout
  }

  createSolarJuiceOrder = async ({ checkout, projectIds }) => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${this.orgId}/_orders/outlet/solar_juice/create/`,
      data: {
        checkout,
        project_ids: projectIds,
      },
    }).catch((error) => {
      console.error('Unable to save order', error)
    })
    return response?.data?.order_id
  }

  recordCheckout = async ({ checkout, projectIds }) => {
    await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${this.orgId}/_orders/outlet/create/`,
      data: {
        checkout,
        project_ids: projectIds,
      },
    }).catch((error) => {
      console.error('Unable to save order before redirecting to checkout', error)
    })
  }

  getShippingRates = async (data: ShippingRateDataType) => {
    const resp = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `outlet_api/shipping_rates/`,
      data,
    }).catch((error) => {
      console.error('Unable to compute shipping rates', error)
    })
    return resp?.data?.rates
  }
}

export default SolarOutletService
