import { PERFORMANCE_CALCULATOR_ID } from 'constants/calculators'
import { StdFieldErrorType } from 'reducer/project/projectErrorsReducer'
import { AvailableCustomerActionsType } from './customerActions'
import { EventCategoryEnum, EventFormVariant, EventIconVariant } from './events'
import { PrivateFileType } from './privateFile'
import { ProjectTransactionType, ProjectTransactionTypeV3 } from './projectTransactions'
import { ProposalDataType, SelectedProjectProposalDataType } from './proposals'
import { PermissionSets, RoleType } from './roles'
import { StudioSystemType } from './studio/items'
import { SystemDataType } from './systems'
import { TestimonialType } from './testimonials'
import { NormalizedUsageType } from './usage'
import { UtilityType } from './utilities'

type Statement = {
  balance: number
  label: string
  value: number
}

//TODO: add 'new' to this type, will cause a lot of (important) downstream complaints
export type ProjectId = number // | 'new'

/*
Note: is_commercial is not available on the project model. It can be used as a derived field but
it should never be used where we are treating it as a possibly dynamic field on the project form.
*/
export interface BaseProjectType {
  id: ProjectId
  url?: URL
  is_residential?: boolean
  title?: string
  identifier: string
  business_name: string | null
  address: string | null
  zip: string | null
  state: string | null
  language: string | null
  language_override: string | null
  locality: string | null
  country: URL
  county: string | null
  stage: number
  country_iso2: string | null
  lat: number
  lon: number
  contract_date: Date | null
  //Todo: add contact model
  contacts?: any[]
  contacts_new?: ContactData[]
  contacts_data?: ContactData[]
  design?: string
  org_id: number
  assigned_role?: RoleType
  priority?: number
  created_date: Date | null
  modified_date: Date | null
  usage?: string
  //Todo: fix this type
  utility_tariff_current: any | null
  utility_tariff_proposed: any | null
  utility_tariff_current_custom: any | null
  utility_tariff_proposed_custom: any | null
  assigned_team_member: RoleType
  utility_tariff_or_guess: UtilityType
  utility_tariff_proposed_or_guess: UtilityType
  usage_annual_or_guess: number
  available_customer_actions?: AvailableCustomerActionsType
  actions?: StageActionType
  statement: Statement[] | null
  original_system_sold?: string | null
  system_sold?: string | null
  systems?: SystemDataType[]
  payment_option_sold?: URL | null
  simulate_first_year_only?: boolean
  api_key_chat?: string
  usage_normalized?: NormalizedUsageType
  testimonials?: TestimonialType[]
  customer_proposal_data?: ProposalDataType
  stage_name?: string
  lead_source?: string
  tag_names?: string
  valid_until_date?: string | null
  installation_date?: string | null
  business_identifier?: string
  meter_identifier?: string
  utility_tariff_current_code?: string
  utility_tariff_proposed_code?: string
  proposal_message?: string
  wind_region?: any | null
  has_cellular_coverage?: number
  power_factor?: any | null
  number_of_storeys: number | null
  number_of_phases: number | null
  number_of_wires: number | null
  parcel_identifier?: string
  serial_numbers_panels?: string
  serial_numbers_inverters?: string
  serial_numbers_batteries?: string
  years_to_simulate?: number
  is_pricing_locked?: boolean
  roof_type_name?: string
  site_notes?: string
  notes?: string
  proposal_content?: string
  contract_terms?: string
  transactions_data?: ProjectTransactionType[] | ProjectTransactionTypeV3[]
  events_data?: StageActionEventType[]
  greenlancer_project_id?: string | undefined
  docusign_contract_envelope_id?: string

  access: number
  events: unknown[]
  assigned_role_data: RoleType
  assigned_role_email: string
  assigned_role_id: number
  assigned_role_name: string
  assigned_role_phone: string
  assigned_role_accreditation: string
  assigned_installer_role_data: RoleType
  assigned_installer_role: unknown
  assigned_site_inspector_role_data: RoleType
  assigned_site_inspector_role: unknown
  change_orders: ChangeOrderType[]
  initiated_change_order: number | null
  configuration_override: Partial<ProjectConfigType> | null
  configuration: ProjectConfigType
  costing_override: unknown
  costing: Record<string, any>
  contract: unknown
  country_name: string
  last_calculation_error: unknown
  calculation_error_messages: StdFieldErrorType[] | null
  org: string
  private_files_data: PrivateFileType[]
  private_files: unknown[]
  proposal_template: unknown
  proposal_template_settings: {
    title: string
    type: unknown
    pvprosell_template_id: unknown
  }
  roof_type: string
  sold_date: Timestamp
  stars: unknown[]
  system_installed: unknown
  tags_data: TagData[]
  tags: unknown[]
  temperature_min_max: [number, number]
  temperature_max_override: number
  temperature_min_override: number
  testimonials_data: unknown[]
  timezone_offset: number
  utility_tariff_current_guess: UtilityType
  federal_income_tax_rate_percentage: unknown
  state_income_tax_rate_percentage: unknown
  custom_project_info_1: string
  custom_project_info_2: string
  custom_project_info_3: string
  custom_project_info_4: string
  custom_project_info_5: string
  allow_email_notifications: true
  brighte_role_connection_status: 'connection_missing' | 'connected' | 'no_role' | undefined
  auto_apply_max_simulate_years: false
  contract_template_mode: string
  is_lite: true
  activated_premium_imagery: []
  activated_premium_imagery_wallet_product_ids: []
  roof_type_id: number | null
  selected_system?: StudioSystemType
  shared_with?: ProjectShare[]
  unshared_items?: ProjectUnsharedItems
  permissions?: PermissionSets
  tax_name?: string
  workflow: ProjectWorkflowBrief
  workflows: ProjectWorkflowType
  project_sold: null | ImplicitExplicitBoolean
  project_installed: null | ImplicitExplicitBoolean
  custom_data?: Record<CustomDataKeys, Record<string, any>>
}

type CustomDataKeys = 'scaffolding' | '_solarapp_permit' | "proposal_config_v2"

enum ImplicitExplicitBoolean {
  AutoEnabled = 1,
  ManuallyEnabled = 2,
}

export interface TagData {
  id: number
  title: string
  url: string
}
export interface ProjectWorkflowBrief {
  id: number
  org_id: number
  workflow_id: number
  active_stage_id: number | string
}

export interface ProjectWorkflowType extends ProjectWorkflowBrief {
  workflow_url: number
  org_name: string
  is_selected: boolean
  active_stage_title: string
}

export interface ProjectType extends BaseProjectType {
  proposal_data?: ProposalDataType
  is_commercial?: boolean
  files_data?: FileDataBrief[]
}
export type FileDataBrief = {
  id?: number
  title: string
  system_uuid?: string
  file_contents_b64: string
  file_tag_titles: string[]
}

// the project inside of ProposalData has a different shape
export interface SelectedProjectType extends BaseProjectType {
  proposal_data?: SelectedProjectProposalDataType
}

export interface ProjectShare {
  is_shared: boolean
  org_name: string
  org_id: number
}
interface ProjectUnsharedItems {
  configuration_override?: UnsharedEntity
  proposal_template?: UnsharedEntity
  contract_by_project?: UnsharedEntity
  contract_by_system?: UnsharedEntity[]
  testimonials?: UnsharedEntity[]
  pricing_schemes?: UnsharedEntity[]
  costing_schemes?: UnsharedEntity[]
  adders?: UnsharedEntity[]
  payment_options?: UnsharedEntity[]
  battery_control_scheme?: UnsharedEntity
  components?: Object
}

interface UnsharedEntity {
  title: string
  url: string
}

export interface ProjectConfigType {
  id: number
  url: string
  org: string
  title: string
  description: string
  priority: false
  created_date: Timestamp
  modified_date: Timestamp
  performance_calculator: PERFORMANCE_CALCULATOR_ID
  weather_dataset: number
  inverter_modelling_automation: number
  setbacks_default: number
  setbacks_gutter: number
  setbacks_ridge: number
  setbacks_valley: number
  setbacks_hip: number
  setbacks_rake: number
  setbacks_shared: number
  setbacks_flat_gutter: number
  setbacks_skylight: number
  setbacks_dormer: number
  setbacks_objects: number
  setbacks_arrays: number
  tilt_rack_default_tilt: number
  tilt_rack_default_orientation: 'landscape' | 'portrait'
  apply_tilt_racks_below_slope: number
  years_to_simulate: number
  performance_adjustment: number
  discount_rate: number
  utility_inflation_annual: string
  feed_in_tariff_inflation_annual: 0
  utility_inflation_annual_lower_bound: number
  utility_inflation_annual_upper_bound: number
  scaling_factors_label: string
  proposed_usage_adjustment: number
  sam_string_soiling: number
  sam_string_dc_wiring: number
  sam_string_mismatch: number
  sam_micro_soiling: number
  sam_micro_dc_wiring: number
  sam_micro_mismatch: number
  sam_optimizer_soiling: number
  sam_optimizer_dc_wiring: number
  sam_optimizer_mismatch: number
  sam_ac_wiring: number
  sam_nameplate: number
  sam_diodes_and_connections: number
  sam_dc_system_availability: number
  sam_ac_system_availability: number
  show_tax_effects_of_solar: boolean
  auto_apply_max_simulate_years: boolean
}

//TODO: constrain further
export type Timestamp = string // e.g. '2022-08-31T03:39:22.225002Z'

export interface StageActionType {
  created_date: string
  events: StageActionEventType[]
  id: number
  modified_date: string
  order: number
  org: string
  share_with_orgs: string[]
  stage: number
  title: string
  url: string
}

export interface StageActionEventType {
  action_id: number
  completion_date: string
  created_date: string
  duration: number
  end: string
  event_type_id: number
  id: number
  org: string
  is_archived: boolean
  is_complete: boolean
  is_planned: boolean
  modified_date: string
  notes: string | null
  project_id: number
  project_name: string
  start: string
  task_status: number
  title: string
  who: {
    display: string
    email: string
    portrait_image_public_url: string
  }
  categories: EventCategoryEnum[]
  form_config: EventFormVariant
  event_icon: EventIconVariant
  team_members?: string[]
}

export enum ContactDataTypeEnum {
  REAL_CONTACT = 0,
  ANONYMOUS_CONTACT = 1,
}

export interface ContactData {
  display: string
  email: string
  family_name: string
  first_name: string
  full_name: string
  id: number
  identifer: string
  org_id: number
  phone: string
  url: string
  user_id: string
  type: ContactDataTypeEnum
}

export interface ChangeOrderType {
  id: number
  allow_review: boolean
  contract_date: Date | null
  created_date: Date
  document_template: number | null
  is_complete: boolean
  payment_option_sold: number | null
  payment_option_title: string | null
  project: number | null
  system_sold: number | null
}
