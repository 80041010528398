import { FormControlLabel, makeStyles, Theme, Tooltip, useMediaQuery } from '@material-ui/core'
import {
  CheckOutlined,
  EditOutlined,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Checkbox } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { OpenSolarThemeType } from 'Themes'
import ACHForm from './ach/ACHForm'
import BECSForm from './becs/BECSForm'
import { PAYMENT_METHOD_LABEL_MAP, THREE_DS_ENABLED_COUNTRIES } from './constants'
import CreditCardForm from './creditCards/CreditCardForm'
import SEPACreditForm from './sepa/SEPACreditForm'
import {
  ACHPaymentDataType,
  BECSPaymentDataType,
  BlueSnapCardDataType,
  PaymentRequestType,
  PaymentStaticCopy,
  SelectedPaymentMethodType,
} from './types'

type PropTypes = {
  paymentRequestData: PaymentRequestType
  projectId?: string
  doSubmitPayment: (cardData?: BlueSnapCardDataType | ACHPaymentDataType | BECSPaymentDataType) => void
  pmtMethod: SelectedPaymentMethodType
  countryIso2: string
  orgName: string
  paymentStaticCopy: PaymentStaticCopy
  setPaymentMethod: (PaymentMethodType) => void
}

const useStyles = makeStyles<OpenSolarThemeType, { isChecked: boolean; isMobile: boolean }>((Theme) => ({
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
  },
  checkboxRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: ({ isMobile }) => (isMobile ? '8px 12px' : '8px 24px'),
    borderColor: ({ isChecked }) => (isChecked ? '#4272DD' : '#CDCDCD'),
    borderWidth: ({ isChecked }) => (isChecked ? '2px 2px 2px 2px' : '0 0 1px 0'),
    borderStyle: 'solid',
  },
  paymentWrapper: {
    padding: ({ isMobile }) => (isMobile ? '1.5rem' : '1.5rem 2rem 1rem'),
    background: '#F8F8F8',
    borderBottom: '1px solid #CDCDCD',
  },
  acceptedCheckRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkWrapper: {
    width: '42px',
    height: '42px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    backgroundColor: Theme.alert_success,
    color: 'white',
    borderRadius: '25px',
    fontSize: '18px',
  },
  editIcon: {
    color: Theme.greyMid1,
    marginLeft: '10px',
    cursor: 'pointer',
  },
  paymentMethodsContainer: {
    display: 'flex',
    gap: '6px',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  ccIconWrapper: {
    padding: '0px 3px',
  },
}))

const PaymentMethodPanel: React.FC<PropTypes> = (props) => {
  const [collapseSelectedPaymentMethod, setCollapseSelectedPaymentMethod] = useState<boolean>(false)
  // used to dismount the form component after the payment method has been submitted. This ensures if the user tries
  // to go back and edit their payment method data (eg update their credit card) they have to start from scratch with a new token
  const [forceFormDismount, setForceFormDismount] = useState<boolean>(false)
  const [paymentInfoEntered, setPaymentInfoEntered] = useState<boolean>(false)
  const [prevPaymentMethod, setPrevPaymentMethod] = useState<SelectedPaymentMethodType>()
  const form = useForm()
  const formState = useFormState()
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const is3DSRequired = THREE_DS_ENABLED_COUNTRIES.includes(props.countryIso2)

  const isChecked = useMemo(() => {
    return formState.values?.payment_method_type === props.pmtMethod
  }, [formState.values?.payment_method_type])

  // returns true when a different payment method has been saved (so it was selected by the user and then they clicked "next")
  const otherPmtIsSaved = useMemo(() => {
    return (
      formState?.values?.saved_payment_method_type && formState?.values?.saved_payment_method_type !== props.pmtMethod
    )
  }, [formState?.values?.saved_payment_method_type])

  const handleChange = (e) => {
    // don't let them uncheck a selected payment method. They have to select another one
    if (e.target.checked) {
      form.change('payment_method_type', props.pmtMethod)
    }
    setForceFormDismount(false)
    setCollapseSelectedPaymentMethod(false)
  }

  const initiateEdit = () => {
    setForceFormDismount(false)
    setCollapseSelectedPaymentMethod(false)
    form.change('payment_method_type', props.pmtMethod)
    form.change('is_editing', true)
  }

  const cancelEdit = () => {
    setForceFormDismount(true)
    setCollapseSelectedPaymentMethod(true)
    // if the user added one payment method, then opened the form for another one and changed their mind we should revert things
    // back to the state where their initial payment method is selected
    if (formState.values?.saved_payment_method_type) {
      form.change('payment_method_type', formState.values.saved_payment_method_type)
      form.change('is_editing', true)
    } else {
      form.change('is_editing', false)
    }
  }

  const savePayment = (paymentData: BlueSnapCardDataType | ACHPaymentDataType | BECSPaymentDataType | undefined) => {
    setCollapseSelectedPaymentMethod(true)
    props.doSubmitPayment(paymentData)
    setForceFormDismount(true)
    form.change('is_editing', false)
    form.change('saved_payment_method_type', props.pmtMethod)
    // clear out bank fields from form state if the user just submitted their ACH/BECS details
    if (props.pmtMethod === 'ach') {
      form.change('ecpRoutingNumber', undefined)
      form.change('ecpAccountNumber', undefined)
      form.change('ecpAccountNumberConfirm', undefined)
      form.change('ecpAccountType', undefined)
      form.change('signature_data', undefined)
      form.change('authorizedByShopper', undefined)
    }
    if (props.pmtMethod === 'becs_direct_debit') {
      form.change('bsb_number', undefined)
      form.change('account_number', undefined)
      form.change('accountNumberConfirm', undefined)
      form.change('authorizedByShopper', undefined)
    }
  }

  const classes = useStyles({ isChecked, isMobile })

  const paymentMethods = props.paymentRequestData.payment_methods
  const orgCountry = props.countryIso2
  const ACCEPTED_CARDS_MAP = {
    US: 'Mercury Card, Diners Club International, JCB',
    GB: 'Diners Club International, JCB',
    EUR: 'Diners Club International, JCB',
    FR: 'Cartes Bancaires',
  }

  const acceptedCards = useMemo(() => {
    return ACCEPTED_CARDS_MAP[orgCountry]
  }, [orgCountry])

  useEffect(() => {
    if (props.pmtMethod) {
      logAmplitudeEvent('cashflow_payment_method_rendered', {
        payment_method: props.pmtMethod,
        project_id: props.projectId,
        org_id: props.paymentRequestData.org_id,
        country_iso2: props.countryIso2,
      })
    }
  }, [props.pmtMethod])

  useEffect(() => {
    if (prevPaymentMethod) {
      logAmplitudeEvent('cashflow_payment_method_switched', {
        previous_payment_method: prevPaymentMethod,
        new_payment_method: props.pmtMethod,
        project_id: props.projectId,
        org_id: props.paymentRequestData.org_id,
        country_iso2: props.countryIso2,
      })
    }
  }, [prevPaymentMethod])

  useEffect(() => {
    if (prevPaymentMethod) {
      logAmplitudeEvent('cashflow_payment_method_abandoned', {
        abandoned_payment_method: prevPaymentMethod,
        project_id: props.projectId,
        org_id: props.paymentRequestData.org_id,
        country_iso2: props.countryIso2,
      })
    }
  }, [paymentInfoEntered])

  const renderForm = () => (
    // hide the forms until the user is ready to fill them out but still render the form either way.
    // this avoids some messiness related to losing data
    <div style={{ display: isChecked && !collapseSelectedPaymentMethod ? undefined : 'none' }}>
      <div className={classes.paymentWrapper}>
        {props.pmtMethod === 'credit_card' && (
          <CreditCardForm
            projectId={props.projectId}
            doSubmitPayment={savePayment}
            paymentRequestData={props.paymentRequestData}
            countryIso2={props.countryIso2}
            cancelEdit={cancelEdit}
            setPaymentInfoEntered={setPaymentInfoEntered}
          />
        )}
        {/* local bank transfer is no longer supported, if we re-activate this then the component needs to fetch its own SDK token */}
        {/* {props.pmtMethod === 'local_bank_transfer' && (
          <LocalBankTransferForm projectId={props.projectId} doSubmitPayment={props.doSubmitPayment} />
        )} */}
        {props.pmtMethod === 'ach' && (
          <ACHForm
            paymentRequestData={props.paymentRequestData}
            projectId={props.projectId}
            doSubmitPayment={savePayment}
            countryIso2={props.countryIso2}
            orgName={props.orgName}
            revocationText={props.paymentStaticCopy?.ach?.revocation}
            esignatureText={props.paymentStaticCopy?.ach?.esignature}
            cancelEdit={cancelEdit}
            setPaymentInfoEntered={setPaymentInfoEntered}
          />
        )}
        {props.pmtMethod === 'becs_direct_debit' && (
          <BECSForm
            paymentRequestData={props.paymentRequestData}
            projectId={props.projectId || ''}
            doSubmitPayment={savePayment}
            countryIso2={props.countryIso2}
            orgName={props.orgName}
            esignatureText={props.paymentStaticCopy?.becs?.esignature}
            setPaymentInfoEntered={setPaymentInfoEntered}
          />
        )}
        {props.pmtMethod === 'sepa_credit' && (
          <SEPACreditForm paymentRequestData={props.paymentRequestData} />
        )}
      </div>
    </div>
  )

  const handleWrapperClick = () => {
    if (!isChecked) {
      setPrevPaymentMethod(form.getState().values.payment_method_type)
      form.change('payment_method_type', props.pmtMethod)
      props.setPaymentMethod(props.pmtMethod)

      logAmplitudeEvent('cashflow_payment_method_rendered', {
        payment_method: props.pmtMethod,
        project_id: props.projectId,
        org_id: props.paymentRequestData.org_id,
        country_iso2: props.countryIso2,
      })

      logAmplitudeEvent('cashflow_payment_method_selected', {
        payment_method: props.pmtMethod,
        project_id: props.projectId,
        org_id: props.paymentRequestData.org_id,
        country_iso2: props.countryIso2,
      })

      if (is3DSRequired && props.pmtMethod === 'credit_card') {
        logAmplitudeEvent('cashflow_3ds_initiated', {
          project_id: props.projectId,
          org_id: props.paymentRequestData.org_id,
          country_iso2: props.countryIso2,
        })
      }

      if (is3DSRequired && prevPaymentMethod === 'credit_card') {
        logAmplitudeEvent('cashflow_3ds_abandoned', {
          project_id: props.projectId,
          org_id: props.paymentRequestData.org_id,
          country_iso2: props.countryIso2,
        })
      }
    }
  }

  return (
    <div>
      <div className={classes.checkboxRowWrapper} onClick={handleWrapperClick}>
        <div className={classes.checkboxLabel}>
          {collapseSelectedPaymentMethod && !otherPmtIsSaved ? (
            <div className={classes.acceptedCheckRow}>
              <div className={classes.checkWrapper}>
                <CheckOutlined className={classes.checkIcon} />
              </div>
              <h2>{translate(PAYMENT_METHOD_LABEL_MAP[props.pmtMethod])}</h2>
              <div onClick={initiateEdit}>
                <EditOutlined className={classes.editIcon} />
              </div>
            </div>
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  data-testid={`payment-method-${props.pmtMethod}`}
                  checked={isChecked}
                  onChange={handleChange}
                  icon={<RadioButtonUncheckedOutlined />}
                  checkedIcon={<RadioButtonCheckedOutlined />}
                />
              }
              label={<h2>{translate(PAYMENT_METHOD_LABEL_MAP[props.pmtMethod])}</h2>}
            />
          )}
        </div>

        {props.pmtMethod === 'credit_card' &&
          (acceptedCards ? (
            <div className={classes.paymentMethodsContainer}>
              {paymentMethods.includes('credit_card_standard') && (
                <img src={`${window.PUBLIC_URL}/images/cashflow/credit_card_standard.svg`} />
              )}
              {paymentMethods.includes('credit_card_premium') && (
                <>
                  <div className={classes.ccIconWrapper}>
                    <img src={`${window.PUBLIC_URL}/images/cashflow/Amex.svg`} />
                  </div>
                  <div className={classes.ccIconWrapper}>
                    <img src={`${window.PUBLIC_URL}/images/cashflow/Discover.svg`} />
                  </div>
                  <div className={classes.ccIconWrapper}>
                    <Tooltip title={acceptedCards}>
                      <img src={`${window.PUBLIC_URL}/images/cashflow/credit_card_others.svg`} />
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          ) : (
            <img src={`${window.PUBLIC_URL}/images/cashflow/credit_card_standard.svg`} />
          ))}
      </div>
      {!forceFormDismount && renderForm()}
    </div>
  )
}

export default PaymentMethodPanel
