import { Dialog, DialogTitle } from '@material-ui/core'
import { Add, CloseOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { Button, IconButton, Stepper, styled } from 'opensolar-ui'
import ConfigureCustomInvoice from 'projectSections/sections/payments/cashFlowTransactions/customInvoice/ConfigureCustomInvoice'
import { StandalonePaymentRequestSettings } from 'projectSections/sections/payments/cashFlowTransactions/customInvoice/CustomInvoiceButton'
import PreviewCustomInvoice from 'projectSections/sections/payments/cashFlowTransactions/customInvoice/PreviewCustomInvoice'
import ReviewAndSendCustomInvoice from 'projectSections/sections/payments/cashFlowTransactions/customInvoice/ReviewAndSendCustomInvoice'
import { DEFAULT_LINE_ITEM } from 'projectSections/sections/payments/cashFlowTransactions/customInvoice/constants'
import {
  InvoiceEmail,
  LineItem,
  SimpleInvoiceType,
} from 'projectSections/sections/payments/cashFlowTransactions/customInvoice/types'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import InvoiceContactDetailsPage from './InvoiceContactDetailsPage'
import { STEPS } from './constants'
import { InvoiceContactDetails } from './types'
import { DEFAULT_INVOICE_DETAILS } from './utils'

const restClientInstance = restClient(window.API_ROOT + '/api')

const TitleWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.grey[300],
}))

const CloseBtn = styled(CloseOutlined)(({ theme }) => ({
  color: theme.palette.grey[500],
}))

type PropTypes = {
  setRefreshKey: (number) => void
}

const StandaloneInvoiceBtn: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [lineItems, setLineItems] = useState<LineItem[]>([DEFAULT_LINE_ITEM])
  const [paymentRequestSettings, setPaymentRequestSettings] = useState<StandalonePaymentRequestSettings>()
  const [invoice, setInvoice] = useState<SimpleInvoiceType | undefined>(undefined)
  const [contactDetails, setContactDetails] = useState<InvoiceContactDetails>(DEFAULT_INVOICE_DETAILS)
  const [activeStep, setActiveStep] = useState(0)
  const currentStage = STEPS[activeStep].stepId

  const orgId = useSelector(authSelectors.getOrgId)

  const onClose = () => {
    setIsOpen(false)
    setActiveStep(0)
    setContactDetails(DEFAULT_INVOICE_DETAILS)
    setLineItems([DEFAULT_LINE_ITEM])
    setInvoice(undefined)
    setPaymentRequestSettings(undefined)
  }

  const onOpen = () => {
    setIsOpen(true)
  }

  const nextPage = () => {
    if (activeStep < STEPS.length - 1) {
      setActiveStep(activeStep + 1)
    }
  }

  const previousPage = (formvals?) => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }

    if (currentStage === 'configure') {
      setLineItems(formvals.lineItems)
      setPaymentRequestSettings({
        title: formvals.title,
        description: formvals.description,
        accepted_payment_methods: formvals.accepted_payment_methods,
        surcharging_enabled: formvals.surcharging_enabled,
      })
    }
  }

  const saveContactDetails = (details) => {
    setContactDetails(details)
    nextPage()
  }

  const sendInvoice = async (emailConfig: InvoiceEmail) => {
    setIsLoading(true)
    restClientInstance('CUSTOM_PATCH', 'custom', {
      url: `orgs/${orgId}/invoices/${invoice?.id}/issue_and_send/`,
      data: { ...emailConfig },
    })
      .then(() => {
        onClose()
        notify(translate('Email sent'), 'success')
        logAmplitudeEvent('cashflow_custom_invoice_dialog_sent', {
          org_id: orgId,
        })
      })
      .catch((err) => {
        if (err?.body?.message) notify(err.body.message, 'warning')
        else notify(translate('Something went wrong and we are unable to send this invoice'), 'warning')
      })
      .finally(() => {
        props.setRefreshKey((prev) => prev + 1)
        setIsLoading(false)
      })
  }

  const makeInvoiceDraft = (invoiceLineItems: LineItem[], paymentRequestFields: StandalonePaymentRequestSettings) => {
    setLineItems(invoiceLineItems)
    setPaymentRequestSettings(paymentRequestFields)
    setIsLoading(true)

    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/cashflow_invoices/adhoc_inovices/`,
      data: {
        line_items: invoiceLineItems,
        ...paymentRequestFields,
        ...contactDetails,
      },
    })
      .then((res) => {
        setInvoice({
          id: res.data.invoice.id,
          download_url: res.data.invoice.pdf_download_url,
          invoice_number: res.data.invoice.invoice_number,
        })
        nextPage()
      })
      .catch((err) => {
        if (err?.body?.message) notify(err.body.message, 'warning')
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <Button onClick={onOpen} variant="contained" color="default">
        <Add /> {translate('Custom Invoice')}
      </Button>
      <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          <TitleWrapper>
            <Stepper
              orientation="horizontal"
              steps={STEPS.map((s, index) => ({
                id: s.step,
                content: <span>{translate(s.label)}</span>,
                isComplete: index < activeStep,
              }))}
              activeStep={activeStep}
            />

            <div>
              <IconButton onClick={onClose}>
                <CloseBtn />
              </IconButton>
            </div>
          </TitleWrapper>
        </DialogTitle>

        {currentStage === 'contact' && (
          <InvoiceContactDetailsPage
            nextPage={nextPage}
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            saveContactDetails={saveContactDetails}
          />
        )}
        {currentStage === 'configure' && (
          <ConfigureCustomInvoice
            makeInvoiceDraft={makeInvoiceDraft}
            lineItems={lineItems}
            isLoading={isLoading}
            paymentRequestSettings={paymentRequestSettings}
            isNoProjectInvoice={true}
            previousPage={previousPage}
          />
        )}
        {currentStage === 'preview' && invoice && (
          <PreviewCustomInvoice nextPage={nextPage} previousPage={previousPage} invoice={invoice} />
        )}
        {currentStage === 'email' && (
          <ReviewAndSendCustomInvoice
            sendInvoice={sendInvoice}
            previousPage={previousPage}
            invoice={invoice}
            isLoading={isLoading}
            isNoProjectInvoice={true}
            contactDetails={contactDetails}
          />
        )}
      </Dialog>
    </>
  )
}

export default StandaloneInvoiceBtn
