import { SaveOutlined } from '@material-ui/icons'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import ButtonWithConfirmDialog from 'elements/button/ButtonWithConfirmDialog'
import { StandardInputs } from 'elements/input/StandardInputs'
import useTrackComponent from 'hooks/eventTracking/useTrackComponent'
import { Button } from 'opensolar-ui'
import React, { useCallback, useMemo } from 'react'
import { BooleanInput, SaveButton, SimpleForm, Toolbar, useNotify, useRedirect, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { ActionType, EventType } from 'types/tracking'
import { WorkflowActionType, WorkflowType } from 'types/workflows'
import { reloadEntireApp, urlToId } from 'util/misc'
import EditTemplate from './edit/EditTemplate'
import { validateWorkflow } from './edit/util'
import SyncActions from './SyncActions'

const CustomizedToolbar = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const translate = useTranslate()
  const formValues = useFormState().values
  const showStageDialog = useMemo(() => !!formValues.workflow_stages?.filter((x) => x.forDelete).length, [
    formValues.workflow_stages,
  ])
  const newStages = useMemo(() => formValues.workflow_stages?.filter((x) => typeof x.id === 'string'), [
    formValues.workflow_stages,
  ])

  const { trackEvent } = useTrackComponent({
    componentKey: 'workflow_form',
    eventName: 'Workflow Toolbar',
    description: 'User Viewed Workflow Form',
  })

  const onSuccess = useCallback(
    (response) => {
      const record = response.data
      if (record && !record.workflow_stages) {
        trackEvent(
          EventType.SYSTEM_EVENT,
          { type: ActionType.SAVE },
          { eventName: 'User Created Workflow', title: record.title }
        )
        notify('ra.notification.created', 'info')
        redirect('edit', `/${props.resource}`, record.id, record)
      } else {
        trackEvent(
          EventType.SYSTEM_EVENT,
          { type: ActionType.UPDATE },
          { eventName: 'User Edited Workflow', title: record.title }
        )
        notify('ra.notification.updated', 'success')
        if (!!newStages?.length || showStageDialog) reloadEntireApp()
      }
    },
    [newStages]
  )

  return (
    <Toolbar {...props}>
      <div>
        <Link style={{ textDecoration: 'none' }} to={`/${props.resource}`}>
          <Button
            startIcon={<AlertError />}
            variant="contained"
            color="default"
            style={{ margin: '10px 0 ', position: 'relative' }}
          >
            <span>{translate('Cancel')}</span>
          </Button>
        </Link>
        {showStageDialog ? (
          <ButtonWithConfirmDialog
            {...props}
            size="large"
            label={'Save'}
            variant={'contained'}
            dialogTitle={translate('Confirm Workflow Save')}
            dialogBody={translate(
              'Stages cannot be deleted if this Workflow is active on a project. Stages will be archived instead.'
            )}
            confirmLabel={'Confirm'}
            icon={<SaveOutlined />}
            saveButton={() => {
              return (
                <SaveButton
                  {...props}
                  key="save"
                  label="Confirm"
                  redirect={'list'}
                  submitOnEnter={true}
                  onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
                    defaultSaveFunc(values, redirect, { onSuccess, onFailure: props.onFailure })
                  }}
                />
              )
            }}
          />
        ) : (
          <SaveButton
            {...props}
            redirect={'edit'}
            submitOnEnter={true}
            onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
              defaultSaveFunc(values, redirect, { onSuccess, onFailure: props.onFailure })
            }}
          />
        )}
      </div>
    </Toolbar>
  )
}
const formatDefaultValues = (values) => {
  let formattedValues = { ...values }
  if (values.workflow_stages) {
    //filter out actions with the same ID if present
    formattedValues.workflow_stages = values.workflow_stages
      ?.map((x) => {
        let newActions: WorkflowActionType[] = []
        if (!!x.actions?.length) {
          x.actions.forEach((y) => {
            const existing = newActions.find((action: WorkflowActionType) => action.id === y.id)
            if (!existing) {
              newActions.push(y)
            }
          })
        }
        return { ...x, actions: newActions }
      })
      .sort((stageA, stageB) => stageA.order - stageB.order)
  }
  return formattedValues
}

const formatValues = (values) => {
  let formattedValues = { ...values }
  if (values.workflow_stages) {
    formattedValues.workflow_stages = values.workflow_stages
      ?.filter((x) => !(typeof x.id === 'string' && x.forDelete))
      .map((x) => {
        let newActions = x.actions.map((y) => {
          if (!y.share_with_orgs) return y
          return { ...y, share_with_orgs: y.share_with_orgs.map((z) => urlToId(z)) }
        })
        return { ...x, actions: newActions }
      })
  }
  return formattedValues
}
interface PropTypes {
  id?: number
  record: WorkflowType
}

const WorkflowForm: React.FC<PropTypes> = (props) => {
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  const { allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('business_process'))
  return (
    <SimpleForm
      redirect={false}
      {...props}
      defaultValue={formatDefaultValues}
      formatSubmitValues={(values) => formatValues(values)}
      disabled={!allowEdit}
      toolbar={<CustomizedToolbar {...props} />}
      validate={validateWorkflow}
    >
      <StandardInputs
        archive={isAdmin}
        autoApply={isAdmin && 'is_default'}
        autoApplyLabel="Is Default"
        extraFields={
          props.id
            ? [
                <BooleanInput
                  source={'is_automated'}
                  label="Auto-Update Project Stages"
                  helperText="Automatically update stages when a project is marked as sold or installed."
                  defaultChecked={true}
                />,
              ]
            : []
        }
      />
      {props.id && <EditTemplate {...props} />}
      <ShareabilitySelector extraAction={<SyncActions />} />
    </SimpleForm>
  )
}

export default WorkflowForm
