import { ContiguousPanelRow, MountingCalcResult, PanelRow } from '../types'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'

export abstract class PerRowBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract generateBomForRow(resultFromLastRow: MountingCalcResult, row: PanelRow): MountingCalcResult

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    return this.block.rows
      .flatMap(getContiguousRows)
      .reduce((resultFromLastRow, row) => this.generateBomForRow(resultFromLastRow, row), result)
  }
}

// A PanelRow may have gaps. Much easier to work with rows without gaps
// An alternative would be to check each x-coord for a panel, and neighbours, and add to the bom accordingly
export function getContiguousRows(row: PanelRow): ContiguousPanelRow[] {
  const contiguousRows: ContiguousPanelRow[] = []

  let currentContiguousRow: ContiguousPanelRow = []

  row.forEach((panel, index) => {
    if (panel.isActive) {
      currentContiguousRow.push(panel)

      if (!row[index + 1] || !row[index + 1].isActive) {
        // We've found the end of the contiguous row...
        // ...so we push it and start over
        contiguousRows.push(currentContiguousRow)
        currentContiguousRow = []
      }
    }
  })

  return contiguousRows
}
