var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var LoadingDots = function (props) {
    return (_jsxs("div", { style: __assign({ display: 'flex', height: 'inherit', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }, (props.style || {})), children: [_jsxs("div", { className: "spinner", children: [_jsx("div", { className: "bounce1", style: { backgroundColor: props.color ? props.color : undefined } }), _jsx("div", { className: "bounce2", style: { backgroundColor: props.color ? props.color : undefined } }), _jsx("div", { className: "bounce3", style: { backgroundColor: props.color ? props.color : undefined } })] }), props.text && _jsx("span", { style: { marginTop: '30px', textAlign: 'center' }, children: props.text })] }));
};
