import {
  ContiguousPanelColumn,
  HasPosition,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  PanelColumn,
  PanelWithPosition,
} from '../types'
import { addItems, createMountingItem } from '../utils'
import { BomGeneratorAbstract } from './BomGeneratorAbstract'
import { getContiguousColumns } from './PerColumnBomGeneratorAbstract'

export abstract class PerColumnClampBomGeneratorAbstract extends BomGeneratorAbstract {
  abstract chooseMidClamp(): Item
  abstract chooseEndClamp(): Item
  abstract getClampPositions(input: MountingCalcInput, panel: PanelWithPosition): HasPosition[]

  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    return getContiguousColumns(this.block.rows).reduce(
      (resultFromLastColumn, column) => this.generateBomForColumn(resultFromLastColumn, column),
      result
    )
  }

  generateBomForColumn(resultFromLastColumn: MountingCalcResult, column: PanelColumn): MountingCalcResult {
    const resultWithMidClamps = this.addMidClamps(column, resultFromLastColumn)
    const resultWithEndClamps = this.addEndClamps(column, resultWithMidClamps)
    return resultWithEndClamps
  }

  addMidClamps(column: ContiguousPanelColumn, result: MountingCalcResult): MountingCalcResult {
    if (column.length < 2) return result

    const midClamps: Item[] = []
    const baseClamp = this.chooseMidClamp()
    const panelHeight = this.block.panel.height
    const offset = (column[1].top - (column[0].top + panelHeight)) / 2 // half the gap between panels, to position clamps right in the middle

    for (let index = 1; index < column.length; index++) {
      // Start with the second panel
      const top = column[index].top - offset // Halfway point between the panels
      midClamps.push(
        ...this.getClampPositions(this.input, column[0]).map(
          ({ left }) => createMountingItem(baseClamp, { top, left, blockIndex: this.blockIndex }) // Add the clamp at the right position
        )
      )
    }

    return addItems(result, midClamps)
  }

  addEndClamps(column: ContiguousPanelColumn, result: MountingCalcResult): MountingCalcResult {
    if (!column.length) return result

    const endClamps: Item[] = []
    const baseClamp = this.chooseEndClamp()

    const columnStart = column[0].top
    const columnEnd = getColumnEnd(column)

    this.getClampPositions(this.input, column[0]).forEach(({ left }) => {
      endClamps.push(
        createMountingItem(baseClamp, { top: columnStart, left, blockIndex: this.blockIndex }),
        createMountingItem(baseClamp, { top: columnEnd, left, blockIndex: this.blockIndex })
      )
    })

    return addItems(result, endClamps)
  }
}

function getColumnEnd(column: ContiguousPanelColumn) {
  return column[column.length - 1].top + column[column.length - 1].height
}
