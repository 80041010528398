import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@material-ui/core'
import { EmailOutlined, Info } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Button from 'elements/button/Button'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ContactData } from 'types/projects'
import { getIsValidEmail } from 'util/misc'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  integration: string | undefined | null
  paymentOptionId: number
  systemUuid: string | null
  orgId: number
  onClose: () => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  contentWrapper: {
    margin: '10px 0px',
  },
  paragraphsWrapper: {
    marginBottom: '30px',
  },
  inputRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  inputWrapper: {
    margin: '0px 0px 30px',
    display: 'flex',
    width: '100%',
  },
  inputToolTip: {
    marginLeft: '10px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonWrapper: {
    margin: '8px',
  },
}))

const EmailApplicationDialog: React.FC<PropTypes> = (props) => {
  const [emailLoading, setEmailLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [firstRealContact, setFirstRealContact] = useState<ContactData | undefined>(undefined)

  const project = useFormState().values
  const form = useForm()
  const isPendingSave = form.mutators.getFormDirtyFields()?.length > 0
  const classes = useStyles()
  const notify = useNotify()
  const translate = useTranslate()

  // find the first real contact on the project and save it in state
  useEffect(() => {
    let validContactMissing = false
    if (project.contacts_data?.length) {
      let firstReal: ContactData | undefined = undefined
      project.contacts_data?.forEach((con: ContactData) => {
        if (!firstReal && con.email && !con.email.includes('@os.code')) {
          firstReal = con
        }
      })
      if (firstReal) {
        setFirstRealContact(firstReal)
      } else {
        validContactMissing = true
      }
    } else {
      validContactMissing = true
    }
    if (validContactMissing) {
      notify(
        translate('Please use the Info page to add a contact with a valid email address to the project first'),
        'warning'
      )
      props.onClose()
    }
  }, [project.contacts_data])

  // when we set or update the contact, use that contact's email to pre-fill the text input
  useEffect(() => {
    if (!email && firstRealContact && firstRealContact.email) {
      setEmail(firstRealContact.email)
    }
  }, [firstRealContact])

  // the back-end relies on saved data. To ensure the user doesn't accidentally send an application based on an old design we require that they save or discard their changes
  useEffect(() => {
    if (isPendingSave) {
      notify(translate('Please save or discard your changes to the project first'), 'warning')
      props.onClose()
    }
  }, [isPendingSave])

  const onSubmit = () => {
    if (!getIsValidEmail(email)) {
      notify(translate('Please enter a valid emaill address'), 'warning')
      return
    }
    setEmailLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${props.orgId}/projects/${project.id}/systems/${props.systemUuid}/${props.paymentOptionId}/${props.integration}/share_credit_app/`,
      data: {
        email,
      },
    })
      .then((res) => {
        notify(translate('The credit application has been emailed to your customer'), 'success')
        logAmplitudeEvent('integrated_finance_application_sent', {
          integration: props.integration,
          project_id: project?.id,
          system_uuid: props.systemUuid,
          payment_option_id: props.paymentOptionId,
          placement: 'payments page',
        })
        props.onClose()
      })
      .catch((err) => {
        console.dir(err)
        if (err?.body?.message) notify(err.body.message, 'warning')
        else if (err?.message) notify(err.message, 'warning')
        else {
          notify(translate('We were unable to email this credit application'), 'warning')
        }
      })
      .finally(() => {
        setEmailLoading(false)
      })
  }
  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>
        <div className={classes.titleRow}>
          <div>Email Application to {firstRealContact?.display}</div>
          <div className={classes.inputToolTip}>
            <Tooltip
              title={translate(
                'Send an email to the customer that will send them directly to a credit application for the selected option. Confirm the email address below is correct and then click Send'
              )}
            >
              <Info htmlColor="rgb(180, 180, 180)" fontSize="small" />
            </Tooltip>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <div className={classes.paragraphsWrapper}>
            <p>{translate('Send an email to your customer directly from the lender.')}</p>
            <p>{translate('A link to the finance application will be automatically included in the email.')}</p>
          </div>
          <span className="small">{translate('Recipient')}</span>
          <div className={classes.inputRow}>
            <div className={classes.inputWrapper}>
              <TextField value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionRow}>
          <div className={classes.buttonWrapper}>
            <Button color="default" label="Cancel" fullWidth onClick={props.onClose} disabled={emailLoading} />
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              label="Send"
              fullWidth
              onClick={onSubmit}
              loading={emailLoading}
              disabled={emailLoading}
              startIcon={<EmailOutlined />}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default EmailApplicationDialog
